import React from "react";
import { Component } from 'react';
import './Header.css';
import image from "./REA_India_Straight_Logo.png";
import { removeCookie } from '../../utils/Cookie';
import config from '../../config';
import { getEmployeeDetailUrl } from '../../utils/CommonUrl';
import axios from 'axios';
import { getAxiosConfig, getRequestHeader } from '../../utils/CommonFunction'

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      empDetails:{},
      flag: false
    };
  }

  async componentDidMount() {
    const { status, data = {} } = await this.employeeDetailsFetch();
    if (status !== 200) {
      this.goToHomepage();
    }
    
    this.setState({ empDetails: data[0] });
    this.setState({ flag: true });
  }

  async employeeDetailsFetch() {
    let response = [];
    try {
      const config = getAxiosConfig();
      config.headers = getRequestHeader();
      response = await axios.get(getEmployeeDetailUrl(), config);
    } catch (error) {
      console.log(error);
    }

    return response;
  }

  handleLogout = () => {
    this.logOut();
  }

  goToHomepage = () => {
    window.location = process.env.REACT_APP_BASE_URL;
  }

  logOut = () => {
    removeCookie(config.userCookieName);
    window.location = process.env.REACT_APP_LOGOUT_URL;
  }

  render() {
    const { flag, empDetails } = this.state;
    return (
        <div className="header">
          <div className="logo_wrap">
              <img
                src={image}
                className="logo-image"
                alt="Housing | Unified"
                title="Housing | Unified"
                onClick={this.goToHomepage}
              />
            <div className="profile-div">
              { 
                flag ?  
                (
                  <div>
                    <div className="log-out">
                      <h3 className="profile-h3">
                      { `${ empDetails.legalname } - ${ empDetails.employeeno }` }
                      <img 
                        className="logout-logo"
                        src="https://img.icons8.com/material-outlined/24/000000/shutdown.png" 
                        alt="Housing | Logout"
                        title="Housing | Logout"
                        onClick={ this.handleLogout }
                      />
                      </h3>
                    </div>
                  </div>
                ) 
                : 
                (<div></div>) 
              }
            </div>
          </div>
        </div>
    )
  }
}

export default Header;
