import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  cardHeader: {
    backgroundColor: '#485ED5',
    background: 'linear-gradient(60deg, #3f51b5, #4e66e6)',
    width: '100%',
    minHeight: '50px',
    borderRadius: "5px"
  },
  headerText: {
    color: '#FFFFFF',
    marginTop: '10px',
    minHeight: 'auto',
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    fontWeight: '100',
    marginBottom: '3px',
    textDecoration: 'none',
    textAlign: 'center'
  },
  root: {
    padding: theme.spacing(0)
  },
  cardBody: {
    width: '100%',
    minHeight: "600px",
    backgroundColor: "#F1F1F4",
  },
  filterBody: {
    padding: "10px"
  },
  maiBody: {
    padding: "10px"
  },
  gridBody: {
    padding: "10px"
  },
  tableHeadCell: {
    color: 'inherit',
    fontSize: '10px',
    fontWeight: 'bold',
    padding: '0px !important'
  },
  tableCell: {
    color: 'inherit',
    fontSize: '11px',

    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',

    width: '20px',
    maxWidth: '20px',
    height: "48px",
    maxHeight: "48px"
  },
  pad0: {
    padding: '0 !important'
  },
  tableHeader: {
    backgroundColor: '#4A61DB',
    color: '#FFFFFF'
  },
  tableHeadCellDetail: {
    color: 'inherit',
    fontSize: '12px',
    fontWeight: 'bold',
    padding: '5px !important',
    textAlign: "left",
    width: "100px"
  },
  tableCellDetail: {
    color: 'inherit',
    fontSize: '14px',
    textAlign: "left",
    padding: '5px !important',
    width: "100px"
  },
  anchorLink: {
    textDecoration: "none !important",
    color: "rgb(69, 89, 201)",
    fontWeight: "bold"
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  anchorLinkEmp: {
    textDecoration: "none !important",
    color: "rgb(69, 89, 201)"
  },
}))
