import React, { Fragment } from 'react'
import Grid from '@material-ui/core/Grid'
import { useStyles } from './style'
import { 
    Paper
} from '@material-ui/core'
const companyName = 'REA India'

const Welcome = ({
    offerData
}) => {
    const { name } = offerData
    const classes = useStyles()
    return (
        <Fragment>
            <Grid container spacing={2} className={classes.root}>
                <Grid item sm={12}>
                    <Paper className={classes.paperFirst}>
                        <span>Dear {name},</span>
                        <br></br>
                        <br></br>
                        <span>Welcome you to the onboarding process of {companyName}!</span>
                        <br></br>
                        <br></br>
                        <span>We’re super excited to have you onboard. As a first step, let’s help you with a seamless pre-onboarding process. Our HRIS platform will take you through a few simple steps to complete the documentation process so that we’re all set before you come onboard. You are required to submit information related to your personal details, education qualification, professional experience, professional references, etc. Just follow the instructions and provide the details requested under each section one by one. Please note that the information provided by you should match with the relevant documents that you have shared as part of the hiring process. You may need to upload documents at a few places as instructed in the relevant sections. Kindly note that any mismatch would lead to restarting the entire process again, leading to delay in the onboarding process. We don’t want that your experience is hampered in any way, so kindly fill in the information with utmost care and attention.</span>
                        <br></br>
                        <br></br>
                        <span>Once again welcome onboard and we look forward to memorable journey together.</span>
                    </Paper>
                </Grid>
            </Grid>
        </Fragment>
    )
}

export default Welcome