import React, { useState,useEffect } from 'react';
import { useStyles } from './style';
import { getPendingDocumentDetailsFetchData, getDocumentMasterListFetchData, updatePendingDocuments, getHropsListFetchData, getLoggedInEmployeeDetailFetchData, sendEmail, postDownloadAllFiles } from '../fetchData'
import HeaderLayout from '../HeaderLayout'
import { TableCell, TableContainer, TableHead, TableRow, Table, TableBody, Select, MenuItem, Button, Grid, FormControl, TablePagination, Snackbar, TextField } from '@material-ui/core'
import PeopleOutlineTwoTone from '@material-ui/icons/PeopleOutlineTwoTone'
import {Paper} from '@material-ui/core'
import SaveIcon from '@material-ui/icons/Save'
import CloudDownload from '@material-ui/icons/CloudDownload'
import logo from '../../../utils/load.gif'
import { employeeTaggingUrl,getEmployeeDetailUrl } from '../../../utils/CommonUrl'
import MuiAlert from '@material-ui/lab/Alert/Alert';
import { EfilingApiUrl } from '../../../utils/CommonUrl'
import { efilingConstant } from '../../../constants/EfilingConstant';
import { getMenuSubmenuRightsDetail } from '../../../utils/authFunction';
import { pageURLConfig, menuSubmenuPageCategory } from '../../../constants/CommonConstant';
const { moduleName, controllerName, actionName } = pageURLConfig.EFILING_INDEX_DOCUMENT_APPROVAL
const { ADMIN_CATEGORY } = menuSubmenuPageCategory

const PendingDocument =  React.memo(({ index, pendingDocument, onUpdate, documentMasterListAndIdMapping, pendingDocumentChangeData, hrOpsRole, loggedInEmployeeDetails }) => {

const classes = useStyles();

const handleRemarksChange = (id,value) => {
  const newPendingDocumentDetail = {...pendingDocument}
  newPendingDocumentDetail.remarks_by_hrops = value
  pendingDocumentChangeData[pendingDocument.id] = newPendingDocumentDetail
  onUpdate(newPendingDocumentDetail)
};

const onUpdateActionChange = (ev) => {
  const { name, value } = ev.target
  const newPendingDocumentDetail = {...pendingDocument}
  if(value == 'Approve'){
    if(hrOpsRole === efilingConstant.HROPS_ADMIN_ROLE_EMP.HROPS1){
      newPendingDocumentDetail.status_id = efilingConstant.status.APPROVED
    }

    if(hrOpsRole === efilingConstant.HROPS_ADMIN_ROLE_EMP.HROPS2){
      newPendingDocumentDetail.status_id = efilingConstant.status.APPROVED
    }

    if(hrOpsRole === efilingConstant.HROPS_ADMIN_ROLE_EMP.HROPS3){
      newPendingDocumentDetail.status_id = efilingConstant.status.APPROVED
    }
    
    newPendingDocumentDetail.action = 'Approve'
    newPendingDocumentDetail.approvedOrRejectedOn = new Date()
    newPendingDocumentDetail.approvedOrRejectedBy = loggedInEmployeeDetails.legalname + '-' + loggedInEmployeeDetails.employeeno
  } else if (value == 'Reject') {
    newPendingDocumentDetail.status_id = efilingConstant.status.REJECTED
    newPendingDocumentDetail.action = 'Reject'
    newPendingDocumentDetail.approvedOrRejectedOn = new Date()
    newPendingDocumentDetail.approvedOrRejectedBy = loggedInEmployeeDetails.legalname + '-' + loggedInEmployeeDetails.employeeno
  }
  pendingDocumentChangeData[pendingDocument.id] = newPendingDocumentDetail
  onUpdate(newPendingDocumentDetail)
};
  return (
    <TableRow>      
      <TableCell >{index + 1}</TableCell>
      <TableCell>{pendingDocument.legalname} <br></br> {`(${pendingDocument.employeeno})`}</TableCell>
      <TableCell>{pendingDocument.date_of_submission ? (new Date(pendingDocument.date_of_submission)).toLocaleDateString('en-GB', {
                    day: 'numeric', month: 'short', year: 'numeric'
                  }).replace(/ /g, '-') : '---'}
      </TableCell>
      <TableCell>{ ( pendingDocument.document_master_id && documentMasterListAndIdMapping[pendingDocument.document_master_id] ) ? documentMasterListAndIdMapping[pendingDocument.document_master_id].document_type : ''}</TableCell>
      <TableCell>{pendingDocument.old_status_id ? efilingConstant.status_hrops[pendingDocument.old_status_id] : '--'}</TableCell>
      <TableCell>{pendingDocument.remarks ? pendingDocument.remarks : '--'}</TableCell>      
      <TableCell>{pendingDocument.document_path ?  <a target='blank' href={EfilingApiUrl.GET_FILE_URL+btoa(pendingDocument.document_path)} className={classes.anchor}>
            VIEW
        </a> : '---'}</TableCell>
      <TableCell>
      <TextField
            variant="outlined"
            label=""
            name='remarks'
            value={pendingDocument.remarks_by_hrops ? pendingDocument.remarks_by_hrops : ''}
            onChange={e => handleRemarksChange(pendingDocument.id, e.target.value)}
            style={{minWidth:'100px'}}
        />
      </TableCell>
      <TableCell>
      <FormControl className={classes.skillFormControl}>
          <Select
              label='Action'
              name='action'
              value={pendingDocument.action ? pendingDocument.action : 0}
              onChange={onUpdateActionChange}
          >
              <MenuItem key='1' value='Approve'>Approve</MenuItem>
              <MenuItem key='2' value='Reject'>Reject</MenuItem>
          </Select>
        </FormControl>
      </TableCell>
      <TableCell>{pendingDocument.department ? pendingDocument.department : '--'}</TableCell>
      <TableCell>{pendingDocument.function ? pendingDocument.function : '--'}</TableCell>
      <TableCell>{pendingDocument.grade ? pendingDocument.grade : '--'}</TableCell>
      <TableCell>{pendingDocument.location_name ? pendingDocument.location_name : '--'}</TableCell>
      
    </TableRow>
  );
});

const ListOfPendingDocuments = ({ pendingDocuments, setPendingDocuments, documentMasterListAndIdMapping, pendingDocumentChangeData, hrOpsRole, loggedInEmployeeDetails }) => {
    const onUpdate = React.useCallback(
        (pendingDocumentChange) => {
            setPendingDocuments((prevPendingDocuments) =>
          prevPendingDocuments.map((t) => (t.id === pendingDocumentChange.id ? pendingDocumentChange : t))
          );
        },
        [setPendingDocuments]
      );
  return (
    <TableContainer>
        <Table>
            <TableHead>
            <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Employee Details</TableCell>
                <TableCell style={{minWidth:'77px'}}>Requested On</TableCell>
                <TableCell style={{width: '150'}}>Description</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Employee Remark</TableCell>
                <TableCell>Preview</TableCell>
                <TableCell style={{minWidth:'100px'}}>Remark</TableCell>
                <TableCell>Action</TableCell>

                <TableCell>Department</TableCell>
                <TableCell>Sub-department</TableCell>
                <TableCell>Grade</TableCell>
                <TableCell>Location</TableCell>

            </TableRow>
            </TableHead>
            <TableBody>
            { ( pendingDocuments && pendingDocuments.length > 0 ) ? pendingDocuments.map((pendingDocument, index)=>(
                <PendingDocument 
                key= {index}
                index = {index}
                pendingDocument={pendingDocument} 
                onUpdate={onUpdate} 
                documentMasterListAndIdMapping = {documentMasterListAndIdMapping}
                pendingDocumentChangeData={pendingDocumentChangeData}
                hrOpsRole={hrOpsRole}
                loggedInEmployeeDetails={loggedInEmployeeDetails}
                />
            )) : (
                <TableRow>
                <TableCell><b>No result found</b></TableCell>
                </TableRow>
            ) } 
            </TableBody>
        </Table>
    </TableContainer>
  );
};

export default function PendingDocumentScreen() {
  const [pendingDocuments, setPendingDocuments] = React.useState([]);
  const [pendingDocumentChangeData, setPendingDocumentChangeData] = useState({})
  const [documentMasterListAndIdMapping, setDocumentMasterListAndIdMapping] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [snackBarDetails, setSnackBarDetails] = useState({open:false,errorMessage:'',type:''})
  const [isAuthorised, setisAuthorised] = useState(true)
  const [loggedInEmployeeDetails, setLoggedInEmployeeDetails] = useState({})
  const [hrOpsRole, setHrOpsRole] = useState('')
  const [isHavingPageAddAccess, setIsHavingPageAddAccess] = useState(false)
  const [isHavingPageEditAccess, setIsHavingPageEditAccess] = useState(false)
  const [isHavingPageViewAccess, setIsHavingPageViewAccess] = useState(null)

  const [downloadButtonDisable, setDownloadButtonDisable] = useState(false)

  const classes = useStyles();

  useEffect(()=>{
      getPendingDocumentsDetails()
      setPageRights()
  },[])

  const setPageRights = async () => {
    const params = { pageCategory: ADMIN_CATEGORY, moduleName, controllerName, actionName}
    const rightsObj = await getMenuSubmenuRightsDetail(params)
    const { 
        isUserSuperAdmin,
        isUserGroupAdmin,
        allEmployeeAddRights,
        allEmployeeEditRights, 
        allEmployeeViewRights 
    } = rightsObj
    const addAccess = isUserSuperAdmin || 
          (isUserGroupAdmin && allEmployeeAddRights) 
    const editAccess =  isUserSuperAdmin || 
          (isUserGroupAdmin && allEmployeeEditRights)
    const viewAccess =  isUserSuperAdmin || 
        (isUserGroupAdmin && allEmployeeViewRights)
    
    setIsHavingPageAddAccess(addAccess)
    setIsHavingPageEditAccess(editAccess)
    setIsHavingPageViewAccess(viewAccess)
  }

  const getPendingDocumentsDetails = async () => {
      try {
          setIsLoading(true)
          await getDocumentMasterList()
          let tempHrOpsList = await getHrOpsList()
          let tempLoggedInEmployeeDetails = await getLoggedInEmployeeDetails()
          let tempHrOpsRole = await checkAuthorised(tempHrOpsList,tempLoggedInEmployeeDetails)
          console.log(tempHrOpsRole,'tempHrOpsRole')
          let response = await getPendingDocumentDetailsFetchData({hrOpsRole:tempHrOpsRole});
          if( response && response.data ){
            setPendingDocuments(response.data)
          }
          setIsLoading(false)
      } catch (error) {
          
      }
  }

  const handleUpdate = async () => {
    try {
      setIsLoading(true)
      if(Object.keys(pendingDocumentChangeData).length > 0){
        const response = await updatePendingDocuments({pendingDocumentChangeData});
        if(response.success){
          setSnackBarDetails({open:true,errorMessage:'Document updated successfully',type:'success'})
          sendApproveAndRejectsEmails(JSON.parse(JSON.stringify(pendingDocumentChangeData)))
          getPendingDocumentsDetails()
          setPendingDocumentChangeData({})
        } else {
          setSnackBarDetails({open:true,errorMessage:'Something went wrong',type:'error'})
        }
      } else {
        setSnackBarDetails({open:true,errorMessage:'Please make changes to save details',type:'info'})
      }
      setIsLoading(false)

    } catch (error) {
      
    }
  }

  const sendApproveAndRejectsEmails = async (updatesObjects) => {
    let combinedEmailList = {
      rejectEmployeeNos:[],
      approveEmployeeNos: [],
      emailObjects: []
    }

    Object.keys(updatesObjects).forEach(key => {
      let item = updatesObjects[key]
      if(item.action == 'Reject' && combinedEmailList.rejectEmployeeNos.includes(item.employeeno)){
        return
      }
      if(item.action == 'Approve' && combinedEmailList.approveEmployeeNos.includes(item.employeeno)){
        return
      }
      if(hrOpsRole != efilingConstant.HROPS_ADMIN_ROLE_EMP.HROPS3 && item.action == 'Approve'){
        return 
      }
      let emailObject = {}
      emailObject.employeeFirstName = item.first_name,
      emailObject.cc = []
      emailObject.to = item.company_provided_email_id
      if(item.action == 'Reject'){
        emailObject.cc.push(item.reporting_manager_email_id)
        emailObject.cc.push(item.hrbp_email_id)
        emailObject.remarks = item.remarks_by_hrops ? item.remarks_by_hrops : ''
        emailObject.emailTemplate = 'DocumentRejectionEmail'
        combinedEmailList.rejectEmployeeNos.push(item.employeeno)
      } else if(item.action == 'Approve'){
        emailObject.emailTemplate = 'PendingDocumentApprovedEmail'
        combinedEmailList.approveEmployeeNos.push(item.employeeno)
      }
      combinedEmailList.emailObjects.push(emailObject)
    })
    
    await Promise.all(combinedEmailList.emailObjects.map(async item => {
      await sendEmail({emailObject:item})
    }))

  }

  const getDocumentMasterList = async () => {
      try {
          let response = {}
          response = await getDocumentMasterListFetchData();
          let documentDetailAndIdMapping = {}
          if(response && response.data){
              response.data.forEach(element => {
                  documentDetailAndIdMapping[element.id] = element
              })
              setDocumentMasterListAndIdMapping(documentDetailAndIdMapping)
          }

      } catch (error) {
          
      }
  }

  const getHrOpsList = async () => {
    try {
      let response = {}
      response = await getHropsListFetchData()
      if(response && response.data){
        let tempHropsList = {}
        response.data.map(element => {
            if(tempHropsList && tempHropsList[element.role]){
              tempHropsList[element.role].push(element.employeeno)
            } else {
              tempHropsList[element.role] = []
              tempHropsList[element.role].push(element.employeeno)
            }
        })
        return tempHropsList
      }
    } catch (error) {
      
    }
  }

  const getLoggedInEmployeeDetails = async () => {
    try {
      let loggedInEmployeeDetailsResponse = await getLoggedInEmployeeDetailFetchData()
      if(loggedInEmployeeDetailsResponse && loggedInEmployeeDetailsResponse.data){
        setLoggedInEmployeeDetails(loggedInEmployeeDetailsResponse.data[0])
        return loggedInEmployeeDetailsResponse.data[0]
      }
    } catch (error) {
      
    }
  }

  const checkAuthorised = async (tempHrOpsList,tempLoggedInEmployeeDetails) => {
    let isAuthorised = false;
    let tempHrOpsRole = ''
    if(tempLoggedInEmployeeDetails && tempHrOpsList) {
      Object.keys(tempHrOpsList).map(role => {
        if(tempHrOpsList[role].includes(tempLoggedInEmployeeDetails['employeeno'])){
          isAuthorised = true;
          tempHrOpsRole = role
          setHrOpsRole(role)
        }
      })
    }
    setisAuthorised(isAuthorised)
    // if(!isAuthorised) {
    //   window.alert("Not Authorised")
    //   window.location.href = process.env.REACT_APP_BASE_URL
    // }
    return tempHrOpsRole;
  }

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBarDetails({open:false,errorMessage:'',type:''});
  };

  const doesDocumentExists = () => {
    let doesDocumentExists = false;
    pendingDocuments.forEach((t) => {
        if(t.document_path) {
            doesDocumentExists =  true;
        }
    })
    return doesDocumentExists;
}


const handleDownloadAll = async () => {
  setIsLoading(true)
  setDownloadButtonDisable(true)
  let payload = {}
  pendingDocuments.forEach((doc => {
    if(doc && doc.document_path && doc.employeeno){
      if(payload[doc.employeeno]){
        payload[doc.employeeno].push(doc)
      } else {
        payload[doc.employeeno] = []
        payload[doc.employeeno].push(doc)
      }
    }
  }))

  let response = await postDownloadAllFiles(payload)
  let downloadFileName = `Pending_Documents_${new Date}.zip`
  const linkSource = `data:application/pdf;base64,${response}`;
  const downloadLink = document.createElement('a');
  document.body.appendChild(downloadLink);

  downloadLink.href = linkSource;
  downloadLink.target = '_self';
  downloadLink.download = downloadFileName;
  downloadLink.click(); 
  setIsLoading(false)
  setDownloadButtonDisable(false)

}


  return (
    <div>
      {(!isHavingPageViewAccess && !isAuthorised) 
        ? 
          (isHavingPageViewAccess == null) 
          ? 
          <div className={classes.loader}><img src={logo} alt="loading..." /></div> 
          :
          <div> <h1 style={{margin:'0px'}}>Unauthorised </h1> <br></br> <span>You don't have access to view this page</span> </div> 
        :   
      <div className={classes.root}>
          {snackBarDetails && snackBarDetails.open ? 
            <Snackbar
              anchorOrigin={{ vertical:'top', horizontal:'center' }}
              open={snackBarDetails.open}
              autoHideDuration={4000}
              onClose={handleSnackbarClose}
            >
              <MuiAlert severity={snackBarDetails.type}>{snackBarDetails.errorMessage}</MuiAlert>
            </Snackbar> : (null)
          }
          <Grid container>
              <Grid > 
                <HeaderLayout
                    title='Employee Document Approval'
                    subTitle='Pending'
                    icon={<PeopleOutlineTwoTone/>}
                />
              </Grid>
          </Grid>
          { ( isLoading || (!isHavingPageViewAccess && !isAuthorised)) 
              ? 
              <div className={classes.loader}><img src={logo} alt="loading..." /></div>
              : 
          <Paper className={classes.pageContent}>
              <ListOfPendingDocuments 
              pendingDocuments={pendingDocuments} 
              setPendingDocuments={setPendingDocuments} 
              documentMasterListAndIdMapping={documentMasterListAndIdMapping}
              pendingDocumentChangeData={pendingDocumentChangeData}
              hrOpsRole={hrOpsRole}
              loggedInEmployeeDetails={loggedInEmployeeDetails}
              />
          </Paper>
          }
          <div className={classes.button}>
              <Button
                  variant='contained'
                  size='large'
                  color='primary'
                  onClick={handleUpdate}
                  disabled={!isHavingPageEditAccess}
              >
                  Update
              </Button>
              {doesDocumentExists() ? 
                <Button
                    className={classes.button}
                    variant='contained'
                    size='large'
                    color='primary'
                    onClick={handleDownloadAll}
                    disabled={downloadButtonDisable}
                >
                    Download All
                </Button> : '' }
          </div>
      </div>
      
      }
    </div>
  );
}
