import React from 'react'

//import @material ui section
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import { useStyles } from './style'
import { TableCell, TableRow, Table, TableContainer, TableHead, TableBody } from '@material-ui/core'


const DriverModal = ({
  openDriverModal,
  closeDriverModal,
  driverModalData: {
    driverData,
    driverName,
    reportType
  },
  addPercentSign
}) => {

    const classes = useStyles()
  return (
    <Dialog
      className={classes.root}
      open={openDriverModal}
      onClose={closeDriverModal}
      aria-labelledby='form-dialog-title'
    >

      <DialogTitle id='form-dialog-title' onClick={closeDriverModal} style={{display:'flex',justifyContent:'end',padding:'0px 10px'}}>x</DialogTitle>
      {/* <DialogTitle id='form-dialog-title' onClick={closeDriverModal} style={{padding:'0px',margin:'-26px',color:'white',position:'fixed',marginLeft:'404px'}}>X</DialogTitle> */}
      <DialogTitle id='form-dialog-title' style={{textAlign:'center',padding:'0px 0px'}}>Overall Demography</DialogTitle>


      <DialogContent>
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                            <TableCell>{driverName} Driver Questions</TableCell>
                            {['a','ma'].includes(reportType) ?
                            <TableCell>Average Score</TableCell>
                            : ['f','mf'].includes(reportType) ? <TableCell>Favourable %</TableCell> : null }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {driverData && driverData.length > 0 && driverData.map(ele => {
                        return ele.type == 'rating' && 
                                <TableRow>
                                    <TableCell> {ele.title} </TableCell>
                                    <TableCell> {addPercentSign(Number(ele.average).toFixed(1))} </TableCell>
                                </TableRow>
                        
                    })}
                </TableBody>
            </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  )
}

export default DriverModal
