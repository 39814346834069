import React, { useState,useEffect } from 'react';
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Autocomplete from '@material-ui/lab/Autocomplete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CardHeader from '@material-ui/core/CardHeader';
import Card from '@material-ui/core/Card';
import HeaderLayout from '../HeaderLayout'
import PeopleOutlineTwoTone from '@material-ui/icons/PeopleOutlineTwoTone'

import { useStyles } from './style'
import { Button, FormControl, Grid, IconButton, Tooltip, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from '@material-ui/core'
import { getDepartmentListFetchData, getSubDepartmentFetchData, getGradeListFetchData, getLocationListFetchData, getAllEmployeeDocumentDetails, empAutocompleteListFetchData } from '../fetchData';

import { orange } from '@material-ui/core/colors'
import { withStyles } from '@material-ui/core/styles'
import { efilingConstant } from '../../../constants/EfilingConstant';
import logo from '../../../utils/load.gif'
import EditIcon from '@material-ui/icons/Edit';
import { getMenuSubmenuRightsDetail } from '../../../utils/authFunction';
import { pageURLConfig, menuSubmenuPageCategory } from '../../../constants/CommonConstant';
const { moduleName, controllerName, actionName } = pageURLConfig.EFILING_INDEX_DOCUMENT_MASTER
const { ADMIN_CATEGORY } = menuSubmenuPageCategory

const PreviousButton = withStyles(theme => ({
    root: {
      color: theme.palette.getContrastText(orange[500]),
      backgroundColor: orange[500],
      '&:hover': {
        backgroundColor: orange[700]
      }
    }
  }))(Button)

const INITIAL_FILTER_OBJECT = {
    name: '',
    department: '',
    subDepartment: '',
    grade: '',
    designation: '',
    location: '',
    status: '',
    employeeStatus: '',
    autocompleteValue: null
}

const SearchFilters = ({handleFilters}) => {
    const classes = useStyles()
    const [expandDefault, setExpandDefault] = useState(false)
    const [filterObj, setFilterObj] = useState(INITIAL_FILTER_OBJECT)
    const [departmentList, setDepartmentList] = useState([])
    const [subDepartmentList, setSubDepartmentList] = useState([])
    const [gradeList, setGradeList] = useState([])
    const [locationList, setLocationList] = useState([])
    const [empAutocompleteList, setEmpAutocompleteList] = useState([])

    useEffect( () => {
        getDepartmentList()
        getGradeList()
        getLocationList()
        // handleFilters(filterObj)
    },[])

    const handleChange = async event => {
        setFilterObj({ ...filterObj, [event.target.name]: event.target.value })
    }

    const handleAutocompleteEmpInputChange = async event => {
        setFilterObj({ ...filterObj, 'name': event.target.value })
        let tempEmpAutocompleteList = await empAutocompleteListFetchData({empString: event.target.value})
        if(tempEmpAutocompleteList && tempEmpAutocompleteList.data){
            setEmpAutocompleteList(tempEmpAutocompleteList.data)
        }
    }
    

    const handleAutocompleteEmployeeno = async newValue => {
        if(newValue){
            setFilterObj({ ...filterObj, name : newValue.legalname,  autoCompleteValue : newValue })
        } else if (newValue == null) {
            setFilterObj({ ...filterObj, autoCompleteValue : null  })
        }
    }
    

    const getDepartmentList = async () => {
        try {
            let response = {}
            response = await getDepartmentListFetchData()
            if(response && response.data){
                setDepartmentList(response.data)
            }
        } catch (error) {
            
        }
    }

    const getSubDepartment = async (departmentId) => {
        try {
            let response = {}
            response = await getSubDepartmentFetchData({departmentId:[departmentId]})
            if(response && response.data){
                setSubDepartmentList(response.data)
            }
        } catch (error) {
        }
    }

    const getGradeList = async () => {

        try {
            var response = await getGradeListFetchData()
            if(response && response.data){
                response.data.sort((a,b) => (parseInt(a.grade_name) > parseInt(b.grade_name)) ? 1 : ((parseInt(b.grade_name) > parseInt(a.grade_name)) ? -1 : 0))
                setGradeList(response.data)
            }
        } catch (error) {
            
        }
    }

    const getLocationList = async () => {

        try {
            var response = await getLocationListFetchData()
            if(response && response.data) {
                setLocationList(response.data)
            }     
        } catch (error) {
            
        }
    }

    const handleDepartmentChange = event => {
        getSubDepartment(event.target.value)
        setFilterObj({
          ...filterObj,
          department: event.target.value,
          subDepartment: ''
        })
    } 

    const handleReset = () => {
        setFilterObj(INITIAL_FILTER_OBJECT)
    }



    return (
            <Accordion
                expanded={expandDefault}
                onChange={() => setExpandDefault(!expandDefault)}
                className={classes.pageContent}
                style={{margin:'9px'}}
                
            >
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1a-content'
                id='panel1a-header'
                className={classes.pageContent}
                >                   
                    <Grid>
                        <Card>
                            <CardHeader
                                title="Filters"
                                style={{ color: '#3f51b5'}}
                                titleTypographyProps={{variant:'subtitle1' }}
                            />
                        </Card>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails className={classes.pageContent}>
                <Grid container>
                <Paper elevation={2} className={classes.root}>
                    <form className={classes.formRoot} noValidate>
                        <Grid container>
                            <Grid container spacing={3}>
                                <Grid item xs={4} sm={12} md={4}>
                                <Autocomplete
                                    freeSolo
                                    id="outlined-basic"
                                    name="name"
                                    value={filterObj.autoCompleteValue}
                                    options={empAutocompleteList}
                                    getOptionLabel={(option) => {return `${option.legalname} - ${option.employeeno}`}}
                                    getOptionSelected={(option, value) => { return option.employeeno === value.employeeno}}
                                    style={{ width: 300 }}
                                    renderInput={(params) => <TextField {...params} label="Employee Name / Code" variant="standard" />}
                                    onChange={(event, newValue) => {
                                        handleAutocompleteEmployeeno(newValue);
                                    }}
                                    onInputChange={handleAutocompleteEmpInputChange}
                                />
                                </Grid>
                                <Grid item xs={4} sm={12} md={4}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel id='demo-simple-select-outlined-label'>
                                    Department
                                    </InputLabel>
                                    <Select
                                    labelId='demo-simple-select-outlined-label'
                                    id='demo-simple-select-outlined'
                                    name='department'
                                    value={filterObj.department || ''}
                                    onChange={e => handleDepartmentChange(e)}
                                    >
                                    {departmentList.length > 0 &&
                                        departmentList.map((item, index) => (
                                        <MenuItem value={item.department_id} key={item.department_id}>
                                            {item.department}
                                        </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                </Grid>
                                <Grid item xs={4} sm={12} md={4}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel id='demo-simple-select-outlined-label'>
                                    Sub Department
                                    </InputLabel>
                                    <Select
                                    labelId='demo-simple-select-outlined-label'
                                    id='demo-simple-select-outlined'
                                    name='subDepartment'
                                    value={filterObj.subDepartment || ''}
                                    onChange={e => handleChange(e)}
                                    >
                                    {subDepartmentList.length > 0 &&
                                        subDepartmentList.map((item, index) => (
                                        <MenuItem value={item.function_id} key={item.function_id}>
                                            {item.function}
                                        </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item xs={4} sm={12} md={4}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel id='demo-simple-select-outlined-label'>
                                    Grade
                                    </InputLabel>
                                    <Select
                                    labelId='demo-simple-select-outlined-label'
                                    id='demo-simple-select-outlined'
                                    name='grade'
                                    value={filterObj.grade || ''}
                                    onChange={e => handleChange(e)}
                                    >
                                    {gradeList.length > 0 &&
                                        gradeList.map((item, index) => (
                                        <MenuItem value={item.id} key={item.id}>
                                            {item.grade_name}
                                        </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                </Grid>
                                <Grid item xs={4} sm={12} md={4}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel id='demo-simple-select-outlined-label'>
                                    Location - Branch
                                    </InputLabel>
                                    <Select
                                    labelId='demo-simple-select-outlined-label'
                                    id='demo-simple-select-outlined'
                                    name='location'
                                    value={filterObj.location || ''}
                                    onChange={e => handleChange(e)}
                                    >
                                    {locationList.length > 0 &&
                                        locationList.map((item, index) => (
                                        <MenuItem value={item.id} key={item.id}>
                                            {item.locality}
                                        </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                </Grid>
                                <Grid item xs={4} sm={12} md={4}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel id='demo-simple-select-outlined-label'>
                                    Status
                                    </InputLabel>
                                    <Select
                                    labelId='demo-simple-select-outlined-label'
                                    id='demo-simple-select-outlined'
                                    name='status'
                                    value={filterObj.status || ''}
                                    onChange={e => handleChange(e)}
                                    >
                                    <MenuItem value='Completed'>Completed</MenuItem>
                                    <MenuItem value='Pending'>Pending</MenuItem>
                                    </Select>
                                </FormControl>
                                </Grid>
                            
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item xs={4} sm={12} md={4}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel id='demo-simple-select-outlined-label'>
                                    Employee Status
                                    </InputLabel>
                                    <Select
                                    labelId='demo-simple-select-outlined-label'
                                    id='demo-simple-select-outlined'
                                    name='employeeStatus'
                                    value={filterObj.employeeStatus || ''}
                                    onChange={e => handleChange(e)}
                                    >
                                    <MenuItem value='Inactive'>Inactive</MenuItem>
                                    <MenuItem value='Active'>Active</MenuItem>
                                    </Select>
                                </FormControl>
                                </Grid>
                            
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            spacing={3}
                            className={classes.marginTop}
                            direction='row'
                            alignItems='center'
                        >
                            <Grid item>
                            <PreviousButton
                                variant='outlined'
                                color='primary'
                                className={classes.resetButton}
                                size='small'
                                onClick={() => handleReset()}
                            >
                                Reset
                            </PreviousButton>
                            <Button
                                variant='contained'
                                color='primary'
                                size='small'
                                style={{ marginLeft: 20 }}
                                onClick={() => handleFilters(filterObj)}
                                className={classes.button}
                            >
                                Filter Results
                            </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
                </Grid>
                </AccordionDetails>
            </Accordion>
    )
}


export default function DocumentMasterScreen() { 
    const classes = useStyles()
    const [employeeDocumentStatusList, setEmployeeDocumentStatusList] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);
    const [isHavingPageAddAccess, setIsHavingPageAddAccess] = useState(false)
    const [isHavingPageEditAccess, setIsHavingPageEditAccess] = useState(false)
    const [isHavingPageViewAccess, setIsHavingPageViewAccess] = useState(null)

    useEffect( () => {
        setPageRights()
    },[])

    const setPageRights = async () => {
        const params = { pageCategory: ADMIN_CATEGORY, moduleName, controllerName, actionName}
        const rightsObj = await getMenuSubmenuRightsDetail(params)
        const { 
            isUserSuperAdmin,
            isUserGroupAdmin,
            allEmployeeAddRights,
            allEmployeeEditRights, 
            allEmployeeViewRights 
       } = rightsObj
       const addAccess = isUserSuperAdmin ||  (isUserGroupAdmin && allEmployeeAddRights) 
       const editAccess =  isUserSuperAdmin || (isUserGroupAdmin && allEmployeeEditRights)
       const viewAccess =  isUserSuperAdmin || (isUserGroupAdmin && allEmployeeViewRights)
        
        setIsHavingPageAddAccess(addAccess)
        setIsHavingPageEditAccess(editAccess)
        setIsHavingPageViewAccess(viewAccess)
   }

    const handleFilters = async ( filterObj ) => {
        try {
            setIsLoading(true)
            let response = {}
            response = await getAllEmployeeDocumentDetails({filterObj})
            if(response && response.data){
                setEmployeeDocumentStatusList(response.data)
            }
            setPage(0);
            setIsLoading(false)
        } catch (error) {
            
        }
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };

    return (
        <React.Fragment>
            {(!isHavingPageViewAccess) 
            ? 
                (isHavingPageViewAccess == null) ? <div className={classes.loader}><img src={logo} alt="loading..." /></div> :
                <div> <h1 style={{margin:'0px'}}>Unauthorised </h1> <br></br> <span>You don't have access to view this page</span> </div> 
            :
        <div className={classes.root}>
            <Grid container>
                <Grid> 
                <HeaderLayout
                    title='Employee Documents'
                    subTitle=''
                    icon={<PeopleOutlineTwoTone/>}
                />
                </Grid>
            </Grid>
            <SearchFilters
            handleFilters={handleFilters}
            />
            { ( isLoading ) 
            ? 
            <div className={classes.loader}><img src={logo} alt="loading..." /></div>
            : 
            <Paper className={classes.pageContent} elevation={5}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>Employee</TableCell>
                                <TableCell>Department</TableCell>
                                <TableCell>Sub Department</TableCell>
                                <TableCell>Grade</TableCell>
                                <TableCell>Location</TableCell>
                                <TableCell style={{width:'90px'}}>Group Doj</TableCell>
                                <TableCell>Documentation Status</TableCell>
                                <TableCell>Emp Status</TableCell>
                                <TableCell style={{textAlign:'center'}}>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        { ( employeeDocumentStatusList && employeeDocumentStatusList.length > 0 ) ? employeeDocumentStatusList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((element,key)=>(
                            <TableRow key={key}>
                                <TableCell >{key + 1}</TableCell>
                                <TableCell> 
                                    <a target='_blank' href={ process.env.REACT_APP_BASE_URL + '/?pme=' + btoa(element.employeeno)} className={classes.anchor}>
                                        {element.employeeName} ({element.employeeno})
                                    </a> 
                                </TableCell>
                                <TableCell> {element.department}</TableCell>
                                <TableCell> {element.subDepartment}</TableCell>
                                <TableCell> {element.grade}</TableCell>
                                <TableCell> {element.location}</TableCell>
                                <TableCell>{element.groupDoj ? (new Date(element.groupDoj.toString().substring(0,4),parseInt(element.groupDoj.toString().substring(5,6)) - 1,element.groupDoj.toString().substring(7,8))).toLocaleDateString('en-GB', {
  day: '2-digit', month: 'short', year: 'numeric'
}).replace(/ /g, '-') : '---'}</TableCell>
                                <TableCell> {element.status}</TableCell>
                                <TableCell> {element.employeeStatus}</TableCell>
                                {isHavingPageEditAccess ? 
                                <TableCell style={{textAlign:'center'}}><a target='_blank' href={process.env.REACT_APP_BASE_URL+'/efiling/index/document-modify/m/ep/empno/'+btoa(element.employeeno)}>
                                    <Tooltip title="Modify">
                                        <IconButton>
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip></a>
                                </TableCell> : null}
                                
                            </TableRow>
                        )) : (
                            <TableRow>
                            <TableCell><b>No result found</b></TableCell>
                            </TableRow>
                        ) } 
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                rowsPerPageOptions={[50, 100, 200, 250]}
                component="div"
                count={employeeDocumentStatusList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
            }
        </div>}
        </React.Fragment>
    )

}
