import React, { useEffect, useState } from "react"
import { AddQuestion } from "./AddQuestion"
import { PreviewSurvey } from "./PreviewSurvey"
import { getSurveyDataCall } from "./fetchData"
import { TitleComponent } from "../../components/PageTitle/PageTitle"

const Survey = props => {
    const surveyId = props.computedMatch.params.surveyId

    const { moduleName, empDetails } = props
    const [surveyData, setSurveyData] =  useState({})
    const [surveyQueData, setSurveyQueData] =  useState({})
    const [surveySettings, setSurveySettings] =  useState({})
    const [surveyDriverData, setSurveyDriverData] =  useState({})
    
    useEffect(() => {
        try {
            async function fetchData() {
                const { surveyData, surveyQuestion, surveySettings, driverData } = await getSurveyDataCall({ surveyId })
                setSurveyData(surveyData[0])
                setSurveyQueData(surveyQuestion[0])
                setSurveySettings(surveySettings)
                setSurveyDriverData(driverData)
            }
            
            switch (moduleName) {
                case "addQuestionToSurvey":
                case "previewSurvey":
                    fetchData()
                    break
                default:
                    break;
            }
            
        } catch (err) {
        }
    }, [])

    switch (moduleName) {
        case "addQuestionToSurvey":
            return (
                    <React.Fragment>
                        <TitleComponent title="REA India | Survey" />
                        <AddQuestion 
                            surveyId={surveyId}
                            surveyData={surveyData} 
                            surveyQueData={surveyQueData}
                            surveySettings={surveySettings}
                            empDetails={empDetails}
                            surveyDriverData={surveyDriverData}
                        />
                    </React.Fragment>
            )
        case "previewSurvey":
            return (
                <React.Fragment>
                    <TitleComponent title="REA India | Survey" />
                    <PreviewSurvey 
                        surveyId={surveyId}
                        surveyData={surveyData} 
                        surveyQueData={surveyQueData}
                        surveySettings={surveySettings}
                        empDetails={empDetails}
                    />
                </React.Fragment>
            )
        default:
            return (
                <div>This is survey page!</div>
            )
    }
}

export { Survey }