import React, { useState,useEffect } from 'react';
import { useStyles } from './style';
import { getDocumentMasterListFetchData, getEmployeeDocumentDetailsFetchData, getLoggedInEmployeeDetailFetchData, postEmployeeDocumentListFetchData, updateEmployeeDocumentListFetchData, getStatusMasterListFetchData, postUploadedFiles, getHropsListFetchData, sendEmail } from '../fetchData'
import HeaderLayout from '../HeaderLayout'
import { TableCell, TableContainer, TableHead, TableRow, Table, TableBody, Select, MenuItem, Button, Grid, FormControl, TablePagination, TextField, Snackbar, InputLabel } from '@material-ui/core'
import PeopleOutlineTwoTone from '@material-ui/icons/PeopleOutlineTwoTone'
import {Paper, Fab} from '@material-ui/core'
import SaveIcon from '@material-ui/icons/Save'
import CloudDownload from '@material-ui/icons/CloudDownload'
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined'
import logo from '../../../utils/load.gif'
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate"
import { employeeTaggingUrl,getEmployeeDetailUrl } from '../../../utils/CommonUrl'
import { efilingConstant } from '../../../constants/EfilingConstant'
import { EfilingApiUrl } from '../../../utils/CommonUrl'
import MuiAlert from '@material-ui/lab/Alert/Alert';
import qs from 'qs'
import JSZip from 'jszip'
import JSZipUtils from 'jszip-utils'
import { saveAs } from 'save-as'
import Publish from '@material-ui/icons/Publish'
import { getMenuSubmenuRightsDetail } from '../../../utils/authFunction';
import { pageURLConfig, menuSubmenuPageCategory } from '../../../constants/CommonConstant';
const { moduleName, controllerName, actionName } = pageURLConfig.EFILING_INDEX_DOCUMENT_MODIFY
const { ADMIN_CATEGORY } = menuSubmenuPageCategory

const maxUploadFileSize = 10485760
const oneMbInBytes = 1048576

export default function DocumentModify(props) {
    const [isLoading, setIsLoading] = useState(false)
    const [employeeDocumentDetails, setEmployeeDocumentDetails] = useState([])
    const [loggedInEmployeeDetails, setLoggedInEmployeeDetails] = useState({})
    const [documentMasterListAndIdMapping, setDocumentMasterListAndIdMapping] = useState([])
    const [statusMasterListAndIdMapping, setStatusMasterListAndIdMapping] = useState([])
    const [fileData, setFileData] = useState({})
    const [currentFileEmployeeDocumentRowId, setCurrentFileEmployeeDocumentRowId] = useState(0)
    const classes = useStyles();
    const [snackBarDetails, setSnackBarDetails] = useState({open:false,errorMessage:'',type:''})
    const [hropsRole, setHropsRole] = useState('')
    const [isAuthorised, setisAuthorised] = useState(true)
    const [newDocumentAdd, setNewDocumentAdd] = useState({})
    const [showOldVersion, setShowOldVersion] = useState(false)
    const [isHavingPageAddAccess, setIsHavingPageAddAccess] = useState(false)
    const [isHavingPageEditAccess, setIsHavingPageEditAccess] = useState(false)
    const [isHavingPageViewAccess, setIsHavingPageViewAccess] = useState(null)

    useEffect(()=>{
        getEmployeeDocumentDetails()
        getDocumentMasterList()
        getStatusMasterList()
        setPageRights()
    },[])

    useEffect(()=>{

    },[employeeDocumentDetails])

    useEffect(()=>{

    },[isLoading])

    const setPageRights = async () => {
        const params = { pageCategory: ADMIN_CATEGORY, moduleName, controllerName, actionName}
        const rightsObj = await getMenuSubmenuRightsDetail(params)
        const { 
            isUserSuperAdmin,
            isUserGroupAdmin,
            allEmployeeAddRights,
            allEmployeeEditRights, 
            allEmployeeViewRights 
       } = rightsObj
       const addAccess = isUserSuperAdmin || (isUserGroupAdmin && allEmployeeAddRights) 
       const editAccess =  isUserSuperAdmin || (isUserGroupAdmin && allEmployeeEditRights)
       const viewAccess =  isUserSuperAdmin || (isUserGroupAdmin && allEmployeeViewRights)
        
        setIsHavingPageAddAccess(addAccess)
        setIsHavingPageEditAccess(editAccess)
        setIsHavingPageViewAccess(viewAccess)
   }

    const onFileChangeButtonClick = async (id,e) => {
        setCurrentFileEmployeeDocumentRowId(id)
    }
    
    const onInputClick = async (e) => {
        e.target.value = ''
    }

    const onFileChange = async (e) => {

        let files = e.target.files[0]
        if(files && currentFileEmployeeDocumentRowId){
            let isSpecialFileType = efilingConstant.extraApprovalDocumentIds.includes(currentFileEmployeeDocumentRowId)
            if(isSpecialFileType){
                
                if(!efilingConstant.fileExtenstionsSpecial.includes(files.name.split('.').pop())){
                    setSnackBarDetails({open:true,errorMessage:`Selected file type is invalid! Allowed file types are ${efilingConstant.fileExtenstionsSpecial.join(',')}`,type:'error'})
                    return false
                }
            } else {
                if(!efilingConstant.fileExtenstions.includes(files.name.split('.').pop())){
                    setSnackBarDetails({open:true,errorMessage:`Selected file type is invalid! Allowed file types are ${efilingConstant.fileExtenstions.join(',')}`,type:'error'})
                    return false
                }
            }
            
            if (files.size > maxUploadFileSize) {
                alert(
                  `Exceeding file size limit! Upload file size upto ${maxUploadFileSize/oneMbInBytes} MB!`
                );
                return false;
            }
            var fileDataArray = fileData && fileData[currentFileEmployeeDocumentRowId] ? (fileData[currentFileEmployeeDocumentRowId]).concat([files]) : [files]

            setFileData({...fileData,[currentFileEmployeeDocumentRowId]:fileDataArray})
            setCurrentFileEmployeeDocumentRowId(0)
        }
        
    }

    const handleSelectedFile = (e, document_master_id,fileIndex) => {
        let newFileData = {}
        Object.keys(fileData).forEach((element) => {
            if(element != document_master_id){
                newFileData[element] = fileData[element]
            } else if(element == document_master_id){
                fileData[document_master_id].map((file,index) => {
                    if(index != fileIndex){
                        newFileData[element] ? newFileData[element].push(file) : (newFileData[element] = [file])
                    }
                })
            }
        })
        setFileData(newFileData)
    }

    const getEmployeeDocumentDetails = async () => {
        try {
            setIsLoading(true)
            let loggedInEmployeeDetailsResponse = await getLoggedInEmployeeDetailFetchData()
            setLoggedInEmployeeDetails(loggedInEmployeeDetailsResponse.data[0])
            let tempHrOpsList = await getHrOpsList()
            await checkAuthorised(tempHrOpsList,loggedInEmployeeDetailsResponse.data[0])
            let empNo = qs.parse(props.location.search, { ignoreQueryPrefix: true }).empNo
            if(empNo){
                empNo = atob(empNo)
                let response = await getEmployeeDocumentDetailsFetchData({employeeno: empNo, 
                    visibility:[efilingConstant.DOCUMENT_VISIBILITY.BOTH, efilingConstant.DOCUMENT_VISIBILITY.EMPLOYEE,efilingConstant.DOCUMENT_VISIBILITY.HROPS],
                    applicability:[efilingConstant.DOCUMENT_APPLICABILITY.ALL_EMPLOYEES,efilingConstant.DOCUMENT_APPLICABILITY.NOT_ALL_EMPLOYEES],
                    showOldVersion: showOldVersion
                })
                if(response && response.data){
                    if(!(response.data.length == 1 && response.data[0]['document_master_id'] == null)){
                        response.data.forEach(element => {
                            if(element.status_id === efilingConstant.status.APPROVED){
                                element.remarks = ''
                            }
                        })
                        response.data.sort((a,b) => {
                                return a['document_type'].localeCompare(b['document_type'])})
                        setEmployeeDocumentDetails(response.data)
                    }
                }
            }
            setIsLoading(false)
        } catch (error) {
            
        }
    }

    const getHrOpsList = async () => {
        try {
          let response = {}
          response = await getHropsListFetchData()
          if(response && response.data){
            let tempHropsList = {}
            response.data.map(element => {
                if(tempHropsList && tempHropsList[element.role]){
                  tempHropsList[element.role].push(element.employeeno)
                } else {
                  tempHropsList[element.role] = []
                  tempHropsList[element.role].push(element.employeeno)
                }
            })
            return tempHropsList
          }
        } catch (error) {
          
        }
      }

    const checkAuthorised = async (tempHrOpsList,tempLoggedInEmployeeDetails) => {
        let isAuthorised = false;
        let tempHrOpsRole = ''
        if(tempLoggedInEmployeeDetails && tempHrOpsList) {
          Object.keys(tempHrOpsList).map(role => {
            if(tempHrOpsList[role].includes(tempLoggedInEmployeeDetails['employeeno'])){
              isAuthorised = true;
              tempHrOpsRole = role
              setHropsRole(role)
            }
          })
        }
        setisAuthorised(isAuthorised)
        return tempHrOpsRole;
    }

    const getDocumentMasterList = async () => {
        try {
            let response = {}
            response = await getDocumentMasterListFetchData();
            let documentDetailAndIdMapping = {}
            response.data.forEach(element => {
                documentDetailAndIdMapping[element.id] = element
            })
            setDocumentMasterListAndIdMapping(documentDetailAndIdMapping)
        } catch (error) {
            
        }
    }

    const getStatusMasterList = async () => {
        try {
            let response = {}
            response = await getStatusMasterListFetchData();
            let statusAndIdMapping = {}
            if(response && response.data){
                response.data.forEach(element => {
                    statusAndIdMapping[element.id] = element
                })
                setStatusMasterListAndIdMapping(statusAndIdMapping)
            }
            
        } catch (error) {
            
        }
    }

    const handleSubmit = async () => {
        let validationResponse = await validateData();
        
        if(validationResponse.error){
            setSnackBarDetails({open:true,errorMessage:validationResponse.message,type:'error'})
            return false;
        }
        setIsLoading(true)
        let tempEmployeeDocumentDetails = []
        await Promise.all(employeeDocumentDetails.map(async documentDetail => {
            if(fileData[documentDetail.document_master_id]){
                let fileVersion = documentDetail.version,newStatusId = documentDetail.status_id
                let isAchrived = false;
                if(efilingConstant.status.PENDING_FOR_SUBMISSION === documentDetail.status_id){
                    fileVersion = 1
                } else if(efilingConstant.status.APPROVED === documentDetail.status_id){
                    fileVersion = documentDetail.version + 1
                    isAchrived = true;
                }

                if(hropsRole === efilingConstant.HROPS_ADMIN_ROLE_EMP.HROPS1){
                    newStatusId = efilingConstant.status.PENDING_FOR_APPROVAL_BY_HROPS2
                }

                if(hropsRole === efilingConstant.HROPS_ADMIN_ROLE_EMP.HROPS2){
                    newStatusId = efilingConstant.status.PENDING_FOR_APPROVAL_BY_HROPS3
                }

                if(hropsRole === efilingConstant.HROPS_ADMIN_ROLE_EMP.HROPS3){
                    newStatusId = efilingConstant.status.APPROVED
                }

                let formData = new FormData();
                fileData[documentDetail.document_master_id].map((file,index) => {
                    formData.set('file'+index,file)
                })
                formData.set('employeeNo',documentDetail.employeeno)
                formData.set('module','efiling')
                formData.set('document_alias',documentMasterListAndIdMapping[documentDetail.document_master_id]['document_alias'])
                formData.set('document_ext',efilingConstant.extraApprovalDocumentIds.includes(documentDetail.document_master_id) ? fileData[documentDetail.document_master_id][0]["name"].split('.').pop() : 'pdf')
                formData.set('version',fileVersion)
                let uploadFileResponse = await postUploadedFiles(formData)
                if(uploadFileResponse.success){
                    if(isAchrived){
                        
                        let tempDocumentDetail = JSON.parse(JSON.stringify(documentDetail))
                        tempDocumentDetail.status_id = efilingConstant.status.ARCHIVED
                        tempDocumentDetail.remarks = ''
                        tempEmployeeDocumentDetails.push(tempDocumentDetail)
                    }
                    if( uploadFileResponse && uploadFileResponse.data && uploadFileResponse.data.documentPath){
                        documentDetail.document_path = uploadFileResponse.data.documentPath
                        documentDetail.version = fileVersion
                        documentDetail.status_id = newStatusId
                        documentDetail.date_of_submission = new Date()
                        documentDetail.uploaded_by = loggedInEmployeeDetails['employeeno']
                        if(hropsRole === efilingConstant.HROPS_ADMIN_ROLE_EMP.HROPS1){
                            documentDetail.submitted_by = 'HROPS1'
                        } else if (hropsRole === efilingConstant.HROPS_ADMIN_ROLE_EMP.HROPS2){
                            documentDetail.submitted_by = 'HROPS2'
                        } else if (hropsRole === efilingConstant.HROPS_ADMIN_ROLE_EMP.HROPS3){
                            documentDetail.submitted_by = 'HROPS3'
                        }

                        if(newStatusId === efilingConstant.status.APPROVED){
                            documentDetail.approvedOrRejectedOn = new Date()
                            documentDetail.approvedOrRejectedBy = loggedInEmployeeDetails.legalname + '-' + loggedInEmployeeDetails.employeeno
                        }
                        if(isAchrived){
                            documentDetail.id = ''
                        }
                    }
                }
            }
            tempEmployeeDocumentDetails.push(documentDetail)
        }))
        let insertObjects = [], updateObjects = []
        tempEmployeeDocumentDetails.map(documentDetail => {
            if(documentDetail.id == ''){
                insertObjects.push(documentDetail)
            } else {
                updateObjects.push(documentDetail)
            }
        })

        if(insertObjects.length > 0){
            const insertResponse = await postEmployeeDocumentListFetchData(insertObjects)
        }

        if(updateObjects.length > 0){
            const updateResonse = await updateEmployeeDocumentListFetchData(updateObjects)
        }

        if(updateObjects.length > 0 || insertObjects.length > 0){
            setSnackBarDetails({open:true,errorMessage:'Document Uploaded Successfully',type:'success', positionVertical:'top'})
            sendPendingDocumentSubmittedByHROpsEmail(hropsRole)
        }
        getEmployeeDocumentDetails()
        setFileData({})
        setIsLoading(false)
    }

    const sendPendingDocumentSubmittedByHROpsEmail = async(hropsRole) => {
        if(hropsRole == efilingConstant.HROPS_ADMIN_ROLE_EMP.HROPS3){
            return;
        }
        let emailObject = {}
        if(hropsRole == efilingConstant.HROPS_ADMIN_ROLE_EMP.HROPS1){
            emailObject.emailTemplate = 'PendingDocumentSubmittedByHROps1Email'
        }
        if(hropsRole == efilingConstant.HROPS_ADMIN_ROLE_EMP.HROPS2){
            emailObject.emailTemplate = 'PendingDocumentSubmittedByHROps2Email'
        }
        emailObject.employee = (employeeDocumentDetails && employeeDocumentDetails[0] && employeeDocumentDetails[0]['legalname']) ? employeeDocumentDetails[0]['legalname'] + ' (' + employeeDocumentDetails[0]['employeeno'] + ')': ''
        await sendEmail({emailObject})
    }

    const validateData = async () => {
        let validationResponse = {message: '', error: false}
        return validationResponse
    }

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackBarDetails({open:false,errorMessage:'',type:''});
    }; 

    const handleDownloadAll = () => {
        setIsLoading(true);
        var zip = new JSZip();
        var count = 0;
        var zipFilename = `${employeeDocumentDetails[0]['employeeno']}.zip`;

        var doumentsPaths = []

        employeeDocumentDetails.forEach(element => {
            if(element.document_path){
                doumentsPaths.push(element.document_path)
            }
        })
        if(doumentsPaths.length == 0) {
            setIsLoading(false)
        }
        doumentsPaths.forEach(function(documentPath){
            let documentPathArray = documentPath.split('/')
            var filename = `${documentPathArray[1]}_${documentPathArray[documentPathArray.length - 2]}_${documentPathArray[documentPathArray.length - 1]}`;
            // loading a file and add it in a zip file
            const url = EfilingApiUrl.GET_FILE_URL+btoa(documentPath)
            JSZipUtils.getBinaryContent(url, function (err, data) {
                if(err) {
                    console.log(err)
                    throw err; // or handle the error
                    
                }
                zip.file(filename, data, {binary:true});
                count++;
                if (count == doumentsPaths.length) {
                    zip.generateAsync({type:'blob',compression: "DEFLATE",
                    compressionOptions: {
                        level: 9
                    }}).then(function(content) {
                        saveAs(content, zipFilename);
                        setIsLoading(false);
                    });
                }
            });
        });
        
    }

    const handleNewDocumentChange = (event) => {
        setNewDocumentAdd({ ...newDocumentAdd, [event.target.name]: event.target.value })
    }

    const handleAddNewDocument = () => {
        if(!newDocumentAdd.documentId){
            window.alert("Please select a document to be added")
        } else {
            setEmployeeDocumentDetails(employeeDocumentDetails.concat({
                document_master_id: newDocumentAdd.documentId,
                document_path: "",
                employeeno: employeeDocumentDetails[0]['employeeno'],
                id: "",
                remarks: "",
                status_id: 1,
                uploaded_by: "",
                version: "",
                canBeRemoved: true
            }))
        }

    }

    const handleDocumentRemove = (documentId) => {
        let tempEmployeeDocumentDetails  = []
        employeeDocumentDetails.forEach((t) => {
            if(t.document_master_id !== documentId) {
                tempEmployeeDocumentDetails.push(t)
            }
        })
        setEmployeeDocumentDetails(tempEmployeeDocumentDetails);
    }

    useEffect(()=>{
        getEmployeeDocumentDetails()
    },[showOldVersion])

    const doesDocumentExists = () => {
        let doesDocumentExists = false;
        employeeDocumentDetails.forEach((t) => {
            if(t.document_path) {
                doesDocumentExists =  true;
            }
        })
        return doesDocumentExists;
    }

  return (
      <div className={classes.root}>
        {(!isHavingPageViewAccess && !isAuthorised) 
            ? 
                (isHavingPageViewAccess == null) 
                ? 
                <div className={classes.loader}><img src={logo} alt="loading..." /></div> 
                :
                <div> <h1 style={{margin:'0px'}}>Unauthorised </h1> <br></br> <span>You don't have access to view this page</span> </div> 
            :
        <div>
                {snackBarDetails && snackBarDetails.open ? 
                <Snackbar
                    anchorOrigin={{ vertical:'top', horizontal:'center' }}
                    open={snackBarDetails.open}
                    autoHideDuration={4000}
                    onClose={handleSnackbarClose}
                >
                    <MuiAlert severity={snackBarDetails.type}>{snackBarDetails.errorMessage}</MuiAlert>
                </Snackbar> : (null)
                }
                <Grid container>
                    <Grid> 
                    <HeaderLayout
                        title='Document Modify'
                        icon={<PeopleOutlineTwoTone/>}
                    />
                    </Grid>
                </Grid>
                { ( isLoading || (!isHavingPageViewAccess && !isAuthorised) ) 
                    ? 
                    <div className={classes.loader}><img src={logo} alt="loading..." /></div>
                    : 
                    <Paper className={classes.pageContent}>
                        <Grid container>
                            <Grid item xs={5}> 
                            <h4>
                                Employee : { (employeeDocumentDetails && employeeDocumentDetails[0] && employeeDocumentDetails[0]['legalname']) ? employeeDocumentDetails[0]['legalname'] + ' (' + employeeDocumentDetails[0]['employeeno'] + ')': ''} 
                                <h5 style={{ margin: '0px'}}>
                                Department : { (employeeDocumentDetails && employeeDocumentDetails[0] && employeeDocumentDetails[0]['department']) ? employeeDocumentDetails[0]['department'] : ''} <br></br>
                                Sub-Department : { (employeeDocumentDetails && employeeDocumentDetails[0] && employeeDocumentDetails[0]['function']) ? employeeDocumentDetails[0]['function'] : ''} <br></br>
                                Designation: { (employeeDocumentDetails && employeeDocumentDetails[0] && employeeDocumentDetails[0]['designation']) ? employeeDocumentDetails[0]['designation'] : ''}, Grade : { (employeeDocumentDetails && employeeDocumentDetails[0] && employeeDocumentDetails[0]['grade']) ? employeeDocumentDetails[0]['grade'] : ''} <br></br>
                                Location : { (employeeDocumentDetails && employeeDocumentDetails[0] && employeeDocumentDetails[0]['location_name']) ? employeeDocumentDetails[0]['location_name'] : ''} 
                                </h5>
                            </h4>
                            </Grid>
                            <Grid item xs={3}>
                                {!showOldVersion ? 
                                <FormControl className={classes.formControl}>
                                    <InputLabel id='demo-simple-select-outlined-label'>
                                        List of Documents
                                    </InputLabel>
                                    <Select
                                    labelId='demo-simple-select-outlined-label'
                                    id='demo-simple-select-outlined'
                                    name='documentId'
                                    value={newDocumentAdd.documentId || ''}
                                    onChange={e => handleNewDocumentChange(e)}
                                    >
                                    {(Object.keys(documentMasterListAndIdMapping)).length > 0 &&
                                        Object.keys(documentMasterListAndIdMapping).sort(function(a, b) { return documentMasterListAndIdMapping[a]['document_type'].localeCompare(documentMasterListAndIdMapping[b]['document_type']) }).map((documentId, index) => (
                                            ([efilingConstant.DOCUMENT_APPLICABILITY.NOT_ALL_EMPLOYEES].includes(documentMasterListAndIdMapping[documentId]['applicability']) || [efilingConstant.DOCUMENT_VISIBILITY.HROPS].includes(documentMasterListAndIdMapping[documentId]['visibility'])) 
                                        ?   <MenuItem value={documentId} key={documentId}>
                                                {documentMasterListAndIdMapping[documentId]['document_type']}
                                            </MenuItem> : '' 
                                        ))}
                                    </Select>
                                </FormControl> : '' }
                            </Grid>
                            <Grid item xs={2}>
                                {!showOldVersion ? 
                                <Button
                                    className={classes.addNewDocumentButton}
                                    variant='contained'
                                    size='large'
                                    color='primary'
                                    onClick={handleAddNewDocument}
                                >
                                    Add
                                </Button> : '' }
                            </Grid>
                            <Grid item xs={2}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel id='demo-simple-select-outlined-label'>
                                    
                                    </InputLabel>
                                    <Select
                                    labelId='demo-simple-select-outlined-label'
                                    id='demo-simple-select-outlined'
                                    name='versions'
                                    value={showOldVersion ? 'allVersions' : 'latestVersions'}
                                    onChange={e => setShowOldVersion(!showOldVersion)}
                                    >
                                        <MenuItem value='latestVersions'>Latest Versions</MenuItem>
                                        <MenuItem value='allVersions'>All Versions</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{width: '150'}}><b>Document Name</b></TableCell>
                                        <TableCell><b>Source</b></TableCell>
                                        <TableCell><b>Preview</b></TableCell>
                                        <TableCell><b>Submitted</b></TableCell>
                                        <TableCell><b>Status</b></TableCell>
                                        <TableCell style={{width: '200', textAlign: 'center'}} ><b>Upload</b></TableCell>
                                        <TableCell style={{ textAlign: 'center'}}><b>Action</b></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                { ( employeeDocumentDetails && employeeDocumentDetails.length > 0 ) ? employeeDocumentDetails.map((element,key)=>(
                                    <TableRow key={key}>
                                        <TableCell >{ (documentMasterListAndIdMapping && documentMasterListAndIdMapping[element.document_master_id]) ? documentMasterListAndIdMapping[element.document_master_id]['document_type'] : ''} {showOldVersion ? (element.document_path.split('.')[0]).split('/').pop() : ''}</TableCell>
                                        <TableCell> {element.module_id && efilingConstant.MODULE_MASTER[element.module_id] ? efilingConstant.MODULE_MASTER[element.module_id] : 'Efiling'} </TableCell>
                                        <TableCell>{element.document_path ? (['html','htm','xlsx','docx','xlsm','xlsb','xls'].includes(element.document_path.split('.').pop()) ? <a target='blank' href={EfilingApiUrl.GET_FILE_URL_1+btoa(element.document_path)+'.'+element.document_path.split('.').pop()} className={classes.anchor}>
                                                    VIEW
                                                </a> : <a target='_blank' href={EfilingApiUrl.GET_FILE_URL_1+btoa(element.document_path)} className={classes.anchor}>
                                                    VIEW
                                                </a>)  : '---'}</TableCell>
                                        <TableCell>
                                            {[efilingConstant.status.PENDING_FOR_SUBMISSION,
                                                efilingConstant.status.REJECTED].includes(element.status_id) ? 
                                                'No' 
                                                : 
                                                ( [efilingConstant.status.PENDING_FOR_APPROVAL,
                                                    efilingConstant.status.APPROVED, 
                                                    efilingConstant.status.PENDING_FOR_APPROVAL_BY_HROPS2,
                                                    efilingConstant.status.PENDING_FOR_APPROVAL_BY_HROPS3].includes(element.status_id) ? 'Yes' : [efilingConstant.status.ARCHIVED].includes(element.status_id) ? '--' : '' )
                                                }
                                            </TableCell>
                                        <TableCell>{statusMasterListAndIdMapping[element.status_id] ? statusMasterListAndIdMapping[element.status_id]['status_text'] : '---'}</TableCell>

                                    <TableCell style={{width: '200', textAlign: 'center'}}>
                                        {(!(showOldVersion || element.employeeStatus == 'Inactive'))? 
                                            <FormControl className={classes.formControl}>
                                                <input
                                                    accept="application/pdf, image/*, text/html"
                                                    className={classes.input}
                                                    id="contained-button-file"
                                                    type="file"
                                                    name={element.document_master_id}
                                                    onChange={onFileChange}
                                                    onClick={onInputClick}
                                                    title="Select a file"
                                                />
                                                <label htmlFor="contained-button-file">
                                                    {fileData[element.document_master_id] && ["20","21"].includes(element.document_master_id) ? null :
                                                    <><Publish className = {classes.uploadButton} onClick={e => onFileChangeButtonClick(element.document_master_id)}></Publish>
                                                    {fileData[element.document_master_id] ? <div>(+)</div> : ''}</>
                                                    }
                                                </label>
                                                { fileData[element.document_master_id] ? 
                                                <div>
                                                    {fileData[element.document_master_id].map((file,index) => (
                                                        
                                                        <div align="right" key={index}>
                                                            {file.name}
                                                            <span onClick={e=>handleSelectedFile(e, element.document_master_id,index)}>
                                                                <DeleteForeverOutlinedIcon className={classes.icon} />
                                                            </span>
                                                        </div>
                                                    ))}
                                                </div>
                                                : ''
                                                }
                                            </FormControl> : '---' }
                                        </TableCell> 
                                        <TableCell> { (element.canBeRemoved && ( documentMasterListAndIdMapping &&
                                                        documentMasterListAndIdMapping[element.document_master_id] &&
                                                        ( [efilingConstant.DOCUMENT_APPLICABILITY.NOT_ALL_EMPLOYEES].includes(documentMasterListAndIdMapping[element.document_master_id]['applicability']) || [efilingConstant.DOCUMENT_VISIBILITY.HROPS].includes(documentMasterListAndIdMapping[element.document_master_id]['visibility']) ) ) )
                                            ? ( <Button className={classes.removeDocumentButton} onClick={e => handleDocumentRemove(element.document_master_id)}> Remove </Button> ) 
                                            : '---' } </TableCell>
                                    </TableRow>
                                )) : (
                                    <TableRow>
                                    <TableCell><b>No result found</b></TableCell>
                                    </TableRow>
                                ) } 
                                </TableBody>
                                
                            </Table>
                        </TableContainer>
                    </Paper>
                }
                    <Grid
                        container
                        spacing={3}
                        className={classes.marginTop}
                        direction='row'
                        alignItems='center'
                    >
                        <Grid item>
                            <Button
                                className={classes.button}
                                variant='contained'
                                size='large'
                                color='primary'
                                onClick={handleSubmit}
                                disabled={!isHavingPageEditAccess}
                            >
                                Submit
                            </Button>
                            {doesDocumentExists() ? 
                            <Button
                                className={classes.button}
                                variant='contained'
                                size='large'
                                color='primary'
                                onClick={handleDownloadAll}
                            >
                                Download All
                            </Button> : '' }
                        </Grid>
                    </Grid>
            </div>    
        }
      </div>
  )
}