/**
 * HistoryAll component is using style from this place
 */
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  cardHeader: {
    backgroundColor: '#485ED5',
    background: 'linear-gradient(60deg, #3f51b5, #4e66e6)',
    width: '100%',
    minHeight: '50px',
    borderRadius: "5px"
  },
  headerText: {
    color: '#FFFFFF',
    marginTop: '10px',
    minHeight: 'auto',
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: 'bold',
    marginBottom: '3px',
    textDecoration: 'none',
    textAlign: 'center'
  },
  root: {
    padding: theme.spacing(0)
  },
  cardBody: {
    width: '100%',
    minHeight: "600px",
    backgroundColor: "#F1F1F4",
  },
  filterBody: {
    padding: "10px"
  },
  maiBody: {
    padding: "10px"
  },
  gridBody: {
    padding: "10px"
  }
}))
