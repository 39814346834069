import React, { useState,useEffect } from 'react';
import { useStyles } from './style';
import { getDocumentMasterListFetchData, uploadFilestoGoogleDrive, postBulkDownload, getLoggedInEmployeeDetailFetchData, getStatusMasterListFetchData, postUploadedFiles, getHropsListFetchData } from '../fetchData'
import HeaderLayout from '../HeaderLayout'
import { TableCell, TableContainer, TableHead, TableRow, Table, TableBody, Select, MenuItem, Button, Grid, FormControl, TablePagination, TextField, Snackbar, InputLabel } from '@material-ui/core'
import PeopleOutlineTwoTone from '@material-ui/icons/PeopleOutlineTwoTone'
import {Paper, Fab} from '@material-ui/core'
import SaveIcon from '@material-ui/icons/Save'
import CloudDownload from '@material-ui/icons/CloudDownload'
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined'
import logo from '../../../utils/load.gif'
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate"
import { employeeTaggingUrl,getEmployeeDetailUrl } from '../../../utils/CommonUrl'
import { efilingConstant } from '../../../constants/EfilingConstant'
import { EfilingApiUrl } from '../../../utils/CommonUrl'
import MuiAlert from '@material-ui/lab/Alert/Alert';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText'
import qs from 'qs'
import JSZip from 'jszip'
import JSZipUtils from 'jszip-utils'
import { saveAs } from 'save-as'
import Publish from '@material-ui/icons/Publish'
import { getMenuSubmenuRightsDetail } from '../../../utils/authFunction';
import { pageURLConfig, menuSubmenuPageCategory } from '../../../constants/CommonConstant';
const { moduleName, controllerName, actionName } = pageURLConfig.EFILING_INDEX_MODULE_MASTER_CONFIG
const { ADMIN_CATEGORY } = menuSubmenuPageCategory


export default function MultipleDownload(props) {
    const [isLoading, setIsLoading] = useState(false)
    const [employeeDocumentDetails, setEmployeeDocumentDetails] = useState([])
    const [loggedInEmployeeDetails, setLoggedInEmployeeDetails] = useState({})
    const [documentMasterListAndIdMapping, setDocumentMasterListAndIdMapping] = useState([])
    const [statusMasterListAndIdMapping, setStatusMasterListAndIdMapping] = useState([])
    const [fileData, setFileData] = useState({})
    const [currentFileEmployeeDocumentRowId, setCurrentFileEmployeeDocumentRowId] = useState(0)
    const classes = useStyles();
    const [snackBarDetails, setSnackBarDetails] = useState({open:false,errorMessage:'',type:''})
    const [hropsRole, setHropsRole] = useState('')
    const [isAuthorised, setisAuthorised] = useState(true)
    const [documentDownloadList, setDocumentDownloadList] = useState({documentId:[]})
    const [employeeCodes,setEmployeeCodes] = useState('')
    const [isHavingPageAddAccess, setIsHavingPageAddAccess] = useState(false)
    const [isHavingPageEditAccess, setIsHavingPageEditAccess] = useState(false)
    const [isHavingPageViewAccess, setIsHavingPageViewAccess] = useState(null)

    useEffect(()=>{
        checkEmployeeAuthorization()
        getDocumentMasterList()
        getStatusMasterList()
        setPageRights()
    },[])

    useEffect(()=>{

    },[employeeDocumentDetails])

    useEffect(()=>{

    },[isLoading])

    const setPageRights = async () => {
        const params = { pageCategory: ADMIN_CATEGORY, moduleName, controllerName, actionName}
        const rightsObj = await getMenuSubmenuRightsDetail(params)
        const { 
            isUserSuperAdmin,
            isUserGroupAdmin,
            allEmployeeAddRights,
            allEmployeeEditRights, 
            allEmployeeViewRights 
       } = rightsObj
       const addAccess = isUserSuperAdmin || (isUserGroupAdmin && allEmployeeAddRights) 
       const editAccess =  isUserSuperAdmin || (isUserGroupAdmin && allEmployeeEditRights)
       const viewAccess =  isUserSuperAdmin || (isUserGroupAdmin && allEmployeeViewRights)
        
        setIsHavingPageAddAccess(addAccess)
        setIsHavingPageEditAccess(editAccess)
        setIsHavingPageViewAccess(viewAccess)
   }

    const checkEmployeeAuthorization = async () => {
        try {
            setIsLoading(true)
            let loggedInEmployeeDetailsResponse = await getLoggedInEmployeeDetailFetchData()
            setLoggedInEmployeeDetails(loggedInEmployeeDetailsResponse.data[0])
            let tempHrOpsList = await getHrOpsList()
            await checkAuthorised(tempHrOpsList,loggedInEmployeeDetailsResponse.data[0])
            setIsLoading(false)
        } catch (error) {
            
        }
    }

    const getHrOpsList = async () => {
        try {
          let response = {}
          response = await getHropsListFetchData()
          if(response && response.data){
            let tempHropsList = {}
            response.data.map(element => {
                if(tempHropsList && tempHropsList[element.role]){
                  tempHropsList[element.role].push(element.employeeno)
                } else {
                  tempHropsList[element.role] = []
                  tempHropsList[element.role].push(element.employeeno)
                }
            })
            return tempHropsList
          }
        } catch (error) {
          
        }
      }

    const checkAuthorised = async (tempHrOpsList,tempLoggedInEmployeeDetails) => {
        let isAuthorised = false;
        let tempHrOpsRole = ''
        if(tempLoggedInEmployeeDetails && tempHrOpsList) {
          Object.keys(tempHrOpsList).map(role => {
            if(tempHrOpsList[role].includes(tempLoggedInEmployeeDetails['employeeno'])){
              isAuthorised = true;
              tempHrOpsRole = role
              setHropsRole(role)
            }
          })
        }
        setisAuthorised(isAuthorised)
        return tempHrOpsRole;
    }

    const getDocumentMasterList = async () => {
        try {
            let response = {}
            response = await getDocumentMasterListFetchData();
            let documentDetailAndIdMapping = {0:{document_type:'ALL'}}
            response.data.forEach(element => {
                documentDetailAndIdMapping[element.id] = element
            })
            setDocumentMasterListAndIdMapping(documentDetailAndIdMapping)
        } catch (error) {
            
        }
    }

    const getStatusMasterList = async () => {
        try {
            let response = {}
            response = await getStatusMasterListFetchData();
            let statusAndIdMapping = {}
            if(response && response.data){
                response.data.forEach(element => {
                    statusAndIdMapping[element.id] = element
                })
                setStatusMasterListAndIdMapping(statusAndIdMapping)
            }
            
        } catch (error) {
            
        }
    }

    const validateData = async ({employeeCodes,documentDownloadList}) => {
        let validationResponse = {message: '', error: false}
        if( !(documentDownloadList && documentDownloadList.documentId && documentDownloadList.documentId.length > 0 )){
            validationResponse.message = 'Please select a document to download'
            validationResponse.error = true
            return validationResponse
        }
        if(employeeCodes.trim() === ''){
            validationResponse.message = 'Please enter employee codes'
            validationResponse.error = true
            return validationResponse
        }
        return validationResponse
    }

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackBarDetails({open:false,errorMessage:'',type:''});
    };

    const handleDownloadAll = async () => {
        setIsLoading(true)
        const validateResponse = await validateData({employeeCodes,documentDownloadList})

        if(!validateResponse.error){
            let payload_document_master_id = documentDownloadList.documentId
            if(documentDownloadList.documentId.includes('0')){
                payload_document_master_id = Object.keys(documentMasterListAndIdMapping)
            }
            var data = {employeecode:employeeCodes.split(',').map(function(item) {
                return item.trim();
              }),document_master_id:payload_document_master_id}
            const response = await postBulkDownload(data)
            if(response.success){
                setSnackBarDetails({open:true, errorMessage:response.message, type: 'success'})
            } else {
                setSnackBarDetails({open:true, errorMessage: response.message, type: 'error'})
            }

        } else {
            setSnackBarDetails({open:true, errorMessage:validateResponse.message, type: 'error'})
        }
        setIsLoading(false)
    }

    const handleNewDocumentChange = (event) => {
        setDocumentDownloadList({ ...documentDownloadList, [event.target.name]: event.target.value })
    }
    
    const handleEmployeeCodes = (event) => {
        setEmployeeCodes(event.target.value )
    }

    const renderValueForDropDown = (selected) => {
        selected.sort()
        var renderValue = []
        renderValue = selected.map(documentId => {
            return documentMasterListAndIdMapping[documentId]['document_type']
        })
        return renderValue.join(', ')
    }
    
  return (
      <div>
        {(!isHavingPageViewAccess && !isAuthorised) 
        ? 
            (isHavingPageViewAccess == null) 
            ? 
            <div className={classes.loader}><img src={logo} alt="loading..." /></div> 
            :
            <div> <h1 style={{margin:'0px'}}>Unauthorised </h1> <br></br> <span>You don't have access to view this page</span> </div> 
        :   
        <div>
                {snackBarDetails && snackBarDetails.open ? 
                <Snackbar
                    anchorOrigin={{ vertical:'top', horizontal:'center' }}
                    open={snackBarDetails.open}
                    autoHideDuration={4000}
                    onClose={handleSnackbarClose}
                >
                    <MuiAlert severity={snackBarDetails.type}>{snackBarDetails.errorMessage}</MuiAlert>
                </Snackbar> : (null)
                }
                <Grid container>
                    <Grid> 
                    <HeaderLayout
                        title='Multiple Download'
                        icon={<PeopleOutlineTwoTone/>}
                    />
                    </Grid>
                </Grid>
                { ( isLoading || (!isHavingPageViewAccess && !isAuthorised) )
                    ? 
                    <div className={classes.loader}><img src={logo} alt="loading..." /></div>
                    : 
                    <Paper className={classes.pageContent}>
                        <Grid container>
                            <Grid item xs={3} >
                                <TextField
                                    id='outlined-basic'
                                    label='Employee Codes (Comma Seperated)'
                                    placeholder='Employee Codes (Comma Seperated)'
                                    name='employeeCodes'
                                    value={employeeCodes || ''}
                                    onChange={e => handleEmployeeCodes(e)}
                                    className={classes.textField}
                                />
                                <FormControl className={classes.formControl}>
                                    <InputLabel id='demo-simple-select-outlined-label'>
                                        List of Documents
                                    </InputLabel>
                                    <Select
                                    labelId='demo-simple-select-outlined-label'
                                    id='demo-simple-select-outlined'
                                    name='documentId'
                                    multiple
                                    value={documentDownloadList.documentId || []}
                                    onChange={e => handleNewDocumentChange(e)}
                                    renderValue={(selected) => renderValueForDropDown(selected)}
                                    >
                                    {(Object.keys(documentMasterListAndIdMapping)).length > 0 &&
                                        Object.keys(documentMasterListAndIdMapping).map((documentId, index) => (
                                            <MenuItem value={documentId} key={documentId}>
                                                <Checkbox checked={documentDownloadList.documentId.includes(documentId)} />
                                                <ListItemText primary={documentMasterListAndIdMapping[documentId]['document_type']} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            spacing={3}
                            className={classes.marginTop}
                            direction='row'
                            alignItems='center'
                        >
                            <Grid item>
                                <Button
                                    className={classes.button}
                                    variant='contained'
                                    size='large'
                                    color='primary'
                                    onClick={handleDownloadAll}
                                >
                                    Download All
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                }
            </div>    
        }
      </div>
  )
}