import React,{ Component } from 'react'
import {AllEmployeeTagging} from '../../pages/EmployeeTagging/AllEmployeeTagging'
import {EmployeeTaggingSummary} from '../../pages/EmployeeTagging/EmployeeTaggingSummary'
export default class EmployeeTaggingComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
         path : props.computedMatch.params.type
    };
}
  getPage(path){
    switch(path){
      case "Summary":
        return EmployeeTaggingSummary
      default:
        return AllEmployeeTagging
    }
  }
  render (){
    const Component = this.getPage(this.state.path)
    const {empDetails} = this.props
    return (
      <div>
        {["504770","504776","504778","504779","504773","504973","507399","508234"].includes(empDetails.employeeno) ? 
        <Component {...this.props}/> : (window.location = process.env.REACT_APP_LOGIN_URL)}
      </div>
    )
  }
}
