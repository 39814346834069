import React from "react"

const SurveyTemplate = ({
    surveyName = '',
    description = '',
    message = ''
}) => {
    return (
        <div className="sd-root-modern">
                    <form>
                        <div className="sv_custom_header"></div>
                        <div className="sd-container-modern">
                            <div className="sd-title sd-container-modern__title">
                                <div className="sd-header__text">
                                    <h3 className="sd-title" aria-label="{surveyName}" role="button">
                                        <span className="sv-string-viewer">{surveyName}</span>
                                    </h3>
                                    <h5 className="sd-description">
                                        <span className="sv-string-viewer">{description}</span>
                                    </h5>
                                </div>
                                <div className="sd-hidden"></div>
                            </div>
                            <div className="sv-components-column sv-components-column--expandable sd-body sd-completedpage">
                                <h3>{message}</h3>
                            </div>
                        </div>
                    </form>
                </div>
    )
}

export { SurveyTemplate }