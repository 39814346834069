import Timeline, {
  TimelineHeaders,
  SidebarHeader,
  DateHeader,
} from 'react-calendar-timeline';
import moment from 'moment';
import { Component } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import fetch from 'node-fetch';
import React from 'react';
import 'react-calendar-timeline/lib/Timeline.css';
import './Calendar.css';
import Cookie from 'js-cookie';
import config from '../../config';
import Header from '../Header/Header';
import SkipNextRoundedIcon from '@material-ui/icons/SkipNextRounded';
import SkipPreviousRoundedIcon from '@material-ui/icons/SkipPreviousRounded';

const dataDict = {
  secondsInADay: 86400000,
};
class Calender extends Component {
  constructor(props) {
    super(props);
    let visibleTimeStart = moment()
      .startOf('day')
      .add(7, 'hour')
      .valueOf();
    let visibleTimeEnd = moment()
      .startOf('day')
      .add(1, 'day')
      .subtract(3, 'hour')
      .valueOf();
    const rooms = [{ id: '', title: '' }];
    const schedules = [
      {
        id: '',
        group: '',
        startTime: '',
        endTime: '',
      },
    ];
    this.state = {
      visibleTimeStart,
      visibleTimeEnd,
      schedules,
      rooms,
      loading: false,
    };
  }
  //to get the list of the rooms
  async groupFetch() {
    let rooms = [];
    const response = await fetch(`http://${config.hostName}/rooms/list/`, {
      headers: {
        ULC: `${Cookie.get('ULC')}`,
      },
    });
    const data = await response.json();
    rooms = data.map((room) => {
      const obj = {
        key: room.id,
        id: room.id,
        title: room.name,
      };
      return obj;
    });
    return rooms;
  }
  //scheduleFetch() to get the schedule of that particular day
  async scheduleFetch(rooms) {
    let schedules = [];
    let start = moment(this.state.visibleTimeStart)
      .format()
      .substring(0, 19);
    let end = moment(this.state.visibleTimeEnd)
      .format()
      .substring(0, 19);
    const response = await fetch(
      `http://${config.hostName}/rooms/busy?minTime=${start}&maxTime=${end}`,
      {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          ULC: `${Cookie.get('ULC')}`,
        },
      }
    );
    const data = await response.json();
    schedules = data.map((data) => {
      const obj = {
        key:
          moment(data.end).format() + moment(data.start).format() + data.roomId,
        id:
          moment(data.end).format() + moment(data.start).format() + data.roomId,
        group: data.roomId,
        start_time: moment(data.start),
        end_time: moment(data.end),
      };
      return obj;
    });
    this.setState({ rooms: rooms, schedules: schedules, loading: false });
  }

  //for the first time
  async componentDidMount() {
    const rooms = await this.groupFetch();
    this.scheduleFetch(rooms);
  }
  async componentDidUpdate() {
    if (this.state.loading === true) {
      this.scheduleFetch(this.state.rooms); // to get the data when previous or next button is clicked
    }
  }
  onPrevClick = () => {
    this.setState({
      visibleTimeStart: this.state.visibleTimeStart - dataDict.secondsInADay,
      visibleTimeEnd: this.state.visibleTimeEnd - dataDict.secondsInADay,
      loading: true,
    });
  };
  onNextClick = () => {
    this.setState({
      visibleTimeStart: this.state.visibleTimeStart + dataDict.secondsInADay,
      visibleTimeEnd: this.state.visibleTimeEnd + dataDict.secondsInADay,
      loading: true,
    });
  };

  render() {
    const { visibleTimeStart, visibleTimeEnd } = this.state;
    if (this.state.loading === true) {
      return (
        <div>
          <Header />
          <LinearProgress />
          <LinearProgress color='secondary' />
        </div>
      );
    } else {
      return (
        <React.Fragment>
          <Header />
          <div>
            <Timeline
              groups={this.state.rooms}
              items={this.state.schedules}
              visibleTimeStart={visibleTimeStart}
              visibleTimeEnd={visibleTimeEnd}
              canMove={false}>
              <TimelineHeaders className='sticky'>
                <SidebarHeader>
                  {({ getRootProps }) => {
                    return (
                      <div {...getRootProps()}>
                        <SkipPreviousRoundedIcon
                          style={{ fontSize: 50, paddingRight: 10 }}
                          onClick={this.onPrevClick}></SkipPreviousRoundedIcon>
                        <SkipNextRoundedIcon
                          style={{ fontSize: 50, paddingLeft: 10 }}
                          onClick={this.onNextClick}></SkipNextRoundedIcon>
                      </div>
                    );
                  }}
                </SidebarHeader>
                <DateHeader unit='primaryHeader' />
                <DateHeader />
              </TimelineHeaders>
            </Timeline>
          </div>
        </React.Fragment>
      );
    }
  }
}

export default Calender;
