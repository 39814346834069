
const getRelationListUrl = () => {
    return `${process.env.REACT_APP_HOST_NAME}/api/hrms/insurance/nomineeRelationList`
}

const getEmployeeNomineeDetailUrl = () => {
    return `${process.env.REACT_APP_HOST_NAME}/api/hrms/insurance/nomineeDetail`
}

const getNomineeFinalSubmitUrl = () => {
    return `${process.env.REACT_APP_HOST_NAME}/api/hrms/insurance/nomineeFinalSubmit`
}

const getSendEmailForNomineeUrl = () => {
    return `${process.env.REACT_APP_HOST_NAME}/api/hrms/insurance/sendEmailForNominee`
}

export {
    getRelationListUrl,
    getEmployeeNomineeDetailUrl,
    getNomineeFinalSubmitUrl,
    getSendEmailForNomineeUrl
}