import React,{ Component } from 'react'
import {OrgChart} from '../../pages/OrgChart/OrganizationChart'

export default class OrganizationChartComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
         path : props.computedMatch.params.type
    };
   }
  getPage(path){
    switch(path){
      case "tech":
        return OrgChart
      default:
        return OrgChart
    }
  }
  render (){
    const Component = this.getPage(this.state.path)
    return (
      <div>
        <Component {...this.props}/>
      </div>
    )
  }
}
