import React from 'react'
import TablePagination from '@material-ui/core/TablePagination'

const PendingDetailPagination = props => {
  const {
    page,
    pages,
    rows,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage
  } = props

  return (
    <TablePagination
      component='div'
      page={page}
      rowsPerPageOptions={pages}
      rowsPerPage={rowsPerPage}
      count={rows.length}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  )
}

export default PendingDetailPagination
