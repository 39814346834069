import axios from 'axios'
import url from 'url'

import { EfilingApiUrl } from '../../utils/CommonUrl'
import { getAxiosConfig, getRequestHeader } from '../../utils/CommonFunction'
import { efilingConstant } from '../../constants/EfilingConstant';

let axiosConfig = getAxiosConfig({ withCredentials:true });
axiosConfig.headers = getRequestHeader()

export const getDocumentMasterListFetchData = () => {
    const { GET_DOCUMENT_MASTER_LIST } = EfilingApiUrl
    return axios.get(`${GET_DOCUMENT_MASTER_LIST}`)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}


export const getStatusMasterListFetchData = () => {
    const { GET_STATUS_MASTER_LIST } = EfilingApiUrl
    return axios.get(`${GET_STATUS_MASTER_LIST}`)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}

export const getEmployeeDocumentDetailsFetchData = (dataObj) => {
    const { GET_EMPLOYEE_DOCUMENT_DETAILS } = EfilingApiUrl
    return axios.get(`${GET_EMPLOYEE_DOCUMENT_DETAILS}`,{params:dataObj})
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}


export const postUploadedFiles = (formData) => {
    // const formData = new FormData()
    // formData.set('file', dataObj.file)
    const { UPLOAD_EFILING_FILE } = EfilingApiUrl
    return axios.post(`${UPLOAD_EFILING_FILE}`, formData, { "Content-Type": "multipart/form-data" })
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}

export const postEmployeeDocumentListFetchData = (insertObjects) => {

    const { POST_EMPLOYEE_DOCUMENT_LIST } = EfilingApiUrl
    return axios.post(`${POST_EMPLOYEE_DOCUMENT_LIST}`, insertObjects)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}

export const updateEmployeeDocumentListFetchData = (updateObjects) => {
    const { UPDATE_EMPLOYEE_DOCUMENT_LIST } = EfilingApiUrl
    return axios.post(`${UPDATE_EMPLOYEE_DOCUMENT_LIST}`, updateObjects)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}


export const getLoggedInEmployeeDetailFetchData = () => {
    const { GET_LOGGED_IN_EMPLOYEE_DETAILS } = EfilingApiUrl
    return axios.get(`${GET_LOGGED_IN_EMPLOYEE_DETAILS}`)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}

export const getPendingDocumentDetailsFetchData = (dataObj) => {
    const { GET_PENDING_DOCUMENT_DETAILS } = EfilingApiUrl
    return axios.get(`${GET_PENDING_DOCUMENT_DETAILS}`,{params:dataObj})
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}


export const updatePendingDocuments = (dataObj) => {
    const { UPDATE_PENDING_DOCUMENTS } = EfilingApiUrl
    return axios.post(`${UPDATE_PENDING_DOCUMENTS}`, dataObj)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}

export const getHropsListFetchData = () => {
    const { GET_HROPS_LIST } = EfilingApiUrl
    return axios.get(`${GET_HROPS_LIST}`)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}


export const getDepartmentListFetchData = () => {
    const { GET_DEPARTMENT_LIST } = EfilingApiUrl
    return axios.get(`${GET_DEPARTMENT_LIST}`)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}

export const getSubDepartmentFetchData = (dataObj) => {
    const { GET_SUBDEPARTMENT_LIST } = EfilingApiUrl
    return axios.get(`${GET_SUBDEPARTMENT_LIST}`, {params:dataObj})
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}


export const getGradeListFetchData = (dataObj) => {
    const { GET_GRADE_LIST } = EfilingApiUrl
    return axios.get(`${GET_GRADE_LIST}`, {params:dataObj})
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}


export const getLocationListFetchData = (dataObj) => {
    const { GET_LOCATION_LIST } = EfilingApiUrl
    return axios.get(`${GET_LOCATION_LIST}`, {params:dataObj})
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}

export const getAllEmployeeDocumentDetails = (dataObj) => {
    const { GET_ALL_EMPLOYEE_DOCUMENT_LIST } = EfilingApiUrl
    return axios.post(`${GET_ALL_EMPLOYEE_DOCUMENT_LIST}`, dataObj)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}

export const getEmployeeDocumentDetailsForBulkUploadFetchData = () => {
    const { EMPLOYEE_BULK_UPLOAD_DOCUMENT_DETAILS } = EfilingApiUrl
    return axios.get(`${EMPLOYEE_BULK_UPLOAD_DOCUMENT_DETAILS}`)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}

export const getHrbpListFetchData = () => {
    const { GET_HRBP_LIST } = EfilingApiUrl
    return axios.get(`${GET_HRBP_LIST}`)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}

export const getHrbpEmployeeDocumentDetails = (dataObj) => {
    const { GET_HRBP_EMPLOYEE_DOCUMENT_LIST } = EfilingApiUrl
    return axios.post(`${GET_HRBP_EMPLOYEE_DOCUMENT_LIST}`, dataObj)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}


export const sendEmail = (dataObj) => {
    const { SEND_EMAIL } = EfilingApiUrl
    return axios.post(`${SEND_EMAIL}`, dataObj)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}



export const postDocumentMasterConfigFetchData = (dataObj) => {
    const { POST_DOCUMENT_MASTER_CONFIG } = EfilingApiUrl
    return axios.post(`${POST_DOCUMENT_MASTER_CONFIG}`, dataObj)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}

export const getModuleMasterListFetchData = () => {
    const { GET_MODULE_MASTER_LIST } = EfilingApiUrl
    return axios.get(`${GET_MODULE_MASTER_LIST}`)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}



export const postModuleMasterConfigFetchData = (dataObj) => {
    const { POST_MODULE_MASTER_CONFIG } = EfilingApiUrl
    return axios.post(`${POST_MODULE_MASTER_CONFIG}`, dataObj)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}


export const uploadFilestoGoogleDrive = (dataObj) => {
    const { UPLOAD_FILES_TO_GOOGLE_DRIVE } = EfilingApiUrl
    return axios.post(`${UPLOAD_FILES_TO_GOOGLE_DRIVE}`, dataObj)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}


export const postBulkDownload = (dataObj) => {
    const { POST_BULK_DOWNLOAD } = EfilingApiUrl
    return axios.post(`${POST_BULK_DOWNLOAD}`, dataObj)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}

export const postDownloadAllFiles = (dataObj) => {
    const { POST_DOWNLOAD_ALL_FILES } = EfilingApiUrl
    return axios.post(`${POST_DOWNLOAD_ALL_FILES}`, dataObj)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}

export const postPendingDocumentAlert = (dataObj) => {
    const { POST_PENDING_DOCUMENT_ALERT } = EfilingApiUrl
    return axios.post(`${POST_PENDING_DOCUMENT_ALERT}`, dataObj)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}

export const postPendingDocumentStatusReport = (dataObj) => {
    const { DOWNLOAD_PENDING_DOCUMENT_REPORT } = EfilingApiUrl
    return axios.post(`${DOWNLOAD_PENDING_DOCUMENT_REPORT}`, dataObj)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}

export const empAutocompleteListFetchData = (dataObj) => {
    const { EMP_AUTOCOMPLETE_LIST_URL } = EfilingApiUrl
    return axios.post(`${EMP_AUTOCOMPLETE_LIST_URL}`,dataObj)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}

