import axios from 'axios'

import { commonApiUrl } from '../../../utils/CommonUrl'
import { getAxiosConfig, getRequestHeader } from '../../../utils/CommonFunction'

let axiosConfig = getAxiosConfig({ withCredentials:true });
axiosConfig.headers = getRequestHeader()


export const getDepartmentListFetchData = () => {
  const { GET_DEPARTMENT_LIST } = commonApiUrl
  return axios.get(`${GET_DEPARTMENT_LIST}`)
          .then(response => {
              const { data } = response || {}
              return data
          })
          .catch((err) => {
              const { 
                  response: {
                      data
                  } = {}
              } = err
              return data
          })
}

export const getSubDepartmentFetchData = (dataObj) => {
  const { GET_SUBDEPARTMENT_LIST } = commonApiUrl
  return axios.get(`${GET_SUBDEPARTMENT_LIST}`, {params:dataObj})
          .then(response => {
              const { data } = response || {}
              return data
          })
          .catch((err) => {
              const { 
                  response: {
                      data
                  } = {}
              } = err
              return data
          })
}


export const getGradeListFetchData = (dataObj) => {
  const { GET_GRADE_LIST } = commonApiUrl
  return axios.get(`${GET_GRADE_LIST}`, {params:dataObj})
          .then(response => {
              const { data } = response || {}
              return data
          })
          .catch((err) => {
              const { 
                  response: {
                      data
                  } = {}
              } = err
              return data
          })
}

export const getDesignationListFetchData = (dataObj) => {
  const { GET_DESIGNATION_LIST } = commonApiUrl
  return axios.get(`${GET_DESIGNATION_LIST}`, {params:dataObj})
          .then(response => {
              const { data } = response || {}
              return data
          })
          .catch((err) => {
              const { 
                  response: {
                      data
                  } = {}
              } = err
              return data
          })
}


export const getLocationListFetchData = (dataObj) => {
  const { GET_LOCATION_LIST } = commonApiUrl
  return axios.get(`${GET_LOCATION_LIST}`, {params:dataObj})
          .then(response => {
              const { data } = response || {}
              return data
          })
          .catch((err) => {
              const { 
                  response: {
                      data
                  } = {}
              } = err
              return data
          })
}

export const getUniqueGradeListFetchData = () => {
    const { GET_UNIQUE_GRADE_LIST } = commonApiUrl
    return axios.post(`${GET_UNIQUE_GRADE_LIST}`)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}

export const getDesignationListByGradeNameFetchData = (dataObj) => {
    const { GET_DESIGNATION_BY_GRADE_NAME_LIST } = commonApiUrl
    return axios.post(`${GET_DESIGNATION_BY_GRADE_NAME_LIST}`, dataObj)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}
