import React, { Component} from 'react'
import PendingDueList from "../../pages/Probation/PendingDueList/index"
import { ProbationForm } from '../../pages/Probation/ProbationForm';
import ProbationStatusView from '../../pages/Probation/ProbationStatusView';
import { ProbationFormCHRO } from '../../pages/Probation/ProbationFormCHRO';
import ReportHrbp from '../../pages/Probation/ReportHrbp';
import qs from "qs";
import { probationConstant } from '../../constants/ProbationConstant';

export default class Probation extends Component {
  constructor(props) {
    super(props);
    let stateObj = {
         path : props.computedMatch.params.type,
         role: props.computedMatch.params.role,
         isFormOpened: false,
    };
    const {empNo} = qs.parse(props.location.search, { ignoreQueryPrefix: true , allowEmptyKeys: true });
    if(empNo) {
      stateObj = {...stateObj, isFormOpened: true}
    }
    this.state = stateObj
  }
  getPage(path){
    const {isFormOpened, role} = this.state
    switch(path){
      case "pending-due-list":
        if(isFormOpened) {
          return ProbationForm
        } else {
          return PendingDueList
        }
      case "pending-due-list-hrbp":
        if(isFormOpened) {
          return ProbationForm
        } else {
          return PendingDueList
        }
      case "pending-confirmation-letter":
        if(isFormOpened) {
          return ProbationFormCHRO
        } else {
          return PendingDueList
        }
      case "pending-termination-letter":
        if(isFormOpened) {
          return role == probationConstant.userRoleToURLParamsMapping.HR_HEAD ? ProbationFormCHRO : ProbationForm
        } else {
          return PendingDueList
        }
      case "report-hrbp":
        if(isFormOpened) {
          return ProbationForm
        } else {
          return ReportHrbp
        }
      case "status-view":
        return ProbationStatusView
      case "closed-form-list":
        if(isFormOpened) {
          return ProbationForm
        } else {
          return PendingDueList
        }
      default:
        return null;
    }
  }
  render (){
    const Component = this.getPage(this.state.path)

    return (
      <div>
        { Component ? <Component {...this.props}/> : null }
      </div>
    )
  }
}
