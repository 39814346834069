import { makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        color: 'black',
        padding: theme.spacing(0)
    },
    control: {
      padding: theme.spacing(0),
    },
    header: {
        backgroundColor: '#fff'
    },
    logoImage: {
        width: '194px',
        marginTop: 0,
        cursor: 'pointer'
   },
   candidateName: {
       fontSize: 14
   }
}));