import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  table: {
    '& .MuiTableCell-root': {
      fontWeight: 'bold'
    }
  },
  tableCell: {
    width: '33%',
  },
  anchorLink: {
    width: '33%',
    color: 'blue',
    cursor: 'pointer',
  },
  linkNotFound :{
    width: '33%',
    color: 'red'
  }
}))
