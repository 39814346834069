import { makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1
    },
    paper: {
      padding: theme.spacing(0),
      textAlign: 'center',
    },
    paperGeneral: {
      paddingRight: '20px',
      paddingTop: '10px',
      paddingBottom: '10px',
      textAlign: 'left',
      fontSize: '12px',
      fontWeight: 'bold',
      minHeight: '400px',
      maxHeight: '400px',
      overflow: 'auto'
    },
    paperFirst: {
      padding: theme.spacing(1),
      textAlign: 'center',
    },
    formControl: {
      margin: theme.spacing(3),
      minWidth: 120,
    },
    formControlAddBtn: {
      margin: theme.spacing(3),
      minWidth: 120,
      textAlign: 'center'
    },
    formControlLable: {
      textAlign: 'left',
      margin: theme.spacing(3),
      minWidth: 120,
    },
    selectEmpty: {
      width: '120%',
      marginTop: theme.spacing(2),
    },
    formControlSpan: {
      textAlign: 'center',
      margin: theme.spacing(3),
      minWidth: 100,
      fontSize: '14px'
    },
    formControlDoeActive: {
      margin: theme.spacing(3),
      minWidth: 120,
      display: ''
    },
    formControlInactive: {
      display: 'none'
    },
    formControlActive: {
      display: ''
    },
    formControlDate: {
      margin: theme.spacing(2),
      minWidth: 120,
    },
    container: {
      maxHeight: 200,
    },
    overflowDivAddress: {
      width: '100px',
      height: '60px',
      overflowY: 'scroll',
      overflowX: 'hidden',
      marginTop: '32px'
    },
    toolTipbutton: {
      margin: theme.spacing(1),
    }
}));
  