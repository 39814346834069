import React, { Fragment, useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { useStyles } from './style';
import {
  Paper,
  FormControl,
  Checkbox,
  FormControlLabel,
  InputLabel,
  Divider,
} from '@material-ui/core';

import { joinerConst } from '../../../constants/JoinerConstant';
import { getUploadedFiles, postUploadedFiles } from '../fetchData';
import FileUpload from '../FileUpload';
import FileListTable from '../FileListTable';

const company = 'REA India'

const FinalSubmit = ({
  errorObj,
  offerData,
  joinerEmploymentData,
  handleChildData = () => {},
  handleChildDataValidation = () => {},
  handleNotification = () => {},
}) => {
  const [loading, setLoading] = useState(false)
  const classes = useStyles();
  const { offerId } = offerData;
  const [{ is_fresher }] = joinerEmploymentData;

  const [fileList, setFileList] = useState([]);
  useEffect(() => {
    // let text = ``;
    // let status = !is_fresher;
    if (!is_fresher) {
      getUploadedDocuments(offerId);
      // status = !fileList.length;
      // if (status) {
      //   text = `Please upload the relevant document!`;
      // }
    }
    //handleChildDataValidation('joinerFinalData', { status, text });
    handleChildDataValidation('joinerFinalAccept', {
      status: true,
      text: `Please check the checkbox!`,
    });
  }, [errorObj]);

  const { fileExtenstions, maxUploadFileSize, oneMbInBytes } = joinerConst;

  const [acceptCheck, setAcceptCheck] = useState(false);
  const [fileData, setFileData] = useState([]);
  const [fileNotification, setFileNotification] = useState({
    status: false,
    text: '',
    color: '',
  });

  const onFileChange = async (e) => {
    if (fileData.length >= 3) {
      alert('You can select maximum three files to upload!');
      return false;
    }

    if (e.target.files.length) {
      let files = e.target.files[0];
      if (!fileExtenstions.includes(files.name.split('.').pop())) {
        alert(
          `Selected file type is invalid! Allowed file types are ${fileExtenstions.join(
            ','
          )}`
        );
        return false;
      }
      if (files.size > maxUploadFileSize) {
        alert(
          `Exceeding file size limit! Upload file size upto ${maxUploadFileSize/oneMbInBytes} MB!`
        );
        return false;
      }
      setFileData([...fileData, { inputFile: files }]);
    } else {
      setFileData([]);
    }
  };

  const handleFileUpload = async () => {
    let noitfyStatus = false;
    let text = '';
    let color = '';
    if (!fileData.length) {
      noitfyStatus = true;
      text = 'Choose at least 1 file!';
      color = 'red';
    }
    setLoading(true)
    const [{ status } = {}] = await Promise.all(
      fileData
        .filter(
          ({ inputFile }) => inputFile !== null && inputFile !== undefined
        )
        .map(({ inputFile }) => {
          const res = imageUpload({
            inputFile,
            objectId: offerId,
            objectType: 'LastEmployerRelievingDocument',
            documentType: 'LastEmployerRelievingDocument',
          });
          return postUploadedFiles(res);
        })
    ).catch((err) => console.log(err));
    if (status === 200) {
      handleChildDataValidation('joinerFinalData', { status: false, text: '' });
      getUploadedDocuments(offerId);
      noitfyStatus = true;
      text = 'File uploaded successfully!';
      color = 'green';
      handleNotification({ type: '', message: '' });
    } else {
      noitfyStatus = true;
      text = 'Something went wrong!';
      color = 'red';
    }
    setLoading(false)
    setFileData([]);
    setFileNotification({ status: noitfyStatus, text, color });
  };

  const getUploadedDocuments = async (offerId) => {
    const query = {
      objectId: offerId,
      objectType: 'LastEmployerRelievingDocument',
      domainId: 2,
    };
    let data = [];
    try {
      data = await getUploadedFiles(query);
      data = data.data;
    } catch (err) {
      console.log(err);
    }
    // if (data && data.length) {
    //   handleChildDataValidation('joinerFinalData', { status: false, text: `` });
    // }
    setFileList(data);
  };

  const imageUpload = ({ inputFile, objectId, objectType, documentType }) => {
    const formData = new FormData();
    formData.append('file', inputFile);
    formData.append('objectId', objectId);
    formData.append('objectType', objectType);
    formData.append('documentType', documentType);
    formData.append('domainId', 2);
    return formData;
  };

  const handleChange = (e) => {
    setAcceptCheck(e.target.checked);
    if (e.target.checked) {
      handleChildDataValidation('joinerFinalAccept', {
        status: false,
        text: ``,
      });
    }
  };

  const handleSelectedFile = (e, index) => {
    const newFileData = fileData.filter((obj, i) => {
      return i !== index;
    });
    setFileData(newFileData);
  };

  return (
    <Fragment>
      <Grid container spacing={3} className={classes.root}>
      
        <Grid item sm={10}>
            <div style={{color: "red", float: "left", fontSize: "11px", paddingTop: "10px", paddingLeft: "10px"}}>
              <sup>*</sup> Kindly read general instructions before filling in the information on this page
            </div>
          {!is_fresher ? (
            <Fragment>
              <FileUpload
                loading={loading}
                fileData={fileData}
                fileNotification={fileNotification}
                onFileChange={onFileChange}
                handleFileUpload={handleFileUpload}
                handleSelectedFile={handleSelectedFile}
                customLable={
                  <InputLabel className={classes.inputLabel}>
                    {`Relieving Letter or Resignation Acceptance email from your last employer (the organization prior to joining ${company})`}
                  </InputLabel>
                }
              />
            </Fragment>
          ) : null}
          {fileList && fileList.length ? (
            <FileListTable fileList={fileList} limit={0} />
          ) : null}
          <Divider light />
          <Paper className={classes.paperFirst}>
            <FormControl className={classes.formControl}>
              <FormControlLabel
                className={classes.formControlLable}
                control={
                  <Checkbox
                    checked={acceptCheck}
                    onChange={(e) => handleChange(e)}
                    name='acceptCheck'
                    color='primary'
                  />
                }
                label='"I hereby fully agree and affirm that all the information/details/declaration provided are true, fair and correct to the best of my knowledge and belief. If any information or declaration is found to be incorrect, then the Company reserves the right to take appropriate action in its sole and absolute discretion."'
              />
            </FormControl>
          </Paper>
        </Grid>
        <Grid item sm={2}>
          <Paper className={classes.paperGeneral}>
            <div>
              <ul>
                <li className='unorder-list'>&nbsp;</li>
                <li className='unorder-list'>General Instructions:</li>
                <li className='unorder-list'>&nbsp;</li>
                <li>Please read the declaration carefully, select checkbox and click on FINISH to submit the details.</li>
              </ul>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default FinalSubmit;
