import { makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      minHeight: 430,
      maxWidth: '80%',
      marginLeft: '10%'
    },
    paperFirst: {
      padding: theme.spacing(3),
      textAlign: 'justify',
      color: 'black',
    },
}));
  