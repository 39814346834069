import axios from "axios"
import { getAxiosConfig, getRequestHeader } from "../../utils/CommonFunction"
import { surveyApiUrl } from "../../utils/CommonUrl"

let axiosConfig = getAxiosConfig({ withCredentials:true });
axiosConfig.headers = getRequestHeader()

export const getSurveyDataCall = async ({ surveyId }) => {
    const { GET_SURVEY_DATA_URL } = surveyApiUrl

    try {
        const response = await axios.get(`${GET_SURVEY_DATA_URL}/${surveyId}`)
        if (response.status === 200) {
            return response.data
        }

        throw response
    } catch (err) {
        throw err
    }
}

export const getSurveyDataByTokenCall = async ({ token }) => {
    const { GET_SURVEY_BY_TOKEN_DATA_URL } = surveyApiUrl

    try {
        const response = await axios.get(`${GET_SURVEY_BY_TOKEN_DATA_URL}/${token}`)
        if (response.status === 200) {
            return response.data
        }

        throw response
    } catch (err) {
        throw err
    }
}

export const saveSurveyJsonCall = async ({ surveyId, jsonObj, saveNo, callback }) => {
    const url = `${surveyApiUrl.SAVE_SURVEY_DATA_URL}/${surveyId}`

    try {
        const response = await axios.post(url, { queObj: jsonObj }, axiosConfig)
        callback(saveNo, true)
        if (response.status === 200) {
            return response.data
        }
    } catch (err) {
        callback(saveNo, false)
        throw err
    }
}

export const saveSurveyResponseCall = async ({ token, json, isDraft, isSubmitted, currentPageNum }) => {
    const url = `${surveyApiUrl.SAVE_SURVEY_RESPONSE_URL}/${token}`

    try {
        const response = await axios.post(url, { surveyResponse: json, isDraft, isSubmitted, currentPageNum }, axiosConfig)
        if (response.status === 200) {
            return response.data
        }
    } catch (err) {
        throw err
    }
}

export const postSurveyCritertiaData = async ({ formData, bucketCsvData }) => {
    const url = `${surveyApiUrl.SAVE_SURVEY_CRITERIA}`

    try {
        const response = await axios.post(url, { formData, bucketCsvData }, axiosConfig)
        if (response.status === 200) {
            return response.data
        }
    } catch (err) {
        throw err
    }
}

export const getCriteriaListFetch = async () => {
    const { GET_SURVEY_CRITERIA_LIST } = surveyApiUrl

    try {
        const response = await axios.get(`${GET_SURVEY_CRITERIA_LIST}`)
        if (response.status === 200) {
            return response.data
        }

        throw response
    } catch (err) {
        throw err
    }
}


export const getAverageScoreDetails = async ({ reportObj }) => {
    const url = `${surveyApiUrl.GET_AVERAGE_SCORE_DETAILS}`

    try {
        const response = await axios.post(url, { reportObj }, axiosConfig)
        if (response.status === 200) {
            return response.data
        }
    } catch (err) {
        throw err
    }
}

export const fetchSurveyListV2 = async ({searchObj}) => {
    const { GET_SURVEY_LIST_V2 } = surveyApiUrl

    try {
        const response = await axios.post(`${GET_SURVEY_LIST_V2}`,{searchObj})
        if (response.status === 200) {
            return response.data
        }

        throw response
    } catch (err) {
        throw err
    }
}


export const getFavResponseDetails = async ({ reportObj }) => {
    const url = `${surveyApiUrl.GET_FAV_RESPONSE_DETAILS}`

    try {
        const response = await axios.post(url, { reportObj }, axiosConfig)
        if (response.status === 200) {
            return response.data
        }
    } catch (err) {
        throw err
    }
}

export const getAvgManagerScorecardDetails = async ({ reportObj }) => {
    const url = `${surveyApiUrl.GET_AVG_MANAGER_SCORECARD_DETAILS}`

    try {
        const response = await axios.post(url, { reportObj }, axiosConfig)
        if (response.status === 200) {
            return response.data
        }
    } catch (err) {
        throw err
    }
}

export const getManagerScorecardFavDetails = async ({ reportObj }) => {
    const url = `${surveyApiUrl.GET_MANAGER_SCORECARD_FAV_DETAILS}`

    try {
        const response = await axios.post(url, { reportObj }, axiosConfig)
        if (response.status === 200) {
            return response.data
        }
    } catch (err) {
        throw err
    }
}

export const getPendingSurveyListFetch = async () => {
    const { GET_PENDING_SURVEY_LIST } = surveyApiUrl

    try {
        const response = await axios.get(`${GET_PENDING_SURVEY_LIST}`)
        if (response.status === 200) {
            return response.data
        }

        throw response
    } catch (err) {
        throw err
    }
}

export const saveColorShadingLogic = async ({ formData }) => {
    const url = `${surveyApiUrl.SAVE_COLOR_SHADING_LOGIC}`

    try {
        const response = await axios.post(url, { formData }, axiosConfig)
        if (response.status === 200) {
            return response.data
        }
    } catch (err) {
        throw err
    }
}

export const getCurrentColorShadingLogicFetch = async ({searchParams}) => {
    const { GET_COLOR_SHADING_LOGIC } = surveyApiUrl

    try {
        const response = await axios.post(`${GET_COLOR_SHADING_LOGIC}`,{searchParams},axiosConfig)
        if (response.status === 200) {
            return response.data
        }

        throw response
    } catch (err) {
        throw err
    }
}

export const getColorCodingListFetch = async () => {
    const { GET_COLOR_SHADING_LOGIC_LIST } = surveyApiUrl

    try {
        const response = await axios.get(`${GET_COLOR_SHADING_LOGIC_LIST}`)
        if (response.status === 200) {
            return response.data
        }

        throw response
    } catch (err) {
        throw err
    }
}


