import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({

    contentWrapper: {
        width: '98%',
        margin: 'auto',
        marginTop: theme.spacing(2)
        // '& .rec-slider-container': {
        //     border: '2px solid black'
        // }
    },
    anchor: {
        // width: '100%',
    },
    singleImage: {
        display: 'flex',
        justifyContent: 'center'
    }
}))