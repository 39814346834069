import React, { useState,useEffect } from 'react';
import { useStyles } from './style';
import { getDocumentMasterListFetchData, getEmployeeDocumentDetailsFetchData, getLoggedInEmployeeDetailFetchData, postEmployeeDocumentListFetchData, updateEmployeeDocumentListFetchData, getStatusMasterListFetchData, postUploadedFiles, getHropsListFetchData, sendEmail } from '../fetchData'
import HeaderLayout from '../HeaderLayout'
import { TableCell, TableContainer, TableHead, TableRow, Table, TableBody, Select, MenuItem, Button, Grid, FormControl, TablePagination, TextField, Snackbar } from '@material-ui/core'
import PeopleOutlineTwoTone from '@material-ui/icons/PeopleOutlineTwoTone'
import {Paper, Fab} from '@material-ui/core'
import SaveIcon from '@material-ui/icons/Save'
import CloudDownload from '@material-ui/icons/CloudDownload'
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined'
import logo from '../../../utils/load.gif'
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate"
import { employeeTaggingUrl,getEmployeeDetailUrl } from '../../../utils/CommonUrl'
import { efilingConstant } from '../../../constants/EfilingConstant'
import { EfilingApiUrl } from '../../../utils/CommonUrl'
import MuiAlert from '@material-ui/lab/Alert/Alert';
import JSZip from 'jszip'
import JSZipUtils from 'jszip-utils'
import { saveAs } from 'save-as'
import Publish from '@material-ui/icons/Publish'
import { getMenuSubmenuRightsDetail } from '../../../utils/authFunction';
import { pageURLConfig, menuSubmenuPageCategory,EMPLOYEE_USER_ROLE } from '../../../constants/CommonConstant';
const { moduleName, controllerName, actionName } = pageURLConfig.EFILING_INDEX_DOCUMENT_UPLOAD
const { NON_ADMIN_CATEGORY  } = menuSubmenuPageCategory

const maxUploadFileSize = 10485760
const oneMbInBytes = 1048576

export default function DocumentScreen(props) {
    const [isLoading, setIsLoading] = useState(false)
    const [employeeDocumentDetails, setEmployeeDocumentDetails] = useState([])
    const [loggedInEmployeeDetails, setLoggedInEmployeeDetails] = useState({})
    const [documentMasterListAndIdMapping, setDocumentMasterListAndIdMapping] = useState([])
    const [statusMasterListAndIdMapping, setStatusMasterListAndIdMapping] = useState([])
    const [fileData, setFileData] = useState({})
    const [currentFileEmployeeDocumentRowId, setCurrentFileEmployeeDocumentRowId] = useState(0)
    const classes = useStyles();
    const [snackBarDetails, setSnackBarDetails] = useState({open:false,errorMessage:'',type:'',positionVertical:'top'})
    const [isHavingPageAddAccess, setIsHavingPageAddAccess] = useState(false)
    const [isHavingPageEditAccess, setIsHavingPageEditAccess] = useState(false)
    const [isHavingPageViewAccess, setIsHavingPageViewAccess] = useState(null)

    useEffect(()=>{
        getEmployeeDocumentDetails()
        getDocumentMasterList()
        getStatusMasterList()
        setPageRights();
    },[])

    const setPageRights = async () => {
        const params = { userGroupId:EMPLOYEE_USER_ROLE ,pageCategory: NON_ADMIN_CATEGORY, moduleName, controllerName, actionName}
        const rightsObj = await getMenuSubmenuRightsDetail(params)
        const { 
            isUserSuperAdmin,
            selfAddRights,
            selfEditRights,
            selfViewRights,
       } = rightsObj
       const addAccess = isUserSuperAdmin || 
            (selfAddRights) 
       const editAccess =  isUserSuperAdmin || 
            (selfEditRights)
       const viewAccess =  isUserSuperAdmin || 
            (selfViewRights)

        setIsHavingPageAddAccess(addAccess)
        setIsHavingPageEditAccess(editAccess)
        setIsHavingPageViewAccess(viewAccess)
   }

    useEffect(()=>{

    },[employeeDocumentDetails])

    useEffect(()=>{

    },[isLoading])

    const onFileChangeButtonClick = async (id,e) => {
        setCurrentFileEmployeeDocumentRowId(id)
    }


    const onInputClick = async (e) => {
        e.target.value = ''
    }

    const onFileChange = async (e) => {

        let files = e.target.files[0]
        if(files && currentFileEmployeeDocumentRowId){
            if(!efilingConstant.fileExtenstions.includes(files.name.split('.').pop())){
                setSnackBarDetails({open:true,errorMessage:`Selected file type is invalid! Allowed file types are ${efilingConstant.fileExtenstions.join(',')}`,type:'error'})
                return false
            }
            if (files.size > maxUploadFileSize) {
                alert(
                  `Exceeding file size limit! Upload file size upto ${maxUploadFileSize/oneMbInBytes} MB!`
                );
                return false;
            }
            var fileDataArray = fileData && fileData[currentFileEmployeeDocumentRowId] ? (fileData[currentFileEmployeeDocumentRowId]).concat([files]) : [files]

            setFileData({...fileData,[currentFileEmployeeDocumentRowId]:fileDataArray})
            setCurrentFileEmployeeDocumentRowId(0)
        }
        
    }
    const handleSelectedFile = (e, document_master_id,fileIndex) => {
        let newFileData = {}
        Object.keys(fileData).forEach((element) => {
            if(element != document_master_id){
                newFileData[element] = fileData[element]
            } else if(element == document_master_id){
                fileData[document_master_id].map((file,index) => {
                    if(index != fileIndex){
                        newFileData[element] ? newFileData[element].push(file) : (newFileData[element] = [file])
                    }
                })
            }
        })
        setFileData(newFileData)
    }

    const getEmployeeDocumentDetails = async () => {
        try {
            setIsLoading(true)
            let loggedInEmployeeDetailsResponse = await getLoggedInEmployeeDetailFetchData()
            setLoggedInEmployeeDetails(loggedInEmployeeDetailsResponse.data[0])
            let response = await getEmployeeDocumentDetailsFetchData({employeeno: loggedInEmployeeDetailsResponse.data[0]['employeeno'],
                                visibility:[efilingConstant.DOCUMENT_VISIBILITY.BOTH, efilingConstant.DOCUMENT_VISIBILITY.EMPLOYEE],
                                applicability:[efilingConstant.DOCUMENT_APPLICABILITY.ALL_EMPLOYEES]})
            console.log(response)
            if(response && response.data){
                if(!(response.data.length == 1 && response.data[0]['document_master_id'] == null)){
                    response.data.forEach(element => {
                        if(element.status_id === efilingConstant.status.APPROVED){
                            element.remarks = ''
                        }
                    })
                    setEmployeeDocumentDetails(response.data)
                }
            }
            setIsLoading(false)
        } catch (error) {
            
        }
    }

    const getDocumentMasterList = async () => {
        try {
            let response = {}
            response = await getDocumentMasterListFetchData();
            let documentDetailAndIdMapping = {}
            response.data.forEach(element => {
                documentDetailAndIdMapping[element.id] = element
            })
            setDocumentMasterListAndIdMapping(documentDetailAndIdMapping)
        } catch (error) {
            
        }
    }

    const getStatusMasterList = async () => {
        try {
            let response = {}
            response = await getStatusMasterListFetchData();
            let statusAndIdMapping = {}
            if(response && response.data){
                response.data.forEach(element => {
                    statusAndIdMapping[element.id] = element
                })
                setStatusMasterListAndIdMapping(statusAndIdMapping)
            }
            
        } catch (error) {
            
        }
    }

    const handleRemarksChange = async (id,value) => {
        let tempEmployeeDocumentDetails = JSON.parse(JSON.stringify(employeeDocumentDetails))
        tempEmployeeDocumentDetails.map((t) => (t.id === id ? t.remarks = value : ''))
        setEmployeeDocumentDetails(tempEmployeeDocumentDetails)
    }

    const handleSubmit = async () => {
        console.log(documentMasterListAndIdMapping)
        let validationResponse = await validateData();
        
        if(validationResponse.error){
            setSnackBarDetails({open:true,errorMessage:validationResponse.message,type:'error',positionVertical:'bottom'})
            return false;
        }
        setIsLoading(true)
        let tempEmployeeDocumentDetails = []
        await Promise.all(employeeDocumentDetails.map(async documentDetail => {
            if(fileData[documentDetail.document_master_id]){
                let fileVersion = documentDetail.version,newStatusId = documentDetail.status_id
                let isAchrived = false;
                if(efilingConstant.status.PENDING_FOR_SUBMISSION === documentDetail.status_id){
                    fileVersion = 1
                } else if(efilingConstant.status.APPROVED === documentDetail.status_id){
                    fileVersion = documentDetail.version + 1
                    isAchrived = true;
                }

                if([efilingConstant.status.PENDING_FOR_SUBMISSION,efilingConstant.status.APPROVED,efilingConstant.status.REJECTED]){
                    newStatusId = efilingConstant.status.PENDING_FOR_APPROVAL
                }
                let formData = new FormData();
                fileData[documentDetail.document_master_id].map((file,index) => {
                    formData.set('file'+index,file)
                })
                // formData.set('file',fileData[documentDetail.document_master_id])
                formData.set('employeeNo',documentDetail.employeeno)
                formData.set('module','efiling')
                formData.set('document_alias',documentMasterListAndIdMapping[documentDetail.document_master_id]['document_alias'])
                formData.set('document_ext','pdf')
                formData.set('version',fileVersion)
                let uploadFileResponse = await postUploadedFiles(formData)
                if(uploadFileResponse.success){
                    if(isAchrived){
                        
                        let tempDocumentDetail = JSON.parse(JSON.stringify(documentDetail))
                        tempDocumentDetail.status_id = efilingConstant.status.ARCHIVED
                        tempDocumentDetail.remarks = ''
                        tempEmployeeDocumentDetails.push(tempDocumentDetail)
                    }
                    if( uploadFileResponse && uploadFileResponse.data && uploadFileResponse.data.documentPath){
                        documentDetail.document_path = uploadFileResponse.data.documentPath
                        documentDetail.version = fileVersion
                        documentDetail.status_id = newStatusId
                        documentDetail.date_of_submission = new Date()
                        documentDetail.uploaded_by = loggedInEmployeeDetails['employeeno']
                        documentDetail.submitted_by = 'Employee'
                        if(isAchrived){
                            documentDetail.id = ''
                        }
                    }
                }
            }
            tempEmployeeDocumentDetails.push(documentDetail)
        }))
        let insertObjects = [], updateObjects = []
        tempEmployeeDocumentDetails.map(documentDetail => {
            if(documentDetail.id == ''){
                insertObjects.push(documentDetail)
            } else {
                updateObjects.push(documentDetail)
            }
        })

        if(insertObjects.length > 0){
            const insertResponse = await postEmployeeDocumentListFetchData(insertObjects)
        }

        if(updateObjects.length > 0){
            const updateResonse = await updateEmployeeDocumentListFetchData(updateObjects)
        }
        getEmployeeDocumentDetails()
        setFileData({})
        setSnackBarDetails({open:true,errorMessage:'Document Uploaded Successfully',type:'success', positionVertical:'top'})
        await sendPendingDocumentSubmittedByEmployeeEmail()
        setIsLoading(false)
    }

    const sendPendingDocumentSubmittedByEmployeeEmail = async () => {
        let emailObject = {}
        emailObject.employee =  (employeeDocumentDetails && employeeDocumentDetails[0] && employeeDocumentDetails[0]['legalname']) ? employeeDocumentDetails[0]['legalname'] + ' (' + employeeDocumentDetails[0]['employeeno'] + ')': ''
        emailObject.cc = []
        if(employeeDocumentDetails && employeeDocumentDetails[0] && employeeDocumentDetails[0]['company_provided_email_id']){
            emailObject.cc.push(employeeDocumentDetails[0]['company_provided_email_id']) 
        }
        emailObject.emailTemplate = 'PendingDocumentSubmittedByEmployeeEmail'
        await sendEmail({emailObject})
    }
    const validateData = async () => {
        let validationResponse = {message: '', error: false}
        employeeDocumentDetails.forEach(element => {
            if(element.status_id === efilingConstant.status.APPROVED){
                if(element.remarks != ''){
                    if(!fileData[element.document_master_id]){
                        validationResponse.error = true
                        validationResponse.message = 'Document is required with remarks'
                        return false
                    }
                }
                if(fileData[element.document_master_id]){
                    if(element.remarks === ''){
                        validationResponse.error = true
                        validationResponse.message = 'Remark is required when modifying document in approved state'
                        return false
                    }
                }
            }
        })
        return validationResponse
    }

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackBarDetails({open:false,errorMessage:'',type:''});
    };

    const handleDownloadAll = () => {
        var zip = new JSZip();
        var count = 0;
        var zipFilename = `${employeeDocumentDetails[0]['employeeno']}.zip`;

        var doumentsPaths = []

        employeeDocumentDetails.forEach(element => {
            if(element.document_path){
                doumentsPaths.push(element.document_path)
            }
        })
        doumentsPaths.forEach(function(documentPath){
            let documentPathArray = documentPath.split('/')
            var filename = `${documentPathArray[documentPathArray.length - 2]}${documentPathArray[documentPathArray.length - 1]}`;
            const url = EfilingApiUrl.GET_FILE_URL+btoa(documentPath)
            JSZipUtils.getBinaryContent(url, function (err, data) {
                if(err) {
                    console.log(err)
                    throw err; // or handle the error
                    
                }
                console.log(data,'here')
                zip.file(filename, data, {binary:true});
                count++;
                if (count == doumentsPaths.length) {
                    console.log('inside')
                    zip.generateAsync({type:'blob'}).then(function(content) {
                        saveAs(content, zipFilename);
                    });
                }
            });
        });
    }

    
  return (
    <React.Fragment>
        {(!isHavingPageViewAccess) 
        ?
            (isHavingPageViewAccess == null) ? <div className={classes.loader}><img src={logo} alt="loading..." /></div> :
                <div> <h1 style={{margin:'0px'}}>Unauthorised </h1> <br></br> <span>You don't have access to view this page</span> </div> 
        : 
        <div>
            {snackBarDetails && snackBarDetails.open ? 
            <Snackbar
                anchorOrigin={{ vertical:snackBarDetails.positionVertical, horizontal:'center' }}
                open={snackBarDetails.open}
                autoHideDuration={4000}
                onClose={handleSnackbarClose}
          >
            <MuiAlert severity={snackBarDetails.type}>{snackBarDetails.errorMessage}</MuiAlert>
          </Snackbar> : (null)
          }
          <Grid container>
            <Grid> 
              <HeaderLayout
                  title='My Documents'
                  icon={<PeopleOutlineTwoTone/>}
              />
              </Grid>
          </Grid>
          { ( isLoading ) 
            ? 
            <div className={classes.loader}><img src={logo} alt="loading..." /></div>
            : 
            <Paper className={classes.pageContent}>
                <Grid container>
                    <Grid item xs={3}> 
                    <h5>
                        Employee Code : { (employeeDocumentDetails && employeeDocumentDetails[0] ) ? employeeDocumentDetails[0]['employeeno'] : ''}
                    </h5>
                    </Grid>
                    <Grid item xs={3}> 
                    <h5>
                        { (employeeDocumentDetails && employeeDocumentDetails[0] && employeeDocumentDetails[0]['legalname']) ? ('Employee Name : ' + employeeDocumentDetails[0]['legalname']) : ''}
                    </h5>
                    </Grid>
                    <Grid item xs={3} style={{paddingLeft:'10px'}}> 
                        <h5>
                            Allowed Document Types : JPEG/PNG/JPG/PDF
                        </h5>
                    </Grid><Grid item xs={3} style={{paddingLeft:'10px'}}> 
                        <h5>
                            Max File Size : 10MB
                        </h5>
                    </Grid>
                </Grid>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{width: '150'}}>Document Name</TableCell>
                                <TableCell>Date of Submission</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>View</TableCell>
                                <TableCell style={{width: '200'}}>Remarks</TableCell>
                                <TableCell style={{width: '200', textAlign:'center'}}>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        { ( employeeDocumentDetails && employeeDocumentDetails.length > 0 ) ? employeeDocumentDetails.map((element,key)=>(
                            <TableRow key={key}>
                                <TableCell >
                                    {documentMasterListAndIdMapping[element.document_master_id] ? documentMasterListAndIdMapping[element.document_master_id]['document_type'] : ''}
                                    { (documentMasterListAndIdMapping && documentMasterListAndIdMapping[element.document_master_id] && documentMasterListAndIdMapping[element.document_master_id]['is_mandatory']) ? <span style={{color:'red'}}>*</span> : ''}
                                </TableCell>
                                <TableCell>{element.date_of_submission ? (new Date(element.date_of_submission)).toLocaleDateString('en-GB', {
  day: 'numeric', month: 'short', year: 'numeric'
}).replace(/ /g, '-') : '---'}</TableCell>
                                <TableCell>{statusMasterListAndIdMapping[element.status_id] ? statusMasterListAndIdMapping[element.status_id]['status_text'] : '---'}</TableCell>
                                <TableCell>{element.document_path ? (['html','htm','xlsx','docx','xlsm','xlsb','xls'].includes(element.document_path.split('.').pop()) ? <a target='blank' href={EfilingApiUrl.GET_FILE_URL_1+btoa(element.document_path)+'.'+element.document_path.split('.').pop()} className={classes.anchor}>
                                            VIEW
                                        </a> : <a target='blank' href={EfilingApiUrl.GET_FILE_URL_1+btoa(element.document_path)} className={classes.anchor}>
                                            VIEW
                                        </a>)  : '---'}</TableCell>
                                <TableCell style={{width: '200'}}>{element.status_id === efilingConstant.status.APPROVED ? 
                                    <TextField
                                        variant="outlined"
                                        label=""
                                        name='remarks'
                                        value={element.remarks}
                                        onChange={e => handleRemarksChange(element.id, e.target.value)}
                                    />
                                    : (element.status_id === efilingConstant.status.REJECTED ? element.remarks_by_hrops : '---')}</TableCell>
                                <TableCell style={{width: '200', textAlign:'center'}}>
                                    {[efilingConstant.status.PENDING_FOR_SUBMISSION,efilingConstant.status.APPROVED,efilingConstant.status.REJECTED].includes(element.status_id)  
                                    ?
                                    <FormControl className={classes.formControl}>
                                        <input
                                            accept="application/pdf, image/*"
                                            className={classes.input}
                                            id="contained-button-file"
                                            type="file"
                                            name={element.document_master_id}
                                            onChange={onFileChange}
                                            onClick={onInputClick}
                                            title="Select a file"
                                        />
                                        <label htmlFor="contained-button-file">
                                            {[efilingConstant.status.PENDING_FOR_SUBMISSION,efilingConstant.status.REJECTED].includes(element.status_id)  ? 
                                            
                                            <label htmlFor="contained-button-file" style={{display:'flex',justifyContent:'center'}}>
                                                <Publish className = {classes.uploadButton} onClick={e => onFileChangeButtonClick(element.document_master_id)}></Publish>
                                                {fileData[element.document_master_id] ? <div>(+)</div> : ''}
                                            </label>
                                            : 
                                            <Button className = {classes.modifyButton} component="span" variant="contained" onClick={e => onFileChangeButtonClick(element.document_master_id)}>
                                                Modify
                                            </Button>}
                                        </label>
                                        { fileData[element.document_master_id] ? 
                                        <div>
                                            {fileData[element.document_master_id].map((file,index) => (
                                                
                                                <div align="right" key={index}>
                                                    {file.name}
                                                    <span onClick={e=>handleSelectedFile(e, element.document_master_id,index)}>
                                                        <DeleteForeverOutlinedIcon className={classes.icon} />
                                                    </span>
                                                </div>
                                            ))}
                                        </div>
                                        : ''
                                        }
                                    </FormControl> : '---'
                                    }
                                </TableCell>
                            </TableRow>
                        )) : (
                            <TableRow>
                            <TableCell><b>No result found</b></TableCell>
                            </TableRow>
                        ) } 
                        </TableBody>
                        
                    </Table>
                </TableContainer>
            </Paper>
          }
            <Grid
                container
                spacing={3}
                className={classes.marginTop}
                direction='row'
                alignItems='center'
                >
                    <Grid item>
                        <Button
                            className={classes.button}
                            variant='contained'
                            size='large'
                            color='primary'
                            onClick={handleSubmit}
                            disabled={!(isHavingPageAddAccess || isHavingPageEditAccess)}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
        </div>}
    </React.Fragment>
  )
}