import React from "react"
import { SurveyCreatorComponent, SurveyCreator } from "survey-creator-react"
import { saveSurveyJsonCall } from "../fetchData"
import { SURVEY_CONST } from "../../../constants/SurveyConstant"

import { useStyles } from './style'
import { Serializer } from "survey-core";
import * as SurveyCore from "survey-core";
import Sortable from 'sortablejs';
import { sortablejs } from "surveyjs-widgets";
import { SurveyTemplate } from "../SurveyTemplate"
import ToastElement from "../../../components/Shared/Elements/ToastElement"


import "survey-core/defaultV2.min.css"
import "survey-creator-core/survey-creator-core.min.css"

const AddQuestion = ({
    surveyId,
    surveyData,
    surveyQueData,
    surveySettings,
    surveyDriverData = []
}) => {
    const classes = useStyles()
    const { surveyCreator = { settings: {} }, surveyPage = { settings: {} } } =  surveySettings
    const { question_json = null } = surveyQueData || {}
    const dbQueJson = question_json ? JSON.parse(question_json) : question_json

    let creatorOptions = surveyCreator.settings ? { ...surveyCreator.settings } : { ...SURVEY_CONST.DEFAULT_SURVEY_SETTING_OBJECT }
    let defaultPageSettings = surveyPage.settings ? { ...surveyPage.settings } : { ...SURVEY_CONST.DEFAULT_SURVEY_PAGE_SETTING_OBJECT }

    let surveyJsonObj = { 
        ...defaultPageSettings,
        ...dbQueJson
    }
    const { isSurveyLive, surveyName = '', description = '', is_interactive = 0 } = surveyData
    surveyJsonObj = { ...surveyJsonObj, title: surveyName, description: description }

    if(is_interactive){
        creatorOptions = {...creatorOptions,questionTypes:SURVEY_CONST.questionTypesForInteractiveSurvey,designer: {defaultAddQuestionType:'rating'}}
    }
    if(Object.keys(surveyData).length > 0 && !is_interactive){
        sortablejs(SurveyCore);
    }
    if(surveyDriverData.length > 0){
        Serializer.addProperty("question", {
            name: "driver",
            type: "number",
            category: "general",
            choices: surveyDriverData ? ["No Driver"].concat(surveyDriverData) : [],
            default: "No Driver",
            visibleIndex: 0,
            onSetValue: (survey, value) => {
                // ...
                // You can perform required checks or modify the `value` here
                // ...
                // Set the `value`
                survey.setPropertyValue("driver", value);
                // You can perform required actions after the `value` is set
                // ...
            }
        });
        Serializer.addProperty("question", {
            name: "managerRelated",
            category: "general",
            choices: ["No","Yes"],
            default: "No",
            visibleIndex: 0,
            onSetValue: (survey, value) => {
                // ...
                // You can perform required checks or modify the `value` here
                // ...
                // Set the `value`
                survey.setPropertyValue("managerRelated", value);
                // You can perform required actions after the `value` is set
                // ...
            }
        });
    }
    

    const createCreator = ({ options, json }) => {
        const res = new SurveyCreator({...options })
        res.JSON = json
        
        return res
    }

    const SurveyCreatorWidget = ({ creatorOptions, surveyJsonObj }) => {
        const creator = createCreator({ options: creatorOptions, json: surveyJsonObj })
        creator.saveSurveyFunc = async (saveNo, callback) => {
            callback(saveNo, true)
            let type = ''
            let msg = ''

            try {
                const result = await saveSurveyJsonCall({ surveyId, jsonObj: creator.JSON, saveNo, callback })  
                if (result.success) {
                    msg = `Survey data saved successfully!`
                    type = `success`
                }
            } catch (err) {
                msg = err.response.data.message || err.message
                type = `error`
            }

            if (Array.isArray(msg) && msg.length > 0) {
                msg.forEach(ele => {
                    ToastElement({ msg: ele, type })
                })
            } else if(msg){
                ToastElement({ msg, type })
            }
        }

        return <SurveyCreatorComponent className={classes.root} creator={creator} />
        
    }

    const accessDeniedPage = ({ surveyName = '', description = ''}) => {
        return (
                <SurveyTemplate 
                    surveyName={surveyName}
                    description={description}
                    message={'The survey is live now. you can not modify it.'}
                />
            )
    }


    return ( isSurveyLive === 0 ? <div className={classes.root}> {SurveyCreatorWidget({ creatorOptions, surveyJsonObj  })} </div>: ( isSurveyLive === 1 ? accessDeniedPage({ surveyName, description }) : (null)) )

    
}

export { AddQuestion }