import React, { useState } from "react"
import { 
    Grid,
    Card,
    Divider
} from "@material-ui/core"

import { useStyles } from "./style"

const NetOfferCard = ({ 
    netOfferRecord = {},
    handleDetailedData = () => {}
}) => {
    const classes = useStyles()
    const { totalOfferCount = 0, netOffer } = netOfferRecord
    const [sourceName, setSourceName] = useState("")
    const [sourceCount, setSourceCount] = useState(totalOfferCount)

    const handleSourceName = (val) => {
        setSourceName(val)
        let sourceVal = totalOfferCount
        if (val) {
            sourceVal = netOffer[val]
        }
        setSourceCount(sourceVal)
    }


    return (<Grid item xs={3} sm={3} md={3} className={classes.gridBody}>
                <Card style={{ borderRadius: "10%", height: "180px", backgroundColor: "#FFEFE7" }}>
                    <div style={{ padding: "10 10 0 10" }}>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12}>
                                <Grid container>
                                    <Grid item xs={6} sm={6} md={6}>
                                        <label style={{ fontSize: "1.17em", fontWeight: "bold" }}>Net Offer</label>
                                        <br/>
                                        <span style={{ color:"#9A99AA", fontSize: "14px" }}>Total Net Offer</span>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6}>
                                        <div style={{ textAlign: "right"}}>
                                            <select style={{ textAlign: "center", width: "100px", borderRadius: "8px", height: "35px", backgroundColor: "#3454F4", color: "#FFFFFF"  }} name="sourceName" placeholder="Source" onChange={e => handleSourceName(e.target.value)}>
                                                <option value={""}>Source</option>
                                                {Object.keys(netOffer).map(source => <option value={source}>{source}</option>)}
                                            </select>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <div style={{ padding: "10 0 5 0" }}>
                                    <Divider />
                                </div>
                            </Grid>
                            
                            <Grid item xs={12} sm={12} md={12} onClick={e => handleDetailedData("NetOffer")}>
                                <div style={{ textAlign: "center", paddingTop: "20px" }}>
                                    <label style={{ fontSize: "4.00em", fontWeight: "bold" }}> { sourceCount }</label>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    </Card>
                </Grid>
            )
}

export default NetOfferCard