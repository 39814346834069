import React, { useState,useEffect } from 'react';
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CardHeader from '@material-ui/core/CardHeader';
import Card from '@material-ui/core/Card';
import Email from '@material-ui/icons/Email'
// import CardHeader from 'components/CardComponent/CardHeader'
import HeaderLayout from '../HeaderLayout'
import PeopleOutlineTwoTone from '@material-ui/icons/PeopleOutlineTwoTone'

import { useStyles } from './style'
import { Button, FormControl, Grid, Snackbar, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from '@material-ui/core'
import { getDepartmentListFetchData, postPendingDocumentAlert, getLoggedInEmployeeDetailFetchData,getHrbpListFetchData, getSubDepartmentFetchData, getGradeListFetchData, getLocationListFetchData, getHrbpEmployeeDocumentDetails, sendEmail } from '../fetchData';
// import { getGradeList, getLocationList } from '../../Campaign/fetchData';

import { orange } from '@material-ui/core/colors'
import { withStyles } from '@material-ui/core/styles'
import { efilingConstant } from '../../../constants/EfilingConstant';
import logo from '../../../utils/load.gif'
import MuiAlert from '@material-ui/lab/Alert/Alert';
import { EfilingApiUrl } from '../../../utils/CommonUrl';
import {getPagesAcess} from '../../../utils/authFunction'
import {SUPERADMIN, pageURLConfig} from '../../../constants/CommonConstant'
const { moduleName, controllerName, actionName } = pageURLConfig.EFILING_INDEX_DOCUMENT_MASTER_HRBP
const PreviousButton = withStyles(theme => ({
    root: {
      color: theme.palette.getContrastText(orange[500]),
      backgroundColor: orange[500],
      '&:hover': {
        backgroundColor: orange[700]
      }
    }
  }))(Button)

const INITIAL_FILTER_OBJECT = {
    location: '',
    hrbpId: '',
    status: '',
    employee_name: ''
}

const SearchFilters = ({handleFilters,setisAuthorised, setLoggedInUserDetails, setIsUserSuperAdmin, setIsHavingPageViewAccess, setIsHavingPageEditAccess}) => {
    const classes = useStyles()
    const [expandDefault, setExpandDefault] = useState(false)
    const [filterObj, setFilterObj] = useState(INITIAL_FILTER_OBJECT)
    const [departmentList, setDepartmentList] = useState([])
    const [subDepartmentList, setSubDepartmentList] = useState([])
    const [gradeList, setGradeList] = useState([])
    const [locationList, setLocationList] = useState([])
    const [hrbpList, setHrbpList] = useState([])
    const [hrbpAndAdminGroupIdMapping,setHrbpAndAdminGroupIdMapping] = useState({})
    const [hrbpEmployeenoAndAdminGroupIdMapping, setHrbpEmployeenoAndAdminGroupIdMapping] = useState({})
    const [loggedInEmployeeDetails, setLoggedInEmployeeDetails] = useState({})
    

    useEffect( () => {
        getDepartmentList()
        getGradeList()
        getLocationList()
        getHrbpList()
        // handleFilters(filterObj)
        getLoggedinUser()
    },[])

    const handleChange = event => {
        setFilterObj({ ...filterObj, [event.target.name]: event.target.value })
    }

    const getLoggedinUser = async () => {
        let loggedInEmployeeDetailsResponse = await getLoggedInEmployeeDetailFetchData()
        setLoggedInEmployeeDetails(loggedInEmployeeDetailsResponse.data[0])
        setLoggedInUserDetails(loggedInEmployeeDetailsResponse.data[0])
        
        let isSuperAdminUser = false
        if(loggedInEmployeeDetailsResponse.data[0].admin_user_group_id == SUPERADMIN){
            isSuperAdminUser = true
        }
        setIsUserSuperAdmin(isSuperAdminUser)
        setPageAcessses(loggedInEmployeeDetailsResponse.data[0], isSuperAdminUser)
    }

    const getDepartmentList = async () => {
        try {
            let response = {}
            response = await getDepartmentListFetchData()
            console.log(response)
            if(response && response.data){
                setDepartmentList(response.data)
            }
        } catch (error) {
            
        }
    }

    const getHrbpList = async () => {
        try {

            let loggedInEmployeeDetailsResponse = await getLoggedInEmployeeDetailFetchData()
            setLoggedInEmployeeDetails(loggedInEmployeeDetailsResponse.data[0])
            let response = {}
            response = await getHrbpListFetchData()
            if(response && response.data){
                var tempHrbpAndAdminGroupIdMapping = {}
                var tempHrbpEmployeenoAndAdminGroupIdMapping = {}
                var tempHrbList = []
                response.data.map(hrbpData => {
                    if(tempHrbpAndAdminGroupIdMapping && tempHrbpAndAdminGroupIdMapping[hrbpData.admin_user_group_id]){
                        tempHrbpAndAdminGroupIdMapping[hrbpData.admin_user_group_id].push(hrbpData)
                    } else {
                        tempHrbpAndAdminGroupIdMapping[hrbpData.admin_user_group_id] = []
                        tempHrbpAndAdminGroupIdMapping[hrbpData.admin_user_group_id].push(hrbpData)
                    }

                    if(tempHrbpEmployeenoAndAdminGroupIdMapping && tempHrbpEmployeenoAndAdminGroupIdMapping[hrbpData.admin_user_group_id]){
                        tempHrbpEmployeenoAndAdminGroupIdMapping[hrbpData.admin_user_group_id].push(hrbpData.hr_emp_id)
                    } else {
                        tempHrbpEmployeenoAndAdminGroupIdMapping[hrbpData.admin_user_group_id] = []
                        tempHrbpEmployeenoAndAdminGroupIdMapping[hrbpData.admin_user_group_id].push(hrbpData.hr_emp_id)                        
                    }
                })
                if(tempHrbpEmployeenoAndAdminGroupIdMapping[efilingConstant.HRBP_ADMIN_GROUP_CONSTANT.HRBP_HEAD]
                    .includes(loggedInEmployeeDetailsResponse.data[0].employeeno)){
                        tempHrbList = [...tempHrbpAndAdminGroupIdMapping[efilingConstant.HRBP_ADMIN_GROUP_CONSTANT.HRBP],
                                        ...tempHrbpAndAdminGroupIdMapping[efilingConstant.HRBP_ADMIN_GROUP_CONSTANT.Process_Control_HRBP],
                                        ...tempHrbpAndAdminGroupIdMapping[efilingConstant.HRBP_ADMIN_GROUP_CONSTANT.HRBP_HEAD]]
                } else if(tempHrbpEmployeenoAndAdminGroupIdMapping[efilingConstant.HRBP_ADMIN_GROUP_CONSTANT.Process_Control_HRBP]
                    .includes(loggedInEmployeeDetailsResponse.data[0].employeeno)){
                        response.data.map(hrbpData => {
                            if(hrbpData.reporting_manager_emp_id == loggedInEmployeeDetailsResponse.data[0].employeeno){
                                tempHrbList ? tempHrbList.push(hrbpData) : (tempHrbList = [hrbpData])
                            }
                            if(hrbpData.hr_emp_id == loggedInEmployeeDetailsResponse.data[0].employeeno){
                                tempHrbList ? tempHrbList.push(hrbpData) : (tempHrbList = [hrbpData])
                            }
                        })
                } else if(tempHrbpEmployeenoAndAdminGroupIdMapping[efilingConstant.HRBP_ADMIN_GROUP_CONSTANT.HRBP]
                    .includes(loggedInEmployeeDetailsResponse.data[0].employeeno)){
                        response.data.map(hrbpData => {
                            if(hrbpData.hr_emp_id == loggedInEmployeeDetailsResponse.data[0].employeeno){
                                tempHrbList ? tempHrbList.push(hrbpData) : (tempHrbList = [hrbpData])
                            }
                        })
                } 
                
                if(tempHrbList.length > 0){
                    setFilterObj({ ...filterObj, hrbpId: loggedInEmployeeDetailsResponse.data[0].employeeno })
                } else {
                    setisAuthorised(false)
                }
                setHrbpList(tempHrbList)

                handleFilters({...INITIAL_FILTER_OBJECT, hrbpId: loggedInEmployeeDetailsResponse.data[0].employeeno})
            }
        } catch (error) {

        }
    }

    const getSubDepartment = async (departmentId) => {
        try {
            let response = {}
            response = await getSubDepartmentFetchData({departmentId:[departmentId]})
            console.log(response)
            if(response && response.data){
                setSubDepartmentList(response.data)
            }
        } catch (error) {
        }
    }

    const getGradeList = async () => {

        try {
            var response = await getGradeListFetchData()
            console.log(response)
            if(response && response.data){
                response.data.sort((a,b) => (parseInt(a.grade_name) > parseInt(b.grade_name)) ? 1 : ((parseInt(b.grade_name) > parseInt(a.grade_name)) ? -1 : 0))
                setGradeList(response.data)
            }
        } catch (error) {
            
        }
    }

    const getLocationList = async () => {

        try {
            var response = await getLocationListFetchData()
            console.log(response)
            if(response && response.data) {
                setLocationList(response.data)
            }     
        } catch (error) {
            
        }
    }

    const handleDepartmentChange = event => {
        getSubDepartment(event.target.value)
        setFilterObj({
          ...filterObj,
          department: event.target.value,
          subDepartment: ''
        })
    } 

    const handleReset = () => {
        setFilterObj({...INITIAL_FILTER_OBJECT, hrbpId: loggedInEmployeeDetails.employeeno})
        handleFilters({...INITIAL_FILTER_OBJECT, hrbpId: loggedInEmployeeDetails.employeeno})
    }


    const setPageAcessses = async (loggedInUserObj, isSuperAdminUser) => {
        let pageAccess = await getPagesAcess({ userGroupId: loggedInUserObj.admin_user_group_id, moduleName,controllerName, actionName })
        let pageViewAccessVal = false, pageEditAccessVal = false
        if(isSuperAdminUser) {
            pageViewAccessVal = true
            pageEditAccessVal = true
        } else {
            if(pageAccess && pageAccess.data && pageAccess.data.data[0] && pageAccess.data.data[0].page_view == "Yes"){
                pageViewAccessVal = true
            }
            if(pageAccess && pageAccess.data && pageAccess.data.data[0] && pageAccess.data.data[0].page_edit == "Yes"){
                pageEditAccessVal = true
            }
        }
        setIsHavingPageViewAccess(pageViewAccessVal)
        setIsHavingPageEditAccess(pageEditAccessVal)
    }

    return (
            <Accordion
                expanded={expandDefault}
                onChange={() => setExpandDefault(!expandDefault)}
                className={classes.pageContent}
                style={{margin:'9px'}}
                
            >
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1a-content'
                id='panel1a-header'
                className={classes.pageContent}
                >                   
                    <Grid>
                        <Card>
                            <CardHeader
                                title="Filters"
                                style={{ color: '#3f51b5'}}
                                titleTypographyProps={{variant:'subtitle1' }}
                            />
                        </Card>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails className={classes.pageContent}>
                <Grid container>
                <Paper elevation={2} className={classes.root}>
                    <form className={classes.formRoot} noValidate autoComplete='off'>
                        <Grid container>
                            <Grid container spacing={3}>
                                <Grid item xs={4} sm={12} md={4}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel id='demo-simple-select-outlined-label'>
                                    HRBP Name
                                    </InputLabel>
                                    <Select
                                    labelId='demo-simple-select-outlined-label'
                                    id='demo-simple-select-outlined'
                                    name='hrbpId'
                                    value={filterObj.hrbpId || ''}
                                    onChange={e => handleChange(e)}
                                    >
                                    {hrbpList.length > 0 &&
                                        hrbpList.map((item, index) => (
                                        <MenuItem value={item.hr_emp_id} key={item.hr_emp_id}>
                                            {item.legalname}
                                        </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                </Grid>
                                <Grid item xs={4} sm={12} md={4}>
                                <TextField
                                    id='outlined-basic'
                                    label='Candidate Name'
                                    placeholder='Candidate Name'
                                    name='employee_name'
                                    value={filterObj.employee_name || ''}
                                    onChange={e => handleChange(e)}
                                    className={classes.formControl}
                                />
                                </Grid>
                                <Grid item xs={4} sm={12} md={4}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel id='demo-simple-select-outlined-label'>
                                    Location
                                    </InputLabel>
                                    <Select
                                    labelId='demo-simple-select-outlined-label'
                                    id='demo-simple-select-outlined'
                                    name='location'
                                    value={filterObj.location || ''}
                                    onChange={e => handleChange(e)}
                                    >
                                    {locationList.length > 0 &&
                                        locationList.map((item, index) => (
                                        <MenuItem value={item.id} key={item.id}>
                                            {item.locality}
                                        </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                </Grid>
                                <Grid item xs={4} sm={12} md={4}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel id='demo-simple-select-outlined-label'>
                                    Status
                                    </InputLabel>
                                    <Select
                                    labelId='demo-simple-select-outlined-label'
                                    id='demo-simple-select-outlined'
                                    name='status'
                                    value={filterObj.status || ''}
                                    onChange={e => handleChange(e)}
                                    >
                                    <MenuItem value='Completed'>Completed</MenuItem>
                                    <MenuItem value='Pending'>Pending</MenuItem>
                                    </Select>
                                </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            spacing={3}
                            className={classes.marginTop}
                            direction='row'
                            alignItems='center'
                        >
                            <Grid item>
                            <PreviousButton
                                variant='outlined'
                                color='primary'
                                className={classes.resetButton}
                                size='small'
                                onClick={() => handleReset()}
                            >
                                Reset
                            </PreviousButton>
                            <Button
                                variant='contained'
                                color='primary'
                                size='small'
                                style={{ marginLeft: 20 }}
                                onClick={() => handleFilters(filterObj)}
                                className={classes.button}
                            >
                                Filter Results
                            </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
                </Grid>
                </AccordionDetails>
            </Accordion>
    )
}


export default function DocumentMasterHrbpScreen() { 
    const classes = useStyles()
    const [employeeDocumentStatusList, setEmployeeDocumentStatusList] = useState([])
    const [loggedInUserDetails, setLoggedInUserDetails] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(200);
    const [snackBarDetails, setSnackBarDetails] = useState({open:false,errorMessage:'',type:'',positionVertical:'top'})
    const [isAuthorised, setisAuthorised] = useState(true)
    const [isUserSuperAdmin, setIsUserSuperAdmin] = useState(false)
    const [isHavingPageEditAccess, setIsHavingPageEditAccess] =  useState(true)
    const [isHavingPageViewAccess, setIsHavingPageViewAccess] =  useState(null)

    const handleFilters = async ( filterObj ) => {
        try {
            setIsLoading(true)
            let response = {}
            response = await getHrbpEmployeeDocumentDetails({filterObj})
            console.log(response)
            if(response && response.data){
                setEmployeeDocumentStatusList(response.data)
            }
            setPage(0);
            setIsLoading(false)
        } catch (error) {
            
        }
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };

    const handleEmail = async (employeeName, emailId, document_id, employeeno) => {
        setIsLoading(true)
        let emailObject = {emailTemplate: efilingConstant.EMAIL_TEMPLATES.PENDING_DOCUMENT_GENERIC_EMAIL,
            employeeName: employeeName,
            emailId: emailId}
        let response = await sendEmail({ emailObject } )
        if(response && response.success && response.data){
            setSnackBarDetails({open:true,errorMessage:response.data.message,type:'success',positionVertical:'top'})
            const pendingDocumentAlertInsertObj = {employeeno: employeeno, document_master_id: document_id, alert_sent_by: loggedInUserDetails.employeeno,alert_sent_on:new Date()}
            await postPendingDocumentAlert(pendingDocumentAlertInsertObj)
        } else {
            setSnackBarDetails({open:true,errorMessage:'Something went wrong',type:'error',positionVertical:'top'})
        }
        setIsLoading(false)
    }

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackBarDetails({open:false,errorMessage:'',type:''});
    };

    const handleDownloadPendingDocumentReport = () => {
        const a = document.createElement('a');
        a.setAttribute('hidden', '');
        a.setAttribute('href', `${EfilingApiUrl.DOWNLOAD_PENDING_DOCUMENT_REPORT}`);
        a.setAttribute('target', '_blank');
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }

    return (
        <div>
        {(!isHavingPageViewAccess) 
        ? 
            (isHavingPageViewAccess == null) ? <div className={classes.loader}><img src={logo} alt="loading..." /></div> :
            <div> <h1 style={{margin:'0px'}}>Unauthorised </h1> <br></br> <span>You don't have access to view this page</span> </div> 
        :
            <div className={classes.root}>
                {snackBarDetails && snackBarDetails.open ? 
                    <Snackbar
                        anchorOrigin={{ vertical:snackBarDetails.positionVertical, horizontal:'center' }}
                        open={snackBarDetails.open}
                        autoHideDuration={4000}
                        onClose={handleSnackbarClose}
                    >
                        <MuiAlert severity={snackBarDetails.type}>{snackBarDetails.errorMessage}</MuiAlert>
                    </Snackbar> : (null)
                }
                <Grid container>
                    <Grid> 
                    <HeaderLayout
                        title='Employee Documents'
                        subTitle=''
                        icon={<PeopleOutlineTwoTone/>}
                    />
                    </Grid>
                </Grid>
                <SearchFilters
                handleFilters={handleFilters}
                setisAuthorised={setisAuthorised}
                setLoggedInUserDetails={setLoggedInUserDetails}
                setIsUserSuperAdmin={setIsUserSuperAdmin}
                setIsHavingPageViewAccess={setIsHavingPageViewAccess}
                setIsHavingPageEditAccess={setIsHavingPageEditAccess}
                />
                { ( isLoading ) 
                ? 
                <div className={classes.loader}><img src={logo} alt="loading..." /></div>
                : 
                <Paper className={classes.pageContent} elevation={5}>
                        <a  className={classes.downloadAnchor} onClick={handleDownloadPendingDocumentReport}>
                            Download Pending Document Report
                        </a>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell>Employee ID</TableCell>
                                    <TableCell>Employee Name</TableCell>
                                    <TableCell>Department</TableCell>
                                    <TableCell>Sub Department</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Document Name</TableCell>
                                    {/* <TableCell style={{textAlign:'center'}}>Action</TableCell> */}
                                    <TableCell>Email</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {console.log(employeeDocumentStatusList)}
                            { ( employeeDocumentStatusList && employeeDocumentStatusList.length > 0 ) ? employeeDocumentStatusList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((element,key)=>(
                                <TableRow key={key}>
                                    <TableCell >{key + 1}</TableCell>
                                    <TableCell>{element.employeeno}</TableCell>
                                    <TableCell>{element.employeeName}</TableCell>
                                    <TableCell>{element.department}</TableCell>
                                    <TableCell>{element.sub_department}</TableCell>
                                    <TableCell> {element.status}</TableCell>
                                    <TableCell> {element.document_name}</TableCell>
                                    <TableCell> 
                                        {isHavingPageEditAccess ? 
                                            <Email onClick={e => handleEmail(element.employeeName, element.emailId, element.document_id, element.employeeno)}></Email>
                                            : '-'
                                        }
                                    </TableCell>
                                </TableRow>
                            )) : (
                                <TableRow>
                                <TableCell><b>No result found</b></TableCell>
                                </TableRow>
                            ) } 
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                    rowsPerPageOptions={[50, 100, 200, 250]}
                    component="div"
                    count={employeeDocumentStatusList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                </Paper>
                }
            </div>
        }
        </div>
    )

}
