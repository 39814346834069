import axios from 'axios'
import url from 'url'

import { joinerApiUrl } from '../../utils/CommonUrl'
import { getAxiosConfig, getRequestHeader } from '../../utils/CommonFunction'

const token = url.parse(window.location.href).pathname.replace('/joiner/dashboard/token/', '')
let axiosConfig = getAxiosConfig({ withCredentials:true });
axiosConfig.headers = getRequestHeader()

const REACT_APP_PIXIE_BASE_URL = process.env.REACT_APP_PIXIE_BASE_URL || 'https://beta.proptiger-ws.com/pixie'

export const getDashboardData = async () => {
    const { OFFER_GET_DATA_URL } = joinerApiUrl
    return axios.get(`${OFFER_GET_DATA_URL}/token/${token}`)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch(err => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}

export const getEmpData = async (empCode) => {
    const { EMP_GET_DATA_URL } = joinerApiUrl
    return axios.get(`${EMP_GET_DATA_URL}/token/${token}`, {params:{empCode}})
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch(err => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}

export const postPersonalData = (dataObj) => {
    const { PERSONAL_POST_DATA_URL } = joinerApiUrl
    return axios.post(`${PERSONAL_POST_DATA_URL}/token/${token}`, dataObj, axiosConfig)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}

export const postContactData = (dataObj) => {
    const { CONTACT_POST_DATA_URL } = joinerApiUrl
    return axios.post(`${CONTACT_POST_DATA_URL}/token/${token}`, dataObj, axiosConfig)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}

export const postEmploymentData = (dataObj) => {
    const { EMPLOYMENT_POST_DATA_URL } = joinerApiUrl
    return axios.post(`${EMPLOYMENT_POST_DATA_URL}/token/${token}`, dataObj, axiosConfig)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}

export const postNomineeData = (dataObj) => {
    const { NOMINEE_POST_DATA_URL } = joinerApiUrl
    return axios.post(`${NOMINEE_POST_DATA_URL}/token/${token}`, dataObj, axiosConfig)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}

export const postEducationData = (dataObj) => {
    const { EDUCATION_POST_DATA_URL } = joinerApiUrl
    return axios.post(`${EDUCATION_POST_DATA_URL}/token/${token}`, dataObj, axiosConfig)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}

export const postWorkHistoryData = (dataObj) => {
    const { WORK_POST_DATA_URL } = joinerApiUrl
    return axios.post(`${WORK_POST_DATA_URL}/token/${token}`, dataObj, axiosConfig)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}

export const postReferenceData = (dataObj) => {
    const { REFERENCE_POST_DATA_URL } = joinerApiUrl
    return axios.post(`${REFERENCE_POST_DATA_URL}/token/${token}`, dataObj, axiosConfig)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}

export const postRelativeData = (dataObj) => {
    const { RELATIVE_POST_DATA_URL } = joinerApiUrl
    return axios.post(`${RELATIVE_POST_DATA_URL}/token/${token}`, dataObj, axiosConfig)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}

export const postChildCareData = (dataObj) => {
    const { CHILD_CARE_POST_DATA_URL } = joinerApiUrl
    return axios.post(`${CHILD_CARE_POST_DATA_URL}/token/${token}`, dataObj, axiosConfig)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}

export const deleteJoinerRecord = (dataObj) => {
    const { DELETE_JOINER_RECORD_URL } = joinerApiUrl
    return axios.delete(`${DELETE_JOINER_RECORD_URL}/token/${token}`, {data: dataObj}, axiosConfig)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}

export const updateStep = (dataObj) => {
    const { UPDATE_JOINER_STEPS_URL } = joinerApiUrl
    return axios.put(`${UPDATE_JOINER_STEPS_URL}/token/${token}`, dataObj, axiosConfig)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}

export const getUploadedFiles = (query) => {
    return axios.get(`${REACT_APP_PIXIE_BASE_URL}/data/v1/entity/document`, { params: query })
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch(err => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}

export const postUploadedFiles = async (fileObj) => {
    return axios.post(`${REACT_APP_PIXIE_BASE_URL}/data/v1/entity/document`, fileObj)
            .then(response => {
                return response
            })
            .catch(err => {
                return err
            })
}