// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
     width: 100%;
}
.fa-trash-alt {
     cursor: pointer;
}
.table-head {
     background: black !important;
     color: white !important;
}
.unorder-list {
     list-style-type:none;
}
.nominee-policy-doc {
     float: right;
     margin-right: 15px;
     margin-bottom: 5px;
     cursor: pointer;
     color: rgb(100, 141, 174);
}

.overflow-div-address {
     width: 225px;
     height: 60px;
     overflow-y: scroll;
     overflow-x: hidden;
     margin-top: 32px;
}
.overflow-div-gaddress {
     width: 225px;
     height: 60px;
     overflow-y: scroll;
     overflow-x: hidden;
}
.header-text {
     font-size: 1.5rem;
}

.loader {
     display: 'flex';
     justify-content: 'center';
 }

`, "",{"version":3,"sources":["webpack://./src/components/Nominee/Nominee.css"],"names":[],"mappings":"AAAA;KACK,WAAW;AAChB;AACA;KACK,eAAe;AACpB;AACA;KACK,4BAA4B;KAC5B,uBAAuB;AAC5B;AACA;KACK,oBAAoB;AACzB;AACA;KACK,YAAY;KACZ,kBAAkB;KAClB,kBAAkB;KAClB,eAAe;KACf,yBAAyB;AAC9B;;AAEA;KACK,YAAY;KACZ,YAAY;KACZ,kBAAkB;KAClB,kBAAkB;KAClB,gBAAgB;AACrB;AACA;KACK,YAAY;KACZ,YAAY;KACZ,kBAAkB;KAClB,kBAAkB;AACvB;AACA;KACK,iBAAiB;AACtB;;AAEA;KACK,eAAe;KACf,yBAAyB;CAC7B","sourcesContent":[".container {\n     width: 100%;\n}\n.fa-trash-alt {\n     cursor: pointer;\n}\n.table-head {\n     background: black !important;\n     color: white !important;\n}\n.unorder-list {\n     list-style-type:none;\n}\n.nominee-policy-doc {\n     float: right;\n     margin-right: 15px;\n     margin-bottom: 5px;\n     cursor: pointer;\n     color: rgb(100, 141, 174);\n}\n\n.overflow-div-address {\n     width: 225px;\n     height: 60px;\n     overflow-y: scroll;\n     overflow-x: hidden;\n     margin-top: 32px;\n}\n.overflow-div-gaddress {\n     width: 225px;\n     height: 60px;\n     overflow-y: scroll;\n     overflow-x: hidden;\n}\n.header-text {\n     font-size: 1.5rem;\n}\n\n.loader {\n     display: 'flex';\n     justify-content: 'center';\n }\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
