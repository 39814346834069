import React, { forwardRef } from 'react';
import './Nominee.css';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DateFnsUtils from '@date-io/date-fns';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import { FormHelperText } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },

  button: {
    margin: theme.spacing(1),
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '100%',
    },
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
    flexGrow: 1,
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const AddNomineeDetailsTable = (props) => {
  const classes = useStyles();
  const {
    loading,
    formInput,
    formErrors,
    handleChange,
    handleInputName,
    dataFromParent,
    handleDateChange,
    handleInputNumber,
    isGuardianRequired,
    handleClick,
    openAddDialog,
    handleAddDialogClose,
    confirmAddDialogue,
    isHavingSelfAddAccess
  } = props;
  return (
    <div>
      <form method='POST' className={classes.root} autoComplete='off'>
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <div style={{ color: '#3f51b5' }}>
                  <ul>
                    <li className='unorder-list'>Please Note:</li>
                    <li>
                      All details must be as per Govt ID proofs (Including House
                      No., Locality, City, State, Pincode).{' '}
                    </li>
                    <li>
                      Nominees can be either Father, Mother, Father in law,
                      Mother in law, Spouse, Brother, Sister, Son, Daughter.
                    </li>
                    <li>Name should be provided as per Aadhaar/PAN Card.</li>
                    <li>
                      If the details submitted are incomplete or incorrect in
                      any manner, then claim will be rejected, so, kindly double
                      check details prior to submitting the same.
                    </li>
                    <li>
                      This nomination supersedes any other nomination given in
                      the past and will be considered as final for Group Term
                      Life, Group Personal accidental insurance, Full & Final
                      Settlement, ESOPs or any other dues (except PF &
                      Gratuity).
                    </li>
                    <li>
                      Contact number of nominees / Guardian should be a ten
                      digit India mobile number, however, in case you wish to
                      mention a landline number or an ISD number, then please
                      mention the same along with appropriate STD or ISD code as
                      applicable.
                    </li>
                    <li>
                      In case you are nominating a Guardian as a primary
                      nominee, then please note that your guardian should have a
                      guardian certificate for claim to be processed.
                    </li>
                  </ul>
                </div>
                <div style={{ textAlign: 'center' }}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      label='Nominee Name'
                      value={formInput.legalName || ''}
                      name='legalName'
                      onChange={handleChange}
                      error={formErrors.legalName ? true : false}
                      helperText={formErrors.legalName || ''}
                      onKeyPress={handleInputName}
                      inputProps={{ minLength: 3, maxLength: 100 }}
                    />
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <InputLabel
                      htmlFor='relation'
                      error={formErrors.relation ? true : false}
                    >
                      Relation
                    </InputLabel>
                    <Select
                      value={formInput.relation || ''}
                      className={classes.selectEmpty}
                      name='relation'
                      onChange={handleChange}
                      input={<Input id='relation' />}
                      error={formErrors.relation ? true : false}
                    >
                      <MenuItem value='' disabled>
                        <em>select the value</em>
                      </MenuItem>
                      {dataFromParent.map((element) => {
                        return (
                          <MenuItem key={element.id} value={element.id}>
                            {element.value}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {formErrors.relation && (
                      <FormHelperText style={{ color: 'red' }}>
                        {formErrors.relation || ''}
                      </FormHelperText>
                    )}
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        label='Date of Birth'
                        format='dd-MM-yyyy'
                        clearable={false}
                        name='dob'
                        value={formInput.dob}
                        onChange={handleDateChange}
                        disableFuture={true}
                        minDate={formInput.minDate || false}
                        inputProps={{ readOnly: true }}
                        error={formErrors.dob ? true : false}
                        helperText={formErrors.dob || ''}
                      />
                    </MuiPickersUtilsProvider>
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <TextField
                      label='Contact No.'
                      value={formInput.phone || ''}
                      name='phone'
                      noValidate
                      onChange={handleChange}
                      error={formErrors.phone ? true : false}
                      helperText={formErrors.phone || ''}
                      onKeyPress={handleInputNumber}
                      inputProps={{ maxLength: 15, minLength: 10 }}
                    />
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <TextField
                      label='Share %'
                      value={formInput.percentage || ''}
                      name='percentage'
                      error={formErrors.percentage ? true : false}
                      helperText={formErrors.percentage || ''}
                      onChange={handleChange}
                      onKeyPress={handleInputNumber}
                    />
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <TextareaAutosize
                      rowsMin={3}
                      rowsMax={4}
                      placeholder='Enter Nominee Address (as per Govt ID)'
                      value={formInput.address || ''}
                      name='address'
                      onChange={handleChange}
                    />
                    {formErrors.address && (
                      <FormHelperText style={{ color: 'red' }}>
                        {formErrors.address || ''}
                      </FormHelperText>
                    )}
                  </FormControl>
                </div>
                {isGuardianRequired ? (
                  <div style={{ textAlign: 'center' }}>
                    <FormControl className={classes.formControl}>
                      <TextField
                        label='Guardian Name'
                        value={formInput.guardianName || ''}
                        name='guardianName'
                        onChange={handleChange}
                        error={formErrors.guardianName ? true : false}
                        helperText={formErrors.guardianName || ''}
                        onKeyPress={handleInputName}
                        inputProps={{ minLength: 3, maxLength: 100 }}
                      />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <TextField
                        label='Guardian Contact No.'
                        value={formInput.guardianPhone || ''}
                        name='guardianPhone'
                        noValidate
                        onChange={handleChange}
                        error={formErrors.guardianPhone ? true : false}
                        helperText={formErrors.guardianPhone}
                        onKeyPress={handleInputNumber}
                        inputProps={{ maxLength: 15, minLength: 10 }}
                      />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <TextareaAutosize
                        rowsMin={3}
                        rowsMax={4}
                        aria-label='maximum height'
                        placeholder='Enter Guardian Address'
                        value={formInput.guardianAddress || ''}
                        name='guardianAddress'
                        onChange={handleChange}
                      />
                      {formErrors.guardianAddress && (
                        <FormHelperText style={{ color: 'red' }}>
                          {formErrors.guardianAddress || ''}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </div>
                ) : null}
                <div style={{ textAlign: 'center' }}>
                  <FormControl className={classes.formControl}>
                    <Button
                      variant='contained'
                      color='primary'
                      size='small'
                      className={classes.button}
                      startIcon={<AddIcon />}
                      type='button'
                      onClick={handleClick}
                      disabled={loading || !isHavingSelfAddAccess}
                    >
                      Add Nominee
                    </Button>
                    {loading && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </FormControl>
                </div>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </form>
      <Dialog
        open={openAddDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleAddDialogClose(false)}
        aria-labelledby='alert-dialog-slide-title-add'
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle id='alert-dialog-slide-title-add'></DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            <Typography component={'span'} style={{ fontSize: '1rem' }}>
              {confirmAddDialogue}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleAddDialogClose(false)} color='secondary'>
            Disagree
          </Button>
          <Button onClick={() => handleAddDialogClose(true)} color='primary'>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddNomineeDetailsTable;
