import React from "react";

const PingTest = () => {
     const PingTestText = {success: true}
     return (
          <div>
               {JSON.stringify(PingTestText)}
          </div>
     )
}

export default PingTest;