import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiTable-root': {
            width: '95%',
            margin: '20px'
        },
        '& .MuiTableCell-root': {
            padding: '2px',
            border: '1px solid grey'
            // margin: theme.spacing(3)
        }
    },
    pageContent: {
        margin: theme.spacing(5),
        padding: theme.spacing(3),
        marginLeft: theme.spacing(1)
        // width: '50%'
    },
    saveButton : {
        margin: theme.spacing(5),
    },
    downloadButton : {
        margin: theme.spacing(5),
    },
    podFormControl: {
        margin: theme.spacing(1),
        width: 260
    },
    skillFormControl: {
        margin: theme.spacing(1),
        width: 120
    },
    loader: {
        display: 'flex',
        justifyContent: 'center'
    },
    table: {
        width: '110%'
    }
}))
