// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  background-color: whitesmoke;
}
.react-calendar-timeline .rct-scroll {
  overflow-x: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/components/Calendar/Calendar.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;AAC9B;AACA;EACE,kBAAkB;AACpB","sourcesContent":[".header {\n  background-color: whitesmoke;\n}\n.react-calendar-timeline .rct-scroll {\n  overflow-x: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
