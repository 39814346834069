import React, { useEffect, useState } from "react"
import url from "url"

import { getSurveyDataByTokenCall } from "../fetchData"
import { getLoggedInUserDetailsFunc } from "../../../utils/authFunction"
import { FillSurvey } from "../FillSurvey" 
import Loader from "../../../components/Loader"
import { SurveyTemplate } from "../SurveyTemplate"
import { TitleComponent } from "../../../components/PageTitle/PageTitle"

const RenderSurvey = () => {
    const token = url.parse(window.location.href).pathname.replace('/fillSurvey/', '')

    if(token == 'c03cd99f-ec70-4e10-962d-62565f7b9e66'){
        window.location.href = "https://hris.proptiger.com/survey/index/pending-survey";
        return <div></div>
    }
    const [surveyData, setSurveyData] =  useState(null)
    const [surveyQueData, setSurveyQueData] =  useState(null)
    const [empDetails, setEmpDetails] =  useState(null)
    const [loginStatus, setLoginStatus] = useState(null)
    const [isSurveyFound, setIsSurveyFound] = useState(null)
    const [isAnonymousSurvey, setIsAnonymousSurvey] = useState(null)
    const [ackMessage, setAckMessage] = useState('')
    
    const { employeeno: loggedInUserId = null } = empDetails || {}
    const { employeeNo: surveyEmpId = null } = surveyData || {}

    useEffect(() => {
            async function getLoggedInUserDetails() {
                const response = await getLoggedInUserDetailsFunc()
                setLoginStatus(response.status)
                if (response.status === 200 && response.data.success) {
                    setEmpDetails(response.data.data)
                }

                return response
            }

            async function fetchDataForFillSurvey() {
                const response = await getSurveyDataByTokenCall({ token })
                setSurveyData(response.surveyData[0])
                setSurveyQueData(response.surveyQuestion[0])
                setIsAnonymousSurvey(response.surveyData[0].is_anonymous)
                setIsSurveyFound(200)
                
                return response
            }
            
            fetchDataForFillSurvey()
            .then(res => {
                if (res.surveyData[0].is_anonymous === 0) {
                    getLoggedInUserDetails()
                    .then()
                    .catch(err => {
                        setAckMessage(err.response.data.message)
                        setLoginStatus(err.response.status)
                    })
                }
            })
            .catch(err => {
                setAckMessage(err.response.data.message)
                setIsSurveyFound(err.response.status)
            })
    }, [])

    const renderFillSurveyPage = () => {
        return  (
                <React.Fragment>
                    <TitleComponent title="REA India | Survey" />
                    <FillSurvey 
                            loginStatus={loginStatus}
                            token={token}
                            empDetails={empDetails}
                            surveyData={surveyData}
                            surveyQueData={surveyQueData}
                        />
                </React.Fragment>
            )
    }

    const redirectToLoginPage = () => {
        return (
             <center><div><b><a href={process.env.REACT_APP_LOGIN_URL} >Login to HRIS</a></b></div></center>
        )
    }

    const surveyNotFoundPage = ({
        message = ""
    }) => {
        return (
            <React.Fragment>
                <TitleComponent title="REA India | Survey" />
                <SurveyTemplate 
                    surveyName={''}
                    description={''}
                    message={message || "The survey isn't available! Please contact with your HRBP!"}
                />
            </React.Fragment>
        )
    }
    
    if (loginStatus === null && isAnonymousSurvey === null && isSurveyFound === null) {
        return <Loader loading={true} />
    } else if (isSurveyFound !== null && isSurveyFound !== 200) {
        return surveyNotFoundPage({ message: ackMessage })
    } else if (isAnonymousSurvey === 0 && loginStatus !== null && loginStatus !== 200) {
        return redirectToLoginPage()
    } else if (isAnonymousSurvey === 0 && loginStatus === 200 && loggedInUserId !== surveyEmpId) {
        return surveyNotFoundPage({ message: ackMessage })
    } else {
        return renderFillSurveyPage()
    }
}

export { RenderSurvey }