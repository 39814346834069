import { makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(0),
      textAlign: 'center',
    },
    paperGeneral: {
      paddingRight: '20px',
      paddingTop: '10px',
      paddingBottom: '10px',
      textAlign: 'left',
      fontSize: '12px',
      fontWeight: 'bold',
      minHeight: '400px',
      maxHeight: '400px',
      overflow: 'auto'
    },
    paperFirst: {
      padding: theme.spacing(1),
      textAlign: 'center',
    },
    formControl: {
      margin: theme.spacing(3),
      minWidth: 120,
    },
    formControlPrevPf: {
      margin: theme.spacing(3),
      minWidth: 230,
    },
    formControlEmailId: {
      margin: theme.spacing(3),
      minWidth: 300,
    },
    formControlLable: {
      textAlign: 'left',
      margin: theme.spacing(3),
      minWidth: 120,
    },
    selectEmpty: {
      width: '120%',
      marginTop: theme.spacing(2),
    },
    formControlSpan: {
      textAlign: 'center',
      margin: theme.spacing(3),
      minWidth: 100,
      fontSize: '14px'
    },
    formControlDoeActive: {
      margin: theme.spacing(2),
      minWidth: 120
    },
    formControlInactive: {
      display: 'none'
    },
    formControlActive: {
      display: ''
    },
    formControlDate: {
      margin: theme.spacing(2),
      minWidth: 120,
    }
}));
  