
export const isEmpty = (val) => (typeof val === 'undefined' || val === null || val.toString().trim() === '')

export const trimMultiSpace = (val) => {
     return val.replace(/ +(?= )/g,'');
}

export const getAxiosConfig = (...params) => {
     let withCredentials = true
     if (params.length) {
          const [ paramObj ] = params
          withCredentials = paramObj
     }
     
     return {
          withCredentials
     }
}

export const getRequestHeader = () => {
     return {
          "Content-Type": "application/json"
     }
}

export const createObjectArrayForSelect = (dataArray) => {
     let result = []
     dataArray.forEach((element, index) => {
          result.push({id:index, value:element})
     });

     return result
}

export const formatDate = value => {
     value =  String(value)
     if (!value.length) {
          return null
     }

     let year = value.substr(0, 4)
     let month = value.substr(4, 2)
     let day = value.substr(6, 2)
     return `${year}-${month}-${day}`
}

export const findRelationById = (relationList, relationId) => {
     if (relationList && relationId) {
          let { relation } = relationList.find(({ id }) => { return id === relationId })
          return relation
     }
     return false;
}

export const arrayDiff = ({
     baseArr = [],
     compArr = []
}) => {
     return compArr.filter(x => !baseArr.includes(x))
}