import React from "react"
import { useStyles } from "./style"
import { Grid, Card, Paper } from "@material-ui/core"
import ReportTable from "./ReportTable"
import NoRecordToDisplay from "./NoRecordToDisplay"
import {
    listingSort,
    getComparator
} from "../../../constants/CommonConstant"
import { getSortBy } from "./Comparison"

const columns = [
    {
        id: "#",
        label: "S.No.",
        disableSorting: true
    },
    {
        id: "employeeno",
        label: "Employee No",
        disableSorting: false
    },
    {
        id: "emp_name",
        label: "Employee Name",
        disableSorting: false
    },
    {
        id: "group_doj",
        label: "Date of Joining",
        disableSorting: false
    },
    {
        id: "department_name",
        label: "Department",
        disableSorting: false
    },
    {
        id: "function_name",
        label: "Sub-Department",
        disableSorting: false
    },
    {
        id: "grade",
        label: "Grade",
        disableSorting: false
    },
    {
        id: "designation",
        label: "Designation",
        disableSorting: false
    },
    {
        id: "location",
        label: "Location",
        disableSorting: false
    },
    {
        id: "location_name",
        label: "Branch",
        disableSorting: false
    },
    {
        id: "emp_manager_name",
        label: "Reporting Manager Name",
        disableSorting: false
    },
    {
        id: "mood_type",
        label: "Mood",
        disableSorting: false
    },
    {
        id: "emp_remarks",
        label: "Employee Remarks",
        disableSorting: false
    },
    {
        id: "tabp_name",
        label: "TABP",
        disableSorting: false
    },
    {
        id: "mood_category",
        label: "Feedback Category",
        disableSorting: false
    },
    {
        id: "tabp_remarks",
        label: "TABP Remarks",
        disableSorting: false
    },
    {
        id: "captured_timestamp",
        label: "Employee Record Time",
        disableSorting: false
    },
    {
        id: "tabp_record_timestamp",
        label: "TABP Record Time",
        disableSorting: false
    },
    {
        id: "tat_format",
        label: "Time taken to close (hh:mm:ss)",
        disableSorting: false
    },
    {
        id: "time_range",
        label: "Time Range",
        disableSorting: false
    }
]

const feelingCols = {
    timeFrame: "Time Frame",
    tabp_name: "TABP",
    tabp: "TABP Ecode",
    department_name: "Department",
    function_name: "Sub Department",
    grade_name: "Grade",
    designation_name: "Designation",
    location_name: "Location",
    rm_name: "Reporting Manager",
    reportingManager: "RM Ecode",
    Sad: "No. of Sad Cases",
    Angry: "No. of Angry Cases",
    total: "Total Cases",
    within36: "Cases Responded within TAT of 36 hrs",
    avgTimeToClose: "Average Time taken to close (hh:mm:ss)",
    casePercentWithin36: "% Cases Responded within TAT of 36 hrs",
}

const totalKeys = ["Angry", "Sad", "total", "totalTat", "within36"]

const getColumns = ({ allFilterData }) => {
    let downloadableColumns = []
    for (const key in feelingCols) {
        if (allFilterData.includes(key)) {
            downloadableColumns.push({
                id: key,
                key: key,
                header: feelingCols[key],
                label: feelingCols[key],
                disableSorting: false
            })
            if (key === "tabp_name") {
                downloadableColumns.push({
                    id: "tabp",
                    key: "tabp",
                    header: feelingCols["tabp"],
                    label: feelingCols["tabp"],
                    disableSorting: false
                }) 
            }
            if (key === "rm_name") {
                downloadableColumns.push({
                    id: "reportingManager",
                    key: "reportingManager",
                    header: feelingCols["reportingManager"],
                    label: feelingCols["reportingManager"],
                    disableSorting: false
                }) 
            }
        }
    }
    
    return downloadableColumns
}

const getObjectToArrayList = ({ data = {}, dynamicColumns = [], allFilterData }) => {
    let txnRows = []
    let totalRow = {
        total: 0,
        within36: 0,
        totalTat: 0,
        avgTimeToClose: "",
        casePercentWithin36: "",
        timeFrameOrder: ""
    }
    
    for (const ordered in data) {
        const rowObj = data[ordered]
        let pushObj = { timeFrameOrder: rowObj.timeFrameOrder || "" }
        for (const colObj of dynamicColumns) {
            pushObj[colObj.key] = rowObj[colObj.key]
            if (colObj.key === "avgTimeToClose") {
                pushObj[colObj.key] = tatFormatting(getHourMinuteSecond(rowObj.totalAvgTat))
            }
            
            if (totalKeys.includes(colObj.key)) {
                if (!totalRow[colObj.key]) {
                    totalRow[colObj.key] = 0
                }

                totalRow[colObj.key] += rowObj[colObj.key]
            }
        }

        if (rowObj.totalTat) {
            totalRow.totalTat += rowObj.totalTat
        }
        
        txnRows.push({ ...pushObj })
    }

    totalRow[dynamicColumns[0].key] = "Total"
    totalRow.casePercentWithin36 = totalRow.total ? `${((totalRow.within36*100)/totalRow.total).toFixed(2)}%` : "0.00%"
    const totalAvgTat = totalRow.total ? totalRow.totalTat/totalRow.total : 0
    totalRow.avgTimeToClose = tatFormatting(getHourMinuteSecond(totalAvgTat))
    
    const sortBy = getSortBy({ allFilterData })

    txnRows = listingSort(txnRows, getComparator("asc", sortBy))
    
    return { txnRows, totalRow }
}

const getHourMinuteSecond = totalSeconds => {
    if (!totalSeconds) {
        return { hours:0, minutes:0, seconds:0 }
    }

    totalSeconds = Math.floor(totalSeconds)
    let minutes = Math.floor(totalSeconds / 60)
    const hours = Math.floor(minutes / 60)
    const seconds = totalSeconds % 60
    minutes = minutes % 60

    return { hours, minutes, seconds }
}

const tatFormatting = ({ hours, minutes, seconds }) => {
    hours = `${hours}`.length === 1 ? `0${hours}` : `${hours}`
    minutes = `${minutes}`.length === 1 ? `0${minutes}` : `${minutes}`
    seconds = `${seconds}`.length === 1 ? `0${seconds}` : `${seconds}`

    return `${hours}:${minutes}:${seconds}`
}

const FeelingAtHomeData = ({
    detailedData,
    handleDetailedData
}) => {
    const classes = useStyles()
    let { 
        feelAtHomeDetailedReport = [], 
        allFilterData = [],
        totalRowRecord = {},
        totalColName = "",
        isCompare = false,
        isCompareTotal = false
    } = detailedData
    
    let dynamicColumns = columns
    let records = feelAtHomeDetailedReport
    let totalRowRec = {}

    if (allFilterData.length) {
        dynamicColumns = getColumns({ allFilterData })
        let { txnRows, totalRow } = getObjectToArrayList({ data: feelAtHomeDetailedReport, dynamicColumns, allFilterData })
        if (isCompare && isCompareTotal && totalRowRecord && Object.keys(totalRowRecord).length) {
            let compareTotal = getObjectToArrayList({ data: totalRowRecord, dynamicColumns, allFilterData })
            totalRow = compareTotal.totalRow
            isCompareTotal = true
            totalRow[dynamicColumns[0].key] = totalColName
        } else if (isCompare && !isCompareTotal) {
            totalRow = {}
        }    
        
        records = txnRows
        totalRowRec = totalRow
    }

    const goBack = (pageName) => {
        handleDetailedData(pageName, true)
    }
    
    const recLen = records.length

    return (<React.Fragment>
        <Grid item xs={12} sm={12} md={12} className={classes.youAreAwesomeTableGridBody}>
        <Card>
            <Paper>
                {
                    recLen ? (<ReportTable 
                        rows={records}
                        columns={dynamicColumns}
                        totalRow={totalRowRec}
                        reportName="Feeling At Home"
                        goBack={goBack}
                        pages={[ 50, 100, 500, 1000 ]}
                        isCompareTotal={isCompareTotal}
                    />) : <NoRecordToDisplay goBack={goBack} />
                }
                
            </Paper>
        </Card>
        </Grid>
    </React.Fragment>)
}

export default FeelingAtHomeData