import React from 'react';
import { Component } from 'react';
import { Switch, Route } from "react-router-dom";
import { CircularProgress } from '@material-ui/core';
import Calendar from '../components/Calendar/Calendar';
import Nominee from '../components/Nominee/Nominee';
import Campaign from '../components/Campaign/Campaign';
import Dashboard from '../components/Campaign/Dashboard/DashboardComponent'
import AllCampaign from '../components/Campaign/AllCampaign/AllCampaignComponent'
import EmployeeTagging from '../components/EmployeeTagging/EmployeeTaggingComponent'
import { userApiUrl } from '../utils/CommonUrl';
import { getAxiosConfig, getRequestHeader } from '../utils/CommonFunction';
import Efiling from '../components/Efiling/Efiling'
import SurveyJs from '../components/SurveyJs/SurveyJs'
import HrisRoleAccess from '../components/HrisRoleAccess/HrisRoleAccess'
import OrganizationChart from '../components/OrgChart/OrganizationChartComponent'
import axios from 'axios';
import Probation from '../components/Probation/Probation';
import { Survey } from "../pages/Survey"
import { empHasRightsToAccess } from '../utils/authFunction';
import PendingTaskList from "../pages/HrDashboard/PendingTaskList"
import ReportAnalytics from "../pages/HrDashboard/ReportAnalytics"
import CeoLetter from "../pages/Onboarding/CeoLetter"

const Routes = ()=>{
     return(
          <Switch>
               <ProtectedRoute 
                    path="/calendar" 
                    component={ Calendar }
                    moduleName="calendar" 
               />
               <ProtectedRoute 
                    path="/nomineeDetails" 
                    component={ Nominee } 
                    moduleName="nomineeDetails" 
               />
               <ProtectedRoute 
                    path="/createCampaign" 
                    component={ Campaign }
                    moduleName="createCampaign" 
               />
               <ProtectedRoute 
                    path="/dashboard" 
                    component={ Dashboard } 
                    moduleName="dashboard"
               />
               <ProtectedRoute 
                    path="/allCampaign" 
                    component={ AllCampaign } 
                    moduleName="allCampaign"
               />
               <ProtectedRoute 
                    path="/employeeTagging/:type" 
                    component={ EmployeeTagging }
               />
               <ProtectedRoute 
                    path="/employeeTagging" 
                    component={ EmployeeTagging } 
                    moduleName="employeeTagging"
               />
               <ProtectedRoute 
                    path="/efiling/:type" 
                    component={ Efiling } 
               />
               <ProtectedRoute 
                    path="/orgChart" 
                    component={ OrganizationChart } 
                    moduleName="orgChart"
               />
               <ProtectedRoute 
                    path="/addQuestionToSurvey/:surveyId"
                    component={ Survey }
                    moduleName="addQuestionToSurvey"
               />
               <ProtectedRoute 
                    path="/previewSurvey/:surveyId"
                    component={ Survey }
                    moduleName="previewSurvey"
               />
               <ProtectedRoute 
                    path="/surveyjs/:type" 
                    component={ SurveyJs } 
               />
               <ProtectedRoute 
                    path="/probation/:type/r/:role" 
                    component={ Probation } 
               />
               <ProtectedRoute 
                    path="/probation/:type" 
                    component={ Probation } 
               />
               <ProtectedRoute 
                    path="/pendingTaskList" 
                    component={ PendingTaskList } 
               />
               <ProtectedRoute 
                    path="/reportAnalytics" 
                    component={ ReportAnalytics } 
                    />
               <ProtectedRoute 
                    path="/hrisRoleAccess/:type" 
                    component={ HrisRoleAccess } 
               />
               <ProtectedRoute 
                    path="/ceoLetter"
                    component={CeoLetter}
                    moduleName="ceoLetter"
               />
          </Switch>
     )
}

class ProtectedRoute extends Component {
     constructor(props) {
          super(props);
          this.state = {
               isAuthenticated : false,
               loading : true,
               empDetails: null
          };
          this.getUserDetails = this.getUserDetails.bind(this);
     }

     componentDidMount() {
       this.getUserDetails();
     }

     async getUserDetails() {
          try {
               const config = getAxiosConfig();
               config.headers = getRequestHeader();
               let response = await axios.get(userApiUrl.USER_DATA_URL , config);
               
               const { status, data = {} } = response;
               if (status === 200) {
                    this.setState({
                         isAuthenticated : true,
                         loading: false,
                         empDetails: data.data
                    });
               }
          } catch (error) {
               this.setState({
                    isAuthenticated : false,
                    loading: false
               });
          }
     }

     render() {
          const { loading, isAuthenticated, empDetails } = this.state;
          const { component: MyComponent, moduleName } = this.props;
          let componentProps = { empDetails, ...this.props }

          let isAllowed = false
          if (isAuthenticated) {
               isAllowed = empHasRightsToAccess({moduleName, empRoles: empDetails.roles})
          }
          
          return (
               loading ? <CircularProgress /> 
               : <Route 
                    render = { () => isAuthenticated && isAllowed 
                         ? (<MyComponent { ...componentProps } />)
                         : (window.location = process.env.REACT_APP_LOGIN_URL) } 
               />
          )
     }
}
export default Routes;