import React, { useState } from "react"
import { Grid, TableContainer, Table, TableBody } from "@material-ui/core"
import CommonTableHeader from "./CommonTableHeader"
import CommonTablePagination from "./CommonTablePagination"
import CommonTableBody from "./CommonTableBody"

import {
    listingSort,
    getComparator
} from "../../../constants/CommonConstant"


const getPagesCount = ({ pages, totalCount = 0 }) => {
    if (totalCount > 1000) {
        pages.push(totalCount)
    }

    return pages
}


const CommonTable = ({
    rows = [],
    columns = [],
    totalRow = {},
    pages = [ 50, 100, 500, 1000 ],
    minHeight = "338px",
    maxHeight = "338px",
    handleClick,
    actionId = "",
    actionName = ""
}) => {
    pages = getPagesCount({ pages, totalCount: rows.length })
    const [order, setOrder] = useState()
    const [orderBy, setOrderBy] = useState()

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(pages[page])

    const handleSortRequest = cellId => {
        const isAsc = orderBy === cellId && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(cellId)
    }

    const record = () => {
        return listingSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, (page + 1) * rowsPerPage)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }


    const records = record()
    const recordCount = records.length
    const colCount = columns.length
    const totalDataLen = Object.keys(totalRow).length

    return (
        <React.Fragment>
            {
                <Grid container>
                    <Grid item xs={12} sm={12} md={8}></Grid>
                    <Grid item xs={12} sm={12} md={3}>
                        <Grid style={{ textAlign: "right", padding: "10px" }}>
                            {/** Download Option Here */}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={1}>
                        {/** Go Back Action Here */}
                    </Grid>
                </Grid>
            }
            <TableContainer style={{ minHeight, maxHeight }}>
                <Table stickyHeader aria-label="customized table">
                    <CommonTableHeader 
                        order={order}
                        orderBy={orderBy}
                        columns={columns}
                        handleSortRequest={handleSortRequest}
                    />
                    <TableBody>
                    {
                        recordCount ? (
                            <React.Fragment>
                            { records.map((rowObj, i) => 
                                <CommonTableBody 
                                    key={i}
                                    row={rowObj}
                                    idx={i}
                                    columns={columns}
                                    recCount={recordCount}
                                    colCount={colCount}
                                    actionId={actionId}
                                    actionName={actionName}
                                    handleClick={handleClick}
                                    page={page}
                                    pages={pages}
                                    rowsPerPage={rowsPerPage}
                                />)
                            }
                            {(recordCount > 1 && totalDataLen) ? <CommonTableBody 
                                isTotalRow={true}
                                row={totalRow}
                                idx={recordCount+1}
                                columns={columns}
                                recCount={recordCount}
                                colCount={colCount}
                            /> : null}
                            </React.Fragment>
                        ) : (<CommonTableBody
                            row={{}}
                            idx={1}
                            columns={columns}
                            recCount={recordCount}
                            colCount={colCount}
                        />)
                    }
                    </TableBody>
                </Table>
            </TableContainer>
            {
                recordCount ? (<CommonTablePagination
                    page={page}
                    pages={pages}
                    rowsPerPage={rowsPerPage}
                    rows={rows}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                />) : null
            }
        </React.Fragment>
    )
}

export default CommonTable