import { makeStyles } from '@material-ui/core/styles'
import { FullscreenExit } from '@material-ui/icons'

export const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiTableCell-root': {
          padding: '10px'
        }
    },
    pageContent: {
        margin: theme.spacing(5),
        padding: theme.spacing(3),
        marginLeft: theme.spacing(1),
        marginTop: '0px'
        // width: '50%'
    },
    saveButton : {
        margin: theme.spacing(5),
        color: 'white',
        backgroundColor: 'blue',
        float: 'right'
    },
    uploadButton : {
        color: 'black',
        backgroundColor: 'lightgreen',
        '&:hover': {
            backgroundColor: 'lightgreen',
        }
    },
    modifyButton : {
        color: 'black',
        backgroundColor: 'lightgrey',
    },
    loader: {
        display: 'flex',
        justifyContent: 'center'
    },
    table: {
        width: '110%'
    },
    input: {
        display: "none"
    },
    button: {
        margin: theme.spacing(3),
        padding: theme.spacing(1)
    },
    deleteDiv : {
        marginTop: theme.spacing(1),
        display: 'flex'
    },
    gridItem : {
        display: 'flex',
        justifyContent: 'center'
    }
}))