import React from "react"
import { Grid, Card, Paper } from "@material-ui/core"
import { useStyles } from "./style"
import ReportTable from "./ReportTable"
import NoRecordToDisplay from "./NoRecordToDisplay"

import {
    listingSort,
    getComparator
} from "../../../constants/CommonConstant"
import { getSortBy } from "./Comparison"


const obFeedbackCols = {
    timeFrame: "Time Frame",
    tabp_name: "TABP",
    tabp: "TABP Ecode",
    department_name: "Department",
    function_name: "Sub Department",
    grade: "Grade",
    designation: "Designation",
    location_name: "Location",
    rm_name: "Reporting Manager",
    reportingManager: "RM Ecode",
    noOfJoiners: "No. of Eligible Joiner(s)",
    noOfResponse:"No of Response(s)"
}

const getColumns = ({ allFilterData, questionNames }) => {

    let downloadableColumns = []

    for (const key in obFeedbackCols) {
        if (allFilterData.includes(key)) {
            downloadableColumns.push({
                id: key,
                key: key,
                header: obFeedbackCols[key],
                label: obFeedbackCols[key],
                disableSorting: false
            })

            if (key === "tabp_name") {
                downloadableColumns.push({
                    id: "tabp",
                    key: "tabp",
                    header: obFeedbackCols["tabp"],
                    label: obFeedbackCols["tabp"],
                    disableSorting: false
                }) 
            }
            if (key === "rm_name") {
                downloadableColumns.push({
                    id: "reportingManager",
                    key: "reportingManager",
                    header: obFeedbackCols["reportingManager"],
                    label: obFeedbackCols["reportingManager"],
                    disableSorting: false
                }) 
            }
        }
    }

    for (const qId in questionNames) {
        downloadableColumns.push({
            id: qId,
            key: qId,
            header: questionNames[qId],
            label: questionNames[qId],
            disableSorting: false
        })
    }

    downloadableColumns.push({
        id: "overall_avg",
        key: "overall_avg",
        header: "Overall Average",
        label: "Overall Average",
        disableSorting: false
    })
    
    return downloadableColumns
}

const getObjectToArrayList = ({ data = {}, dynamicColumns = [], questionNames, feedbackReport, allFilterData }) => {
    let txnRows = []
    let totalRow = { overall_avg: 0 }
    
    const queIdList = Object.keys(questionNames)
    const allRowCount = Object.keys(data).length
    
    for (const ordered in data) {
        const rowObj = data[ordered]
        const { avgRatings, totalAvg } = rowObj
        let pushObj = { timeFrameOrder: rowObj.timeFrameOrder || "" }

        for (const colObj of dynamicColumns) {
            if (queIdList.includes(colObj.key)) {
                pushObj[colObj.key] = parseFloat(avgRatings[colObj.key]).toFixed(2)
            } else {
                pushObj[colObj.key] = rowObj[colObj.key]
                if (colObj.key === "noOfJoiners") {
                    totalRow[colObj.key] = feedbackReport["noOfJoiners"]
                } else if (colObj.key === "noOfResponse") {
                    totalRow[colObj.key] = feedbackReport["noOfResponse"]
                } else if (colObj.key === "overall_avg") {
                    pushObj[colObj.key] = parseFloat(totalAvg).toFixed(2)
                } else {
                    totalRow[colObj.key] = ""
                }
            }
        }
        
        txnRows.push({ ...pushObj })
    }
    
    for (const qId in questionNames) {
        totalRow[qId] = parseFloat(feedbackReport.avgRatings[qId]).toFixed(2)
    }
    
    totalRow["overall_avg"] = parseFloat(feedbackReport.totalAvg).toFixed(2)
    totalRow[dynamicColumns[0].key] = "Total"
    
    const sortBy = getSortBy({ allFilterData })
    txnRows = listingSort(txnRows, getComparator("asc", sortBy))

    return { txnRows, totalRow, allRowCount }
}

const SixtyFeedbackData = ({ 
    detailedData = {},
    handleDetailedData = () => {}
}) => {
    const classes = useStyles()

    let { 
        allFilterData = [], 
        finalProcessedData = {}, 
        questionNames = {}, 
        feedbackReport = {},
        totalRowRecord = {},
        totalColName = "",
        isCompare = false,
        isCompareTotal = false
    } = detailedData
    const dynamicColumns = getColumns({ allFilterData, questionNames })
    let { txnRows, totalRow } = getObjectToArrayList({ data: finalProcessedData, dynamicColumns, questionNames, feedbackReport, allFilterData })
    
    if (isCompare && isCompareTotal && totalRowRecord && Object.keys(totalRowRecord).length) {
        let compareTotal =  getObjectToArrayList({ data: totalRowRecord, dynamicColumns, questionNames, feedbackReport, allFilterData })
        totalRow = compareTotal.totalRow
        isCompareTotal = true
        totalRow[dynamicColumns[0].key] = totalColName
    } else if (isCompare && !isCompareTotal) {
        totalRow = {}
    } 

    const txnRowsLen = txnRows.length

    const goBack = (pageName) => {
        handleDetailedData(pageName, true)
    }

    return (<React.Fragment>
        <Grid item xs={12} sm={12} md={12} className={classes.youAreAwesomeTableGridBody}>
            <Card>
                <Paper>
                {
                    txnRowsLen ? <ReportTable 
                    rows={txnRows}
                    columns={dynamicColumns}
                    totalRow={totalRow}
                    reportName="60 Day Feedback"
                    isCompareTotal={isCompareTotal}
                    goBack={goBack}
                    /> : <NoRecordToDisplay goBack={goBack} />
                }
            </Paper>
        </Card>
        </Grid>
    </React.Fragment>)
}

export default SixtyFeedbackData