import { commonConst } from '../constants/CommonConstant'
const { 
     nameWithSpaceRegex,
     panRegEx,
     adharRegEx,
     pinCodeRegex,
     addressRegEx,
     bankNameRegex,
     bankAccountNumberRegEx,
     bankIfscCodeRegEx,
     mobileRegEx,
     landlineRegEx,
     pfRegEx,
     uanRegEx,
     passportNumberRegEx,
     sharePercentRegEx,
     instituteNameRegex,
     emailRegEx,
     numberWithDecimalRegEx,
     amountRegEx,
     experienceRegEx,
     marksRegEx
} = commonConst

export const onlyNumber = (charCode) => charCode >= 48 && charCode <= 57

export const onlyAlphabets = (charCode) => ((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123) || charCode === 32)

export const onlyAlphabetsWithNumber = (charCode) => ((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123) || (charCode >= 48 && charCode <= 57))

export const onlyNumberWithSpecialChar = (charCode, specialChars = []) => {
     return (charCode >= 48 && charCode <= 57 ) || specialChars.includes(charCode);
}

export const checkSpecialOccurenceInPhoneNumber = (string, specialChars) => {
     let flag = false;
     string.split('').forEach((val, index) => { 
          if (index && val === specialChars && string[index-1] === string[index]) {
               flag = true;
          }
     });

     return flag;
}

export const onlyAlphabetsWithoutSpace = (charCode) => ((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123))

export const checkNameRegEx = value => nameWithSpaceRegex.test(value)

export const checkPanRegEx = value => panRegEx.test(value)

export const checkAdharRegEx = value => adharRegEx.test(value)

export const checkPinCodeRegEx = value => pinCodeRegex.test(value)

export const checkAddressRegEx = value => addressRegEx.test(value)

export const onlyNumberForContact = (charCode) => ((charCode >= 48 && charCode <= 57) || charCode === 43 || charCode === 45)

export const charOnlyForRelationship = (charCode) => ((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123) || charCode === 32 || charCode === 45)

export const checkBankNameRegEx = (value) => bankNameRegex.test(value)

export const checkBankAccountNumberRegEx = (value) => bankAccountNumberRegEx.test(value)

export const checkBankIfscCodeRegEx = (value) => bankIfscCodeRegEx.test(value)

export const checkMobileRegEx = (value) => mobileRegEx.test(value)

export const checkLandlineRegEx = (value) => landlineRegEx.test(value)

export const checkPfRegEx = (value) => pfRegEx.test(value)

export const checkUanRegEx = (value) => uanRegEx.test(value)

export const checkPassportNumberRegEx = (value) => passportNumberRegEx.test(value)

export const charAllowedForPF = (charCode) => ((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123) || (charCode >= 48 && charCode <= 57) || charCode === 45 || charCode === 47)

export const checkSharePercentRegEx = (value) => sharePercentRegEx.test(value)

export const checkInstituteNameRegex = (value) => instituteNameRegex.test(value)

export const checkEmailRegEx = (value) => emailRegEx.test(value)

export const checkNumberWithDecimalRegEx = (value) => numberWithDecimalRegEx.test(value)

export const checkAmountRegEx = (value) => amountRegEx.test(value)

export const checkExperienceRegEx = (value) => experienceRegEx.test(value)

export const checkMarksRegEx = (value) => marksRegEx.test(value)
