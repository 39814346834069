import React from "react"
import { 
    Grid,
    Card,
    Table, TableBody, TableRow, TableCell,
    Paper,
    Divider
} from "@material-ui/core"
import { useStyles } from "./style"
import WowImage from "../../../assets/feelingAtHome/WOW.jpg"
import HappyImage from "../../../assets/feelingAtHome/Happy.jpg"
import NeutralImage from "../../../assets/feelingAtHome/Neutral.jpg"
import SadImage from "../../../assets/feelingAtHome/Sad.jpg"
import AngryImage from "../../../assets/feelingAtHome/Angry.jpg"

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js"
import { Bar } from "react-chartjs-2"

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
)

const emojiIcon = {
    "WOW": WowImage,
    "Happy": HappyImage,
    "Neutral": NeutralImage,
    "Sad": SadImage,
    "Angry": AngryImage,
}

const options = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 1,
    plugins: {
        legend: {
            display: false
        }
    },
    scales: {
        x: {
            grid: {
                display: false
            },
            ticks: {
                display: true,
            }
        },
        y: {
            beginAtZero: true,
            border: {
                dash: [2, 4],
            },
            ticks: {
                precision: 0
            }
        }
    }
}

const colorScheme = {
    "Sad" : "#483AFF",
    "Angry" : "#C893FE"
}


const getGraphLabelList = () => {
    return ["0-12 hrs", "+12-24 hrs", "+24-36 hrs", "+36 hrs"]
} 

const getDataSets = ({ labels, records }) => {
    let datasets = [{
        label: "Sad",
        data: [],
        backgroundColor: [colorScheme["Sad"]],
        barPercentage: 0.9,
        categoryPercentage: 0.4,
        borderRadius: 5,
        borderSkipped: false
    },
    {
        label: "Angry",
        data: [],
        backgroundColor: [colorScheme["Angry"]],
        barPercentage: 0.9,
        categoryPercentage: 0.4,
        borderRadius: 5,
        borderSkipped: false
    }
]
    for (const labelName of labels) {
        datasets[0].data.push(records[labelName]["Sad"])
        datasets[1].data.push(records[labelName]["Angry"])
    }

    return datasets
}

const FeelingAtHomeCard = ({ 
    feelAtHomeReport = {},
    handleDetailedData = () => {}
}) => {
    const classes = useStyles()
    const { tatCount, avgTat, moodCount } = feelAtHomeReport

    const labels = getGraphLabelList()
    const datasets = getDataSets({ labels, records: tatCount })

    return (<Grid item xs={12} sm={12} md={12} className={classes.gridBody} style={{ cursor: "pointer" }}>
                <Card style={{ height: "490px" }}>
                    <div onClick={e => handleDetailedData("FeelingAtHome")}>
                        <Grid container>
                            <Grid xs={8} sm={8} md={8} item>
                                    <div style={{ padding: "5 0 5 5" }}>
                                        <label style={{ fontSize: "1.17em", fontWeight: "bold" , cursor: "pointer" }}>Feeling @ Home</label>
                                        <br/>
                                        <span style={{ color:"#9A99AA", fontSize: "11px", cursor: "pointer"  }}>TAT for Angry And Sad Cases</span>
                                    </div>

                                    <div style={{ padding: "5 0 5 5" }}>
                                        <Divider />
                                    </div>

                                    <div style={{ float: "right", padding: "0 10 2 0" }}>
                                        <span style={{ height: "7px", width: "7px", backgroundColor: `${colorScheme["Sad"]}`, borderRadius: "50%", display: "inline-block" }}></span>
                                        &nbsp; <span style={{ fontSize: "11px" }}>Sad</span>
                                        <br/>
                                        <span style={{ height: "7px", width: "7px", backgroundColor: `${colorScheme["Angry"]}`, borderRadius: "50%", display: "inline-block" }}></span>
                                        &nbsp; <span style={{ fontSize: "11px" }}>Angry</span>
                                    </div>

                                    <div>
                                        <Paper style={{ width: "318px", height: "330px", padding: "5 0 0 5" }}>
                                            <Bar options={options} data={{ labels, datasets }} />
                                        </Paper>
                                    </div>

                                    <div style={{ padding: "30 0 10 50" }}>
                                        <span>
                                            <span style={{ height: "7px", width: "7px", backgroundColor: `${colorScheme["Sad"]}`, borderRadius: "50%", display: "inline-block" }}></span>
                                            &nbsp;&nbsp;
                                            <span style={{ fontSize: "11px" }}>Average TAT for Sad Cases: {`${avgTat.Sad.hours}hrs ${avgTat.Sad.minutes}m`}</span>
                                        </span>
                                        <br/>
                                        <span>
                                            <span style={{ height: "7px", width: "7px", backgroundColor: `${colorScheme["Angry"]}`, borderRadius: "50%", display: "inline-block" }}></span>
                                            &nbsp;&nbsp;
                                            <span style={{ fontSize: "11px" }}>Average TAT for Angry Cases:  {`${avgTat.Angry.hours}hrs ${avgTat.Angry.minutes}m`}</span>
                                        </span>
                                        <br/>
                                        <span>
                                            <span style={{ height: "7px", width: "7px", background: `linear-gradient( 90deg, ${colorScheme["Angry"]} 50%, ${colorScheme["Angry"]} 50%, ${colorScheme["Sad"]} 50%, ${colorScheme["Sad"]} 50% )`, borderRadius: "50%", display: "inline-block" }}></span>
                                            &nbsp;&nbsp;
                                            <span style={{ fontSize: "11px" }}>Average TAT for Angry & Sad Cases:  {`${avgTat.AngryNSad.hours}hrs ${avgTat.AngryNSad.minutes}m`}</span>
                                        </span>
                                    </div>

                                </Grid>

                                <Grid xs={1} sm={1} md={1} item style={{ padding: "0 0 0 10" }}>
                                    <Divider orientation="vertical" />
                                </Grid>
                                
                                <Grid xs={3} sm={3} md={3} item style={{ padding: "50 0 0 0" }}>
                                    <div style={{ border: "1px solid #4C32FF", width: "80px", textAlign: "center", padding: "5px", borderRadius: "10px", backgroundColor: "#F3F1FF" }}>
                                        <span style={{ fontSize: "10px", fontWeight: "bold", color: "#4C32FF" }}>Total Expressions</span>
                                    </div>
                                    <div style={{ padding: "8 0 0 0" }}>
                                    <Table>
                                        <TableBody>
                                        {Object.keys(moodCount).map((key, index) => {
                                            return <TableRow key={index} >
                                                    <TableCell style={{ width: "1%" }} className={classes.expressionCell}><span><img src={emojiIcon[key]} alt={key} style={{ width: "15px", borderRadius: "50%" }}/></span></TableCell>
                                                    <TableCell style={{ width: "5%" }} className={classes.expressionCell}><span style={{ fontSize: "10px", fontWeight: "bold" }}>{`${key}`}</span></TableCell>
                                                    <TableCell className={classes.expressionCell}><span style={{ fontSize: "10px" }}>{`${moodCount[key]}`}</span></TableCell>
                                                </TableRow>
                                        })}
                                        </TableBody>
                                    </Table>
                                    </div>
                                </Grid>
                        </Grid>
                    </div>
                </Card>
            </Grid>
        )
}

export default FeelingAtHomeCard