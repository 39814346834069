import React, { useState, useEffect, Fragment } from "react"
import moment from 'moment-timezone'
import { CSVLink } from 'react-csv'

import { 
    Grid,TableBody, TableRow, TableCell, Paper, Card, TableHead, Table, TableContainer,
    Button
} from "@material-ui/core"
import { useStyles } from "./style"

import PendingTableHeader  from "./PendingTableHeader"
import PendingTableBody from "./PendingTableBody"
import PendingDetailPagination from "./PendingDetailPagination"
import './index.css';

import {
    listingSort,
    getComparator
} from "../../../constants/CommonConstant"

const fixedRowCont = 5
const pages = [50, 100, 500, 1000]


const PendingCard = ({ 
    listItem,
    index,
    handleViewMore,
    isDetailedPage = false,
    pageNames
}) => {
    const classes = useStyles()
    const { 
        labelName = '',
        pendingCount = 0,
        columns = [],
        list = [],
        redirectLink
    } = listItem
    
    const recCount = list.length

    const [order, setOrder] = useState()
    const [orderBy, setOrderBy] = useState()
    const [rows, setRows] = useState([])

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(pages[page])

    const isMoreBtn = recCount ? true : false

    useEffect(() => {
        let recRows = []
        if (isDetailedPage) {
            recRows = list
        } else {
            recRows = getFiveRows({ list, columns, recCount })
        }
        setRows(recRows)
    }, [isDetailedPage])

    const getTableHeader = ({
        labelName = "",
        pendingCount = 0,
        redirectLink,
        colCount
    }) => {
        return (<TableHead stickyheader={"stickyheader"} aria-label="sticky table">
                    <TableRow key={"Header_Row_1"}>
                        <TableCell style={{ color: '#4559C9', fontWeight: "bold", fontSize: "14px", padding: "7px" }} colSpan={colCount - 1} rowSpan={2}>
                            {redirectLink ? <span><a target="_blank" className={classes.anchorLink} href={redirectLink}>{labelName}</a></span> : <span>{labelName}</span>}
                        </TableCell>
                        <TableCell style={{ textAlign: "center", border: "0", fontSize: "12px", padding: "0px" }}>Total Count</TableCell>
                    </TableRow>
                    <TableRow key={"Header_Row_2"}>
                        <TableCell style={{ textAlign: "center", fontWeight: "bold", fontSize: "14px", padding: "0px" }}>{pendingCount}</TableCell>
                    </TableRow>
                </TableHead>)
    }

    const getFiveRows = ({ list, columns }) => {
        let result = [...list]
        result = list.slice(0, fixedRowCont)
        // let rowLen = result.length
        // if (rowLen<fixedRowCont) {
        //     let fixedObj = {}
        //     columns.map(cell => fixedObj[cell.id] = "")
        //     while (rowLen < fixedRowCont) {
        //         result.push(fixedObj)
        //         rowLen++
        //     }
        // }
        return result
    }

    const handleSortRequest = cellId => {
        const isAsc = orderBy === cellId && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(cellId)
    }

    const record = () => {
        return listingSort(rows, getComparator(order, orderBy)).slice(
          page * rowsPerPage,
          (page + 1) * rowsPerPage
        )
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const getSummaryPage = (props) => {
        let { 
            labelName, 
            pendingCount, 
            columns, 
            redirectLink,
            order,
            orderBy,
            recCount,
            handleSortRequest,
            isMoreBtn
        } = props

        //~ Removed designation column for summary page
        columns =  columns.filter(obj =>  ["emp_code", "emp_name", "pending_days"].includes(obj.id))
        let colCount = columns.length
        const records = record()
        let remainingCount = recCount < fixedRowCont ? (fixedRowCont - recCount) : 0

        while (remainingCount) {
            records.push(remainingCount)
            remainingCount--
        }

        return (<Grid item xs={12} sm={6} md={6} className={classes.gridBody}>
            <Card>
                <Paper>
                    <TableContainer style={{ minHeight: "338px", maxHeight: "338px"}}>
                        <Table aria-label="customized table">
                            {getTableHeader({ 
                                labelName, 
                                pendingCount, 
                                columns, 
                                redirectLink,
                                order,
                                orderBy,
                                recCount,
                                colCount,
                                handleSortRequest
                            })}
                            <PendingTableHeader 
                                columns={columns}
                                order={order}
                                orderBy={orderBy}
                                handleSortRequest={handleSortRequest}
                                index={index}
                            />
                            <TableBody>
                                { records.map((row, idx) => <PendingTableBody
                                        key={idx}
                                        row={row}
                                        index={index+idx}
                                        columns={columns}
                                        idx={idx}
                                        recCount={recCount}
                                        redirectLink={redirectLink}
                                    />)
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    { 
                    <TableContainer style={{ minHeight: "60px", maxHeight: "60px"}}>
                        <Table>
                            <TableHead>
                                <TableRow style={{ height: "50px" }}>
                                        <TableCell style={{ textAlign: "center", border: "none", marginTop: "10px", padding: "5px" }} colSpan={colCount}>
                                            { isMoreBtn ? <Button 
                                                onClick={()=>handleViewMore(labelName)}
                                                style={{ backgroundColor: '#4559C9', color: "white", padding: "5px", borderRadius: "5px", textTransform: "none" }}
                                                >Click here to view the complete details</Button> : (null)
                                            }
                                        </TableCell>
                                </TableRow>
                            </TableHead>
                        </Table>
                    </TableContainer>
                }
                </Paper>
            </Card>
        </Grid>)
    }

    const getDownloadableCols = (columns) => {
        let result = [...columns]
        result.push({
            key: "manager",
            label: "Reporting Manager"
        }, {
            key: "tabp",
            label: "TABP"
        })

        return result
    }

    const getDownloadableRows = (rows) => {
        let result = [...rows]
        return result.map(obj => {
            obj.manager = `${obj.manager_emp_name} - (${obj.manager_emp_code})`
            obj.tabp = `${obj.tabp_emp_name} - (${obj.tabp_emp_code})`

            return obj
        })
    }

    const getDetailedPage = (props) => {
        let { 
            labelName,
            columns, 
            order,
            orderBy,
            handleSortRequest,
            recCount,
            redirectLink
        } = props

        const records = record()
        recCount = records.length

        return (<Fragment>
            {<Grid container>
                <Grid item xs={12} sm={12} md={8}></Grid>
                
                <Grid item xs={12} sm={12} md={3}>
                    <Grid style={{ textAlign: "right", padding: "10px" }}>
                        { recCount ? <CSVLink
                            headers={getDownloadableCols(columns)}
                            data={getDownloadableRows(rows)}
                            filename={`${labelName}_${moment().format("DD_MM_YYYY_HH_mm_ss")}.csv`}
                            target="_blank"
                        >
                        <span title="Download Report">Download Report</span>
                        </CSVLink> : (null)}
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={1}>
                    <div style={{ textAlign: "right", padding: "10px" }}>
                        <Button 
                            onClick={() => goBack()}
                            style={{ backgroundColor: '#4559C9', color: "white", padding: "5px", borderRadius: "5px", textTransform: "none" }}>
                        Back</Button> 
                    </div>
                </Grid>
            </Grid>}
            <TableContainer>
                <Table aria-label="customized table">
                    <PendingTableHeader 
                        columns={columns}
                        order={order}
                        orderBy={orderBy}
                        handleSortRequest={handleSortRequest}
                        index={index}
                        isDetailedPage={isDetailedPage}
                    />
                    <TableBody>
                        { recCount ? records.map((row, idx) => <PendingTableBody
                                        key={idx}
                                        row={row}
                                        columns={columns}
                                        isDetailedPage={isDetailedPage}
                                        index={page * rowsPerPage + idx}
                                        idx={idx}
                                        recCount={recCount}
                                        redirectLink={redirectLink}
                                    />) : (<TableRow>
                            <TableCell colSpan={columns.length + 1} style={{textAlign: "center"}}><span style={{color:"green"}}>No records to display</span></TableCell>
                        </TableRow>)
                    }
                    </TableBody>
                </Table>
            </TableContainer>
            {
                recCount ? <PendingDetailPagination
                    page={page}
                    pages={pages}
                    rowsPerPage={rowsPerPage}
                    rows={rows}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                /> : (null)
            }
            <Grid item xs={12} sm={12} md={9}></Grid>
            <Grid item xs={12} sm={12} md={3}></Grid>
        </Fragment>)
    }
    
    const goBack = () => {
        handleViewMore(false, true)
    }
    
    return (isDetailedPage === labelName ? getDetailedPage({
                labelName,
                columns, 
                order,
                orderBy,
                handleSortRequest,
                recCount,
                redirectLink
            }) : 
            (isDetailedPage === false  ? getSummaryPage({
                labelName, 
                pendingCount, 
                columns, 
                redirectLink,
                order,
                orderBy,
                recCount,
                handleSortRequest,
                isMoreBtn }) : null)
    )
}

export default PendingCard