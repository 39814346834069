import { makeStyles } from '@material-ui/core/styles'
import blue from "@material-ui/core/colors/blue"

export const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
    },
    formControl: {
      margin: theme.spacing(3),
      minWidth: 120,
    },
    formControlDate: {
      margin: theme.spacing(3),
      maxWidth: 180,
    },
    formControlMarks: {
      margin: theme.spacing(3),
      maxWidth: 80,
    },
    formControlAddBtn: {
      margin: theme.spacing(3),
      minWidth: 120,
      textAlign: 'center'
    },
    paperGeneral: {
      paddingRight: '20px',
      paddingTop: '10px',
      paddingBottom: '10px',
      textAlign: 'left',
      fontSize: '12px',
      fontWeight: 'bold',
      maxHeight: '400px',
      overflow: 'auto'
    },
    paperFirst: {
      padding: theme.spacing(1),
      textAlign: 'center',
    },
    input: {
      display: "none"
    },
    buttonFile: {
      color: blue[900],
      marginLeft: '100%'
    },
    formControlLabel: {
      textAlign: 'left',
      margin: theme.spacing(3),
      minWidth: 120,
      marginTop: 30
    }
}));
  