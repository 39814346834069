import { makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  ratingSection: {
    marginTop: '3em'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  marginTop: {
    marginTop: 20
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1'
    }
  },
  btnLoading: {
    marginLeft: 10,
    textTransform: 'none'
  },
  multipleSelect: {
    margin: theme.spacing(1),
    minWidth: 280,
    maxWidth: 280
  },
  rangeSplitter: {
    fontSize: '.9rem',
    fontWeight: '1rem',
    fontFamily: `Roboto, Helvetica, Arial, sans-serif`,
    color: 'red'
  },
  filterTitleMargin: {
    marginLeft: 0
  },
  dispayNone: {
    display: 'none'
  }
}))
