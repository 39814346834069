import { makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center'
    },
    formControl: {
      margin: theme.spacing(3),
      minWidth: 120,
    },
    formControlDate: {
      margin: theme.spacing(3),
      maxWidth: 140,
    },
    formControlMarks: {
      margin: theme.spacing(3),
      maxWidth: 80,
    },
    formControlAddBtn: {
      margin: theme.spacing(3),
      minWidth: 120,
      textAlign: 'center'
    },
    paperGeneral: {
      paddingRight: '20px',
      paddingTop: '10px',
      paddingBottom: '10px',
      textAlign: 'left',
      fontSize: '12px',
      fontWeight: 'bold',
      minHeight: '400px',
      maxHeight: '400px',
      overflow: 'auto'
    },
    paperFirst: {
      padding: theme.spacing(1),
      textAlign: 'center'
    },
}));
  