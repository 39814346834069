import React from 'react'
import Grid from '@material-ui/core/Grid'
import { useStyles } from './style'
import Typography from '@material-ui/core/Typography'

const companyName = 'REA India'
const FooterLayout = () => {
    const classes = useStyles()
    return (
        <Grid container className={classes.root} spacing={2} direction="column">
            <Grid item container sm={12} className={classes.footer}>
                <Grid item container sm={12} alignItems="center" justifyContent="center">
                    <Typography>{companyName} Group. All Rights Reserved.</Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default FooterLayout