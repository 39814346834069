import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import Routes from '../src/routes';
import PingTest from './components/PingTest/PingTest';
import Dashboard from './components/Joiner/Dashboard'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { RenderSurvey } from "./pages/Survey/RenderSurvey"

const App = () => {
  return (
    <div>
      <Router>
        <Route path="/ping" component={PingTest} />
        <Route path="/joiner/dashboard" component={Dashboard} />
        <Route path="/fillSurvey" component={ RenderSurvey } />
        <Routes />
      </Router>
      <ToastContainer autoclose={2000} hideProgressBar />
    </div>
  );
}

export default App;
