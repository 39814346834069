import React, { Fragment, useState, useEffect } from 'react';
import { useStyles } from './style';
import {
  Paper,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Input,
  FormHelperText,
  FormLabel,
  Grid,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment-timezone';

import FileUpload from '../FileUpload';
import FileListTable from '../FileListTable';
import {
  createObjectArrayForSelect,
  formatDate,
} from '../../../utils/CommonFunction';
import { commonConst } from '../../../constants/CommonConstant';
import { joinerConst } from '../../../constants/JoinerConstant';
import {
  onlyAlphabets,
  checkNameRegEx,
  checkBankNameRegEx,
  checkBankAccountNumberRegEx,
  checkBankIfscCodeRegEx,
} from '../../../utils/Validation';

import { postUploadedFiles, getUploadedFiles } from '../fetchData';
import { ControlCameraOutlined } from '@material-ui/icons';

const Personal = ({
  errorObj,
  offerData,
  joinerPersonalData = [],
  maritalStatusList = [],
  bloodGroupList = [],
  handleChildData = () => {},
  handleChildDataValidation = () => {},
  handleNotification = () => {},
  submitCheck = false
}) => {
  const classes = useStyles();
  const {
    offerId,
    first_name,
    middle_name,
    last_name,
    gender_val,
    pan,
    aadhar_number,
    joiner_extra_curricular,
  } = offerData;
  let { date_of_birth } = offerData;
  const {
    minEligibleYearToJoin,
    maxEligibleYearToJoin,
    fileExtenstions,
    maxUploadFileSize,
    oneMbInBytes
  } = joinerConst;

  const [loading, setLoading] = useState(false)
  const minDobDate = moment()
    .subtract(maxEligibleYearToJoin, 'years')
    .format('YYYY-MM-DD');
  const maxDobDate = moment()
    .subtract(minEligibleYearToJoin, 'years')
    .format('YYYY-MM-DD');
  const [
    {
      id = null,
      father_name = '',
      marital_status = '',
      spouse_name = '',
      spouse_gender = '',
      blood_group = '',
      is_bank_account = '',
      bank_name = '',
      bank_account_number = '',
      bank_ifsc_code = '',
      first_name_changed = '',
      middle_name_changed = '',
      last_name_changed = '',
      aadhar_changed = '',
      pan_changed = '',
      dob_changed = '',
    } = {},
  ] = joinerPersonalData;

  let { genderList } = commonConst;
  const genderListObj = createObjectArrayForSelect(genderList);
  const maritalStatusListObj = createObjectArrayForSelect(maritalStatusList);
  const bloodGroupListObj = createObjectArrayForSelect(bloodGroupList);
  date_of_birth = dob_changed
    ? formatDate(dob_changed)
    : formatDate(date_of_birth);
  const initObj = {
    offerId,
    id: id,
    firstName: first_name_changed ? first_name_changed : first_name,
    middleName: middle_name_changed ? middle_name_changed : middle_name,
    lastName: last_name_changed ? last_name_changed : last_name,
    gender: gender_val,
    fatherName: father_name === '' ? '' : father_name,
    bloodGroup: blood_group === '' ? '' : blood_group,
    dateOfBirth: date_of_birth,
    panNumber: pan_changed ? pan_changed : pan,
    aadharNumber: aadhar_changed ? aadhar_changed : aadhar_number,
    maritalStatus: marital_status === '' ? '' : marital_status,
    spouseName: spouse_name === '' ? '' : spouse_name,
    spouseGender: spouse_gender === '' ? '' : spouse_gender,
    isBankAccount: is_bank_account === '' ? '' : is_bank_account,
    bankName: bank_name === '' ? '' : bank_name,
    bankAccountNumber: bank_account_number === '' ? '' : bank_account_number,
    bankIfscCode: bank_ifsc_code === '' ? '' : bank_ifsc_code,
    extraCurricular: joiner_extra_curricular ? joiner_extra_curricular : ''
  };

  let initErrObj = {};
  for (let k in initObj) {
    initErrObj[k] = { status: false, text: '' };
  }

  const documentTypeList = {
  1: {
    efiling_document_id: 1,
    document_type: 'PAN Card',
    document_alias: 'pan_card'
  },
  2: {
    efiling_document_id: 2,
    document_type: 'Aadhaar Card',
    document_alias: 'aadhaar_card'
  },
  3: {
    efiling_document_id: 3,
    document_type: 'Cancelled Cheque',
    document_alias: 'bank_account_details'
  },
  4: {
    efiling_document_id: 4,
    document_type: 'Bank Passbook',
    document_alias: 'bank_account_details'
  }}
  
  const [personalObj, setPersonalObj] = useState(initObj);
  const [newErrorObj, setNewErrorObj] = useState(initErrObj);
  const [documentTypeId, setDocumentTypeId] = useState('')
  const [initUploadCount, setInitUploadCount] = useState(0)

  useEffect(() => {
    for (let k in personalObj) {
      checkChange(k, personalObj[k]);
      handleChildData(k, personalObj[k]);
      let errObj = checkPersonalDataError(k, personalObj[k]);
      handleChildDataValidation(k, errObj);
    }
   
    if(submitCheck){
      setNewErrorObj({...newErrorObj,...errorObj})
    }
  }, [personalObj,submitCheck]);

  useEffect(() => {
    setFileUploadMandatory(isBankAccount==='Existing')
    getUploadedDocuments(offerId, true)
  }, [])

  const {
    aadharNumber,
    bankAccountNumber,
    bankIfscCode,
    bankName,
    bloodGroup,
    dateOfBirth,
    fatherName,
    firstName,
    gender,
    isBankAccount,
    lastName,
    maritalStatus,
    middleName,
    panNumber,
    spouseGender,
    spouseName,
    extraCurricular
  } = personalObj;

  const getUploadedDocuments = async (offerId, isInit = false) => {
    const query = {
      objectId: offerId,
      objectType: 'PersonalDataDocument',
      domainId: 2,
    };
    let data = [];
    try {
      data = await getUploadedFiles(query);
      data = data.data;
      console.log(data)
    } catch (err) {
      console.log(err);
    }
    setFileList(data);
    if (isInit) {
      const fileListCount = Array.isArray(data) ? data.length : 0
      setInitUploadCount(fileListCount)
    }
  };

  const checkChange = (key, value) => {
    let flag = false;
    switch (key) {
      case 'firstName':
        if (first_name !== value) {
          flag = true;
        }
        break;
      case 'middleName':
        if (middle_name !== value) {
          flag = true;
        }
        break;
      case 'lastName':
        if (last_name !== value) {
          flag = true;
        }
        break;
      case 'dateOfBirth':
        if (
          moment(date_of_birth).format('YYYYMMDD') !==
          moment(value).format('YYYYMMDD')
        ) {
          flag = true;
        }
        break;
      case 'panNumber':
        if (pan !== value) {
          flag = true;
        }
        break;
      case 'aadharNumber':
        if (aadhar_number !== value) {
          flag = true;
        }
        break;
      case 'bankAccountNumber':
        if (isBankAccount === 'Existing') {
          flag = true;
        }
        break;
      case 'bankName':
        if (isBankAccount === 'Existing') {
          flag = true;
        }
        break;
      case 'bankIfscCode':
        if (isBankAccount === 'Existing') {
          flag = true;
        }
        break;
      case 'isBankAccount':
        if (value === 'Existing') {
          flag = true;
        }
        break;
      default:
        break;
    }
    flag = checkChangeForFileUpload()
    let text = '';
    if (flag) {
      text =
        'Since you have added/changed your bank account details, kindly upload the supporting documents for the same.';
    }
    // setFileUploadMandatory(flag);
    
    handleChildDataValidation('personalFileUpload', { status: flag, text });
    // setNewErrorObj({...newErrorObj,...errorObj})
  };

  const checkChangeForFileUpload = () => {
    let flag = false
    if (isBankAccount === 'Existing' && 
    (bankName === '' || bank_name !== bankName || bankAccountNumber === '' || bank_account_number !== bankAccountNumber || bankIfscCode === '' || bank_ifsc_code !== bankIfscCode) &&
    (initUploadCount === 0 || initUploadCount <= fileList.length)
    ) {
      flag = true
    }

    return flag
  }

  const handleDocumentTypeChange = async (e) => {
    let key = e.target.name;
    let val = e.target.value;
    setDocumentTypeId(val)
  }
  const handleChange = async (e) => {
    let key = e.target.name;
    let val = e.target.value;
    
    switch (key) {
      case 'panNumber':
      case 'bankIfscCode':
        val = val.toUpperCase();
        break;
      default:
        break;
    }
    let newObject = { ...personalObj, [key]: val };
    if(key === 'isBankAccount' && val === 'New'){
      setFileUploadMandatory(false)
        newObject = { 
          ...newObject,
          bankName: '',
          bankAccountNumber: '',
          bankIfscCode: ''
        }
        setNewErrorObj({
          ...newErrorObj,
          bankName: {status:false,text:''},
          bankAccountNumber: {status: false, text: ''},
          bankIfscCode: {status: false, text: ''}
        })
    } else if (key === 'isBankAccount' && val === 'Existing') {
      setFileUploadMandatory(true)
      newObject = { 
        ...newObject,
        bankName: initObj.bankName,
        bankAccountNumber: initObj.bankAccountNumber,
        bankIfscCode: initObj.bankIfscCode
      }
    }
    
    setPersonalObj(newObject);
    checkChange(key, val);
    handleChildData(key, val);
    let err = checkPersonalDataError(key, val);
    handleChildDataValidation(key, err);
    setNewErrorObj({...newErrorObj,[key]:{status: err.status,text:err.text}})
  };

  const handleDateChange = (val) => {
    checkChange('dateOfBirth', val);
    handleChildData('dateOfBirth', val);
    setPersonalObj({ ...personalObj, dateOfBirth: val });
    let err = checkPersonalDataError('dateOfBirth', val);
    handleChildDataValidation('dateOfBirth', err);
    setNewErrorObj({...newErrorObj,dateOfBirth:{status:err.status,text:err.text}})
  };

  const handleInput = (e, type) => {
    switch (type) {
      case 'firstName':
      case 'middleName':
      case 'lastName':
      case 'fatherName':
      case 'spouseName':
        if (!onlyAlphabets(e.which)) {
          e.preventDefault();
        }
        break;
      default:
        break;
    }
  };

  const checkPersonalDataError = (key, value) => {
    let text = '';
    let status = false;
    value = typeof value === 'string' ? value.trim() : value;
    switch (key) {
      // case 'firstName':
      //   if (!checkNameRegEx(value)) {
      //     status = true;
      //     text = `Invalid first name`;
      //   }
      //   break;
      // case 'middleName':
      //   if (value !== '' && !checkNameRegEx(value)) {
      //     status = true;
      //     text = `Invalid middle name`;
      //   }
      //   break;
      // case 'lastName':
      //   if (value !== '' && !checkNameRegEx(value)) {
      //     status = true;
      //     text = `Invalid last name`;
      //   }
      //   break;
      case 'fatherName':
        if (!checkNameRegEx(value)) {
          status = true;
          text = `Invalid father's name`;
        }
        break;
      case 'bloodGroup':
        if (!bloodGroupList.includes(value)) {
          status = true;
          text = `Invalid blood group`;
        }
        break;
      case 'dateOfBirth':
        if (
          !moment(value).isValid() ||
          moment().diff(moment(value), 'year') < 18
        ) {
          status = true;
          text = `Invalid date of birth`;
        }
        break;
      // case 'panNumber':
      //   if (!checkPanRegEx(value)) {
      //     status = true;
      //     text = `Invalid PAN`;
      //   }
      //   break;
      // case 'aadharNumber':
      //   if (!checkAdharRegEx(value)) {
      //     status = true;
      //     text = `Invalid Aadhaar number`;
      //   }
      //   break;
      case 'maritalStatus':
        if (!maritalStatusList.includes(value)) {
          status = true;
          text = `Invalid marital status`;
        } else {
          if (maritalStatus !== 'Married') {
            if (!checkNameRegEx(spouseName)) {
              handleChildDataValidation('spouseName', {
                status: true,
                text: `Invalid spouse name`,
              });
            }
            if (!genderList.includes(spouseGender)) {
              handleChildDataValidation('spouseGender', {
                status: true,
                text: `Invalid spouse gender`,
              });
            }
          } else {
            handleChildDataValidation('spouseGender', {
              status: false,
              text: ``,
            });
            handleChildDataValidation('spouseName', {
              status: false,
              text: ``,
            });
          }
        }
        break;
      case 'spouseName':
        if (maritalStatus === 'Married' && !checkNameRegEx(value)) {
          status = true;
          text = `Invalid spouse name`;
        }
        break;
      case 'spouseGender':
        if (maritalStatus === 'Married' && !genderList.includes(value)) {
          status = true;
          text = `Invalid spouse gender`;
        }
        break;
      case 'isBankAccount':
        if (!['Existing', 'New'].includes(value)) {
          status = true;
          text = `Select this`;
        } else {
          if (value === 'New') {
            handleChildDataValidation('bankName', { status: false, text: `` });
            handleChildDataValidation('bankAccountNumber', {
              status: false,
              text: ``,
            });
            handleChildDataValidation('bankIfscCode', {
              status: false,
              text: ``,
            });
          } else {
            if (!checkBankNameRegEx(bankName)) {
              handleChildDataValidation('bankName', {
                status: true,
                text: `Invalid bank name`,
              });
            } else if(bankName.match("  ")) {
              handleChildDataValidation('bankName', {
                status: true,
                text: `Bank name should not contain double space`,
              });
            }
            if (!checkBankAccountNumberRegEx(bankAccountNumber)) {
              handleChildDataValidation('bankAccountNumber', {
                status: true,
                text: `Invalid bank account number`,
              });
            }
            if (!checkBankIfscCodeRegEx(bankIfscCode)) {
              handleChildDataValidation('bankIfscCode', {
                status: true,
                text: `Invalid bank IFSC code`,
              });
            }
          }
        }
        break;
      case 'bankName':
        if (isBankAccount === 'Existing') {
          if(!checkBankNameRegEx(value)) {
            status = true;
            text = `Invalid bank name`;
          } else if(bankName.match("  ")) {
            status = true;
            text =`Bank name should not contain double space`;
          }
        } 
        break;
      case 'bankAccountNumber':
        if (
          isBankAccount === 'Existing' &&
          !checkBankAccountNumberRegEx(value)
        ) {
          status = true;
          text = `Invalid bank account number`;
        }
        break;
      case 'bankIfscCode':
        if (isBankAccount === 'Existing' && !checkBankIfscCodeRegEx(value)) {
          status = true;
          text = `Invalid bank IFSC code`;
        }
        break;
      case 'extraCurricular':
        if (value === '') {
          status = true;
          text = `Enter extra-curricular activities`;
        }
        break;
      default:
        break;
    }

    return { text, status };
  };

  const [fileList, setFileList] = useState([]);
  let isFileAvl = fileList && fileList.length ? true : false;
  // if (fileList.length) {
  //   handleChildDataValidation('personalFileUpload', {
  //     status: !isFileAvl,
  //     text: '',
  //   });
  // }
  const [isFileUploadMandatory, setFileUploadMandatory] = useState(isFileAvl);
  const [fileData, setFileData] = useState([]);
  const [fileNotification, setFileNotification] = useState({
    status: false,
    text: '',
    color: '',
  });
  const onFileChange = async (e) => {
    if (fileData.length >= 3) {
      alert('You can select maximum 3 files to upload!');
      return false;
    }

    if (e.target.files.length) {
      let files = e.target.files[0];
      if (!fileExtenstions.includes(files.name.split('.').pop())) {
        alert(
          `Selected file type is invalid! Allowed file types are ${fileExtenstions.join(
            ','
          )}`
        );
        return false;
      }
      if (files.size > maxUploadFileSize) {
        alert(
          `Exceeding file size limit! Upload file size upto ${maxUploadFileSize/oneMbInBytes} kb!`
        );
        return false;
      }
      setFileData([...fileData, { inputFile: files }]);
    }
  };

  const handleFileUpload = async () => {
    let noitfyStatus = false;
    let text = '';
    let color = '';
    
    if (!fileData.length) {
      noitfyStatus = true;
      text = 'Choose atleast 1 file!';
      color = 'red';
    }

    setLoading(true)
      const [{ status } = {}] = await Promise.all(
        fileData
          .filter(
            ({ inputFile }) => inputFile !== null && inputFile !== undefined
          )
          .map(({ inputFile }) => {
            const res = imageUpload({
              inputFile,
              objectId: offerId,
              objectType: 'PersonalDataDocument',
              documentType: 'PersonalDataDocument',
              efilingDocumentType : documentTypeList[documentTypeId] ? documentTypeList[documentTypeId].document_alias : ''
            });
            return postUploadedFiles(res);
          })
      ).catch((err) => console.log(err));
  
      if (status === 200) {
        handleChildDataValidation('personalFileUpload', {
          status: false,
          text: '',
        });
        getUploadedDocuments(offerId);
        noitfyStatus = true;
        text = 'File uploaded successfully!';
        color = 'green';
        handleNotification({ type: '', message: '' });
      } else {
        noitfyStatus = true;
        text = 'Something went wrong!';
        color = 'red';
      }

    setLoading(false)
    setFileData([]);
    setFileNotification({ status: noitfyStatus, text, color });
  };

  const imageUpload = ({ inputFile, objectId, objectType, documentType, efilingDocumentType }) => {
    const formData = new FormData();
    formData.append('file', inputFile);
    formData.append('objectId', objectId);
    formData.append('objectType', objectType);
    formData.append('documentType', documentType);
    formData.append('domainId', 2);
    formData.append('description', efilingDocumentType);
    return formData;
  };

  const handleSelectedFile = (e, index) => {
    const newFileData = fileData.filter((obj, i) => {
      return i !== index;
    });
    setFileData(newFileData);
  };
  
  return (
    <Fragment>
      <Grid container spacing={2} className={classes.root}>
        <Grid item sm={10}>
          <Paper className={classes.paperFirst}>
          <div style={{color: "red", float: "left", fontSize: "11px", paddingTop: "10px"}}>
              <sup>*</sup> Kindly read general instructions before filling in the information on this page
            </div>
            <Grid container spacing={3}>
              <FormControl className={classes.formControlLable}>
                <FormLabel>
                  Name<br></br>(As on Aadhar Card):
                </FormLabel>
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  autoComplete='off'
                  label='First Name'
                  value={firstName}
                  name='firstName'
                  required={false}
                  onChange={(e) => handleChange(e)}
                  onKeyPress={(e) => handleInput(e, 'firstName')}
                  inputProps={{ minLength: 2, maxLength: 100, readOnly: true }}
                  error={newErrorObj.firstName.status}
                  helperText={newErrorObj.firstName.text}
                  disabled={true}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  autoComplete='off'
                  label='Middle Name'
                  value={middleName}
                  name='middleName'
                  onChange={(e) => handleChange(e)}
                  onKeyPress={(e) => handleInput(e, 'middleName')}
                  inputProps={{ minLength: 1, maxLength: 100, readOnly: true  }}
                  error={newErrorObj.middleName.status}
                  helperText={newErrorObj.middleName.text}
                  disabled={true}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  autoComplete='off'
                  label='Last Name'
                  value={lastName}
                  name='lastName'
                  onChange={(e) => handleChange(e)}
                  onKeyPress={(e) => handleInput(e, 'lastName')}
                  inputProps={{ minLength: 1, maxLength: 100, readOnly: true  }}
                  error={newErrorObj.lastName.status}
                  helperText={newErrorObj.lastName.text}
                  disabled={true}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  autoComplete='off'
                  required={true}
                  label="Father's Name"
                  value={fatherName}
                  name='fatherName'
                  onChange={(e) => handleChange(e)}
                  onKeyPress={(e) => handleInput(e, 'fatherName')}
                  inputProps={{ minLength: 2, maxLength: 100 }}
                  error={newErrorObj.fatherName.status}
                  helperText={newErrorObj.fatherName.text}
                />
              </FormControl>
            </Grid>
            <Grid container spacing={3}>
              <FormControl className={classes.formControl}>
                <InputLabel
                  htmlFor='bloodGroup'
                  required={true}
                  error={newErrorObj.bloodGroup.status}
                >
                  Blood Group
                </InputLabel>
                <Select
                  value={bloodGroup}
                  className={classes.selectEmpty}
                  name='bloodGroup'
                  input={<Input id='bloodGroup' />}
                  onChange={(e) => handleChange(e)}
                  error={newErrorObj.bloodGroup.status}
                >
                  <MenuItem value='' disabled>
                    <em>Select the value</em>
                  </MenuItem>
                  {bloodGroupListObj.map(({ value }, index) => {
                    return (
                      <MenuItem key={index} value={value}>
                        {value}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText style={{ color: 'red' }}>
                  {newErrorObj.bloodGroup.text}
                </FormHelperText>
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  label='Gender'
                  value={gender}
                  name='gender'
                  disabled={true}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    label='Date of Birth'
                    format='dd-MM-yyyy'
                    clearable={false}
                    name='dateOfBirth'
                    value={dateOfBirth}
                    onChange={handleDateChange}
                    disableFuture={true}
                    minDate={minDobDate}
                    maxDate={maxDobDate}
                    inputProps={{ readOnly: true }}
                    required={false}
                    error={newErrorObj.dateOfBirth.status}
                    helperText={newErrorObj.dateOfBirth.text}
                    disabled={true}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  autoComplete='off'
                  label='PAN'
                  value={panNumber}
                  name='panNumber'
                  required={false}
                  onChange={(e) => handleChange(e)}
                  inputProps={{ minLength: 10, maxLength: 10, readOnly: true  }}
                  error={newErrorObj.panNumber.status}
                  helperText={newErrorObj.panNumber.text}
                  disabled={true}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  autoComplete='off'
                  label='Aadhaar Number'
                  value={aadharNumber}
                  name='aadharNumber'
                  required={false}
                  onChange={(e) => handleChange(e)}
                  inputProps={{ minLength: 12, maxLength: 12, readOnly: true  }}
                  error={newErrorObj.aadharNumber.status}
                  helperText={newErrorObj.aadharNumber.text}
                  disabled={true}
                />
              </FormControl>
            </Grid>
          </Paper>
          <Paper className={classes.paper}>
            <Grid container spacing={3}>
                <FormControl className={classes.formControlExtra}>
                  <TextField
                      autoComplete="off"
                      label="Extra-Curricular Activities"
                      placeholder="Extra-Curricular Activities"
                      value={extraCurricular || ''}
                      name="extraCurricular"
                      onChange={(e) => handleChange(e)}
                      error={newErrorObj.extraCurricular.status}
                      helperText={newErrorObj.extraCurricular.text}
                  />
                </FormControl>
              </Grid>
          </Paper>
          <Paper className={classes.paper}>
            <Grid container spacing={3}>
              <FormControl className={classes.formControlMaritalStatus}>
                <InputLabel
                  required={true}
                  error={newErrorObj.maritalStatus.status}
                >
                  Marital Status
                </InputLabel>
                <Select
                  value={maritalStatus}
                  label='maritalStatus'
                  name='maritalStatus'
                  onChange={(e) => handleChange(e)}
                  error={newErrorObj.maritalStatus.status}
                >
                  <MenuItem value='' disabled>
                    <em>Select the value</em>
                  </MenuItem>
                  {maritalStatusListObj.map(({ value }, index) => {
                    return (
                      <MenuItem key={index} value={value}>
                        {value}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText style={{ color: 'red' }}>
                  {newErrorObj.maritalStatus.text}
                </FormHelperText>
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  autoComplete='off'
                  label='Spouse Name'
                  value={spouseName}
                  name='spouseName'
                  className={
                    maritalStatus === 'Married'
                      ? classes.formControlSpouseActive
                      : classes.formControlSpouseInactive
                  }
                  onChange={(e) => handleChange(e)}
                  onKeyPress={(e) => handleInput(e, 'spouseName')}
                  error={
                    maritalStatus === 'Married' && newErrorObj.spouseName.status
                  }
                  helperText={
                    maritalStatus === 'Married' && newErrorObj.spouseName.text
                  }
                  inputProps={{
                    maxLength: 100,
                  }}
                />
              </FormControl>
              <FormControl className={classes.formControlGender}>
                <InputLabel
                  className={
                    maritalStatus === 'Married'
                      ? classes.formControlSpouseActive
                      : classes.formControlSpouseInactive
                  }
                  error={newErrorObj.spouseGender.status}
                >
                  Spouse Gender
                </InputLabel>
                <Select
                  className={
                    maritalStatus === 'Married'
                      ? classes.formControlSpouseActive
                      : classes.formControlSpouseInactive
                  }
                  value={spouseGender}
                  name='spouseGender'
                  input={<Input id='spouseGender' />}
                  onChange={(e) => handleChange(e)}
                  error={
                    maritalStatus === 'Married' && newErrorObj.spouseGender.status
                  }
                >
                  <MenuItem value='' disabled>
                    <em>Select the value</em>
                  </MenuItem>
                  {genderListObj.map(({ id, value }, index) => {
                    return (
                      <MenuItem key={index} value={value}>
                        {value}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText style={{ color: 'red' }}>
                  {maritalStatus === 'Married' && newErrorObj.spouseGender.text}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Paper>
          <Paper className={classes.paper}>
            <Grid container spacing={3}>
              <FormControl className={classes.formControlBankStatus}>
                <InputLabel
                  required={true}
                  error={newErrorObj.isBankAccount.status}
                >
                  Bank Account
                </InputLabel>
                <Select
                  value={isBankAccount}
                  label='isBankAccount'
                  name='isBankAccount'
                  input={<Input id='isBankAccount' />}
                  onChange={(e) => handleChange(e)}
                  error={newErrorObj.isBankAccount.status}
                >
                  <MenuItem value='New'>I want to open a new bank</MenuItem>
                  <MenuItem value='Existing'>
                    I want to use an existing bank account
                  </MenuItem>
                </Select>
                <FormHelperText style={{ color: 'red' }}>
                  {newErrorObj.isBankAccount.text}
                </FormHelperText>
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  autoComplete='off'
                  label='Bank Name'
                  value={bankName}
                  name='bankName'
                  className={
                    isBankAccount === 'Existing'
                      ? classes.formControlBankActive
                      : classes.formControlBankInactive
                  }
                  onChange={(e) => handleChange(e)}
                  error={newErrorObj.bankName.status}
                  helperText={newErrorObj.bankName.text}
                  inputProps={{
                    maxLength: 100,
                  }}
                />
              </FormControl>
              <FormControl className={classes.formControlAccNo}>
                <TextField
                  autoComplete='off'
                  label='Bank Account Number'
                  value={bankAccountNumber}
                  name='bankAccountNumber'
                  className={
                    isBankAccount === 'Existing'
                      ? classes.formControlBankActive
                      : classes.formControlBankInactive
                  }
                  onChange={(e) => handleChange(e, 'bankAccountNumber')}
                  error={newErrorObj.bankAccountNumber.status}
                  helperText={newErrorObj.bankAccountNumber.text}
                  inputProps={{
                    maxLength: 18,
                  }}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  autoComplete='off'
                  label='IFSC Code'
                  value={bankIfscCode}
                  name='bankIfscCode'
                  className={
                    isBankAccount === 'Existing'
                      ? classes.formControlBankActive
                      : classes.formControlBankInactive
                  }
                  onChange={(e) => handleChange(e)}
                  error={newErrorObj.bankIfscCode.status}
                  helperText={newErrorObj.bankIfscCode.text}
                  inputProps={{
                    maxLength: 11,
                  }}
                />
              </FormControl>
            </Grid>
          </Paper>
          { 
          isFileUploadMandatory ? (
            <div>
              <FileUpload
                loading={loading}
                fileData={fileData}
                fileNotification={fileNotification}
                onFileChange={onFileChange}
                handleFileUpload={handleFileUpload}
                handleSelectedFile={handleSelectedFile}
                customLable = 'Kindly upload the supporting documents for bank details by clicking this button'
                supportingDocList="Supporting Docs: {Existing Bank A/c - Cancelled Cheque/Bank Passbook}"
              />
            </div>) : (null)
          }
          {isFileUploadMandatory && fileList && fileList.length ? (
            <FileListTable fileList={fileList} limit={2} />
          ) : null}
        </Grid>
        <Grid item sm={2}>
          <Paper className={classes.paperGeneral}>
            <div>
              <ul>
                <li className='unorder-list'>&nbsp;</li>
                <li className='unorder-list'>General Instructions:</li>
                <li className='unorder-list'>&nbsp;</li>
                {/* <li>Kindly fill your name (as per Aadhaar Card / Pan Card). Any change in name would call for uploading an updated Aadhaar Card / Pan Card copy.</li> */}
                <li>For your Bank details, you can either choose to provide details of your existing bank account and upload a copy of a canceled cheque / bank passbook / account statement (as a supporting document) or select an option to request for opening a new bank account post your joining.</li>
                <li>Allowed file types are pdf, doc, jpg, jpeg, png.</li>
                <li>To move ahead, click SAVE & NEXT</li>
              </ul>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default Personal;
