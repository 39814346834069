export const joinerConst =  {
    minEligibleYearToJoin: 18,
    maxEligibleYearToJoin: 60,
    minorNomineeAgeLimit: 18,
    maxUsedSharePercent: 100,
    maxUploadFileSize: 5242880,
    oneMbInBytes: 1048576,
    maxNomineeRecord: 4,
    maxWorkHistoryRecord: 6,
    minRefRecord: 2,
    maxRefRecord: 3,
    fileExtenstions: ['doc', 'docx', 'pdf', 'jpg', 'jpeg', 'png'],
    formFieldNames: {
        "Personal Details": [
            'firstName',
            'middleName',
            'lastName',
            'fatherName',
            'bloodGroup',
            'dateOfBirth',
            'panNumber',
            'aadharNumber',
            'maritalStatus',
            'spouseName',
            'spouseGender',
            'isBankAccount',
            'bankName',
            'bankAccountNumber',
            'bankIfscCode',
            'personalFileUpload',
            'extraCurricular',
            'personalFileUpload'
        ],
        "Contact Details": [
            'addressLine1',
            'addressLine2',
            'addressLine3',
            'presentAddressLine1',
            'presentAddressLine2',
            'presentAddressLine3',
            'emergencyContactName',
            'emergencyContactNumber',
            'emergencyContactRelation',
            'landlineNumber1',
            'mobileNumber2',
            'pinCode',
            'presentPinCode',
            'contactFileUpload'  
        ],
        "Previous Employment Details": [
            "isFresher",
            "previousPfNo",
            "previousUan",
            "dateOfExit",
            "isInternationalWorker",
            "countryName",
            "passportNumber",
            "passportValidityFrom",
            "passportValidityTo",
            'isPfEmp'
        ],
        "Nominee Details": [
            'joinerNomineeData'
        ],
        "Education Details": [
            'joinerEducationData'
        ],
        "Work Experience":[
            'joinerWorkHistoryData'
        ],
        "Reference Details":[
            'joinerReferenceData'
        ],
        "Relative Details":[
            'joinerRelativesData'
        ],
        "Child Care Details":[
            'joinerChildCareData'
        ],
        "Final Submission": [
            'joinerFinalData',
            'joinerFinalAccept'
        ]
    },
    formErrorMessage: {
        "Personal Details": 'There is some error in the form!',
        "Contact Details": 'There is some error in the form!',
        "Previous Employment Details": 'There is some error in the form!',
        "Nominee Details": 'Add at least 1 nominee record!',
        "Education Details": 'Add at least 1 education record!',
        "Work Experience": 'Add at least 1 work history record!',
        "Reference Details": 'Add at least 2 reference record!',
        "Relative Details": 'Add at least 1 relative record!',
        "Child Care Details": 'Add at least 1 child care record!',
    },
    joinerDetailStatus: {
        OJ_LINK_SENT: '1',
        OJ_FORM_DRAFT: '2',
        OJ_FORM_SUBMITTED: '3',
        OJ_FORM_APPROVED_HRBP: '4',
        OJ_FORM_REJECTED_HRBP: '5',
        OJ_FORM_APPROVED_HROPS: '6',
        OJ_FORM_REJECTED_HROPS: '7'
      }
}