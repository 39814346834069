import React, { useState, Fragment } from 'react'
import {
  Step, 
  Stepper,
  StepLabel,
  Button,
  Typography,
  Divider,
  Grid
} from '@material-ui/core'
import Welcome from '../Welcome'
import Personal from '../Personal'
import Contact from '../Contact'
import Employeement from '../Employment'
import Nominee from '../Nominee'
import Education from '../Education'
import WorkHistory from '../WorkHistory'
import Reference from '../Reference'
import Relative from '../Relative'
import ChildCare from '../ChildCare'
import FinalSubmit from '../FinalSubmit'
import SimpleAlerts from '../Alert'
import { useStyles } from './style'
import { joinerConst } from '../../../constants/JoinerConstant'
import { LastPage } from '../LastPage'
import DialogComponent from '../../../components/Shared/Elements/DialogComponent'
import ToastElement from '../../../components/Shared/Elements/ToastElement'

import { 
  postPersonalData,
  postContactData,
  postEmploymentData,
  updateStep,
  getDashboardData
} from '../fetchData'
import { scrollToViewHandler } from '../../../constants/CommonConstant'

import nomineeConstant from '../../../constants/NomineeConstant'

const initErrorObj = () => {
  let err = {}
  const { formFieldNames } = joinerConst
  for (let i in formFieldNames) {
    formFieldNames[i].map(element =>  err[element] = {status: false, text: ''})
  }
  return err
}

let initObj = {}
let errorObj = initErrorObj()
const handleChildData = (name, value) => {
  initObj[name] = value
}
const handleChildDataValidation = (name, value) => {
  errorObj[name] = value
}

const getSteps = ({ gender_val, is_fresher }) => {
  let stepList = [
                    'Welcome',
                    'Personal Details', 
                    'Contact Details', 
                    'Previous Employment Details',
                    'Nominee Details',
                    'Education Details',
                ]
    if (is_fresher!==1) {
      stepList.push('Work Experience')
    }
    stepList.push('Reference Details')
    stepList.push('Relative Details')
    if (gender_val==='Female') {
      stepList.push('Child Care Details')
    }
    stepList.push('Final Submission')
    return stepList
}

const getStepNumber = (steps, stepName) => {
  return steps.indexOf(stepName)
}

const getStepName = (steps, stepNumber) => {
  return steps[stepNumber]
}

const getStepContent = (step, steps,
  submitCheck = false,
  setSubmitCheck,
   { 
    offerDataDetail,
    maritalStatusList,
    bloodGroupList,
    relationList,
    joinerPersonalDataDetail,
    joinerContactDataDetail,
    joinerEmploymentDataDetail,
    joinerNomineeDataDetail,
    joinerEducationDataDetail,
    joinerWorkHistoryDataDetail,
    joinerReferenceDataDetail,
    joinerRelativesDataDetail,
    joinerChildCareDataDetail,
    handleNotification=()=>{},
    handleSkippableComponent=()=>{},
    highestQualificationDetail
  }) => {
  switch (steps[step]) {
    case "Welcome": 
        return <Welcome 
                offerData={offerDataDetail}
              />
    case "Personal Details":
        return <Personal 
                  errorObj={errorObj}
                  offerData={offerDataDetail} 
                  maritalStatusList={maritalStatusList}
                  bloodGroupList={bloodGroupList}
                  joinerPersonalData={joinerPersonalDataDetail}
                  handleChildData={handleChildData}
                  handleChildDataValidation={handleChildDataValidation}
                  handleNotification={handleNotification}
                  submitCheck={submitCheck}
              />
    case "Contact Details":
        return <Contact 
                  errorObj={errorObj}
                  offerData={offerDataDetail}
                  joinerContactData={joinerContactDataDetail}
                  handleChildData={handleChildData}
                  handleChildDataValidation={handleChildDataValidation}
                  handleNotification={handleNotification}
                  submitCheck={submitCheck}
              />
    case "Previous Employment Details":
        return <Employeement 
                  errorObj={errorObj}
                  offerData={offerDataDetail}
                  joinerEmploymentData={joinerEmploymentDataDetail}
                  handleChildData={handleChildData}
                  handleChildDataValidation={handleChildDataValidation}
                  submitCheck={submitCheck}
                  setSubmitCheck={setSubmitCheck}
              />
    case "Nominee Details":
        return <Nominee 
                  errorObj={errorObj}
                  offerData={offerDataDetail}
                  joinerNomineeData={joinerNomineeDataDetail}
                  joinerPersonalData={joinerPersonalDataDetail}
                  relationList={relationList}
                  handleChildData={handleChildData}
                  handleChildDataValidation={handleChildDataValidation}
                  handleNotification={handleNotification}
              />
    case "Education Details":
        return <Education 
                  errorObj={errorObj}
                  offerData={offerDataDetail}
                  joinerEducationData={joinerEducationDataDetail}
                  handleChildData={handleChildData}
                  handleChildDataValidation={handleChildDataValidation}
                  handleNotification={handleNotification}
                  highestQualificationData={highestQualificationDetail}
              />
    case "Work Experience":
        return <WorkHistory 
                  errorObj={errorObj}
                  offerData={offerDataDetail}
                  joinerWorkHistoryData={joinerWorkHistoryDataDetail}
                  handleChildData={handleChildData}
                  handleChildDataValidation={handleChildDataValidation}
                  handleNotification={handleNotification}
              />
    case "Reference Details":
        return <Reference 
                  errorObj={errorObj}
                  offerData={offerDataDetail}
                  joinerReferenceData={joinerReferenceDataDetail}
                  handleChildData={handleChildData}
                  handleChildDataValidation={handleChildDataValidation}
                  handleNotification={handleNotification}
                  handleSkippableComponent={handleSkippableComponent}
              />
    case "Relative Details":
        return <Relative 
                errorObj={errorObj}
                offerData={offerDataDetail}
                joinerRelativesData={joinerRelativesDataDetail}
                handleChildData={handleChildData}
                handleChildDataValidation={handleChildDataValidation}
                handleNotification={handleNotification}
                handleSkippableComponent={handleSkippableComponent}
              />
    case "Child Care Details":
        return <ChildCare 
                errorObj={errorObj}
                offerData={offerDataDetail}
                joinerChildCareData={joinerChildCareDataDetail}
                handleChildData={handleChildData}
                handleChildDataValidation={handleChildDataValidation}
                handleNotification={handleNotification}
                handleSkippableComponent={handleSkippableComponent}
              />
    case "Final Submission":
        return <FinalSubmit 
                  errorObj={errorObj}
                  offerData={offerDataDetail}
                  joinerEmploymentData={joinerEmploymentDataDetail}
                  handleChildData={handleChildData}
                  handleChildDataValidation={handleChildDataValidation}
                  handleNotification={handleNotification}
              />
    default:
      return 'Unknown step'
  }
}

const checkIfErroExists = (errObject, activeStep) => {
  const { formFieldNames } = joinerConst  
  let finalFlag
  formFieldNames[activeStep].forEach(element => {
    if (errObject[element]['status']) {
      finalFlag = errObject[element]['status']
    }
  })

  return finalFlag
}

const checkNomineeSharePercent = (joinerNomineeDataDetail) => {
  if (joinerNomineeDataDetail.length) {
    return joinerNomineeDataDetail.reduce((acc, {share_gratuity, share_pf}) => {
        return { 
                share_gratuity: acc.share_gratuity + share_gratuity,
                share_pf: acc.share_pf + share_pf
            }
    })
}

return {share_gratuity: 0, share_pf: 0}
}

export const StepLayout = (dataList) => {
  let {
    data: {
      offerData,
      joinerPersonalData,
      joinerContactData,
      joinerEmploymentData,
      joinerNomineeData,
      joinerEducationData,
      joinerWorkHistoryData,
      joinerReferenceData,
      joinerRelativesData,
      joinerChildCareData,
      maritalStatusList,
      bloodGroupList,
      relationList,
      highestQualificationData
    }
  } = dataList
  
  const [offerDataDetail, setOfferDataDetail] = useState(offerData)
  const [joinerPersonalDataDetail, setPersonalDataDetail] = useState(joinerPersonalData)
  const [joinerContactDataDetail, setContactDataDetail] = useState(joinerContactData)
  const [joinerEmploymentDataDetail, setEmploymentDataDetail] = useState(joinerEmploymentData)
  const [joinerNomineeDataDetail, setNomineeDataDetail] = useState(joinerNomineeData)
  const [joinerEducationDataDetail, setEducationDataDetail] = useState(joinerEducationData)
  const [joinerWorkHistoryDataDetail, setWorkHistoryDataDetail] = useState(joinerWorkHistoryData)
  const [joinerReferenceDataDetail, setReferenceDataDetail] = useState(joinerReferenceData)
  const [joinerRelativesDataDetail, setRelativesDataDetail] = useState(joinerRelativesData)
  const [joinerChildCareDataDetail, setChildCareDataDetail] = useState(joinerChildCareData)
  const [highestQualificationDetail, setHighestQualificationDetail] = useState(highestQualificationData)

  const [submitCheck, setSubmitCheck] = useState(false)

  const [joinerReferenceDataCount, setJoinerReferenceDataCount] = useState(joinerReferenceDataDetail.length)
  const [joinerRelativesDataCount, setJoinerRelativesDataCount] = useState(joinerRelativesDataDetail.length)
  const [joinerChildCareDataCount, setJoinerChildCareDataCount] = useState(joinerChildCareDataDetail.length)
  const [isGettingApiCall, setIsGettingApiCall] = useState(false)
  
  const [notification, setNotification] = useState({type:'', message: ''})
  const [loading, setLoading] = useState(false)
  
  const {
    gender_val='',
    joiner_form_current_step=0,
    joiner_detail_status=null
  } =  offerDataDetail

  const [{ is_fresher } = {}] = joinerEmploymentDataDetail
  const steps = getSteps({ gender_val, is_fresher })
  const [activeStep, setActiveStep] = useState(getStepNumber(steps, joiner_form_current_step))
  const classes = useStyles()
  const dialogTitle = `Confirm`
  const [openDialog, setOpenDialog] = useState(false)
  const [dialogContentText, setDialogContentText] = useState(`Do you want to submit the complete form?`)
  const handleDialogClose = (val) => {
    if (val===true) {
      handleSubmit(initObj)
    }
    setOpenDialog(false)
  }

  const handleNotification = ({type='', message=''}) => {
    setNotification({type, message})
  }

  const isStepOptional = (step) => {
    if (is_fresher===1 && steps[step] === "Reference Details" && joinerReferenceDataCount===0) {
      return true
    }
    if (steps[step] === "Relative Details" && joinerRelativesDataCount === 0) {
      return true
    }
    if (steps[step] === "Child Care Details" && joinerChildCareDataCount === 0) {
      return true
    }
    return false
  }

  const handleSkippableComponent = (componentName, count) => {
    switch (componentName) {
      case "Reference Details":
        setJoinerReferenceDataCount(count)
        break
      case "Relative Details":
        setJoinerRelativesDataCount(count)
        break
      case "Child Care Details":
        setJoinerChildCareDataCount(count)
        break
      default:
        break
    }
  }

  const handleSkip = async () => {
    if (isStepOptional(activeStep)) {
      let {success,data} = await updateStep({stepNo: getStepName(steps, (activeStep+1))})
      if (success) {
        handleAfterApiSuccess(data)
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
      }
    }
    setNotification({type:'', message: ''})
  }

  const handleNext = async () => {
    let stepName = getStepName(steps, activeStep)
    let type=`error`
    let message=``

    if (stepName === 'Welcome') {
      let {success = false} = await updateStep({stepNo:steps[activeStep+1]}) || false
      if (success) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
      }
    } else {
      let checkError = checkIfErroExists(errorObj, stepName)
      if (checkError === false || checkError === undefined) {
        setNotification({type:'', message: ''})
        setSubmitCheck(false)
        if (stepName === 'Final Submission') {
          setDialogContentText(`Do you want to submit the complete form?`)
          setOpenDialog(true)
        } else if (stepName === 'Nominee Details') {
          const {
            maxNomineeSharePercent,
        } = nomineeConstant
          const { share_gratuity, share_pf } = checkNomineeSharePercent(initObj.joinerNomineeData)
          if (share_pf < maxNomineeSharePercent || share_gratuity < maxNomineeSharePercent) {
            message = `Your % share among the added nominees is coming less than 100%.`
          } else {
            handleSubmit(initObj)
          }
        } else {
          handleSubmit(initObj)
        }
      } else {
        const { formErrorMessage } = joinerConst
        let stepName = getStepName(steps, activeStep)
        if (stepName === 'Personal Details') {
          message = errorObj.personalFileUpload.text
        }
        if (stepName === 'Contact Details') {
          message = errorObj.contactFileUpload.text
        }
        
        if (stepName === 'Final Submission') {
          message = errorObj.joinerFinalData.text ? errorObj.joinerFinalData.text :  errorObj.joinerFinalAccept.text
        }
        if (!message) {
          message = formErrorMessage[stepName]
        }
        setSubmitCheck(true)
      }
      setNotification({
        type,
        message
      })
      scrollToViewHandler('main-div')
    }
  }

  const handleSubmit = async (dataObj) => {
    setIsGettingApiCall(true)
    let response = {}
    let type = ''
    let msg = ''
    let stepName = steps[activeStep]
    let stepNo = steps[activeStep+1]
    
    switch (stepName) {
      case "Welcome":
        setLoading(true)
        response = await updateStep({stepNo})
        setLoading(false)
        break
      case "Personal Details":
        dataObj.stepNo = stepNo
        setLoading(true)
        response = await postPersonalData(dataObj)
        setLoading(false)
        break
      case "Contact Details": 
        setLoading(true)
        dataObj.stepNo = stepNo
        response = await postContactData(dataObj)
        setLoading(false)
        break
      case "Previous Employment Details": 
        setLoading(true)
        dataObj.stepNo = stepNo
        response = await postEmploymentData(dataObj)
        
        setLoading(false)
        break
      case "Nominee Details": 
        setLoading(true)
        response = await updateStep({stepNo})
        setLoading(false)
        break
      case "Education Details":
        setLoading(true)
        response = await updateStep({stepNo})
        setLoading(false)
        break
      case "Work Experience": 
        setLoading(true)
        response = await updateStep({stepNo})
        setLoading(false)
        break
      case "Reference Details": 
        setLoading(true)
        response = await updateStep({stepNo})
        setLoading(false)
        break
      case "Relative Details":
        setLoading(true)
        response = await updateStep({stepNo})
        setLoading(false)
        break
      case "Child Care Details": 
        setLoading(true)
        response = await updateStep({stepNo})
        setLoading(false)
        break
      case "Final Submission":
        setLoading(true) 
        response = await updateStep({stepNo: "Form Submitted"})
        setLoading(false)
        break
      default:
        break
    }

    const { 
      success=false, 
      data={},
      message={}
    } = response || {}

    if (success) {
      handleAfterApiSuccess(data)
      if (stepName !== 'Final Submission') {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
        ToastElement({
          msg: `${stepName} Saved Successfully!`,
          type: 'success'
        })
      }
    } else {
      type = 'error'
      msg = parseApiErrorMessage(message) || getApiErrorMesssage({message, stepName})
    }
    
    setIsGettingApiCall(false)
    setNotification({type, message:msg})
  }

  const parseApiErrorMessage = (errorMessage) => {
    for (const key in errorMessage) {
      if (errorMessage[key].length) {
        return errorMessage[key][0]
      }
    }
    return false
  }

  const getApiErrorMesssage = ({message, stepName}) => {
    const { formFieldNames } = joinerConst  
    let errMsg = 'Something went wrong'
    const key = formFieldNames[stepName]
      .filter(element => message[element] !== undefined )
      .filter((element, index) => index===0)
      .find(element => element)
    if (key) {
      errMsg = message[key][0]
    }
    return errMsg
  }

  const handleAfterApiSuccess = (data) => {
    const { 
      offerData={},
      joinerPersonalData=[],
      joinerContactData=[],
      joinerEmploymentData=[],
      joinerNomineeData=[],
      joinerEducationData=[],
      joinerWorkHistoryData=[],
      joinerReferenceData=[],
      joinerRelativesData=[],
      joinerChildCareData=[]
    } = data
    
    if (offerData) {
      setOfferDataDetail(offerData)
    }
    if (joinerPersonalData && joinerPersonalData.length) {
      setPersonalDataDetail(joinerPersonalData)
    }
    if (joinerContactData && joinerContactData.length) {
      setContactDataDetail(joinerContactData)
    }
    if (joinerEmploymentData && joinerEmploymentData.length) {
      setEmploymentDataDetail(joinerEmploymentData)
    }
    if (joinerNomineeData) {
      setNomineeDataDetail(joinerNomineeData)
    }
    if (joinerEducationData) {
      setEducationDataDetail(joinerEducationData)
    }
    if (joinerWorkHistoryData) {
      setWorkHistoryDataDetail(joinerWorkHistoryData)
    }
    if (joinerReferenceData) {
      setReferenceDataDetail(joinerReferenceData)
    }
    if (joinerRelativesData) {
      setRelativesDataDetail(joinerRelativesData)
    }
    if (joinerChildCareData) {
      setChildCareDataDetail(joinerChildCareData)
    }
    if(highestQualificationData && highestQualificationData.length){
      setHighestQualificationDetail(highestQualificationData)
    }
  }

  const handleBack = async () => {
    setNotification({type: '', message: ''})
    await handleStepChangeApiCall()
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
    setSubmitCheck(false)
  }

  const closeAlert = () => {
    setNotification({type:'', message: ''})
  }
  
  const handleStepChange = async (stepNo) => {
    let currentStep = [joinerConst.joinerDetailStatus.OJ_FORM_REJECTED_HRBP, joinerConst.joinerDetailStatus.OJ_FORM_REJECTED_HROPS].includes(offerDataDetail.joiner_detail_status)  ? 'Final Submission' : joiner_form_current_step;
    if (offerDataDetail) {
      if(stepNo<=steps.indexOf(currentStep)){
        await handleStepChangeApiCall()
        setActiveStep(stepNo)
      }
    }
    
    setSubmitCheck(false)
  }

  const handleStepChangeApiCall = async () => {
    const response = await getDashboardData()
    const { success = false, data } = response
    if (success) {
      handleAfterApiSuccess(data)
    }
  }
  return (
    <Fragment>
    { 
      (joiner_detail_status  && [joinerConst.joinerDetailStatus.OJ_LINK_SENT, 
        joinerConst.joinerDetailStatus.OJ_FORM_DRAFT,
        joinerConst.joinerDetailStatus.OJ_FORM_REJECTED_HRBP, joinerConst.joinerDetailStatus.OJ_FORM_REJECTED_HROPS].includes(joiner_detail_status) )
      ?
      (<div className={classes.root} id="main-div">
        <Stepper activeStep={activeStep}>
          {
            steps.map((label) => {
              const stepProps = {}
              const labelProps = {}
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps} onClick={e=>handleStepChange(steps.indexOf(label))} style={{border:'none',background: 'none',padding:'0', cursor:'pointer'}}>
                    <button onClick={e=>handleStepChange(steps.indexOf(label))} style={{border:'none',background: 'none',padding:'0', cursor:'pointer'}}>
                      {label}
                    </button>
                    </StepLabel>
                </Step>
              )
            })
          }
        </Stepper>
        <div>
          {
            activeStep === steps.length ? (
              null
            ) : (
              <div>
                {
                  notification.message !== '' ? 
                    <Grid item sm={12} className={classes.notificationGrid}>
                      <SimpleAlerts 
                        type={notification.type}
                        message={notification.message}
                        handleAlert={closeAlert}
                      />
                      <Divider light />
                    </Grid>
                    : (null)
                }
                <Typography component={'span'} className={classes.instructions}>
                  {
                    getStepContent(activeStep, steps, submitCheck, setSubmitCheck, {
                      offerDataDetail,
                      joinerPersonalDataDetail,
                      maritalStatusList,
                      bloodGroupList,
                      joinerContactDataDetail,
                      joinerEmploymentDataDetail,
                      joinerNomineeDataDetail,
                      joinerEducationDataDetail,
                      joinerWorkHistoryDataDetail,
                      joinerReferenceDataDetail,
                      joinerRelativesDataDetail,
                      joinerChildCareDataDetail,
                      relationList,
                      handleSkippableComponent,
                      handleNotification,
                      highestQualificationDetail
                    })
                  }
                </Typography>
                <div className={classes.btnAction}>
                  {
                    activeStep!==0 ?
                    (
                      <Button 
                        disabled={activeStep === 0 || loading} 
                        onClick={handleBack} 
                        className={classes.button}
                      >
                        Back
                      </Button>
                    ) : (null) 
                  }
                  {isStepOptional(activeStep) && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSkip}
                      className={classes.button}
                    >
                      Skip
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                    disabled={isGettingApiCall}
                  >
                    {
                      activeStep === steps.length - 1 ? 'Finish' : (activeStep === 0 ? 'Start' : 'Save & Next')
                    }
                  </Button>
                </div>
                
                <DialogComponent 
                    openDialog={openDialog}
                    dialogTitle={dialogTitle}
                    dialogContentText={dialogContentText}
                    handleDialogClose={handleDialogClose}
                />
              </div>
            )}
        </div>
      </div>)
      : (<LastPage />)
    }
    </Fragment>
  )
}