import React from "react"
import { useStyles } from "./style"
import { Grid, Card, Paper } from "@material-ui/core"
import ReportTable from "./ReportTable"
import NoRecordToDisplay from "./NoRecordToDisplay"

const columns = [
    {
        id: "emp_name",
        label: "Candidate Name",
        disableSorting: false
    },
    {
        id: "designation",
        label: "Designation",
        disableSorting: false
    },
    {
        id: "department_name",
        label: "Department",
        disableSorting: false
    },
    {
        id: "function_name",
        label: "Sub-Department",
        disableSorting: false
    },
    {
        id: "grade",
        label: "Grade",
        disableSorting: false
    },
    {
        id: "location_name",
        label: "Location",
        disableSorting: false
    },
    {
        id: "group_doj",
        label: "Proposed DOJ",
        disableSorting: false
    },
    {
        id: "emp_manager_name",
        label: "Reporting Manager Name",
        disableSorting: false
    },
    {
        id: "hiring_source_value",
        label: "Source",
        disableSorting: false
    },
    {
        id: "tabp_name",
        label: "TABP",
        disableSorting: false
    }
]

const GrossOfferData = ({
    detailedData = {},
    handleDetailedData
}) => {
    const classes = useStyles()
    let { offerRecord: { offerListing = [] } } = detailedData

    const goBack = (pageName) => {
        handleDetailedData(pageName, true)
    }
    
    const records = offerListing
    const recLen = offerListing.length
   
    return (<React.Fragment>
        <Grid item xs={12} sm={12} md={12} className={classes.youAreAwesomeTableGridBody}>
        <Card>
            <Paper>
                {
                    recLen ? (<ReportTable 
                        rows={records}
                        columns={columns}
                        reportName="Gross Offer"
                        goBack={goBack}
                        pages={[ 10, 50, 100, 500, 1000 ]}
                    />) : <NoRecordToDisplay goBack={goBack} />
                }
                
            </Paper>
        </Card>
        </Grid>
    </React.Fragment>)
}

export default GrossOfferData