import { Grid, Paper, Button, Typography, CircularProgress, Box, List, ListSubheader,ListItem, ListItemText,Divider, TextField, } 
from "@material-ui/core";
import DialogComponent from "../../../components/Shared/Elements/DialogComponent";
import React, { useEffect, useState} from "react";
import { useStyles } from "./style";
import HeaderLayout from "../HeaderLayout";
import PeopleOutlineTwoTone from "@material-ui/icons/PeopleOutlineTwoTone";
import qs from 'qs';
import {
  getProbationFormDetails,
  getLoggedInUserDetailsInProbation,
  updateProbationFormData,
  triggerLetterRegeneration
} from "../fetchData" 
import moment from "moment";
import { probationConstant } from "../../../constants/ProbationConstant";
import SnackBarComponent from "../../../components/Shared/Elements/SnackBarComponent";
import { isEmpty } from "../../../utils/CommonFunction";
import { getProbationFormAccessByUserRole, getFormStatusAfterAction } from "../common";
import { probationApiUrl } from "../../../utils/CommonUrl";

const {probationFormAccess, probationActionInForm,loggedInUserRole, listingTypeUrlParams } = probationConstant;
const { CONFIRMATION_LISTING, TERMINATION_LISTING } = listingTypeUrlParams
const { PAA, RHR, HROPS_L1, HROPS_L2, HR_HEAD} = loggedInUserRole;
const REMARKS_INPUT_CHAR_LIMIT = 500

export function ProbationFormCHRO(props) {
  const classes = useStyles();
  const [notification, setNotification] = useState({ isOpen: false, type: 'success', message: ''})
  const [loading, setLoading] = useState(false);
  const [empData, setEmpData] = useState({});
  const [letterLink, setLetterLink] = useState(' ');
  const [dialog, setDialog] = useState({isOpen: false, contentText: '', actionName: ''});
  const [loggedInUser, setloggedInUser] = useState({empNo: null, role: null})
  const [formAccess, setFormAccess] = useState(probationFormAccess.VIEW);
  const [shouldRedirectToListingPage, setShouldRedirectToListingPage] = useState(false);
  const [isSendBackButtonVisible, setIsSendBackButtonVisible] = useState(false);
  const [isLetterReGenButtonVisible, setIsLetterReGenButtonVisible] = useState(false);
  const [isConfirmationListingPage, setIsConfirmationListingPage] = useState(true);
  const [textBoxCharCount, setTextBoxCharCount] = useState(0)
  const [listingPageURL ,setListingPageURL] = useState(null)
  const [isFormActionTaken, setIsFormActionTaken] = useState(false)

  const handleSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }
    setNotification({isOpen:false, message:'', type:''});
    if(shouldRedirectToListingPage){
      setShouldRedirectToListingPage(false);
      window.parent.postMessage(`${listingPageURL}`, process.env.REACT_APP_BASE_URL)
    }
  };
 
  const handleDialogClose = (val) => {
    if (val === true) {
      handleUpdateFormDataAfterConfirmation(dialog.actionName);
      setIsFormActionTaken(true)
    }
    setDialog(false)
  }

  const handleFormAction =  (actionName) => {
    const isSendBackAction = actionName == 'sentBack'
    if(isSendBackAction && (!empData.sendBackRemarks)) {
      setNotification({ isOpen: true, type : 'error', message: 'Please add send back remarks before proceeding.'})
      return 
    } 
    let dialogText = ``
    if(isConfirmationListingPage) {
      dialogText =   isSendBackAction ? `Are you sure you want to send back the form?` :
      `Are you sure you want to confirm the employee under probation?`;
    } else {
      dialogText =   isSendBackAction ? `Are you sure you want to send back the form?` :
      `Are you sure you want to terminate the employee's agreement under probation?`;
    }
    setDialog({isOpen: true, contentText: dialogText, actionName});
  }

  const handleInputChange = (event, inputKey) => {
    let updatedData = empData
    let sendBackRemarksTextCount = 0
    if(inputKey == 'sendBackRemarks') {
      const enteredValue = event.target.value
      updatedData = { ...updatedData, [inputKey]: enteredValue }
      sendBackRemarksTextCount = enteredValue.length
    }
    setEmpData(updatedData)
    setTextBoxCharCount(sendBackRemarksTextCount)
  }

  useEffect(() => {
    const fetchData =  async () => {
      try {
        const {empNo, id, type} = qs.parse(props.location.search, { ignoreQueryPrefix: true });
        let updatedFormData = empData
        let formAccessType = null
        let letterLink = null;
        updatedFormData.empNo= atob(empNo);
        let isFormFilled = false;
        let isSendBackButtonVisible = false;
        let isLetterReGenButtonVisible = false;
        let pageType = null
        let listingPageURL = null
        if(!isEmpty(id)) {
          const parsedId = atob(id)
          updatedFormData.formId= parsedId;
          isFormFilled = true;
        }
        if(!isEmpty(type)) {
          pageType = atob(type)
        }
        let isConfirmationListingPage = (props.computedMatch.params.type === 'pending-confirmation-letter'); 
        let loggedInUser ={};
        const userData = await getLoggedInUserDetailsInProbation();
        if(userData && userData.empNo && userData.userRole) {
          loggedInUser = {empNo: userData.empNo, role: userData.userRole}
          if([HROPS_L1, HROPS_L2, HR_HEAD].includes(loggedInUser.role)) {
            const details = await getProbationFormDetails(updatedFormData.empNo);
            if(details && details.length){
              const detail = details[0]
              updatedFormData = {...updatedFormData, ...detail};
              updatedFormData.sendBackRemarks = '' // reset previous sendback remarks
              if(detail.letterLink){
                letterLink = `${probationApiUrl.GET_FILE_URL + btoa(detail.letterLink)}`
              } 
              formAccessType = getProbationFormAccessByUserRole(loggedInUser.role, updatedFormData.probationFormStatus);
            }

            if(loggedInUser.role == HR_HEAD) {
              isSendBackButtonVisible = true
            } else {
              isLetterReGenButtonVisible = true
            }
            const {type , role} = props && props.computedMatch && props.computedMatch.params
            listingPageURL = `${process.env.REACT_APP_BASE_URL}/probation/index/${type}/r/${role}?tab=0`
          }
        }
        setloggedInUser(loggedInUser);
        setEmpData(updatedFormData);
        setFormAccess(formAccessType);
        setLetterLink(letterLink);
        setIsSendBackButtonVisible(isSendBackButtonVisible)
        setIsLetterReGenButtonVisible(isLetterReGenButtonVisible)
        setIsConfirmationListingPage(isConfirmationListingPage)
        setListingPageURL(listingPageURL)
      } catch(err) {
      } 
    }
    fetchData();
  }, []);

  const handleLetterRegenerationAction = async () => {
    try {
      setLoading(true)
      const formId = empData && empData.probationFormId
      const resp = await triggerLetterRegeneration({formId})
      if(resp && resp.success) {
        let currentPageLink = window.location.href
        window.location = currentPageLink;
      }
      setLoading(false);
    } catch(error){
      setNotification({
        isOpen: true ,
        message: `Some error occurred while letter regeneration`,
        type: 'error'
      });
      setLoading(false);
    }
  }

  const handleUpdateFormDataAfterConfirmation = async (action) => {
    try {
      setLoading(true);
      const isApproveAction = (action == 'approve');
      const isSendBackAction = (action == "sentBack")
      let obj = {}
      const sendBackRemarks = (isSendBackAction && empData.sendBackRemarks) ? empData.sendBackRemarks : null
      if(formAccess == probationFormAccess.VERIFY) {
        obj = {
          id: empData.formId,
          action
        }
        if(sendBackRemarks) {
          obj = { ...obj, send_back_remarks: sendBackRemarks }
        }
      }
      const resp = await updateProbationFormData(obj); 
      if(resp.success == true) {
        setNotification({
          isOpen: true ,
          message: `Probation form ${isApproveAction ? 'approved' : 'sent back'} successfully`,
          type: 'success'
        });
        setLoading(false);
      } else {
        setNotification({
          isOpen: true ,
          message: `Unable to update probation form`,
          type: 'error'
        });
        setLoading(false);
      }
      setShouldRedirectToListingPage(true);
    } catch(error) {
      setNotification({
        isOpen: true ,
        message: `Some error occurred while updating probation form`,
        type: 'error'
      });
      setShouldRedirectToListingPage(true);
      setLoading(false);
    }  
  }

  const handleBack = () => {
    window.parent.postMessage(`${listingPageURL}`, process.env.REACT_APP_BASE_URL)
  }

  return (
      <Paper className={classes.root}>
          <Grid container spacing={2}>
            <Grid item> 
              <HeaderLayout
                  title= {isConfirmationListingPage ? 'Confirmation Letter' : 'Termination Letter'}
                  subTitle=''
                  icon={<PeopleOutlineTwoTone/>}
              />
            </Grid>
          </Grid>
          <Paper elevation={2} >
            <Grid container spacing={2}>
              {!formAccess  ?
                  <Grid item xs={12} style={{width: '90%'}}>
                    <Typography variant="h6" align="center" style={{color: 'red', textAlign: 'center'}}>
                      You are not authorized to view this page
                    </Typography>
                  </Grid> 
                :
                  <form className={classes.formRoot}>
                    <Grid container className={classes.gridContainer} >
                      <Grid item xs={3}>
                        <Typography variant="subtitle1" className={classes.label}>Employee Name</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="subtitle1" className={classes.value} >{empData && empData.legalname} {empData && empData.employeeno ? `(${empData.employeeno})` : ''}</Typography>
                      </Grid>
                      <Grid item xs={3}> 
                        <Typography variant="subtitle1" className={classes.label}>Group DOJ</Typography>
                      </Grid>
                      <Grid item xs={3} >
                        <Typography variant="subtitle1" className={classes.value} >{empData && moment(empData.groupDOJ).format("DD-MMM-YYYY")}</Typography>
                      </Grid>
                    </Grid>
                    <Grid container className={classes.gridContainer}>
                      <Grid item xs={3} >
                        <Typography variant="subtitle1" className={classes.label}>Company</Typography> 
                      </Grid>
                      <Grid item xs={3} >
                        <Typography variant="subtitle1" className={classes.value} >{empData &&  empData.company}</Typography>
                      </Grid>
                      <Grid item xs={3}> 
                        <Typography variant="subtitle1" className={classes.label}>Reporting Manager</Typography>
                      </Grid>
                      <Grid item xs={3} >
                        <Typography variant="subtitle1" className={classes.value} >{empData && empData.reportingManagerName} </Typography>
                      </Grid>
                    </Grid>
                    <Grid container className={classes.gridContainer}>
                      <Grid item xs={3}> 
                        <Typography variant="subtitle1" className={classes.label}>Designation</Typography>
                      </Grid>
                      <Grid item xs={3} >
                        <Typography variant="subtitle1" className={classes.value} >{empData && empData.designation}</Typography>
                      </Grid>
                      <Grid item xs={3} >
                        <Typography variant="subtitle1" className={classes.label}>Grade</Typography>
                      </Grid>
                      <Grid item xs={3} >
                        <Typography variant="subtitle1" className={classes.value} >{empData && empData.grade}</Typography>
                      </Grid>
                    </Grid>
                    <Grid container className={classes.gridContainer}>
                      <Grid item xs={3}> 
                        <Typography variant="subtitle1" className={classes.label}>Department</Typography>
                      </Grid>
                      <Grid item xs={3} >
                        <Typography variant="subtitle1" className={classes.value} >{empData && empData.department}</Typography>
                      </Grid>
                      <Grid item xs={3} >
                        <Typography variant="subtitle1" className={classes.label}>Location</Typography>
                      </Grid>
                      <Grid item xs={3} >
                        <Typography variant="subtitle1" className={classes.value} >{empData && empData.locality}</Typography>
                      </Grid>
                    </Grid>
                    <Grid container className={classes.gridContainer}>
                      <Grid item xs={12} style={{ marginTop: '30px' }}>
                        {letterLink ? 
                          <object height="600" width="90%" type="application/pdf" data={`${probationApiUrl.GET_FILE_URL + btoa(empData.letterLink)}?#zoom=70&scrollbar=0&toolbar=1&navpanes=0`}>
                            <iframe width="90%" height="600" src={letterLink}></iframe>
                          </object> :
                          <Typography variant="h6" align="center" style={{color: 'red', textAlign: 'center'}}>Some error occured while embedding probation letter</Typography>
                        }
                      </Grid>
                    </Grid>
                    {(empData.sendBackRemarksHistory && empData.sendBackRemarksHistory.length > 0) ? 
                      <Grid container className={classes.gridInputContainer} spacing={3} style={{
                        width: "90%",
                        marginTop: 37,
                        marginLeft: 0,
                        height: 180,
                        overflow: 'scroll',
                        border: "1px solid rgba(0, 0, 0, 0.25)",
                        borderRadius: '.5',
                      }}
                      > 
                        <List style={{ width: "100%", paddingTop: 0}} className={classes.history}>
                          <ListSubheader style={{ lineHeight: '25px', color: 'white', backgroundColor: '#757ce8'}}>
                            Remarks History
                          </ListSubheader>
                        {empData.sendBackRemarksHistory.map((element, key) => (
                            <React.Fragment key={key}>
                              <ListItem key={key}>
                                <ListItemText
                                  style={{ width: "100%" }}
                                  primary={
                                    <React.Fragment>
                                      <Typography
                                        style={{
                                          fontWeight: 'bold',
                                          width: "70%",
                                          display: "inline",
                                          float: "left",
                                          fontSize: "12px",
                                        }}
                                        component="span"
                                        variant="subtitle2"
                                      >
                                        {element.added_by ? element.added_by : ''}
                                      </Typography>
                                      <Typography
                                        style={{
                                          width: "30%",
                                          display: "inline",
                                          fontSize: "10px",
                                          float: "right",
                                          textAlign: "right",
                                          color: "gray",
                                        }}
                                        component="span"
                                        variant="caption"
                                      >
                                        {element.added_at}
                                      </Typography>
                                      <Typography
                                        style={{
                                          display: "block",
                                          width: "100%",
                                          fontSize: "10px",
                                          float:'left',
                                          color: "gray",
                                          marginBottom: 5
                                        }}
                                        component="div"
                                        variant="caption"
                                      >
                                        {element.userRole ? element.userRole : ''}
                                      </Typography>
                                      <Typography
                                        style={{
                                          marginLeft: 15,
                                          display: "block",
                                          fontSize: "13px",
                                          maxHeight: 120,
                                          color: "gray",
                                        }}
                                        component="div"
                                        variant="body2"
                                      >
                                        {element.remarks}
                                      </Typography>
                                      <Typography
                                      style={{
                                        display: "block",
                                        padding: '0 3px 0 3px',
                                        fontSize: "10px",
                                        float:'right',
                                        color: "black",
                                        fontWeight: 'bold',
                                        backgroundColor: element.action == "approve" ? "#99EDC3": "yellow",
                                      }}
                                        component="div"
                                        variant="caption"
                                      >
                                        {element.action == "approve" ? "Approval Remarks" : "Sendback Remarks"}
                                      </Typography>
                                    </React.Fragment>
                                  }
                                />
                              </ListItem> 
                              <Divider component="li" />
                            </React.Fragment>
                        ))}
                        </List>
                      </Grid>
                        : null
                    }
                    {loggedInUser.role == HR_HEAD  && letterLink && 
                        <Grid container className={classes.gridInputContainer} spacing={2} 
                          style={{ width: "100%" }}
                        >
                          <Grid item style={{ width: "92%"}}>
                            <TextField
                              style={{width: '100%'}}
                              className={classes.customTextBox}
                              variant="outlined"
                              label="Send back remarks"
                              placeholder="Add your send back remarks..."
                              name='sendBackRemarks'
                              value={empData.sendBackRemarks}
                              onChange={(event) => handleInputChange(event, 'sendBackRemarks')}
                              multiline={true}
                              minRows={3}
                              maxRows={3}
                              inputProps={{ maxLength: REMARKS_INPUT_CHAR_LIMIT, width: '50px'}}
                            />
                            <div className={classes.charCount}>
                              {textBoxCharCount}&nbsp;/&nbsp;{REMARKS_INPUT_CHAR_LIMIT}
                            </div>
                          </Grid>
                        </Grid> 
                    }
                    {(formAccess == probationConstant.probationFormAccess.VERIFY) &&
                      <Grid container className={classes.gridInputContainer} spacing={2} style={{ width: '100%' }}>
                        {isSendBackButtonVisible && letterLink &&  
                          <Grid item style={{ paddingRight: 31 }}>
                            <Button
                                variant='contained'
                                size='large'
                                color='primary'
                                onClick={() => handleFormAction('sentBack')}
                                disabled={loading || isFormActionTaken}
                                className={classes.button}
                            >
                                Send Back for review
                              </Button>
                          </Grid>  
                        }
                        {letterLink &&
                          <Grid item style={{ paddingRight: 31 }}>
                            <Button
                                variant='contained'
                                size='large'
                                color='primary'
                                onClick={() => handleFormAction('approve')}
                                disabled={loading || isFormActionTaken}
                                className={classes.button}
                            >
                                Approve
                            </Button>
                          </Grid>
                        }
                        <Grid item style={{ paddingRight: 31 }}>
                          <Button 
                            variant="outlined"
                            color="primary"
                            size="large"
                            className={classes.button}
                            onClick={handleBack}
                            disabled={loading}
                          >
                            Back
                          </Button>
                        </Grid>
                        {isLetterReGenButtonVisible &&  
                          <Grid item  style={{ width: 590, paddingRight: 31 }}>
                            <Box display="flex" justifyContent="flex-end">
                              <Button
                                  variant='contained'
                                  size='large'
                                  color='primary'
                                  onClick={handleLetterRegenerationAction}
                                  disabled={loading || isFormActionTaken}
                                  className={classes.button}
                              >
                                  Letter Regeneration
                              </Button>
                            </Box>
                          </Grid>
                        }
                      </Grid>
                    }
                  
                    <Grid container spacing={2}>
                      {loading && 
                        <Grid item style={{position: 'relative', bottom: 115, left: 400}}>
                          <CircularProgress/>
                        </Grid>
                      }
                      {
                        notification.isOpen ? (
                            <SnackBarComponent
                              open={notification.isOpen}
                              severity={notification.type}
                              message={notification.message}
                              handleSnackBarClose={handleSnackBarClose}
                              autoHideDuration={5000}
                              style={{position: 'relative', bottom: '100px'}}
                            />
                        ) : (null)
                      }
                      <Grid item>
                        <DialogComponent
                            openDialog={dialog.isOpen}
                            dialogTitle="Confirm"
                            dialogContentText={dialog.contentText}
                            handleDialogClose={handleDialogClose}
                            agreeButtonText="Yes"
                            disagreeButtonText="No"
                            showAgreeButtonAtFirst={true}
                        />
                      </Grid>
                    </Grid>
                  </form>
              }
            </Grid>
          </Paper>
      </Paper>
  )
}
