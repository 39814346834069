import { probationConstant } from "../../constants/ProbationConstant";
const { loggedInUserRole, formStatusKeyToIdMapping, probationFormAccess,probationActionInForm } = probationConstant;
const { PAA, RHR, HROPS_L1, HROPS_L2, HR_HEAD, HROPS_L3 } = loggedInUserRole;
const {
  PENDING_WITH_PAA,
  PENDING_WITH_RHR,
  PENDING_WITH_HROPS_L1,
  PENDING_WITH_HROPS_L2,
  PENDING_WITH_HR_HEAD,
  CLOSED,
  SENT_BACK_BY_RHR,
  SENT_BACK_BY_HROPS_L1,
  SENT_BACK_BY_HROPS_L2,
  SENT_BACK_BY_HR_HEAD ,
  FORWARDED_PV_TO_HROPS_L1 ,
  FORWARDED_PV_TO_HROPS_L2 ,
  FORWARDED_PV_TO_HR_HEAD ,
  FORWARDED_PV_TO_HROPS_L2_AT_HROPS_L1 ,
  FORWARDED_PV_TO_HR_HEAD_AT_HROPS_L1 ,
  FORWARDED_PV_TO_HR_HEAD_AT_HROPS_L2,
  CONFIRMATION_FWD_TO_HR_HEAD_FROM_PAA,
  CONFIRMATION_FWD_TO_HR_HEAD_FROM_RHR,
  CONFIRMATION_FWD_TO_HROPS_FROM_HR_HEAD,
  CONFIRMATION_FORWARDED_PV_TO_HR_HEAD_FROM_PAA,
  CONFIRMATION_SENT_BACK_BY_HR_HEAD_TO_PAA,
  CONFIRMATION_SENT_BACK_BY_HR_HEAD_TO_RHR,
  CONFIRMATION_FORWARDED_PV_TO_HR_OPS,
  CONFIRMATION_FORWARDED_PV_TO_HR_HEAD_FROM_RHR,
  SENT_BACK_BY_HROPS_L3,

} = formStatusKeyToIdMapping;

export const getProbationFormAccessByUserRole = (loggedInUserRole, currentFormStatus, isFormOpenedInReport=false) => {
  let formAccess = probationFormAccess.VIEW;
  if(isFormOpenedInReport) return formAccess
  switch (loggedInUserRole) {
    case PAA:
      if(currentFormStatus == PENDING_WITH_PAA) {
        formAccess = probationFormAccess.FILL
      } else if(currentFormStatus == SENT_BACK_BY_RHR || currentFormStatus == CONFIRMATION_SENT_BACK_BY_HR_HEAD_TO_PAA) {
        formAccess = probationFormAccess.REVIEW
      } else {
        formAccess = probationFormAccess.VIEW
      }
      break;
    case RHR:
      if(currentFormStatus == PENDING_WITH_RHR) {
        formAccess = probationFormAccess.VERIFY_AND_EDIT;
      } else if ([
        SENT_BACK_BY_HROPS_L1,
        SENT_BACK_BY_HROPS_L2,
        SENT_BACK_BY_HR_HEAD,
        CONFIRMATION_SENT_BACK_BY_HR_HEAD_TO_RHR
      ].includes(currentFormStatus)) {
        formAccess = probationFormAccess.REVIEW;
      }
      break;
    case HROPS_L1:
      if([
          PENDING_WITH_HROPS_L1, 
          FORWARDED_PV_TO_HROPS_L1,
          FORWARDED_PV_TO_HROPS_L2_AT_HROPS_L1,
          FORWARDED_PV_TO_HR_HEAD_AT_HROPS_L1,
          CONFIRMATION_FWD_TO_HROPS_FROM_HR_HEAD,
          CONFIRMATION_FORWARDED_PV_TO_HR_OPS 
        ].includes(currentFormStatus)) {
        formAccess = probationFormAccess.VERIFY;
      }
      break;
    case HROPS_L2:
      if([
        PENDING_WITH_HROPS_L2,
        FORWARDED_PV_TO_HROPS_L2,
        FORWARDED_PV_TO_HR_HEAD_AT_HROPS_L2,
        CONFIRMATION_FWD_TO_HROPS_FROM_HR_HEAD,
        CONFIRMATION_FORWARDED_PV_TO_HR_OPS,
        SENT_BACK_BY_HROPS_L3
      ].includes(currentFormStatus)) {
        formAccess = probationFormAccess.VERIFY;
      }
      break;
    case HR_HEAD:
      if([
        PENDING_WITH_HR_HEAD,
        FORWARDED_PV_TO_HR_HEAD,
        CONFIRMATION_FWD_TO_HR_HEAD_FROM_PAA,
        CONFIRMATION_FWD_TO_HR_HEAD_FROM_RHR,
        CONFIRMATION_FORWARDED_PV_TO_HR_HEAD_FROM_PAA,
        CONFIRMATION_FORWARDED_PV_TO_HR_HEAD_FROM_RHR,
      ].includes(currentFormStatus)) {
        formAccess = probationFormAccess.VERIFY;
      }
      break;
    case HROPS_L3:
      if(currentFormStatus == CLOSED) {
        formAccess = probationFormAccess.VERIFY 
      }
      break
  }
  return formAccess;
}
