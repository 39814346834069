import React, { useState } from "react"
import { Model } from "survey-core"
import { Survey } from "survey-react-ui" 
import Cookies from "js-cookie"

import { saveSurveyResponseCall } from "../fetchData"
import ToastElement from "../../../components/Shared/Elements/ToastElement"

import { SurveyTemplate } from "../SurveyTemplate"

import * as SurveyCore from "survey-core";
import Sortable from 'sortablejs';
import { sortablejs } from "surveyjs-widgets";

import "survey-core/defaultV2.min.css"
import "./index.css"

const FillSurvey = ({
    token,
    empDetails,
    surveyData,
    surveyQueData
}) => {
    const { surveyName, description, is_anonymous, is_mandatory, is_interactive, is_save_draft, isSubmitted, response_json, current_page_num = 0 } = surveyData
    const { question_json = null } = surveyQueData || {}
    let dbQueJson = JSON.parse(question_json)
    dbQueJson = { ...dbQueJson, title: surveyName, description: description }
    const [isSurveyCompleted, setIsSurveyCompleted] = useState(isSubmitted)
    const correctStr = "Correct";
    const incorrectStr = "Incorrect";
    var questionHtmls = {}

    sortablejs(SurveyCore);
    const loadSurvey = () => {
        const survey = new Model(dbQueJson)
        survey.currentPageNo = current_page_num
        
        if (response_json) {
            survey.data = JSON.parse(response_json)   
        }
        if(!!is_interactive && isSubmitted){
            survey.mode = "display";
            survey.questionsOnPageMode = "singlePage";
            survey.showNavigationButtons = "none";
        }

        const getTextHtml = (text, str, isCorrect) => {
            if (text.indexOf(str) < 0)
                return undefined;
        
            return text.substring(0, text.indexOf(str)) +
                "<span class='" +  (isCorrect ? "correctAnswer" : "incorrectAnswer" ) + "'>" +
                    str +
                "</span>";
        }
    
        const isAnswerCorrect = (q) => {
            const correctAnswer = q.correctAnswer;
            if ((!correctAnswer || q.isEmpty()) && q.classMetaData.name != 'boolean')
                return undefined;
        
            let givenAnswer = q.value;
            if (!Array.isArray(correctAnswer))
                return correctAnswer == givenAnswer;
        
            if (!Array.isArray(givenAnswer))
                givenAnswer = [givenAnswer];
        
            for (let i = 0; i < givenAnswer.length; i++) {
                if (correctAnswer.indexOf(givenAnswer[i]) < 0)
                    return false;
            }
            return true;
        }
    
        // Adds "Correct" or "Incorrect" to a question title
        const changeTitle = (q,options = null) => {
            if (!q) return;

            if(q.value === undefined || (Array.isArray(q.value) && q.value.length == 0)) return;
        
            const isCorrect = isAnswerCorrect(q);
            if (!q.prevTitle) {
                q.prevTitle = q.title;
            }
            if (isCorrect === undefined) {
                q.title = q.prevTitle;
            }

            var radio = options ? options.htmlElement.querySelector('input[value="' + q.correctAnswer + '"]') : questionHtmls[q.name].querySelector('input[value="' + q.correctAnswer + '"]');
            if(!!radio) {
                if(!['boolean'].includes(q.classMetaData.name)){
                    radio.parentElement.style.color = 'darkgreen'
                    radio.parentElement.style.fontWeight = 'bold'
                    radio.parentElement.style.opacity = '0.85'
                }
                if(['radiogroup','checkbox'].includes(q.classMetaData.name)){
                    radio.parentElement.querySelector('.sd-item__control-label').style.color = 'darkgreen'
                    radio.parentElement.querySelector('.sd-item__control-label').style.fontWeight = 'bold'
                    radio.parentElement.querySelector('.sd-item__control-label').style.opacity = '0.85'
                }

                if(['rating'].includes(q.classMetaData.name)){
                    radio.parentElement.querySelector('.sd-rating__item-text').style.color = 'darkgreen'
                    radio.parentElement.querySelector('.sd-rating__item-text').style.fontWeight = 'bold'
                    radio.parentElement.querySelector('.sd-rating__item-text').style.opacity = '0.85'
                }

                if(['imagepicker'].includes(q.classMetaData.name)){
                    radio.parentElement.querySelector('.sd-imagepicker__image').style.border = 'solid 3px darkgreen'
                    radio.parentElement.querySelector('.sd-imagepicker__image').style.objectFit = 'fill'
                    var otherChoices = q.choices
                    if(!!otherChoices){
                        q.choices.map(item => {
                            var htmlEle = options ? options.htmlElement.querySelector('input[value="' + item.propertyHash.value + '"]') :  questionHtmls[q.name].querySelector('input[value="' + item.propertyHash.value + '"]');
                            if(q.correctAnswer != item.propertyHash.value){
                                htmlEle.parentElement.style.opacity = '0.30'
                            }
                        })
                    }
                }
            }

            if(q.classMetaData.name == 'checkbox'){
                if(q.value.length >= q.correctAnswer.length){
                    var otherChoices = q.choices
                    q.choices.map(item => {
                        var htmlEle = options ? options.htmlElement.querySelector('input[value="' + item.propertyHash.value + '"]') : questionHtmls[q.name].querySelector('input[value="' + item.propertyHash.value + '"]');
                        htmlEle.setAttribute("disabled","true")

                        var selectAll = options ? options.htmlElement.querySelector('input[value=""]') : questionHtmls[q.name].querySelector('input[value=""]');
                        
                        if(!!selectAll){
                            selectAll.setAttribute("disabled","true")
                            selectAll.parentElement.querySelector('.sd-item__control-label').style.opacity = '0.30'
                        }
                        var noneAll = options ? options.htmlElement.querySelector('input[value="none"]') : questionHtmls[q.name].querySelector('input[value="none"]');
                        if(!!noneAll){
                            noneAll.setAttribute("disabled","true")
                            noneAll.parentElement.querySelector('.sd-item__control-label').style.opacity = '0.30'
                        }
                        
                        if(q.correctAnswer.includes(item.propertyHash.value)){
                            htmlEle.parentElement.querySelector('.sd-item__control-label').style.color = 'darkgreen'
                            htmlEle.parentElement.querySelector('.sd-item__control-label').style.fontWeight = 'bold'
                            htmlEle.parentElement.querySelector('.sd-item__control-label').style.opacity = '0.85'
                        } else {
                            htmlEle.parentElement.querySelector('.sd-item__control-label').style.opacity = '0.30'
                        }

                    })
                }
            }
            q.title =  q.prevTitle + ' ' + (isCorrect ? correctStr : incorrectStr);
            if(q.classMetaData.name == 'checkbox'){
                if(q.value.length >= q.correctAnswer.length){
                    q.title =  q.prevTitle + ' ' + (isCorrect ? correctStr : incorrectStr);
                } else {
                    q.title =  q.prevTitle ;
                }
            }
            if(q.classMetaData.name == 'boolean'){
                let radioBoolean = questionHtmls[q.name].querySelectorAll('.sd-boolean__thumb-ghost')
                Array.from(radioBoolean,ele => {
                    let htmlElement = ele.querySelector(".sv-string-viewer")
                    if(htmlElement.innerHTML == (q.correctAnswer == true ? 'Yes' : 'No')){
                        htmlElement.style.color = "darkgreen"
                        htmlElement.style.fontWeight = 'bold'
                    }
                })
            }
        }
        
        survey.onValueChanged.add((sender, options) => {
            const currentPageNum = survey.currentPage.num - 1
            saveSurveyResponseCall({  token, json: sender.data, isDraft: true, currentPageNum })
        })

        survey.onCurrentPageChanged.add((sender, options) => {
            const currentPageNum = survey.currentPage.num - 1
            saveSurveyResponseCall({  token, json: sender.data, isDraft: true, currentPageNum })
        })

        survey.onComplete.add(async (sender, options) => {
            const currentPageNum = survey.currentPage.num - 1
            const result = await saveSurveyResponseCall({  token, json: sender.data, isSubmitted: true, currentPageNum })
            
            if (result.success) {
                survey.showCompletedPage = true
                survey.completedHtml = `<div>Done!</div>`
                ToastElement({
                    msg: `${result.message}`,
                    type: 'success'
                })
            } else {
                ToastElement({
                    msg: `${result.message}`,
                    type: 'error'
                })
            }
            if(!is_interactive){
                setIsSurveyCompleted(result.success)
            }
        })  

        if(!is_interactive){
            survey.addNavigationItem({
                id: "survey_clear_current_page",
                title: "Clear page",
                visibleIndex: 49, 
                action: () => {
                    survey.currentPage.questions.forEach(question => question.value = undefined)
                }
            })
        }

        if(!!is_interactive){
            survey.onTextMarkdown.add((_, options) => {
                const text = options.text;
                let html = getTextHtml(text, correctStr, true);
                if (!html) {
                    html = getTextHtml(text, incorrectStr, false);
                }
                if (!!html) {
                    // Set an HTML string with the "Correct" or "Incorrect" suffix for display
                    options.html = html;
                }
            });
        }
        
        

        if(!!is_interactive){
            survey
            .onAfterRenderQuestion
            .add(function (survey, options) {
                questionHtmls = {...questionHtmls,[options.question.name]:options.htmlElement}
                if(!!isSubmitted){
                    changeTitle(options.question,options)
                }
            });
        }
         

        if (is_anonymous === 1 || is_save_draft === 1) {
            survey.addNavigationItem({
                id: "survey_save_draft_current_page",
                title: "Save as Draft",
                visibleIndex: 51, 
                action: saveAsDraftFunc.bind(null, survey)
            })
        }


        
        const renderBack = ({ surveyToken, isSurveyCompleted }) => {
            const progressText = "Go Back to HRIS"
            const progressSpan = <span className="navigation-text">{progressText}</span>

            return (
                <div className="navigation-block">
                    <div className="sv-action__content">
                        <button className="sd-btn sd-navigation__next-btn go-back-btn" onClick={goBackFunc.bind(null, { surveyToken, isSurveyCompleted })}>{ progressSpan }</button>
                    </div>
                </div>
            )
        }

        return (
            <div>
                { (is_anonymous === 1) ? null : ( is_mandatory === 1 && !isSurveyCompleted ? null : renderBack({ surveyToken: token, isSurveyCompleted })) }
                { !is_anonymous && !isSurveyCompleted && is_save_draft === 1? saveDraftInfo() : null }
                { (isSurveyCompleted && !is_interactive) ? completedPage({ surveyName: surveyData.surveyName , description: surveyData.description, isSubmitted }) : <Survey model={survey} /> }
            </div>
        )
    }

    const goBackFunc = ({ surveyToken, isSurveyCompleted }) => {
        if (isSurveyCompleted) {
            removeCookieFunc({ name: "SURVEY", value: surveyToken })
        } else {
            setCookieFunc({ name: "SURVEY", value: surveyToken })
        }

        window.location = process.env.REACT_APP_BASE_URL
    }

    const removeCookieFunc = ({ name, value }) => {
        let checkedSurveyIdList = Cookies.get(name)
        if (!checkedSurveyIdList) {
            checkedSurveyIdList = []
        } else {
            checkedSurveyIdList = JSON.parse(checkedSurveyIdList)
        }

        if (Array.isArray(checkedSurveyIdList) && checkedSurveyIdList.includes(value)) {
            const index = checkedSurveyIdList.indexOf(value)
            if (index > -1) {
                checkedSurveyIdList.splice(index, 1)
            }
        }

        if (checkedSurveyIdList.length) {
            Cookies.set(`${name}`, JSON.stringify(checkedSurveyIdList), { domain: `.${getSubDomainName()}`, path: '/' })
        } else {
            Cookies.remove(name)
        }
    }

    const setCookieFunc = ({ name, value }) => {
        let checkedSurveyIdList = Cookies.get(name)
        if (!checkedSurveyIdList) {
            checkedSurveyIdList = [value]
        } else {
            checkedSurveyIdList = JSON.parse(checkedSurveyIdList)
        }

        if (Array.isArray(checkedSurveyIdList) && !checkedSurveyIdList.includes(value)) {
            checkedSurveyIdList.push(value)
        }

        Cookies.set(`${name}`, JSON.stringify(checkedSurveyIdList), { domain: `.${getSubDomainName()}`, path: '/' })
    }

    const getSubDomainName = () => {
        let replaceWord = ''
        if (['local', 'production'].includes(process.env.REACT_APP_ENV)) {
            replaceWord = 'hrms.'
        }

        if (process.env.REACT_APP_ENV === 'beta') {
            replaceWord = 'devmenaka.'
        }

        if (process.env.REACT_APP_ENV === 'uat') {
            replaceWord = 'uat-menaka.'
        }
       
       return window.location.hostname.replace(replaceWord, '')
    }
      

    const saveAsDraftFunc = async (survey) => {
        const pageValue = setSurveyResponseData(survey)
        const currentPageNum = survey.currentPage.num - 1
        const result = await saveSurveyResponseCall({  token, json: pageValue, isDraft: true, currentPageNum })
            if (result.success) {
                goBackFunc({ surveyToken: token, isSurveyCompleted })
            } else {
                ToastElement({
                    msg: `${result.message}`,
                    type: 'error'
                })
            }
    }

    const setSurveyResponseData = (survey) => {
        let result = {}
        for (const obj of survey.getPlainData()) {
            const { name, value } = obj
            result = { 
                ...result, 
                [name]: (value === undefined ? '' : value) 
            }
        }
       
        return result
    }

    const getAckMessage = ({ isSubmitted, isAnonymous }) => {
        let message = `Thank you for completing the survey!`
        if (isAnonymous === 1 && isSubmitted) {
            message = `This survey link has expired! Contact your HRBP if you have not attempted the survey.` 
        } else if (isSubmitted) {
            message = `This survey link has expired!` 
        }

        return message
    }

    const completedPage = ({ surveyName = '', description = '', isSubmitted }) => {
        return (
                <SurveyTemplate 
                    surveyName={surveyName}
                    description={description}
                    message={getAckMessage({ isSubmitted, isAnonymous: is_anonymous })}
                />
            )
    }

    const saveDraftInfo = () => {
        return (
            <div className="save-draft-info">
                <span>This survey supports Save as Draft. You may click the Save as Draft button to save your progress and proceed to HRIS. You can continue filling the survey the next time you login to HRIS or use the Surveys Tab in HRIS to access the survey again.</span>
            </div>
        )
    }
    
    return loadSurvey()
}

export { FillSurvey }