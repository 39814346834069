import React, { useState,useEffect } from 'react';
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CardHeader from '@material-ui/core/CardHeader';
import Card from '@material-ui/core/Card';
import HeaderLayout from '../HeaderLayout'
import PeopleOutlineTwoTone from '@material-ui/icons/PeopleOutlineTwoTone'

import { useStyles } from './style'
import { Button, FormControl, Grid, Snackbar, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from '@material-ui/core'
import { postModuleMasterConfigFetchData, getModuleMasterListFetchData } from '../fetchData';
import MuiAlert from '@material-ui/lab/Alert/Alert';
import { efilingConstant } from '../../../constants/EfilingConstant';
import logo from '../../../utils/load.gif'
import qs from 'qs'
import { getMenuSubmenuRightsDetail } from '../../../utils/authFunction';
import { pageURLConfig, menuSubmenuPageCategory } from '../../../constants/CommonConstant';
const { moduleName, controllerName, actionName } = pageURLConfig.EFILING_INDEX_MODULE_MASTER_CONFIG
const { ADMIN_CATEGORY } = menuSubmenuPageCategory

const INITIAL_MODULE_DETAILS_OBJECT = {
    module_name: '',
    isEditing: false,
    editModuleId: 0
}

const ModuleDetails = ({handleNewModuleDetail,props,moduleMasterListAndIdMapping, isHavingPageAddAccess, isHavingPageEditAccess}) => {
    const classes = useStyles()
    const [expandDefault, setExpandDefault] = useState(false)
    const [newModuleDetails, setNewModuleDetails] = useState(INITIAL_MODULE_DETAILS_OBJECT)

    useEffect( () => {
        checkEditable()
    }, [moduleMasterListAndIdMapping])

    const handleChange = event => {
        setNewModuleDetails({ ...newModuleDetails, [event.target.name]: event.target.value })
    }

    const checkEditable = async () => {
        let moduleId = qs.parse(props.location.search, { ignoreQueryPrefix: true }).id
        if(moduleId){
            moduleId = atob(moduleId)
        }
        if(moduleMasterListAndIdMapping && moduleMasterListAndIdMapping[moduleId]){
            setNewModuleDetails({...newModuleDetails,
                                module_name: moduleMasterListAndIdMapping[moduleId]['module_name'],
                                isEditing: true,
                                editModuleId: moduleId
            })
        }
    }

    return (
            <Accordion
                expanded={expandDefault}
                onChange={() => setExpandDefault(!expandDefault)}
                className={classes.pageContent}
                style={{margin:'9px'}}
                
            >
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1a-content'
                id='panel1a-header'
                className={classes.pageContent}
                >                   
                    <Grid>
                        <Card>
                            <CardHeader
                                title="Add/Edit Module"
                                style={{ color: '#3f51b5'}}
                                titleTypographyProps={{variant:'subtitle1' }}
                            />
                        </Card>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails className={classes.pageContent}>
                <Grid container>
                <Paper elevation={2} className={classes.root}>
                    <form className={classes.formRoot} noValidate autoComplete='off'>
                        <Grid container>
                            <Grid container spacing={3}>
                                <Grid item xs={4} sm={12} md={4}>
                                <TextField
                                    id='outlined-basic'
                                    label='Module Name'
                                    placeholder='Module Name'
                                    name='module_name'
                                    value={newModuleDetails.module_name || ''}
                                    onChange={e => handleChange(e)}
                                    className={classes.formControl}
                                    inputProps={
                                        { readOnly: false, }
                                    }
                                />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            spacing={3}
                            className={classes.marginTop}
                            direction='row'
                            alignItems='center'
                        >
                            <Grid item>
                            <Button
                                variant='contained'
                                color='primary'
                                size='small'
                                style={{ marginLeft: 20 }}
                                onClick={() => handleNewModuleDetail(newModuleDetails)}
                                className={classes.button}
                                disabled={newModuleDetails.isEditing ? !isHavingPageEditAccess : !isHavingPageAddAccess}
                            >
                                {newModuleDetails.isEditing ? 'Edit' : 'Add'}
                            </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
                </Grid>
                </AccordionDetails>
            </Accordion>
    )
}


export default function ModuleMasterConfigScreen(props) { 
    const classes = useStyles()
    const [isLoading, setIsLoading] = useState(false)
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(200);
    const [documentMasterListAndIdMapping, setDocumentMasterListAndIdMapping] = useState([])
    const [moduleMasterListAndIdMapping, setModuleMasterListAndIdMapping] = useState([])
    const [snackBarDetails, setSnackBarDetails] = useState({open:false,errorMessage:'',type:''})
    const [isHavingPageAddAccess, setIsHavingPageAddAccess] = useState(false)
    const [isHavingPageEditAccess, setIsHavingPageEditAccess] = useState(false)
    const [isHavingPageViewAccess, setIsHavingPageViewAccess] = useState(null)

    useEffect( () => {
        getModuleMasterList()
        setPageRights()
    },[])

    const setPageRights = async () => {
        const params = { pageCategory: ADMIN_CATEGORY, moduleName, controllerName, actionName}
        const rightsObj = await getMenuSubmenuRightsDetail(params)
        const { 
            isUserSuperAdmin,
            isUserGroupAdmin,
            allEmployeeAddRights,
            allEmployeeEditRights, 
            allEmployeeViewRights 
       } = rightsObj
       const addAccess = isUserSuperAdmin || 
            (isUserGroupAdmin && allEmployeeAddRights) 
       const editAccess =  isUserSuperAdmin || 
            (isUserGroupAdmin && allEmployeeEditRights)
       const viewAccess =  isUserSuperAdmin || 
            (isUserGroupAdmin && allEmployeeViewRights)
        
        setIsHavingPageAddAccess(addAccess)
        setIsHavingPageEditAccess(editAccess)
        setIsHavingPageViewAccess(viewAccess)
   }

    const handleNewModuleDetail = async ( newModuleDetails ) => {
        try {
            setIsLoading(true)
            let validateResponse = await validateNewModuleDetail(newModuleDetails)
            let response = {}
            if(validateResponse && validateResponse.error) {
                setSnackBarDetails({open:true,errorMessage:validateResponse.message,type:'error'})
                setIsLoading(false)
            } else {
                response = await postModuleMasterConfigFetchData({newModuleDetails})
                if(response && response.data && response.data.message){
                    setSnackBarDetails({open:true,errorMessage:response.data.message,type:'success'})
                    setIsLoading(false)
                } else {
                    setSnackBarDetails({open:true,errorMessage:'something went wrong',type:'error'})
                }
            }
            
            getModuleMasterList()
            setPage(0);
            
        } catch (error) {
            
        }
    }

    const validateNewModuleDetail = async (newModuleDetails) => {
        let validateResponse = {message:'',error:false}
        if(newModuleDetails){
            if(!newModuleDetails.module_name){
                validateResponse.message = 'Module Name is required'
                validateResponse.error = true
                return validateResponse
            }
        }
        return validateResponse
    }



    const getModuleMasterList = async () => {
        try {
            let response = {}
            response = await getModuleMasterListFetchData();
            let moduleDetailAndIdMapping = {}
            response.data.forEach(element => {
                moduleDetailAndIdMapping[element.id] = element
            })
            console.log(moduleDetailAndIdMapping)
            setModuleMasterListAndIdMapping(moduleDetailAndIdMapping)
        } catch (error) {
            
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };
    
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackBarDetails({open:false,errorMessage:'',type:''});
    };


    return (
        <React.Fragment>
            {(!isHavingPageViewAccess) 
            ? 
            (isHavingPageViewAccess == null) ? <div className={classes.loader}><img src={logo} alt="loading..." /></div> :
            <div> <h1 style={{margin:'0px'}}>Unauthorised </h1> <br></br> <span>You don't have access to view this page</span> </div> 
            : 
            <div className={classes.root}>
                {snackBarDetails && snackBarDetails.open ? 
                    <Snackbar
                        anchorOrigin={{ vertical:'top', horizontal:'center' }}
                        open={snackBarDetails.open}
                        autoHideDuration={4000}
                        onClose={handleSnackbarClose}
                    >
                        <MuiAlert severity={snackBarDetails.type}>{snackBarDetails.errorMessage}</MuiAlert>
                    </Snackbar> : (null)
                }
                <Grid container>
                    <Grid> 
                    <HeaderLayout
                        title='Module Master'
                        subTitle='Config'
                        icon={<PeopleOutlineTwoTone/>}
                    />
                    </Grid>
                </Grid>
                <ModuleDetails
                handleNewModuleDetail={handleNewModuleDetail}
                props={props}
                moduleMasterListAndIdMapping={moduleMasterListAndIdMapping}
                isHavingPageAddAccess={isHavingPageAddAccess}
                isHavingPageEditAccess={isHavingPageEditAccess}
                />
                { ( isLoading ) 
                ? 
                <div className={classes.loader}><img src={logo} alt="loading..." /></div>
                : 
                <Paper className={classes.pageContent} elevation={5}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell>Module Name</TableCell>
                                    <TableCell style={{textAlign:'center'}}>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            { ( moduleMasterListAndIdMapping && Object.keys(moduleMasterListAndIdMapping).length > 0 ) ? Object.keys(moduleMasterListAndIdMapping).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((element,key)=>(
                                <TableRow key={key}>
                                    <TableCell >{key + 1}</TableCell>
                                    <TableCell>{moduleMasterListAndIdMapping[element]['module_name']}</TableCell>
                                    <TableCell style={{textAlign:'center'}}><a target='blank' href={process.env.REACT_APP_BASE_URL+'/efiling/index/module-master-config/m/ep/id/'+btoa(element)}>Modify</a>
                                    </TableCell>
                                </TableRow>
                            )) : (
                                <TableRow>
                                <TableCell><b>No result found</b></TableCell>
                                </TableRow>
                            ) } 
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                    rowsPerPageOptions={[50, 100, 200, 250]}
                    component="div"
                    count={Object.keys(moduleMasterListAndIdMapping).length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                </Paper>
                }
            </div>}
        </React.Fragment>
    )

}
