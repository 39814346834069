export const getEmployeeDetailUrl = () => {
    return `${process.env.REACT_APP_HOST_NAME}/api/hrms/emp`
}

export const userApiUrl = {
    "USER_DATA_URL": `${process.env.REACT_APP_HOST_NAME}/api/hrms/user`
}

export const pageAcessUrl = {
    "PAGE_ACESS_URL": `${process.env.REACT_APP_HOST_NAME}/api/hrms/user/pageAcesses`
}

export const menuSubmenuAccessUrl = {
    "MENU_SUBMENU_ACCESS_URL": `${process.env.REACT_APP_HOST_NAME}/api/hrms/user/menuSubmenuAccess`
}


export const joinerApiUrl = {
    'OFFER_GET_DATA_URL': `${process.env.REACT_APP_HOST_NAME}/api/hrms/joiner/dashboard`,
    'PERSONAL_POST_DATA_URL': `${process.env.REACT_APP_HOST_NAME}/api/hrms/joiner/personalData`,
    'CONTACT_POST_DATA_URL': `${process.env.REACT_APP_HOST_NAME}/api/hrms/joiner/contactData`,
    'EMPLOYMENT_POST_DATA_URL': `${process.env.REACT_APP_HOST_NAME}/api/hrms/joiner/employmentData`,
    'NOMINEE_POST_DATA_URL': `${process.env.REACT_APP_HOST_NAME}/api/hrms/joiner/nomineeData`,
    'DELETE_JOINER_RECORD_URL': `${process.env.REACT_APP_HOST_NAME}/api/hrms/joiner/deleteRecord`,
    'UPDATE_JOINER_STEPS_URL': `${process.env.REACT_APP_HOST_NAME}/api/hrms/joiner/updateSteps`,
    'EDUCATION_POST_DATA_URL': `${process.env.REACT_APP_HOST_NAME}/api/hrms/joiner/educationData`,
    'WORK_POST_DATA_URL': `${process.env.REACT_APP_HOST_NAME}/api/hrms/joiner/workHistoryData`,
    'REFERENCE_POST_DATA_URL': `${process.env.REACT_APP_HOST_NAME}/api/hrms/joiner/referenceData`,
    'RELATIVE_POST_DATA_URL': `${process.env.REACT_APP_HOST_NAME}/api/hrms/joiner/relativesData`,
    'CHILD_CARE_POST_DATA_URL': `${process.env.REACT_APP_HOST_NAME}/api/hrms/joiner/childCareData`,
    'EMP_GET_DATA_URL': `${process.env.REACT_APP_HOST_NAME}/api/hrms/joiner/getEmpForJoiner`
}

export const campaignApiUrl = {
    'CAMPAIGN_DATA_POST_URL': `${process.env.REACT_APP_HOST_NAME}/api/hrms/campaign/insertCampaignData`,
    'GET_DEPARTMENT_LIST'   : `${process.env.REACT_APP_HOST_NAME}/api/hrms/campaign/getDepartmentList`,
    'GET_GRADE_LIST'        : `${process.env.REACT_APP_HOST_NAME}/api/hrms/campaign/getGradeList`,
    'GET_CAMPAIGN_TYPE_LIST': `${process.env.REACT_APP_HOST_NAME}/api/hrms/campaign/getCampaignTypeList`,
    'GET_ACTIVE_CAMPAIGNS'  : `${process.env.REACT_APP_HOST_NAME}/api/hrms/campaign/getActiveCampaigns` ,
    'GET_CAMPAIGN_DATA'     : `${process.env.REACT_APP_HOST_NAME}/api/hrms/campaign/getCampaignData`,
    'GET_ALL_CAMPAIGNS'     : `${process.env.REACT_APP_HOST_NAME}/api/hrms/campaign/getAllCampaigns`,
    'CAMPAIGN_DATA_UPDATE_URL': `${process.env.REACT_APP_HOST_NAME}/api/hrms/campaign/updateCampaignData`,
    'UPLOAD_FILE_URL'       : `${process.env.REACT_APP_HOST_NAME}/api/hrms/campaign/uploadFile`,
    'GET_IMAGE_URL'       : `${process.env.REACT_APP_HOST_NAME}/api/hrms/campaign/`,
    'GET_LOCATION_LIST'     : `${process.env.REACT_APP_HOST_NAME}/api/hrms/campaign/getLocationList`,
    'CAMPAIGN_DATA_POST_TEST_URL': `${process.env.REACT_APP_HOST_NAME}/singlefile`,
    'GET_SUBDEPARTMENT_LIST': `${process.env.REACT_APP_HOST_NAME}/api/hrms/campaign/getSubDepartmentList`,
    'GET_ACTIVITY_LIST': `${process.env.REACT_APP_HOST_NAME}/api/hrms/campaign/getActivityList`,
    'CAMPAIGN_ORDER_UPDATE_URL': `${process.env.REACT_APP_HOST_NAME}/api/hrms/campaign/updateCampaignsOrder`,
}

export const EfilingApiUrl = {
    'CAMPAIGN_DATA_POST_URL': `${process.env.REACT_APP_HOST_NAME}/api/hrms/campaign/insertCampaignData`,
    'GET_DEPARTMENT_LIST'   : `${process.env.REACT_APP_HOST_NAME}/api/hrms/campaign/getDepartmentList`,
    'GET_EMPLOYEE_DOCUMENT_DETAILS'        : `${process.env.REACT_APP_HOST_NAME}/api/hrms/efiling/getEmployeeDocumentDetails`,
    'GET_LOGGED_IN_EMPLOYEE_DETAILS'       : `${process.env.REACT_APP_HOST_NAME}/api/hrms/efiling/getLoggedInEmployeeDetails`,
    'GET_DOCUMENT_MASTER_LIST'             : `${process.env.REACT_APP_HOST_NAME}/api/hrms/efiling/getDocumentMasterList`,
    'GET_STATUS_MASTER_LIST'             : `${process.env.REACT_APP_HOST_NAME}/api/hrms/efiling/getStatusMasterList`,
    'POST_EMPLOYEE_DOCUMENT_LIST'          : `${process.env.REACT_APP_HOST_NAME}/api/hrms/efiling/postEmployeeDocumentList`,
    'UPDATE_EMPLOYEE_DOCUMENT_LIST'          : `${process.env.REACT_APP_HOST_NAME}/api/hrms/efiling/updateEmployeeDocumentList`,
    'GET_FILE_URL' : `${process.env.REACT_APP_HOST_NAME}/api/hrms/efiling/file?key=`,
    'GET_FILE_URL_1' : `${process.env.REACT_APP_HOST_NAME}/api/hrms/efiling/file/`,
    'UPLOAD_EFILING_FILE' : `${process.env.REACT_APP_HOST_NAME}/api/hrms/efiling/uploadFile`,
    'GET_PENDING_DOCUMENT_DETAILS' : `${process.env.REACT_APP_HOST_NAME}/api/hrms/efiling/getPendingDocumentDetails`,
    'UPDATE_PENDING_DOCUMENTS'     :  `${process.env.REACT_APP_HOST_NAME}/api/hrms/efiling/updatePendingDocuments`,
    'GET_HROPS_LIST'            : `${process.env.REACT_APP_HOST_NAME}/api/hrms/efiling/getHropsList`,
    'GET_SUBDEPARTMENT_LIST': `${process.env.REACT_APP_HOST_NAME}/api/hrms/campaign/getSubDepartmentList`,
    'GET_LOCATION_LIST'     : `${process.env.REACT_APP_HOST_NAME}/api/hrms/campaign/getLocationList`,
    'GET_GRADE_LIST'        : `${process.env.REACT_APP_HOST_NAME}/api/hrms/campaign/getGradeList`,
    'GET_ALL_EMPLOYEE_DOCUMENT_LIST' : `${process.env.REACT_APP_HOST_NAME}/api/hrms/efiling/getAllEmployeeDocumentDetails`,
    'BULK_UPLOAD_EFILING_FILE' : `${process.env.REACT_APP_HOST_NAME}/api/hrms/efiling/bulkUpload`,
    'EMPLOYEE_BULK_UPLOAD_DOCUMENT_DETAILS' : `${process.env.REACT_APP_HOST_NAME}/api/hrms/efiling/getEmployeeBulkUploadDocumentDetails`,
    'GET_HRBP_LIST' : `${process.env.REACT_APP_HOST_NAME}/api/hrms/efiling/gethrbplist`,
    'GET_HRBP_EMPLOYEE_DOCUMENT_LIST' : `${process.env.REACT_APP_HOST_NAME}/api/hrms/efiling/getHrbpEmployeeDocumentList`,
    'SEND_EMAIL'            : `${process.env.REACT_APP_HOST_NAME}/api/hrms/efiling/sendEmail`,
    'DOWNLOAD_PENDING_DOCUMENT_REPORT': `${process.env.REACT_APP_HOST_NAME}/api/hrms/efiling/downloadPendingDocumentReport`,
    'POST_DOCUMENT_MASTER_CONFIG' : `${process.env.REACT_APP_HOST_NAME}/api/hrms/efiling/postDocumentMasterConfig`,
    'GET_MODULE_MASTER_LIST'             : `${process.env.REACT_APP_HOST_NAME}/api/hrms/efiling/getModuleMasterList`,
    'POST_MODULE_MASTER_CONFIG' : `${process.env.REACT_APP_HOST_NAME}/api/hrms/efiling/postModuleMasterConfig`,
    'UPLOAD_FILES_TO_GOOGLE_DRIVE': `${process.env.REACT_APP_HOST_NAME}/api/hrms/efiling/uploadFilesToGoogleDrive`,
    'POST_BULK_DOWNLOAD': `${process.env.REACT_APP_HOST_NAME}/api/hrms/efiling/insertBulkDownload`,
    'POST_DOWNLOAD_ALL_FILES': `${process.env.REACT_APP_HOST_NAME}/api/hrms/efiling/postDownloadAllFiles`,
    'POST_PENDING_DOCUMENT_ALERT': `${process.env.REACT_APP_HOST_NAME}/api/hrms/efiling/postPendingDocumentAlert`,
    'EMP_AUTOCOMPLETE_LIST_URL': `${process.env.REACT_APP_HOST_NAME}/api/hrms/efiling/empAutocompleteList`,
} 
export const employeeTaggingUrl = {
    'GET_ALL_EMPLOYEE_TAGGING_DETAILS': `${process.env.REACT_APP_HOST_NAME}/api/hrms/employeeTagging/getAllEmployeeTaggingDetails`,
    'GET_ALL_EMP_SUMMARY': `${process.env.REACT_APP_HOST_NAME}/api/hrms/employeeTagging/getSummary`,
    'GET_PODS_LIST': `${process.env.REACT_APP_HOST_NAME}/api/hrms/employeeTagging/getPodsList`,
    'GET_SKILLS_LIST': `${process.env.REACT_APP_HOST_NAME}/api/hrms/employeeTagging/getSkillsList`,
    'UPDATE_EMPLOYEE_TAGGING': `${process.env.REACT_APP_HOST_NAME}/api/hrms/employeeTagging/updateEmployeeTagging`,
    'DOWNLOAD_EMPLOYEE_TAGGING_AS_EXCEL': `${process.env.REACT_APP_HOST_NAME}/api/hrms/employeeTagging/download`,
    'DOWNLOAD_EMPLOYEE_TAGGING_SUMMARY': `${process.env.REACT_APP_HOST_NAME}/api/hrms/employeeTagging/downloadSummary`,
    'GET_EMPLOYEE_DETAILS_FOR_EMPLOYEE_TAGGING': `${process.env.REACT_APP_HOST_NAME}/api/hrms/employeeTagging/getEmployeeDetailsForEmployeeTagging`
}

export const probationApiUrl = {
    'GET_PROBATION_RECORDS' : `${process.env.REACT_APP_HOST_NAME}/api/hrms/probation/getProbationRecords`,
    'POST_PROBATION_FORM_DETAILS' : `${process.env.REACT_APP_HOST_NAME}/api/hrms/probation/insertProbationFormDetails`,
    'UPDATE_PROBATION_FORM_DETAILS' : `${process.env.REACT_APP_HOST_NAME}/api/hrms/probation/updateProbationFormDetails`,
    'GET_PROBATION_FORM_DETAILS':  `${process.env.REACT_APP_HOST_NAME}/api/hrms/probation/getProbationFormDetails`,
    'GET_LOGGED_IN_EMPLOYEE_DETAILS'       : `${process.env.REACT_APP_HOST_NAME}/api/hrms/probation/getLoggedInEmployeeDetails`,
    'SEND_EMAIL_ON_PROBATION_CLOSURE'       : `${process.env.REACT_APP_HOST_NAME}/api/hrms/probation/sendEmailOnProbationClosure`,
    'GET_LOGGED_IN_USER_ROLE_FOR_PROBATION' : `${process.env.REACT_APP_HOST_NAME}/api/hrms/probation/getLoggedInUserRole`,
    'DOWNLOAD_PROBATION_REPORT': `${process.env.REACT_APP_HOST_NAME}/api/hrms/probation/downloadProbationReport`,
    'LETTER_REGENERATION': `${process.env.REACT_APP_HOST_NAME}/api/hrms/probation/triggerProbationLetterRegeneration`,
    'GET_FILE_URL' : `${process.env.REACT_APP_HOST_NAME}/api/hrms/probation/file?key=`, 

}

export const commonApiUrl = {
    'GET_DEPARTMENT_LIST'   : `${process.env.REACT_APP_HOST_NAME}/api/hrms/campaign/getDepartmentList`,
    'GET_SUBDEPARTMENT_LIST': `${process.env.REACT_APP_HOST_NAME}/api/hrms/campaign/getSubDepartmentList`,
    'GET_LOCATION_LIST'     : `${process.env.REACT_APP_HOST_NAME}/api/hrms/campaign/getLocationList`,
    'GET_GRADE_LIST'        : `${process.env.REACT_APP_HOST_NAME}/api/hrms/common/getGradeList`,
    'GET_DESIGNATION_LIST'        : `${process.env.REACT_APP_HOST_NAME}/api/hrms/common/getDesignationList`,
    'GET_UNIQUE_GRADE_LIST'        : `${process.env.REACT_APP_HOST_NAME}/api/hrms/common/getUniqueGradeList`,
    'GET_DESIGNATION_BY_GRADE_NAME_LIST' : `${process.env.REACT_APP_HOST_NAME}/api/hrms/common/getDesignationByGradeNameList`
}

export const surveyApiUrl = {
    "GET_SURVEY_DATA_URL": `${process.env.REACT_APP_HOST_NAME}/api/hrms/survey/getSurveyDetails`,
    "SAVE_SURVEY_DATA_URL": `${process.env.REACT_APP_HOST_NAME}/api/hrms/survey/saveSurveyData`,
    "SAVE_SURVEY_RESPONSE_URL": `${process.env.REACT_APP_HOST_NAME}/api/hrms/survey/saveSurveyResponse`,
    "GET_SURVEY_BY_TOKEN_DATA_URL": `${process.env.REACT_APP_HOST_NAME}/api/hrms/survey/getSurveyDetailsByToken`,
    "SAVE_SURVEY_CRITERIA": `${process.env.REACT_APP_HOST_NAME}/api/hrms/survey/saveSurveyCriteria`,
    "GET_SURVEY_CRITERIA_LIST": `${process.env.REACT_APP_HOST_NAME}/api/hrms/survey/getSurveyCriteriaList`,
    "DOWNLOAD_CSV_FOR_CRITERIA_BUCKET": `${process.env.REACT_APP_HOST_NAME}/api/hrms/survey/downloadCsvForCriteriaBucket`,
    "GET_AVERAGE_SCORE_DETAILS": `${process.env.REACT_APP_HOST_NAME}/api/hrms/survey/postAnonymousSurveyReport`,
    "GET_SURVEY_LIST_V2": `${process.env.REACT_APP_HOST_NAME}/api/hrms/survey/getSurveyListVersion2`,
    "GET_FAV_RESPONSE_DETAILS": `${process.env.REACT_APP_HOST_NAME}/api/hrms/survey/postFavourableDashboard`,
    "GET_AVG_MANAGER_SCORECARD_DETAILS": `${process.env.REACT_APP_HOST_NAME}/api/hrms/survey/postAvgManagerScorecardSurveyDashboard`,
    "GET_MANAGER_SCORECARD_FAV_DETAILS": `${process.env.REACT_APP_HOST_NAME}/api/hrms/survey/postManagerScorecardFavDashboard`,
    "GET_PENDING_SURVEY_LIST": `${process.env.REACT_APP_HOST_NAME}/api/hrms/survey/getPendingSurveyList`,
    "SAVE_COLOR_SHADING_LOGIC": `${process.env.REACT_APP_HOST_NAME}/api/hrms/survey/saveColorShadingLogic`,
    "GET_COLOR_SHADING_LOGIC": `${process.env.REACT_APP_HOST_NAME}/api/hrms/survey/getColorShadingLogic`,
    "GET_COLOR_SHADING_LOGIC_LIST": `${process.env.REACT_APP_HOST_NAME}/api/hrms/survey/getColorShadingLogicList`,
}

export const hrDashboardAPIUrl = {
    "PENDING_TASK_LIST_API": `${process.env.REACT_APP_HOST_NAME}/api/hrms/hrDashboard/getPendingTaskList`,
    "REPORT_ANALYTICS_API": `${process.env.REACT_APP_HOST_NAME}/api/hrms/hrDashboard/getReportAnalytics`,
    "REPORT_YOU_ARE_AWESOME_DATA_API": `${process.env.REACT_APP_HOST_NAME}/api/hrms/hrDashboard/getYouAreAwesomeData`,
    "REPORT_FILTER_INIT_DATA_API": `${process.env.REACT_APP_HOST_NAME}/api/hrms/hrDashboard/getReportFilterInit`,
    "REPORT_FILTER_DATA_API": `${process.env.REACT_APP_HOST_NAME}/api/hrms/hrDashboard/getReportFilter`,
    "REPORT_OB_FEEDBACK_DATA_API": `${process.env.REACT_APP_HOST_NAME}/api/hrms/hrDashboard/getReportOnboardingFeedback`,
    "REPORT_OB_SUBDEPARTMENT_API": `${process.env.REACT_APP_HOST_NAME}/api/hrms/hrDashboard/getSubDepartmentListHRDashboard`,
    "REPORT_FEELING_AT_HOME_DATA_API": `${process.env.REACT_APP_HOST_NAME}/api/hrms/hrDashboard/getReportFeelingAtHome`,
    "REPORT_DIVERSITY_DATA_API": `${process.env.REACT_APP_HOST_NAME}/api/hrms/hrDashboard/getReportDiversity`,
    "REPORT_EXIT_ANALYSIS_DATA_API": `${process.env.REACT_APP_HOST_NAME}/api/hrms/hrDashboard/getReportExitAnalysis`,
    "REPORT_GROSS_OFFER_DATA_API": `${process.env.REACT_APP_HOST_NAME}/api/hrms/hrDashboard/getGrossOfferData`,
    "REPORT_NET_OFFER_DATA_API": `${process.env.REACT_APP_HOST_NAME}/api/hrms/hrDashboard/getNetOfferData`
}

export const masterAdminAccessUrl = {
    "GET_ACTIVE_EMPLOYEE_URL": `${process.env.REACT_APP_HOST_NAME}/api/hrms/emp/getActiveEmployees`,
    "POST_MASTER_ADMIN_ACCESS": `${process.env.REACT_APP_HOST_NAME}/api/hrms/emp/postMasterAdminAccess`,
    "GET_MASTER_ADMIN_EMPLOYEE_URL": `${process.env.REACT_APP_HOST_NAME}/api/hrms/emp/getMaterAdminEmployee` 
} 

export const onboardingAPIUrl = {
    "CEO_LETTER_LIST_API": `${process.env.REACT_APP_HOST_NAME}/api/hrms/onboarding/ceo-letter-list`,
    "PREVIEW_CEO_LETTER_API": `${process.env.REACT_APP_HOST_NAME}/api/hrms/onboarding/preview-ceo-email`,
    "SEND_CEO_LETTER_API": `${process.env.REACT_APP_HOST_NAME}/api/hrms/onboarding/send-ceo-email`,
    "GET_FILE_FROM_S3_API": `${process.env.REACT_APP_HOST_NAME}/api/hrms/onboarding/get-file-from-s3`
}
