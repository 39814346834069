// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.go-back-btn {
    margin-top: 1%;
    margin-left: 80%;
}`, "",{"version":3,"sources":["webpack://./src/pages/Survey/PreviewSurvey/index.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,gBAAgB;AACpB","sourcesContent":[".go-back-btn {\n    margin-top: 1%;\n    margin-left: 80%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
