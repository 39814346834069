import React from 'react'
import Grid from '@material-ui/core/Grid'
import Card from '../CardComponent/Card'
import CardBody from '../CardComponent/CardBody'
import Table from '../Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'

//Import core files
import { useStyles } from './style'

import { SemipolarLoading } from 'react-loadingg'
const Container = () => <SemipolarLoading />

function Loader ({ loading }) {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell scope='row' align={'center'} padding={'normal'}>
                      {loading ? (
                        <React.Fragment>
                          <Container />
                          <Grid
                            container
                            direction='row'
                            justifyContent='center'
                            alignItems='center'
                          >
                            <Grid item className={classes.loader}>
                              Loading...
                            </Grid>
                          </Grid>
                        </React.Fragment>
                      ) : (
                        <span> No Data to show</span>
                      )}
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </CardBody>
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}

export default Loader
