import React from 'react'
import TablePagination from '@material-ui/core/TablePagination'

const ReportPagination = ({
    page,
    pages,
    rows,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage
}) => {
return (
    <TablePagination
        component='div'
        page={page}
        rowsPerPageOptions={pages}
        rowsPerPage={rowsPerPage}
        count={rows.length}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
    />
  )
}

export default ReportPagination
