import { makeStyles } from '@material-ui/core/styles'
import blue from "@material-ui/core/colors/blue"

export const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(0),
      textAlign: 'center',
    },
    paperFileList: {
      padding: theme.spacing(0),
      textAlign: 'left'
    },
    paperGeneral: {
      paddingRight: '20px',
      paddingTop: '10px',
      paddingBottom: '10px',
      textAlign: 'left',
      fontSize: '12px',
      fontWeight: 'bold',
      minHeight: '400px',
      maxHeight: '400px',
      overflow: 'auto'
    },
    paperFirst: {
      padding: theme.spacing(1),
      textAlign: 'center',
    },
    formControl: {
      margin: theme.spacing(3),
      minWidth: 120,
    },
    formControlGender: {
      margin: theme.spacing(3),
      minWidth: 140,
    },
    formControlDocumentType: {
      margin: theme.spacing(3),
      paddingRight: '100px'
      // minWidth: 500,
    },
    formControlAccNo: {
      margin: theme.spacing(3),
      minWidth: 180,
    },
    formControlLable: {
      textAlign: 'left',
      margin: theme.spacing(3),
      minWidth: 120,
    },
    formControlMaritalStatus: {
      margin: theme.spacing(3),
      minWidth: 150,
    },
    formControlBankStatus: {
      margin: theme.spacing(3),
      minWidth: 150,
    },
    selectEmpty: {
      width: '120%',
      marginTop: theme.spacing(2),
    },
    formControlSpouseActive: {
      display: ''
    },
    formControlSpouseInactive: {
      display: 'none'
    },
    formControlBankActive: {
      display: ''
    },
    formControlBankInactive: {
      display: 'none'
    },
    input: {
      display: "none"
    },
    button: {
      color: blue[900],
      margin: 10
    },
    icon: {
      margin: 10,
      fontSize: 24,
    },
    tableFiles: {
      width: '20%'
    },
    formControlExtra: {
      margin: theme.spacing(3),
      minWidth: 450,
    },
}));
  