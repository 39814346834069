import React, { Component} from 'react'
import MasterAdmin from '../../pages/HrisRoleAccess/RoleAccess'

export default class SurveyJs extends Component {
  constructor(props) {
    super(props);
    this.state = {
         path : props.computedMatch.params.type
    };
}
  getPage(path){
    switch(path){
      case "masterAdmin":
        return MasterAdmin 
      default:
        return null
    }
  }
  render (){
    const Component = this.getPage(this.state.path)

    return (
      <div>
        { Component ? <Component {...this.props}/> : null }
      </div>
    )
  }
}