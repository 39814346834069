import React, { useState } from "react"
import DateFnsUtils from "@date-io/date-fns"
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers'

import {
    Grid,
    Input,
    InputLabel,
    FormControl,
    MenuItem,
    Select,
    Accordion,
    Button,
    CircularProgress,
    AccordionSummary,
    AccordionDetails,
    Checkbox,
    ListItemText,
    ListItemIcon,
    FormHelperText,
    Typography
} from '@material-ui/core'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useStyles } from './style'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
        }
    },
    getContentAnchorEl: null
}

const getComparisonList = ({ filterObj, dateFilterTypeData }) => {
    let data = []

    if (filterObj.dateFilterType === "custom") {
        data.push({ id: "custom", name: "Custom" })
    } else  {
        for (const obj of dateFilterTypeData) {
            if (filterObj.dateFilterType === "month" && obj.id === "month") {
                data.push(obj)
            } else if (["quarter", "finYear"].includes(filterObj.dateFilterType) && ["month", "quarter"].includes(obj.id)) {
                data.push(obj)
            }
        }
    }

    return data
}

const getTimeFrameList = ({ comparisonObj, dateFilterRange }) => {
    return dateFilterRange[comparisonObj.comparisonCriteria] || []
}

const FilterReport = ({ 
    filterObj = {},
    departmentData = [],
    subDepartmentData = [],
    gradeData = [],
    designationsData = [],
    branchData = [],
    reportingManagerData = [],
    tabpData = [],
    reportTypeData = [],
    employeeTypeData = [],
    dateFilterTypeData = [],
    dateFilterRange = {},
    errorObj={},
    havingTABPTeam,
    handleChange = () => {},
    handleFilters = () => {},
    handleReset = () => {},
    handleDateChange = () => {},
    isDateSelected = false,
    filterLoading,
    handleSearch = () => {},
    isApiError,
    apiErrorMessage = "",
    loading,
    exitReportTypeData = [],
    removeFutureMonths = () => {},
    feelingReportTypeData = [],
    comparisonObj={},
    handleComparison=()=>{},
    handleCompareData=()=>{},
    handleCompareDateChange=()=>{},
    compErrorObj={},
    pageView="",
    isCompare = false
}) => {
    const classes = useStyles()
    const [expandDefault, setExpandDefault] = useState(true)
    const handleMultiSelectValue = ({ selectedIds, keyId, keyName, list }) => {
        let hashList = {}
        for (const obj of list) {
            hashList[obj[keyId]] = obj[keyName]
        }
        let result = []
        for (const selectedId of selectedIds) {
            if (hashList[selectedId]) {
                result.push(hashList[selectedId])
            }
        }
        return result.join(",")
    }

    dateFilterRange = removeFutureMonths(filterObj)
    const comparisonList = getComparisonList({ filterObj, dateFilterTypeData })
    const timeFrameList = getTimeFrameList({ comparisonObj, dateFilterRange })
    
    let isCompareFlag = ["FeelingAtHome", "YouAreAwesome", "FifteenFeedback", "SixtyFeedback", "Diversity", "ExitAnalysis"].includes(pageView) && isCompare
    if (isCompare && (pageView === "FeelingAtHome" && filterObj.feelingReportType !== "consolidated")) {
        isCompareFlag = false
    }
    
    return (<React.Fragment>
        <Grid container>
            <Accordion expanded={expandDefault} onChange={()=>setExpandDefault(!expandDefault)} style={{ color:'#4356C2' }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>Filters</AccordionSummary>
            </Accordion>
        </Grid>
        <Grid container style={{ marginTop: '10px' }}>
            <Accordion expanded={expandDefault} style={{ width:  '100%'}}>
                <AccordionSummary style={{ height: "0px !important"}}></AccordionSummary>
                <AccordionDetails>
                    <div className={classes.root}>
                        {isApiError && <Grid item xs={12} style={{ color: 'red', textAlign: 'center', paddingBottom: "5px" }}>
                        <Typography variant="caption" align="center">
                        {apiErrorMessage}
                        </Typography></Grid>}
                        <Grid container spacing={0}>
                            <Grid container item xs={12} spacing={0}>
                                <Grid item xs={3}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel id='demo-simple-select-outlined-label'>
                                        Date Filter Type
                                        </InputLabel>
                                        <Select
                                        labelId='demo-mutiple-checkbox-label'
                                        id='demo-mutiple-checkbox'
                                        name='dateFilterType'
                                        value={filterObj.dateFilterType}
                                        onChange={e => handleChange(e)}
                                        input={<Input style={{ maxWidth: '200px' }}/>}
                                        MenuProps={MenuProps}
                                        error={errorObj.dateFilterType ? true : false}
                                        >
                                        {dateFilterTypeData.length > 0 &&
                                            dateFilterTypeData.map((item, index) => (
                                            <MenuItem value={item.id} key={item.id}>
                                                {item.name}
                                            </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText style={{ color: 'red' }}>
                                            {errorObj.dateFilterType}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                                {filterObj.dateFilterType && ["month", "quarter"].includes(filterObj.dateFilterType) ? (
                                <React.Fragment>
                                    <Grid item xs={3}>
                                        <FormControl className={classes.formControl}>
                                        <InputLabel id='demo-simple-select-outlined-label'>
                                        Year
                                        </InputLabel>
                                        <Select
                                        labelId='demo-mutiple-checkbox-label'
                                        id='demo-mutiple-checkbox'
                                        name="year"
                                        value={filterObj.year}
                                        onChange={e => handleChange(e)}
                                        input={<Input style={{ maxWidth: '200px' }}/>}
                                        MenuProps={MenuProps}
                                        error={errorObj.year ? true : false}
                                        >
                                        {dateFilterRange.year.length > 0 &&
                                            dateFilterRange.year.map((item, index) => (
                                            <MenuItem value={item.id} key={item.id}>
                                                {item.name}
                                            </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText style={{ color: 'red' }}>
                                        {errorObj.year}
                                        </FormHelperText>
                                        </FormControl>
                                    </Grid>

                                {filterObj.dateFilterType === "month" ? (
                                    <Grid item xs={3}>
                                        <FormControl className={classes.formControl}>
                                        <InputLabel id='demo-simple-select-outlined-label'>
                                        Month
                                        </InputLabel>
                                        <Select
                                        labelId='demo-mutiple-checkbox-label'
                                        id='demo-mutiple-checkbox'
                                        name="month"
                                        value={filterObj.month}
                                        onChange={e => handleChange(e)}
                                        input={<Input style={{ maxWidth: '200px' }}/>}
                                        renderValue={(selected) => { return handleMultiSelectValue({ selectedIds: selected, keyId:"id", keyName:"name", list:dateFilterRange.month })}}
                                        MenuProps={MenuProps}
                                        multiple
                                        disabled={filterObj.year ? false : true}
                                        error={errorObj.month ? true : false}
                                        >
                                        { dateFilterRange.month.length > 0 &&
                                            <MenuItem value="all">
                                                <ListItemIcon>
                                                    <Checkbox
                                                        checked={filterObj.selectAllMonth}
                                                        indeterminate={
                                                            filterObj.month.length > 0 && filterObj.month.length < dateFilterRange.month.length
                                                        }
                                                    />
                                                </ListItemIcon>
                                                <ListItemText primary={"Select All"} />
                                            </MenuItem>
                                        }
                                        {dateFilterRange.month.length > 0 &&
                                            dateFilterRange.month.map((item, index) => (
                                            <MenuItem value={item.id} key={item.id}>
                                                <Checkbox
                                                    checked={filterObj.month.includes(item.id)}
                                                />
                                                <ListItemText primary={item.name} />
                                            </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText style={{ color: 'red' }}>
                                        {errorObj.month}
                                        </FormHelperText>
                                        </FormControl>
                                    </Grid>) : (null)
                                }

                                {filterObj.dateFilterType === "quarter" ? (
                                    <Grid item xs={3}>
                                        <FormControl className={classes.formControl}>
                                        <InputLabel id='demo-simple-select-outlined-label'>
                                        Quarter
                                        </InputLabel>
                                        <Select
                                        labelId='demo-mutiple-checkbox-label'
                                        id='demo-mutiple-checkbox'
                                        name="quarter"
                                        value={filterObj.quarter}
                                        onChange={e => handleChange(e)}
                                        input={<Input style={{ maxWidth: '200px' }}/>}
                                        renderValue={(selected) => { return handleMultiSelectValue({ selectedIds: selected, keyId:"id", keyName:"name", list:dateFilterRange.quarter })}}
                                        MenuProps={MenuProps}
                                        disabled={filterObj.year ? false : true}
                                        multiple
                                        error={errorObj.quarter ? true : false}
                                        >

                                        { dateFilterRange.quarter.length > 0 &&
                                            <MenuItem value="all">
                                                <ListItemIcon>
                                                    <Checkbox
                                                        checked={filterObj[`selectAllQuarter`]}
                                                        indeterminate={
                                                            filterObj.quarter.length > 0 && filterObj.quarter.length < dateFilterRange.quarter.length
                                                        }
                                                    />
                                                </ListItemIcon>
                                                <ListItemText primary={"Select All"} />
                                            </MenuItem>
                                        }
                                        {dateFilterRange.quarter.length > 0 &&
                                            dateFilterRange.quarter.map((item, index) => (
                                            <MenuItem value={item.id} key={item.id}>
                                                <Checkbox
                                                    checked={filterObj.quarter.includes(item.id)}
                                                />
                                                <ListItemText primary={item.name} />
                                            </MenuItem>
                                        ))}
                                        </Select>
                                        <FormHelperText style={{ color: 'red' }}>
                                        {errorObj.quarter}
                                        </FormHelperText>
                                        </FormControl>
                                    </Grid>) : (null)
                                }
                                </React.Fragment>
                                    ) : (null)
                                }

                                {filterObj.dateFilterType && filterObj.dateFilterType === "finYear" ? (
                                        <Grid item xs={3}>
                                            <FormControl className={classes.formControl}>
                                            <InputLabel id='demo-simple-select-outlined-label'>
                                            Financial Year
                                            </InputLabel>
                                            <Select
                                            labelId='demo-mutiple-checkbox-label'
                                            id='demo-mutiple-checkbox'
                                            name="finYear"
                                            value={filterObj.finYear}
                                            onChange={e => handleChange(e)}
                                            input={<Input style={{ maxWidth: '200px' }}/>}
                                            MenuProps={MenuProps}
                                            error={errorObj.finYear ? true : false}
                                            renderValue={(selected) => { return handleMultiSelectValue({ selectedIds: selected, keyId:"id", keyName:"name", list:dateFilterRange.finYear })}}
                                            multiple
                                            >
                                            { dateFilterRange.finYear.length > 0 &&
                                                <MenuItem value="all">
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            checked={filterObj[`selectAllFinYear`]}
                                                            indeterminate={
                                                                filterObj.finYear.length > 0 && filterObj.finYear.length < dateFilterRange.finYear.length
                                                            }
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText primary={"Select All"} />
                                                </MenuItem>
                                            }
                                            {dateFilterRange.finYear.length > 0 &&
                                                dateFilterRange.finYear.map((item, index) => (
                                                <MenuItem value={item.id} key={item.id}>
                                                    <Checkbox
                                                        checked={filterObj.finYear.includes(item.id)}
                                                    />
                                                    <ListItemText primary={item.name} />
                                                </MenuItem>
                                            ))}
                                            </Select>
                                            <FormHelperText style={{ color: 'red' }}>
                                            {errorObj.finYear}
                                            </FormHelperText>
                                            </FormControl>
                                        </Grid>
                                    ) : (null)
                                }

                                {filterObj.dateFilterType && filterObj.dateFilterType === "custom" ? (
                                    <React.Fragment>
                                        <Grid item xs={3}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                margin='normal'
                                                id='date-picker-dialog'
                                                name='customFromDate'
                                                label='From Date'
                                                format='dd-MMM-yyyy'
                                                value={filterObj.customFromDate}
                                                onChange={date => handleDateChange(date, 'customFromDate')}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date'
                                                }}
                                                InputProps={{ readOnly: true }}
                                                style={{ marginTop: "10px", width: "200px" }}
                                                minDate={filterObj.minCustomDate}
                                                maxDate={filterObj.maxCustomDate}
                                                error={errorObj.customFromDate ? true : false}
                                                helperText={errorObj.customFromDate}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                        <Grid item xs={3}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        margin='normal'
                                                        id='date-picker-dialog'
                                                        name='customToDate'
                                                        label='To Date'
                                                        format='dd-MMM-yyyy'
                                                        value={filterObj.customToDate}
                                                        onChange={date => handleDateChange(date, 'customToDate')}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change date'
                                                        }}
                                                        InputProps={{ readOnly: true }}
                                                        style={{ marginTop: "10px", width: "200px" }}
                                                        minDate={filterObj.minCustomDate}
                                                        maxDate={filterObj.maxCustomDate}
                                                        error={errorObj.customToDate ? true : false}
                                                        helperText={errorObj.customToDate}
                                                    />
                                                </MuiPickersUtilsProvider>
                                        </Grid>
                                    </React.Fragment>
                                    ) : (null)
                                }
                                <Grid item xs={3} style={{ textAlign: "center", paddingTop: "3%" }}>
                                    {filterLoading || loading ? <CircularProgress
                                size={24}
                                className={classes.purpleButtonProgress}
                                /> : <Button
                                        variant='contained'
                                        color='primary'
                                        onClick={() => handleSearch()}
                                        size='small'
                                        className={classes.btnLoading}
                                    >
                                    Done
                                    </Button>}
                                </Grid>
                        </Grid>
                        </Grid>
                        
                        {!filterLoading && isDateSelected ? (<React.Fragment>
                            <Grid container spacing={0}>
                                <Grid container item xs={12} spacing={1}>
                                {filterObj.hasOwnProperty('department') && (
                                            <Grid item xs={3}>
                                            <FormControl className={classes.formControl}>
                                                <InputLabel id='demo-simple-select-outlined-label'>
                                                Department
                                                </InputLabel>
                                                <Select
                                                labelId='demo-mutiple-checkbox-label'
                                                id='demo-mutiple-checkbox'
                                                name='department'
                                                value={filterObj.department}
                                                onChange={e => handleChange(e)}
                                                input={<Input style={{ maxWidth: '200px' }}/>}
                                                renderValue={(selected) => { return handleMultiSelectValue({ selectedIds: selected, keyId:"department_id", keyName:"department", list:departmentData })}}
                                                MenuProps={MenuProps}
                                                disabled={!isDateSelected}
                                                multiple
                                                >
                                                { departmentData.length > 0 &&
                                                    <MenuItem value="all">
                                                        <ListItemIcon>
                                                            <Checkbox
                                                                checked={filterObj.selectAllDepartment}
                                                                indeterminate={
                                                                    filterObj.department.length > 0 && filterObj.department.length < departmentData.length
                                                                }
                                                            />
                                                        </ListItemIcon>
                                                        <ListItemText primary={"Select All"} />
                                                    </MenuItem>
                                                }
                                                {departmentData.length > 0 &&
                                                    departmentData.map((item, index) => (
                                                    <MenuItem value={item.department_id} key={item.department_id}>
                                                        <ListItemIcon>
                                                            <Checkbox
                                                                checked={filterObj.department.includes(item.department_id)}
                                                            />
                                                        </ListItemIcon>
                                                        <ListItemText primary={item.department} />
                                                    </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            </Grid>
                                )}
            
                                {filterObj.hasOwnProperty('subDepartment') && (
                                    <Grid item xs={3}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel id='demo-simple-select-outlined-label'>
                                        Sub Department
                                        </InputLabel>
                                        <Select
                                        labelId='demo-mutiple-checkbox-label'
                                        id='demo-mutiple-checkbox'
                                        name='subDepartment'
                                        multiple
                                        value={filterObj.subDepartment}
                                        onChange={e => handleChange(e)}
                                        input={<Input style={{ maxWidth: '200px' }}/>}
                                        renderValue={(selected) => { return handleMultiSelectValue({ selectedIds: selected, keyId:"function_id", keyName:"function_name", list:subDepartmentData })}}
                                        MenuProps={MenuProps}
                                        disabled={!isDateSelected || !subDepartmentData.length}
                                        >
                                        { subDepartmentData.length > 0 &&
                                            <MenuItem value="all">
                                                <ListItemIcon>
                                                    <Checkbox
                                                        checked={filterObj.selectAllSubDepartment}
                                                        indeterminate={
                                                            filterObj.subDepartment.length > 0 && filterObj.subDepartment.length < subDepartmentData.length
                                                        }
                                                    />
                                                </ListItemIcon>
                                                <ListItemText primary={"Select All"} />
                                            </MenuItem>
                                        }
                                        {subDepartmentData.length > 0 &&
                                            subDepartmentData.map((item, index) => (
                                            <MenuItem value={item.function_id} key={item.function_id}>
                                                <Checkbox
                                                    checked={filterObj.subDepartment.includes(item.function_id)}
                                                />
                                                <ListItemText primary={item.function_name} />
                                            </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    </Grid>
                                )}
                                        
                                {filterObj.hasOwnProperty('grade') && (
                                    <Grid item xs={3}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel id='demo-mutiple-checkbox-label'>
                                        Grade
                                        </InputLabel>
                                        <Select
                                        labelId='demo-mutiple-checkbox-label'
                                        id='demo-mutiple-checkbox'
                                        multiple
                                        name='grade'
                                        value={filterObj.grade}
                                        onChange={e => handleChange(e)}
                                        input={<Input style={{ maxWidth: '200px' }}/>}
                                        renderValue={selected => selected.join(",")}
                                        MenuProps={MenuProps}
                                        disabled={!isDateSelected}
                                        >
                                        { gradeData.length > 0 &&
                                            <MenuItem value="all">
                                                <ListItemIcon>
                                                    <Checkbox
                                                        checked={filterObj.selectAllGrade}
                                                        indeterminate={
                                                            filterObj.grade.length > 0 && filterObj.grade.length < gradeData.length
                                                        }
                                                    />
                                                </ListItemIcon>
                                                <ListItemText primary={"Select All"} />
                                            </MenuItem>
                                        }
                                        {gradeData.length > 0 &&
                                            gradeData.map((item, index) => (
                                            <MenuItem
                                                value={item.grade}
                                                key={item.grade}
                                            >
                                                <Checkbox checked={filterObj.grade.includes(item.grade)} />
                                                <ListItemText primary={`${item.grade}`} />
                                            </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    </Grid>
                                )}
            
                                {filterObj.hasOwnProperty('designation') && (
                                    <Grid item xs={3}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel id='demo-mutiple-checkbox-label'>
                                        Designation
                                        </InputLabel>
                                        <Select
                                        labelId='demo-mutiple-checkbox-label'
                                        id='demo-mutiple-checkbox'
                                        multiple
                                        name='designation'
                                        value={filterObj.designation}
                                        onChange={e => handleChange(e)}
                                        input={<Input style={{ maxWidth: '200px' }}/>}
                                        renderValue={selected => selected.join(",")}
                                        MenuProps={MenuProps}
                                        disabled={!isDateSelected || !designationsData.length}
                                        >
                                        { designationsData.length > 0 &&
                                            <MenuItem value="all">
                                                <ListItemIcon>
                                                    <Checkbox
                                                        checked={filterObj.selectAllDesignation}
                                                        indeterminate={
                                                            filterObj.designation.length > 0 && filterObj.designation.length < designationsData.length
                                                        }
                                                    />
                                                </ListItemIcon>
                                                <ListItemText primary={"Select All"} />
                                            </MenuItem>
                                        }
                                        {designationsData.length > 0 &&
                                            designationsData.map((item, index) => (
                                            <MenuItem
                                                value={item.designation}
                                                key={item.designation}
                                            >
                                                <Checkbox
                                                checked={
                                                    filterObj.designation.includes(
                                                    item.designation
                                                    )
                                                }
                                                />
                                                <ListItemText
                                                primary={`${item.designation}`}
                                                />
                                            </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    </Grid>
                                )}

                                {filterObj.hasOwnProperty('location') && (
                                    <Grid item xs={3}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel id='demo-mutiple-checkbox-label'>
                                        Location
                                        </InputLabel>
                                        <Select
                                        labelId='demo-mutiple-checkbox-label'
                                        id='demo-mutiple-checkbox'
                                        multiple
                                        name='location'
                                        value={filterObj.location}
                                        onChange={e => handleChange(e)}
                                        input={<Input style={{ maxWidth: '200px' }}/>}
                                        renderValue={(selected) => { return handleMultiSelectValue({ selectedIds: selected, keyId:"id", keyName:"locality", list:branchData })}}
                                        MenuProps={MenuProps}
                                        disabled={!isDateSelected}
                                        >
                                        { branchData.length > 0 &&
                                            <MenuItem value="all">
                                                <ListItemIcon>
                                                    <Checkbox
                                                        checked={filterObj.selectAllLocation}
                                                        indeterminate={
                                                            filterObj.location.length > 0 && filterObj.location.length < branchData.length
                                                        }
                                                    />
                                                </ListItemIcon>
                                                <ListItemText primary={"Select All"} />
                                            </MenuItem>
                                        }
                                        {branchData.length > 0 &&
                                            branchData.map((item, index) => (
                                            <MenuItem
                                                value={item.id}
                                                key={item.id}
                                            >
                                                <Checkbox
                                                checked={
                                                    filterObj.location.includes(
                                                    item.id
                                                    )
                                                }
                                                />
                                                <ListItemText
                                                primary={item.locality}
                                                />
                                            </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    </Grid>
                                )}

                                {filterObj.hasOwnProperty('groupDojFrom') && (
                                    <Grid item xs={3}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                        margin='normal'
                                        id='date-picker-dialog'
                                        name='groupDojFrom'
                                        label='DOJ (From)'
                                        format='dd-MMM-yyyy'
                                        value={filterObj.groupDojFrom}
                                        onChange={date => handleDateChange(date, 'groupDojFrom')}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date'
                                        }}
                                        InputProps={{ readOnly: true }}
                                        style={{ marginTop: "10px", width: "200px" }}
                                        helperText={errorObj.groupDojFrom}
                                        error={errorObj.groupDojFrom ? true : false}
                                        minDate={filterObj.minDojDate}
                                        maxDate={filterObj.maxDojDate}
                                        disabled={!isDateSelected}
                                        />
                                    </MuiPickersUtilsProvider>
                                    </Grid>
                                )}
            
                                {filterObj.hasOwnProperty('groupDojTo') && (
                                    <Grid item xs={3}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                        margin='normal'
                                        id='date-picker-dialog'
                                        name='groupDojTo'
                                        label='DOJ (To)'
                                        format='dd-MMM-yyyy'
                                        value={filterObj.groupDojTo}
                                        onChange={date => handleDateChange(date, 'groupDojTo')}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date'
                                        }}
                                        InputProps={{ readOnly: true }}
                                        style={{ marginTop: "10px", width: "200px" }}
                                        helperText={errorObj.groupDojTo}
                                        error={errorObj.groupDojTo ? true : false}
                                        minDate={filterObj.minDojDate}
                                        maxDate={filterObj.maxDojDate}
                                        disabled={!isDateSelected}
                                        />
                                    </MuiPickersUtilsProvider>
                                    </Grid>
                                )}
                                
                                {reportingManagerData.length && filterObj.hasOwnProperty('reportingManager') ? (
                                    <Grid item xs={3}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel id='demo-simple-select-outlined-label'>
                                        Reporting Manager
                                        </InputLabel>
                                        <Select
                                        labelId='demo-mutiple-checkbox-label'
                                        id='demo-mutiple-checkbox'
                                        name='reportingManager'
                                        value={filterObj.reportingManager}
                                        onChange={e => handleChange(e)}
                                        input={<Input style={{ maxWidth: '200px' }}/>}
                                        renderValue={(selected) => { return handleMultiSelectValue({ selectedIds: selected, keyId:"emp_code", keyName:"emp_name", list:reportingManagerData })}}
                                        MenuProps={MenuProps}
                                        disabled={!isDateSelected}
                                        multiple
                                        >
                                        { reportingManagerData.length > 0 &&
                                            <MenuItem value="all">
                                                <ListItemIcon>
                                                    <Checkbox
                                                        checked={filterObj.selectAllRM}
                                                        indeterminate={
                                                            filterObj.reportingManager.length > 0 && filterObj.reportingManager.length < reportingManagerData.length
                                                        }
                                                    />
                                                </ListItemIcon>
                                                <ListItemText primary={"Select All"} />
                                            </MenuItem>
                                        }
                                        {reportingManagerData.length > 0 &&
                                            reportingManagerData.map((item, index) => (
                                            <MenuItem value={item.emp_code} key={item.emp_code}>
                                                <Checkbox
                                                    checked={filterObj.reportingManager.includes(item.emp_code)}
                                                />
                                                <ListItemText primary={`${item.emp_name} - ${item.emp_code}`} />
                                            </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    </Grid>
                                ) : (null)}

                                {tabpData.length && filterObj.hasOwnProperty('tabp') ? (
                                    <Grid item xs={3}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel id='demo-simple-select-outlined-label'>
                                        TABP
                                        </InputLabel>
                                        <Select
                                        labelId='demo-mutiple-checkbox-label'
                                        id='demo-mutiple-checkbox'
                                        name='tabp'
                                        value={filterObj.tabp}
                                        onChange={e => handleChange(e)}
                                        input={<Input style={{ maxWidth: '200px' }}/>}
                                        renderValue={(selected) => { return handleMultiSelectValue({ selectedIds: selected, keyId:"emp_code", keyName:"emp_name", list:tabpData })}}
                                        MenuProps={MenuProps}
                                        disabled={!isDateSelected}
                                        multiple
                                        >
                                        { tabpData.length > 0 &&
                                            <MenuItem value="all">
                                                <ListItemIcon>
                                                    <Checkbox
                                                        checked={filterObj.selectAllTabp}
                                                        indeterminate={
                                                            filterObj.tabp.length > 0 && filterObj.tabp.length < tabpData.length
                                                        }
                                                    />
                                                </ListItemIcon>
                                                <ListItemText primary={"Select All"} />
                                            </MenuItem>
                                        }
                                        {tabpData.length > 0 &&
                                            tabpData.map((item, index) => (
                                            <MenuItem value={item.emp_code} key={item.emp_code}>
                                                <Checkbox
                                                    checked={filterObj.tabp.includes(item.emp_code)}
                                                />
                                                <ListItemText primary={`${item.emp_name} - ${item.emp_code}`} />
                                            </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    </Grid>
                                ): (null)}

                                {(havingTABPTeam || filterObj.tabp.length) && employeeTypeData.length && filterObj.hasOwnProperty('employeeType') ? (
                                    <Grid item xs={3}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel id='demo-simple-select-outlined-label'>
                                        Employee Type
                                        </InputLabel>
                                        <Select
                                        labelId='demo-mutiple-checkbox-label'
                                        id='demo-mutiple-checkbox'
                                        name='employeeType'
                                        value={filterObj.employeeType}
                                        onChange={e => handleChange(e)}
                                        input={<Input style={{ maxWidth: '200px' }}/>}
                                        renderValue={(selected) => { return handleMultiSelectValue({ selectedIds: selected, keyId:"id", keyName:"name", list:employeeTypeData })}}
                                        MenuProps={MenuProps}
                                        disabled={!isDateSelected}
                                        multiple
                                        >
                                        { employeeTypeData.length > 0 &&
                                            <MenuItem value="all">
                                                <ListItemIcon>
                                                    <Checkbox
                                                        checked={filterObj.selectAllEmployeeType}
                                                        indeterminate={
                                                            filterObj.employeeType.length > 0 && filterObj.employeeType.length < employeeTypeData.length
                                                        }
                                                    />
                                                </ListItemIcon>
                                                <ListItemText primary={"Select All"} />
                                            </MenuItem>
                                        }
                                        {employeeTypeData.length > 0 &&
                                            employeeTypeData.map((item, index) => (
                                            <MenuItem value={item.id} key={item.id}>
                                                <Checkbox
                                                    checked={filterObj.employeeType.includes(item.id)}
                                                />
                                                <ListItemText primary={item.name} />
                                            </MenuItem>
                                        ))}
                                        </Select>
                                        <FormHelperText style={{ color: '#3F50B5', fontSize: "10px" }}>
                                            {filterObj.employeeType.length ? `The filter will apply to the ${filterObj.tabp.length ? 'selected TABP(s)' : 'logged-in user!'}` : ""}
                                        </FormHelperText>
                                    </FormControl>
                                    </Grid>
                                ): (null)}

                                {reportTypeData.length && filterObj.hasOwnProperty('reportType') ? (
                                    <Grid item xs={3}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel id='demo-simple-select-outlined-label'>
                                        Report Column
                                        </InputLabel>
                                        <Select
                                        labelId='demo-mutiple-checkbox-label'
                                        id='demo-mutiple-checkbox'
                                        name='reportType'
                                        value={filterObj.reportType}
                                        onChange={e => handleChange(e)}
                                        input={<Input style={{ maxWidth: '200px' }}/>}
                                        MenuProps={MenuProps}
                                        disabled={!isDateSelected}
                                        renderValue={(selected) => { return handleMultiSelectValue({ selectedIds: selected, keyId:"id", keyName:"name", list:reportTypeData })}}
                                        multiple
                                        >
                                        { reportTypeData.length > 0 &&
                                            <MenuItem value="all">
                                                <ListItemIcon>
                                                    <Checkbox
                                                        checked={filterObj.selectAllReportType}
                                                        indeterminate={
                                                            filterObj.reportType.length > 0 && filterObj.reportType.length < reportTypeData.length
                                                        }
                                                    />
                                                </ListItemIcon>
                                                <ListItemText primary={"Select All"} />
                                            </MenuItem>
                                        }

                                        {reportTypeData.length > 0 &&
                                            reportTypeData.map((item, index) => (
                                            <MenuItem value={item.id} key={item.id}>
                                                <Checkbox
                                                    checked={filterObj.reportType.includes(item.id)}
                                                />
                                                <ListItemText primary={item.name} />
                                            </MenuItem>
                                        ))}
                                        </Select>
                                        <FormHelperText style={{ color: '#3F50B5', fontSize: "10px" }}>
                                            {`At least one report type is required!`}
                                        </FormHelperText>
                                    </FormControl>
                                    </Grid>
                                ): (null)}

                                {exitReportTypeData.length && filterObj.hasOwnProperty('exitReportType') ? (
                                    <Grid item xs={3}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel id='demo-simple-select-outlined-label'>
                                        Exit Report Type
                                        </InputLabel>
                                        <Select
                                        labelId='demo-mutiple-checkbox-label'
                                        id='demo-mutiple-checkbox'
                                        name='exitReportType'
                                        value={filterObj.exitReportType}
                                        onChange={e => handleChange(e)}
                                        input={<Input style={{ maxWidth: '200px' }}/>}
                                        MenuProps={MenuProps}
                                        disabled={!isDateSelected}
                                        >
                                        {exitReportTypeData.length > 0 &&
                                            exitReportTypeData.map((item, index) => (
                                            <MenuItem value={item.id} key={item.id}>
                                                {item.name}
                                            </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    </Grid>
                                ): (null)}

                                {feelingReportTypeData.length && filterObj.hasOwnProperty("feelingReportType") ? (
                                    <Grid item xs={3}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel id='demo-simple-select-outlined-label'>
                                        Report Type
                                        </InputLabel>
                                        <Select
                                        labelId='demo-mutiple-checkbox-label'
                                        id='demo-mutiple-checkbox'
                                        name='feelingReportType'
                                        value={filterObj.feelingReportType}
                                        onChange={e => handleChange(e)}
                                        input={<Input style={{ maxWidth: '200px' }}/>}
                                        MenuProps={MenuProps}
                                        disabled={!isDateSelected}
                                        >
                                        {feelingReportTypeData.length > 0 &&
                                            feelingReportTypeData.map((item, index) => (
                                            <MenuItem value={item.id} key={item.id}>
                                                {item.name}
                                            </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    </Grid>
                                ) : null}
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid container item xs={12} spacing={3}>
                                    <Grid item xs={9}></Grid>
                                    <Grid item xs={3}>
                                        {
                                            !loading ? (<React.Fragment><Button
                                                variant='contained'
                                                className={classes.btnLoading}
                                                onClick={() => handleReset()}
                                                size='small'
                                                >
                                                Reset
                                            </Button>
                                            <Button
                                                variant='contained'
                                                color='primary'
                                                onClick={() => handleFilters()}
                                                size='small'
                                                className={classes.btnLoading}
                                            >
                                            { filterObj.feelingReportType === "complete" ? "Download" : "Filter Result" }
                                            </Button></React.Fragment>) : (<CircularProgress
                                                size={24}
                                                className={classes.purpleButtonProgress}
                                            />)
                                        }
                                    </Grid>
                                </Grid>
                                {isCompareFlag && filterObj.feelingReportType === "consolidated" ? <Grid container item xs={12} spacing={3}>
                                    {comparisonObj.hasOwnProperty("comparisonCriteria") ? (
                                        <Grid item xs={3}>
                                            <FormControl className={classes.formControl}>
                                                <InputLabel id='demo-simple-select-outlined-label'>
                                                Comparison Criteria
                                                </InputLabel>
                                                <Select
                                                    labelId='demo-mutiple-checkbox-label'
                                                    id='demo-mutiple-checkbox'
                                                    name="comparisonCriteria"
                                                    value={comparisonObj.comparisonCriteria}
                                                    onChange={e => handleCompareData(e)}
                                                    input={<Input style={{ maxWidth: '200px' }}/>}
                                                    MenuProps={MenuProps}
                                                    error={compErrorObj.comparisonCriteria ? true : false}
                                                    >
                                                    {comparisonList.length &&
                                                        comparisonList.map((item, index) => (
                                                        <MenuItem value={item.id} key={item.id}>
                                                            {item.name}
                                                        </MenuItem>
                                                        ))}
                                                    </Select>
                                                    <FormHelperText style={{ color: 'red' }}>
                                                    {compErrorObj.comparisonCriteria}
                                                    </FormHelperText>
                                            </FormControl>
                                        </Grid>
                                    ) : null }

                                    { ["finYear"].includes(filterObj.dateFilterType) && comparisonObj.hasOwnProperty("timeFrame") ? (
                                        <Grid item xs={3}>
                                            <FormControl className={classes.formControl}>
                                                <InputLabel id='demo-simple-select-outlined-label'>
                                                Time Frame for Comparison
                                                </InputLabel>
                                                <Select
                                                    labelId='demo-mutiple-checkbox-label'
                                                    id='demo-mutiple-checkbox'
                                                    name="timeFrame"
                                                    value={comparisonObj.timeFrame}
                                                    onChange={e => handleCompareData(e)}
                                                    input={<Input style={{ width: '225px' }}/>}
                                                    MenuProps={MenuProps}
                                                    error={compErrorObj.timeFrame ? true : false}
                                                    multiple
                                                    renderValue={(selected) => { return handleMultiSelectValue({ selectedIds: selected, keyId:"id", keyName:"name", list:timeFrameList })}}
                                                    disabled={!isDateSelected || !comparisonObj.comparisonCriteria}
                                                    >
                                                    { timeFrameList.length &&
                                                        <MenuItem value="all">
                                                            <ListItemIcon>
                                                                <Checkbox
                                                                    checked={comparisonObj.selectAllTimeFrame}
                                                                    indeterminate={
                                                                        comparisonObj.timeFrame.length > 0 && comparisonObj.timeFrame.length < timeFrameList.length
                                                                    }
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText primary={"Select All"} />
                                                        </MenuItem>
                                                    }
                                                    {timeFrameList.length > 0 &&
                                                        timeFrameList.map((item, index) => (
                                                        <MenuItem
                                                            value={item.id}
                                                            key={item.id}
                                                        >
                                                            <Checkbox
                                                            checked={
                                                                comparisonObj.timeFrame.includes(
                                                                item.id
                                                                )
                                                            }
                                                            />
                                                            <ListItemText
                                                                primary={item.name}
                                                            />
                                                        </MenuItem>
                                                        ))}
                                                    </Select>
                                                    <FormHelperText style={{ color: 'red' }}>
                                                    {compErrorObj.timeFrame}
                                                    </FormHelperText>
                                            </FormControl>
                                        </Grid>
                                    ) : null }

                                    {filterObj.dateFilterType === "custom" ? (
                                        <React.Fragment>
                                            <Grid item xs={3}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                    margin='normal'
                                                    id='date-picker-dialog'
                                                    name='compareFromDate'
                                                    label='Compare From Date'
                                                    format='dd-MMM-yyyy'
                                                    value={comparisonObj.compareFromDate}
                                                    onChange={date => handleCompareDateChange(date, 'compareFromDate')}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date'
                                                    }}
                                                    InputProps={{ readOnly: true }}
                                                    style={{ marginTop: "10px", width: "200px" }}
                                                    minDate={filterObj.minCustomDate}
                                                    maxDate={filterObj.maxCustomDate}
                                                    error={compErrorObj.compareFromDate ? true : false}
                                                    helperText={compErrorObj.compareFromDate}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>
                                            <Grid item xs={3}>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <KeyboardDatePicker
                                                            margin='normal'
                                                            id='date-picker-dialog'
                                                            name='compareToDate'
                                                            label='Compare To Date'
                                                            format='dd-MMM-yyyy'
                                                            value={comparisonObj.compareToDate}
                                                            onChange={date => handleCompareDateChange(date, 'compareToDate')}
                                                            KeyboardButtonProps={{
                                                                'aria-label': 'change date'
                                                            }}
                                                            InputProps={{ readOnly: true }}
                                                            style={{ marginTop: "10px", width: "200px" }}
                                                            minDate={filterObj.minCustomDate}
                                                            maxDate={filterObj.maxCustomDate}
                                                            error={compErrorObj.compareToDate ? true : false}
                                                            helperText={compErrorObj.compareToDate}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                            </Grid>
                                        </React.Fragment>
                                        ) : (null)
                                    }
                                    <Grid item xs={3} style={{ padding: "35 0 0 25" }}>
                                        {
                                            !loading ? (
                                            <Button
                                                variant='contained'
                                                color='primary'
                                                onClick={() => handleComparison()}
                                                size='small'
                                                className={classes.btnLoading}
                                            >
                                            Compare
                                            </Button>) : (<CircularProgress
                                                size={24}
                                                className={classes.purpleButtonProgress}
                                            />)
                                        }
                                    </Grid>
                                    <Grid item xs={3}></Grid>
                                </Grid> : null 
                                }
                            </Grid>
                        </React.Fragment>):(null)}
                    </div>
                </AccordionDetails>
            </Accordion>
        </Grid>
        </React.Fragment>
    )
}

export default FilterReport