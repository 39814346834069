import { makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles(theme => ({
    formRoot: {
      padding: theme.spacing(2)
    },

    formControl: {
      margin: theme.spacing(1),
      width: '100%'
    },
   
    dateInput: {
      '& .MuiGrid-item': {
        paddingLeft: 25,
      }
    },
    button: {
      '& .MuiGrid-item': {
        margin: 20,
      } 
    },
    resetButton: {
      marginLeft: 20,
      background: 'white !important',
      textTransform: 'none'
    },
}))
