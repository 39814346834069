export const SURVEY_CONST = {
    "DEFAULT_SURVEY_SETTING_OBJECT" : {
        showSurveyTitle: false,
        showObjectTitles: true,
        showJSONEditorTab: false,
        showLogicTab: false,
        isAutoSave: true,
        allowModifyPages: true,
        haveCommercialLicense: false,
        maximumChoicesCount: 10,
        maximumColumnsCount: 10
    },
    "DEFAULT_SURVEY_PAGE_SETTING_OBJECT" : {
        title:  "",
        description: "",
        showQuestionNumbers: "on"
    },
    questionTypesForInteractiveSurvey:["rating", "checkbox", "radiogroup", "boolean","imagepicker"],
    "SURVEY_REPORT_COLUMNS": {
        'all'              : 'All',
        'tenure'       : 'Tenure Bucket', 
        'designation'          : 'Designation', 
        'grade'                : 'Grade', 
        'department'           : 'Department', 
        'function'     : 'Sub-Dept', 
        'reporting_manager_id' : 'Reporting Manager ID',
        'reporting_manager': 'Reporting Manager',
        'lteam_member'     : 'L Team Member',
        'location'         : 'Location',
        'branch'           : 'Branch',
        'age_bucket'       : 'Age Bucket',
        'gender'           : 'Gender',
        'rhr_name'         : 'RHR Name',
        'custom_criteria'  : 'Custom Criteria'
    }
}
