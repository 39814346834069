import React from 'react'
import Alert from '@material-ui/lab/Alert'
import {
    Snackbar
} from '@material-ui/core'

const SnackBarComponent = ({
    open,
    autoHideDuration=6000,
    snackbarPosition = { horizontal: 'center', vertical: 'bottom'},
    severity='',
    message='',
    style={style},
    handleSnackBarClose=()=>{},
}) => {
    return (
        <Snackbar 
            open={open} 
            autoHideDuration={autoHideDuration} 
            onClose={handleSnackBarClose} 
            anchorOrigin={snackbarPosition}
            style={style}
            >
            <Alert onClose={handleSnackBarClose} severity={severity}>
                {message}
            </Alert>
        </Snackbar>
    )
}

export default SnackBarComponent