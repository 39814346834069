import React, { useState,useEffect } from 'react';
import { useStyles } from './style';
import { getAllEmployeesTaggingData, getPodsListData, getSkillsListData, updateEmployeeTagging, getLoggedInEmployeeDetailFetchData } from '../fetchData'
import HeaderLayout from '../HeaderLayout'
import { TableCell, TableContainer, TableHead, TableRow, Table, TableBody, Select, MenuItem, Button, Grid, FormControl, TablePagination, Snackbar } from '@material-ui/core'
import PeopleOutlineTwoTone from '@material-ui/icons/PeopleOutlineTwoTone'
import {Paper} from '@material-ui/core'
import SaveIcon from '@material-ui/icons/Save'
import CloudDownload from '@material-ui/icons/CloudDownload'
import logo from '../../../utils/load.gif'
import { employeeTaggingUrl } from '../../../utils/CommonUrl'
import MuiAlert from '@material-ui/lab/Alert/Alert';

const ACCESS_GRADE = 7
const EmployeesTaggingDetailRow = React.memo(({ 
  employeeDetail, 
  onUpdate, 
  loggedInEmployeeDetails, 
  classes, 
  podsList, 
  allEmployeesTaggingNewDetails,
  podsAndPodLeadMapping
 }) => {

  const onUpdatePodChange = (ev) => {
    const { name, value } = ev.target
    const newEmployeeDetail = {...employeeDetail}
    newEmployeeDetail.pod_master_id = value
    allEmployeesTaggingNewDetails[name] = newEmployeeDetail
    onUpdate(newEmployeeDetail)
  };

  let status = loggedInEmployeeDetails && loggedInEmployeeDetails.grade >= ACCESS_GRADE ? employeeDetail.employee_status : "Active"
  return (
    <TableRow>      
      <TableCell>{employeeDetail.legalname}</TableCell>
      <TableCell>{employeeDetail.employeeno}</TableCell>
      <TableCell>{status}</TableCell>
      <TableCell>{employeeDetail.designation}</TableCell>
      <TableCell>{employeeDetail.grade}</TableCell>
      <TableCell>{employeeDetail.reporting_manager_name}</TableCell>
      <TableCell>{employeeDetail.function}</TableCell>
      <TableCell>
        {loggedInEmployeeDetails && loggedInEmployeeDetails.grade >= ACCESS_GRADE ? (
          <FormControl className={classes.podFormControl}>
            <Select
                label='Pods'
                name={employeeDetail.employeeno}
                value={employeeDetail.pod_master_id ? employeeDetail.pod_master_id : 0}
                onChange={onUpdatePodChange}
            >
                {
                    podsList.map(
                        item => (<MenuItem key={item.id} value={item.id}>{item.pod}</MenuItem>)
                    )
                }

            </Select>
          </FormControl> ) : employeeDetail.pod
        }
      </TableCell>
      <TableCell style={{width: '100'}}>{employeeDetail.pod_master_id ? podsAndPodLeadMapping[employeeDetail.pod_master_id] : '---'}</TableCell>
      
    </TableRow>
  );
});


const ListOfEmployeeTaggingDetails = ({ 
  allEmployeesTaggingDetails, 
  setAllEmployeesTaggingDetails, 
  loggedInEmployeeDetails, 
  classes, 
  podsList, 
  page, 
  rowsPerPage, 
  allEmployeesTaggingNewDetails, 
  podsAndPodLeadMapping
}) => {
  const onUpdate = React.useCallback(
    (newDetail) => {
      setAllEmployeesTaggingDetails((prevAllEmployeesTaggingDetails) =>
      prevAllEmployeesTaggingDetails.map((t) => (t.id === newDetail.id ? newDetail : t))
      );
    },
    [setAllEmployeesTaggingDetails]
  );
  return (
    <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Full Name</TableCell>
              <TableCell>Emp Code</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Designation</TableCell>
              <TableCell>Grade</TableCell>
              <TableCell>Reporting Manager</TableCell>
              <TableCell>Sub Dept</TableCell>
              <TableCell>POD</TableCell>
              <TableCell>Pod Lead</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          { ( allEmployeesTaggingDetails && allEmployeesTaggingDetails.length > 0 ) ? allEmployeesTaggingDetails.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((employeeDetail,key)=>(
              <EmployeesTaggingDetailRow 
                key={key} 
                employeeDetail={employeeDetail} 
                onUpdate={onUpdate} 
                loggedInEmployeeDetails={loggedInEmployeeDetails} 
                classes={classes} 
                podsList={podsList} 
                allEmployeesTaggingNewDetails={allEmployeesTaggingNewDetails} 
                podsAndPodLeadMapping={podsAndPodLeadMapping}
                />
          )) : (
              <TableRow>
              <TableCell><b>No result found</b></TableCell>
              </TableRow>
          ) } 
          </TableBody>
        </Table>
      </TableContainer>
  );
};


export function AllEmployeeTagging(props) {

  const [allEmployeesTaggingDetails,setAllEmployeesTaggingDetails] = useState([]);
  const [allEmployeesTaggingNewDetails, setAllEmployeesTaggingNewDetails] = useState({});
  const [podsList, setPodsList] = useState([{id:0, pod: ''}]);
  const [podsAndPodLeadMapping, setPodsAndPodLeadMapping] = useState({});
  const [skillsList, setSkillsList] = useState([{id:0, skill: ''}]);
  const [skillAndSpecializationMap, setSkillAndSpecializationMap] = useState({});
  const [isLoading, setIsLoading] = useState(true)
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(200);
  const [loggedInEmployeeDetails, setLoggedInEmployeeDetails] = useState({})
  const [snackBarDetails, setSnackBarDetails] = useState({open:false,errorMessage:'',type:''})
  const classes = useStyles();

  useEffect(()=> {
    getPodsList()
    getSkillsList()
    getAllEmployeesTaggingDetails()
    getLoggedInEmployeeDetails()
  },[])

  const getAllEmployeesTaggingDetails = async () => {
    setIsLoading(true)
    try {
        let response = {}
        response = await getAllEmployeesTaggingData()
        response.data.forEach(element => {
          if(!element.employee_tagging_id){
            element['sql_action'] = 'insert'
          } else {
            element['sql_action'] = 'update'
          }
        });
        setAllEmployeesTaggingDetails(response.data)
        setIsLoading(false)
    } catch (error) {
        
    }
  }
  
  const getLoggedInEmployeeDetails = async () => {
    try {
        let response = await getLoggedInEmployeeDetailFetchData()
        if(response.data){
          response.data[0].grade = parseInt(response.data[0].grade, 10)
        }
        setLoggedInEmployeeDetails(response.data[0])
    } catch (error) {
        
    }
  }

  const getPodsList = async () => {
    try {
        let response = {}
        response = await getPodsListData()
        response.data.forEach(element => {
          if(element.pod_lead_name){
            podsAndPodLeadMapping[element.id] = element.pod_lead_name
          } else {
            podsAndPodLeadMapping[element.id] = '---'
          }
        })
        setPodsAndPodLeadMapping(podsAndPodLeadMapping)
        setPodsList(podsList.concat(response.data))
    } catch (error) {
        
    }
  }

  const getSkillsList = async () => {
    try {
        let response = {}
        response = await getSkillsListData()
        response.data.forEach(element => {
          if(element.specialization){
            skillAndSpecializationMap[element.id] = element.specialization
          } else {
            skillAndSpecializationMap[element.id] = '---'
          }
        })
        setSkillAndSpecializationMap(skillAndSpecializationMap)
        setSkillsList(skillsList.concat(response.data))
    } catch (error) {
        
    }
  }

  const saveTaggingData = async () => {
    setIsLoading(true)
    if(Object.keys(allEmployeesTaggingNewDetails).length > 0){
      const response = await updateEmployeeTagging({allEmployeesTaggingNewDetails});
      if(response.success){
        getAllEmployeesTaggingDetails()
        
      } else {
        setSnackBarDetails({open:true,errorMessage:'Something went wrong',type:'error'})
      }
      setAllEmployeesTaggingNewDetails({})
    } else {
      setSnackBarDetails({open:true,errorMessage:'Please make changes to save tagging details',type:'info'})
    }
    setIsLoading(false)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDownloadAsExcel = () => {
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', `${employeeTaggingUrl.DOWNLOAD_EMPLOYEE_TAGGING_AS_EXCEL}`);
    a.setAttribute('target', '_blank');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBarDetails({open:false,errorMessage:'',type:''});
  };

  return (
      <div className={classes.root}> 
          {snackBarDetails && snackBarDetails.open ? 
          <Snackbar
            anchorOrigin={{ vertical:'top', horizontal:'center' }}
            open={snackBarDetails.open}
            autoHideDuration={4000}
            onClose={handleSnackbarClose}
          >
            <MuiAlert severity={snackBarDetails.type}>{snackBarDetails.errorMessage}</MuiAlert>
          </Snackbar> : (null)
          }
          <Grid container>
            <Grid item xs={3}> 
              <HeaderLayout
                  title='Employee Tagging'
                  subTitle='Technology'
                  icon={<PeopleOutlineTwoTone/>}
              />
            </Grid>
            {loggedInEmployeeDetails && loggedInEmployeeDetails.grade >= ACCESS_GRADE ? (
            <Grid item xs={3}> 
              <Button className={classes.saveButton} variant="contained" endIcon={<SaveIcon />} onClick={saveTaggingData}>
                Save
              </Button>
            </Grid> ) : ''
            }
            {loggedInEmployeeDetails && loggedInEmployeeDetails.grade >= ACCESS_GRADE ? (
            <Grid item xs={3}> 
              <Button className={classes.downloadButton} variant="contained" endIcon={<CloudDownload />} onClick={handleDownloadAsExcel}>
                Download as Excel
              </Button>
            </Grid> ) : ''
            }
            <Grid item xs={3}> 
              
              <Button className={classes.downloadButton} variant="contained" >
              <a href="/EmployeeTagging/Summary"> Summary View</a>
              </Button>
            </Grid>
            <Grid item xs={3}> 
              <a href="/EmployeeTagging/Summary"> Summary </a>
            </Grid>
          </Grid>
          { ( isLoading ) 
            ? 
            <div className={classes.loader}><img src={logo} alt="loading..." /></div>
            : 
            <Paper className={classes.pageContent}>
              <ListOfEmployeeTaggingDetails 
                allEmployeesTaggingDetails={allEmployeesTaggingDetails} 
                setAllEmployeesTaggingDetails={setAllEmployeesTaggingDetails} 
                loggedInEmployeeDetails = {loggedInEmployeeDetails}
                classes={classes}
                podsList={podsList}
                page={page}
                rowsPerPage={rowsPerPage}
                allEmployeesTaggingNewDetails={allEmployeesTaggingNewDetails}
                skillsList={skillsList}
                podsAndPodLeadMapping={podsAndPodLeadMapping}
                skillAndSpecializationMap={skillAndSpecializationMap}
              />
              <TablePagination
                rowsPerPageOptions={[50, 100, 200, 250]}
                component="div"
                count={allEmployeesTaggingDetails.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Paper>
          }
      </div>
  )
}
