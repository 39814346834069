import React, { Fragment, useState, useEffect } from 'react';
import { useStyles } from './style';
import {
  Grid,
  Paper,
  FormControl,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  InputLabel,
  Select,
  Input,
  MenuItem,
  FormHelperText,
  Divider,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import UpdateIcon from '@material-ui/icons/Update';
import ReplayIcon from '@material-ui/icons/Replay';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import SimpleAlerts from '../Alert';
import { deleteJoinerRecord, postRelativeData, getEmpData } from '../fetchData';
import DialogComponent from '../../../components/Shared/Elements/DialogComponent';

const tableColumns = [
  { id: 'Employee Code', label: 'Employee Code', minWidth: 10 },
  { id: 'Relative Name', label: 'Relative Name', minWidth: 30 },
  { id: 'Relation', label: 'Relation', minWidth: 20 },
  { id: 'RERA License', label: 'RERA License Holder', minWidth: 20 },
  {
    id: 'Edit',
    label: '',
    minWidth: 5,
    align: 'left',
  },
  {
    id: 'Delete',
    label: '',
    minWidth: 5,
    align: 'left',
  },
];

const relationList = [
  `Father`,
  `Mother`,
  `Spouse`,
  `Son`,
  `Daughter`,
  `Brother`,
  `Sister`,
  `Father-in-law`,
  `Mother-in-law`,
  `Maternal Uncle`,
  `Paternal Uncle`,
  `Maternal Aunt`,
  `Paternal Aunt`,
  `Brother-in-law`,
  `Sister-in-law`,
  `Paternal First Cousin`,
  `Maternal First Cousin`,
]

const Relative = ({
  joinerRelativesData,
  handleChildData = () => {},
  handleChildDataValidation = () => {},
  handleNotification = () => {},
  handleSkippableComponent = () => {},
}) => {
  const classes = useStyles();
  const [joinerRelativesDataList, setJoinerRelativesData] = useState(
    joinerRelativesData
  );
  const [notification, setNotification] = useState({ type: '', message: '' });
  const [isUpdate, setIsUpdate] = useState(0);
  const [isReraRelUpdate, setIsReraRelUpdate] = useState(0);
  const initObj = {
    id: null,
    relativeName: '',
    empCode: '',
    relation: '',
    isReaEmp: '',
    hasRelWithRera: '',
    hasReraLic: 0,
    relWithReraName: '',
    relWithReraRelation: '',
  };

  let initErrObj = {};
  for (let k in initObj) {
    initErrObj[k] = { status: false, text: '' };
  }

  const [apiEmpCode, setApiEmpCode] = useState('');
  const [relativeObj, setRelativeObj] = useState(initObj);
  const [localErrObj, setLocalErrObj] = useState(initErrObj);
  // const [disabledAddBtn, setDisabledAddBtn] = useState(true);
  // const [disabledReraRelAddBtn, setDisabledReraRelAddBtn] = useState(true);
  const dialogTitle = `Confirm`;
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteRecordId, setDeleteRecordId] = useState('');
  const handleDialogOpen = (id) => {
    setOpenDialog(true);
    setDeleteRecordId(id);
  };
  const handleDialogClose = (val) => {
    if (val === true) {
      handleRecordDelete(deleteRecordId);
    }
    setOpenDialog(false);
  };

  const {
    relativeName,
    empCode,
    relation,
    isReaEmp,
    hasReraLic,
    relWithReraName,
    relWithReraRelation,
  } = relativeObj;

  useEffect(() => {
    const isCount = joinerRelativesDataList.length ? true : false;
    handleChildData('joinerRelativesData', joinerRelativesDataList);
    handleChildDataValidation('joinerRelativesData', {
      status: !isCount,
      text: '',
    });
  }, [joinerRelativesDataList]);

  const handleChange = (e) => {
    let key = e.target.name;
    let value = e.target.value;
    setRelativeObj({ ...relativeObj, [key]: value });
    let { text, status } = checkRelativeDataError(key, value);
    setLocalErrObj({ ...localErrObj, [key]: { text, status } });
  };

  const handleCheckChange = (e) => {
    setRelativeObj({ ...relativeObj, [e.target.name]: e.target.checked })
    if (!e.target.checked) {
      if (e.target.name === 'isReaEmp') {
        setRelativeObj({
          ...relativeObj,
          relation: '',
          relativeName: '',
          empCode: null,
          isReaEmp: ''
        })
      }
      if (e.target.name === 'hasReraLic') {
        setRelativeObj({
          ...relativeObj,
          relWithReraName: '',
          relWithReraRelation: '',
          hasRelWithRera: '',
          hasReraLic: 0
        })
      }
    }
  }

  const handleEmpData = async () => {
    let text = '';
    let status = false;

    if (!isDuplicateRecord(empCode)) {
      const { data: { legalname } = {}, success } = await getEmpData(empCode);
      if (success) {
        setRelativeObj({ ...relativeObj, relativeName: legalname });
      } else {
        text = 'Invalid employee code';
        status = true;
        setRelativeObj({ ...relativeObj, relativeName: '', relation: '' });
        // setDisabledAddBtn(true)
      }
    } else {
      text = 'Duplicate employee code';
      status = true;
      setRelativeObj({ ...relativeObj, relativeName: '', relation: '' });
      // setDisabledAddBtn(true)
    }
    setLocalErrObj({ ...localErrObj, empCode: { text, status } });
  };

  const checkRelativeDataError = (key, value) => {
    let text = '';
    let status = false;
    switch (key) {
      case 'relativeName':
        if (value === '') {
          status = true;
          text = `Invalid name`;
        }
        break;
      case 'empCode':
        if (value === '') {
          status = true;
          text = `Invalid employee code`;
        }
        break;
      case 'relation':
        if (value === '') {
          status = true;
          text = `Invalid relation`;
        }
        break;
      case 'relWithReraName':
        if (value === '') {
          status = true;
          text = `Invalid name`;
        }
        break;
      case 'relWithReraRelation':
        if (value === '') {
          status = true;
          text = `Invalid relation`;
        }
        break;
      default:
        break;
    }
    // handleButtonDisable({ status, key, value });
    return { text, status };
  };

  const sortRelativeDataList = (list = []) => {
    let result = {
      relativeWithRera: [],
      relWithRea:[]
    }

    list.forEach(obj => {
      if (obj.has_rera_lic) {
        result.relativeWithRera.push(obj)
      } else {
        result.relWithRea.push(obj)
      }
    })

    return result
  }

  /*
  const handleButtonDisable = ({ key, value }) => {
    let empCode = key === 'empCode' ? value : relativeObj.empCode;
    let relativeName =
    key === 'relativeName' ? value : relativeObj.relativeName;
    let relation = key === 'relation' ? value : relativeObj.relation;
    let relWithReraName =
      key === 'relWithReraName' ? value : relativeObj.relWithReraName;
    let relWithReraRelation =
      key === 'relWithReraRelation' ? value : relativeObj.relWithReraRelation;
    if (isReaEmp) {
      setDisabledAddBtn(!(empCode !== '' && relation !== '' && relativeName !==''));
    }
    if (hasRelWithRera) {
      setDisabledReraRelAddBtn(!(relWithReraName !== '' && relWithReraRelation !== ''));
    }
  };
  */

  const isDuplicateRecord = (empCode) => {
    const res = joinerRelativesDataList.filter(
      (obj) => obj.emp_code === empCode && empCode !== apiEmpCode
    );
    return res.length;
  };

  const handleSaveData = () => {
    // setDisabledReraRelAddBtn(true);
    let text = ``;
    if (joinerRelativesDataList.length >= 3 && !isUpdate) {
      text = `You can add upto 3 records!`;
      setNotification({ message: text, type: 'error' });
      // setDisabledReraRelAddBtn(false);
    } else {
      let errObj = {};
      let finalStatus;
      const relObj = {
        relationName: relativeObj.relationName,
        relation: relativeObj.relation,
        empCode: relativeObj.empCode,
        hasReraLic: relativeObj.hasReraLic,
      };
      for (let key in relObj) {
        let { text, status } = checkRelativeDataError(key, relObj[key]);
        errObj[key] = { text, status };
        if (status) {
          finalStatus = status;
        }
      }
      if (isDuplicateRecord(relativeObj.empCode) && apiEmpCode !== empCode) {
        finalStatus = true;
        errObj.empCode.text = 'Duplicate employee code';
        errObj.empCode.status = true;
      }
      setLocalErrObj({ ...localErrObj, ...errObj });
      if (finalStatus) {
        text = `There is some error in the form!`;
        setNotification({ message: text, type: 'error' });
        // setDisabledReraRelAddBtn(false);
      } else {
        handleApiCall(relativeObj, 0);
      }
    }
  };

  const handleReraRelSaveData = () => {
    // setDisabledAddBtn(true);
    let text = ``;
    if (joinerRelativesDataList.length >= 3 && !isReraRelUpdate) {
      text = `You can add upto 3 records!`;
      setNotification({ message: text, type: 'error' });
      // setDisabledAddBtn(false);
    } else {
      let errObj = {};
      let finalStatus;
      const relWithReraObj = {
        relWithReraName: relativeObj.relWithReraName,
        relWithReraRelation: relativeObj.relWithReraRelation,
        hasReraLic: 1,
      };
      for (let key in relWithReraObj) {
        let { text, status } = checkRelativeDataError(key, relWithReraObj[key]);
        errObj[key] = { text, status };
        if (status) {
          finalStatus = status;
        }
      }
      setLocalErrObj({ ...localErrObj, ...errObj });
      if (finalStatus) {
        text = `There is some error in the form!`;
        setNotification({ message: text, type: 'error' });
        // setDisabledAddBtn(false);
      } else {
        const apiObj = {
          id: relativeObj.id && isReraRelUpdate ? relativeObj.id : null,
          relativeName: relWithReraObj.relWithReraName,
          relation: relWithReraObj.relWithReraRelation,
          hasReraLic: relWithReraObj.hasReraLic,
          empCode: null
        };
        handleApiCall(apiObj, 1);
      }
    }
  };

  const handleApiCall = async (dataObj, buttonFlag) => {
    let response = {};
    response = await postRelativeData({ relativeList: [dataObj] });
    const { success = false, joinerRelativesData = [] } = response || {};
    
    let text = ``;
    let type = ``;
    if (success) {
      if (buttonFlag === 0) {
        handleReset();
      } else {
        handleReraRelReset();
      }
      
      setJoinerRelativesData(joinerRelativesData);
      handleSkippableComponent('Relative Details', joinerRelativesData.length);
      handleNotification({ status: false, text: '' });
      text = dataObj.id
        ? 'Record updated successfully!'
        : 'Record added successfully!';
      type = `success`;
    } else {
      text = `Something went wrong!`;
      type = `error`;
    }
    setNotification({ message: text, type });
    // setDisabledReraRelAddBtn(false);
    // setDisabledAddBtn(false);
    setApiEmpCode('');
  };

  const handleReset = () => {
    setLocalErrObj({
      ...localErrObj,
      relation: { status: false, text: '' },
      relativeName: { status: false, text: '' },
      empCode: { status: false, text: '' },
      isReaEmp: { status: false, text: '' },
      hasReraLic: { status: false, text: '' }
    });
    setRelativeObj({
      ...relativeObj,
      relation: '',
      relativeName: '',
      empCode: null,
      isReaEmp: '',
      hasReraLic: 0,
    });
    setIsUpdate(false);
    setIsReraRelUpdate(false)
    setNotification({ message: '', type: '' });
    handleNotification({ status: false, text: '' });
    // setDisabledAddBtn(true);
    setApiEmpCode('');
  };

  const handleReraRelReset = () => {
    setLocalErrObj({
      ...localErrObj,
      relWithReraName: { text: '', status: false },
      relWithReraRelation: { text: '', status: false },
    });
    setRelativeObj({
      ...relativeObj,
      relWithReraName: '',
      relWithReraRelation: '',
      hasRelWithRera: '',
      hasReraLic: 0
    });
    setIsUpdate(false);
    setIsReraRelUpdate(false)
    setNotification({ message: '', type: '' });
    handleNotification({ status: false, text: '' });
    // setDisabledReraRelAddBtn(true);
  };

  const handleRecordEdit = (e, recId, empCode) => {
    setApiEmpCode(empCode);
    handleNotification({ status: false, text: '' });
    setNotification({ message: '', type: '' });
    const updateRec = getRelativeRecordById(recId);
    if (updateRec) {
      const {
        id = null,
        relative_name = '',
        emp_code = '',
        relation = '',
        department_id = '',
        location_name = '',
        has_rera_lic = false,
      } = updateRec;
      let updateObj = {
        id,
        relativeName: relative_name,
        relation,
      };
      if (emp_code) {
        updateObj = {
          ...updateObj,
          empCode: emp_code,
          departmentId: department_id,
          locationName: location_name,
          hasReraLic: has_rera_lic,
          isReaEmp: 1,
          hasRelWithRera: '',
        };
        setIsUpdate(recId);
      } else {
        updateObj = {
          id,
          relWithReraName: relative_name,
          relWithReraRelation: relation,
          hasReraLic: has_rera_lic,
          isReaEmp: '',
          hasRelWithRera: 1,
        };
        setIsReraRelUpdate(true);
      }
      setRelativeObj(updateObj);
      setLocalErrObj(initErrObj);
    }
  };

  const getRelativeRecordById = (id) => {
    return joinerRelativesDataList.find((obj) => obj.id === id);
  };

  const handleRecordDelete = async (id) => {
    let text = ``;
    let type = ``;
    setLocalErrObj(initErrObj);
    setRelativeObj(initObj);
    setIsUpdate(false);
    setIsReraRelUpdate(false);
    if (id) {
      let response = {};
      response = await deleteJoinerRecord({ id, modelName: 'relative' });
      const { success = false, joinerRelativesData = [] } = response || {};
      if (success) {
        setJoinerRelativesData(joinerRelativesData);
        handleSkippableComponent(
          'Relative Details',
          joinerRelativesData.length
        );
        text = `Record deleted successfully!`;
        type = `success`;
      } else {
        text = `Something went wrong!`;
        type = `error`;
      }
    }
    handleNotification({ status: false, text: '' });
    setNotification({ message: text, type });
  };

  const closeAlert = () => {
    setNotification({ type: '', message: '' });
  };

  return (
    <Fragment>
      <Grid container spacing={2} className={classes.root}>
        <Grid item sm={10}>
          <Paper className={classes.paperFirst}>
            {notification.message ? (
              <SimpleAlerts
                type={notification.type}
                message={notification.message}
                handleAlert={closeAlert}
              />
            ) : null}
            <div style={{color: "red", float: "left", fontSize: "11px", paddingTop: "10px"}}>
              <sup>*</sup> Kindly read general instructions before filling in the information on this page
            </div>
            <Grid container spacing={4}>
              <FormControl
                className={classes.formControlLabel}
                error={localErrObj.isReaEmp.status}
              >
                <FormControlLabel
                  className={classes.formControlLable}
                  control={
                    <Checkbox
                      checked={isReaEmp ? true : false}
                      onChange={(e) => handleCheckChange(e)}
                      name='isReaEmp'
                      color='primary'
                    />
                  }
                  label="Is any of your relative a part of REA India Group?"
                />
              </FormControl>
            </Grid>
            <Grid container spacing={4}>
              {isReaEmp ? (
                <div>
                  <FormControl className={classes.formControl}>
                    <TextField
                      autoComplete='off'
                      required={true}
                      label='Employee Code'
                      placeholder='Enter Employee Code'
                      value={empCode}
                      name='empCode'
                      onChange={(e) => handleChange(e)}
                      inputProps={{ minLength: 2, maxLength: 100 }}
                      error={localErrObj.empCode.status}
                      helperText={localErrObj.empCode.text}
                      onBlur={handleEmpData}
                    />
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <TextField
                      autoComplete='off'
                      required={true}
                      label='Relative Name'
                      placeholder={relativeName ? '' : 'Relative Name'}
                      value={relativeName}
                      name='relativeName'
                      onChange={(e) => handleChange(e)}
                      inputProps={{
                        minLength: 2,
                        maxLength: 100,
                        readOnly: true,
                      }}
                      error={localErrObj.relativeName.status}
                      helperText={localErrObj.relativeName.text}
                    />
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <InputLabel
                      required={true}
                      error={localErrObj.relation.status}
                    >
                      Relation
                    </InputLabel>
                    <Select
                      value={relation}
                      name='relation'
                      input={<Input id='relation' />}
                      onChange={(e) => handleChange(e)}
                      error={localErrObj.relation.status}
                    >
                      <MenuItem value='' disabled>
                        <em>Select the value</em>
                      </MenuItem>
                      {relationList.map((element, index) => {
                        return (
                          <MenuItem key={index} value={element}>
                            {element}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText style={{ color: 'red' }}>
                      {localErrObj.relation.text}
                    </FormHelperText>
                  </FormControl>
                </div>
              ) : (
                <div></div>
              )}
              {isReaEmp ? (
                <div>
                  <Grid
                    container
                    spacing={2}
                    className={classes.formControlAddBtn}
                  >
                    <FormControl className={classes.formControlAddBtn}>
                      {isUpdate ? (
                        <Button
                          variant='contained'
                          color='secondary'
                          size='small'
                          className={classes.button}
                          startIcon={<UpdateIcon />}
                          onClick={handleSaveData}
                          disabled={
                            relativeName &&
                            empCode &&
                            relation &&
                            !localErrObj.relativeName.status &&
                            !localErrObj.empCode.status &&
                            !localErrObj.relation.status ? false : true
                          }
                        >
                          Update
                        </Button>
                      ) : (
                        <Button
                          variant='contained'
                          color='secondary'
                          size='small'
                          className={classes.button}
                          startIcon={<SaveIcon />}
                          onClick={handleSaveData}
                          disabled={
                            relativeName &&
                            empCode &&
                            relation &&
                            !localErrObj.relativeName.status &&
                            !localErrObj.empCode.status &&
                            !localErrObj.relation.status ? false : true
                          }
                        >
                          Add
                        </Button>
                      )}
                    </FormControl>
                    <FormControl className={classes.formControlAddBtn}>
                      <Button
                        variant='contained'
                        size='small'
                        className={classes.button}
                        startIcon={<ReplayIcon />}
                        onClick={handleReset}
                      >
                        Reset
                      </Button>
                    </FormControl>
                  </Grid>
                </div>
              ) : (
                <div></div>
              )}
            </Grid>
            
            <Grid container spacing={4}>
              <FormControl
                className={classes.formControlLabel}
                error={localErrObj.hasReraLic.status}
              >
                <FormControlLabel
                  className={classes.formControlLable}
                  control={
                    <Checkbox
                      checked={hasReraLic ? true : false}
                      onChange={(e) => handleCheckChange(e)}
                      name='hasReraLic'
                      color='primary'
                    />
                  }
                  label="Does any relative of yours hold a valid RERA license/registration?"
                />
              </FormControl>
            </Grid>
            <Grid container spacing={4}>
              {hasReraLic ? (
                <div>
                  <FormControl className={classes.formControl}>
                    <TextField
                      autoComplete='off'
                      required={true}
                      label='Relative Name'
                      placeholder='Relative Name'
                      value={relWithReraName}
                      name='relWithReraName'
                      onChange={(e) => handleChange(e)}
                      inputProps={{ minLength: 2, maxLength: 100 }}
                      error={localErrObj.relWithReraName.status}
                      helperText={localErrObj.relWithReraName.text}
                    />
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <InputLabel
                      required={true}
                      error={localErrObj.relWithReraRelation.status}
                    >
                      Relation
                    </InputLabel>
                    <Select
                      value={relWithReraRelation}
                      name='relWithReraRelation'
                      input={<Input id='relWithReraRelation' />}
                      onChange={(e) => handleChange(e)}
                      error={localErrObj.relWithReraRelation.status}
                    >
                      <MenuItem value='' disabled>
                        <em>Select the value</em>
                      </MenuItem>
                      {relationList.map((element, index) => {
                        return (
                          <MenuItem key={index} value={element}>
                            {element}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText style={{ color: 'red' }}>
                      {localErrObj.relWithReraRelation.text}
                    </FormHelperText>
                  </FormControl>
                </div>
              ) : (
                <div></div>
              )}
              {hasReraLic ? (
                <div>
                  <Grid
                    container
                    spacing={2}
                    className={classes.formControlAddBtn}
                  >
                    <FormControl className={classes.formControlAddBtn}>
                      {isReraRelUpdate ? (
                        <Button
                          variant='contained'
                          color='secondary'
                          size='small'
                          className={classes.button}
                          startIcon={<UpdateIcon />}
                          onClick={handleReraRelSaveData}
                          disabled={
                            relWithReraName &&
                            relWithReraRelation &&
                            !localErrObj.relWithReraName.status &&
                            !localErrObj.relWithReraRelation.status ? false : true
                          }
                        >
                          Update
                        </Button>
                      ) : (
                        <Button
                          variant='contained'
                          color='secondary'
                          size='small'
                          className={classes.button}
                          startIcon={<SaveIcon />}
                          onClick={handleReraRelSaveData}
                          disabled={
                            relWithReraName &&
                            relWithReraRelation &&
                            !localErrObj.relWithReraName.status &&
                            !localErrObj.relWithReraRelation.status ? false : true
                          }
                        >
                          Add
                        </Button>
                      )}
                    </FormControl>
                    <FormControl className={classes.formControlAddBtn}>
                      <Button
                        variant='contained'
                        size='small'
                        className={classes.button}
                        startIcon={<ReplayIcon />}
                        onClick={handleReraRelReset}
                      >
                        Reset
                      </Button>
                    </FormControl>
                  </Grid>
                </div>
              ) : (
                <div></div>
              )}
            </Grid>
          </Paper>
          <Divider light />
          <Paper className={classes.root}>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label='sticky table'>
              <TableHead>
                  <TableRow>
                    <TableCell colSpan={tableColumns.length}>
                      <b>Relative(s) with REA India</b>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    {tableColumns.map((column) => column.label === 'RERA License Holder' ? null : (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  { 
                  sortRelativeDataList(joinerRelativesDataList).relWithRea.map(
                    (
                      { id, relative_name, emp_code, relation, has_rera_lic },
                      index
                    ) => {
                      return (
                        <TableRow hover tabIndex={-1} key={index}>
                          <TableCell align={'left'} size={'small'}>
                            {emp_code ? emp_code : '-'}
                          </TableCell>
                          <TableCell align={'left'} size={'small'}>
                            {relative_name}
                          </TableCell>
                          <TableCell align={'left'} size={'small'}>
                            {relation}
                          </TableCell>
                          {/* <TableCell align={'left'} size={'small'}>
                            {has_rera_lic ? 'Yes' : 'No'}
                          </TableCell> */}
                          <TableCell align={'left'} size={'small'}>
                            <div
                              onClick={(e) => handleRecordEdit(e, id, emp_code)}
                            >
                              <EditIcon />
                            </div>
                          </TableCell>
                          <TableCell align={'left'} size={'small'}>
                            <div
                              onClick={(e) => {
                                handleDialogOpen(id);
                              }}
                            >
                              <DeleteForeverIcon />
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <Divider light />
          <Paper className={classes.root}>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label='sticky table'>
              <TableHead>
                  <TableRow>
                    <TableCell colSpan={tableColumns.length}>
                      <b>Relative(s) having RERA license/registration</b>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    {tableColumns.map((column) => column.label === 'Employee Code' ? null : (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  { 
                  sortRelativeDataList(joinerRelativesDataList).relativeWithRera.map(
                    (
                      { id, relative_name, emp_code, relation, has_rera_lic },
                      index
                    ) => {
                      return (
                        <TableRow hover tabIndex={-1} key={index}>
                          {/* <TableCell align={'left'} size={'small'}>
                            {emp_code ? emp_code : '-'}
                          </TableCell> */}
                          <TableCell align={'left'} size={'small'}>
                            {relative_name}
                          </TableCell>
                          <TableCell align={'left'} size={'small'}>
                            {relation}
                          </TableCell>
                          <TableCell align={'left'} size={'small'}>
                            {has_rera_lic ? 'Yes' : 'No'}
                          </TableCell>
                          <TableCell align={'left'} size={'small'}>
                            <div
                              onClick={(e) => handleRecordEdit(e, id, emp_code)}
                            >
                              <EditIcon />
                            </div>
                          </TableCell>
                          <TableCell align={'left'} size={'small'}>
                            <div
                              onClick={(e) => {
                                handleDialogOpen(id);
                              }}
                            >
                              <DeleteForeverIcon />
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
        <Grid item sm={2}>
          <Paper className={classes.paperGeneral}>
            <div>
            <ul>
                <li className='unorder-list'>&nbsp;</li>
                <li className='unorder-list'>General Instructions:</li>
                <li className='unorder-list'>&nbsp;</li>
                <li>Select appropriate option on the screen and declare all of your relatives that are working with REA India or / and have a valid RERA (Real Estate Regulatory Authority) license.</li>
                <li>In case your relative(s) is working in REA India, kindly mention their employee code with REA India and their relationship with you. </li>
                <li>In case any of your relative (s) holds a valid RERA license, click on the check box and share their details. </li>
                <li>Click on ADD in case you have more than 1 relative to declare.</li>
                <li>You may click on SKIP option in case this section is not applicable to you.</li>
                <li>To move ahead, click SAVE & NEXT.</li>
              </ul>
            </div>
            <DialogComponent
              openDialog={openDialog}
              dialogTitle={dialogTitle}
              dialogContentText='Are you sure you want to delete this relative record?'
              handleDialogClose={handleDialogClose}
            />
          </Paper>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default Relative;
