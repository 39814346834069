export const efilingConstant = {
    fileExtenstions: ['jpg', 'jpeg', 'png', 'pdf'],
    fileExtenstionsSpecial: ['jpg', 'jpeg', 'png', 'pdf','htm','html'],
    extraApprovalDocumentIds: ["20","21","108","109","110","111","112","113","114","115","116","117","118","119","120","121","122","123","124","125",20,21,108,109,110,111,112,113,114,115,116,117,118,119,120,121,122,123,124,125],
    bulkUploadFileExtension: ['zip'],
    status : {
        PENDING_FOR_SUBMISSION: 1,
        PENDING_FOR_APPROVAL  : 2,
        APPROVED              : 3,
        REJECTED              : 4,
        ARCHIVED              : 5,
        PENDING_FOR_APPROVAL_BY_HROPS2: 6,
        PENDING_FOR_APPROVAL_BY_HROPS3: 7,
    },
    status_hrops : {
        2: 'Pending with HROps1',
        6: 'Pending with HROps2',
        7: 'Pending with HROps3'
    },
    HROPS_ADMIN_ROLE_EMP : {
        HROPS1 : '4',
        HROPS2 : '3',
        HROPS3 : '2',
        CHRO   : '1'
    },
    DOCUMENT_VISIBILITY : {
        EMPLOYEE: 1,
        HROPS: 2,
        BOTH: 3
    },
    DOCUMENT_APPLICABILITY : {
        ALL_EMPLOYEES: 1,
        NOT_ALL_EMPLOYEES: 2
    },
    EMAIL_TEMPLATES : {
        PENDING_DOCUMENT_GENERIC_EMAIL : 'Submit your pending document(s)'
    },
    HRBP_ADMIN_GROUP_CONSTANT : {
        'SUPER_ADMIN': 1,
        'HRBP': 9,
        'Process_Control_HRBP': 28,
        'HRBP_HEAD': 27
    },
    MODULE_MASTER: {
        1: 'Efiling',
        2: 'Online Joining',
        3: 'Offer Module'
    }
}