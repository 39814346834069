import { makeStyles } from '@material-ui/core/styles'
import blue from "@material-ui/core/colors/blue"

export const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(0),
      textAlign: 'center',
      paddingLeft: '20px'
    },
    formControl: {
      margin: theme.spacing(3),
      minWidth: 120,
    },
    formControlLable: {
      textAlign: 'left',
      margin: theme.spacing(3),
      minWidth: 120,
    },
    input: {
      display: "none"
    },
    button: {
      color: blue[900],
      margin: 10
    },
    icon: {
      margin: 10,
      fontSize: 24,
    },
}))
  