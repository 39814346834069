import { makeStyles } from '@material-ui/core/styles'

const colorLoader = '#ce4233'

// body {
//   background: $color-background;
// }
export const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  loader: {
    textAlign: 'center',
    marginTop: 60,
    fontFamily: 'helvetica, arial, sans-serif',
    textTransform: 'uppercase',
    fontWeight: 900,
    color: colorLoader,
    letterSpacing: '0.2em',

    '&::before': {
      content: '',
      display: 'block',
      width: 15,
      height: 15,
      background: colorLoader,
      position: 'absolute',
      animation: 'load 0.7s infinite alternate ease-in-out'
    },
    '&::after': {
      content: '',
      display: 'block',
      width: 15,
      height: 15,
      background: colorLoader,
      position: 'absolute',
      animation: 'load 0.7s infinite alternate ease-in-out'
    },

    '&::before': {
      top: 0
    },

    '&::after': {
      bottom: 0
    }
  },

  '@keyframes load': {
    '0%': {
      left: 0,
      height: 30,
      width: 15
    },
    '50%': {
      height: 8,
      width: 40
    },
    '100%': {
      left: 235,
      height: 30,
      width: 15
    }
  }
}))
