import React from 'react'
import { useStyles } from './style' 

export const LastPage = () => {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            Form Submitted!
        </div>
    )
}