import React from 'react'
import AllCampaign from '../../../pages/Campaign/AllCampaign'


export default function DashboardComponent(props) {
  return (
    <div>
      < AllCampaign {...props}/>
    </div>
  )
}
