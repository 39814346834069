import React, { useState,useEffect } from 'react';
import { useStyles } from './style';
import { getDocumentMasterListFetchData, getEmployeeDocumentDetailsFetchData, getLoggedInEmployeeDetailFetchData, postEmployeeDocumentListFetchData, updateEmployeeDocumentListFetchData, postUploadedFiles, getHropsListFetchData, getEmployeeDocumentDetailsForBulkUploadFetchData } from '../fetchData'
import HeaderLayout from '../HeaderLayout'
import { TableCell, TableContainer, TableHead, TableRow, Table, TableBody, Select, MenuItem, Button, Grid, FormControl, TablePagination, TextField, Snackbar, InputLabel } from '@material-ui/core'
import PeopleOutlineTwoTone from '@material-ui/icons/PeopleOutlineTwoTone'
import {Paper} from '@material-ui/core'
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined'
import logo from '../../../utils/load.gif'
import { efilingConstant } from '../../../constants/EfilingConstant'
import MuiAlert from '@material-ui/lab/Alert/Alert';
import JSZip from 'jszip'
import { getMenuSubmenuRightsDetail } from '../../../utils/authFunction';
import { pageURLConfig, menuSubmenuPageCategory } from '../../../constants/CommonConstant';
const { moduleName, controllerName, actionName } = pageURLConfig.EFILING_INDEX_BULK_UPLOAD
const { ADMIN_CATEGORY } = menuSubmenuPageCategory

export default function BulkUploadScreen(props) {
    const [isLoading, setIsLoading] = useState(false)
    const [employeeDocumentDetails, setEmployeeDocumentDetails] = useState([])
    const [loggedInEmployeeDetails, setLoggedInEmployeeDetails] = useState({})
    const [documentMasterListAndIdMapping, setDocumentMasterListAndIdMapping] = useState([])
    const [documentMasterListAndAliasMapping,setDocumentMasterListAndAliasMapping] = useState([])
    const [fileData, setFileData] = useState({})
    const classes = useStyles();
    const [snackBarDetails, setSnackBarDetails] = useState({open:false,errorMessage:'',type:'',positionVertical:'top'})
    const [hropsRole, setHropsRole] = useState('')
    const [isAuthorised, setisAuthorised] = useState(true)
    const [successList, setSuccessList] = useState([])
    const [failureList, setfailureList] = useState([])
    const [isHavingPageAddAccess, setIsHavingPageAddAccess] = useState(false)
    const [isHavingPageEditAccess, setIsHavingPageEditAccess] = useState(false)
    const [isHavingPageViewAccess, setIsHavingPageViewAccess] = useState(null)

    useEffect(()=>{
        getEmployeeDocumentDetails()
        getDocumentMasterList()
        setPageRights();
    },[])

    useEffect(()=>{

    },[employeeDocumentDetails])

    useEffect(()=>{

    },[isLoading])

    useEffect(()=>{

    },[successList])

    useEffect(()=>{

    },[failureList])
    

    const setPageRights = async () => {
        const params = { pageCategory: ADMIN_CATEGORY, moduleName, controllerName, actionName}
        const rightsObj = await getMenuSubmenuRightsDetail(params)
        const { 
            isUserSuperAdmin,
            isUserGroupAdmin,
            allEmployeeAddRights,
            allEmployeeEditRights, 
            allEmployeeViewRights 
       } = rightsObj
       const addAccess = isUserSuperAdmin || 
            (isUserGroupAdmin && allEmployeeAddRights) 
       const editAccess =  isUserSuperAdmin || 
            (isUserGroupAdmin && allEmployeeEditRights)
       const viewAccess =  isUserSuperAdmin || 
            (isUserGroupAdmin && allEmployeeViewRights)
        
        setIsHavingPageAddAccess(addAccess)
        setIsHavingPageEditAccess(editAccess)
        setIsHavingPageViewAccess(viewAccess)
   }

    const onFileChange = async (e) => {

        let files = e.target.files[0]
        console.log(files);
        if(files){
            if(!efilingConstant.bulkUploadFileExtension.includes(files.name.split('.').pop())){
                setSnackBarDetails({open:true,errorMessage:`Selected file type is invalid! Allowed file types are ${efilingConstant.bulkUploadFileExtension.join(',')}`,type:'error',positionVertical:'top'})
                return false
            }
            setFileData(files)
        }
        
    }
    const handleSelectedFile = (e, document_master_id) => {
        setFileData({})
    }

    const getEmployeeDocumentDetails = async () => {
        try {
            setIsLoading(true)
            setSuccessList([])
            setfailureList([])
            let loggedInEmployeeDetailsResponse = await getLoggedInEmployeeDetailFetchData()
            setLoggedInEmployeeDetails(loggedInEmployeeDetailsResponse.data[0])
            let tempHrOpsList = await getHrOpsList()
            await checkAuthorised(tempHrOpsList,loggedInEmployeeDetailsResponse.data[0])
            let response = await getEmployeeDocumentDetailsForBulkUploadFetchData()
            if(response && response.data){
                setEmployeeDocumentDetails(response.data)
            }
            setIsLoading(false)
        } catch (error) {
            
        }
    }

    const getHrOpsList = async () => {
        try {
          let response = {}
          response = await getHropsListFetchData()
          if(response && response.data){
            let tempHropsList = {}
            response.data.map(element => {
                if(tempHropsList && tempHropsList[element.role]){
                  tempHropsList[element.role].push(element.employeeno)
                } else {
                  tempHropsList[element.role] = []
                  tempHropsList[element.role].push(element.employeeno)
                }
            })
            return tempHropsList
          }
        } catch (error) {
          
        }
      }

    const checkAuthorised = async (tempHrOpsList,tempLoggedInEmployeeDetails) => {
        let isAuthorised = false;
        let tempHrOpsRole = ''
        if(tempLoggedInEmployeeDetails && tempHrOpsList) {
          Object.keys(tempHrOpsList).map(role => {
            if(tempHrOpsList[role].includes(tempLoggedInEmployeeDetails['employeeno'])){
              isAuthorised = true;
              tempHrOpsRole = role
              setHropsRole(role)
            }
          })
        }
        setisAuthorised(isAuthorised)
        return tempHrOpsRole;
    }

    const getDocumentMasterList = async () => {
        try {
            let response = {}
            response = await getDocumentMasterListFetchData();
            let documentDetailAndIdMapping = {}, tempDocumentMasterListAndAliasMapping = {}
            response.data.forEach(element => {
                documentDetailAndIdMapping[element.id] = element
                tempDocumentMasterListAndAliasMapping[element.document_alias] = element
            })
            setDocumentMasterListAndAliasMapping(tempDocumentMasterListAndAliasMapping)
            setDocumentMasterListAndIdMapping(documentDetailAndIdMapping)
        } catch (error) {
            
        }
    }

    const handleSubmit = async () => {
        setIsLoading(true)
        const zip = new JSZip();

        let employeeDocumentRecordMap = {}

        employeeDocumentDetails.map(element => {
            if(employeeDocumentRecordMap && employeeDocumentRecordMap[element.employeeno]){
                if(documentMasterListAndIdMapping[element.document_master_id]){
                    employeeDocumentRecordMap[element.employeeno][documentMasterListAndIdMapping[element.document_master_id]['document_alias']] = element
                }
            } else  {
                if(documentMasterListAndIdMapping[element.document_master_id]){
                    employeeDocumentRecordMap[element.employeeno] = {}
                    employeeDocumentRecordMap[element.employeeno][documentMasterListAndIdMapping[element.document_master_id]['document_alias']] = element
                }
            }
        })

        let tempEmployeeDocumentDetails = [], tempSuccessList = [], tempFailureList = []
        zip.loadAsync(fileData).then( async function(fileArray){
            await Promise.all(Object.keys(fileArray.files).map(async fileName => {
                if(!fileName.includes('MACOSX')){
                    let validateResponse = await validateFileName(fileName)
                    let newFileName = ''
                    let fileNameSplitArray = fileName.split('/')
                    if(fileNameSplitArray.length == 2){
                        newFileName = fileNameSplitArray[1]
                    }else {
                        newFileName = fileNameSplitArray[0]
                    }
                    let nameArray = newFileName.split('&')
                    let employeeno = nameArray[0]
                    let document_alias = nameArray[1] ? nameArray[1].split('.')[0] : null
                    let fileVersion, newStatusId
                    let isAchrived = false;
                    if(employeeDocumentRecordMap && employeeDocumentRecordMap[employeeno] && employeeDocumentRecordMap[employeeno][document_alias]){
                        fileVersion = employeeDocumentRecordMap[employeeno][document_alias]['version'] + 1
                        isAchrived = true
                    } else {
                        fileVersion = 1
                    }
                    if(hropsRole === efilingConstant.HROPS_ADMIN_ROLE_EMP.HROPS1){
                        newStatusId = efilingConstant.status.PENDING_FOR_APPROVAL_BY_HROPS2
                    }
    
                    if(hropsRole === efilingConstant.HROPS_ADMIN_ROLE_EMP.HROPS2){
                        newStatusId = efilingConstant.status.PENDING_FOR_APPROVAL_BY_HROPS3
                    }
    
                    if(hropsRole === efilingConstant.HROPS_ADMIN_ROLE_EMP.HROPS3){
                        newStatusId = efilingConstant.status.APPROVED
                    }
                    if(!validateResponse.error){
                        await fileArray.files[fileName].async("ArrayBuffer").then(async function(arrayBuffer) {
                            let formData = new FormData();
                            formData.set('file',new Buffer.from(arrayBuffer, "binary").toString("base64"))
                            formData.set('employeeNo',employeeno)
                            formData.set('module','efiling')
                            formData.set('document_alias',document_alias)
                            formData.set('document_ext',fileName ? (fileName).split('.').pop() : '')
                            formData.set('version',fileVersion)
                            formData.set('bulkUpload','1')
                            let uploadFileResponse = await postUploadedFiles(formData)
                            if(uploadFileResponse.success){
                                tempSuccessList.push(newFileName)
                                if(isAchrived){
                                    let tempDocumentDetail = JSON.parse(JSON.stringify(employeeDocumentRecordMap[employeeno][document_alias]))
                                    tempDocumentDetail.status_id = efilingConstant.status.ARCHIVED
                                    tempDocumentDetail.remarks = ''
                                    tempEmployeeDocumentDetails.push(tempDocumentDetail)
                                }
                                if( uploadFileResponse && uploadFileResponse.data && uploadFileResponse.data.documentPath){
                                    let tempDocumentDetailInsert = {}
                                    tempDocumentDetailInsert.employeeno = employeeno

                                    tempDocumentDetailInsert.document_path = uploadFileResponse.data.documentPath
                                    tempDocumentDetailInsert.version = fileVersion
                                    tempDocumentDetailInsert.document_master_id = documentMasterListAndAliasMapping[document_alias]['id']
                                    tempDocumentDetailInsert.status_id = newStatusId
                                    tempDocumentDetailInsert.date_of_submission = new Date()
                                    tempDocumentDetailInsert.uploaded_by = loggedInEmployeeDetails['employeeno']
                                    tempDocumentDetailInsert.id = ''
                                    tempEmployeeDocumentDetails.push(tempDocumentDetailInsert)
                                }
                            } else {
                                tempFailureList.push(newFileName)
                            }
                        })
                    } else {
                        tempFailureList.push(newFileName)
                    }
                }
            }))

            let insertObjects = [], updateObjects = []
            tempEmployeeDocumentDetails.map(documentDetail => {
                if(documentDetail.id == ''){
                    insertObjects.push(documentDetail)
                } else {
                    updateObjects.push(documentDetail)
                }
            })

            if(insertObjects.length > 0){
                const insertResponse = await postEmployeeDocumentListFetchData(insertObjects)
            }

            if(updateObjects.length > 0){
                const updateResonse = await updateEmployeeDocumentListFetchData(updateObjects)
            }
            setSuccessList(tempSuccessList)
            setfailureList(tempFailureList)
            setFileData({})
            setIsLoading(false)
        })
        
    }

    const validateFileName = async (fileName) => {
        let validationResponse = {message: '', error: false}
        let nameArray = fileName.split('&')
        let employeeno = nameArray[0]
        let document_alias = nameArray[1] ? nameArray[1].split('.')[0] : null
        if(!document_alias || !Object.keys(documentMasterListAndAliasMapping).includes(document_alias)){
            validationResponse.message = "Invalid File Name"
            validationResponse.error = true
        }
        return validationResponse
    }

    const validateData = async () => {
        let validationResponse = {message: '', error: false}
        employeeDocumentDetails.forEach(element => {
            if(element.status_id === efilingConstant.status.APPROVED){
                if(element.remarks != ''){
                    if(!fileData[element.document_master_id]){
                        validationResponse.error = true
                        validationResponse.message = 'Document is required with remarks'
                        return false
                    }
                }
                if(fileData[element.document_master_id]){
                    if(element.remarks === ''){
                        validationResponse.error = true
                        validationResponse.message = 'Remark is required when modifying document in approved state'
                        return false
                    }
                }
            }
        })
        return validationResponse
    }

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackBarDetails({open:false,errorMessage:'',type:''});
    };

    
  return (
      <div>
            {(!isHavingPageViewAccess && !isAuthorised) 
                ? 
                    (isHavingPageViewAccess == null) 
                    ? 
                    <div className={classes.loader}><img src={logo} alt="loading..." /></div> 
                    :
                    <div> <h1 style={{margin:'0px'}}>Unauthorised </h1> <br></br> <span>You don't have access to view this page</span> </div> 
                :
            <div>
                {snackBarDetails && snackBarDetails.open ? 
                <Snackbar
                    anchorOrigin={{ vertical:snackBarDetails.positionVertical, horizontal:'center' }}
                    open={snackBarDetails.open}
                    autoHideDuration={4000}
                    onClose={handleSnackbarClose}
                >
                    <MuiAlert severity={snackBarDetails.type}>{snackBarDetails.errorMessage}</MuiAlert>
                </Snackbar> : (null)
                }
                <Grid container>
                    <Grid> 
                    <HeaderLayout
                        title='Bulk Documents Upload'
                        icon={<PeopleOutlineTwoTone/>}
                    />
                    </Grid>
                </Grid>
                { ( isLoading ) 
                    ? 
                    <div className={classes.loader}><img src={logo} alt="loading..." /></div>
                    : 
                    <Paper className={classes.pageContent}>
                        <Grid container>
                        <Grid item xs={5}> 
                            <h4>
                               Naming Convention : <br></br> <i>{`{employeeno}`}</i>&<i>{`{documentAlias}`}</i>.<i>{`{extension}`}</i>
                            </h4>
                            <h4 style={{ margin: '0px'}}>
                               Examples : 
                            </h4>
                            504961&aadhaar_card.pdf<br></br>504797&business_approval.htm
                            <br></br><br></br>
                            <h4 style={{ margin: '0px'}}>Documents Aliases : 
                            </h4>
                            {Object.keys(documentMasterListAndAliasMapping).map(alias => {
                                    return <div>{alias}, <br></br></div>  
                            })}
                        </Grid>
                        </Grid>
                        <Grid container 
                            justifyContent='center'
                        >
                            <Grid item xs={3} className={classes.gridItem}> 
                            <h5>
                                Upload File <br></br>  Allowed Format : .zip
                            </h5>
                            </Grid>
                            <Grid item xs={3} className={classes.gridItem}> 
                                <FormControl className={classes.formControl}>
                                    <input
                                        accept="application/zip, image/*"
                                        className={classes.input}
                                        id="contained-button-file"
                                        type="file"
                                        name="file"
                                        onChange={onFileChange}
                                        title="Select a file"
                                    />
                                    <label htmlFor="contained-button-file">
                                        <Button className={classes.uploadButton} component="span" variant="contained">
                                            Upload
                                        </Button>
                                    </label>
                                    { fileData && fileData.name ? 
                                        <div className={classes.deleteDiv}>
                                            {fileData.name}
                                            <div align="right">
                                                <span onClick={e=>handleSelectedFile(e)}>
                                                    <DeleteForeverOutlinedIcon className={classes.icon} />
                                                </span>
                                            </div>
                                        </div>
                                        : ''
                                    }
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Paper>
                }
                    <Grid
                        container
                        spacing={3}
                        className={classes.marginTop}
                        direction='row'
                        alignItems='center'
                        justifyContent='center'
                    >
                        <Grid item>
                            <Button
                                className={classes.button}
                                variant='contained'
                                size='large'
                                color='primary'
                                onClick={handleSubmit}
                                disabled={!isHavingPageEditAccess}
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                    <Paper>
                         <TableContainer>
                             <Table>
                                 <TableHead>
                                    <TableRow>
                                        <TableCell>Success List :</TableCell>
                                    </TableRow>
                                 </TableHead>
                                 <TableBody>
                                    { (successList && successList.length > 0) ? successList.map((element,key) => (
                                        <TableRow key={key}>
                                            <TableCell> {element}</TableCell>
                                        </TableRow>
                                    )) :
                                    <TableRow>
                                        <TableCell> </TableCell>
                                    </TableRow>}
                                 </TableBody>
                             </Table>
                         </TableContainer>
                         <TableContainer>
                             <Table>
                                 <TableHead>
                                    <TableRow>
                                        <TableCell>Failure List :</TableCell>
                                    </TableRow>
                                 </TableHead>
                                 <TableBody>
                                    { (failureList && failureList.length) > 0 ? failureList.map((element,key) => (
                                        <TableRow key={key}>
                                            <TableCell> {element}</TableCell>
                                        </TableRow>
                                    )) : 
                                    <TableRow>
                                        <TableCell> </TableCell>
                                    </TableRow>}
                                 </TableBody>
                             </Table>
                         </TableContainer>
                    </Paper>
            </div>
        }
      </div>
  )
}