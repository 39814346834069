// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* global styles for orgchart */
.orgchart-container {
  box-sizing: border-box;
  position: relative;
  margin: 10px;
  height: 700px;
  background-color: antiquewhite !important;
  border: 2px dashed #aaa;
  border-radius: 5px;
  overflow: auto;
  text-align: center;
}

.orgchart {
  box-sizing: border-box;
  display: inline-block;
  background-image: linear-gradient(90deg, rgba(200, 0, 0, 0.15) 10%, rgba(0, 0, 0, 0) 10%), linear-gradient(rgba(200, 0, 0, 0.15) 10%, rgba(0, 0, 0, 0) 10%);
  background-size: 10px 10px;
  border: 1px dashed rgba(0,0,0,0);
  padding: 20px 20px 0 20px;
}

.orgchart-container .hidden {
  display: none!important;
}

.orgchart-container > .oc-mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  text-align: center;
  background-color: rgba(0,0,0,0.3);
}

.orgchart-container > .oc-mask .spinner {
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
}

.orgchart-container > .oc-mask .spinner::before {
  width: 100px;
  height: 100px;
  border-width: 10px;
  border-radius: 50px;
  border-top-color: rgba(68, 157, 68, 0.8);
  border-bottom-color: rgba(68, 157, 68, 0.8);
  border-left-color: rgba(68, 157, 68, 0.8);
}
`, "",{"version":3,"sources":["webpack://./src/pages/OrgChart/Organization/ChartContainer.css"],"names":[],"mappings":"AAAA,+BAA+B;AAC/B;EACE,sBAAsB;EACtB,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,yCAAyC;EACzC,uBAAuB;EACvB,kBAAkB;EAClB,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,sBAAsB;EACtB,qBAAqB;EACrB,2JAA2J;EAC3J,0BAA0B;EAC1B,gCAAgC;EAChC,yBAAyB;AAC3B;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,QAAQ;EACR,SAAS;EACT,OAAO;EACP,YAAY;EACZ,kBAAkB;EAClB,iCAAiC;AACnC;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,mBAAmB;EACnB,wCAAwC;EACxC,2CAA2C;EAC3C,yCAAyC;AAC3C","sourcesContent":["/* global styles for orgchart */\n.orgchart-container {\n  box-sizing: border-box;\n  position: relative;\n  margin: 10px;\n  height: 700px;\n  background-color: antiquewhite !important;\n  border: 2px dashed #aaa;\n  border-radius: 5px;\n  overflow: auto;\n  text-align: center;\n}\n\n.orgchart {\n  box-sizing: border-box;\n  display: inline-block;\n  background-image: linear-gradient(90deg, rgba(200, 0, 0, 0.15) 10%, rgba(0, 0, 0, 0) 10%), linear-gradient(rgba(200, 0, 0, 0.15) 10%, rgba(0, 0, 0, 0) 10%);\n  background-size: 10px 10px;\n  border: 1px dashed rgba(0,0,0,0);\n  padding: 20px 20px 0 20px;\n}\n\n.orgchart-container .hidden {\n  display: none!important;\n}\n\n.orgchart-container > .oc-mask {\n  position: absolute;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  z-index: 999;\n  text-align: center;\n  background-color: rgba(0,0,0,0.3);\n}\n\n.orgchart-container > .oc-mask .spinner {\n  position: absolute;\n  top: calc(50% - 50px);\n  left: calc(50% - 50px);\n}\n\n.orgchart-container > .oc-mask .spinner::before {\n  width: 100px;\n  height: 100px;\n  border-width: 10px;\n  border-radius: 50px;\n  border-top-color: rgba(68, 157, 68, 0.8);\n  border-bottom-color: rgba(68, 157, 68, 0.8);\n  border-left-color: rgba(68, 157, 68, 0.8);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
