import React, { useState,useEffect } from 'react';
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CardHeader from '@material-ui/core/CardHeader';
import Card from '@material-ui/core/Card';
import HeaderLayout from '../HeaderLayout'
import PeopleOutlineTwoTone from '@material-ui/icons/PeopleOutlineTwoTone'
import SimpleAlerts from '../../Joiner/Alert'

import { useStyles } from './style'
import { Button, FormControl, Grid,Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, InputLabel, MenuItem, Select } from '@material-ui/core'
import { getActiveEmployeeListFetch, postMasterAdminAccess, getMasterAdminEmployeesFetch} from '../fetchData';

import { orange } from '@material-ui/core/colors'
import { withStyles } from '@material-ui/core/styles'
import logo from '../../../utils/load.gif'
import { scrollToViewHandler } from '../../../constants/CommonConstant';
import Autocomplete from '@material-ui/lab/Autocomplete';


const PreviousButton = withStyles(theme => ({
    root: {
      color: theme.palette.getContrastText(orange[500]),
      backgroundColor: orange[500],
      '&:hover': {
        backgroundColor: orange[700]
      }
    }
  }))(Button)

const INITIAL_FORM_OBJECT = {
    master_admin_access_employeeno: '',
    email_id: '',
    access_type: [],
    action_type: '',
    autoCompleteValue: null
}

const ACCESS_TYPES = ['HRIS','PEAK','HRIS_SHORT']

  

const MasterAdminAccessForm = ({handleSave,setNotification}) => {
    const classes = useStyles()
    const [expandDefault, setExpandDefault] = useState(false)
    const [formObj, setFormObj] = useState(INITIAL_FORM_OBJECT)
    const [activeEmployeeList, setActiveEmployeeList] = useState([])
    
    useEffect(() => {
        getActiveEmployeeList()
    },[])

    const getActiveEmployeeList = async () => {
        try {
            let response = await getActiveEmployeeListFetch()
            if(response.data){
                setActiveEmployeeList(response.data)
            }
        } catch (error) {
            throw error
        }
    }

    const handleChange = event => {
        setFormObj({ ...formObj, [event.target.name]: event.target.value })
    }

    const handleAutocompleteEmployeeno = newValue => {
        if(newValue){
            let accessType = [], action_type = 'insert'
            if(newValue.access_type != null){
                accessType = JSON.parse(newValue.access_type)
            }

            if(newValue.hma_status == 1){
                action_type = 'update'
            }
            setFormObj({ ...formObj, master_admin_access_employeeno : newValue.employeeno, email_id : newValue.company_provided_email_id, access_type: accessType, action_type: action_type, autoCompleteValue : newValue })
        } else if (newValue == null) {
            setFormObj({ ...formObj, master_admin_access_employeeno : '', email_id : '', action_type: '',autoCompleteValue:null })
        }
    }


    const handleReset = () => {
        setFormObj(INITIAL_FORM_OBJECT)
    }

    return (
            <Accordion
                expanded={expandDefault}
                onChange={() => setExpandDefault(!expandDefault)}
                className={classes.pageContent}
                style={{margin:'9px'}}
                
            >
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1a-content'
                id='panel1a-header'
                className={classes.pageContent}
                >                   
                    <Grid>
                        <Card>
                            <CardHeader
                                title="Add/Edit Master Access"
                                style={{ color: '#3f51b5'}}
                                titleTypographyProps={{variant:'subtitle1' }}
                            />
                        </Card>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails className={classes.pageContent}>
                <Grid container>
                <Paper elevation={2} className={classes.root}>
                    <form className={classes.formRoot} noValidate autoComplete='off'>
                        <Grid container>
                            <Grid container spacing={3}>
                                <Grid item xs={2} className={classes.gridItem}> 
                                <h5>
                                    Employee Name<span style={{color:'red'}}>*</span>:
                                </h5>
                                </Grid>
                                <Grid item xs={3} sm={12} md={3} style={{marginLeft:'4px'}}>
                                    <Autocomplete
                                        id="master-admin-emloyeeno"
                                        name="master_admin_access_employeeno"
                                        value={formObj.autoCompleteValue}
                                        options={activeEmployeeList}
                                        getOptionLabel={(option) => { return `${option.legalname} - ${option.employeeno}`}}
                                        getOptionSelected={(option, value) => { return option.employeeno === value.employeeno}}
                                        style={{ width: 300 }}
                                        renderInput={(params) => <TextField {...params} label="Employee" variant="outlined" />}
                                        onChange={(event, newValue) => {
                                            handleAutocompleteEmployeeno(newValue);
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={2} className={classes.gridItem}> 
                                <h5>
                                    Access Type<span style={{color:'red'}}>*</span>:
                                </h5>
                            </Grid>
                            <Grid item xs={4} sm={12} md={4}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel id='demo-simple-select-outlined-label'>
                                    Access Type
                                    </InputLabel>
                                    <Select
                                    labelId='demo-simple-select-outlined-label'
                                    id='demo-simple-select-outlined'
                                    name='access_type'
                                    multiple
                                    value={formObj.access_type || ''}
                                    onChange={e => handleChange(e)}
                                    >
                                    {ACCESS_TYPES.length > 0 &&
                                        ACCESS_TYPES.map((item, index) => (
                                        <MenuItem value={item} key={item}>
                                            {item}
                                        </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            spacing={3}
                            className={classes.marginTop}
                            direction='row'
                            alignItems='center'
                        >
                            <Grid item>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    size='small'
                                    style={{ marginLeft: 20 }}
                                    onClick={() => handleSave(formObj)}
                                    className={classes.button}
                                >
                                    {formObj.action_type == 'update' ? 'Modify' : 'Submit'}
                                </Button>
                                <PreviousButton
                                    variant='outlined'
                                    color='primary'
                                    className={classes.resetButton}
                                    size='small'
                                    onClick={() => handleReset()}
                                >
                                    Reset
                                </PreviousButton>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
                </Grid>
                </AccordionDetails>
            </Accordion>
    )
}


export default function MasterAdmin() { 
    const classes = useStyles()
    const [notification, setNotification] = useState({type: '',  message: ''})
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingTable, setIsLoadingTable] = useState(false)
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [masterAdminEmployeesList, setMasterAdminEmployeesList] = useState([])

    useEffect(() => {
        getMasterAdminEmployeesList()
    },[])

    const getMasterAdminEmployeesList = async () => {
        try {
            setIsLoadingTable(true)
            let response = await getMasterAdminEmployeesFetch()
            if(response.data){
                setMasterAdminEmployeesList(response.data)
            }
            setIsLoadingTable(false)
        } catch (error) {
            throw error
        }
    }

    const handleSave = async ( formData ) => {
        const validateResponse = validateFormData(formData)
        if(validateResponse.error){
            setNotification({type:'error',message:validateResponse.message})
            scrollToViewHandler('alerts')
        } else {
            try {
                setIsLoading(true)
                let response = {}
                response = await postMasterAdminAccess({formData})
                if(response.success){
                    setNotification({type:'success',message:response.message})
                    scrollToViewHandler('alerts')
                    getMasterAdminEmployeesList()
                }
                setIsLoading(false)
            } catch (error) {
                throw(error)
            }
        }
    }

    const validateFormData = (formData) => {
        let validateResponse = {error:false,message:''}
        
        if(formData.master_admin_access_employeeno.trim() == ''){
            validateResponse.error = true
            validateResponse.message = 'Please select employee'
            return validateResponse
        }
        if(Array.isArray(formData.access_type) && formData.access_type.length <= 0){
            validateResponse.error = true
            validateResponse.message = 'Please select access type'
            return validateResponse
        }

        return validateResponse
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };
    const closeAlert = () => {
        setNotification({type:'', message: ''})
    }

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid> 
                <HeaderLayout
                    title='Master Admin'
                    subTitle=''
                    icon={<PeopleOutlineTwoTone/>}
                />
                </Grid>
            </Grid>
            {
                notification.message ? (
                    <div className={classes.notification} id={'alerts'}>
                        <SimpleAlerts 
                            type={notification.type}
                            message={notification.message}
                            handleAlert={closeAlert}
                        />
                    </div>
                ) : (null)
            }
            { ( isLoading ) 
            ? 
                <div className={classes.loader}><img src={logo} alt="loading..." /></div>
            : 
                <MasterAdminAccessForm
                handleSave={handleSave}
                setNotification={setNotification}
                />
            }
            { ( isLoadingTable ) 
            ? 
            <div className={classes.loader}><img src={logo} alt="loading..." /></div>
            : 
            <Paper className={classes.pageContent} elevation={5}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell><b>Employee Name</b></TableCell>
                                <TableCell><b>Employeeno</b></TableCell>
                                <TableCell><b>Acess Type</b></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        { ( masterAdminEmployeesList && masterAdminEmployeesList.length > 0 ) ? masterAdminEmployeesList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((element,key)=>(
                            <TableRow key={key}>
                                <TableCell> 
                                    {element.legalname}
                                </TableCell>
                                <TableCell> 
                                    {element.employeeno}
                                </TableCell>
                                <TableCell> {element.access_type} </TableCell>
                            </TableRow>
                        )) : (
                            <TableRow>
                            <TableCell><b>No result found</b></TableCell>
                            </TableRow>
                        ) } 
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                rowsPerPageOptions={[10, 50 ,100,200, 250]}
                component="div"
                count={masterAdminEmployeesList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
            }
        </div>
    )

}
