import React, { Fragment, useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import { useStyles } from './style'
import { 
    Paper,
    FormControl,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    Divider
} from '@material-ui/core'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import moment from 'moment'
import SaveIcon from '@material-ui/icons/Save'
import EditIcon from '@material-ui/icons/Edit'
import UpdateIcon from '@material-ui/icons/Update'
import ReplayIcon from '@material-ui/icons/Replay'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import CircularProgress from '@material-ui/core/CircularProgress'
import { formatDate as formatDateFun } from '../../../utils/CommonFunction'

import SimpleAlerts from '../Alert'
import {
    deleteJoinerRecord,
    postWorkHistoryData
} from '../fetchData'
import {
    onlyNumber,
    checkAmountRegEx,
    checkExperienceRegEx
} from '../../../utils/Validation'
import {
    joinerConst
} from '../../../constants/JoinerConstant'
import DialogComponent from '../../../components/Shared/Elements/DialogComponent'
import SelectExperience from './SelectExperience'
import { scrollToViewHandler } from '../../../constants/CommonConstant'

const tableColumns = [
    { id: 'Company Name', label: 'Company Name', minWidth: 100 },
    { id: 'Company Address', label: 'Company Address', minWidth: 100 },
    { id: 'Designation', label: 'Designation', minWidth: 150 },
    { id: 'Service Start Date', label: 'Service Start Date', minWidth: 50 },
    { id: 'Service End Date', label: 'Service End Date', minWidth: 50 },
    { id: 'Last Drawn Annual Salary', label: 'Last Drawn Annual Salary', minWidth: 50 },
    { id: 'Reason For Leaving', label: 'Reason For Leaving', minWidth: 50 },
    {
        id: 'Edit',
        label: '',
        minWidth: 10,
        align: 'left',
    },
    {
        id: 'Delete',
        label: '',
        minWidth: 10,
        align: 'left',
    }
]
const viewsDate = ["year", "month"]
const formatDate = "MM-yyyy"
const organizationName = 'REA India'

const optionsInYears = []
const optionsInMonths = []
for (let index = 0; index <= 45; index++) {
    if (index < 12) {
        optionsInMonths.push({id:index, value:index})
    }
    optionsInYears.push({id:index, value:index})
}

const WorkHistory = ({
    offerData,
    joinerWorkHistoryData,
    handleChildData=()=>{},
    handleChildDataValidation=()=>{},
    handleNotification=()=>{}
}) => {
    const {
        maxWorkHistoryRecord
    } = joinerConst
    const classes = useStyles()
    const loading = false
    const [joinerWorkHistoryDataList, setJoinerWorkHistoryData] = useState(joinerWorkHistoryData)
    const [notification, setNotification] = useState({type: '',  message: ''})
    const [isUpdate, setIsUpdate] = useState(0)
    const [deleteRecordId,setDeleteRecordId] = useState('')
    const dialogTitle = `Confirm`
    const [openDialog, setOpenDialog] = useState(false)
    const [offerDataDetail,setOfferDataDetail] = useState(offerData)
    const [isSaveExperience, setIsSaveExperience] = useState(false)

    const getDiffInMonths = ({
        startDate,
        endDate
    }) => {
        if (!moment(startDate).isValid() || !moment(endDate).isValid()) {
            return 0
        }

        return moment(endDate).diff(moment(startDate), 'months')
    }
    
    const getSumOfTotalExpInMonths = (list = []) => {
        if (!list.length) {
            return 0
        }
        let months = 0
        list.forEach(({
            id,
            service_start_date, 
            service_end_date
        }) => {
            if (isUpdate !== id) {
                months += getDiffInMonths({
                    startDate: service_start_date,
                    endDate: service_end_date
                })
            }
        })

        return months
    }

    const handleDialogOpen = (id) => {
        setOpenDialog(true)
        if (id) {
            setDeleteRecordId(id)
        } else {
            setIsSaveExperience(true)
        }
    }
    const handleDialogClose = (val) => {
        if (val === true) {
            if (deleteRecordId) {
                handleRecordDelete(deleteRecordId)
            } else {
                handleSaveData()
            }
        }
        setOpenDialog(false)
    }

    const {
        joiner_total_experience,
        joiner_previous_experience,
        date_of_joining,
        proposed_date_of_joining,
    } = offerData
    let proposedDateOfJoining = (proposed_date_of_joining && proposed_date_of_joining !== '0000-00-00 00:00:00') ? moment(
        formatDateFun(proposed_date_of_joining)
      ).format('DD-MMM-YYYY') : moment(
        formatDateFun(date_of_joining)
      ).format('DD-MMM-YYYY')

    const maxDoeDate = moment(proposedDateOfJoining).format(
    'YYYY-MM-DD'
    )

    const initObj = {
        id:null,
        companyName: '',
        companyAddress: '',
        designation: '',
        serviceStartDate: null,
        serviceEndDate: null,
        grossSalary:'',
        reasonForLeaving:''
    }

    const experience = 
        {
            previousExperience: joiner_previous_experience,
            totalExperience: joiner_total_experience
        }
    const [experienceObj, setExperienceObj] = useState(experience)

    const {
        previousExperience,
        totalExperience
    } = experienceObj

    let initErrObj = {}
    for (let k in initObj) {
        initErrObj[k] = {status: false, text: ''}
    }

    for (let k in experience) {
        initErrObj[k] = {status: false, text: ''}
    }

    initErrObj['prevYear'] = {status: false, text: ''}
    initErrObj['prevMonth'] = {status: false, text: ''}
    initErrObj['totalYear'] = {status: false, text: ''}
    initErrObj['totalMonth'] = {status: false, text: ''}

    const [workHistoryObj, setWorkHistoryObj] = useState(initObj)
    const [localErrObj, setLocalErrObj] = useState(initErrObj)

    const {
        companyName,
        companyAddress,
        designation,
        serviceStartDate,
        serviceEndDate,
        grossSalary,
        reasonForLeaving
    } = workHistoryObj

    useEffect(() => {
        const isCount = joinerWorkHistoryDataList.length ? true : false
        handleChildData('joinerWorkHistoryData', joinerWorkHistoryDataList)
        handleChildDataValidation('joinerWorkHistoryData', {status: !isCount, text: ''})
    }, [joinerWorkHistoryDataList])

    const handleChange = (e) => {
        let key = e.target.name
        let value = e.target.value
        setWorkHistoryObj({...workHistoryObj, [key]:value})
        let {text, status} = checkWorkHistoryDataError(key, value)
        setLocalErrObj({...localErrObj, [key]: {text, status}})
        closeAlert()
    }

    const handleInput = (e) => {
        switch (e.target.name) {
            case "previousExperience":
            case "totalExperience":
                if (!onlyNumber(e.which)) {
                    e.preventDefault()
                }
                break
            default:
                break
        }
    }

    const handleDateChange = (value, key) => {
        setWorkHistoryObj({...workHistoryObj, [key]:value})
        if(key==='serviceStartDate'){
            let {text, status} = checkWorkHistoryDataError(key, value)
            let {serviceEndText, serviceEndStatus} = checkWorkHistoryDataError('serviceEndDate', serviceEndDate)
            setLocalErrObj({...localErrObj, [key]: {text, status}, serviceEndDate: {text: serviceEndText,status: serviceEndStatus}})
        } else {
            let {text, status} = checkWorkHistoryDataError(key, value)
            let {serviceStartText, serviceStartStatus} = checkWorkHistoryDataError('serviceStartDate', serviceStartDate)
            setLocalErrObj({...localErrObj, [key]: {text, status}, serviceStartDate: {text: serviceStartText,status: serviceStartStatus}})
        }
    }

    const checkWorkHistoryDataError = (key, value) => {
        let text = ''
        let status = false
        switch (key) {
            case "previousExperience":
                if (!checkExperienceRegEx(value)) {
                    status = true
                    text = `Invalid previous experience`
                } else if (parseFloat(value) > parseFloat(totalExperience)) {
                    status = true
                    text = `Previous experience can't be greater than total experience`
                }
                break
            case "totalExperience":
                if (!checkExperienceRegEx(value)) {
                    status = true
                    text = `Invalid total experience`
                } else if (parseFloat(value) < parseFloat(previousExperience)) {
                    status = true
                    text = `Total experience can't be less than previous experience`
                }
                break
            case "companyName":
                if (value === '') {
                    status = true
                    text = `Invalid company name`
                }
                break
            case "companyAddress":
                if (value === '') {
                    status = true
                    text = `Invalid company address`
                }
                break
            case "designation":
                if (value === '') {
                    status = true
                    text = `Invalid designation`
                }
                break
            case "serviceStartDate":
                if (value === '' || !moment(value).isValid()) {
                    status = true
                    text = `Invalid service start date`
                } else if (moment(value).isAfter(serviceEndDate)) {
                    status = true
                    text = `Service start date can not be after end date`
                }
                break
            case "serviceEndDate":
                if (value === '' || !moment(value).isValid()) {
                    status = true
                    text = `Invalid service end date`
                } else if (moment(value).isBefore(serviceStartDate)) {
                    status = true
                    text = `Service end date can not be before start date`
                }
                break
            case "grossSalary":
                if (!checkAmountRegEx(value)) {
                    status = true
                    text = `Invalid last drawn annual salary`
                }
                break
            case "reasonForLeaving":
                if (value === '' || value.length <3) {
                    status = true
                    text = `Reason for leaving should be at least 3 characters`
                }
                break
            default:
                break
        }

        return {text, status}
    }

    const handleSaveData = () => {
        let text = ``
        if (joinerWorkHistoryDataList.length >= maxWorkHistoryRecord) {
            text = `You can add upto ${maxWorkHistoryRecord} records!`
            setNotification({message: text, type:'error'})
        } else {
            let errObj = {}
            let finalStatus
            let generalErrMsg = ''
            let saveExpObj = {}
            if (!isSaveExperience) {
                for (let key in workHistoryObj) {
                    let {text, status} = checkWorkHistoryDataError(key, workHistoryObj[key])
                    errObj[key] = {text, status}
                    if (status) {
                        finalStatus = status
                    }
                }
                const startDate = moment(serviceStartDate).format('YYYYMM')
                const endDate = moment(serviceEndDate).format('YYYYMM')
                let errFlag = false
                let totalExpInRecords = 0
                joinerWorkHistoryDataList.forEach((workData)=>{
                    const historyStartDate = moment(workData.service_start_date).format('YYYYMM')
                    const historyEndDate = moment(workData.service_end_date).format('YYYYMM')
                    if(!isUpdate || (isUpdate && workHistoryObj.id !== workData.id)){
                        totalExpInRecords += Number(moment(workData.service_end_date).startOf('month').diff(moment(workData.service_start_date).startOf('month'),'months'))
                        if(moment(historyStartDate).isBetween(startDate, endDate, undefined, '()') ||
                        moment(startDate).isBetween(historyStartDate, historyEndDate, undefined, '()') ||
                        moment(endDate).isBetween(historyStartDate, historyEndDate, undefined, '()') ||
                        moment(historyEndDate).isBetween(startDate, endDate, undefined, '()')
                        ){
                            errObj = {...errObj,serviceStartDate:{text:'Invalid Date Period (Already exists in work history record)',status:true},
                            serviceEndDate:{text:'Invalid Date Period (Already exists in work history record)',status:true}}
                            errFlag = true
                        }
                        if(errFlag){
                            finalStatus = 'Invalid Date Period (Already exists in work history record)'
                        }
                    }
                })
                const totalExperienceInMonths = offerDataDetail.joiner_total_experience * 12
                if(!errFlag && totalExperienceInMonths < (totalExpInRecords + moment(serviceEndDate).startOf('month').diff(moment(serviceStartDate).startOf('month'),'month'))){
                    errObj = {...errObj,serviceStartDate:{text:'Invalid Date Period (Cannot exceed total experience)',status:true},
                    serviceEndDate:{text:'Invalid Date Period (Cannot exceed total experience)',status:true}}
                    errFlag = true
                    finalStatus = 'Invalid Date Period (Cannot exceed total experience)'
                } 
            } else {
                finalStatus = false
                if (prevYear === '') {
                    errObj['prevYear'] = {text: 'Select year', status: true}
                    finalStatus = true
                }
        
                if (prevMonth === '') {
                    errObj['prevMonth'] = {text: 'Select month', status: true}
                    finalStatus = true
                }

                if (prevYear === 0 && prevMonth === 0 && !finalStatus) {
                    finalStatus = true
                     generalErrMsg = `Year and month can't be 0 at the same time`
                }
        
                if (totalYear === '') {
                    errObj['totalYear'] = {text: 'Select year', status: true}
                    finalStatus = true
                }
        
                if (totalMonth === '') {
                    errObj['totalMonth'] = {text: 'Select month', status: true}
                    finalStatus = true
                }

                if (totalYear === 0 && totalMonth === 0 && !finalStatus) {
                    finalStatus = true
                    generalErrMsg = `Year and month can't be 0 at the same time`
                }

                if (!finalStatus) {
                    let prevExpInMonths = calculateMonths(prevYear, prevMonth)
                    let totalExpInMonths = calculateMonths(totalYear, totalMonth)
                    if (parseFloat(prevExpInMonths) > parseFloat(totalExpInMonths)) {
                        finalStatus = true
                        generalErrMsg = `Previous work experience can't be greater than total experience`
                    }
                    
                    if (parseFloat(totalExpInMonths) < parseFloat(prevExpInMonths)) {
                        finalStatus = true
                        generalErrMsg = `Total work experience can't be less than previous experience`
                    }

                    if (!finalStatus) {
                        saveExpObj = {
                            previousExperience: String(prevExpInMonths),
                            totalExperience: String(totalExpInMonths)
                        }
                        //setExperienceObj(saveExpObj)
                    }
                }
            }
            setLocalErrObj({...localErrObj,...errObj})
            if (finalStatus) {
                text = generalErrMsg || `There is some error in the form!`
                setNotification({message: text, type:'error'})
            } else {
                if (!checkTotalExperience(workHistoryObj)) {
                    setNotification({message: `The total experience should not be less than total work history.`, type:'error'})
                } else {
                    handleApiCall(workHistoryObj, saveExpObj, isSaveExperience) 
                }
            }
        }
    }

    const checkTotalExperience = (saveExpObj) => {
        let totalExp =  getSumOfTotalExpInMonths(joinerWorkHistoryData) || 0
        const { serviceEndDate, serviceStartDate } = saveExpObj
        
        let recordExp =  getDiffInMonths({
            startDate: serviceStartDate,
            endDate: serviceEndDate
        })
        console.log(totalExp, recordExp, totalExperience)
        if (parseFloat(totalExp + recordExp) > parseFloat(totalExperience)) {
            return false
        }

        return true
    }

    const handleApiCall = async (dataObj, saveExpObj, isSaveExperience) => {
        let response = {}
        if(!isSaveExperience){
            response = await postWorkHistoryData({workHistoryList: [dataObj], experienceOnly: false})
        } else {
            response = await postWorkHistoryData({previousExperience: saveExpObj.previousExperience, totalExperience: saveExpObj.totalExperience, experienceOnly: true})
        }
        const { 
            success=false, 
            offerData={},
            joinerWorkHistoryData=[] 
        } = response || {}
        let text = ``
        let type = ``
        if (success) {
            setOfferDataDetail(offerData)
            setExperienceObj({
                previousExperience: offerData.joiner_previous_experience,
                totalExperience: offerData.joiner_total_experience
            })
            setWorkHistoryObj(initObj)
            setJoinerWorkHistoryData(joinerWorkHistoryData)
            setOfferDataDetail(offerData)
            handleNotification({status: false, text: ''})
            text = dataObj.id || totalExperience ? 'Record updated successfully!' : 'Record added successfully!'
            type = `success`
        } else {
            text = `Something went wrong!`
            type = `error`
        }
        setNotification({message: text, type})
        setIsUpdate(false)
        setIsSaveExperience(false)
    }

    const handleReset = () => {
        setLocalErrObj(initErrObj)
        setWorkHistoryObj(initObj)
        setIsUpdate(false)
        setNotification({message: '', type:''})
        handleNotification({status: false, text: ''})
    }

    const handleRecordEdit = (e, recId) => {
        scrollToViewHandler('main-form-grid-div')
        handleNotification({status: false, text: ''})
        setNotification({message: '', type: ''})
        const updateRec = getWorkHistoryRecordById(recId)
        if (updateRec) {
            const {
                id=null,
                company_name='',
                service_start_date=null,
                service_end_date=null,
                company_address='',
                designation='',
                gross_salary='',
                reason_for_leaving='',
            } = updateRec
            const updateObj = {
                id,
                companyName: company_name,
                companyAddress: company_address,
                designation: designation,
                serviceStartDate: service_start_date,
                serviceEndDate: service_end_date,
                grossSalary: gross_salary,
                reasonForLeaving: reason_for_leaving,
            }
            setWorkHistoryObj(updateObj)
            setIsUpdate(recId)
            setLocalErrObj(initErrObj)
        }
    }

    const getWorkHistoryRecordById = (id) => {
        return joinerWorkHistoryDataList.find(obj => obj.id === id)
    }

    const handleRecordDelete = async (id) => {
        let text = ``
        let type = ``
        setLocalErrObj(initErrObj)
        setWorkHistoryObj(initObj)
        setIsUpdate(false)
        if (id) {
            let response = {}
            response = await deleteJoinerRecord({id, modelName:'work'})
            const { 
                success=false, 
                joinerWorkHistoryData=[] 
            } = response || {}
            
            if (success) {
                setJoinerWorkHistoryData(joinerWorkHistoryData)
                text = `Record deleted successfully!`
                type = `success`
            } else {
                text = `Something went wrong!`
                type = `error`
            }
        }
        handleNotification({status: false, text: ''})
        setNotification({message: text, type})
    }

    const closeAlert = () => {
        setNotification({type:'', message: ''})
    }

    /*
    ! Stale function to check experience records
    const checkCompanyDuration = (selectedDate, key) => {
        let flag = true
        selectedDate =  moment(selectedDate).format('YYYYMM')
        if (joinerWorkHistoryDataList.length) {
            joinerWorkHistoryDataList.forEach(({
                id,
                service_start_date,
                service_end_date
            }) => {
                if (Number(isUpdate) !== Number(id)) {
                    let recStartDate = moment(service_start_date).format('YYYYMM')
                    let recEndDate = moment(service_end_date).format('YYYYMM')
                    if (key === 'serviceStartDate' && (recStartDate === selectedDate || (selectedDate > recStartDate && selectedDate < recEndDate))) {
                        flag = false
                    }
                    if (key === 'serviceEndDate' && (recEndDate === selectedDate || (selectedDate > recStartDate && selectedDate < recEndDate))) {
                        flag = false
                    }
                }
            })
        }
        return flag
    }
    */

    const calculateYearAndMonth = (monthsCount) => {
        if (!monthsCount) {
            return {year: '', month: ''}
        }
        let month = monthsCount % 12
        let year =  Math.floor(monthsCount/12)
        return {year, month}
    }

    const displayYearAndMonth = (monthsCount) => {
        let {year, month} = calculateYearAndMonth(monthsCount)
        year += year > 1 ? ` years` : ` year`
        month += month > 1 ? ` months` : ` month`
        return `${year}, ${month}`
    }

    const calculateMonths = (yearCount, monthCount) => {
        return (yearCount * 12) + monthCount
    }
        
    const prevExpObj = calculateYearAndMonth(previousExperience)
    const totalExpObj = calculateYearAndMonth(totalExperience)

    const [prevYear, setPrevYear] = useState(prevExpObj.year)
    const [prevMonth, setPrevMonth] = useState(prevExpObj.month)
    const [totalYear, setTotalYear] = useState(totalExpObj.year)
    const [totalMonth, setTotalMonth] = useState(totalExpObj.month)

    const handlePrevYear = (val) => {
        setPrevYear(val)
        setLocalErrObj({...localErrObj, prevYear: {text: '', status: false} })
    }

    const handlePrevMonth = (val) => {
        setPrevMonth(val)
        setLocalErrObj({...localErrObj, prevMonth: {text: '', status: false} })
    }

    const handleTotalYear = (val) => {
        setTotalYear(val)
        setLocalErrObj({...localErrObj, totalYear: {text: '', status: false} })
    }

    const handleTotalMonth = (val) => {
        setTotalMonth(val)
        setLocalErrObj({...localErrObj, totalMonth: {text: '', status: false} })
    }

    return (
        <Fragment>
            <Grid container spacing={2} className={classes.root}>
                <Grid item sm={10}>
                    <Paper className={classes.paperFirst}>
                        {
                            notification.message ? (
                            <SimpleAlerts 
                                type={notification.type}
                                message={notification.message}
                                handleAlert={closeAlert}
                            />
                            ) : (null)
                        }
                        <div style={{color: "red", float: "left", fontSize: "11px", paddingTop: "10px"}}>
                            <sup>*</sup> Kindly read general instructions before filling in the information on this page
                        </div>
                        <Grid id="main-form-grid-div" container spacing={2} style={{marginBottom:"20px"}}>
                            <Grid item sm={3} style={{textAlign:"left", marginLeft: "8px"}} >
                                <FormControl className={classes.formControlPExp}>
                                    <span style={{marginLeft: "8px"}} >Work Experience 
                                        <br></br><label style={{fontSize: "9px"}}>(with organization prior to joining {organizationName})</label>
                                    </span>
                                </FormControl>
                                <br/>
                                
                                <FormControl className={classes.formControlPExp}>
                                    <SelectExperience 
                                        label={`Year`}
                                        options={optionsInYears}
                                        onChangeEvent={handlePrevYear}
                                        defaultVal={prevYear}
                                        errorText={localErrObj.prevYear.text}
                                    />
                                </FormControl>
                                <FormControl className={classes.formControlPExp}>
                                    <SelectExperience 
                                        label={`Month`}
                                        options={optionsInMonths}
                                        onChangeEvent={handlePrevMonth}
                                        defaultVal={prevMonth}
                                        errorText={localErrObj.prevMonth.text}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item sm={3} style={{textAlign:"left", marginLeft: "8px"}}>
                                <FormControl className={classes.formControlPExp}>
                                    <div style={{marginLeft: "8px"}}>Total experience
                                    <br></br><label style={{fontSize: "9px"}}>(Overall experience)</label></div>
                                </FormControl>
                                <br/>
                                
                                <FormControl className={classes.formControlPExp}>
                                    <SelectExperience 
                                        label={`Year`}
                                        options={optionsInYears}
                                        onChangeEvent={handleTotalYear}
                                        defaultVal={totalYear}
                                        errorText={localErrObj.totalYear.text}
                                        //isDisabled={totalExperience ? true : false}
                                    />
                                </FormControl>
                                <FormControl className={classes.formControlPExp}>
                                    <SelectExperience 
                                        label={`Month`}
                                        options={optionsInMonths}
                                        onChangeEvent={handleTotalMonth}
                                        defaultVal={totalMonth}
                                        errorText={localErrObj.totalMonth.text}
                                        //isDisabled={totalExperience ? true : false}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item sm={3}>
                                <FormControl className={classes.formControlAddBtn}>
                                    
                                    {
                                        offerDataDetail.joiner_total_experience && offerDataDetail.joiner_previous_experience 
                                        ? <Button
                                        variant="contained"
                                        color="secondary"
                                        size="small"
                                        className={classes.button}
                                        startIcon={<UpdateIcon />}
                                        onClick={e => handleDialogOpen()}
                                        disabled={false}
                                    >UPDATE</Button> : <Button
                                    variant="contained"
                                    color="secondary"
                                    size="small"
                                    className={classes.button}
                                    startIcon={<SaveIcon />}
                                    onClick={e => handleDialogOpen()}
                                    disabled={false}
                                >SAVE</Button>
                                    }
                                    
                                </FormControl>
                            </Grid>
                        </Grid>
                            {offerDataDetail.joiner_total_experience && offerDataDetail.joiner_previous_experience  ? <div>
                            <Grid container spacing={2}>
                                <FormControl className={classes.formControl}>
                                    <TextField
                                        autoComplete="off"
                                        required={true}
                                        label="Company Name"
                                        placeholder="Company Name"
                                        value={companyName}
                                        name="companyName"
                                        onChange={e => handleChange(e)}
                                        onKeyPress={e => handleInput(e)}
                                        inputProps={{ minLength:2, maxLength:100 }}
                                        error={localErrObj.companyName.status}
                                        helperText={localErrObj.companyName.text}
                                    />
                                </FormControl>
                                <FormControl className={classes.formControl}>
                                    <TextField
                                        autoComplete="off"
                                        required={true}
                                        label="Company Location"
                                        placeholder="Company Location"
                                        value={companyAddress}
                                        name="companyAddress"
                                        onChange={e => handleChange(e)}
                                        onKeyPress={e => handleInput(e)}
                                        inputProps={{ minLength:2, maxLength:100 }}
                                        error={localErrObj.companyAddress.status}
                                        helperText={localErrObj.companyAddress.text}
                                    />
                                </FormControl>
                                <FormControl className={classes.formControl}>
                                    <TextField
                                        autoComplete="off"
                                        required={true}
                                        label="Designation"
                                        placeholder="Designation"
                                        value={designation}
                                        name="designation"
                                        onChange={e => handleChange(e)}
                                        onKeyPress={e => handleInput(e)}
                                        inputProps={{ minLength:2, maxLength:100 }}
                                        error={localErrObj.designation.status}
                                        helperText={localErrObj.designation.text}
                                    />
                                </FormControl>
                                <FormControl className={classes.formControlDate}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        label="Service Start From"
                                        views={viewsDate}
                                        format={formatDate}
                                        clearable={false}
                                        name="serviceStartDate"
                                        value={serviceStartDate}
                                        onChange = {val => handleDateChange(val, 'serviceStartDate')}
                                        disableFuture={true}
                                        inputProps={{ readOnly: true }}
                                        required={true}
                                        error={localErrObj.serviceStartDate.status}
                                        helperText={localErrObj.serviceStartDate.text}
                                        style={{width:'200px'}}
                                    />
                                    </MuiPickersUtilsProvider>
                                </FormControl>
                                <FormControl className={classes.formControlDate}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        views={viewsDate}
                                        label="Service End To"
                                        format={formatDate}
                                        clearable={false}
                                        name="serviceEndDate"
                                        value={serviceEndDate}
                                        onChange = {val => handleDateChange(val, 'serviceEndDate')}
                                        // disableFuture={false}
                                        maxDate={maxDoeDate}
                                        inputProps={{ readOnly: true }}
                                        required={true}
                                        error={localErrObj.serviceEndDate.status}
                                        helperText={localErrObj.serviceEndDate.text}
                                    />
                                    </MuiPickersUtilsProvider>
                                </FormControl>
                                <FormControl className={classes.formControl}>
                                    <TextField
                                        autoComplete="off"
                                        required={true}
                                        label="Last Drawn Annual Salary (in INR)"
                                        placeholder="Last Drawn Annual Salary (in INR)"
                                        value={grossSalary}
                                        name="grossSalary"
                                        onChange={e => handleChange(e)}
                                        onKeyPress={e => handleInput(e)}
                                        inputProps={{ minLength:1, maxLength:10 }}
                                        error={localErrObj.grossSalary.status}
                                        helperText={localErrObj.grossSalary.text}
                                        style={{width:"300px"}}
                                    />
                                </FormControl>
                                <FormControl className={classes.formControl}>
                                    <TextField
                                        autoComplete="off"
                                        required={true}
                                        label="Reason For Leaving"
                                        placeholder="Reason For Leaving"
                                        value={reasonForLeaving}
                                        name="reasonForLeaving"
                                        onChange={e => handleChange(e)}
                                        onKeyPress={e => handleInput(e)}
                                        inputProps={{ minLength:2, maxLength:1000 }}
                                        error={localErrObj.reasonForLeaving.status}
                                        helperText={localErrObj.reasonForLeaving.text}
                                    />
                                </FormControl>
                        </Grid>
                        <Grid container spacing={2} className={classes.formControlAddBtn}>
                            <FormControl className={classes.formControlAddBtn}>
                                {
                                    isUpdate ? (
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            size="small"
                                            className={classes.button}
                                            startIcon={<UpdateIcon />}
                                            onClick={handleSaveData}
                                            disabled={
                                                (companyName &&
                                                companyAddress &&
                                                designation &&
                                                serviceStartDate &&
                                                serviceEndDate &&
                                                grossSalary &&
                                                reasonForLeaving &&
                                                !localErrObj.companyName.status &&
                                                !localErrObj.companyAddress.status &&
                                                !localErrObj.designation.status &&
                                                !localErrObj.serviceStartDate.status &&
                                                !localErrObj.serviceEndDate.status &&
                                                !localErrObj.grossSalary.status &&
                                                !localErrObj.reasonForLeaving.status
                                                ) ? false : true
                                            }
                                        >
                                        Update
                                        {loading && (
                                            <CircularProgress size={24} className={classes.buttonProgress} />
                                        )}
                                        </Button>
                                    ) : (
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            size="small"
                                            className={classes.button}
                                            startIcon={<SaveIcon />}
                                            onClick={handleSaveData}
                                            disabled={
                                                (companyName &&
                                                companyAddress &&
                                                designation &&
                                                serviceStartDate &&
                                                serviceEndDate &&
                                                grossSalary &&
                                                reasonForLeaving &&
                                                !localErrObj.companyName.status &&
                                                !localErrObj.companyAddress.status &&
                                                !localErrObj.designation.status &&
                                                !localErrObj.serviceStartDate.status &&
                                                !localErrObj.serviceEndDate.status &&
                                                !localErrObj.grossSalary.status &&
                                                !localErrObj.reasonForLeaving.status
                                                ) ? false : true
                                            }
                                        >
                                        Add
                                        {loading && (
                                            <CircularProgress size={24} className={classes.buttonProgress} />
                                        )}
                                        </Button>
                                    )
                                }
                            </FormControl>
                            <FormControl className={classes.formControlAddBtn}>
                            <Button
                                variant="contained"
                                size="small"
                                className={classes.button}
                                startIcon={<ReplayIcon />}
                                onClick={handleReset}
                                disabled={loading}
                            >
                            Reset
                            </Button>
                            </FormControl>
                        </Grid>
                        </div> : <div></div>}
                    </Paper>
                    <Divider light />
                    <Paper className={classes.root}>
                        <TableContainer className={classes.container}>
                            <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={4} style={{fontWeight:"bold"}}>
                                    Work Experience<label style={{fontSize: "8px"}}>(with organization prior to joining {organizationName})</label> :- &nbsp;
                                    {` ${ previousExperience ? displayYearAndMonth(previousExperience) : ''}`} 
                                    </TableCell>
                                    <TableCell colSpan={5} style={{fontWeight:"bold"}}>
                                        Total Experience :- &nbsp;
                                        {totalExperience ? ` ${displayYearAndMonth(totalExperience)}` : ''}</TableCell>
                                </TableRow>
                                <TableRow>
                                {tableColumns.map((column) => (
                                    <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                    size={'small'}
                                    variant={'head'}
                                    >
                                    {column.label}
                                    </TableCell>
                                ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                    {
                                        joinerWorkHistoryDataList.map(({
                                            id,
                                            company_name,
                                            company_address,
                                            designation,
                                            service_start_date,
                                            service_end_date,
                                            gross_salary,
                                            reason_for_leaving,
                                        }, index) => {
                                            return (
                                                <TableRow hover tabIndex={-1} key={index}>
                                                    <TableCell align={'left'} size={'small'}>
                                                        {company_name}
                                                    </TableCell>
                                                    <TableCell align={'left'} size={'small'}>
                                                        {company_address}
                                                    </TableCell>
                                                    <TableCell align={'left'} size={'small'}>
                                                        {designation}
                                                    </TableCell>
                                                    <TableCell align={'left'} size={'small'}>
                                                        {moment(service_start_date).format('MMM-YYYY')}
                                                    </TableCell>
                                                    <TableCell align={'left'} size={'small'}>
                                                        {moment(service_end_date).format('MMM-YYYY')}
                                                    </TableCell>
                                                    <TableCell align={'left'} size={'small'}>
                                                        {gross_salary}
                                                    </TableCell>
                                                    <TableCell align={'left'} size={'small'}>
                                                        {reason_for_leaving}
                                                    </TableCell>
                                                    <TableCell align={'left'} size={'small'}>
                                                        <div onClick={e=>handleRecordEdit(e, id)}>
                                                            <EditIcon />
                                                        </div>
                                                    </TableCell>
                                                    <TableCell align={'left'} size={'small'}>      
                                                        <div onClick={e=>handleDialogOpen(id)}>
                                                            <DeleteForeverIcon />
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                            </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Grid>
                <Grid item sm={2}>
                    <Paper className={classes.paperGeneral}>
                        <div>
                            <ul>
                                <li className="unorder-list">General Instructions:</li>
                                <li className="unorder-list">&nbsp;</li>
                                <li>In case you are not a fresher, kindly provide your past work experience details in reverse chronological order i.e. start with your most recent employer prior to joining REA India and so on.</li>
                                <li>To move ahead, click SAVE & NEXT</li>
                            </ul>
                        </div>
                        <DialogComponent 
                            openDialog={openDialog}
                            dialogTitle={dialogTitle}
                            dialogContentText={
                                offerDataDetail.joiner_previous_experience && offerDataDetail.joiner_total_experience ?
                                'Are you sure you want to update this work history record?' :
                                'Are you sure you want to save this work experience record?'
                            }
                            handleDialogClose={handleDialogClose}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </Fragment>
    )
}

export default WorkHistory