import React from "react"
import { Grid, Card, Paper } from "@material-ui/core"
import { useStyles } from "./style"
import ReportTable from "./ReportTable"
import NoRecordToDisplay from "./NoRecordToDisplay"

import {
    listingSort,
    getComparator
} from "../../../constants/CommonConstant"

import { getSortBy } from "./Comparison"

const youAreAwesomeCols = {
    timeFrame: "Time Frame",
    tabp_name: "TABP",
    tabp: "TABP Ecode",
    department_name: "Department",
    function_name: "Sub Department",
    grade: "Grade",
    designation: "Designation",
    location_name: "Location",
    rm_name: "Reporting Manager",
    reportingManager: "RM Ecode",
    
    mappedHeadCount: "Actual Headcount Mapped",
    totalNoOfCardExchanged: "Total No. of Cards Exchanged",
    percentOfCardExchanged: "% of Total Cards exchanged (out of actual HC)",

    uniqueCardSenderCount: "Unique Cards Senders",
    percentOfUniqueSender: "% of Unique Cards senders (out of actual HC)",



    mappedManagerHeadCount: "Managers Mapped to RHR",
    cardSentByManagerToDRCount: "Cards Sent by Managers to Direct Reportees",
    percentOfMangerToDRSender: "% Total Cards sent by Managers to their DRs",

    cardSentByUniqueManagerToDRCount: "Unique Managers who gave cards to their DRs",
    percentOfUniqueMangerToDRSender: "% Unique Managers who sent cards to their DRs"
}

const getColumns = ({ allFilterData, selectedReportType, youAreAwesomeCols }) => {

    let downloadableColumns = []
    for (const fName of allFilterData) {
        downloadableColumns.push({
            id: fName,
            key: fName,
            label: youAreAwesomeCols[fName],
            disableSorting: false
        })

        if (fName === "tabp_name") {
            downloadableColumns.push({
                id: "tabp",
                key: "tabp",
                header: youAreAwesomeCols["tabp"],
                label: youAreAwesomeCols["tabp"],
                disableSorting: false
            }) 
        }
        if (fName === "rm_name") {
            downloadableColumns.push({
                id: "reportingManager",
                key: "reportingManager",
                header: youAreAwesomeCols["reportingManager"],
                label: youAreAwesomeCols["reportingManager"],
                disableSorting: false
            }) 
        }
    }
    
    let isMappedHeadCount = false
    let isMappedManagerHeadCount = false

    for (const rType of selectedReportType) {
        if (["totalNoOfCardExchanged", "uniqueCardSenderCount"].includes(rType)) {
            if (!isMappedHeadCount) {
                downloadableColumns.push({
                    id: "mappedHeadCount",
                    key: "mappedHeadCount",
                    label: youAreAwesomeCols["mappedHeadCount"],
                    disableSorting: false
                })
                isMappedHeadCount = true
            }

            if (["totalNoOfCardExchanged"].includes(rType)) {
                downloadableColumns.push({
                    id: rType,
                    key: rType,
                    label: youAreAwesomeCols[rType],
                    disableSorting: false
                })

                downloadableColumns.push({
                    id: "percentOfCardExchanged",
                    key: "percentOfCardExchanged",
                    label: youAreAwesomeCols["percentOfCardExchanged"],
                    disableSorting: false
                })
            }
    
            if (["uniqueCardSenderCount"].includes(rType)) {
                downloadableColumns.push({
                    id: rType,
                    key: rType,
                    label: youAreAwesomeCols[rType],
                    disableSorting: false
                })

                downloadableColumns.push({
                    id: "percentOfUniqueSender",
                    key: "percentOfUniqueSender",
                    label: youAreAwesomeCols["percentOfUniqueSender"],
                    disableSorting: false
                })
            }
        }
        if (["cardSentByManagerToDRCount", "cardSentByUniqueManagerToDRCount"].includes(rType)) {
            if (!isMappedManagerHeadCount) {
                downloadableColumns.push({
                    id: "mappedManagerHeadCount",
                    key: "mappedManagerHeadCount",
                    label: youAreAwesomeCols["mappedManagerHeadCount"],
                    disableSorting: false
                })
                isMappedManagerHeadCount = true
            }
            

            if (["cardSentByManagerToDRCount"].includes(rType)) {
                downloadableColumns.push({
                    id: rType,
                    key: rType,
                    label: youAreAwesomeCols[rType],
                    disableSorting: false
                })

                downloadableColumns.push({
                    id: "percentOfMangerToDRSender",
                    key: "percentOfMangerToDRSender",
                    label: youAreAwesomeCols["percentOfMangerToDRSender"],
                    disableSorting: false
                })
            }

            if (["cardSentByUniqueManagerToDRCount"].includes(rType)) {
                downloadableColumns.push({
                    id: rType,
                    key: rType,
                    label: youAreAwesomeCols[rType],
                    disableSorting: false
                })

                downloadableColumns.push({
                    id: "percentOfUniqueMangerToDRSender",
                    key: "percentOfUniqueMangerToDRSender",
                    label: youAreAwesomeCols["percentOfUniqueMangerToDRSender"],
                    disableSorting: false
                })
            }
        }
    }
    

    return downloadableColumns
}


const getObjectToArrayList = ({ data = {}, dynamicColumns = [], allFilterData, isCompare = false }) => {
    let txnRows = []
    let totalRow = {}
    let totalHeadCount = {}
    let totalTxnCount = {}
    for (const ordered in data) {
        const rowObj = data[ordered]
        let pushObj = { timeFrameOrder: rowObj.timeFrameOrder || "" }

        let isMappedHeadCountSet = false
        let isMappedManagerCountSet = false

        for (const colObj of dynamicColumns) {
            switch (colObj.key) {
                case "percentOfCardExchanged":
                    if (!totalHeadCount["mappedHeadCount"]) {
                        totalHeadCount["mappedHeadCount"] = 0
                    }

                    if (!totalTxnCount["totalNoOfCardExchanged"]) {
                        totalTxnCount["totalNoOfCardExchanged"] = 0
                    }

                    if (!isMappedHeadCountSet) {
                        totalHeadCount["mappedHeadCount"] += rowObj.mappedHeadCount  
                        isMappedHeadCountSet = true
                    }
                    

                    totalTxnCount["totalNoOfCardExchanged"] += rowObj["totalNoOfCardExchanged"]
                    pushObj[colObj.key] = (rowObj.mappedHeadCount  ? `${((rowObj["totalNoOfCardExchanged"]*100)/rowObj.mappedHeadCount).toFixed(2)} %` : '0.00 %')
                    break
                case "percentOfUniqueSender":
                    if (!totalHeadCount["mappedManagerHeadCount"]) {
                        totalHeadCount["mappedManagerHeadCount"] = 0
                    }

                    if (!totalTxnCount["uniqueCardSenderCount"]) {
                        totalTxnCount["uniqueCardSenderCount"] = 0
                    }

                    if (!isMappedHeadCountSet) {
                        totalHeadCount["mappedHeadCount"] += rowObj.mappedHeadCount  
                        isMappedHeadCountSet = true
                    }

                    totalTxnCount["uniqueCardSenderCount"] += rowObj["uniqueCardSenderCount"]

                    pushObj[colObj.key] = (rowObj.mappedHeadCount  ? `${((rowObj["uniqueCardSenderCount"]*100)/rowObj.mappedHeadCount).toFixed(2)} %` : '0.00 %')
                    break
                case "percentOfMangerToDRSender":
                    if (!totalHeadCount["mappedManagerHeadCount"]) {
                        totalHeadCount["mappedManagerHeadCount"] = 0
                    }

                    if (!totalTxnCount["cardSentByManagerToDRCount"]) {
                        totalTxnCount["cardSentByManagerToDRCount"] = 0
                    }

                    if (!isMappedManagerCountSet) {
                        totalHeadCount["mappedManagerHeadCount"] += rowObj.mappedManagerHeadCount
                        isMappedManagerCountSet = true
                    }
            
                    totalTxnCount["cardSentByManagerToDRCount"] += rowObj["cardSentByManagerToDRCount"]
                    pushObj[colObj.key] = (rowObj.mappedManagerHeadCount  ? `${((rowObj["cardSentByManagerToDRCount"]*100)/rowObj.mappedManagerHeadCount).toFixed(2)} %` : '0.00 %')
                    break
                case "percentOfUniqueMangerToDRSender":
                    if (!totalHeadCount["mappedManagerHeadCount"]) {
                        totalHeadCount["mappedManagerHeadCount"] = 0
                    }

                    if (!totalTxnCount["cardSentByUniqueManagerToDRCount"]) {
                        totalTxnCount["cardSentByUniqueManagerToDRCount"] = 0
                    }

                    if (!isMappedManagerCountSet) {
                        totalHeadCount["mappedManagerHeadCount"] += rowObj.mappedManagerHeadCount
                        isMappedManagerCountSet = true
                    }
            
                    totalTxnCount["cardSentByUniqueManagerToDRCount"] += rowObj["cardSentByUniqueManagerToDRCount"]
                    pushObj[colObj.key] = (rowObj.mappedManagerHeadCount ? `${((rowObj["cardSentByUniqueManagerToDRCount"]*100)/rowObj.mappedManagerHeadCount).toFixed(2)} %` : '0.00 %')
                    break
                default:
                    pushObj[colObj.key] = rowObj[colObj.key]
                    if (!totalRow[colObj.key]) {
                        totalRow[colObj.key] = 0
                    }

                    if ([
                        "percentOfCardExchanged", 
                        "percentOfUniqueSender",
                        "percentOfMangerToDRSender",
                        "percentOfUniqueMangerToDRSender",
                        "mappedHeadCount",
                        "mappedManagerHeadCount",
                        "totalNoOfCardExchanged",
                        "cardSentByManagerToDRCount",
                        "cardSentByUniqueManagerToDRCount",
                        "uniqueCardSenderCount"
                    ].includes(colObj.key)) {
                        totalRow[colObj.key] += rowObj[colObj.key]
                    } else {
                        totalRow[colObj.key] = ""
                    }
                    break
            }
        }
        txnRows.push({ ...pushObj })
    }

    if (!isCompare) {
        totalRow[dynamicColumns[0].key] = "Total"
    }
    
    for (const key in totalRow) {
        switch (key) {
            case "totalNoOfCardExchanged":
                totalRow["percentOfCardExchanged"] = (totalRow["mappedHeadCount"]  ? `${((totalRow["totalNoOfCardExchanged"]*100)/totalRow["mappedHeadCount"]).toFixed(2)} %` : '0.00 %')
                break
            case "uniqueCardSenderCount":
                totalRow["percentOfUniqueSender"] = (totalRow["mappedHeadCount"]  ? `${((totalRow["uniqueCardSenderCount"]*100)/totalRow["mappedHeadCount"]).toFixed(2)} %` : '0.00 %')
                break
            case "cardSentByManagerToDRCount":
                totalRow["percentOfMangerToDRSender"] = (totalRow["mappedManagerHeadCount"]  ? `${((totalRow["cardSentByManagerToDRCount"]*100)/totalRow["mappedManagerHeadCount"]).toFixed(2)} %` : '0.00 %')
                break
            case "cardSentByUniqueManagerToDRCount":
                totalRow["percentOfUniqueMangerToDRSender"] = (totalRow["mappedManagerHeadCount"]  ? `${((totalRow["cardSentByUniqueManagerToDRCount"]*100)/totalRow["mappedManagerHeadCount"]).toFixed(2)} %` : '0.00 %')
                break
            default:
                break
        }
    }
    
    const sortBy = getSortBy({ allFilterData })
    txnRows = listingSort(txnRows, getComparator("asc", sortBy))
    
    return { txnRows, totalRow }
}

const YouAreAwesomeData = ({ 
    detailedData = {},
    reportTypeData = [],
    handleDetailedData = () => {}
}) => {
    const classes = useStyles()
    let { 
        allFilterData = [], 
        finalProcessedData = {}, 
        selectedReportType = ["totalNoOfCardExchanged"],
        totalRowRecord = {},
        totalColName = "",
        isCompare = false,
        isCompareTotal = false
    } = detailedData
    
    const dynamicColumns = getColumns({ allFilterData, reportTypeData, selectedReportType, youAreAwesomeCols })
    let { txnRows, totalRow } = getObjectToArrayList({ data: finalProcessedData, dynamicColumns, allFilterData })
    
    if (isCompare && isCompareTotal && totalRowRecord && Object.keys(totalRowRecord).length) {
        let compareTotal = getObjectToArrayList({ data: totalRowRecord, dynamicColumns, allFilterData, isCompare: true })
        totalRow = compareTotal.totalRow
        isCompareTotal = true
        totalRow[dynamicColumns[0].key] = totalColName
    } else if (isCompare && !isCompareTotal) {
        totalRow = {}
    }

    const txnRowsLen = txnRows.length
    
    const goBack = (pageName) => {
        handleDetailedData(pageName, true)
    }

    return (<React.Fragment>
        <Grid item xs={12} sm={12} md={12} className={classes.youAreAwesomeTableGridBody}>
            <Card>
                <Paper>
                {
                    txnRowsLen ? <ReportTable 
                    rows={txnRows}
                    columns={dynamicColumns}
                    totalRow={totalRow}
                    isCompareTotal={isCompareTotal}
                    reportName="You Are Awesome"
                    goBack={goBack}
                    /> : <NoRecordToDisplay goBack={goBack} />
                }
                </Paper>
            </Card>
        </Grid>
    </React.Fragment>)
}

export default YouAreAwesomeData