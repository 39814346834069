import React, { useState } from 'react';
import { Component } from 'react';
import Header from '../Header/Header';
import NomineeTable from './NomineeTable';
import AddNomineeDetailsTable from './AddNominee';
import moment from "moment";
import { toast } from 'react-toastify';
import { isEmpty, trimMultiSpace } from '../../utils/CommonFunction';
import { onlyNumber, onlyAlphabets, onlyNumberWithSpecialChar, checkSpecialOccurenceInPhoneNumber } from '../../utils/Validation';
import { postNomineeDetails, updateNomineeDetails, sendEmailNotification, updateEmployeeRecForNominee }  from './ActionFunction';
import nomineeConstant from '../../constants/NomineeConstant';
import { TitleComponent } from '../PageTitle/PageTitle';
import { getRelationListUrl, getEmployeeNomineeDetailUrl } from './NomineeApiUrl';
import { getEmployeeDetailUrl } from '../../utils/CommonUrl';
import axios from 'axios';
import logo from '../../utils/load.gif'
import { getAxiosConfig, getRequestHeader } from '../../utils/CommonFunction'
import { getMenuSubmenuRightsDetail } from '../../utils/authFunction';
import { pageURLConfig, EMPLOYEE_USER_ROLE, menuSubmenuPageCategory } from '../../constants/CommonConstant';
const { moduleName, controllerName, actionName } = pageURLConfig.EMPLOYEE_WORKDETAILS_NOMINEE_DETAILS_REDIRECT
const { NON_ADMIN_CATEGORY } = menuSubmenuPageCategory

const axiosConfig = getAxiosConfig(); 
axiosConfig.headers = getRequestHeader();

const regExp = {
     legalName: RegExp(/^[a-zA-Z ]+$/),
     phone: RegExp(/^\+[1-9][0-9-]{9,15}|[1-9][0-9-]{9,15}$/),
     percentage: RegExp(/(?:\b|-)([1-9]{1,2}[0]?|100)\b/)
};
const initialFormInput = {
     legalName: "",
     relation: "",
     dob: null,
     address: "",
     phone: "",
     percentage: "",
     minDate: moment().subtract(100, 'years').format("YYYY-MM-DD"),
     guardianName:"",
     guardianAddress:"",
     guardianPhone:""
}

const initialFormErrors= {
     legalName: "",
     relation: "",
     dob: "",
     address: "",
     phone: "",
     percentage: "",
     guardianName:"",
     guardianAddress:"",
     guardianPhone:""
}

const initialState = {
     isEditable: false,
     switchView: false,
     deleteId: null,
     openDeleteDialog: false,
     openAddDialog:false,
     viewAddTable: false,
     usedPercentage: 0,
     totalNomineeCount: 0,
     totalSharePercentage:0,
     isSwitchEnabled: true,
     isFormValid: false,
     relationList: [],
     nomineeDetails: [],
     formInput: initialFormInput,
     formErrors: initialFormErrors,
     empDetails: {},
     isGuardianRequired: false,
     loading: false,
     newAddRecAllowed: true,
     policyDocPath: encodeURI(`${process.env.REACT_APP_BASE_URL}${nomineeConstant.policyDocPath}`),
     agreeBtnDisable: true,
     finalSubmitEnable: false,
     openFinalSubmitDialog: false,
     confirmFinalDialogue: nomineeConstant.messages.confirmFinalMessage,
     confirmDeleteDialogue: nomineeConstant.messages.confirmDeleteMessage,
     confirmAddDialogue: nomineeConstant.messages.confirmAddMessage,
     declarationMessage: nomineeConstant.messages.declarationMessage,
     pageLoader:true,
     isHavingSelfAddAccess: false,
     isHavingSelfEditAccess: false,
     isHavingSelfViewAccess:null
};
class Nominee extends Component {
     constructor(props) {
          super(props);
          this.state = initialState;
     }

     componentDidMount() {
          this.relationListFetch();
          this.employeeDetailsFetch();
          this.setPageRights();
     }

     async setPageRights() {
          const { 
               isUserSuperAdmin,
               selfAddRights,
               selfEditRights, 
               selfViewRights 
          } = await getMenuSubmenuRightsDetail({ userGroupId: EMPLOYEE_USER_ROLE , pageCategory: NON_ADMIN_CATEGORY, moduleName, controllerName, actionName})
          this.setState({
               isHavingSelfAddAccess:  isUserSuperAdmin || selfAddRights,
               isHavingSelfEditAccess: isUserSuperAdmin || selfEditRights,
               isHavingSelfViewAccess: isUserSuperAdmin || selfViewRights,
          })
     }

     async relationListFetch() {
          let response = await axios.get(getRelationListUrl(), axiosConfig);
          const { status, data} =  response;
          
          if (status === 200) {
               this.setState({
                    relationList: data
               });
          }
          
          
     }

     employeeDetailsFetch() {
          axios.get(getEmployeeDetailUrl(), axiosConfig)
                    .then(response => {
                         const { data = {} } = response;
                         this.setState({
                              empDetails: data.length ? data[0] : []
                         });
                         this.nomineeDetailsFetch();
                    })
                    .catch( err => {
                         console.log(err);
                    });
     }

     nomineeDetailsFetch() {
          let usedPercentageVal = 0;
          let checkEditable = false;
          const nomineeStatus = 'Active';
          
          axios.get(`${getEmployeeNomineeDetailUrl()}?status=${nomineeStatus}`, axiosConfig)
          .then(response => { 
               const { data = {} }  = response;
               usedPercentageVal = this.getUsedPercentage(data);
               checkEditable = this.findEditable(data, this.state.empDetails);
               this.setState({
                    nomineeDetails: data,
                    totalNomineeCount: data.length,
                    usedPercentage:  usedPercentageVal,
                    isEditable: checkEditable,
                    switchView: !checkEditable,
                    agreeBtnDisable: true,
                    pageLoader: false
               });
     
               this.addTableComponent();
               this.checkFinalSubmitBtn(this.state.empDetails);
          })
          .catch(err => {
               console.log(err);
          });
     }

     formReset = () => { 
          this.setState({
               formInput: {
                    legalName: "",
                    relation: "",
                    dob: null,
                    address: "",
                    phone: "",
                    percentage: "",
                    guardianName:"",
                    guardianAddress:"",
                    guardianPhone:"",
                    minDate: moment().subtract(100, 'years').format("YYYY-MM-DD")
               },
          });
     }

     isNewAddAllowed = () => {
          let usedNomineeCount = this.state.totalNomineeCount;
          let usedsharePercentage = this.getSharedPercentage();
          
          if (usedsharePercentage > nomineeConstant.maxNomineeSharePercent) {
               this.notify(`${nomineeConstant.messages.maxUsedShareMessage}`, 'warn');
               if (this.state.formInput.percentage) {
                    this.setState({ formErrors: {percentage: "Max limit reached." }});
               }
          }

          if (usedNomineeCount >= nomineeConstant.maxNomineeCount) {
               this.notify(`${nomineeConstant.messages.maxNomineeCountMessage}`, 'warn');
          }
          
          return (usedNomineeCount < nomineeConstant.maxNomineeCount && usedsharePercentage <= nomineeConstant.maxNomineeSharePercent);
     }

     getSharedPercentage = () => {
          const percentage = isEmpty(this.state.formInput.percentage) ? 0 : this.state.formInput.percentage;
          return parseFloat(this.state.usedPercentage) + parseFloat(percentage);
     }

     checkFinalSubmitBtn = (empDetails) => {
          let flag = false;
          const { is_nominee_editable, last_nominee_edit_date, nominee_final_submit_date }  = empDetails;
          let accessGivenDate = is_nominee_editable;
          let lastAddDate = last_nominee_edit_date;
          let finalSubmitDate = nominee_final_submit_date;

          if (moment(accessGivenDate).isValid()) {
               if (moment(finalSubmitDate).isValid() || moment(lastAddDate).isValid()) {
                    accessGivenDate = moment(accessGivenDate).format('YYYY-MM-DD HH:mm:ss');
                    const diffAfterFinalSubmit = moment.duration(moment().diff(accessGivenDate)).asHours();
                    if (parseFloat(diffAfterFinalSubmit) < nomineeConstant.windowOpenAfterLastNomnieeEdit && this.state.totalNomineeCount) {
                         flag = true;
                    }
               } else {
                    accessGivenDate = moment(accessGivenDate).format('YYYY-MM-DD HH:mm:ss');
                    const diffAfterAccessGiven = moment.duration(moment().diff(accessGivenDate)).asHours();
                    if (parseFloat(diffAfterAccessGiven) < nomineeConstant.windowOpenAfterGivenEditAccess && this.state.totalNomineeCount) {
                         flag = true;
                    }
               }
          } else if (isEmpty(is_nominee_editable)) {
               flag = true
          }

          this.setState({
               finalSubmitEnable: flag && isEmpty(nominee_final_submit_date)
          });
     }

     findEditable = (nomineeDetail, empDetails) => {
          let editable = false;
          const { is_nominee_editable, last_nominee_edit_date, nominee_final_submit_date }  = empDetails;
          let accessGivenDate = is_nominee_editable;
          let lastAddDate = last_nominee_edit_date;
          let finalSubmitDate = nominee_final_submit_date;

          if (moment(accessGivenDate).isValid()) {
               if (finalSubmitDate || lastAddDate) {
                    accessGivenDate = moment(accessGivenDate).format('YYYY-MM-DD HH:mm:ss');
                    const diffAfterFinalSubmit = moment.duration(moment().diff(accessGivenDate)).asHours();
                    if (parseFloat(diffAfterFinalSubmit) < nomineeConstant.windowOpenAfterLastNomnieeEdit) {
                         editable = true;
                    }
               } else {
                    accessGivenDate = moment(accessGivenDate).format('YYYY-MM-DD HH:mm:ss');
                    const diffAfterAccessGiven = moment.duration(moment().diff(accessGivenDate)).asHours();
                    if (parseFloat(diffAfterAccessGiven) < nomineeConstant.windowOpenAfterGivenEditAccess) {
                         editable = true;
                    }
               }
          }
          else if (!nomineeDetail.length || !moment(finalSubmitDate).isValid()) {
               editable = true;
          }
          
          return editable;
     }

     handleChange = (e) => {
          e.preventDefault();
          const { name, value } = e.target;
          const { formErrors, formInput } = this.validateFields(name, value);
          if (name === 'relation') {
               this.isEligibleForGuaridian();
               const { checkEligible, msg } = this.checkAgeLegality(value, 'relation');
               if (!checkEligible) {
                    formErrors.dob = msg;
                    formInput.dob = null;
               } else {
                    formErrors.dob = '';
               }
          }

          this.setState({formErrors, formInput});
     }

     handleDateChange = (value) => {
          if (value) {
               const formInput = this.state.formInput;
               const formErrors = this.state.formErrors;
               let isEligible =  true;
               let errorMsg = '';
               let date = moment(value, "DD-MM-YYYY");    
               if (moment(date).isValid()) {
                    if (this.state.formInput.relation) {
                         this.checkAgeLegality(date)
                         const { checkEligible, msg } = this.checkAgeLegality(date, 'dob');
                         isEligible = checkEligible;
                         errorMsg = msg;
                    }

                    if (!isEligible)  {
                         date = null;
                    }
                    
                    formInput.dob = date;
                    formErrors.dob = errorMsg;
                    this.setState({ 
                         formInput: formInput,
                         formErrors: formErrors
                    });

                    this.isEligibleForGuaridian();
               }
          }
     }

     checkAgeLegality = (param, type) => {
          let relation = '';
          let date = '';
          let checkEligible = true;
          let msg = '';
         
          if (type === 'dob') {
               relation = this.state.formInput.relation;
               date = param;
          }
          
          if (type === 'relation') {
               date = this.state.formInput.dob;
               relation = param
          }

          if (relation && date && moment(date).isValid() && Object.keys(nomineeConstant.nomineeAgeLegality).map(Number).includes(relation)) {
               checkEligible = moment().diff(moment(date), 'year') >= nomineeConstant.nomineeAgeLegality[relation];
               if (!checkEligible) {
                    msg = `${this.findRelationById(relation)} must be atleast ${nomineeConstant.nomineeAgeLegality[relation]} years old.`
               }
          }
          
          return { checkEligible, msg };
     }

     isEligibleForGuaridian = () => {
          let flag;
          const relationId = this.state.formInput.relation;
          const dob = this.state.formInput.dob;

          if (relationId && dob) {
               const relationName = this.findRelationById(relationId);
               flag = (this.getNomineeAge(dob) < nomineeConstant.minorNomineeAgeLimit) && nomineeConstant.eligibleForGuardian.includes(relationName);
          }

          this.setState({
               isGuardianRequired: flag
          });

          return flag;
     }

     getNomineeAge = (dob) => {
          return moment().diff(dob, 'years');
     }

     checkSharePercent = () => {
          if (this.getSharedPercentage() < nomineeConstant.maxNomineeSharePercent) {
               this.setState({
                    confirmFinalDialogue: `${nomineeConstant.messages.additionalConfirmMessage}`
               });
          } else {
               this.setState({
                    confirmFinalDialogue: `${nomineeConstant.messages.confirmFinalMessage}`
               });
          }
     }

     handleClick = (e) => {
          e.preventDefault();
          if (!this.isNewAddAllowed()) {
               return false;
          }

          if (this.checkFormValid()) {
               this.checkFourthRec();
               this.setState({ openAddDialog: true });
          }
     }

     checkFourthRec = () => {
          if (this.getSharedPercentage() < nomineeConstant.maxNomineeSharePercent && this.state.totalNomineeCount === (nomineeConstant.maxNomineeCount - 1)) {
               this.setState({
                    confirmAddDialogue: `${nomineeConstant.messages.additionalFourtRecMessage} \n ${nomineeConstant.messages.confirmAddMessage}`
               });
          } else {
               this.setState({
                    confirmAddDialogue: `${nomineeConstant.messages.confirmAddMessage}`
               });
          }
     }

     handleDeleteClick = (id) => {
          this.setState({ openDeleteDialog: true, deleteId: id});
     }

     handleAddDialogClose = async (flag) => {
          this.setState({openAddDialog: false});
          if (flag) {
               this.setState({loading: true});
               try {
                    const response = await postNomineeDetails(this.state.formInput, this.state.isGuardianRequired);
                    const { status } = response;
                    if (status === 200) {
                         this.notify('Record added successfully', 'success');
                         if (nomineeConstant.maxNomineeSharePercent > this.getSharedPercentage()) {
                              this.notify(nomineeConstant.messages.usedSharePercentMessage, 'info');
                         }
                         this.formReset();
                         this.setState({
                              loading: false,
                              isGuardianRequired: false
                         });
                         this.componentDidMount();
                    } else {
                         this.notify('Something went wrong.', 'error');
                    }
               } catch (err) {
                    console.error(err);
               }
          }
     }

     handleDeleteDialogClose = async (flag) => {
          this.setState({openDeleteDialog: false});
          if (flag) {
               const response = await updateNomineeDetails(this.state.deleteId);
               try {
                    const { status } = response;
                    if (status === 200) {
                         this.componentDidMount();
                         this.notify('Record deleted successfully', 'success');
                    } else {
                         this.notify('Something went wrong.', 'error');
                    }
               } catch(err) {
                    console.error(err);
               }
          } else {
               this.setState({ deleteId: null });
          }
     }

     addTableComponent = () => {
          let newAddRecAllowed = true;
          if (this.state.totalNomineeCount === nomineeConstant.maxNomineeCount || this.getSharedPercentage() === nomineeConstant.maxNomineeSharePercent) {
               newAddRecAllowed = false;
          }
          
          this.setState({
               newAddRecAllowed: newAddRecAllowed
          });
     }

     checkAndSetValue = (type) => {
          let valid = true;
          let response = { formInput : {}, formErrors: {} };
          response = this.validateFields(type, this.state.formInput[type]);
          this.setState(response.formErrors);
          this.setState(response.formInput);
          if (this.state.formErrors[type] !== '') {
               valid = false;
          }
          
          return valid;
     }

     checkFormValid = () => {
          let valid = false;
          const isNameValid = this.checkAndSetValue('legalName');
          const isRelationValid = this.checkAndSetValue('relation');
          const isDobValid = this.checkAndSetValue('dob');
          const isPhoneValid = this.checkAndSetValue('phone');
          const isPercentValid = this.checkAndSetValue('percentage');
          const isAddressValid = this.checkAndSetValue('address');
          
          if (this.isEligibleForGuaridian()) {
               var isGnameValid = this.checkAndSetValue('guardianName');
               var isGAddValid = this.checkAndSetValue('guardianAddress');
               var isGPhoneValid = this.checkAndSetValue('guardianPhone');
          }
          
          valid = (isNameValid && isRelationValid && isDobValid && isPhoneValid && isPercentValid && isAddressValid);

          if (this.isEligibleForGuaridian() && valid) {
               valid = (isGnameValid && isGAddValid && isGPhoneValid);
          }
          
          this.setState({ isFormValid: valid });
          return valid;
     }

     validateFields = (name, value) => {
          let formErrors = this.state.formErrors;
          let formInput = this.state.formInput;
          let errorMsg = '';
          switch (name) {
               case "legalName":
                    errorMsg = '';
                    formInput.legalName = trimMultiSpace(value);
                    if (isEmpty(value)) {
                         errorMsg = 'This is required!';
                    } else if (value.length < nomineeConstant.minNomineeNameLength || value.length > nomineeConstant.maxNomineeNameLength) {
                         errorMsg = 'Between 3 to 100 characters.';
                    } else if (!regExp.legalName.test(value)) {
                         errorMsg = 'Invalid name.';
                    }

                    formErrors.legalName =  errorMsg;
                    break;
               
               case "relation":
                    errorMsg = '';
                    formInput.relation = value;
                    if (isEmpty(value)) {
                         errorMsg = 'This is required!';
                    }
                    formErrors.relation = errorMsg;
                    break;
               
               case "dob":
                    errorMsg = '';
                    formInput.dob = value;
                    
                    if (isEmpty(value)) {
                         errorMsg = 'This is required!';
                    }
                    else if (!moment(value).isValid()) {
                         errorMsg = 'Invalid date.';
                    }
                    formErrors.dob = errorMsg;
                    break;

               case "address":
                    errorMsg = '';
                    formInput.address = trimMultiSpace(value);
                    if (isEmpty(value)) {
                         errorMsg = 'This is required!';
                    } else if (value.length < nomineeConstant.minNomineeAddressLength) {
                         errorMsg = 'Min 10 characters required!';
                    }
                    formErrors.address =  errorMsg;
                    break;
                    
               case "phone":
                    errorMsg = '';
                    formInput.phone = value;
                    if (isEmpty(value)) {
                         errorMsg = 'This is required!';
                    } else if (!regExp.phone.test(value) || value[value.length-1] === '-' || checkSpecialOccurenceInPhoneNumber(value, '-')) {
                         errorMsg = 'Invalid contact number.';
                    }

                    formErrors.phone =  errorMsg;
                    break;

               case "percentage":
                    errorMsg = '';
                    formInput.percentage = value;
                    if (isEmpty(value)) {
                         errorMsg = 'This is required!';
                    } else if (!regExp.percentage.test(value)) {
                         errorMsg = 'Enter number between 1 to 100.';
                    }

                    formErrors.percentage =  errorMsg;
                    break;

               case "guardianName":
                    errorMsg = '';
                    formInput.guardianName = trimMultiSpace(value);
                    if (isEmpty(value)) {
                         errorMsg = 'This is required!';
                    } else if (value.length < nomineeConstant.minNomineeNameLength || value.length > nomineeConstant.maxNomineeNameLength) {
                         errorMsg = 'Between 3 to 100 characters.';
                    } else if (!regExp.legalName.test(value)) {
                         errorMsg = 'Invalid name.';
                    }

                    formErrors.guardianName =  errorMsg;
                    break;

               case "guardianAddress":
                    errorMsg = '';
                    formInput.guardianAddress = trimMultiSpace(value);
                    if (isEmpty(value)) {
                         errorMsg = 'This is required!';
                    } else if (value.length < nomineeConstant.minNomineeAddressLength) {
                         errorMsg = 'Min 10 characters required!';
                    }
                    formErrors.guardianAddress =  errorMsg;
                    break;
                    
               case "guardianPhone":
                    errorMsg = '';
                    formInput.guardianPhone = value;
                    if (isEmpty(value)) {
                         errorMsg = 'This is required!';
                    } else if (!regExp.phone.test(value) || value[value.length-1] === '-' || checkSpecialOccurenceInPhoneNumber(value, '-')) {
                         errorMsg = 'Invalid contact number.';
                    }

                    formErrors.guardianPhone =  errorMsg;
                    break;
               default:
                    break;
          }

          return { errorMsg, formErrors, formInput };
     }
     
     getUsedPercentage = (nomineeDetail) => {
          if (nomineeDetail.length) {
               const sumArr = nomineeDetail.map((obj) => obj.share_percent);

               if (sumArr.length) {
                    return sumArr.reduce((a, b) => a + b );
               }
          }

          return 0;
     }

     handlePermissionSwitch = (e) => {
          if (e.target.checked) {
               this.sendEmail('askedForEdit');
               this.notify(nomineeConstant.messages.askedForEditMessage);
               this.setState({isSwitchEnabled: false});
          }
     }

     handleFinalSubmit = () => {
          if (this.getSharedPercentage() < nomineeConstant.maxNomineeSharePercent) {
               this.notify(nomineeConstant.messages.beforeFinalCheckMessage, 'info');
               return false;
          }
          this.setState({
               openFinalSubmitDialog: true
          });
     }

     handleFinalSubmitClose = async (flag) => {
          if (this.state.agreeBtnDisable && flag) {
               alert('Invalid request!');
               return false;
          }

          if (this.getSharedPercentage() < nomineeConstant.maxNomineeSharePercent) {
               this.notify(nomineeConstant.messages.beforeFinalCheckMessage, 'info');
               return false;
          }

          this.setState({openFinalSubmitDialog: false});
          if (flag) {
               const response = await updateEmployeeRecForNominee();
               try {
                    const { status } = response;
                    if (status === 200) {
                         this.setState({
                              finalSubmitEnable: false
                         });
                         this.sendEmail('addNominee');
                         this.notify('Record has been submitted successfully', 'success');
                         window.location = process.env.REACT_APP_BASE_URL;
                    } else {
                         this.notify('Something went wrong.', 'error');
                    }
               } catch(err) {
                    console.error(err);
               }
          }
     }

     sendEmail = async (type) => {
          let sendTo = ''; 
          switch (type) {
               case 'askedForEdit' :
                    sendTo = 'hrops';
                    type = 'askedForEdit';
               break;

               case 'addNominee' :
                    sendTo = 'employee';
                    type = 'addNominee';
               break;

               default:
                    break;
          }
          
          if (sendTo && type) {
               const data = { sendTo: sendTo, type: type };
               const response = await sendEmailNotification(data);
               const { status } = response;

               if (status === 200) {
                    return true;
               }
          }

          return false;
     }

     notify = (msg, type, autoClose = 8000) => {
          const toastOption = {
               position: "top-right",
               autoClose: autoClose,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
               bodyClassName: "grow-font-size"
          };

          switch(type) {
               case 'success' :
                    toast.success(`🚀${msg}!`, toastOption);
                    break;
               case 'warn' :
                    toast.warn(`🦄${msg}.`, toastOption);
                    break;
               case 'error' :
                    toast.error(`🦄${msg}!`, toastOption);
                    break;
               case 'info' :
                    toast.info(`🦄${msg}!`, toastOption);
                    break;
               default:
                    toast(`🚀${msg}!`, toastOption);
                    break;
          }
     }

     handleInputName = (e) => {
          const isInputValid = onlyAlphabets(e.which);
          if (!isInputValid) {
               e.preventDefault();
          }

          return isInputValid;
     }

     handleInputNumber = (e) => {
          let isInputValid;
          if (e.target.name === 'phone' || e.target.name === 'guardianPhone') {
               const specialChars = ['-'.charCodeAt(0), '+'.charCodeAt(0)];
               isInputValid = onlyNumberWithSpecialChar(e.which, specialChars);
          } else {
               isInputValid = onlyNumber(e.which);
          }

          if (!isInputValid) {
               e.preventDefault();
          }

          return isInputValid;
     }

     handleAgreeButton = (event) => {
          this.setState({ 
               [event.target.name]: event.target.checked,
               agreeBtnDisable: !event.target.checked
          });
     }

     findRelationById = (relationId) => {
          const relationList = this.state.relationList;
          let relationName;
          if (relationId) {
               let { value } = relationList.find(({ id }) => { return id === relationId });
               relationName = value;
          }

          return relationName;
     }
     
     render() {   
          const {
               isEditable,
               switchView,
               nomineeDetails,
               empDetails,
               openDeleteDialog,
               isSwitchEnabled,
               formInput,
               relationList,
               formErrors,
               loading,
               openAddDialog,
               isGuardianRequired,
               confirmFinalDialogue,
               newAddRecAllowed,
               policyDocPath,
               agreeBtnDisable,
               finalSubmitEnable,
               openFinalSubmitDialog,
               confirmDeleteDialogue,
               confirmAddDialogue,
               declarationMessage,
               pageLoader,
               isHavingSelfAddAccess,
               isHavingSelfEditAccess,
               isHavingSelfViewAccess

          } = this.state;

          return (
               <React.Fragment>
               { !isHavingSelfViewAccess ? 
                    ((isHavingSelfViewAccess == null)
                    ? 
                    <div className='loader'><img src={logo} alt="loading..." /></div> 
                    :
                    <div> <h1 style={{margin:'0px'}}>Unauthorised </h1> <br></br> <span>You don't have access to view this page</span> </div>) :
                    <div>
                         <TitleComponent title="Nominee Details" />
                         <Header />
                         <NomineeTable 
                              openFinalSubmitDialog={openFinalSubmitDialog}
                              finalSubmitEnable={finalSubmitEnable}
                              policyDocPath={policyDocPath}
                              empDetails={empDetails}
                              nomineeDetails={nomineeDetails}
                              isEditable={isEditable}
                              switchView={switchView}
                              isSwitchEnabled={isSwitchEnabled}
                              openDeleteDialog={openDeleteDialog}
                              handleDeleteClick={this.handleDeleteClick}
                              handlePermissionSwitch={this.handlePermissionSwitch}
                              handleDeleteDialogClose={this.handleDeleteDialogClose}
                              handleFinalSubmit={this.handleFinalSubmit}
                              handleFinalSubmitClose={this.handleFinalSubmitClose}
                              confirmFinalDialogue={confirmFinalDialogue}
                              handleAgreeButton={this.handleAgreeButton}
                              agreeBtnDisable={agreeBtnDisable}
                              confirmDeleteDialogue={confirmDeleteDialogue}
                              declarationMessage={declarationMessage}
                              pageLoader={pageLoader}
                              isHavingSelfAddAccess={isHavingSelfAddAccess}
                              isHavingSelfEditAccess={isHavingSelfEditAccess}
                              isHavingSelfViewAccess={isHavingSelfViewAccess}
                         />
                         {
                              isEditable && newAddRecAllowed ?
                              (<AddNomineeDetailsTable 
                                   loading={loading}
                                   formInput={formInput}
                                   formErrors={formErrors}
                                   empDetails={empDetails}
                                   openAddDialog={openAddDialog}
                                   dataFromParent={relationList}
                                   isGuardianRequired={isGuardianRequired}
                                   handleClick={this.handleClick}
                                   handleChange={this.handleChange}
                                   handleInputName={this.handleInputName}
                                   handleInputNumber={this.handleInputNumber}
                                   handleDateChange={this.handleDateChange}
                                   handleAddDialogClose={this.handleAddDialogClose}
                                   confirmAddDialogue={confirmAddDialogue}
                                   isHavingSelfAddAccess={isHavingSelfAddAccess}
                                   isHavingSelfEditAccess={isHavingSelfEditAccess}
                                   isHavingSelfViewAccess={isHavingSelfViewAccess}
                              />) : (null)
                         }
                    </div>
               }
               </React.Fragment>
          )
     }
}

export default Nominee;