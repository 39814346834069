import axios from "axios"
import { routeAccessControl } from "../constants/ACLConstant"
import { userApiUrl, pageAcessUrl, menuSubmenuAccessUrl } from '../utils/CommonUrl'
import { getAxiosConfig, getRequestHeader } from '../utils/CommonFunction'
import { getLoggedInEmployeeDetailFetchData } from "../pages/Efiling/fetchData"
import { SUPERADMIN } from "../constants/CommonConstant"

export const empHasRightsToAccess = ({
    moduleName = '',
    empRoles = []
}) => {
    let eligible = false
    const accessList = routeAccessControl[moduleName]

    if (!accessList) {
        eligible = true
        return eligible
    }

    if (Array.isArray(accessList)) {
        for (const role of accessList) {
            if (empRoles.includes(role)) {
                eligible = true
            }
        }
    }

    return eligible
}

export const getLoggedInUserDetailsFunc = async () => {
    try {
         const config = getAxiosConfig()
         config.headers = getRequestHeader()
         let response = await axios.get(userApiUrl.USER_DATA_URL , config)
         return response
    } catch (err) {
        throw err
    }
}

export const getPagesAcess = async (dataObj) => {
    try {
         const config = getAxiosConfig()
         config.headers = getRequestHeader()
         let response = await axios.post(pageAcessUrl.PAGE_ACESS_URL , dataObj, config)
         return response
    } catch (err) {
        throw err
    }
}

// Main function for page_names access 
export const getPageRightsDetails = async ({moduleName, controllerName, actionName}) => {
    const loggedInUserObj = await getLoggedInEmployeeDetailFetchData()
    let isSuperAdminUser = false
    if(loggedInUserObj.data[0].admin_user_group_id == SUPERADMIN){
        isSuperAdminUser = true
    }

    let pageAccess = null
    let pageViewAccessVal = false, pageAddAccessVal = false, pageEditAccessVal = false
    if(isSuperAdminUser) {
        pageViewAccessVal = true
        pageAddAccessVal = true
        pageEditAccessVal = true
    } else {
        pageAccess = await getPagesAcess ({ 
            userGroupId: loggedInUserObj.data[0].admin_user_group_id,
            moduleName,
            controllerName,
            actionName
        })
        console.log('pageRights', pageAccess.data.data[0]);
        if(pageAccess && pageAccess.data && pageAccess.data.data[0]){
            pageViewAccessVal = pageAccess.data.data[0].page_view == "Yes"
            pageAddAccessVal = pageAccess.data.data[0].page_add == "Yes"
            pageEditAccessVal = pageAccess.data.data[0].page_edit == "Yes"
        }
    }
    return {
        isUserSuperAdmin: isSuperAdminUser,
        isHavingPageViewRights: pageViewAccessVal,
        isHavingPageAddRights : pageAddAccessVal,
        isHavingPageEditRights : pageEditAccessVal 
    }
}

export const axiosForMenuSubmenuAccess = async (dataObj) => {
    try {
         const config = getAxiosConfig()
         config.headers = getRequestHeader()
         let response = await axios.post(menuSubmenuAccessUrl.MENU_SUBMENU_ACCESS_URL , dataObj, config)
         return response
    } catch (err) {
        throw err
    }
}

export const getMenuSubmenuRightsDetail = async ({userGroupId = null, userGroupType = null,pageCategory, moduleName, controllerName, actionName, urlParam = null}) => {
    
    let selfViewAccessVal = false, selfAddAccessVal = false, selfEditAccessVal = false, 
        reporteeAddAccessVal = false, reporteeEditAccessVal = false, reporteeViewAccessVal = false,
        allEmployeeAddAccessVal = false, allEmployeeEditAccessVal = false, allEmployeeViewAccessVal = false,
        isUserSuperAdminVal = false,
        isUserGroupAdminVal = false,
        isUserManagerVal = false
        
    const propObj = { 
        url: urlParam ? urlParam : '/' + moduleName + '/' + controllerName + '/' + actionName,
        pageCategory : pageCategory,
        userGroupId: userGroupId,
        userGroupType: userGroupType
    }
    const pageAccess = await axiosForMenuSubmenuAccess (propObj)
    // console.log('propObj',propObj)
    
    if(pageAccess && pageAccess.data && pageAccess.data.data){
        console.log('pageAccess', pageAccess.data.data)

        isUserSuperAdminVal = pageAccess.data.data.isUserSuperAdmin
        isUserGroupAdminVal = pageAccess.data.data.isUserGroupAdmin
        isUserManagerVal = pageAccess.data.data.isUserManager

        if(pageAccess.data.data.accessData && pageAccess.data.data.accessData.length) {
            selfAddAccessVal = pageAccess.data.data.accessData[0].self_add == 1
            selfEditAccessVal = pageAccess.data.data.accessData[0].self_edit == 1
            selfViewAccessVal = pageAccess.data.data.accessData[0].self_view == 1
            reporteeAddAccessVal = pageAccess.data.data.accessData[0].reportee_add == 1
            reporteeEditAccessVal = pageAccess.data.data.accessData[0].reportee_edit == 1
            reporteeViewAccessVal = pageAccess.data.data.accessData[0].reportee_view == 1
            allEmployeeAddAccessVal = pageAccess.data.data.accessData[0].all_employee_add == 1
            allEmployeeEditAccessVal = pageAccess.data.data.accessData[0].all_employee_edit == 1
            allEmployeeViewAccessVal = pageAccess.data.data.accessData[0].all_employee_view == 1
        }
    }

    return {
        isUserSuperAdmin: isUserSuperAdminVal,
        isUserGroupAdmin : isUserGroupAdminVal,
        isUserManager : isUserManagerVal,
        selfAddRights: selfAddAccessVal,
        selfEditRights: selfEditAccessVal,
        selfViewRights: selfViewAccessVal,
        reporteeAddRights: reporteeAddAccessVal,
        reporteeEditRights: reporteeEditAccessVal,
        reporteeViewRights: reporteeViewAccessVal,
        allEmployeeAddRights: allEmployeeAddAccessVal,
        allEmployeeEditRights: allEmployeeEditAccessVal,
        allEmployeeViewRights: allEmployeeViewAccessVal,
    }
}