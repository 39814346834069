const nomineeConstant = {
  maxNomineeCount: 4,
  maxNomineeSharePercent: 100.0,
  minorNomineeAgeLimit: 18,
  windowOpenAfterGivenEditAccess: 24.0,
  windowOpenAfterLastNomnieeEdit: 48.0,
  eligibleForGuardian: [`Son`, `Daughter`, `Brother`, `Sister`],
  minNomineeAddressLength: 10,
  minNomineeNameLength: 3,
  maxNomineeNameLength: 100,
  fontawesomeCssPath: `https://use.fontawesome.com/releases/v5.12.0/css/all.css`,
  messages: {
    askedForEditMessage: `Your request sent to HR team! They will get in touch with you soon`,
    usedSharePercentMessage: `Hi, you have not completed your Nominee details for Group Personal Accident and Group Term Life Insurance. The share % has to be 100% and thus request you to do the needful and submit the requisite details`,
    maxUsedShareMessage: `Total share percentage must not be greater than 100%`,
    maxNomineeCountMessage: `Max nominee count is 4`,
    confirmAddMessage: `Do you want to add the record?`,
    additionalConfirmMessage: `Your % share among the added nominees is coming less than 100%. Are you sure you want to submit?`,
    confirmFinalMessage: `Do you want to submit?`,
    confirmDeleteMessage: `Do you want to delete record?`,
    additionalFourtRecMessage: `You can only add upto 4 nominees . Your % share among the added nominees is coming less than 100%.`,
    declarationMessage: `I hereby declare that the details of the nominees are correct and are as per the mentioned Govt ID proof only.`,
    beforeFinalCheckMessage: `Your share % among added nominees cannot be less than 100 %. Please make the necessary changes`,
  },
  nomineeAgeLegality: {
    4292: 18,
    4295: 18,
    4296: 18,
    4299: 18,
    4300: 18,
    4310: 18,
  },
  policyDocPath: `/upload_files/policyDoc/20220519151821_GPA and GTL - Benefits Manual.pdf`,
};

export default nomineeConstant;
