import React, { Fragment, useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { useStyles } from './style';
import {
  Paper,
  FormControl,
  TextField,
  InputLabel,
  Select,
  Input,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  FormHelperText,
  Divider,
} from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import UpdateIcon from '@material-ui/icons/Update';
import ReplayIcon from '@material-ui/icons/Replay';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import SimpleAlerts from '../Alert';
import { deleteJoinerRecord, postEducationData } from '../fetchData';
import {
  checkInstituteNameRegex,
  checkMarksRegEx,
} from '../../../utils/Validation';
import DialogComponent from '../../../components/Shared/Elements/DialogComponent';
import { scrollToViewHandler } from '../../../constants/CommonConstant'

const courseTypeList = [
  {
    value: 'Full Time',
  },
  {
    value: 'Part Time',
  },
];
const tableColumns = [
  { id: 'Qualification', label: 'Qualification', minWidth: 100 },
  { id: 'Course Type', label: 'Course Type', minWidth: 100 },
  { id: 'Institute Name', label: 'Institute Name', minWidth: 150 },
  { id: 'Period From', label: 'Period From', minWidth: 50 },
  { id: 'Period To', label: 'Period To', minWidth: 50 },
  { id: 'Marks', label: 'Percentage/CGPA/Grade', minWidth: 50 },
  {
    id: 'Edit',
    label: '',
    minWidth: 10,
    align: 'left',
  },
  {
    id: 'Delete',
    label: '',
    minWidth: 10,
    align: 'left',
  },
];

const Education = ({
  errorObj,
  offerData,
  joinerEducationData,
  handleChildData = () => {},
  handleChildDataValidation = () => {},
  handleNotification = () => {},
  highestQualificationData,
}) => {
  const classes = useStyles();
  const [joinerEducationDataList, setJoinerEducationData] = useState(
    joinerEducationData
  );
  const [highestQualificationDetail] = useState(
    highestQualificationData
  );
  const [notification, setNotification] = useState({ type: '', message: '' });
  const [isUpdate, setIsUpdate] = useState(0);
  const initObj = {
    id: null,
    qualificationName:
      highestQualificationDetail && joinerEducationDataList.length === 0
        ? highestQualificationDetail.qualification
        : '',
    periodFrom: null,
    periodTo:
      highestQualificationDetail && joinerEducationDataList.length === 0
        ? moment(highestQualificationDetail.periodTo).format('YYYY')
        : null,
    instituteName:
      highestQualificationDetail && joinerEducationDataList.length === 0
        ? highestQualificationDetail.instituteName
        : '',
    courseType:
      highestQualificationDetail && joinerEducationDataList.length === 0
        ? highestQualificationDetail.courseType
        : '',
    marks: '',
  };

  let initErrObj = {};
  for (let k in initObj) {
    initErrObj[k] = { status: false, text: '' };
  }

  const [educationObj, setEducationObj] = useState(initObj);
  const [localErrObj, setLocalErrObj] = useState(initErrObj);
  const [deleteRecordId, setDeleteRecordId] = useState('');
  const dialogTitle = `Confirm`;
  const [openDialog, setOpenDialog] = useState(false);
  const handleDialogOpen = (id) => {
    setOpenDialog(true);
    setDeleteRecordId(id);
  };
  const handleDialogClose = (val) => {
    if (val === true) {
      handleRecordDelete(deleteRecordId);
    }
    setOpenDialog(false);
  };

  const {
    qualificationName,
    periodFrom,
    periodTo,
    instituteName,
    courseType,
    marks,
  } = educationObj;

  useEffect(() => {
    const isCount = joinerEducationDataList.length ? true : false;
    handleChildData('joinerEducationData', joinerEducationDataList);
    handleChildDataValidation('joinerEducationData', {
      status: !isCount,
      text: '',
    });
  }, [joinerEducationDataList, highestQualificationDetail]);

  const handleChange = (e) => {
    let key = e.target.name;
    let value = e.target.value;
    setEducationObj({ ...educationObj, [key]: value });
    let { text, status } = checkEducationDataError(key, value);
    setLocalErrObj({ ...localErrObj, [key]: { text, status } });
  };

  const handleInput = (e) => {
    switch (e.target.name) {
      case 'marks':
        break;
      default:
        break;
    }
  };

  const handleDateChange = (value, key) => {
    setEducationObj({ ...educationObj, [key]: value });
    if(key==='periodFrom'){
    let { text, status } = checkEducationDataError(key, value);
    let { periodToText, periodToStatus } = checkEducationDataError('periodTo', value);
    setLocalErrObj({ ...localErrObj, [key]: { text, status }, periodTo: {text: periodToText, status: periodToStatus} });
    } else {
      let { text, status } = checkEducationDataError(key, value);
      let { periodFromText, periodFromStatus } = checkEducationDataError('periodFrom', value);
      setLocalErrObj({ ...localErrObj, [key]: { text, status }, periodFrom: {text: periodFromText, status: periodFromStatus} });
    }
  };

  const checkEducationDataError = (key, value) => {
    let text = '';
    let status = false;
    switch (key) {
      case 'qualificationName':
        if (value === '') {
          status = true;
          text = `Invalid qualification name`;
        }
        break;
      case 'courseType':
        if (value === '' || !['Full Time', 'Part Time'].includes(value)) {
          status = true;
          text = `Invalid course type`;
        }
        break;
      case 'instituteName':
        if (value === '' || !checkInstituteNameRegex(value)) {
          status = true;
          text = `Invalid institute name`;
        }
        break;
      case 'periodFrom':
          if (value === '' || !moment(value).isValid()) {
            status = true;
            text = `Invalid period from date`;
          } else if (moment(value).startOf('year').isAfter(moment(periodTo).endOf('year'))) {
            status = true;
            text = `Period from date can not be after period to date`;
          }
        break;
      case 'periodTo':
        if (value === '' || !moment(value).isValid()) {
          status = true;
          text = `Invalid period to date`;
        } else if (moment(value).endOf('year').isBefore(moment(periodFrom).startOf('year'))) {
          status = true;
          text = `Period to date can not be before period from date`;
        }
        break;
      case 'marks':
        if (!checkMarksRegEx(value) || ( !isNaN(parseFloat(value)) && parseFloat(value) > 100)) {
          status = true;
          text = `Invalid marks`;
        }
        break;
      default:
        break;
    }

    return { text, status };
  };

  const handleSaveData = () => {
    let text = ``;
    if (joinerEducationDataList.length >= 6) {
      text = `You can add upto 6 records!`;
      setNotification({ message: text, type: 'error' });
    } else {
      let errObj = {};
      let finalStatus;
      for (let key in educationObj) {
        let { text, status } = checkEducationDataError(key, educationObj[key]);
        errObj[key] = { text, status };
        if (status) {
          finalStatus = status;
        }
      }
      setLocalErrObj(errObj);
      if (finalStatus) {
        text = `There is some error in the form!`;
        setNotification({ message: text, type: 'error' });
      } else {
        handleApiCall(educationObj);
      }
    }
  };

  const handleApiCall = async (dataObj) => {
    let response = {};
    if (joinerEducationDataList.length === 0) {
      dataObj.isEditable = 0;
      if (periodFrom === null) {
        dataObj.periodFrom = dataObj.periodTo;
      }
    }
    dataObj.periodFrom = moment(dataObj.periodFrom)
      .startOf('year')
      .format('YYYY-MM-DD');
    dataObj.periodTo = moment(dataObj.periodTo)
      .startOf('year')
      .format('YYYY-MM-DD');
    response = await postEducationData({ educationDataList: [dataObj] });
    const { success = false, joinerEducationData = [] } = response || {};
    let text = ``;
    let type = ``;
    if (success) {
      setEducationObj({
        id: null,
        qualificationName: '',
        periodFrom: null,
        periodTo: null,
        instituteName: '',
        courseType: '',
        marks: '',
      });
      setJoinerEducationData(joinerEducationData);
      handleNotification({ status: false, text: '' });
      text = dataObj.id
        ? 'Record updated successfully!'
        : 'Record added successfully!';
      type = `success`;
    } else {
      text = `Something went wrong!`;
      type = `error`;
    }
    setNotification({ message: text, type });
    setIsUpdate(false);
  };

  const handleReset = () => {
    setLocalErrObj(initErrObj);
    setEducationObj(initObj);
    setIsUpdate(false);
    setNotification({ message: '', type: '' });
    handleNotification({ status: false, text: '' });
  };

  const handleRecordEdit = (e, recId) => {
    scrollToViewHandler('main-form-grid-div')
    handleNotification({ status: false, text: '' });
    setNotification({ message: '', type: '' });
    const updateRec = getEducationRecordById(recId);
    if (updateRec) {
      const {
        id = null,
        qualification_name = '',
        period_from = null,
        period_to = null,
        institute_name = '',
        course_type = '',
        marks = '',
      } = updateRec;
      const updateObj = {
        id,
        qualificationName: qualification_name,
        periodFrom: period_from,
        periodTo: period_to,
        instituteName: institute_name,
        courseType: course_type,
        marks,
      };
      setEducationObj(updateObj);
      setIsUpdate(recId);
      setLocalErrObj(initErrObj);
    }
  };

  const getEducationRecordById = (id) => {
    return joinerEducationDataList.find((obj) => obj.id === id);
  };

  const handleRecordDelete = async (id) => {
    let text = ``;
    let type = ``;
    setLocalErrObj(initErrObj);
    setEducationObj(initObj);
    setIsUpdate(false);
    if (id) {
      let response = {};
      response = await deleteJoinerRecord({ id, modelName: 'education' });
      const { success = false, joinerEducationData = [] } = response || {};

      if (success) {
        setJoinerEducationData(joinerEducationData);
        text = `Record deleted successfully!`;
        type = `success`;
      } else {
        text = `Something went wrong!`;
        type = `error`;
      }
    }
    handleNotification({ status: false, text: '' });
    setNotification({ message: text, type });
  };

  const closeAlert = () => {
    setNotification({ type: '', message: '' });
  };

  return (
    <Fragment>
      <Grid container spacing={2} className={classes.root}>
        <Grid id="main-form-grid-div" item sm={10}>
          <Paper className={classes.paperFirst}>
            {notification.message ? (
              <SimpleAlerts
                type={notification.type}
                message={notification.message}
                handleAlert={closeAlert}
              />
            ) : null}
            <div style={{color: "red", float: "left", fontSize: "11px", paddingTop: "10px"}}>
              <sup>*</sup> Kindly read general instructions before filling in the information on this page
            </div>
            <Grid container spacing={2}>
              <FormControl className={classes.formControl}>
                <TextField
                  autoComplete='off'
                  required={true}
                  label='Qualification'
                  placeholder='Qualification'
                  value={qualificationName}
                  name='qualificationName'
                  onChange={(e) => handleChange(e)}
                  onKeyPress={(e) => handleInput(e)}
                  inputProps={{ minLength: 2, maxLength: 100 }}
                  error={localErrObj.qualificationName.status}
                  helperText={localErrObj.qualificationName.text}
                  disabled={
                    joinerEducationDataList.length === 0 &&
                    highestQualificationDetail
                      ? true
                      : false
                  }
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <InputLabel
                  required={true}
                  error={localErrObj.courseType.status}
                >
                  Course Type
                </InputLabel>
                <Select
                  value={courseType}
                  name='courseType'
                  input={<Input id='courseType' />}
                  onChange={(e) => handleChange(e)}
                  error={localErrObj.courseType.status}
                  disabled={
                    joinerEducationDataList.length === 0 &&
                    highestQualificationDetail
                      ? true
                      : false
                  }
                >
                  <MenuItem value='' disabled>
                    <em>Select the value</em>
                  </MenuItem>
                  {courseTypeList.map(({ value }, index) => {
                    return (
                      <MenuItem key={index} value={value}>
                        {value}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText style={{ color: 'red' }}>
                  {localErrObj.courseType.text}
                </FormHelperText>
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  autoComplete='off'
                  required={true}
                  label='Institute Name'
                  placeholder='Institute Name'
                  value={instituteName}
                  name='instituteName'
                  onChange={(e) => handleChange(e)}
                  onKeyPress={(e) => handleInput(e)}
                  inputProps={{ minLength: 2, maxLength: 100 }}
                  error={localErrObj.instituteName.status}
                  helperText={localErrObj.instituteName.text}
                  disabled={
                    joinerEducationDataList.length === 0 &&
                    highestQualificationDetail
                      ? true
                      : false
                  }
                />
              </FormControl>
              <FormControl className={classes.formControlDate}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    label='Period From'
                    format='yyyy'
                    clearable={false}
                    name='periodFrom'
                    value={
                      periodFrom
                      // joinerEducationDataList.length === 0 &&
                      // highestQualificationDetail &&
                      // periodFrom === null
                      //   ? highestQualificationDetail.periodTo
                      //   : periodFrom
                    }
                    maxDate={
                      joinerEducationDataList.length === 0 &&
                      highestQualificationDetail
                        ? highestQualificationDetail.periodTo
                        : moment()
                    }
                    minDate={moment('1950')}
                    onChange={(val) => handleDateChange(val, 'periodFrom')}
                    disableFuture={true}
                    inputProps={{ readOnly: true }}
                    required={true}
                    error={localErrObj.periodFrom.status}
                    helperText={localErrObj.periodFrom.text}
                    views={['year']}
                    emptyLabel='Select Year'
                    style={{ width: '100' }}
                  />
                </MuiPickersUtilsProvider>
                {/* {joinerEducationDataList.length === 0 &&
                highestQualificationDetail &&
                periodFrom === null ? (
                  <div
                    style={{
                      color: 'red',
                      fontSize: '11px',
                      textAlign: 'left',
                      marginTop: '5px',
                    }}
                  >
                    Kindly select the starting year for your qualification. If
                    it is the same as ending year, you can skip it.
                  </div>
                ) : (
                  <div></div>
                )} */}
              </FormControl>
              <FormControl
                className={classes.formControlDate}
                disabled={
                  joinerEducationDataList.length === 0 &&
                  highestQualificationDetail
                    ? true
                    : false
                }
              >
                <MuiPickersUtilsProvider
                  utils={DateFnsUtils}
                  disabled={
                    joinerEducationDataList.length === 0 &&
                    highestQualificationDetail
                      ? true
                      : false
                  }
                >
                  <DatePicker
                    label='Period To'
                    format='yyyy'
                    clearable={false}
                    name='periodTo'
                    value={periodTo}
                    onChange={(val) => handleDateChange(val, 'periodTo')}
                    disableFuture={true}
                    inputProps={{ readOnly: true }}
                    required={true}
                    error={localErrObj.periodTo.status}
                    helperText={localErrObj.periodTo.text}
                    views={['year']}
                    disabled={
                      joinerEducationDataList.length === 0 &&
                      highestQualificationDetail
                        ? true
                        : false
                    }
                    emptyLabel='Select Year'
                    style={{ width: '100' }}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            </Grid>
            <Grid container spacing={2}>
              <FormControl className={classes.formControlMarks}>
                <TextField
                  autoComplete='off'
                  required={true}
                  label='Percentage/CGPA/Grade'
                  placeholder='Percentage/CGPA/Grade'
                  value={marks}
                  name='marks'
                  onChange={(e) => handleChange(e)}
                  onKeyPress={(e) => handleInput(e)}
                  inputProps={{ minLength: 1, maxLength: 6 }}
                  error={localErrObj.marks.status}
                  helperText={localErrObj.marks.text}
                  style={{ width: '200' }}
                />
              </FormControl>
            </Grid>
            <Grid container spacing={2} className={classes.formControlAddBtn}>
              <FormControl className={classes.formControlAddBtn}>
                {isUpdate ? (
                  <Button
                    variant='contained'
                    color='secondary'
                    size='small'
                    className={classes.button}
                    startIcon={<UpdateIcon />}
                    onClick={handleSaveData}
                    disabled={
                      (qualificationName &&
                        periodFrom &&
                        periodTo &&
                        instituteName &&
                        courseType &&
                        marks &&
                        !localErrObj.qualificationName.status &&
                        !localErrObj.periodFrom.status &&
                        !localErrObj.periodTo.status &&
                        !localErrObj.instituteName.status &&
                        !localErrObj.courseType.status &&
                        !localErrObj.marks.status
                        ) ? false : true
                    }
                  >
                    Update
                  </Button>
                ) : (
                  <Button
                    variant='contained'
                    color='secondary'
                    size='small'
                    className={classes.button}
                    startIcon={<SaveIcon />}
                    onClick={handleSaveData}
                    disabled={
                      (qualificationName &&
                      periodFrom &&
                      periodTo &&
                      instituteName &&
                      courseType &&
                      marks &&
                      !localErrObj.qualificationName.status &&
                      !localErrObj.periodFrom.status &&
                      !localErrObj.periodTo.status &&
                      !localErrObj.instituteName.status &&
                      !localErrObj.courseType.status &&
                      !localErrObj.marks.status
                      ) ? false : true
                    }
                  >
                    Add
                  </Button>
                )}
              </FormControl>
              <FormControl className={classes.formControlAddBtn}>
                <Button
                  variant='contained'
                  size='small'
                  className={classes.button}
                  startIcon={<ReplayIcon />}
                  onClick={handleReset}
                >
                  Reset
                </Button>
              </FormControl>
            </Grid>
          </Paper>
          <Divider light />
          <Paper className={classes.root}>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label='sticky table'>
                <TableHead>
                  <TableRow>
                    {tableColumns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                        size={'small'}
                        variant={'head'}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {joinerEducationDataList.map(
                    (
                      {
                        id,
                        qualification_name,
                        period_from,
                        period_to,
                        institute_name,
                        course_type,
                        marks,
                        is_editable,
                      },
                      index
                    ) => {
                      return (
                        <TableRow hover tabIndex={-1} key={index}>
                          <TableCell align={'left'} size={'small'}>
                            {qualification_name}
                          </TableCell>
                          <TableCell align={'left'} size={'small'}>
                            {course_type}
                          </TableCell>
                          <TableCell align={'left'} size={'small'}>
                            {institute_name}
                          </TableCell>
                          <TableCell align={'left'} size={'small'}>
                            {period_from}
                          </TableCell>
                          <TableCell align={'left'} size={'small'}>
                            {period_to}
                          </TableCell>
                          <TableCell align={'left'} size={'small'}>
                            {marks}
                          </TableCell>
                          <TableCell align={'left'} size={'small'}>
                            {is_editable ? (
                              <div onClick={(e) => handleRecordEdit(e, id)}>
                                <EditIcon />
                              </div>
                            ) : (
                              <div></div>
                            )}
                          </TableCell>
                          <TableCell align={'left'} size={'small'}>
                            {is_editable ? (
                              <div onClick={(e) => handleDialogOpen(id)}>
                                <DeleteForeverIcon />
                              </div>
                            ) : (
                              <div></div>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
        <Grid item sm={2}>
          <Paper className={classes.paperGeneral}>
            <div>
              <ul>
                <li className='unorder-list'>&nbsp;</li>
                <li className='unorder-list'>General Instructions:</li>
                <li className='unorder-list'>&nbsp;</li>
                <li>Provide your education details in reverse chronological order i.e. highest education should be mentioned first and the rest followed. For example, if you have done post-graduation, then, order of mentioning your education details shall be i) Post Graduation, ii) Graduation, XII and X. You can click on ADD to add more than one education details.</li>
                <li>To move ahead, click SAVE & NEXT.</li>
              </ul>
            </div>
            <DialogComponent
              openDialog={openDialog}
              dialogTitle={dialogTitle}
              dialogContentText='Are you sure you want to delete this education record?'
              handleDialogClose={handleDialogClose}
            />
          </Paper>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default Education;
