import React from 'react'

import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import Tooltip from '@material-ui/core/Tooltip'
import Zoom from '@material-ui/core/Zoom'

// import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
// import ArrowDropUp from '@material-ui/icons/ArrowDropUp'

import { useStyles } from "./style"

const PendingTableHeader = (props) => {
    const {
        handleSortRequest,
        order,
        orderBy,
        columns,
        index,
        isDetailedPage
      } = props
      const classes = useStyles()

    const getCellLabelName = (headCell) => {
      let label =  <span>
                    {headCell.label} 
                    { headCell.id === "pending_days" ? <Tooltip style={{cursor:'pointer', fontSize: "0.9rem" }} TransitionComponent={Zoom} title={headCell.pendencyInfo}><InfoOutlinedIcon fontSize="inherit" /></Tooltip> : ""}
                  </span>
      
      if (isDetailedPage) {
        let cellName = []
        switch (headCell.id) {
          case "emp_code":
          case "emp_name":
          case "doj":
            cellName = headCell.label.split(" ")
            label = <span>{cellName[0]}<br></br>{cellName[1]}</span>
            break
          case "pending_days":
            cellName = headCell.label.split(" ")
            label = <div style={{ width: "75px" }}>{cellName[0]}<Tooltip style={{cursor:'pointer', fontSize: "0.9rem"}} TransitionComponent={Zoom} title={headCell.pendencyInfo}><InfoOutlinedIcon fontSize="inherit" /></Tooltip><br></br>{cellName[1]} {cellName[2]}</div>
            break
          default:
            label = <span>{headCell.label}</span>
            break
        } 
      }

      return label
    }
      return (
        <TableHead className={classes.tableHeader}>
          <TableRow key={index}>
            { isDetailedPage ? <TableCell
              align='center'
              padding='normal'
              className={isDetailedPage ? classes.tableHeadCellDetail : classes.tableHeadCell}
            >
              <div
                    style={{
                      display: 'flex',
                      justifyContent: 'left',
                      alignItems: 'center'
                    }}
                  >S. No.</div>
            </TableCell> : (null)}
            {columns.map((headCell, i) => (
              <TableCell
                key={`${headCell.id}_${index}_${i}`}
                align='center'
                padding='normal'
                className={`${isDetailedPage ? classes.tableHeadCellDetail : classes.tableHeadCell} ${
                  headCell.removePadding ? classes.pad0 : ''
                }`}
                onClick={
                  headCell.disableSorting || !isDetailedPage
                    ? null
                    : () => {
                        handleSortRequest(headCell.id)
                      }
                }
              >
                {headCell.disableSorting || !isDetailedPage ? (
                  getCellLabelName(headCell)
                ) : (
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                  >
                    {getCellLabelName(headCell)}
                    {orderBy === headCell.id ? (
                      <span className={classes.visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </span>
                    ) : null}
                  </TableSortLabel>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
    )
}

export default PendingTableHeader