import React from "react"
import { 
    Grid,
    Card,
    Divider
} from "@material-ui/core"
import { useStyles } from "./style"

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js"
import { Bar } from "react-chartjs-2"

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
)

const options = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 1,
    plugins: {
        legend: {
            display: false
        }
    },
    scales: {
        x: {
            grid: {
                display: false
            },
            ticks: {
                display: true,
                padding: 0,
                font: {
                    size: 9.5,
                }
            }
        },
        y: {
            beginAtZero: true,
            border: {
                dash: [2, 4],
            },
        }
    }
}

const colorScheme = {
    "0-20" : "#962DFF",
    "20.01-40" : "#4A3AFF",
    ">40": "#E0C6FD"
}

const getPercentOfTotal = ({ totalCount, recordCount }) => {
    return (recordCount * 100)/totalCount
}

const getColorCode = (percentVal) => {
    let colorCode = ``
    if (percentVal > 0 && percentVal <= 20) {
        colorCode = colorScheme["0-20"]
    } else if (percentVal > 20 && percentVal <= 40) {
        colorCode = colorScheme["20.01-40"]
    } else if (percentVal > 40) {
        colorCode = colorScheme[">40"]
    }

    return colorCode
}

const getGraphLabelList = ({ obj }) => {
    return Object.keys(obj).sort((a, b) => b.localeCompare(a))
} 

const getDataSets = ({ labels, records, totalCount }) => {
    let datasets = [{
        label: "",
        data: [],
        backgroundColor: [],
        
        barPercentage: 0.4,
        categoryPercentage: 0.3,
        borderRadius: 5,
    }]

    
    for (const labelName of labels) {
        datasets[0].data.push(records[labelName])

        const percentVal = getPercentOfTotal({ totalCount, recordCount: records[labelName] })
        const colorCode = getColorCode(percentVal)
        datasets[0].backgroundColor.push(colorCode)
    }

    return datasets
}

const getMultiLabels = ({ labels = [] }) => {
    let result = []
    for (const label of labels) {
        let arr = label.trim().split(' ')
        if (arr.length > 1) {
            result.push(arr)
        } else {
            result.push(label)
        }
    }

    return result
}

const GrossOfferCard = ({ 
    grossOfferRecord = {},
    handleDetailedData = () => {}
}) => {
    const classes = useStyles()
    const { totalOfferCount = 0, grossOffer = {} } = grossOfferRecord

    const labels = getGraphLabelList({ obj: grossOffer })
    const datasets = getDataSets({ labels, records: grossOffer, totalCount: totalOfferCount })
    
    return (<Grid item xs={4} sm={4} md={4} className={classes.gridBody}>
                <Card style={{ height: "340px" }}>
                    <div onClick={e => handleDetailedData("GrossOffer")}>
                        <div style={{ padding: "5 0 5 10" }}>
                            <label style={{ fontSize: "1.17em", fontWeight: "bold" }}>Gross Offers</label>
                        </div>
                        <div style={{ padding: "5 0 5 0" }}>
                            <Divider variant="middle" />
                        </div>
                        <div style={{ width: "290px", height: "270px" }}>
                            <Bar options={options} data={{ labels: getMultiLabels({ labels }), datasets }} />
                        </div>
                        <div style={{ textAlign: "center", padding: "0 0 10 0" }}>
                            <span><b>Total Gross Offers : { totalOfferCount }</b></span>
                        </div>
                    </div>
                    </Card>
                </Grid>
            )
}

export default GrossOfferCard