import { makeStyles } from '@material-ui/core/styles'
import blue from "@material-ui/core/colors/blue"

export const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      textAlign: 'center',  
      minWidth: 120,
      minHeight: 340,
      color: blue[900],
      marginTop: '18%',
      fontSize: 30
    }
}));
  