import { makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiFormControl-root': {
            width: '80%',
            padding: theme.spacing(1),
            // margin: theme.spacing(3)
        }
    },

    button: {
        margin: 'auto',
        padding: theme.spacing(1),
    },
    notification: {
        padding: theme.spacing(1),
        margin: theme.spacing(1),
        marginLeft: 0,
        textAlign: 'center',
      },
      input: {
        display: "none"
      }
}))