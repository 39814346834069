import React, { useState,useEffect } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from 'moment';
import { useStyles } from './style'
import { Button, FormControl, Grid, TextField, InputLabel, Select, MenuItem, Paper} from '@material-ui/core'
import { orange } from '@material-ui/core/colors'
import { withStyles } from '@material-ui/core/styles'
import { probationConstant } from '../../../constants/ProbationConstant';
import { 
  getDepartmentListFetchData, 
  getSubDepartmentFetchData, 
  getGradeListFetchData,
  getLocationListFetchData,
  getDesignationListFetchData
} from '../../Shared/FetchData';
const { EMPLOYEE, HRBP, SUPER_ADMIN } = probationConstant.loggedInUserGroup

const INITIAL_PARTIAL_FILTER_OBJ = {
  empName: '',
  dojStartDate: null,
  dojEndDate: null
}

const INITIAL_FULL_FILTER_OBJ = {
  empName: '',
  empNo: '',
  department: '',
  subDepartment: '',
  grade: '',
  designation: '',
  reportingManager: '',
  location: '',
  dojStartDate: null,
  dojEndDate: null,
}
const GRADE_LIST = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
const PreviousButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(orange[500]),
    backgroundColor: orange[500],
    '&:hover': {
      backgroundColor: orange[700]
    }
  }
}))(Button)

export default function Filter({
  loggedInUser,
  sendFilterObj,
  isForReport=false
}) {
  const classes = useStyles()
  const [initialFilterObj, setInitialFilterObj] = useState(INITIAL_FULL_FILTER_OBJ) 
  const [filterObj, setFilterObj] = useState(INITIAL_FULL_FILTER_OBJ)
  const [isPartialFilterForPAA, setIsPartialFilterForPAA] = useState(true);
  const [departmentList, setDepartmentList] = useState([])
  const [subDepartmentList, setSubDepartmentList] = useState([])
  const [gradeList, setGradeList] = useState([])
  const [gradeNameList, setGradeNameList] = useState([])
  const [designationList, setDesignationList] = useState([])
  const [locationList, setLocationList] = useState([])
  const [isReportForRHR, setIsReportForRHR] = useState(false)
  const handleReset = () => {
    setFilterObj(initialFilterObj)
    sendFilterObj(initialFilterObj)
  }
  const handleSearch = () => {
    sendFilterObj(filterObj);
  }

  const handleDateChange = (value, elementName) => {
    let updatedFilterObj = filterObj;
    const formattedValue =  value;
    if(elementName == 'dojStartDate') {
      updatedFilterObj = {...filterObj, [elementName]: formattedValue, dojEndDate: null}
    } else if(elementName == 'dojEndDate') {
      updatedFilterObj = {...filterObj, [elementName]: formattedValue}
    }
    setFilterObj(updatedFilterObj)
  }  

  const handleInputChange = (event, elementName) => {
    const value = event.target.value;
    let updatedObj = filterObj;
    if(elementName == 'empName'){
      updatedObj = {...filterObj, [elementName]: value}
    } else if(elementName == 'empNo'){
      updatedObj = {...filterObj, [elementName]: value}
    } else if(elementName == 'department'){
      updatedObj = {...filterObj, [elementName]: value, subDepartment: ''}
      getSubDepartment(value)
    } else if(elementName == 'subDepartment'){
      updatedObj = {...filterObj, [elementName]: value}
    } else if(elementName == 'grade'){
      updatedObj = {...filterObj, [elementName]: value, designation: ''}
      let selectedGradeRecord = gradeList.filter((record) => {
        return record.grade_name == value && record.department_id == updatedObj.department
      })
      if(selectedGradeRecord.length && selectedGradeRecord[0].grade_id) {
        getDesignationList(selectedGradeRecord[0].grade_id);
      }
    } else if(elementName == 'designation'){
        updatedObj = {...filterObj, [elementName]: value, }
    } else if(elementName == 'reportingManager'){
      updatedObj = {...filterObj, [elementName]: value}
    } else if(elementName == 'location'){
      updatedObj = {...filterObj, [elementName]: value}
    }
    setFilterObj(updatedObj);
  }

  const getDepartmentList = async () => {
    try {
        let response = {}
        response = await getDepartmentListFetchData()
        let applicableDepartment = [];
        if(response && response.data && response.data.length){ // show only those applicable dept for probation
          applicableDepartment = response.data.filter((dept) => {
            return probationConstant.applicableDepartmentListForProbation.includes(dept.department_id);
          })
          if(loggedInUser.userGroup == HRBP) { // show only serving department for Report in RHR dashboard
            const serveringHrbpDept = Object.keys(loggedInUser.hrbpDetails)
            applicableDepartment = applicableDepartment.filter((record) => {
              return serveringHrbpDept.includes(record.department_id.toString())
            })
          }
          setDepartmentList(applicableDepartment)
        }
    } catch (error) {
        
    }
  }

  const getSubDepartment = async (departmentId) => {
    try {
      let response = {}
      response = await getSubDepartmentFetchData({departmentId:[departmentId]})
      if(response && response.data){
          setSubDepartmentList(response.data)
      }
    } catch (error) {
    }
  }

  const getGradeList = async () => {
    try {
      let finalResponse = []
      let departmentList = probationConstant.applicableDepartmentListForProbation
      let response = await getGradeListFetchData({ departmentIds:departmentList })
      if(response && response.data){
          finalResponse = response.data.filter((item) => {
            return probationConstant.probationApplicableGradeList.includes(parseInt(item.grade_name, 10))
          })
      }
      setGradeList(finalResponse)
    } catch (error) {
      
    }
  }

  const getDesignationList = async (gradeId) => {
    try {
      const response = await getDesignationListFetchData({gradeIds: [gradeId]});
      if(response && response.data) {
        setDesignationList(response.data);
      }
    } catch(error) {
      
    }
  }

  const getLocationList = async () => {
    try {
      const response = await getLocationListFetchData()
      let applicableLocation = []
      if(response && response.data) {
        applicableLocation = response.data
        if(loggedInUser.userGroup == HRBP || loggedInUser.role == probationConstant.loggedInUserRole.RHR) { // show only serving location in Reports for RHR dashboard
          let hrbpServingLocation = []
          Object.values(loggedInUser.hrbpDetails).forEach(locationArr => {
            hrbpServingLocation = [...hrbpServingLocation, ...locationArr]
          });
          hrbpServingLocation  = hrbpServingLocation.filter((value, index, arr) => {
            return index == arr.indexOf(value);
          })
          applicableLocation = applicableLocation.filter((record) => {
            return hrbpServingLocation.includes(record.id)
          })
        }
        setLocationList(applicableLocation)
      }     
    } catch (error) {
      
    }
  }

  useEffect(() => {
    let initialFilterObj = INITIAL_PARTIAL_FILTER_OBJ
    let isPartialFilterForPAA = false
    let isReportForRHR = false
    let applicableGradeNameList = []
    if(isForReport) {
      initialFilterObj = {...INITIAL_FULL_FILTER_OBJ, isReport: true}
      if(loggedInUser.userGroup == HRBP) {
        initialFilterObj = {
          ...initialFilterObj,
          hrbpFilter: loggedInUser.hrbpDetails
        }
        isReportForRHR = true;
      }
      getDepartmentList()
      getLocationList()
    } else {
      isPartialFilterForPAA = true
      if(loggedInUser.role != probationConstant.loggedInUserRole.PAA) {
        initialFilterObj = INITIAL_FULL_FILTER_OBJ
        if(loggedInUser.role == probationConstant.loggedInUserRole.RHR) {
          initialFilterObj = {
            ...initialFilterObj,
            hrbpFilter: loggedInUser.hrbpDetails
          }
        }
        isPartialFilterForPAA = false
        getDepartmentList()
        getLocationList()
      }
    }
    applicableGradeNameList = GRADE_LIST.filter(grade => {
      return probationConstant.probationApplicableGradeList.includes(grade)
    })
    getGradeList()
    setInitialFilterObj(initialFilterObj)
    setFilterObj(initialFilterObj)
    setIsPartialFilterForPAA(isPartialFilterForPAA);
    setIsReportForRHR(isReportForRHR)
    setGradeNameList(applicableGradeNameList)
  }, []);

  if(isPartialFilterForPAA) {
    return <Paper elevation={4}>
      <form className={classes.formRoot} noValidate autoComplete='off'>
        <Grid container spacing={4} direction="row" justifyContent="space-around" alignItems='center'>
          <Grid item xs={4} sm={12} md={4}>
            <TextField
              fullWidth={true}
              id='outlined-basic'
              label='Employee Name'
              placeholder='Employee Name'
              name='empName'
              value={filterObj.empName || ''}
              onChange={e => handleInputChange(e, 'empName')}
              className={classes.formControl}
            />
          </Grid>
          <Grid item xs={4} sm={12} md={4}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                label='Group DOJ Start Date'
                autoOk={true}
                format="yyyy-MM-dd"
                clearable={false}
                name='dojStartDate'
                value={filterObj.dojStartDate}
                onChange={value => handleDateChange(value, 'dojStartDate')}
                disableFuture={true}
                inputProps={{ readOnly: true }}
                minDate={moment(probationConstant.policyInfo.PROBATION_POLICY_START_DATE)}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={4} sm={12} md={4}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                label='Group DOJ End Date'
                autoOk={true}
                format="yyyy-MM-dd"
                clearable={false}
                name='dojEndDate'
                value={filterObj.dojEndDate}
                onChange={value => handleDateChange(value, 'dojEndDate')}
                disableFuture={true}
                inputProps={{ readOnly: true }}
                minDate={filterObj.dojStartDate ? moment(filterObj.dojStartDate) : moment(probationConstant.policyInfo.PROBATION_POLICY_START_DATE)}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
        <Grid
            container
            spacing={4}
            className={classes.marginTop}
            direction='row'
            alignItems='center'
        >
          <Grid item xs={4} sm={12} md={4}>
            <PreviousButton
                variant='outlined'
                color='primary'
                className={classes.resetButton}
                size='small'
                onClick={() => handleReset()}
            >
                Reset
            </PreviousButton>
            <Button
                variant='contained'
                color='primary'
                size='small'
                style={{ marginLeft: 50 }}
                onClick={handleSearch}
                className={classes.button}
            >
                Search
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper> 
  } else {
    return <Paper elevation={4}>
    <form className={classes.formRoot} noValidate autoComplete='off'>
      <Grid container spacing={4} >
        <Grid item xs={4} sm={12} md={4}>
          <TextField
            fullWidth={true}
            id='outlined-basic'
            label='Employee Name'
            placeholder='Employee Name'
            name='empName'
            value={filterObj.empName || ''}
            onChange={e => handleInputChange(e, 'empName')}
            className={classes.formControl}
          />
        </Grid>
        <Grid item xs={4} sm={12} md={4}>
          <TextField
            fullWidth={true}
            id='outlined-basic'
            label='Employee Code'
            placeholder='Employee Code'
            name='empNo'
            value={filterObj.empNo || ''}
            onChange={e => handleInputChange(e, 'empNo')}
            className={classes.formControl}
          />
        </Grid>
        <Grid item xs={4} sm={12} md={4}>
          <FormControl className={classes.formControl}>
            <InputLabel id='demo-simple-select-outlined-label'>
              Department
            </InputLabel>
            <Select
              labelId='demo-simple-select-outlined-label'
              id='demo-simple-select-outlined'
              name='department'
              value={filterObj.department || ''}
              onChange={e => handleInputChange(e, 'department')}
              >
              {departmentList.length > 0 &&
                  departmentList.map((item, index) => (
                  <MenuItem value={item.department_id} key={item.department_id}>
                      {item.department}
                  </MenuItem>
                  ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={4} sm={12} md={4}>
          <FormControl className={classes.formControl}>
            <InputLabel id='demo-simple-select-outlined-label'>
              Sub Department
            </InputLabel>
            <Select
              labelId='demo-simple-select-outlined-label'
              id='demo-simple-select-outlined'
              name='subDepartment'
              value={filterObj.subDepartment || ''}
              onChange={e => handleInputChange(e, 'subDepartment')}
              >
              {subDepartmentList.length > 0 &&
                  subDepartmentList.map((item, index) => (
                  <MenuItem value={item.function_id} key={item.function_id}>
                      {item.function}
                  </MenuItem>
                  ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4} sm={12} md={4}>
          <FormControl className={classes.formControl}>
            <InputLabel id='demo-simple-select-outlined-label'>
              Grade
            </InputLabel>
            <Select
              labelId='demo-simple-select-outlined-label'
              id='demo-simple-select-outlined'
              name='grade'
              value={filterObj.grade || ''}
              onChange={e => handleInputChange(e, 'grade')}
              >
              {gradeNameList.length > 0 &&
                  gradeNameList.map((item, index) => (
                  <MenuItem value={item} key={item}>
                      {item}
                  </MenuItem>
                  ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4} sm={12} md={4}>
          <FormControl className={classes.formControl}>
            <InputLabel id='demo-simple-select-outlined-label'>
              Designation
            </InputLabel>
            <Select
              labelId='demo-simple-select-outlined-label'
              id='demo-simple-select-outlined'
              name='designation'
              value={filterObj.designation || ''}
              onChange={e => handleInputChange(e, 'designation')}
              >
              {designationList.length > 0 &&
                  designationList.map((item, index) => (
                  <MenuItem value={item.designation_id} key={item.designation_id}>
                      {item.designation}
                  </MenuItem>
                  ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={4}>  
        <Grid item xs={4} sm={12} md={4}>
          <TextField
            fullWidth={true}
            id='outlined-basic'
            label='Reporting Manager Name'
            placeholder='Reporting Manager Name'
            name='reportingManager'
            value={filterObj.reportingManager || ''}
            onChange={e => handleInputChange(e, 'reportingManager')}
            className={classes.formControl}
          />
        </Grid>
        <Grid item xs={4} sm={12} md={4}>
          <FormControl className={classes.formControl}>
            <InputLabel id='demo-simple-select-outlined-label'>
              Location
            </InputLabel>
            <Select
              labelId='demo-simple-select-outlined-label'
              id='demo-simple-select-outlined'
              name='location'
              value={filterObj.location || ''}
              onChange={e => handleInputChange(e, 'location')}
              >
              {locationList.length > 0 &&
                  locationList.map((item, index) => (
                  <MenuItem value={item.id} key={item.id}>
                      {item.locality}
                  </MenuItem>
                  ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>  
      <Grid container spacing={4} className={classes.dateInput}>  
        <Grid item xs={4} sm={12} md={4}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              label='Group DOJ Start Date'
              autoOk={true}
              format="yyyy-MM-dd"
              clearable={false}
              name='dojStartDate'
              value={filterObj.dojStartDate}
              onChange={(value) => handleDateChange(value, 'dojStartDate')}
              disableFuture={true}
              inputProps={{ readOnly: true }}
              minDate={moment(probationConstant.policyInfo.PROBATION_POLICY_START_DATE)}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={4} sm={12} md={4}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              label='Group DOJ End Date'
              autoOk={true}
              format="yyyy-MM-dd"
              clearable={false}
              name='dojEndDate'
              value={filterObj.dojEndDate}
              onChange={(value) => handleDateChange(value, 'dojEndDate')}
              disableFuture={true}
              inputProps={{ readOnly: true }}
              minDate={filterObj.dojStartDate ? moment(filterObj.dojStartDate) : moment(probationConstant.policyInfo.PROBATION_POLICY_START_DATE)}
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
      <Grid
          container
          spacing={4}
          className={classes.button}
      >
        <Grid item xs={4} sm={12} md={4}>
          <PreviousButton
              variant='outlined'
              color='primary'
              className={classes.resetButton}
              size='small'
              onClick={() => handleReset()}
          >
              Reset
          </PreviousButton>
          <Button
              variant='contained'
              color='primary'
              size='small'
              style={{ marginLeft: 50 }}
              onClick={handleSearch}
              className={classes.button}
          >
              Search
          </Button>
        </Grid>
      </Grid>
    </form>
    </Paper>
  }
}
