import React from 'react'
import { Paper, Card, Typography } from '@material-ui/core'
import {useStyles} from './style'


export default function HeaderLayout(props) {
    const classes = useStyles();
    const { title, subTitle, icon, redirectUrl = "" } = props

    return (
        <Paper elevation={0} square className={classes.root}>
            <div className={classes.pageHeader}>
                <Card className={classes.pageIcon}>
                    {icon}
                </Card>
                <div className={classes.pageTitle}>
                    { title ? <Typography variant="h6" component="div">{title}</Typography> : null }
                    { subTitle ?
                        <Typography
                            variant="subtitle2"
                            component="div">
                            {
                                redirectUrl !== "" ? <span><a target="_blank" className={classes.anchorLink} href={redirectUrl}>{subTitle}</a></span> : <span className={classes.anchorLink}>{subTitle}</span>
                            }
                            </Typography> : null 
                    }
                </div>
            </div>
        </Paper>
    )
}
