import React, { useState,useEffect } from 'react';
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CardHeader from '@material-ui/core/CardHeader';
import Card from '@material-ui/core/Card';
import HeaderLayout from '../HeaderLayout'
import PeopleOutlineTwoTone from '@material-ui/icons/PeopleOutlineTwoTone'

import { useStyles } from './style'
import { Button, FormControl, Grid, Snackbar, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from '@material-ui/core'
import { getDocumentMasterListFetchData, postDocumentMasterConfigFetchData, getLocationListFetchData, getAllEmployeeDocumentDetails } from '../fetchData';
import MuiAlert from '@material-ui/lab/Alert/Alert';
import { efilingConstant } from '../../../constants/EfilingConstant';
import logo from '../../../utils/load.gif'
import qs from 'qs'
import { getMenuSubmenuRightsDetail } from '../../../utils/authFunction';
import { pageURLConfig, menuSubmenuPageCategory } from '../../../constants/CommonConstant';
const { moduleName, controllerName, actionName } = pageURLConfig.EFILING_INDEX_DOCUMENT_MASTER_CONFIG
const { ADMIN_CATEGORY } = menuSubmenuPageCategory

const INITIAL_DOCUMENT_DETAILS_OBJECT = {
    document_type: '',
    document_alias: '',
    is_mandatory: '',
    is_reminder: '',
    visibility: '',
    applicability: '',
    isEditing: false,
    editDocumentId: 0
}

const DocumentDetails = ({handleNewDocumentDetail,props,documentMasterListAndIdMapping, isHavingPageAddAccess, isHavingPageEditAccess}) => {
    const classes = useStyles()
    const [expandDefault, setExpandDefault] = useState(false)
    const [newDocumentDetails, setNewDocumentDetails] = useState(INITIAL_DOCUMENT_DETAILS_OBJECT)

    useEffect( () => {
        checkEditable()
    }, [documentMasterListAndIdMapping])

    const handleChange = event => {
        setNewDocumentDetails({ ...newDocumentDetails, [event.target.name]: event.target.value })
    }

    const checkEditable = async () => {
        let documentId = qs.parse(props.location.search, { ignoreQueryPrefix: true }).id
        if(documentId){
            documentId = atob(documentId)
        }
        if(documentMasterListAndIdMapping && documentMasterListAndIdMapping[documentId]){
            setNewDocumentDetails({...newDocumentDetails,
                                document_type: documentMasterListAndIdMapping[documentId]['document_type'],
                                document_alias: documentMasterListAndIdMapping[documentId]['document_alias'],
                                is_mandatory: documentMasterListAndIdMapping[documentId]['is_mandatory'],
                                visibility: documentMasterListAndIdMapping[documentId]['visibility'],
                                applicability: documentMasterListAndIdMapping[documentId]['applicability'],
                                isEditing: true,
                                editDocumentId: documentId
            })
            setExpandDefault(true)
        }
    }

    return (
            <Accordion
                expanded={expandDefault}
                onChange={() => setExpandDefault(!expandDefault)}
                className={classes.pageContent}
                style={{margin:'9px'}}
                
            >
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1a-content'
                id='panel1a-header'
                className={classes.pageContent}
                >                   
                    <Grid>
                        <Card>
                            <CardHeader
                                title="Add/Edit Document"
                                style={{ color: '#3f51b5'}}
                                titleTypographyProps={{variant:'subtitle1' }}
                            />
                        </Card>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails className={classes.pageContent}>
                <Grid container>
                <Paper elevation={2} className={classes.root}>
                    <form className={classes.formRoot} noValidate autoComplete='off'>
                        <Grid container>
                            <Grid container spacing={3}>
                                <Grid item xs={4} sm={12} md={4}>
                                <TextField
                                    id='outlined-basic'
                                    label='Document Type'
                                    placeholder='Document Type'
                                    name='document_type'
                                    value={newDocumentDetails.document_type || ''}
                                    onChange={e => handleChange(e)}
                                    className={classes.formControl}
                                    inputProps={
                                        { readOnly: false, }
                                    }
                                />
                                </Grid>
                                <Grid item xs={4} sm={12} md={4}>
                                <TextField
                                    id='outlined-basic'
                                    label='Document Alias'
                                    placeholder='Document Alias'
                                    name='document_alias'
                                    value={newDocumentDetails.document_alias || ''}
                                    onChange={e => handleChange(e)}
                                    className={classes.formControl}
                                    inputProps={
                                        { readOnly: newDocumentDetails.isEditing, }
                                    }
                                />
                                </Grid>
                                <Grid item xs={4} sm={12} md={4}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel id='demo-simple-select-outlined-label'>
                                    Is Mandatory
                                    </InputLabel>
                                    <Select
                                    labelId='demo-simple-select-outlined-label'
                                    id='demo-simple-select-outlined'
                                    name='is_mandatory'
                                    value={newDocumentDetails.is_mandatory || ''}
                                    onChange={e => handleChange(e)}
                                    >
                                    <MenuItem value='1'>YES</MenuItem>
                                    <MenuItem value='0'>NO</MenuItem>
                                    </Select>
                                </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item xs={4} sm={12} md={4}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel id='demo-simple-select-outlined-label'>
                                    Visibility
                                    </InputLabel>
                                    <Select
                                    labelId='demo-simple-select-outlined-label'
                                    id='demo-simple-select-outlined'
                                    name='visibility'
                                    value={newDocumentDetails.visibility || ''}
                                    onChange={e => handleChange(e)}
                                    >
                                    {/* <MenuItem value={efilingConstant.DOCUMENT_VISIBILITY.EMPLOYEE}>EMPLOYEE</MenuItem> */}
                                    <MenuItem value={efilingConstant.DOCUMENT_VISIBILITY.HROPS}>HROPS</MenuItem>
                                    <MenuItem value={efilingConstant.DOCUMENT_VISIBILITY.BOTH}>BOTH HROPS AND EMPLOYEE</MenuItem>
                                    </Select>
                                </FormControl>
                                </Grid>
                                <Grid item xs={4} sm={12} md={4}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel id='demo-simple-select-outlined-label'>
                                    Applicability
                                    </InputLabel>
                                    <Select
                                    labelId='demo-simple-select-outlined-label'
                                    id='demo-simple-select-outlined'
                                    name='applicability'
                                    value={newDocumentDetails.applicability || ''}
                                    onChange={e => handleChange(e)}
                                    >
                                    <MenuItem value={efilingConstant.DOCUMENT_APPLICABILITY.ALL_EMPLOYEES}>ALL EMPLOYEES</MenuItem>
                                    <MenuItem value={efilingConstant.DOCUMENT_APPLICABILITY.NOT_ALL_EMPLOYEES}>NOT ALL EMPLOYEES</MenuItem>
                                    </Select>
                                </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            spacing={3}
                            className={classes.marginTop}
                            direction='row'
                            alignItems='center'
                        >
                            <Grid item>
                            <Button
                                variant='contained'
                                color='primary'
                                size='small'
                                style={{ marginLeft: 20 }}
                                onClick={() => handleNewDocumentDetail(newDocumentDetails)}
                                className={classes.button}
                                disabled={newDocumentDetails.isEditing ? !isHavingPageEditAccess : !isHavingPageAddAccess}
                            >
                                {newDocumentDetails.isEditing ? 'Update' : 'Add'}
                            </Button>
                            </Grid>
                            {newDocumentDetails.isEditing ? 
                            <Grid item>
                            <Button
                                variant='contained'
                                color='primary'
                                size='small'
                                style={{ marginLeft: 20 }}
                                className={classes.button}
                                disabled={!isHavingPageAddAccess}
                            >
                                <a className={classes.anchorWhite} target='blank' href={process.env.REACT_APP_BASE_URL+'/efiling/index/document-master-config/m/ep'}>
                                Add New Document</a>
                            </Button>
                            </Grid> : ''}
                        </Grid>
                    </form>
                </Paper>
                </Grid>
                </AccordionDetails>
            </Accordion>
    )
}


export default function DocumentMasterConfigScreen(props) { 
    const classes = useStyles()
    const [isLoading, setIsLoading] = useState(false)
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(200);
    const [documentMasterListAndIdMapping, setDocumentMasterListAndIdMapping] = useState([])
    const [snackBarDetails, setSnackBarDetails] = useState({open:false,errorMessage:'',type:''})
    const [isHavingPageAddAccess, setIsHavingPageAddAccess] = useState(false)
    const [isHavingPageEditAccess, setIsHavingPageEditAccess] = useState(false)
    const [isHavingPageViewAccess, setIsHavingPageViewAccess] = useState(null)
    useEffect( () => {
        getDocumentMasterList()
        setPageRights()
    },[])

    const setPageRights = async () => {
        const params = { pageCategory: ADMIN_CATEGORY, moduleName, controllerName, actionName}
        const rightsObj = await getMenuSubmenuRightsDetail(params)
        const { 
            isUserSuperAdmin,
            isUserGroupAdmin,
            allEmployeeAddRights,
            allEmployeeEditRights, 
            allEmployeeViewRights 
       } = rightsObj
       const addAccess = isUserSuperAdmin || 
            (isUserGroupAdmin && allEmployeeAddRights) 
       const editAccess =  isUserSuperAdmin || 
            (isUserGroupAdmin && allEmployeeEditRights)
       const viewAccess =  isUserSuperAdmin || 
            (isUserGroupAdmin && allEmployeeViewRights)
        
        setIsHavingPageAddAccess(addAccess)
        setIsHavingPageEditAccess(editAccess)
        setIsHavingPageViewAccess(viewAccess)
   }

    const handleNewDocumentDetail = async ( newDocumentDetails ) => {
        try {
            setIsLoading(true)
            let validateResponse = await validateNewDocumentDetail(newDocumentDetails)
            let response = {}
            if(validateResponse && validateResponse.error) {
                setSnackBarDetails({open:true,errorMessage:validateResponse.message,type:'error'})
                setIsLoading(false)
            } else {
                response = await postDocumentMasterConfigFetchData({newDocumentDetails})
                if(response && response.data && response.data.message){
                    setSnackBarDetails({open:true,errorMessage:response.data.message,type:'success'})
                    setIsLoading(false)
                } else {
                    setSnackBarDetails({open:true,errorMessage:'something went wrong',type:'error'})
                }
            }
            
            getDocumentMasterList()
            setPage(0);
            
        } catch (error) {
            
        }
    }

    const validateNewDocumentDetail = async (newDocumentDetails) => {
        let validateResponse = {message:'',error:false}
        if(newDocumentDetails){
            if(!newDocumentDetails.document_type){
                validateResponse.message = 'Document Type is required'
                validateResponse.error = true
                return validateResponse
            }

            if(!newDocumentDetails.document_alias){
                validateResponse.message = 'Document alias is required'
                validateResponse.error = true
                return validateResponse
            }

            let checkDuplicateDocumentAlias = false
            Object.keys(documentMasterListAndIdMapping).map( (documentId) => {
                
                if(documentMasterListAndIdMapping[documentId]['document_alias'] ===  newDocumentDetails.document_alias){
                    checkDuplicateDocumentAlias = true
                }
            })
            
            if(checkDuplicateDocumentAlias && !newDocumentDetails.isEditing){
                validateResponse.message = 'Document alias is already present'
                validateResponse.error = true
                return validateResponse
            }

            if(!newDocumentDetails.is_mandatory){
                validateResponse.message = 'Mandatory field is required'
                validateResponse.error = true
                return validateResponse
            }

            if(!newDocumentDetails.visibility){
                validateResponse.message = 'Visibility field is required'
                validateResponse.error = true
                return validateResponse
            }

            if(!newDocumentDetails.applicability){
                validateResponse.message = 'Applicability field is required'
                validateResponse.error = true
                return validateResponse
            }

        }
        return validateResponse
    }


    const getDocumentMasterList = async () => {
        try {
            let response = {}
            response = await getDocumentMasterListFetchData();
            let documentDetailAndIdMapping = {}
            response.data.forEach(element => {
                documentDetailAndIdMapping[element.id] = element
            })
            setDocumentMasterListAndIdMapping(documentDetailAndIdMapping)
        } catch (error) {
            
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };
    
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackBarDetails({open:false,errorMessage:'',type:''});
    };


    return (
        <React.Fragment>
            {(!isHavingPageViewAccess) 
            ? 
                (isHavingPageViewAccess == null) ? <div className={classes.loader}><img src={logo} alt="loading..." /></div> :
                <div> <h1 style={{margin:'0px'}}>Unauthorised </h1> <br></br> <span>You don't have access to view this page</span> </div> 
            :
            <div className={classes.root}>
                {snackBarDetails && snackBarDetails.open ? 
                    <Snackbar
                        anchorOrigin={{ vertical:'top', horizontal:'center' }}
                        open={snackBarDetails.open}
                        autoHideDuration={4000}
                        onClose={handleSnackbarClose}
                    >
                        <MuiAlert severity={snackBarDetails.type}>{snackBarDetails.errorMessage}</MuiAlert>
                    </Snackbar> : (null)
                }
                <Grid container>
                    <Grid> 
                    <HeaderLayout
                        title='Document Master'
                        subTitle='Config'
                        icon={<PeopleOutlineTwoTone/>}
                    />
                    </Grid>
                </Grid>
                <DocumentDetails
                handleNewDocumentDetail={handleNewDocumentDetail}
                props={props}
                documentMasterListAndIdMapping={documentMasterListAndIdMapping}
                isHavingPageAddAccess={isHavingPageAddAccess}
                isHavingPageEditAccess={isHavingPageEditAccess}
                />
                { ( isLoading ) 
                ? 
                <div className={classes.loader}><img src={logo} alt="loading..." /></div>
                : 
                <Paper className={classes.pageContent} elevation={5}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell>Document Type</TableCell>
                                    <TableCell style={{textAlign:'center'}}>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            { ( documentMasterListAndIdMapping && Object.keys(documentMasterListAndIdMapping).length > 0 ) ? Object.keys(documentMasterListAndIdMapping).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((element,key)=>(
                                <TableRow key={key}>
                                    <TableCell >{key + 1}</TableCell>
                                    <TableCell>{documentMasterListAndIdMapping[element]['document_type']}</TableCell>
                                    <TableCell style={{textAlign:'center'}}><a target='blank' href={process.env.REACT_APP_BASE_URL+'/efiling/index/document-master-config/m/ep/id/'+btoa(element)}>Modify</a>
                                    </TableCell>
                                </TableRow>
                            )) : (
                                <TableRow>
                                <TableCell><b>No result found</b></TableCell>
                                </TableRow>
                            ) } 
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                    rowsPerPageOptions={[50, 100, 200, 250]}
                    component="div"
                    count={Object.keys(documentMasterListAndIdMapping).length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                </Paper>
                }
            </div>}
        </React.Fragment>
    )

}
