import { makeStyles } from '@material-ui/core/styles'
import { FullscreenExit } from '@material-ui/icons'

export const useStyles = makeStyles(theme => ({
    pageContent: {
        margin: theme.spacing(5),
        padding: theme.spacing(3),
        marginLeft: theme.spacing(1),
        marginTop: '0px'
        // width: '50%'
    },
    saveButton : {
        margin: theme.spacing(5),
        color: 'white',
        backgroundColor: 'blue',
        float: 'right'
    },
    uploadButton : {
        '&:hover': {
            cursor: 'pointer'
        }
    },
    modifyButton : {
        color: 'black',
        backgroundColor: 'lightgrey',
    },
    loader: {
        display: 'flex',
        justifyContent: 'center'
    },
    table: {
        width: '100%'
    },
    input: {
        display: "none"
    },
    button: {
        marginTop: theme.spacing(3),
        padding: theme.spacing(1)
    },
    formControl: {
        marginTop: '10px',
        width: '100%'
        //minWidth: 200
    },
    textField: {
        width: '700px'
    },
    gridItem : {
        display: 'flex',
        justifyContent: 'center'
    }
}))