import React, { useState,useEffect } from 'react';
import { useStyles } from './style';
import { getEmpTaggingSummary } from '../fetchData'
import HeaderLayout from '../HeaderLayout'
import { TableCell, TableContainer, TableHead, TableRow, Table, TableBody, Button, Grid } from '@material-ui/core'
import PeopleOutlineTwoTone from '@material-ui/icons/PeopleOutlineTwoTone'
import CloudDownload from '@material-ui/icons/CloudDownload'
import logo from '../../../utils/load.gif'
import { employeeTaggingUrl } from '../../../utils/CommonUrl'

const SkillSummary = ({ 
  data
}) => {
  
  return (
    <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Skill</TableCell>
              <TableCell>Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          { ( data && data.length > 0 ) ? data.map((skill, key)=>(
              <TableRow key={key}>      
                <TableCell>
                  {skill.primary_skill?skill.primary_skill:"untagged"}
                </TableCell>
                <TableCell>{skill.count}</TableCell>
            </TableRow>
          )) : (
              <TableRow>
              <TableCell><b>No result found</b></TableCell>
              </TableRow>
          ) } 
          </TableBody>
        </Table>
      </TableContainer>
  );
};


const PodSummary = ({ 
  data
}) => {
  const {podData, skillList} = data
  return (
    <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>POD</TableCell>
              {skillList.map((skill,key)=>(
                <TableCell>{skill}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
          { ( podData && podData.length > 0 ) ? podData.map((pod, key)=>(
              <TableRow key={key}>      
                <TableCell>
                  {pod.podName}
                </TableCell>
                {skillList.map((skill,key)=>(
                <TableCell>{pod['skills'][skill]}</TableCell>
              ))}
            </TableRow>
          )) : (
              <TableRow>
              <TableCell><b>No result found</b></TableCell>
              </TableRow>
          ) } 
          </TableBody>
        </Table>
      </TableContainer>
  );
};

export function EmployeeTaggingSummary(props) {

  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true)
  const [getSkillData, setSkillData] = useState([])
  const [getPodObj, setPodObj] = useState([])
  const [view,toggleView] = useState("skill")

  useEffect(()=> {
    getSummaryData()
  },[])

  const getSummaryData = async () => {
    setIsLoading(true)
    let data = []
    data = await getEmpTaggingSummary()
    setSkillData(data.data.skillSummary)
    let pod=flattenPodSummary(data.data.podSummary)
    setPodObj(pod)
    setIsLoading(false)
  }

  function flattenPodSummary(podSummary){
    let podObj = {}
    let skillList = []
    if(podSummary && podSummary.length>0){
      podSummary.forEach((record)=>{
        let {pod, primary_skill, count} = record
        if(!pod){
          pod = 'untagged'
        } 
        if(!primary_skill) {
          primary_skill = 'untagged'
        }
        if(skillList.indexOf(primary_skill)<0){
          skillList.push(primary_skill)
        }
        let podRecord = podObj[pod] || {} 
        let skills = podRecord['skills'] || {}
        skills[primary_skill] = count
      
        podObj[pod] = {
          skills,
          podName: pod
        }
      })
    }
    let podData = []
    for (const property in podObj) {
      podData.push(podObj[property])
    }
    return {podData, skillList}
  }
  
  const handleDownloadAsExcel = () => {
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', `${employeeTaggingUrl.DOWNLOAD_EMPLOYEE_TAGGING_SUMMARY}`);
    a.setAttribute('target', '_blank');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  const changeView = (e)=>{
    e.preventDefault()
    toggleView(view === "skill"?"pod":"skill")
  }
  let viewText = view === "skill"?"pod":"skill"
  return (
      <div className={classes.root}> 
          <Grid container>
            <Grid item xs={3}> 
              <HeaderLayout
                  title='Employee Tagging'
                  subTitle='Technology'
                  icon={<PeopleOutlineTwoTone/>}
              />
            </Grid>
            <Grid item xs={3}> 
              <Button className={classes.downloadButton} variant="contained" endIcon={<CloudDownload />} onClick={handleDownloadAsExcel}>
                Download as Excel
              </Button>
            </Grid>
            <Grid item xs={3}> 
              <Button className={classes.downloadButton}> 
                 <a href="/EmployeeTagging"> Detailed View </a>
               </Button>
            </Grid>
            <Grid item xs={3}> 
              <Button className={classes.downloadButton} > 
                 <a href="/" onClick={changeView}> {`${viewText} Summary`}  </a>
              </Button>
            </Grid>
          </Grid>
          { ( isLoading ) 
            ? 
            <div className={classes.loader}><img src={logo} alt="loading..." /></div>
            : 
           <div> 
             {view==="skill"?<SkillSummary data={getSkillData}/>:
             <PodSummary data={getPodObj}/>}
           </div>
          }
      </div>
  )
}
