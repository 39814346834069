import { makeStyles } from '@material-ui/core/styles'
import { FullscreenExit } from '@material-ui/icons'

export const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiCardHeader-title': {
            fontSize: '1rem'
        },
        '& .MuiTableCell-root': {
          padding: '10px'
        }
    },
    pageContent: {
        margin: theme.spacing(1),
        padding: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginTop: '0px'
    },
    saveButton : {
        margin: theme.spacing(5),
        color: 'white',
        backgroundColor: 'blue',
        float: 'right'
    },
    notification: {
        padding: theme.spacing(1),
        margin: theme.spacing(1),
        marginLeft: 0,
        textAlign: 'center',
    },
    uploadButton : {
        color: 'black',
        backgroundColor: 'lightgreen',
        '&:hover': {
            backgroundColor: 'lightgreen',
        }
    },
    modifyButton : {
        color: 'black',
        backgroundColor: 'lightgrey',
    },
    podFormControl: {
        margin: theme.spacing(1),
        width: 260
    },
    skillFormControl: {
        margin: theme.spacing(1),
        width: 120
    },
    loader: {
        display: 'flex',
        justifyContent: 'center'
    },
    input: {
        display: "none"
    },
    button: {
        margin: 'auto',
        padding: theme.spacing(1)
    },
    formRoot: {
        padding: theme.spacing(2)
      },
      ratingSection: {
        marginTop: '3em'
      },
      formControl: {
        margin: theme.spacing(1),
        width: '100%'
      },
      selectEmpty: {
        marginTop: theme.spacing(2)
      },
      marginTop: {
        marginTop: 10
      },
      root: {
        width: '100%'
      },
      gridItem : {
        display: 'flex',
        justifyContent: 'start'
      },
      colorPallete : {
        width: '83px',
        height: '15px',
        border: '0.1px solid black',
        padding: '3px'
      },
      heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular
      },
      resetButton: {
        marginLeft: 20,
        background: 'white !important',
        textTransform: 'none'
      },
      button: {
        textTransform: 'none'
      },
      cardTitleWhite: {
        color: '#FFFFFF',
        marginTop: '0px',
        minHeight: 'auto',
        fontWeight: '300',
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: '3px',
        textDecoration: 'none',
        '& small': {
          color: '#777',
          fontSize: '65%',
          fontWeight: '400',
          lineHeight: '1'
        }
      },
      filterTitleMargin: {
        marginLeft: -14
      },
      picker : {
        position: "relative"
      },
      swatch : {
        width: "28px",
        height: "28px",
        marginTop: "20px",
        borderRaius: "8px",
        border: '3px solid #fff',
        boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1)',
        cursor: 'pointer'
      },
      popover : {
        position: 'absolute',
        left: '40px',
        borderRadius: '9px',
        boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)'
      }
}))