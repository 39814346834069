export const campaignConst = {
    TEMPLATE_HOMEPAGE_IMAGE_HYPERLINK : 1,
    TEMPLATE_SPLASH_SCREEN_IMAGE_HYPERLINK : 3,
    fileExtenstions: ['jpg', 'jpeg', 'png']
}

export const campaignActivityConst = {
    NONE: 0,
    MYDEA_SUBMISSION_60_DAYS: 1
  }
  