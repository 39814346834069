import React from "react"
import { 
    Grid,
    Card,
    Divider
} from "@material-ui/core"
import { useStyles } from "./style"

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js"
import { Bar } from "react-chartjs-2"

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
)

const options = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 1,
    plugins: {
        legend: {
            display: false
        }
    },
    scales: {
        x: {
            grid: {
                display: false
            },
            ticks: {
                display: false,
                padding: 0
            }
        },
        y: {
            beginAtZero: true,
            ticks: {
                precision: 0,
                callback: function(value, index, ticks) {
                    return getKFormatForThousand(value)
                }
            },
            border: {
                dash: [2, 4],
            },
        }
    }
}

const getKFormatForThousand = (num) => {
    return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)* Math.abs(num)
}

const graphCols = {
    "totalNoOfCardExchanged" : { title: "Total No of Cards Exchanged", color: "#7A3DB6" },
    "uniqueCardSenderCount": { title: "Unique Senders of Cards", color: "#962DFF" },
    "cardSentByManagerToDRCount" : { title: "Cards Sent by Managers to DRs", color: "#C794FE" },
    "cardSentBySpecifiedDepartment": { title: "Cards Sent By HR & L&D", color: "#DFC5FE" }
}

const getGraphLabelList = () => {
    let labels = [],
    labelKeys = []
    for (const key in graphCols) {
        labels.push(graphCols[key].title)
        labelKeys.push(key)
    }

    return { labels, labelKeys }
}

const getDataSets = ({ labels, records }) => {
    let datasets = [{
        label: "",
        data: [],
        backgroundColor: [],
        borderRadius: 5,
    }]
    
    for (const labelName of labels) {
        datasets[0].data.push(records[labelName])
        datasets[0].backgroundColor.push(graphCols[labelName].color)
    }

    return datasets
}

const YouAreAwesomeCard = ({ 
    youAreAwesomeReport = {},
    handleDetailedData = () => {}
}) => {
    const classes = useStyles()
    const { mappedHeadCount = 0 } = youAreAwesomeReport
    const { labels, labelKeys } = getGraphLabelList()
    const datasets = getDataSets({ labels: labelKeys, records: youAreAwesomeReport })

    return (<Grid item xs={12} sm={12} md={12} className={classes.gridBody} style={{ cursor: "pointer" }}>
                <Card style={{ height: "350px" }}>
                    <div onClick={e => handleDetailedData("YouAreAwesome")}>
                        <div style={{ padding: "5 5 0 10" }}>
                            <label style={{ fontSize: "1.17em", fontWeight: "bold", cursor: "pointer"  }}>You Are Awesome</label>
                            <br/>
                            <span style={{ color:"#9A99AA", fontSize: "11px", cursor: "pointer"  }}>Total No Of Cards Exchanged</span>
                        </div>
                        <div style={{ padding: "5 0 5 5" }}>
                            <Divider variant="middle" />
                        </div>
                        <Grid container>
                            <Grid xs={7} sm={7} md={7} item style={{  padding: "5 10 0 5" }}>
                                <div style={{ width: "290px", height: "260px" }}>
                                    <Bar options={options} data={{ labels, datasets }} />
                                </div>
                            </Grid>
                            <Grid xs={5} sm={5} md={5} item style={{ padding: "0 0 0 30" }}>
                                <div style={{ textAlign: "center" }}>
                                <span style={{ fontSize: "12px" }}>Head Count </span>
                                <br/>
                                <span style={{ fontWeight: "bold", fontSize: "14px" }}>{mappedHeadCount}</span>
                                </div>
                                <br/>
                                <div>
                                {
                                    Object.keys(graphCols).map((key, index) => 
                                    <span key={index}>
                                        <span style={{ height: "7px", width: "7px", backgroundColor: `${graphCols[key].color}`, borderRadius: "50%", display: "inline-block" }}></span>
                                        &nbsp;
                                        <span style={{ fontSize: "11px" }}><b>{graphCols[key].title}</b></span>
                                        <br/>
                                    </span>
                                    )
                                }
                                </div>
                            </Grid>
                        </Grid>
                        <div>
                        </div>
                    </div>
                    </Card>
                </Grid>
            )
}

export default YouAreAwesomeCard