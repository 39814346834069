import React, { useEffect, useState }  from "react"
import moment from "moment"
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'

import { 
    Grid, Paper, Typography
} from "@material-ui/core"
import PeopleOutlineTwoTone from '@material-ui/icons/PeopleOutlineTwoTone'

import { useStyles } from "./style"
import Loader from "../../../components/Loader"
import Filter from "../Filter"
import { getPendingTaskListService } from "../fetchData"
import PendingCard from "../PendingCard"
import HeaderLayout from "../HeaderLayout"
import { 
    getDepartmentListFetchData, 
    getSubDepartmentFetchData, 
    getUniqueGradeListFetchData,
    getLocationListFetchData,
    getDesignationListByGradeNameFetchData
} from '../../Shared/FetchData'
import { scrollToViewHandler } from '../../../constants/CommonConstant'

const INIT_FILTER_OBJ = {
    department: [],
    subDepartment: [],
    grade: [],
    designation: [],
    groupDojFrom: null,
    groupDojTo: null,
    location: [],
    reportingManager: [],
    tabp: [],
    selectDepartment: false,
    selectAllDepartment: false,
    selectAllSubDepartment: false,
    selectAllGrade: false,
    selectAllDesignation: false,
    selectAllTabp: false,
    selectAllRM: false
}

let defaultErrObj = {
    groupDojFrom: "",
    groupDojTo: ""
}

const pageNames = {
    "15 Days Feedback Form": "FifteenFeedback",
    "60 Days Feedback Form": "SixtyFeedback",
    "Resignation Approval": "Resignation",
    "Feeling @ Home": "Feeling@Home",
    "Exit Feedback": "ExitFeedback",
    "Exit Clearance": "ExitClearance",
    "Comp Off Approval": "CompOffApproval",
    "Family Dependent Details": "FamilyDependent",
    "Onboarding Documents Pendency": "OnboardingPendency",
    "Probation Pendency": "ProbationPendency",
}

const swapped = Object.fromEntries(Object.entries(pageNames).map(a => a.reverse()))

const isJsonString = (str) => {
    try {
        return JSON.parse(str)
    } catch (e) {
        return false
    }
}

const sessionFilterName = "HR_Dashboard_Pending_FilterData"

const PendingTaskList = () => {
    const classes = useStyles()

    const lObj = useLocation()
    const query = new URLSearchParams(lObj.search)
    let uriParams =  query.get("params") || null
    if (uriParams) {
        uriParams = atob(uriParams)
        uriParams = isJsonString(uriParams)
    }
    
    let selectedPageName = uriParams ? uriParams.selectedPageName : false

    const [loading, setLoading] = useState(true)
    const [filterObj, setFilterObj] = useState({ ...INIT_FILTER_OBJ, ...uriParams })
    const [pendingRecord, setPendingRecord] = useState([])
    const [isDetailedPage, setIsDetailedPage] = useState(selectedPageName ? swapped[selectedPageName] : selectedPageName)
    const [redirectUrl, setRedirectUrl] = useState("")
    const [isApiError, setIsApiError] = useState(false)
    const [apiErrorMessage, setApiErrorMessage] = useState("")

    const [departmentData, setDepartmentData] = useState([])
    const [subDepartmentData, setSubDepartmentData] = useState([])
    const [gradeData, setGradeData] = useState([])
    const [designationsData, setDesignationsData] = useState([])
    const [branchData, setBranchData] = useState([])
    const [reportingManagerData, setReportingManagerData] = useState([])
    const [tabpData, setTabpData] = useState([])
    const [errorObj, setErrorObj] = useState(defaultErrObj)
    const[isFilterSelected, setIsFilterSelected] = useState(false)

    useEffect(() => {
        async function fetchData () {
            try {
                let fData = { ...filterObj }
                let sessionFilterData = window.sessionStorage.getItem(sessionFilterName)
                if (sessionFilterData) {
                    sessionFilterData = JSON.parse(sessionFilterData)
                    fData = { ...sessionFilterData }

                    if (fData.department.length) {
                        await getSubDepartment(fData.department)
                    }

                    if (fData.grade.length) {
                        await getDesignationList(fData.grade)
                    }
                }

                let isFSelected = false
                for (const key in fData) {
                    if (["groupDojFrom", "groupDojTo"].includes(key) && fData[key]) {
                        isFSelected =  true
                    }

                    if (["department", "subDepartment", "grade", "designation", "location", "reportingManager", "tabp"].includes(key) && fData[key] && Array.isArray(fData[key]) && fData[key].length) {
                        isFSelected =  true
                    }
                }

                setIsFilterSelected(isFSelected)

                const response = await getPendingTaskListData(fData)
                const { dojParams, pendingRec = [], tabpList = [], managerList = [], success, message = "" } = response
                setIsApiError(success)
                setApiErrorMessage(message)
                if (success) {
                    setPendingRecord(pendingRec)
                    setReportingManagerData(managerList)
                    setTabpData(tabpList)
                    
                    setFilterObj({ ...fData, ...dojParams })
                
                    let redirectLink = ""
                    if (swapped[selectedPageName]) {
                        for (const obj of pendingRec) {
                            if (obj.labelName === swapped[selectedPageName]) {
                                redirectLink = obj.redirectLink
                            } 
                        }
                    }
                    setRedirectUrl(redirectLink)

                    await getDepartmentList()
                    await getGradeList()
                    await getBranchList()
                }
                setLoading(false)
            } catch (err) {
                console.log(err)
            }
        }
        
        fetchData()
    }, [])

    const getPendingTaskListData = async(filterObj = {}) => {
        try {
            const response = await getPendingTaskListService({ filterObj: filterObj })
            return response
        } catch (err) {
            throw err
        }
    }

    const getDepartmentList = async () => {
        try {
            const { data = [] } = await getDepartmentListFetchData()
            setDepartmentData(data)
            return data
        } catch (error) {}
    }   

    const getSubDepartment = async (departmentId = []) => {
        try {
            const { data = []} = await getSubDepartmentFetchData({departmentId:departmentId})
            setSubDepartmentData(data)

            return data
        } catch (err) {}
    }

    const getGradeList = async () => {
        try {
            const { data = [] } = await getUniqueGradeListFetchData()
            setGradeData(data)
            return data
        } catch (err) {}
    }

    const getDesignationList = async (gradeName = []) => {
        try {
            const { data = [] } = await getDesignationListByGradeNameFetchData({gradeName: gradeName});
            setDesignationsData(data)
            return data
        } catch(err) {}
    }

    const getBranchList = async () => {
        try {
            const { data = [] } = await getLocationListFetchData()
            if (data.length) {
                setBranchData(data)
            }     

            return data
        } catch (err) {}
    }

    const handleChange = async event => {
        let fObj = { ...filterObj }
        let eventName = event.target.name
        let eventVal = event.target.value

        if (eventName === 'department') {
            let selectedDepartmentId = []
            if (eventVal[eventVal.length - 1] === "all") {
                selectedDepartmentId = departmentData.map(item => item.department_id)
                selectedDepartmentId = selectedDepartmentId.length === filterObj.department.length ? [] : selectedDepartmentId
            } else {
                for (const item of departmentData) {
                    if (eventVal.includes(item.department_id)) {
                        selectedDepartmentId.push(item.department_id)
                    }
                }
            }
            
            const funcData = await getSubDepartment(selectedDepartmentId)
            fObj.selectAllDepartment = selectedDepartmentId.length
            fObj = { ...fObj, [eventName]: selectedDepartmentId }
            let subDept = []
            if (selectedDepartmentId.length && filterObj.subDepartment.length) {
                for (const obj of funcData) {
                    if (filterObj.subDepartment.includes(obj.function_id)) {
                        subDept.push(obj.function_id)
                    }
                }
            }
            fObj.subDepartment = subDept
        }

        if (eventName === 'subDepartment') {
            let selectedSubDepartmentId = []
            if (eventVal[eventVal.length - 1] === "all") {
                selectedSubDepartmentId = subDepartmentData.map(item => item.function_id)
                selectedSubDepartmentId = selectedSubDepartmentId.length === filterObj.subDepartment.length ? [] : selectedSubDepartmentId
            } else {
                for (const item of subDepartmentData) {
                    if (eventVal.includes(item.function_id)) {
                        selectedSubDepartmentId.push(item.function_id)
                    }
                }
            }
            fObj.selectAllSubDepartment = selectedSubDepartmentId.length
            fObj = { ...fObj, [eventName]: selectedSubDepartmentId }
        }
    
        if (eventName === 'grade') {
            let selectedGrade = []
            if (eventVal[eventVal.length - 1] === "all") {
                selectedGrade = gradeData.map(item => item.grade)
                selectedGrade = selectedGrade.length === filterObj.grade.length ? [] : selectedGrade
            } else {
                for (const item of gradeData) {
                    if (eventVal.includes(item.grade)) {
                        selectedGrade.push(item.grade)
                    }
                }
            }
            
            const desData = await getDesignationList(selectedGrade)
            fObj.selectAllGrade = selectedGrade.length ? true : false
            fObj = { ...fObj, [eventName]: selectedGrade }

            let des = []
            if (selectedGrade.length && filterObj.designation.length) {
                for (const obj of desData) {
                    if (filterObj.designation.includes(obj.designation)) {
                        des.push(obj.designation)
                    }
                }
            }
            fObj.designation = des
        }

        if (eventName === 'designation') {
            let selectedDes = []
            if (eventVal[eventVal.length - 1] === "all") {
                selectedDes = designationsData.map(item => item.designation)
                selectedDes = selectedDes.length === filterObj.designation.length ? [] : selectedDes
            } else {
                for (const item of designationsData) {
                    if (eventVal.includes(item.designation)) {
                        selectedDes.push(item.designation)
                    }
                }
            }

            fObj.selectAllDesignation = selectedDes.length
            fObj = { ...fObj, [eventName]: selectedDes }
        }

        if (eventName === 'location') {
            let selectedLoc = []
            if (eventVal[eventVal.length - 1] === "all") {
                selectedLoc = branchData.map(item => item.id)
                selectedLoc = selectedLoc.length === filterObj.location.length ? [] : selectedLoc
            } else {
                for (const item of branchData) {
                    if (eventVal.includes(item.id)) {
                        selectedLoc.push(item.id)
                    }
                }
            }

            fObj.selectAllLocation = selectedLoc.length
            fObj = { ...fObj, [eventName]: selectedLoc }
        }

        if (eventName === 'location') {
            let selectedLoc = []
            if (eventVal[eventVal.length - 1] === "all") {
                selectedLoc = branchData.map(item => item.id)
                selectedLoc = selectedLoc.length === filterObj.location.length ? [] : selectedLoc
            } else {
                for (const item of branchData) {
                    if (eventVal.includes(item.id)) {
                        selectedLoc.push(item.id)
                    }
                }
            }

            fObj.selectAllLocation = selectedLoc.length
            fObj = { ...fObj, [eventName]: selectedLoc }
        }

        if (eventName === 'reportingManager') {
            let selectedRmId = []
            if (eventVal[eventVal.length - 1] === "all") {
                selectedRmId = reportingManagerData.map(item => item.emp_code)
                selectedRmId = selectedRmId.length === filterObj.reportingManager.length ? [] : selectedRmId
            } else {
                for (const item of reportingManagerData) {
                    if (eventVal.includes(item.emp_code)) {
                        selectedRmId.push(item.emp_code)
                    }
                }
            }

            fObj.selectAllRM = selectedRmId.length
            fObj = { ...fObj, [eventName]: selectedRmId }
        }

        if (eventName === 'tabp') {
            let selectedTabpId = []
            if (eventVal[eventVal.length - 1] === "all") {
                selectedTabpId = tabpData.map(item => item.emp_code)
                selectedTabpId = selectedTabpId.length === filterObj.tabp.length ? [] : selectedTabpId
            } else {
                for (const item of tabpData) {
                    if (eventVal.includes(item.emp_code)) {
                        selectedTabpId.push(item.emp_code)
                    }
                }
            }

            fObj.selectAllTabp = selectedTabpId.length
            fObj = { ...fObj, [eventName]: selectedTabpId }
        }
        
        setFilterObj({ ...fObj })
    }

    const handleFilters = () => {
        let sendObj = { ...filterObj }
        let isValid = validateDuration(sendObj)
        if (!isValid.error) {
            handleSubmit(sendObj)
        }

        window.sessionStorage.setItem(sessionFilterName, JSON.stringify({...filterObj}))
    }

    const handleReset = async () => {
        setFilterObj({ ...INIT_FILTER_OBJ })
        setSubDepartmentData([])
        setDesignationsData([])
        setLoading(true)

        try {
            const response = await getPendingTaskListData({...INIT_FILTER_OBJ})
            const { pendingRec = [], success, message = "" } = response
            setIsApiError(success)
            setApiErrorMessage(message)
            if (success) {
                let filterList = []
                let redirectLink = ""
                if (isDetailedPage) {
                    for (const obj of pendingRec) {
                        if (obj.labelName === isDetailedPage) {
                            filterList.push(obj)
                            redirectLink = obj.redirectLink
                        } 
                    }
                } else {
                    filterList = pendingRec
                }
                setPendingRecord(filterList)
                setRedirectUrl(redirectLink)
                // scrollToViewHandler('main-container-grid')
            }
        } catch (err) {
            console.log(err)
        }
        window.sessionStorage.removeItem(sessionFilterName)
        
       //~ window.sessionStorage.setItem(sessionFilterName, JSON.stringify({...INIT_FILTER_OBJ}))
        setLoading(false)
    }

    const handleDateChange = (date, labelName) => {
        let errMsg = ""
        const dateVal = moment(date).format("DD-MMM-YYYY")
        if (labelName === "groupDojFrom" && filterObj.groupDojTo && moment(filterObj.groupDojTo).isBefore(dateVal)) {
            errMsg = "DOJ (From) date must be less than or equal to DOJ (To) date!"
        } else if (labelName === "groupDojTo" && filterObj.groupDojFrom && moment(filterObj.groupDojFrom).isAfter(dateVal)) {
            errMsg = "DOJ (To) date must be greater than or equal to DOJ (From) date!"
        }
        
        let newErrObj = { [labelName]: errMsg }
        if (errMsg === "") {
            newErrObj = { ...defaultErrObj }
        } else  {
            newErrObj = { [labelName]: errMsg }
        }

        setErrorObj({ ...errorObj, ...newErrObj })
        setFilterObj({ ...filterObj, [labelName]: dateVal })
    }

    const handleViewMore = async (labelName = false, isBack =  false) => {
        let filterList = []
        setLoading(true)
        let redirectLink = ""

        let filterData =  { ...filterObj }

        let pageParams = getUrlParams({ labelName, isBack, filterData })
        window.parent.postMessage(`${pageParams}`, process.env.REACT_APP_BASE_URL) 

        if (labelName && !isBack) {
            for (const obj of pendingRecord) {
                if (obj.labelName === labelName) {
                    filterList.push(obj)
                    redirectLink = obj.redirectLink
                } 
            }
        } else {
            //setFilterObj({ ...INIT_FILTER_OBJ })
            const { pendingRec = [],  success, message = "" } = await getPendingTaskListData({ ...filterData })
            filterList = pendingRec
            setIsApiError(success)
            setApiErrorMessage(message)
        }
        
        setFilterObj({ ...filterData })
        setIsDetailedPage(labelName)
        setRedirectUrl(redirectLink)
        setPendingRecord(filterList)
        setLoading(false)
        scrollToViewHandler('main-container-grid')
    }

    const handleSubmit = async (sendObj) => {
        setLoading(true)
        const { pendingRec = [], success, message = "" } = await getPendingTaskListData(sendObj)
        setIsApiError(success)
        setApiErrorMessage(message)
        let filterList = []
        let redirectLink = ""
        if (isDetailedPage) {
            for (const obj of pendingRec) {
                if (obj.labelName === isDetailedPage) {
                    filterList.push(obj)
                    redirectLink = obj.redirectLink
                } 
            }
        } else {
            filterList = pendingRec
        }

        setRedirectUrl(redirectLink)
        setPendingRecord(filterList)
        setLoading(false)
    }

    const handleSelectAllCheckbox = event => {
        if (event.target.name === 'selectAllDepartment') {
          if (event.target.checked) {
            let selectedDepartmentId = []
            for (const item of departmentData) {
                selectedDepartmentId.push(item.department_id)
            }
            getSubDepartment(selectedDepartmentId)
            filterObj.department = selectedDepartmentId
            filterObj.selectAllDepartment = true
          } else {
            filterObj.department = []
            filterObj.subDepartment = []
            filterObj.grade = []
            filterObj.selectAllDepartment = false
            filterObj.selectAllSubDepartment = false
            setSubDepartmentData([])
          }
        } else if (event.target.name === 'selectAllSubDepartment') {
            if (event.target.checked) {
              let selectedSubDepartmentId = []
              for (const item of subDepartmentData) {
                  selectedSubDepartmentId.push(item.function_id)
              }

              filterObj.subDepartment = selectedSubDepartmentId
              filterObj.selectAllSubDepartment = true
            } else {
              filterObj.subDepartment = []
              filterObj.selectAllSubDepartment = false
            }
        } else if (event.target.name === 'selectAllGrade') {
            if (event.target.checked) {
              let selectedGrade = []
              for (const item of gradeData) {
                  selectedGrade.push(item.grade)
              }
              getDesignationList(selectedGrade)
              filterObj.grade = selectedGrade
              filterObj.selectAllGrade = true
            } else {
              filterObj.grade = []
              filterObj.designation = []
              filterObj.selectAllGrade = false
              filterObj.selectAllDesignation = false
              setDesignationsData([])
            }
        } else if (event.target.name === 'selectAllDesignation') {
            if (event.target.checked) {
              let selectedDesignation = []
              for (const item of designationsData) {
                  selectedDesignation.push(item.designation)
              }
              filterObj.designation = selectedDesignation
              filterObj.selectAllDesignation = true
            } else {
              filterObj.designation = []
              filterObj.selectAllDesignation = false
            }
        } else if (event.target.name === 'selectAllLocation') {
            if (event.target.checked) {
              let selectedBranchId = []
              for (const item of branchData) {
                  selectedBranchId.push(item.id)
              }
              filterObj.location = selectedBranchId
              filterObj.selectAllLocation = true
            } else {
              filterObj.location = []
              filterObj.selectAllLocation = false
            }
        } else if (event.target.name === 'selectAllTabp') {
            if (event.target.checked) {
              let selectedTabpId = []
              for (const item of tabpData) {
                  selectedTabpId.push(item.emp_code)
              }
              filterObj.tabp = selectedTabpId
              filterObj.selectAllTabp = true
            } else {
              filterObj.tabp = []
              filterObj.selectAllTabp = false
            }
        } else if (event.target.name === 'selectAllRM') {
            if (event.target.checked) {
              let selectedRmId = []
              for (const item of reportingManagerData) {
                  selectedRmId.push(item.emp_code)
              }
              filterObj.reportingManager = selectedRmId
              filterObj.selectAllRM = true
            } else {
              filterObj.reportingManager = []
              filterObj.selectAllRM = false
            }
        }

        setFilterObj({ ...filterObj, [event.target.name]: event.target.checked })
    }

    const validateDuration = (obj) => {
        const { groupDojFrom, groupDojTo } = obj 
        let result = { error: false, message: "" }

        let section = ""
        if (groupDojTo && moment(groupDojTo).isBefore(moment(groupDojFrom))) {
            section = "groupDojTo"
            result.error = true
            result.message = "DOJ (To) date must be greater than or equal to DOJ (From) date!"
        }
        
        setErrorObj({ ...errorObj, [section]: result.message })
        return result
    }

    const getUrlParams = ({ labelName, isBack, filterData }) => {
        let uriParams = {}
        
        if (labelName && !isBack) {
            uriParams = { ...uriParams, selectedPageName:pageNames[labelName] }
        }
        
        return Object.keys(uriParams).length ? btoa(JSON.stringify(uriParams)) : ""
    }

    const pendingRecordLen = pendingRecord.length
    
    return (
        <React.Fragment>
            <Helmet>
                <title>HR Dashboard: Pending Task List</title>
            </Helmet>
            <div className={classes.root}>
                <Grid container id="main-container-grid">
                    { isDetailedPage ? 
                        <React.Fragment>
                            <HeaderLayout
                                title={"Pending Task List"}
                                subTitle={isDetailedPage}
                                icon={<PeopleOutlineTwoTone/>}
                                redirectUrl={redirectUrl}
                            /> 
                        </React.Fragment>
                        :
                        <div className={classes.cardHeader}>
                            <h3 className={classes.headerText}>HR Dashboard Pending Task List</h3>
                        </div>
                    }
                    <div className={classes.cardBody}>
                        <div className={classes.filterBody}>
                            <Filter 
                                handleSubmit={handleSubmit}
                                departmentData={departmentData}
                                subDepartmentData={subDepartmentData}
                                gradeData={gradeData}
                                designationsData={designationsData}
                                branchData={branchData}
                                reportingManagerData={reportingManagerData}
                                tabpData={tabpData}
                                loading = {loading}
                                filterObj={filterObj}
                                handleChange={handleChange}
                                handleFilters={handleFilters}
                                handleReset={handleReset}
                                handleDateChange={handleDateChange}
                                handleSelectAllCheckbox={handleSelectAllCheckbox}
                                errorObj={errorObj}
                                isApiError={isApiError}
                                apiErrorMessage={apiErrorMessage}
                                isFilterSelected={isFilterSelected}
                                setIsFilterSelected={setIsFilterSelected}
                            />
                        </div>
                        {
                            loading ? (<Loader loading={loading} />) : (
                                pendingRecordLen ? 
                                <div className={classes.maiBody}>
                                    <Paper elevation={1} square className={classes.root}>
                                        <Grid container>
                                            {
                                                pendingRecord.map((listItem, index) => <PendingCard 
                                                                key={index}
                                                                listItem={listItem}
                                                                index={`${index}`}
                                                                columns={listItem.columns}
                                                                handleViewMore={handleViewMore}
                                                                isDetailedPage={isDetailedPage}
                                                                handleReset={handleReset}
                                                                pageNames={pageNames}
                                                            />)
                                            }
                                        </Grid>
                                    </Paper>
                                </div> : ("No record to display!")
                            )
                        }
                    </div>
                </Grid>
            </div>
                <Grid item xs={12} style={{width: '90%'}}>
                    <Typography variant="h6" align="center" style={{color: 'red', textAlign: 'center'}}>
                    {apiErrorMessage}
                    </Typography>
                </Grid>
        </React.Fragment>
    )
}

export default PendingTaskList