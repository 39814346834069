import React from 'react';
import Helmet from 'react-helmet';

const TitleComponent = ({ title }) => {
     var defaultTitle = 'HRIS';
     return (
         <Helmet>
             <title>{title ? title : defaultTitle}</title>
         </Helmet>
     );
 };
 
 export { TitleComponent };