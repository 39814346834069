import React from "react"
import { CircularProgress, FormHelperText, Button, TextField, FormControl, Grid, TableContainer, Table, TableBody, TableRow, TableCell } from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"
import { useStyles } from "./style"

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow)

const View = ({
    minHeight = "500px",
    maxHeight = "500px",
    detailedObj = {},
    formData = {},
    inputErrObj = {},
    handleNameChange = () => {},
    goBack = () => {},
    handlePreview = () => {},
    loading = false
}) => {
    const classes = useStyles()

    return (
        <Grid style={{ padding: "10 10 10 10" }}>
            <TableContainer style={{ minHeight, maxHeight }}>
                    <Table style={{ width: "100%" }}>
                        <TableBody aria-label="customized table">
                            <StyledTableRow>
                                <TableCell className={classes.emailCell_1}>Name</TableCell>
                                <TableCell className={classes.emailCell_2}>:</TableCell>
                                <TableCell className={classes.emailCell_3}>{detailedObj.emp_name}</TableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <TableCell className={classes.emailCell_1}>Location</TableCell>
                                <TableCell className={classes.emailCell_2}>:</TableCell>
                                <TableCell className={classes.emailCell_3}>{detailedObj.branch}</TableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <TableCell className={classes.emailCell_1}>Date Of Joining</TableCell>
                                <TableCell className={classes.emailCell_2}>:</TableCell>
                                <TableCell className={classes.emailCell_3}>{detailedObj.date_of_joining}</TableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <TableCell className={classes.emailCell_1}>Department</TableCell>
                                <TableCell className={classes.emailCell_2}>:</TableCell>
                                <TableCell className={classes.emailCell_3}>{detailedObj.department}</TableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <TableCell className={classes.emailCell_1}>Sub-Department</TableCell>
                                <TableCell className={classes.emailCell_2}>:</TableCell>
                                <TableCell className={classes.emailCell_3}>{detailedObj.sub_department}</TableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <TableCell className={classes.emailCell_1}>Grade</TableCell>
                                <TableCell className={classes.emailCell_2}>:</TableCell>
                                <TableCell className={classes.emailCell_3}>{detailedObj.grade}</TableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <TableCell className={classes.emailCell_1}>Designation</TableCell>
                                <TableCell className={classes.emailCell_2}>:</TableCell>
                                <TableCell className={classes.emailCell_3}>{detailedObj.designation}</TableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <TableCell className={classes.emailCell_1}>Parents Name<span style={{ color: "red"}}>*</span></TableCell>
                                <TableCell className={classes.emailCell_2}>:</TableCell>
                                <TableCell className={classes.emailCell_3}>
                                    <FormControl className={classes.formControl}>
                                        <TextField error={formData.parentName ? false : true} inputProps={{ maxLength: 30, minLength: 2 }} placeholder="Parents Name" onChange={e => handleNameChange(e, detailedObj.offer_letter_id)} value={formData.parentName} name="parentName" style={{ width: '250px' }} autoComplete="off" />
                                        <FormHelperText style={{ color: 'red' }}>
                                            {inputErrObj.parentName}
                                        </FormHelperText>
                                    </FormControl>
                                </TableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <TableCell className={classes.emailCell_1}>Employee Name<span style={{ color: "red"}}>*</span></TableCell>
                                <TableCell className={classes.emailCell_2}>:</TableCell>
                                <TableCell className={classes.emailCell_3}>
                                    <FormControl className={classes.formControl}>
                                        <TextField error={formData.empName ? false : true} inputProps={{ maxLength: 30, minLength: 2 }} placeholder="Employee Name" onChange={e => handleNameChange(e, detailedObj.offer_letter_id)} value={formData.empName} name="empName" style={{ width: '250px' }} />
                                        <FormHelperText style={{ color: 'red' }}>
                                            {inputErrObj.empName}
                                        </FormHelperText>
                                    </FormControl>
                                </TableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <TableCell colSpan={3}>
                                    <Grid container>
                                        <Grid item xs={4} sm={4} md={4}></Grid>
                                        {
                                            loading ? <Grid style={{ textAlign: "center" }} item xs={4} sm={4} md={4}>
                                                    <CircularProgress size={24} />
                                                </Grid> 
                                                :
                                                    <React.Fragment>
                                                        <Grid item xs={2} sm={2} md={2}>
                                                            {
                                                                (!inputErrObj.parentName && !inputErrObj.empName) ? <Button 
                                                                onClick={() => handlePreview()}
                                                                style={{ backgroundColor: '#2A6D3F', color: "white", padding: "5px", borderRadius: "5px", textTransform: "none" }}>
                                                                Preview</Button> : null
                                                            }
                                                        </Grid>
                                                        <Grid item xs={2} sm={2} md={2}>
                                                            <Button 
                                                            onClick={() => goBack()}
                                                            style={{ backgroundColor: '#4559C9', color: "white", padding: "5px", borderRadius: "5px", textTransform: "none" }}>
                                                        Back</Button> 
                                                        </Grid>
                                                        
                                                    </React.Fragment>
                                        }
                                        <Grid item xs={4} sm={4} md={4}></Grid>
                                    </Grid>
                                </TableCell>
                            </StyledTableRow>
                        </TableBody>
                    </Table>
            </TableContainer>
        </Grid>
    )
}

export default View