import moment from "moment"
import { 
    getFeelingAtHomeDetailDataService,
    getYouAreAwesomeDetailDataService,
    getDiversityDetailDataService,
    getExitAnalysisDetailDataService,
    getObFeedbackDetailDataService
} from "../fetchData"

const COMPARE_DATE_CONST = {
    month: {
        "07": { name: "Jul", year: "startYear", sort: 17, maxMonth: "07"},
        "08":  { name: "Aug", year: "startYear", sort: 18, maxMonth: "08"},
        "09":  { name: "Sep", year: "startYear", sort: 19, maxMonth: "09" },
        "10":  { name: "Oct", year: "startYear", sort: 20, maxMonth: "10" },
        "11":  { name: "Nov", year: "startYear", sort: 21, maxMonth: "11" },
        "12":  { name: "Dec", year: "startYear", sort: 22, maxMonth: "12" },

        "01": { name: "Jan", year: "endYear", sort: 11, maxMonth: "01" },
        "02": { name: "Feb", year: "endYear", sort: 12, maxMonth: "02" },
        "03": { name: "Mar", year: "endYear", sort: 13, maxMonth: "03" },
        "04": { name: "Apr", year: "endYear", sort: 14, maxMonth: "04" },
        "05": { name: "May", year: "endYear", sort: 15, maxMonth: "05" },
        "06": { name: "Jun", year: "endYear", sort: 16, maxMonth: "06" }
    },

    quarter: {
        "JAS": { months: ["07", "08", "09"], maxMonth: "07", name: "JAS", year: "startYear", sort: 1 },
        "OND": { months: ["10", "11", "12"], maxMonth: "10", name: "OND", year: "startYear", sort: 2 },

        "JFM": { months: ["01", "02", "03"], maxMonth: "01", name: "JFM", year: "endYear", sort: 3 },
        "AMJ": { months: ["04", "05", "06"], maxMonth: "04", name: "AMJ", year: "endYear", sort: 4 },
    }
}

const QUARTER_NAMES = {
    "07": "JAS",
    "08": "JAS",
    "09": "JAS",
    "10": "OND",
    "11": "OND",
    "12": "OND",
    "01": "JFM",
    "02": "JFM",
    "03": "JFM",
    "04": "AMJ",
    "05": "AMJ",
    "06": "AMJ"
}

const EXCLUDING_DATE_CONST = {
    "2023": {
        month: ["01", "02", "03", "04", "05", "06"],
        quarter: ["JFM", "AMJ"]
    }
}

export const getSortBy = ({ allFilterData }) => {
    let sortBy = ""
    if (allFilterData.includes("timeFrame")) {
        sortBy = "timeFrame"
    } else if (allFilterData.includes("tabp_name")) {
        sortBy = "tabp_name"
    } else {
        sortBy = allFilterData[0]
    }
    
    return sortBy
}

function getEligibleDatesOfFinYear({ selectedFinYear, comparisonObj, yearList }) {
    let [ startMonYear, endMonYear ] = selectedFinYear.split(`_`)
    const todayDate = moment().format("YYYYMMDD")
    const startDate = moment(`${startMonYear}01`).format("YYYYMMDD")
    const endMonDate = moment(`${endMonYear}`).endOf('month').format("YYYYMMDD")
    
    const { comparisonCriteria } = comparisonObj
    for (let index = +startDate; index <= +endMonDate;) {
        const year = String(index).substr(0, 4)
        const month = String(index).substr(4, 2)
        if (!yearList[year]) {
            yearList[year] = []
        }
        if (comparisonCriteria === "quarter") {
            const qtr = QUARTER_NAMES[month]
            if (!yearList[year].includes(qtr)) {
                yearList[year].push(qtr)
            }
        } else  {
            yearList[year].push(month)
        }
        
        index = +moment(String(index)).add(1, "month").endOf("month").format("YYYYMMDD")
        if (index > +todayDate) {
            break
        }
    }
}

export const getComparisonDates = ({ filterObj, comparisonObj }) => {
    const { dateFilterType } = filterObj
    let yearList = {}
    const currYearMon = moment().format("YYYYMM") 

    if (dateFilterType === "finYear") {
        for (const selectedFinYear of filterObj.finYear) {
            getEligibleDatesOfFinYear({ selectedFinYear, comparisonObj, yearList })
        }
    } else if (dateFilterType === "custom") {
        yearList = { ...comparisonObj }
    } else {
        yearList = { [filterObj.year]: [] }
        if (dateFilterType === "quarter" && comparisonObj.comparisonCriteria === "month") {
            for (const qtr of filterObj.quarter) {
                for (const mon of COMPARE_DATE_CONST[dateFilterType][qtr].months) {
                    const maxYearMonth =  `${filterObj.year}${COMPARE_DATE_CONST[comparisonObj.comparisonCriteria][mon].maxMonth}`
                    if (+maxYearMonth > +currYearMon) {
                        continue
                    }
                    yearList[filterObj.year] = [ ...yearList[filterObj.year], mon ]
                }
            }
        } else  {
            for (const qtr of filterObj[dateFilterType]) {
                const maxYearMonth =  `${filterObj.year}${COMPARE_DATE_CONST[comparisonObj.comparisonCriteria][qtr].maxMonth}`
                if (+maxYearMonth > +currYearMon) {
                    continue
                }
                yearList[filterObj.year].push(qtr)
            }
        }
    }

    for (const key in yearList) {
        if (Array.isArray(yearList[key]) && !yearList[key].length) {
            delete yearList[key]
            continue
        }
    }

    return yearList
}

export const fetchCompareData = async ({ pageView, filterParams, comparisonObj, yearList }) => {
    const { dateFilterType } = filterParams
    let filterParamObj = { ...filterParams }
    const { comparisonCriteria } = comparisonObj
    filterParamObj.dateFilterType = comparisonCriteria
    filterParamObj.month = []
    filterParamObj.quarter = []
    filterParamObj.year = ""
    filterParamObj.finYear = []
    filterParamObj.customFromDate = ""
    filterParamObj.customToDate = ""

    let fetchedData = {}
    let objName = ""
    let totalColName = "Combined"
    let isCompareTotal = false
    if (dateFilterType === "custom") {
        isCompareTotal = true
        filterParamObj.customFromDate = comparisonObj.compareFromDate
        filterParamObj.customToDate = comparisonObj.compareToDate

        let compareColVal = getCustomCompareColumValue({ comparisonObj })
        if (!fetchedData[compareColVal]) {
            fetchedData[compareColVal] = {}
        }

        const apiResult = await fetchDataAPI({ filterParamObj, pageView })
        fetchedData[compareColVal] = { ...apiResult.response }
        objName = apiResult.objName
        totalColName = `${filterParams.customFromDate} : ${filterParams.customToDate}`
    } else {
        for (const year in yearList) {
            filterParamObj.year = year
            for (const item of yearList[year]) {
                filterParamObj[comparisonCriteria] = [item]

                let compareColVal = getCompareColumValue({ comparisonCriteria, year, item })
                
                if (!fetchedData[compareColVal]) {
                    fetchedData[compareColVal] = {}
                }

                const apiResult = await fetchDataAPI({ filterParamObj, pageView })
                fetchedData[compareColVal] = { ...apiResult.response }
                objName = apiResult.objName
            }
        }
    }

    return  { 
        tabularRecord: getTabularData({ fetchedData, comparisonCriteria, objName, pageView, comparisonObj, isCompareTotal, filterParamObj, filterParams }), 
        totalRecord: await getCompareTotalRow({ pageView, filterParams, totalColName, isCompareTotal })
    }
}

const fetchDataAPI = async ({ filterParamObj, pageView }) => {
    let response = {}
    let objName = ""
    switch (pageView) {
        case "FeelingAtHome":
            objName = "feelAtHomeDetailedReport"
            response = await getFeelingAtHomeDetailDataService({ filterObj: { ...filterParamObj }, reportName: pageView })
            break
        case "YouAreAwesome":
            objName = "finalProcessedData"
            response = await getYouAreAwesomeDetailDataService({ filterObj: { ...filterParamObj }, reportName: pageView })
            break
        case "Diversity":
            response = await getDiversityDetailDataService({ filterObj:  { ...filterParamObj } })
            objName = "finalProcessedData"
            break
        case "ExitAnalysis": 
            response = await getExitAnalysisDetailDataService({ filterObj: { ...filterParamObj }, reportName: pageView })
            objName = "finalProcessedData"
            break
        case "FifteenFeedback":
            response = await getObFeedbackDetailDataService({ filterObj: { ...filterParamObj }, feedbackType: "15" })
            objName = "finalProcessedData"
            break
        case "SixtyFeedback":
            response = await getObFeedbackDetailDataService({ filterObj: { ...filterParamObj }, feedbackType: "90" })
            objName = "finalProcessedData"
            break
        default:
            break
    }

    return { response, objName }
}

const getCustomCompareColumValue = ({ comparisonObj }) => {
    return `${comparisonObj.compareFromDate}_${comparisonObj.compareToDate}`
}

const getCompareColumValue = ({ year, item }) => {
    return `${year}_${item}`
}

const getTabularData = ({ fetchedData, comparisonCriteria, objName, pageView, comparisonObj, isCompareTotal = false, filterParams}) => {
    let finalData = { [objName]: {} }
    let allFilterData = []
    let selectedReportType = []
    let questionNames = {}
    let selectedExitReportType = ""
    let feedbackReport = {}

    for (const key in fetchedData) {
        const [year, criteria] = key.split("_")
        let timeFrame = ``
        if (comparisonCriteria === "custom") {
            timeFrame = `${comparisonObj.compareFromDate} : ${comparisonObj.compareToDate}`
        } else {
            timeFrame = `${COMPARE_DATE_CONST[comparisonCriteria][criteria].name}-${year}`
        }

        if (!allFilterData.length) {
            allFilterData = fetchedData[key].allFilterData
        }

        if (pageView === "YouAreAwesome" && !selectedReportType.length) {
            selectedReportType = fetchedData[key].selectedReportType
        }

        if (["FifteenFeedback", "SixtyFeedback", "ExitAnalysis"].includes(pageView)) {
            if (!Object.keys(questionNames).length) {
                questionNames = fetchedData[key].questionNames
            }
            if (!Object.keys(feedbackReport).length) {
                feedbackReport = fetchedData[key].feedbackReport
            }
            if (pageView === "ExitAnalysis" && !selectedExitReportType) {
                selectedExitReportType = fetchedData[key].selectedExitReportType
            }
        }

        for (const ordered in fetchedData[key][objName]) {
            let recObj = fetchedData[key][objName][ordered]
            let timeFrameOrder = ""
            
            if (comparisonCriteria !== "custom") {
                if (["finYear", "quarter"].includes(filterParams.dateFilterType) && comparisonCriteria === "quarter"){
                    timeFrameOrder = getTimeFrameOrder({ filterParams, key, sort: COMPARE_DATE_CONST[comparisonCriteria][criteria].sort, comparisonCriteria })
                } else {
                    timeFrameOrder = `${year}${COMPARE_DATE_CONST[comparisonCriteria][criteria].sort}`
                }
            }
            
            const newOrdered = JSON.stringify({...JSON.parse(ordered), timeFrame, timeFrameOrder })
            finalData[objName][newOrdered] = { ...recObj, timeFrame, timeFrameOrder }
        }
    }

    allFilterData.unshift("timeFrame")
    return { [objName]: finalData[objName], allFilterData, selectedReportType, questionNames, feedbackReport, selectedExitReportType, isCompareTotal, isCompare: true }
}

function getTimeFrameOrder ({ filterParams, key, sort }) {
    const [ year, quarter ]= key.split("_")
    let sortVal = sort
    if (filterParams.dateFilterType === "finYear") {
        for (const finYear of filterParams.finYear) {
            const [startYearMon, endYearMon] = finYear.split("_")
            const startYear = startYearMon.substring(0, 4)
            const endYear = endYearMon.substring(0, 4)

            if (startYear === year && (["JAS", "OND"].includes(quarter) || ["07", "08", "09", "10", "11", "12"].includes(quarter))) {
                sortVal = `${endYear}${startYear}${sort}`
            } else if (endYear === year && (["JFM", "AMJ"].includes(quarter) || ["01", "02", "03", "04", "05", "06"].includes(quarter))) {
                sortVal = `${endYear}${startYear}${sort}`
            }
        }
    } else if (filterParams.dateFilterType === "quarter") {
        if ((["JAS", "OND"].includes(quarter) || ["07", "08", "09", "10", "11", "12"].includes(quarter))) {
            sortVal = `${Number(year)+1}${year}${sort}`
        } else if ((["JFM", "AMJ"].includes(quarter) || ["01", "02", "03", "04", "05", "06"].includes(quarter))) {
            sortVal = `${year}${Number(year)+1}${sort}`
        }
    }

    return sortVal
}

const getCompareTotalRow = async ({ pageView, filterParams, totalColName }) => {
    let response = {}
    let objName = ""
    switch (pageView) {
        case "FeelingAtHome":
            objName = "feelAtHomeDetailedReport"
            response = await getFeelingAtHomeDetailDataService({ filterObj: { ...filterParams }, reportName: pageView })
            break
        case "YouAreAwesome":
            objName = "finalProcessedData"
            response = await getYouAreAwesomeDetailDataService({ filterObj: { ...filterParams }, reportName: pageView })
            break
        case "Diversity":
            response = await getDiversityDetailDataService({ filterObj:  { ...filterParams } })
            objName = "finalProcessedData"
            break
        case "ExitAnalysis": 
            response = await getExitAnalysisDetailDataService({ filterObj: { ...filterParams }, reportName: pageView })
            objName = "finalProcessedData"
            break
        case "FifteenFeedback":
        case "SixtyFeedback":
            let feedbackType = pageView === "FifteenFeedback" ? "15" : "90"
            response = await getObFeedbackDetailDataService({ filterObj: { ...filterParams }, feedbackType })
            objName = "finalProcessedData"
            break
        default:
            break
    }
    
    return { 
        totalRowRecord: response[objName], 
        attritionData: response.attritionData || {},
        feedbackReport: response.feedbackReport || {},
        totalColName
    }
}

