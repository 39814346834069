import React from 'react'

// @material-ui/core components
import Table from '@material-ui/core/Table'

// core components
import { useStyles } from './style'

export default function CustomTable ({ children }) {
  const classes = useStyles()
  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>{children}</Table>
    </div>
  )
}
