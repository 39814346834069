import React from "react"
import { CircularProgress, Button, Grid } from "@material-ui/core"


const PdfViewer = ({ 
    pdfFileUrl,
    loading = false,
    handleSendEmail = () => {},
    handleContinue = () => {}
}) => {
    
    return (
        <Grid style={{ padding: "10 10 10 10" }}>
            <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                    <iframe title="CEO Letter" src={pdfFileUrl} width="100%" height="600px"></iframe>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={4} sm={4} md={4}></Grid>
                {
                    loading ? <Grid style={{ textAlign: "center" }} item xs={4} sm={4} md={4}>
                            <CircularProgress size={24} />
                        </Grid> 
                        :
                        <React.Fragment>
                            <Grid item xs={2} sm={2} md={2} style={{ padding: "10px" }}>
                                <Button 
                                onClick={() => handleContinue()}
                                style={{ backgroundColor: '#4559C9', color: "white", padding: "5px", borderRadius: "5px", textTransform: "none" }}>
                                Continue to Edit</Button> 
                            </Grid>
                            <Grid item xs={2} sm={2} md={2} style={{ padding: "10px" }}>
                                <Button 
                                    onClick={() => handleSendEmail()}
                                    style={{ backgroundColor: '#2A6D3F', color: "white", padding: "5px", borderRadius: "5px", textTransform: "none" }}>
                                    Send</Button>
                            </Grid>
                        </React.Fragment>
                }
                <Grid item xs={4} sm={4} md={4}></Grid>
            </Grid>
        </Grid>
    )
}

export default PdfViewer