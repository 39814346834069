import React, { useEffect, useState, useRef } from 'react'
import moment from 'moment'
import {useStyles} from './style'
import { getAllCampaigns, updateCampaignsOrder } from '../fetchData'

import { TableCell, TableContainer, TableHead, TableRow, Table, TableBody } from '@material-ui/core'
import HeaderLayout from '../HeaderLayout'
import SimpleAlerts from '../../Joiner/Alert'
import PeopleOutlineTwoTone from '@material-ui/icons/PeopleOutlineTwoTone'
import { Paper, FormControl, Select, MenuItem, Button, InputLabel } from '@material-ui/core'
import qs from 'qs'

import SaveIcon from '@material-ui/icons/Save';
import DialogComponent from '../../../components/Shared/Elements/DialogComponent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import Checkbox from '@material-ui/core/Checkbox'
import ListItemText from '@material-ui/core/ListItemText'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import {getLoggedInUserDetailsFunc} from '../../../utils/authFunction'
import {SUPERADMIN} from '../../../constants/CommonConstant'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const statusList = ['Active', 'Inactive']

export default function index(props) {

    const dragItem = useRef()
    const dragOverItem = useRef()
    const [isDraggable, setIsDraggable] = useState(true)

    const [allCampaigns, setAllCampaigns] = useState([])
    const [notification, setNotification] = useState({type: 'success',  message: ''})
    const [filterStatus, setFilterStatus] = useState(['Active'])
    const [initialAllCampaigns, setInitialAllCampaigns] = useState([])
    const [orderChanged, setOrderChanged] = useState(false)
    const [showFilterList, setShowFilterList] = useState(false)
    
    const dialogTitle = `Confirm`
    const [openDialog, setOpenDialog] = useState(false)

    const [pageEdit,setPageEdit] =  useState('No')
    const [pageAdd,setPageAdd] =  useState('No')
    const [superAdmin,setSuperAdmin] =  useState('No')
    

    const handleDialogOpen = () => {
        setOpenDialog(true)
    }

    const handleDialogClose = (val) => {
        if (val === true) {
            saveCampaignOrder()
        }
        setOpenDialog(false)
    }

    const classes = useStyles();

    useEffect(()=> {
        getAllCampaignsData({ filterStatus })
        setUrlParams()
        setPageAcessses()
    },[])

    const setUrlParams = async () => {
        let searchParam = qs.parse(props.location.search, { ignoreQueryPrefix: true }).param
        if(searchParam === 'updated'){
            setNotification({type:'success', message: 'Campaign updated successfully'})
        }
    }

    const setPageAcessses = async () => {
        let loggedUserDetails = await getLoggedInUserDetailsFunc()
        let superAdminTemp = false
        if(loggedUserDetails.data.data.adminUserGroupId == SUPERADMIN){
            superAdminTemp = true
        }
        let pageAddTemp = qs.parse(props.location.search, { ignoreQueryPrefix: true }).pageAdd
        let pageEditTemp = qs.parse(props.location.search, { ignoreQueryPrefix: true }).pageEdit
        setPageAdd(pageAddTemp)
        setPageEdit(pageEditTemp)
        setSuperAdmin(superAdminTemp)
    }

    const getAllCampaignsData = async ({ filterStatus }) => {
        try {
            let response = {}
            response = await getAllCampaigns({ filterStatus })
            let serialNo = 0;
            let finalResponse = response.data.map( row => {
                serialNo = serialNo + 1;
                return {...row,serialNo}
            })
            
            const isDraggable = checkInactiveRecordFound(finalResponse)
            console.log(`isDraggable`, isDraggable)
            setIsDraggable(isDraggable)
            setAllCampaigns(finalResponse)
            setInitialAllCampaigns(finalResponse)

            let { campaignStatus } = response || ''
            setFilterStatus(campaignStatus.split(','))
            setOrderChanged(false)
        } catch (error) {
            
        }
    }

    const checkInactiveRecordFound = (finalResponse = []) => {
        let isDraggable = true
        for (const element of finalResponse) {
            if (element.campaignStatus === 'Inactive') {
                isDraggable = false
            }
        }
        return isDraggable
    }

    const handleChange = (e) => {
        const { target: { value } } = e
        let status = typeof value === 'string' ? value.split(',') : value
        status = status.filter((el) => { return !['', null, undefined].includes(el)})
        setFilterStatus(status)
        getAllCampaignsData({ filterStatus: status.join(',') })
    }

    const closeAlert = () => {
        setNotification({type:'', message: ''})
    }
    
    const dragStart = (e, position) => {
        dragItem.current = position
    }

    const dragEnter = (e, position) => {
        dragOverItem.current = position
    }

    const drop = (e) => {
        const copyListItems = [ ...allCampaigns ]
        const dragItemContent = copyListItems[dragItem.current]
        copyListItems.splice(dragItem.current, 1)
        copyListItems.splice(dragOverItem.current, 0, dragItemContent)
        dragItem.current = null
        dragOverItem.current = null
        let orderChanged = checkIfOrderChanged(copyListItems)
        setOrderChanged(orderChanged)
        setAllCampaigns(copyListItems)
    }

    const checkIfOrderChanged = (copyListItems = []) => {
        let orderChanged = false
        for (let index = 0; index < initialAllCampaigns.length; index++) {
            if (initialAllCampaigns[index].id !== copyListItems[index].id) {
                orderChanged = true
            }
        }
        return orderChanged
    }

    const saveCampaignOrder = async () => {
        let notification = { type: '', message: ''  }
        const listCount = allCampaigns.length
        const orderData = allCampaigns.map((obj, index) => { return { id:obj.id, sort_order: listCount - index }})
        const { data, status} = await updateCampaignsOrder({campaignData: orderData, campaignStatus: filterStatus})
        
        if (status === 200) {
            setAllCampaigns(data.data)
            notification.type = 'success'
            notification.message = data.message
        } else {
            notification.type = 'error'
            notification.message = `Something went wrong! Please try again.`
        }
        setOrderChanged(false)
        setNotification(notification)
    }

    const handleFilter = () => {
        setShowFilterList(!showFilterList)
    }
  return (
    <div >
        <HeaderLayout
            title='Campaign'
            subTitle='Campaign List'
            icon={<PeopleOutlineTwoTone/>}
        />
        {
            notification.message ? (
                <div className={classes.notification}>
                    <SimpleAlerts 
                        type={notification.type}
                        message={notification.message}
                        handleAlert={closeAlert}
                    />
                </div>
            ) : (null)
        }
        <Paper className={classes.pageContent}>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell  colSpan={10}>
                                <ul>
                                    <li>Drag and drop active campaigns for sequencing.</li>
                                    <li>The bottom record will be shown first in the homepage banner.</li>
                                </ul>
                            </TableCell>
                        </TableRow>
                    <TableRow>
                            <TableCell colSpan={6}></TableCell>
                            <TableCell colSpan={2} style={{ textAlign:"right" }}>
                                {
                                    orderChanged ? (
                                        <Button
                                            title="Save Order"
                                            variant='contained'
                                            color='secondary'
                                            size='small'
                                            className={classes.button}
                                            startIcon={<SaveIcon />}
                                            onClick={handleDialogOpen}
                                        >Save Changes</Button>
                                    ) : (null)
                                }
                            </TableCell>
                            <TableCell colSpan={2} style={{ textAlign:"right" }}>
                                <FormControl style={{ width:"100%" }}>
                                    <div>
                                        <FontAwesomeIcon className={classes.filterDiv} icon={faFilter} size="lg" onClick={handleFilter} />&nbsp;Filter
                                    </div>
                                    {
                                        showFilterList ? (
                                            <FormControl style={{ width:"100%" }}>
                                            <InputLabel id="demo-multiple-checkbox-label" style={{ marginLeft:"10px", marginTop:"-6px" }}>Status</InputLabel>
                                            <Select
                                                labelId="demo-multiple-checkbox-label"
                                                id="demo-multiple-checkbox"
                                                multiple
                                                value={filterStatus}
                                                onChange={handleChange}
                                                name="filterStatus"
                                                input={<OutlinedInput label="Tag" />}
                                                renderValue={(selected) => selected.join(', ')}
                                                MenuProps={MenuProps}
                                            >
                                            {
                                                statusList.map((name) => 
                                                    <MenuItem key={name} value={name}>
                                                        <Checkbox checked={filterStatus.indexOf(name) > -1} />
                                                        <ListItemText primary={name} />
                                                    </MenuItem>
                                                )
                                            }
                                            </Select>
                                            </FormControl>
                                        ) : (null)
                                    }
                                    
                                </FormControl>
                            </TableCell>
                    </TableRow>
                        <TableRow>
                            <TableCell><b>S.No.</b></TableCell>
                            <TableCell><b>Name</b></TableCell>
                            <TableCell><b>Template Name</b></TableCell>
                            <TableCell><b>Type</b></TableCell>
                            <TableCell><b>Start Date</b></TableCell>
                            <TableCell><b>End Date</b></TableCell>
                            <TableCell><b>DOJ From</b></TableCell>
                            <TableCell><b>DOJ To</b></TableCell>
                            <TableCell><b>Status</b></TableCell>
                            <TableCell><b>Action</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { ( allCampaigns && allCampaigns.length > 0 ) ? allCampaigns.map((element, index)=>(
                            isDraggable ? (
                                <TableRow 
                                key={index} 
                                draggable={isDraggable}
                                onDragStart={(e) => dragStart(e, index)}
                                onDragEnter={(e) => dragEnter(e, index)}
                                onDragEnd={drop}
                                onDragOver={(e) => e.preventDefault()}
                                >
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{element.name}</TableCell>
                                <TableCell>{element.templateName}</TableCell>
                                <TableCell>{element.typeName}</TableCell>
                                <TableCell style={{ width: 90 }}>{moment(element.startDate).format('DD-MMM-YYYY')}</TableCell>
                                <TableCell style={{ width: 90 }}>{moment(element.endDate).format('DD-MMM-YYYY')}</TableCell>
                                <TableCell style={{ width: 90 }}>{ element.dojFrom ? moment(element.dojFrom).format('DD-MMM-YYYY') : '' }</TableCell>
                                <TableCell style={{ width: 90 }}>{ element.dojTo ? moment(element.dojTo).format('DD-MMM-YYYY') : '' }</TableCell>
                                <TableCell>{element.campaignStatus}</TableCell>
                                <TableCell>
                                    { (pageEdit == "Yes" || superAdmin ) ? <a target='blank' href={process.env.REACT_APP_BASE_URL+'/campaign/index/create-campaign/campaignId/'+element.id}>EDIT</a>
                                    : null}
                                </TableCell>
                                
                            </TableRow>
                                    
                            ) : (
                            <TableRow key={index} >
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{element.name}</TableCell>
                                <TableCell>{element.templateName}</TableCell>
                                <TableCell>{element.typeName}</TableCell>
                                <TableCell style={{ width: 90 }}>{moment(element.startDate).format('DD-MMM-YYYY')}</TableCell>
                                <TableCell style={{ width: 90 }}>{moment(element.endDate).format('DD-MMM-YYYY')}</TableCell>
                                <TableCell style={{ width: 90 }}>{ element.dojFrom ? moment(element.dojFrom).format('DD-MMM-YYYY') : '' }</TableCell>
                                <TableCell style={{ width: 90 }}>{ element.dojTo ? moment(element.dojTo).format('DD-MMM-YYYY') : '' }</TableCell>
                                <TableCell>{element.campaignStatus}</TableCell>
                                <TableCell>
                                    { (pageEdit == "Yes" || superAdmin )? <a target='blank' href={process.env.REACT_APP_BASE_URL+'/campaign/index/create-campaign/campaignId/'+element.id}>EDIT</a>
                                    : null}
                                    </TableCell>
                                
                            </TableRow>    
                            )
                            
                                
                        )) : (
                            <TableRow>
                            <TableCell><b>No result found</b></TableCell>
                            </TableRow>
                        ) } 
                    </TableBody>
                </Table>
            </TableContainer>
            <DialogComponent
              openDialog={openDialog}
              dialogTitle={dialogTitle}
              dialogContentText='Are you sure you want save the current campaign order?'
              handleDialogClose={handleDialogClose}
            />
        </Paper>
    </div>
  )
}
