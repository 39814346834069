// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
     background-color: whitesmoke;
}
.log-out {
     float: right;
     margin-top: 0%;
     margin-right: 0%;
     cursor: pointer;
}
.logo-image {
     width: 180px;
     margin-top: 0;
     cursor: pointer;
     /* margin-top: 20px; */
     margin-left: 30px;
}
.profile-div {
     float: right;
     margin-top: 1%;
     margin-right: 5%;
}
.logout-logo {
     float: right;
     padding-left: 8px;
}
.logo_wrap {
     height: 93px;
}`, "",{"version":3,"sources":["webpack://./src/components/Header/Header.css"],"names":[],"mappings":"AAAA;KACK,4BAA4B;AACjC;AACA;KACK,YAAY;KACZ,cAAc;KACd,gBAAgB;KAChB,eAAe;AACpB;AACA;KACK,YAAY;KACZ,aAAa;KACb,eAAe;KACf,sBAAsB;KACtB,iBAAiB;AACtB;AACA;KACK,YAAY;KACZ,cAAc;KACd,gBAAgB;AACrB;AACA;KACK,YAAY;KACZ,iBAAiB;AACtB;AACA;KACK,YAAY;AACjB","sourcesContent":[".header {\n     background-color: whitesmoke;\n}\n.log-out {\n     float: right;\n     margin-top: 0%;\n     margin-right: 0%;\n     cursor: pointer;\n}\n.logo-image {\n     width: 180px;\n     margin-top: 0;\n     cursor: pointer;\n     /* margin-top: 20px; */\n     margin-left: 30px;\n}\n.profile-div {\n     float: right;\n     margin-top: 1%;\n     margin-right: 5%;\n}\n.logout-logo {\n     float: right;\n     padding-left: 8px;\n}\n.logo_wrap {\n     height: 93px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
