import React from "react"
import { TableRow, TableCell } from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'

import { useStyles } from "./style"


const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow)

const CommonTableBody = ({ 
    columns = [],
    row = {},
    idx = 0,
    recCount = 0,
    colCount = 0,
    isTotalRow = false,
    actionId = "",
    actionName = "",
    handleClick = undefined,
    page,
    rowsPerPage
}) => {
    const classes = useStyles()
    const labelId = `enhanced-table-checkbox-${idx}`
    
    const getCellData = ({ headCell, row, actionId, actionName}) => {
        //if (handleClick !== undefined && headCell.isActionBtn && actionId && actionName && !row[actionName]) {
        
        if (handleClick !== undefined && headCell.isActionBtn && actionId) {
            return <div onClick={() => handleClick(row[actionId])} style={{ color: "#3F50B5", cursor:"pointer" }}><FontAwesomeIcon icon={faEdit} size="lg" /> Send Email</div>
        }
        return row[headCell.id] || ""
    }

    const getEmptyRow = ({ colCount, labelId }) => {
        return <TableCell
            component='th'
            id={labelId}
            scope='row'
            align={'center'}
            colSpan={colCount}
            className={classes.tableCellDetail}
            padding='none'
        ><span style={{ color:"black" }}>No records to display</span></TableCell>
    }

    const getDataRow = ({ columns, row }) => {
        return columns.map((headCell, i) => <TableCell
            key={i}
            component='th'
            id={labelId}
            scope='row'
            align={'left'}
            padding='none'
            className={classes.tableCellDetail}
        >{headCell.id === "#" ? (page*rowsPerPage) + (idx+1) : getCellData({ headCell, row, actionId, actionName }) }</TableCell>)
    }

    const getTotalRow = ({ columns, rowObj }) => {
        return <TableRow key={idx}>{columns.map(colObj => <TableCell style={{ textAlign: "center", fontWeight: "bold", color:"black", backgroundColor: "#DBDBDB" }}>{rowObj[colObj.key]}</TableCell>)}</TableRow>
    }
    return (
        isTotalRow ? getTotalRow({ columns, rowObj:row }) :
        <StyledTableRow key={idx+1}>
            { recCount ? getDataRow({ columns, row }) : getEmptyRow({ colCount, labelId }) }
        </StyledTableRow> 
    )
}

export default CommonTableBody