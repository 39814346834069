import React, { Fragment, useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import {
  Paper,
  FormControl,
  TextField,
  InputLabel,
  Select,
  Input,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  FormHelperText,
  Divider,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';

import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import UpdateIcon from '@material-ui/icons/Update';
import ReplayIcon from '@material-ui/icons/Replay';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import SimpleAlerts from '../Alert';
import { useStyles } from './style';

import nomineeConstant from '../../../constants/NomineeConstant';
import {
  onlyAlphabets,
  onlyNumber,
  checkNameRegEx,
  checkMobileRegEx,
  checkAddressRegEx,
  checkPinCodeRegEx,
} from '../../../utils/Validation';

import { findRelationById } from '../../../utils/CommonFunction';

import { postNomineeData, deleteJoinerRecord } from '../fetchData';
import DialogComponent from '../../../components/Shared/Elements/DialogComponent';
import { scrollToViewHandler } from '../../../constants/CommonConstant'

const tableColumns = [
  { id: 'Relationship', label: 'Relationship', minWidth: 50 },
  { id: 'Name', label: 'Name', minWidth: 50 },
  {
    id: 'Share',
    label: 'Share%(Provident Fund/Gratuity)',
    minWidth: 10,
    align: 'left',
  },
  {
    id: 'DOB',
    label: 'DOB',
    minWidth: 10,
    align: 'left',
  },
  {
    id: 'Address',
    label: 'Address',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'Contact No.',
    label: 'Contact No.',
    minWidth: 10,
    align: 'left',
  },
  {
    id: 'Guardian',
    label: 'Guardian',
    minWidth: 10,
    align: 'left',
  },
  {
    id: 'Edit',
    label: '',
    minWidth: 10,
    align: 'left',
  },
  {
    id: 'Delete',
    label: '',
    minWidth: 10,
    align: 'left',
  },
];

const Nominee = ({
  errorObj,
  offerData,
  joinerPersonalData = [],
  joinerNomineeData = [],
  relationList,
  handleChildData = () => {},
  handleChildDataValidation = () => {},
  handleNotification = () => {},
}) => {
  const {
    minorNomineeAgeLimit,
    maxNomineeSharePercent,
    nomineeAgeLegality,
  } = nomineeConstant;
  
  const [
    { marital_status, father_name = '', spouse_name = '' },
  ] = joinerPersonalData;

  if (marital_status !== 'Married') {
    relationList = relationList.filter((obj) => {
      return obj.relation !== 'Spouse';
    });
  }

  const classes = useStyles();
  const [isGuardianEligible, setGuardianEligible] = useState(false);
  const [joinerNomineeDataList, setJoinerNomineeDataList] = useState(
    joinerNomineeData
  );
  const [notification, setNotification] = useState({ type: '', message: '' });
  const [isUpdate, setIsUpdate] = useState(0);
  const [deleteRecordId, setDeleteRecordId] = useState('');
  const dialogTitle = `Confirm`;
  const [openDialog, setOpenDialog] = useState(false);
  const handleDialogOpen = (id) => {
    setOpenDialog(true);
    setDeleteRecordId(id);
  };
  const handleDialogClose = (val) => {
    if (val === true) {
      handleRecordDelete(deleteRecordId);
    }
    setOpenDialog(false);
  };

  const initObj = {
    id: null,
    relationId: '',
    fullName: '',
    sharePf: '',
    shareGratuity: '',
    dateOfBirth: null,
    contactNumber: '',
    address: '',
    addressPinCode: '',
    guardianName: '',
    guardianContact: '',
    guardianAddress: '',
    guardianAddressPinCode: '',
  };
  let initErrObj = {};
  for (let k in initObj) {
    initErrObj[k] = { status: false, text: '' };
  }

  const [nomineeObj, setNomineeObj] = useState(initObj);
  const [localErrObj, setLocalErrObj] = useState(initErrObj);
  const [autoNomineeName, setAutoPickNomineeName] = useState('');
  const {
    relationId,
    fullName,
    sharePf,
    shareGratuity,
    dateOfBirth,
    contactNumber,
    address,
    addressPinCode,
    guardianName,
    guardianContact,
    guardianAddress,
    guardianAddressPinCode,
  } = nomineeObj;

  useEffect(() => {
    const isCount = joinerNomineeDataList.length ? true : false;
    handleChildData('joinerNomineeData', joinerNomineeDataList);
    handleChildDataValidation('joinerNomineeData', {
      status: !isCount,
      text: '',
    });
  }, [joinerNomineeDataList]);

  const relationIdArray = relationList.map(({ id }) => id);
  const handleChange = (e) => {
    let key = e.target.name;
    let value = e.target.value;
    if (key === 'relationId') {
      Object.keys(nomineeAgeLegality).forEach((relationId)=>{
        if(String(relationId)===String(value)){
          setGuardianEligible(false)
        }
      })
      if (dateOfBirth) {
        setNomineeObj({
          ...nomineeObj,
          [key]: value,
          fullName: pickAutoRelation(value),
          dateOfBirth: null,
        });
        setLocalErrObj({...localErrObj,dateOfBirth:{status:false,text:''}})
      } else {
        setNomineeObj({
          ...nomineeObj,
          [key]: value,
          fullName: pickAutoRelation(value),
        });
        let { text, status } = checkNomineeDataError(key, value);
        setLocalErrObj({ ...localErrObj, [key]: { text, status } });
      }
    } else {
      setNomineeObj({ ...nomineeObj, [key]: value });
      let { text, status } = checkNomineeDataError(key, value);
      setLocalErrObj({ ...localErrObj, [key]: { text, status } });
    }
  };

  const pickAutoRelation = (relationId) => {
    const relationName = findRelationById(relationList, relationId);
    let nomineeName = '';
    if (relationName === 'Father') {
      nomineeName = father_name;
    }
    if (relationName === 'Spouse') {
      nomineeName = spouse_name;
    }
    setAutoPickNomineeName(nomineeName);
    return nomineeName;
  };

  const handleInput = (e) => {
    switch (e.target.name) {
      case 'fullName':
        if (!onlyAlphabets(e.which)) {
          e.preventDefault();
        }
        break;
      case 'sharePf':
      case 'shareGratuity':
      case 'addressPinCode':
      case 'guardianAddressPinCode':
        if (!onlyNumber(e.which)) {
          e.preventDefault();
        }
        break;
      default:
        break;
    }
  };

  const handleDateChange = (val) => {
    setNomineeObj({ ...nomineeObj, dateOfBirth: val });
    let isMinor = false;
    if (moment().diff(val, 'year') < minorNomineeAgeLimit) {
      isMinor = true;
    }

    let { text, status } = checkNomineeDataError('dateOfBirth', val);
    setLocalErrObj({ ...localErrObj, dateOfBirth: { text, status } });
    const guardianEntities = relationList.filter((obj) =>
      ['Son', 'Daughter', 'Brother', 'Sister'].includes(obj.relation)
    );
    const eligibleRelation = guardianEntities.filter(
      (relation) => relation.id === relationId
    );
    if (isMinor && eligibleRelation.length && eligibleRelation[0].id) {
      setGuardianEligible(true);
    } else {
      setGuardianEligible(false);
    }
  };

  const handleSaveData = () => {
    let text = ``;
    if (nomineeObj.sharePf === '0' && nomineeObj.shareGratuity === '0' ) {
      text = `Provident Fund and Gratuity should not be 0 at the same time!`;
      setNotification({ message: text, type: 'error' });
    } else if (joinerNomineeDataList.length >= 4 && !isUpdate) {
      text = `You can add upto 4 records!`;
      setNotification({ message: text, type: 'error' });
    } else {
      let errObj = {};
      let finalStatus;
      for (let key in nomineeObj) {
        let { text, status } = checkNomineeDataError(key, nomineeObj[key]);
        errObj[key] = { text, status };
        if (status) {
          finalStatus = status;
        }
      }
      setLocalErrObj(errObj);
      if (finalStatus) {
        text =
          notification.message !== ''
            ? notification.message
            : `There is some error in the form!`;
        setNotification({ message: text, type: 'error' });
      } else {
        handleApiCall({ ...nomineeObj });
      }
    }
  };

  const handleApiCall = async (dataObj) => {
    let response = {};
    response = await postNomineeData({ nomineeDataList: [dataObj] });
    const { success = false, joinerNomineeData = [] } = response || {};
    let text = ``;
    let type = ``;
    if (success) {
      setNomineeObj(initObj);
      setJoinerNomineeDataList(joinerNomineeData);
      handleNotification({ status: false, text: '' });
      text = dataObj.id
        ? 'Record updated successfully!'
        : 'Record added successfully!';
      type = `success`;
      setIsUpdate(false);
      setGuardianEligible(false);
    } else {
      text = `Something went wrong!`;
      type = `error`;
    }

    setNotification({ message: text, type });
  };

  const getConsumedSharePercentage = () => {
    let { share_gratuity, share_pf } = getSumOfShare();
    if (isUpdate) {
      const updateRec = getNomineeRecordById(isUpdate);
      if (updateRec) {
        share_gratuity -= updateRec.share_gratuity;
        share_pf -= updateRec.share_pf;
      }
    }

    return { share_gratuity, share_pf };
  };

  const getSumOfShare = () => {
    if (joinerNomineeDataList.length) {
      return joinerNomineeDataList.reduce(
        (acc, { share_gratuity, share_pf }) => {
          return {
            share_gratuity: acc.share_gratuity + share_gratuity,
            share_pf: acc.share_pf + share_pf,
          };
        }
      );
    }

    return { share_gratuity: 0, share_pf: 0 };
  };

  const checkAge = (key, value) => {
    let wrongDob = false;
    let relationId;
    let dateOfBirth;
    let text = '';
    if (key === 'relationId') {
      relationId = value;
      dateOfBirth = nomineeObj.dateOfBirth;
    }
    if (key === 'dateOfBirth') {
      relationId = nomineeObj.relationId;
      dateOfBirth = value;
    }
    if (
      relationId &&
      dateOfBirth &&
      moment(dateOfBirth).isValid() &&
      Object.keys(nomineeConstant.nomineeAgeLegality)
        .map(Number)
        .includes(relationId)
    ) {
      wrongDob =
        moment().diff(moment(dateOfBirth), 'year') <
        nomineeAgeLegality[relationId];
      if (wrongDob) {
        text = `${findRelationById(relationList, relationId)} must be at least ${
          nomineeConstant.nomineeAgeLegality[relationId]
        } years old.`;
      }
    }

    setNotification({ message: text, type: `error` });
    return wrongDob;
  };

  const checkNomineeDataError = (key, value) => {
    let text = '';
    let status = false;
    switch (key) {
      case 'relationId':
        if (!relationIdArray.includes(value)) {
          status = true;
          text = 'Select a valid Relationship';
        }
        checkAge(key, value);
        break;
      case 'fullName':
        if (!checkNameRegEx(value)) {
          status = true;
          text = 'Invalid Full Name';
        }
        break;
      case 'sharePf':
        const { share_pf } = getConsumedSharePercentage()
        if (value === '') {
          status = true;
          text = 'Invalid PF Share %';
        }

        // if (share_pf === 100 && value !== '0') {
        //   status = true;
        //   text = 'Invalid PF Share %';
        // }
        
        if (share_pf + parseFloat(value) > maxNomineeSharePercent) {
          status = true;
          text = 'Reached PF Share % limit';
        }
        break;
      case 'shareGratuity':
        const { share_gratuity } = getConsumedSharePercentage()
        if (value === '') {
          status = true;
          text = 'Invalid Gratuity Share %';
        }

        // if (share_gratuity === 100 && value !== '0') {
        //   status = true;
        //   text = 'Invalid Gratuity Share %';
        // }

        if (share_gratuity + parseFloat(value) > maxNomineeSharePercent) {
          status = true;
          text = 'Reached Gratuity Share % limit';
        }
        break;
      case 'dateOfBirth':
        if (!relationId) {
          setNotification({
            type: 'error',
            message:
              'Please select a valid relationship before entering date of birth',
          });
        } else if (value === null || !moment(value).isValid()) {
          status = true;
          text = 'Invalid Date Of Birth';
        } else if (checkAge(key, value)) {
          status = true;
          text = 'Invalid Date Of Birth';
        }
        break;
      case 'contactNumber':
        if (value === '' || (value !== '' && !checkMobileRegEx(value))) {
          status = true;
          text = 'Invalid Contact Number';
        }
        break;
      case 'address':
        if (
          value === '' ||
          (value !== '' && !checkAddressRegEx(value)) ||
          value.length < 3
        ) {
          status = true;
          text = 'Invalid Address';
        }
        break;
      case 'addressPinCode':
        if (value === '' || (value !== '' && !checkPinCodeRegEx(value))) {
          status = true;
          text = 'Invalid Pin Code';
        }
        break;
      case 'guardianName':
        if (isGuardianEligible && !checkNameRegEx(value)) {
          status = true;
          text = 'Invalid Guardian Name';
        }
        break;
      case 'guardianContact':
        if (
          isGuardianEligible &&
          (value === '' || (value !== '' && !checkMobileRegEx(value)))
        ) {
          status = true;
          text = 'Invalid Contact Number';
        }
        break;
      case 'guardianAddress':
        if (
          isGuardianEligible &&
          (value === '' || (value !== '' && !checkAddressRegEx(value)))
        ) {
          status = true;
          text = 'Invalid Address';
        }
        break;
      case 'guardianAddressPinCode':
        if (
          isGuardianEligible &&
          (value === '' || (value !== '' && !checkPinCodeRegEx(value)))
        ) {
          status = true;
          text = 'Invalid Pin Code';
        }
        break;
      default:
        break;
    }

    return { text, status };
  };

  const handleRecordEdit = (e, recId) => {
    scrollToViewHandler('main-form-grid-div')
    handleNotification({ status: false, text: '' });
    setNotification({ message: '', type: '' });
    const updateRec = getNomineeRecordById(recId);
    if (updateRec) {
      const {
        id,
        relation_id,
        name,
        share_pf,
        share_gratuity,
        date_of_birth,
        contact_no,
        address,
        address_pin_code,
        guardian_name = '',
        guardian_contact = '',
        guardian_address = '',
        guardian_address_pin_code = '',
      } = updateRec;
      const updateObj = {
        id: id,
        relationId: relation_id,
        fullName: name,
        sharePf: share_pf,
        shareGratuity: share_gratuity,
        dateOfBirth: date_of_birth,
        contactNumber: contact_no,
        address: address,
        addressPinCode: address_pin_code,
        guardianName: guardian_name || '',
        guardianContact: guardian_contact || '',
        guardianAddress: guardian_address || '',
        guardianAddressPinCode: guardian_address_pin_code || '',
      };
      setNomineeObj(updateObj);
      if (
        updateObj.guardianName &&
        updateObj.guardianContact &&
        updateObj.guardianAddress &&
        updateObj.guardianAddressPinCode
      ) {
        setGuardianEligible(true);
      } else {
        setGuardianEligible(false);
      }
      setIsUpdate(recId);
      setLocalErrObj(initErrObj);
    }
  };

  const getNomineeRecordById = (id) => {
    return joinerNomineeDataList.find((obj) => obj.id === id);
  };

  const handleReset = () => {
    setLocalErrObj(initErrObj);
    setNomineeObj(initObj);
    setIsUpdate(false);
    setGuardianEligible(false);
    setNotification({ message: '', type: '' });
    handleNotification({ status: false, text: '' });
  };

  const handleRecordDelete = async (id) => {
    let text = ``;
    let type = ``;
    setLocalErrObj(initErrObj);
    setNomineeObj(initObj);
    setIsUpdate(false);
    if (id) {
      let response = {};
      response = await deleteJoinerRecord({ id, modelName: 'nominee' });
      const { success = false, joinerNomineeData = [] } = response || {};
      if (success) {
        setJoinerNomineeDataList(joinerNomineeData);
        text = `Record deleted successfully!`;
        type = `success`;
      } else {
        text = `Something went wrong!`;
        type = `error`;
      }
    }
    handleNotification({ status: false, text: '' });
    setNotification({ message: text, type });
    setDeleteRecordId('');
    setGuardianEligible(false);
  };

  const closeAlert = () => {
    setNotification({ type: '', message: '' });
  };

  return (
    <Fragment>
      <Grid container spacing={2} className={classes.root}>
        <Grid id="main-form-grid-div" item sm={10}>
          <Paper className={classes.paperFirst}>
            {notification.message ? (
              <SimpleAlerts
                type={notification.type}
                message={notification.message}
                handleAlert={closeAlert}
              />
            ) : null}
            <div style={{color: "red", float: "left", fontSize: "11px", paddingTop: "10px"}}>
              <sup>*</sup> Kindly read general instructions before filling in the information on this page
            </div>
            <Grid container spacing={2}>
              <FormControl className={classes.formControl}>
                <InputLabel
                  required={true}
                  error={localErrObj.relationId.status}
                >
                  Relationship
                </InputLabel>
                <Select
                  value={relationId}
                  name='relationId'
                  input={<Input id='relationId' />}
                  onChange={(e) => handleChange(e)}
                  error={localErrObj.relationId.status}
                >
                  <MenuItem value='' disabled>
                    <em>Select the value</em>
                  </MenuItem>
                  {relationList.map(({ id, relation }, index) => {
                    const selectedIds = joinerNomineeDataList
                      .filter((nominee) => {
                        if (
                          [
                            'Father',
                            'Mother',
                            'Father in law',
                            'Mother in law',
                          ].includes(nominee.relation_name)
                        ) {
                          return nominee.relation_id;
                        }
                        return null;
                      })
                      .map((nominee) => nominee.relation_id);
                    if (!selectedIds.includes(id) && !isUpdate) {
                      return (
                        <MenuItem key={index} value={id}>
                          {relation}
                        </MenuItem>
                      );
                    }
                    if (isUpdate) {
                      const selectedIds = joinerNomineeDataList.map(
                        (nominee) => {
                          if (
                            nominee.relation_id !== relationId &&
                            [
                              'Father',
                              'Mother',
                              'Father in law',
                              'Mother in law',
                            ].includes(nominee.relation_name)
                          ) {
                            return nominee.relation_id;
                          }
                          return null;
                        }
                      );
                      if (!selectedIds.includes(id)) {
                        return (
                          <MenuItem key={index} value={id}>
                            {relation}
                          </MenuItem>
                        );
                      }
                    }
                    return false;
                  })}
                </Select>
                <FormHelperText style={{ color: 'red' }}>
                  {localErrObj.relationId.text}
                </FormHelperText>
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  autoComplete='off'
                  required={true}
                  label='Full Name'
                  placeholder='Full Name'
                  value={fullName}
                  name='fullName'
                  onChange={(e) => handleChange(e)}
                  onKeyPress={(e) => handleInput(e)}
                  inputProps={{
                    minLength: 2,
                    maxLength: 100,
                    readOnly: autoNomineeName !== '' ? true : false,
                  }}
                  error={localErrObj.fullName.status}
                  helperText={localErrObj.fullName.text}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  autoComplete='off'
                  required={true}
                  label='For Provident Fund'
                  placeholder='Share %'
                  value={sharePf}
                  name='sharePf'
                  onChange={(e) => handleChange(e)}
                  onKeyPress={(e) => handleInput(e, 'sharePf')}
                  inputProps={{ minLength: 1, maxLength: 3 }}
                  error={localErrObj.sharePf.status}
                  helperText={localErrObj.sharePf.text}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  autoComplete='off'
                  required={true}
                  label='For Gratuity'
                  placeholder='Share %'
                  value={shareGratuity}
                  name='shareGratuity'
                  onChange={(e) => handleChange(e)}
                  onKeyPress={(e) => handleInput(e, 'shareGratuity')}
                  inputProps={{ minLength: 1, maxLength: 3 }}
                  error={localErrObj.shareGratuity.status}
                  helperText={localErrObj.shareGratuity.text}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    label='Date of Birth'
                    format='dd-MM-yyyy'
                    clearable={false}
                    name='dateOfBirth'
                    value={dateOfBirth}
                    onChange={handleDateChange}
                    disableFuture={true}
                    inputProps={{ readOnly: true }}
                    required={true}
                    error={localErrObj.dateOfBirth.status}
                    helperText={localErrObj.dateOfBirth.text}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            </Grid>
            <Grid container spacing={2}>
              <FormControl className={classes.formControl}>
                <TextField
                  autoComplete='off'
                  multiline={true}
                  label='Nominee Address'
                  value={address}
                  name='address'
                  required={true}
                  onChange={(e) => handleChange(e)}
                  onKeyPress={(e) => handleInput(e)}
                  inputProps={{ minLength: 1, maxLength: 100 }}
                  error={localErrObj.address.status}
                  helperText={localErrObj.address.text}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  required={true}
                  autoComplete='off'
                  label='Pin Code'
                  placeholder='Nominee Address Pin'
                  value={addressPinCode}
                  name='addressPinCode'
                  onChange={(e) => handleChange(e)}
                  onKeyPress={(e) => handleInput(e, 'addressPinCode')}
                  inputProps={{ maxLength: 6 }}
                  error={localErrObj.addressPinCode.status}
                  helperText={localErrObj.addressPinCode.text}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  style={{ width: '250px' }}
                  required={true}
                  autoComplete='off'
                  label='Nominee Contact Number'
                  placeholder='Nominee Contact Number'
                  value={contactNumber}
                  name='contactNumber'
                  onChange={(e) => handleChange(e)}
                  onKeyPress={(e) => handleInput(e, 'contactNumber')}
                  inputProps={{ minLength: 9, maxLength: 14 }}
                  error={localErrObj.contactNumber.status}
                  helperText={localErrObj.contactNumber.text}
                />
                <div
                  style={{
                    fontSize: 10,
                    wordWrap: 'normal',
                    width: '250px',
                    textAlign: 'left',
                    marginTop: '5px',
                  }}
                >
                  Please add country code before entering mobile number. For
                  example, '+91XXXXXXXXXX'
                </div>
              </FormControl>
            </Grid>
            <Grid
              container
              spacing={2}
              className={
                isGuardianEligible
                  ? classes.formControlActive
                  : classes.formControlInactive
              }
            >
              <FormControl className={classes.formControl}>
                <TextField
                  autoComplete='off'
                  label='Guardian Name'
                  placeholder='Guardian Name'
                  value={guardianName}
                  name='guardianName'
                  onChange={(e) => handleChange(e)}
                  onKeyPress={(e) => handleInput(e, 'guardianName')}
                  inputProps={{ minLength: 2, maxLength: 100 }}
                  error={localErrObj.guardianName.status}
                  helperText={localErrObj.guardianName.text}
                  required
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  autoComplete='off'
                  label='Guardian Contact'
                  placeholder='Guardian Contact'
                  value={guardianContact}
                  name='guardianContact'
                  onChange={(e) => handleChange(e)}
                  onKeyPress={(e) => handleInput(e, 'guardianContact')}
                  inputProps={{ minLength: 9, maxLength: 14 }}
                  error={localErrObj.guardianContact.status}
                  helperText={localErrObj.guardianContact.text}
                  required
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  autoComplete='off'
                  multiline={true}
                  label='Guardian Address'
                  value={guardianAddress}
                  name='guardianAddress'
                  required={true}
                  onChange={(e) => handleChange(e)}
                  onKeyPress={(e) => handleInput(e)}
                  inputProps={{ minLength: 1, maxLength: 100 }}
                  error={localErrObj.guardianAddress.status}
                  helperText={localErrObj.guardianAddress.text}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  autoComplete='off'
                  label='Pin Code'
                  placeholder='Guardian Address Pin'
                  value={guardianAddressPinCode}
                  name='guardianAddressPinCode'
                  onChange={(e) => handleChange(e)}
                  onKeyPress={(e) => handleInput(e, 'guardianAddressPinCode')}
                  inputProps={{ maxLength: 6 }}
                  error={localErrObj.guardianAddressPinCode.status}
                  helperText={localErrObj.guardianAddressPinCode.text}
                  required
                />
              </FormControl>
            </Grid>
            <Grid container spacing={2} className={classes.formControlAddBtn}>
              <FormControl className={classes.formControlAddBtn}>
                {isUpdate ? (
                  <Button
                    variant='contained'
                    color='secondary'
                    size='small'
                    className={classes.button}
                    startIcon={<UpdateIcon />}
                    onClick={handleSaveData}
                    disabled={
                      relationId && 
                      fullName &&
                      sharePf !== '' &&
                      shareGratuity !== '' &&
                      dateOfBirth &&
                      contactNumber &&
                      address &&
                      addressPinCode &&
                      !localErrObj.relationId.status &&
                      !localErrObj.fullName.status &&
                      !localErrObj.sharePf.status &&
                      !localErrObj.shareGratuity.status &&
                      !localErrObj.dateOfBirth.status &&
                      !localErrObj.contactNumber.status &&
                      !localErrObj.address.status &&
                      !localErrObj.addressPinCode.status &&
                      (
                        !isGuardianEligible ||
                        (
                          isGuardianEligible &&
                          guardianName && !localErrObj.guardianName.status &&
                          guardianAddress && !localErrObj.guardianAddress.status&&
                          guardianAddressPinCode && !localErrObj.guardianAddressPinCode.status &&
                          guardianContact && !localErrObj.guardianContact.status
                        )
                      )
                      ? false: true
                    }
                  >
                    Update
                  </Button>
                ) : (
                  <Button
                    variant='contained'
                    color='secondary'
                    size='small'
                    className={classes.button}
                    startIcon={<SaveIcon />}
                    onClick={handleSaveData}
                    disabled={
                      relationId && 
                      fullName &&
                      sharePf &&
                      shareGratuity &&
                      dateOfBirth &&
                      contactNumber &&
                      address &&
                      addressPinCode &&
                      !localErrObj.relationId.status &&
                      !localErrObj.fullName.status &&
                      !localErrObj.sharePf.status &&
                      !localErrObj.shareGratuity.status &&
                      !localErrObj.dateOfBirth.status &&
                      !localErrObj.contactNumber.status &&
                      !localErrObj.address.status &&
                      !localErrObj.addressPinCode.status &&
                      (
                        !isGuardianEligible ||
                        (
                          isGuardianEligible &&
                          guardianName && !localErrObj.guardianName.status &&
                          guardianAddress && !localErrObj.guardianAddress.status&&
                          guardianAddressPinCode && !localErrObj.guardianAddressPinCode.status &&
                          guardianContact && !localErrObj.guardianContact.status
                        )
                      )
                      ? false: true
                    }
                  >
                    Add
                  </Button>
                )}
              </FormControl>
              <FormControl className={classes.formControlAddBtn}>
                <Button
                  variant='contained'
                  size='small'
                  className={classes.button}
                  startIcon={<ReplayIcon />}
                  onClick={handleReset}
                >
                  Reset
                </Button>
              </FormControl>
            </Grid>
          </Paper>
          <Divider light />
          <Paper className={classes.root}>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label='sticky table'>
                <TableHead>
                  <TableRow>
                    {tableColumns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                        size={'small'}
                        variant={'head'}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {joinerNomineeDataList.map(
                    (
                      {
                        id,
                        name,
                        relation_name,
                        date_of_birth,
                        contact_no,
                        address,
                        address_pin_code,
                        share_pf,
                        share_gratuity,
                        is_minor,
                        guardian_name,
                        guardian_contact,
                        guardian_address,
                        guardian_address_pin_code,
                      },
                      index
                    ) => {
                      return (
                        <TableRow hover tabIndex={-1} key={index}>
                          <TableCell align={'left'} size={'small'}>
                            {relation_name}
                          </TableCell>
                          <TableCell align={'left'} size={'small'}>
                            {name}
                          </TableCell>
                          <TableCell align={'left'} size={'small'}>
                            {`${share_pf}/${share_gratuity}`}
                          </TableCell>
                          <TableCell align={'left'} size={'small'}>
                            {moment(date_of_birth).format('DD-MMM-YYYY')}
                          </TableCell>
                          <TableCell align={'left'} size={'small'}>
                            <div className={classes.overflowDivAddress}>
                              {`${address} - ${address_pin_code}`}
                            </div>
                          </TableCell>
                          <TableCell align={'left'} size={'small'}>
                            {contact_no}
                          </TableCell>
                          <TableCell align={'left'} size={'small'}>
                            {is_minor ? (
                              <div className={classes.overflowDivAddress}>
                                {`Name - ${guardian_name} \n
                                                                    Contact - ${guardian_contact}\n
                                                                    Address - ${guardian_address}-${guardian_address_pin_code}\n`}
                              </div>
                            ) : (
                              `NA`
                            )}
                          </TableCell>
                          <TableCell align={'left'} size={'small'}>
                            <div onClick={(e) => handleRecordEdit(e, id)}>
                              <EditIcon />
                            </div>
                          </TableCell>
                          <TableCell align={'left'} size={'small'}>
                            <div onClick={(e) => handleDialogOpen(id)}>
                              <DeleteForeverIcon />
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <DialogComponent
              openDialog={openDialog}
              dialogTitle={dialogTitle}
              dialogContentText='Are you sure you want to delete this nominee record?'
              handleDialogClose={handleDialogClose}
            />
          </Paper>
        </Grid>
        <Grid item sm={2}>
          <Paper className={classes.paperGeneral}>
            <div>
              <ul>
                <li className='unorder-list'>&nbsp;</li>
                <li className='unorder-list'>General Instructions:</li>
                <li className='unorder-list'>&nbsp;</li>
                <li>Share details of your family member(s) whom you wish to declare as nominee(s) and % share of your Provident Fund and Gratuity. Kindly make sure that the overall share should add up to 100% for PF and 100% for Gratuity too. You can click on ADD in case you want to add more than one nominee.</li>
                <li>To move ahead, click SAVE & NEXT.</li>
              </ul>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default Nominee;
