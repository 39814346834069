import React, { Fragment, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'

import { Auth } from '../Auth'
import HeaderLayout from '../HeaderLayout'
import { StepLayout } from '../StepLayout'
import FooterLayout from '../FooterLayout'
import { 
    getDashboardData 
} from '../fetchData'

import SimpleBackdrop from '../Backdrop'
import { useStyles } from './style' 
const TITLE = `REA India | New Joiner`


const Home = () => { 
    const classes = useStyles()
    const [responseData, setData] =  useState({})
    useEffect(() => {
        const getData = async () => {
            const response = await getDashboardData()
            setData(response)
        }
        getData()
    }, [])
    const { 
        success=null,
        message='Access denied!',
        data={}
    } = responseData
    
    const isAuthenticated = success
    const { offerData=[] } = data
    const { name='' } = offerData
    
    return (
        <Fragment>
        {
            isAuthenticated===true ? (
                <Fragment>
                    <Helmet>
                        <title>{ TITLE }</title>
                    </Helmet>
                    <Grid container className={classes.root} spacing={2} direction="column">
                        <HeaderLayout candidateName={name}/>
                        <Divider/>
                        <StepLayout data={data} />
                        <Divider/>
                        <FooterLayout />
                    </Grid>
                </Fragment>
            ) : (
                isAuthenticated===false ? (
                    <Auth message={message} />
                ) :
                (
                    <SimpleBackdrop />
                )
                
            ) 
        }
        </Fragment>
    )
}

export default Home