import React, { Component} from 'react'
import DocumentScreen from '../../pages/Efiling/DocumentScreen'
import PendingDocumentScreen from '../../pages/Efiling/PendingDocument'
import DocumentMasterScreen from '../../pages/Efiling/DocumentMaster'
import DocumentModifyScreen from '../../pages/Efiling/DocumentModify'
import DocumentMasterHrbpScreen from '../../pages/Efiling/DocumentMasterHr'
import BulkUploadScreen from '../../pages/Efiling/BulkUpload'
import DocumentMasterConfigScreen from '../../pages/Efiling/DocumentMasterConfig'
import ModuleMasterConfigScreen from '../../pages/Efiling/ModuleMasterConfig'
import MultipleDownload from '../../pages/Efiling/MutipleDownload'
import DocumentStatusReport  from '../../pages/Efiling/DocumentStatusReport'

export default class Efiling extends Component {
  constructor(props) {
    super(props);
    this.state = {
         path : props.computedMatch.params.type
    };
}
  getPage(path){
    switch(path){
      case "document":
        return DocumentScreen
      case "pendingDocument":
        return PendingDocumentScreen
      case "document-master":
        return DocumentMasterScreen
      case "document-modify":
        return DocumentModifyScreen  
      case "bulk-upload":
        return BulkUploadScreen   
      case "document-master-hrbp":
        return DocumentMasterHrbpScreen
      case "document-master-config":
        return DocumentMasterConfigScreen
      case "module-master-config":
        return ModuleMasterConfigScreen
      case "multiple-download":
        return MultipleDownload
      case "document-status-report":
        return DocumentStatusReport    
      default:
        return null
    }
  }
  render (){
    const Component = this.getPage(this.state.path)

    return (
      <div>
        { Component ? <Component {...this.props}/> : null }
      </div>
    )
  }
}