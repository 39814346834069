import React, { useState, Fragment } from 'react'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import FormHelperText from '@material-ui/core/FormHelperText'

const SelectExperience = ({
    defaultVal = '',
    label = '',
    options = [],
    errorText = '',
    isDisabled = false,
    onChangeEvent = () => {}
}) => {
  const [selectedValue, setVal] = useState(defaultVal)
  const handleChange = event => {
    onChangeEvent(event.target.value)
    setVal(event.target.value)
  }
  return (
    <Fragment>
      <InputLabel id='demo-simple-select-outlined-label'>{label}</InputLabel>
      <Select
        labelId='demo-simple-select-outlined-label'
        id='demo-simple-select-outlined'
        value={selectedValue}
        onChange={handleChange}
        label={label}
        disabled={isDisabled}
      >
        {options.map(({ id, value }, index) => (
          <MenuItem key={index} value={id} >
            {value}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText style={{ color: 'red' }}>{errorText}</FormHelperText>
    </Fragment>
  )
}

export default SelectExperience
