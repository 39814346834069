import React from 'react'
import Home from '../../pages/Campaign/Home'

export default function Campaign(props) {
  return (
    <div>
      < Home {...props}/>
    </div>
  )
}
