import axios from 'axios'
import url from 'url'

import { probationApiUrl } from '../../utils/CommonUrl'
import { getAxiosConfig, getRequestHeader } from '../../utils/CommonFunction'
import { probationConstant } from '../../constants/ProbationConstant';

let axiosConfig = getAxiosConfig({ withCredentials:true });
axiosConfig.headers = getRequestHeader()
let probFormStatusMapping = probationConstant.probationFormStatus;

export const getProbationRecords = async (filterObj) => {
   return await axios.post(`${probationApiUrl.GET_PROBATION_RECORDS}`, filterObj)
   .then(response => {
    const processedData = response && response.data && response.data.data;
    processedData.map((record) => {
      const updatedRecord = record;
      let probationStatusValue = probationConstant.probationStatusLabel[record.probationStatus] 
      let probationFormStatusValue = ""
      if(!record.probationFormStatus) {
        let formStatus = null
        if(record.isConfirmationWindowOpen == "Yes") {
          probationFormStatusValue = probFormStatusMapping[probationConstant.UNFILLED_PROBATION_FORM_STATUS]
          formStatus = 0
        } 
        updatedRecord.probationFormStatus = formStatus;
      } else {
        probationFormStatusValue = probFormStatusMapping[record.probationFormStatus];
      }
      if(record.probationStatus == probationConstant.probationStatus.ON_PROBATION && (record.status != "Active" || record.employeeStatus != "Active")) {
        probationStatusValue = "Not Applicable"
        probationFormStatusValue = "Not Applicable"
      }
      updatedRecord.probationStatusLabel = probationStatusValue
      updatedRecord.probationFormStatusLabel = probationFormStatusValue
      updatedRecord.isFormFilled = !isNaN(parseInt(record.probationFormId)) 
      return updatedRecord;
    })
    return processedData;
   })
   .catch(error => {
      return {
        data : [],
        error: error
      }
   })
}

export const getProbationFormDetails = async (empNo) => {
  return await axios.get(probationApiUrl.GET_PROBATION_FORM_DETAILS, {params: { empNo }})
  .then(response => {
    if(response && response.data && response.data.data) {
      return response.data.data;
    }
  })
  .catch(error => {
    console.log('getProbationFormDetails: fetching data error-' + error);
  })
} 

export const postProbationFormData = async (dataObj) => {
  return await axios.post(`${probationApiUrl.POST_PROBATION_FORM_DETAILS}`, dataObj)
   .then(response => {
     return response.data;
   })
   .catch(error => {
      return {
        data : [],
        error: error
      }
   })
} 

export const updateProbationFormData = async (dataObj) => {
  return await axios.post(`${probationApiUrl.UPDATE_PROBATION_FORM_DETAILS}`, dataObj)
   .then(response => {
     return response.data;
   })
   .catch(error => {
      return {
        data : [],
        error: error
      }
   })
}

export const sendEmailOnProbationClosure = async (dataObj) => {
  return await axios.post(`${probationApiUrl.SEND_EMAIL_ON_PROBATION_CLOSURE}`, dataObj)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return {
        data : [],
        error: error
      }
    })
}

export const getLoggedInUserDetailsInProbation = async () => {
  return await axios.get(probationApiUrl.GET_LOGGED_IN_USER_ROLE_FOR_PROBATION)
    .then(resp => {
      const response =  (resp && resp.data && resp.data.data) ? resp.data.data : {};
      return response;
    })
    .catch(error => {
      return {
        data : {},
        error: error
      }
    })
}

export const triggerLetterRegeneration = async (dataObj) => {
  return await axios.post(probationApiUrl.LETTER_REGENERATION, dataObj)
    .then(resp => {
      return resp.data;
    })
    .catch(error => {
      return {
        data : {},
        error: error
      }
    })
}
