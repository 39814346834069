import { Grid, Paper, FormControl, FormControlLabel, FormLabel, RadioGroup, Radio, TextField, Button, Typography, CircularProgress, Tooltip, List, ListItem, Divider, ListItemText, ListSubheader} 
from "@material-ui/core";
import DialogComponent from "../../../components/Shared/Elements/DialogComponent";
import React, { useEffect, useState} from "react";
import { useStyles } from "./style";
import HeaderLayout from "../HeaderLayout";
import PeopleOutlineTwoTone from "@material-ui/icons/PeopleOutlineTwoTone";
import qs from 'qs';
import {
  getProbationFormDetails,
  postProbationFormData,
  getLoggedInUserDetailsInProbation,
  updateProbationFormData
} from "../fetchData" 
import moment from "moment";
import { probationConstant } from "../../../constants/ProbationConstant";
import SnackBarComponent from "../../../components/Shared/Elements/SnackBarComponent";
import { isEmpty } from "../../../utils/CommonFunction";
import { getProbationFormAccessByUserRole, getFormStatusAfterAction } from "../common";

const INITIAL_POST_DATA = {
  formId: null,
  empNo: '',
  activitiesTarget: '',
  activitiesAchievement: '',
  activitiesAchievementPercentage: '',
  salesTarget: '',
  salesAchievement: '',
  salesAchievementPercentage: '',
  probationAction: '',
  overallRemarks: '',
  areaToFocusOn: '',
  sendBackRemarks: '',
  status: null,
  nextStatus: null,
}
const NUMERIC_INPUT_MAX_LIMIT = 1000000000;
const FORMATTED_NUMERIC_INPUT_MAX_LIMIT = '1,000,000,000';

const REMARKS_INPUT_CHAR_LIMIT = 500;
const { formStatusKeyToIdMapping, formAccessMapping, probationFormAccess,loggedInUserRole, probationActionInForm, higherAuthorityReachToIdMapping } = probationConstant;
const { PAA, RHR, HROPS_L1, HROPS_L2, HROPS_L3 } = loggedInUserRole;
export function ProbationForm(props) {
  const classes = useStyles();
  const [postData, setPostData] = useState(INITIAL_POST_DATA)
  const [sendBackTabIndex, setSendBackTabIndex] = useState(0);
  const [notification, setNotification] = useState({ isOpen: false, type: 'success', message: ''})
  const [loading, setLoading] = useState(false);
  const [empData, setEmpData] = useState({});
  const [dialog, setDialog] = useState({isOpen: false, contentText: '', actionName: 'approve'});
  const [loggedInUser, setloggedInUser] = useState({empNo: null, role: null})
  const [formAccess, setFormAccess] = useState(probationFormAccess.VIEW);
  const [isInputReadOnly, setIsInputReadOnly] = useState(false);
  const [shouldRedirectToListingPage, setShouldRedirectToListingPage] = useState(false);
  const [textBoxCharCount, setTextBoxCharCount] = useState({overallRemarks: 0, areaToFocusOn: 0, sendBackRemarks: 0})
  const [isFormActionTaken, setIsFormActionTaken] = useState(false)
  const [isOverallRemarksVisible, setIsOverallRemarksVisible] = useState(false)

  const [inputToolTipTextObj, setInputToolTipTextObj] = useState({
    activitiesTarget: 'Number of activities',
    activitiesAchievement: 'Actual number of activities',
    activitiesAchievementPercentage: 'Actual Number / Target',
    salesTarget: 'Sales target number',
    salesAchievement: 'Sales actual number',
    salesAchievementPercentage: 'Actual Number / Target',
  });
  const [isSendBackRemarksInputBoxVisible, setIsSendBackRemarksInputBoxVisible] = useState(false)
  const [listingPageURL, setListingPageURL] = useState(null)

  const calculatePercentage = (target, achievement) => {
    let percentage = 0;
    const parsedTarget = parseFloat(target).toFixed(2);
    const parsedAchievement = parseFloat(achievement).toFixed(2);
    if(!isNaN(parsedTarget) && !isNaN(parsedAchievement)) {
      percentage = parsedTarget ? ((parsedAchievement * 100)/ parsedTarget).toFixed(2) : 0;
    }
    return percentage;
  }

  const handleInputChange = (event, inputKey) => {
    if(['activitiesTarget',
        'activitiesAchievement',
        'salesTarget',
        'salesAchievement',
        'overallRemarks',
        'sendBackRemarks',
        'areaToFocusOn',
        'probationAction'
      ].includes(inputKey)
      ) {
        let enteredValue = event.target.value
        let updatedData = postData
        if(['activitiesTarget', 'activitiesAchievement'].includes(inputKey)) {
          enteredValue = enteredValue.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/(.*\..{1,2}).*/g, '$1').replace(/^0[^.]/, '0')
          updatedData = {...updatedData, [inputKey]: enteredValue }
          updatedData.activitiesAchievementPercentage = calculatePercentage(updatedData.activitiesTarget, updatedData.activitiesAchievement)
          setPostData(updatedData)
        } else if(['salesTarget', 'salesAchievement'].includes(inputKey)) {
          enteredValue =  enteredValue.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/(.*\..{1,2}).*/g, '$1').replace(/^0[^.]/, '0')
          updatedData = {...updatedData, [inputKey]: enteredValue }
          updatedData.salesAchievementPercentage = calculatePercentage(updatedData.salesTarget, updatedData.salesAchievement);
          setPostData(updatedData)
        } else {
          updatedData = {...updatedData, [inputKey]: enteredValue }
          if(['overallRemarks', 'areaToFocusOn', 'sendBackRemarks'].includes(inputKey)) {
            const count = enteredValue.length
            setTextBoxCharCount({...textBoxCharCount, [inputKey]: count})
          }
          setPostData(updatedData)
        }
    }
    
  }
  
  const handleSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }
    setNotification({isOpen:false, message:'', type:''});
    if(shouldRedirectToListingPage){
      setShouldRedirectToListingPage(false);
      window.parent.postMessage(`${listingPageURL}`, process.env.REACT_APP_BASE_URL)
    }
  };
 
  const handleDialogClose = (val) => {
    if (val === true) {
      if(loggedInUser.role == PAA && formAccess == probationFormAccess.FILL){
        handleInsertFormDataAfterConfirmation();
      } else {
        handleUpdateFormDataAfterConfirmation(dialog.actionName);
      }
      setIsFormActionTaken(true)
    }
    setDialog({...dialog, isOpen: false});
  }

  const validateInputTarget = (inputName, value) => {
    const parsedVal = parseFloat(value)
    let error = false
    let msg = null
    if(isNaN(parsedVal)) {
      msg = `Please enter valid ${inputName}.`
      error = true
    } else if (parsedVal < 0) {
      msg = `Minimum limit for ${inputName} is 0.`
      error = true
    } else if (parsedVal > NUMERIC_INPUT_MAX_LIMIT) {
      msg = `Maximum limit for ${inputName} is ${FORMATTED_NUMERIC_INPUT_MAX_LIMIT}.`
      error = true
    }
    return {error, message: msg}
  }

  const validatePostData = (actionType) => {
    const {
      activitiesTarget,
      activitiesAchievement,
      salesTarget,
      salesAchievement,
      probationAction,
      overallRemarks,
      areaToFocusOn,
      sendBackRemarks
    } = postData;
    let message = '';
    let error = false;
    const activitiesTargetRes = validateInputTarget('Activities target', activitiesTarget)
    const activitiesAchievementRes = validateInputTarget('Activities number achievement', activitiesAchievement)
    const salesTargetRes = validateInputTarget('Sales target', salesTarget)
    const salesAchievementRes = validateInputTarget('Sales achievement', salesAchievement)
    if(activitiesTargetRes && activitiesTargetRes.error) {
      message = activitiesTargetRes.message
      error = true;
    } else if(activitiesAchievementRes && activitiesAchievementRes.error) {
      message = activitiesAchievementRes.message
      error = true;
    } else if(salesTargetRes && salesTargetRes.error) {
      message = salesTargetRes.message
      error = true;
    } else if(salesAchievementRes && salesAchievementRes.error) {
      message = salesAchievementRes.message
      error = true;
    } else if(!probationAction) {
      message = 'Please select Probation Action.';
      error = true;
    } else if(isOverallRemarksVisible && actionType == "approve" && (!overallRemarks || overallRemarks.trim() == '')) {
      message = 'Please add Overall Remarks.';
      error = true;
    } else if(actionType == "sentBack" && (!sendBackRemarks || sendBackRemarks.trim() == '')) {
      message = 'Please add Send back Remarks before proceeding.';
      error = true;
    }
    return {message, error}
  }

  const handleInsertFormDataAfterConfirmation = async () => {
    try {
      setLoading(true);
      const dataObj = {
        employeeno: postData.empNo,
        probation_action: postData.probationAction,
        activitiesTarget: parseFloat(postData.activitiesTarget).toFixed(2),
        activitiesAchievement: parseFloat(postData.activitiesAchievement).toFixed(2),
        salesTarget: parseFloat(postData.salesTarget).toFixed(2),
        salesAchievement: parseFloat(postData.salesAchievement).toFixed(2),
        overall_remarks: postData.overallRemarks.trim(),
        area_to_focus_on: postData.areaToFocusOn.trim(),
      }
      const resp =  await postProbationFormData(dataObj);
      if(resp && resp.success) {
        setNotification({ isOpen: true, type : 'success', message: 'Probation form submitted successfully'});
        setLoading(false);
      } else {
        setNotification({ isOpen: true, type : 'error', message: 'Unable to submit form data'});
        setLoading(false);
      }
      setShouldRedirectToListingPage(true);
    } catch(error) {
      setLoading(false);
      setNotification({ isOpen: true, type : 'error', message: 'Some error occurred while submitted form data'});
      setShouldRedirectToListingPage(true);
    }
  }

  const handleFormAction =  (actionName) => {
    const validationResponse = validatePostData(actionName)
    if(validationResponse.error) {
      setNotification({ isOpen: true, type : 'error', message: (validationResponse && validationResponse.message)})
    } else {
      let dialogText = '';
      const isEmploymentConfirmed = postData.probationAction == probationConstant.probationActionInForm.CONFIRMED;
      if(loggedInUser.role == PAA) {
        dialogText = isEmploymentConfirmed ?
          'Are you sure you want to confirm the employee under probation?' :
          `Are you sure you want to terminate the employee's agreement under probation?`
        setDialog({isOpen: true, contentText: dialogText, actionName: 'approve'});
      } else if([RHR, HROPS_L1, HROPS_L2, HROPS_L3].includes(loggedInUser.role)) {
        dialogText = actionName == 'sentBack' ? 
          `Are you sure you want to send back the form?` :
          (isEmploymentConfirmed ? 
          `Are you sure you want to confirm the employee under probation?` :
          `Are you sure you want to terminate the employee's agreement under probation?`)
        setDialog({isOpen: true, contentText: dialogText, actionName});
      }
      
    } 
  }

  useEffect(() => {
    setLoading(true);
    const fetchData =  async () => {
      try {
        let listingPageURL = null
        const {empNo, id, tab, report} = qs.parse(props.location.search, { ignoreQueryPrefix: true });
        let updatedFormData = empData
        let updatedPostData = postData;
        let formAccessType = null
        let tooltipTextObj = inputToolTipTextObj;
        let sendBackTabIndex = 0;
        let overallRemarksCharCount = 0
        let areaToFocusOnCharCount = 0
        let isSendBackRemarksInputBoxVisible = false
        let isOverallRemarksVisible = false
        updatedFormData.empNo= atob(empNo);
        updatedPostData.empNo = atob(empNo);
        let isFormFilled = false;
        if(!isEmpty(id)) {
          const parsedId = atob(id)
          updatedFormData.formId= parsedId;
          updatedPostData.formId = parsedId;
          isFormFilled = true;
        }
        let loggedInUser ={};
        let isFormInputReadOnly = true;
        let isFormOpenedInReport = !isEmpty(report)
        const userData = await getLoggedInUserDetailsInProbation();
        if(userData && userData.empNo) {
          loggedInUser = {empNo: userData.empNo, role: userData.userRole, userGroup: userData.userGroupName}
          if(loggedInUser.role == PAA) {
            sendBackTabIndex = (tab && !isNaN(parseInt(tab, 10))) ? parseInt(tab, 10) : 0;
          } 
          const details = await getProbationFormDetails(updatedFormData.empNo);
          if(details && details.length){
            const detail = details[0]
            updatedFormData = {...updatedFormData, ...detail};
            updatedPostData.status = detail.probationFormStatus;
            if(isFormFilled) {
              if(Object.keys(detail.targetDetail).length) {
                const {target, achievement} = detail.targetDetail[probationConstant.probationTargetType.ACTIVITIES]
                updatedPostData.activitiesTarget = target;
                updatedPostData.activitiesAchievement = achievement;
                updatedPostData.activitiesAchievementPercentage = calculatePercentage(target, achievement)
      
                const {target: salesTarget, achievement: salesAchievement} = detail.targetDetail[probationConstant.probationTargetType.SALES_TARGET]
                updatedPostData.salesTarget = salesTarget;
                updatedPostData.salesAchievement = salesAchievement;
                updatedPostData.salesAchievementPercentage = calculatePercentage(salesTarget, salesAchievement)
              }
              updatedPostData.probationAction = detail.probationAction;
              updatedPostData.overallRemarks = '' // reset previous remarks
              overallRemarksCharCount = updatedPostData.overallRemarks && updatedPostData.overallRemarks.length
              updatedPostData.areaToFocusOn = detail.areaToFocusOn
              areaToFocusOnCharCount = detail.areaToFocusOn && detail.areaToFocusOn.length
              updatedFormData.sendBackRemarks = null // reset previous sendback remarks
            }
            formAccessType = getProbationFormAccessByUserRole(loggedInUser.role, updatedFormData.probationFormStatus, isFormOpenedInReport);
            if(formAccessType) {
              isFormInputReadOnly = [probationFormAccess.VIEW, probationFormAccess.VERIFY].includes(formAccessType)
              isOverallRemarksVisible = [
                probationFormAccess.FILL,
                probationFormAccess.REVIEW,
                probationFormAccess.VERIFY_AND_EDIT
              ].includes(formAccessType) 
            }

            if([RHR, HROPS_L1, HROPS_L2, HROPS_L3].includes(loggedInUser.role) &&
              [probationFormAccess.REVIEW, probationFormAccess.VERIFY, probationFormAccess.VERIFY_AND_EDIT].includes(formAccessType)
            ) {
              isSendBackRemarksInputBoxVisible = true
            }

            if(probationConstant.porbationFormTypeBasedOnGrade.type2.includes(detail.grade)) {
              tooltipTextObj = {
                activitiesTarget: 'Target number of activities / person in team',
                activitiesAchievement: 'Actual number of activities / person in team',
                activitiesAchievementPercentage: 'Actual Number / Target',
                salesTarget: 'Sales Target Number',
                salesAchievement: 'Sales Actual Number',
                salesAchievementPercentage: 'Actual Number / Target',
              }
            }
          }
          const {type , role} = props && props.computedMatch && props.computedMatch.params
          if(isFormOpenedInReport) {
            listingPageURL = `${process.env.REACT_APP_BASE_URL}/reports/probation/over-all?report=1`
          } else {
            if(loggedInUser.role == HROPS_L3) {
              listingPageURL = `${process.env.REACT_APP_BASE_URL}/probation/index/${type}/r/${role}`
            } else {
              listingPageURL = `${process.env.REACT_APP_BASE_URL}/probation/index/${type}/r/${role}?tab=${sendBackTabIndex}`
            }
            
          }
        }
        setloggedInUser(loggedInUser);
        setEmpData(updatedFormData);
        setPostData(updatedPostData);
        setFormAccess(formAccessType);
        setIsInputReadOnly(isFormInputReadOnly);
        setInputToolTipTextObj(tooltipTextObj);
        setSendBackTabIndex(sendBackTabIndex);
        setIsSendBackRemarksInputBoxVisible(isSendBackRemarksInputBoxVisible)
        setTextBoxCharCount({
          overallRemarks: overallRemarksCharCount,
          areaToFocusOn: areaToFocusOnCharCount
        })
        setIsOverallRemarksVisible(isOverallRemarksVisible)
        setListingPageURL(listingPageURL)
        setLoading(false);
      } catch(err) {
        setLoading(false);
      } 
    }
    fetchData();
  }, []);

  const handleUpdateFormDataAfterConfirmation = async (action) => {
    const isSentBackAction = (action == 'sentBack');
    try {
      setLoading(true);
      const sendBackRemarks = isSentBackAction ? postData.sendBackRemarks : null
      let obj = {}
      if(formAccess == probationFormAccess.REVIEW || formAccess == probationFormAccess.VERIFY_AND_EDIT) {
        obj = {
          id: postData.formId,
          activitiesTarget: parseFloat(postData.activitiesTarget).toFixed(2),
          activitiesAchievement: parseFloat(postData.activitiesAchievement).toFixed(2),
          salesTarget: parseFloat(postData.salesTarget).toFixed(2),
          salesAchievement: parseFloat(postData.salesAchievement).toFixed(2),
          probation_action: postData.probationAction,
          overall_remarks: postData.overallRemarks.trim(),
          area_to_focus_on: postData.areaToFocusOn.trim(),
          action,
        }
      } else {
        obj = {
          id: postData.formId,
          action,
        }
      }

      if(sendBackRemarks && sendBackRemarks) {
        obj = {...obj, send_back_remarks: sendBackRemarks}
      }
      const resp = await updateProbationFormData(obj);
      if(resp.success == true) {
        setNotification({
          isOpen: true ,
          message: `Probation form ${isSentBackAction ? 'sent back' : 'approved'} successfully`,
          type: 'success'
        });
        setLoading(false);
      } else {
        setLoading(false);
        setNotification({
          isOpen: true ,
          message: `Unable to ${isSentBackAction ? 'send back' : 'approve'} the form`,
          type: 'error'
        });
      }
      setShouldRedirectToListingPage(true);
    } catch(error) {
      setLoading(false);
      setNotification({
        isOpen: true,
        type : 'error',
        message: `Some error occurred in ${isSentBackAction ? 'sending back' : 'approving'} the form`
      });
      setShouldRedirectToListingPage(true);
    }
  }

  const handleBack = () => {
    window.parent.postMessage(`${listingPageURL}`, process.env.REACT_APP_BASE_URL)
  }

  return (
      <Paper>
          <Grid container spacing={2}>
            <Grid item> 
              <HeaderLayout
                  title='Probation Evaluation Form'
                  subTitle=''
                  icon={<PeopleOutlineTwoTone/>}
              />
            </Grid>
          </Grid>
          <Paper elevation={2} >
            <Grid container spacing={2}>
              {!formAccess  ?
                  <Grid item xs={12} style={{width: '90%'}}>
                    <Typography variant="h6" align="center" style={{color: 'red', textAlign: 'center'}}>
                      You are not authorized to view this page
                    </Typography>
                  </Grid> 
                :
                  <form className={classes.formRoot}>
                    <Grid container className={classes.gridContainer} >
                      <Grid item xs={12}>
                        <Typography variant="subtitle1" style={{color:'red'}} >“Please note that the fields containing asterisk(*) are mandatory”</Typography>
                      </Grid>
                    </Grid>
                    <Grid container className={classes.gridContainer} >
                      <Grid item xs={3}>
                        <Typography variant="subtitle1" className={classes.label}>Employee Name</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="subtitle1" className={classes.value} >{empData && empData.legalname}</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="subtitle1" className={classes.label}>Employee Code</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="subtitle1" className={classes.value} >{empData && empData.employeeno}</Typography>
                      </Grid>
                    </Grid>
                    <Grid container className={classes.gridContainer}>
                      <Grid item xs={3}> 
                        <Typography variant="subtitle1" className={classes.label}>Designation</Typography>
                      </Grid>
                      <Grid item xs={3} >
                        <Typography variant="subtitle1" className={classes.value} >{empData && empData.designation}</Typography>
                      </Grid>
                      <Grid item xs={3} >
                        <Typography variant="subtitle1" className={classes.label}>Grade</Typography>
                      </Grid>
                      <Grid item xs={3} >
                        <Typography variant="subtitle1" className={classes.value} >{empData && empData.grade}</Typography>
                      </Grid>
                    </Grid>
                    <Grid container className={classes.gridContainer}>
                      <Grid item xs={3}> 
                        <Typography variant="subtitle1" className={classes.label}>Department</Typography>
                      </Grid>
                      <Grid item xs={3} >
                        <Typography variant="subtitle1" className={classes.value} >{empData && empData.department}</Typography>
                      </Grid>
                      <Grid item xs={3} >
                        <Typography variant="subtitle1" className={classes.label}>Sub Department</Typography>
                      </Grid>
                      <Grid item xs={3} >
                        <Typography variant="subtitle1" className={classes.value} >{empData && empData.subDepartment}</Typography>
                      </Grid>
                    </Grid>
                    <Grid container className={classes.gridContainer}>
                      <Grid item xs={3}> 
                        <Typography variant="subtitle1" className={classes.label}>Employee Reporting Manager</Typography>
                      </Grid>
                      <Grid item xs={3} >
                        <Typography variant="subtitle1" className={classes.value} >{empData && empData.reportingManagerName}</Typography>
                      </Grid>
                      <Grid item xs={3} >
                        <Typography variant="subtitle1" className={classes.label}>Probation Due Date</Typography> 
                      </Grid>
                      <Grid item xs={3} >
                        <Typography variant="subtitle1" className={classes.value} >{empData &&  moment(empData.probationDueDate).format('DD-MMM-YYYY')}</Typography>
                      </Grid>
                    </Grid>
                    <Grid container className={classes.gridInputContainer} spacing={3}>
                      <Grid item xs={4}>
                        <Tooltip 
                          placement="top"
                          title={inputToolTipTextObj.activitiesTarget}
                        >
                          <TextField
                            className={classes.customTextBox}
                            required
                            variant="outlined"
                            label="Activities Target"
                            placeholder={inputToolTipTextObj.activitiesTarget}
                            name='activitiesTarget'
                            value={postData.activitiesTarget}
                            onChange={(event) => handleInputChange(event, 'activitiesTarget')}
                            disabled={isInputReadOnly}
                            style={{ width:'100%' }}
                          />
                        </Tooltip>
                      </Grid>
                      <Grid item xs={4}>
                        <Tooltip 
                          placement="top"
                          title={inputToolTipTextObj.activitiesAchievement}
                        >
                          <TextField
                            className={classes.customTextBox}
                            required
                            variant="outlined"
                            label="Activities Number Achievement"
                            placeholder={inputToolTipTextObj.activitiesAchievement}
                            name='activitiesAchievement'
                            value={postData.activitiesAchievement}
                            onChange={(event) => handleInputChange(event, 'activitiesAchievement')}
                            disabled={isInputReadOnly}
                            style={{ width:'100%' }}
                          />
                        </Tooltip>
                      </Grid>
                      <Grid item xs={4}>
                       <Tooltip 
                          placement="top"
                          title={inputToolTipTextObj.activitiesAchievementPercentage}
                        >
                          <TextField
                            disabled={true} 
                            variant="outlined"
                            aria-readonly
                            label="Achievement % age"
                            name='activitiesAchievementPercentage'
                            value={postData.activitiesAchievementPercentage}
                            onChange={(event) => handleInputChange(event, 'activitiesAchievementPercentage')}
                          />
                        </Tooltip>
                      </Grid>
                    </Grid>
                    <Grid container className={classes.gridInputContainer} spacing={3}>
                      <Grid item xs={4}>
                        <Tooltip 
                          placement="top"
                          title={inputToolTipTextObj.salesTarget}
                        >
                          <TextField
                            className={classes.customTextBox}
                            required 
                            variant="outlined"
                            label="Sales Target"
                            placeholder={inputToolTipTextObj.salesTarget}
                            name='salesTarget'
                            value={postData.salesTarget}
                            onChange={(event) => handleInputChange(event, 'salesTarget')}
                            disabled={isInputReadOnly}
                            style={{ width:'100%' }}

                          />
                        </Tooltip>
                      </Grid>
                      <Grid item xs={4}>
                        <Tooltip 
                          placement="top"
                          title={inputToolTipTextObj.salesAchievement}
                        >
                        <TextField
                            className={classes.customTextBox}                         
                          required
                          variant="outlined"
                          label="Sales Achievement"
                          placeholder={inputToolTipTextObj.salesAchievement}
                          name='salesAchievement'
                          value={postData.salesAchievement}
                          onChange={(event) => handleInputChange(event, 'salesAchievement')}
                          disabled={isInputReadOnly}
                          style={{ width:'100%' }}
                        />
                        </Tooltip>
                      </Grid>
                      <Grid item xs={4} style={{ width:'100%' }} >
                        <Tooltip 
                          placement="top"
                          title={inputToolTipTextObj.salesAchievementPercentage}
                        >
                          <TextField
                            className={classes.customTextBox}
                            disabled={true} 
                            variant="outlined"
                            label="Achievement % age"
                            name='salesAchievementPercentage'
                            value={postData.salesAchievementPercentage}
                            onChange={(event) => handleInputChange(event, 'salesAchievementPercentage')}
                          />
                        </Tooltip>
                      </Grid>
                    </Grid>
                    <Grid container className={classes.gridInputContainer} style={{ padding: '5px'}}>
                      <Grid item>
                        <FormControl required>
                          <FormLabel id="probation-action">Probation Action</FormLabel>
                          <RadioGroup
                            row
                            name="probation-action"
                            value={postData.probationAction}
                            onChange={(event) => handleInputChange(event, 'probationAction')}
                          >
                            <FormControlLabel value="confirmed" control={<Radio disabled={isInputReadOnly} />} label="Employment Confirmation" />
                            <FormControlLabel value="terminated" control={<Radio disabled={isInputReadOnly} />} label="Employment Termination" />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container className={classes.gridInputContainer} spacing={3} >
                      {isOverallRemarksVisible &&
                        <Grid item xs={5}>
                          <TextField
                            required
                            disabled={isInputReadOnly}
                            className={classes.textArea}  
                            variant="outlined"
                            label="Overall Remarks"
                            name='overallRemarks'
                            value={postData.overallRemarks}
                            onChange={(event) => handleInputChange(event, 'overallRemarks')}
                            multiline={true}
                            minRows={5}
                            maxRows={5}
                            inputProps={{ maxLength: REMARKS_INPUT_CHAR_LIMIT, width: '50px'}}
                          />
                          {!isInputReadOnly && 
                            <div className={classes.charCount}>
                              {textBoxCharCount.overallRemarks}&nbsp;/&nbsp;{REMARKS_INPUT_CHAR_LIMIT}
                            </div>
                          }
                          {postData.activitiesAchievementPercentage >= 100 && postData.salesAchievementPercentage >= 100 && postData.probationAction == "terminated" &&
                            <div style={{color:'red'}}>
                              “Please mention the reason of termination in the Overall Remarks as the achievement is 100% or higher”
                            </div>
                          }
                        </Grid>
                      }
                      <Grid item xs={5}>
                        <TextField
                          disabled={isInputReadOnly}
                          className={classes.textArea} 
                          variant="outlined"
                          label="Areas to focus on"
                          name='areaToFocusOn'
                          value={postData.areaToFocusOn}
                          onChange={(event) => handleInputChange(event, 'areaToFocusOn')}
                          multiline={true}
                          minRows={5}
                          maxRows={5}
                          inputProps={{ maxLength: REMARKS_INPUT_CHAR_LIMIT}}
                        />
                        {!isInputReadOnly && 
                          <div className={classes.charCount}>
                            {textBoxCharCount.areaToFocusOn}&nbsp;/&nbsp;{REMARKS_INPUT_CHAR_LIMIT}
                          </div>
                        }
                      </Grid>
                    </Grid>
                    {(empData.sendBackRemarksHistory && empData.sendBackRemarksHistory.length > 0) ? 
                      <Grid container className={classes.gridInputContainer} spacing={3} style={{
                        width: "90%",
                        marginTop: 37,
                        marginLeft: 0,
                        height: 180,
                        overflow: 'scroll',
                        border: "1px solid rgba(0, 0, 0, 0.25)",
                        borderRadius: '.5',
                      }}
                      > 
                        <List style={{ width: "100%", paddingTop: 0}} className={classes.history}>
                          <ListSubheader style={{ lineHeight: '25px', color: 'white', backgroundColor: '#757ce8'}}>
                            Remarks History
                          </ListSubheader>
                        {empData.sendBackRemarksHistory.map((element, key) => (
                            <React.Fragment key={key}>
                              <ListItem key={key}>
                                <ListItemText
                                  style={{ width: "100%" }}
                                  primary={
                                    <React.Fragment>
                                      <Typography
                                        style={{
                                          fontWeight: 'bold',
                                          width: "70%",
                                          display: "inline",
                                          float: "left",
                                          fontSize: "12px",
                                        }}
                                        component="span"
                                        variant="subtitle2"
                                      >
                                        {element.added_by ? element.added_by : ''}
                                      </Typography>
                                      <Typography
                                        style={{
                                          width: "30%",
                                          display: "inline",
                                          fontSize: "10px",
                                          float: "right",
                                          textAlign: "right",
                                          color: "gray",
                                        }}
                                        component="span"
                                        variant="caption"
                                      >
                                        {element.added_at}
                                      </Typography>
                                      <Typography
                                        style={{
                                          display: "block",
                                          width: "100%",
                                          fontSize: "10px",
                                          float:'left',
                                          color: "gray",
                                          marginBottom: 5
                                        }}
                                        component="div"
                                        variant="caption"
                                      >
                                        {element.userRole ? element.userRole : ''}
                                      </Typography>
                                      <Typography
                                        style={{
                                          marginLeft: 15,
                                          display: "block",
                                          fontSize: "13px",
                                          maxHeight: 120,
                                          color: 'gray',
                                        }}
                                        component="div"
                                        variant="body2"
                                      >
                                        {element.remarks}
                                      </Typography>
                                      <Typography
                                        style={{
                                          display: "block",
                                          padding: '0 3px 0 3px',
                                          fontSize: "10px",
                                          float:'right',
                                          color: "black",
                                          fontWeight: 'bold',
                                          backgroundColor: element.action == "approve" ? "#99EDC3": "yellow",
                                        }}
                                        component="div"
                                        variant="caption"
                                      >
                                        {element.action == "approve" ? "Approval Remarks" : "Sendback Remarks"}
                                      </Typography>
                                    </React.Fragment>
                                  }
                                />
                              </ListItem> 
                              <Divider component="li" />
                            </React.Fragment>
                        ))}
                        </List>
                      </Grid>
                        : null
                    }
                    {isSendBackRemarksInputBoxVisible && 
                        <Grid container className={classes.gridInputContainer} spacing={3} >
                          <Grid item style={{ width: "90%", marginTop: 12 }}>
                            <TextField
                              className={classes.customTextBox}
                              variant="outlined"
                              label="Send back remarks"
                              placeholder="Add your send back remarks..."
                              name='sendBackRemarks'
                              value={postData.sendBackRemarks}
                              onChange={(event) => handleInputChange(event, 'sendBackRemarks')}
                              multiline={true}
                              minRows={3}
                              maxRows={3}
                              inputProps={{ maxLength: REMARKS_INPUT_CHAR_LIMIT, width: '50px'}}
                              style={{ width:'100%' }}
                            />
                            <div className={classes.charCount}>
                              {textBoxCharCount.sendBackRemarks}&nbsp;/&nbsp;{REMARKS_INPUT_CHAR_LIMIT}
                            </div>
                          </Grid>
                        </Grid> 
                      }   
                      {formAccess == probationConstant.probationFormAccess.FILL &&
                        <Grid container className={classes.gridInputContainer} spacing={2}>
                          <Grid item style={{ paddingRight: 31 }}>
                            <Button
                                variant='contained'
                                size='large'
                                color='primary'
                                onClick={() => handleFormAction('approve')}
                                disabled={loading || isFormActionTaken}
                                className={classes.button}
                            >
                                Submit
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button 
                              variant="outlined"
                              size="large"
                              className={classes.button}
                              onClick={handleBack}
                              disabled={loading}
                              color="primary"
                            >
                              Back
                            </Button>
                          </Grid>
                        </Grid> 
                      }
                      {([probationConstant.probationFormAccess.REVIEW, probationConstant.probationFormAccess.VERIFY, probationConstant.probationFormAccess.VERIFY_AND_EDIT].includes(formAccess)) &&
                        <Grid container className={classes.gridInputContainer} spacing={2}>
                          {loggedInUser.role != PAA &&
                            <Grid item style={{ paddingRight: 31 }}>
                              <Button
                                  variant='contained'
                                  size='large'
                                  color='primary'
                                  onClick={() => handleFormAction('sentBack')}
                                  disabled={loading || isFormActionTaken}
                                  className={classes.button}
                              >
                                Send Back for review
                              </Button>
                            </Grid>
                          }
                          {loggedInUser.role != HROPS_L3 && 
                            <Grid item style={{ paddingRight: 31 }}>
                              <Button
                                  variant='contained'
                                  size='large'
                                  color='primary'
                                  onClick={() => handleFormAction('approve')}
                                  disabled={loading || isFormActionTaken}
                                  className={classes.button}
                              >
                                  {(loggedInUser.role == PAA && formAccess == probationConstant.probationFormAccess.REVIEW) ? 'Review & Submit' : 'Approve'}
                              </Button>
                            </Grid>
                          } 
                          <Grid item>
                            <Button 
                              variant="outlined"
                              size="large"
                              className={classes.button}
                              onClick={handleBack}
                              disabled={loading}
                              color="primary"
                            >
                              Back
                            </Button>
                          </Grid>
                        </Grid>
                      }
                      {(formAccess == probationConstant.probationFormAccess.VIEW) &&
                        <Grid container className={classes.gridInputContainer} spacing={2}>
                          <Grid item>
                            <Button
                              target="_blank"
                              variant="outlined"
                              size="large"
                              className={classes.button}
                              onClick={handleBack}
                              disabled={loading}
                              color="primary"
                            >
                              Back
                            </Button>
                          </Grid>
                        </Grid>
                      }
                      <Grid container spacing={2}>
                        {loading && 
                          <Grid item style={{position: 'relative', bottom: 115, left: 400}}>
                            <CircularProgress/>
                          </Grid>
                        }
                        {
                          notification.isOpen ? (
                              <SnackBarComponent
                                className={classes.notification}
                                open={notification.isOpen}
                                severity={notification.type}
                                message={notification.message}
                                handleSnackBarClose={handleSnackBarClose}
                                autoHideDuration={5000}
                                style={{position: 'relative', bottom: '100px'}}
                              />
                          ) : (null)
                        }
                        <Grid item>
                          <DialogComponent
                              openDialog={dialog.isOpen}
                              dialogTitle="Confirm"
                              dialogContentText={dialog.contentText}
                              agreeButtonText="Yes"
                              disagreeButtonText="No"
                              handleDialogClose={handleDialogClose}
                              showAgreeButtonAtFirst={true}
                          />
                        </Grid>
                      </Grid>
                  </form>
              }
              
            </Grid>
          </Paper>
      </Paper>
  )
}
