import { makeStyles } from '@material-ui/core/styles'
import { green } from '@material-ui/core/colors'
export const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    btnAction: {
      float: 'right',
      marginRight: '15%',
      padding: '28px'
    },
    buttonWelcome: {
      marginRight: theme.spacing(-18),
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '40%',
      marginTop: -12
    },
    btnLoading: {
      position: 'relative'
    }
}));