import { makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiCardHeader-title': {
            fontSize: '1rem'
        },
        '& .MuiTableCell-root': {
          padding: '10px'
        },
        padding: '5px'
    },
    filterDiv: {
      cursor: 'pointer'
    },
    loader: {
        display: 'flex',
        justifyContent: 'center'
    },
   
    formRoot: {
      padding: theme.spacing(2)
    },

    formControl: {
      margin: theme.spacing(1),
      width: '100%'
    },
    headerCell:{
      fontWeight: 'bold',
      textAlign: 'center',
    },
    headerBodyCell: {
      textAlign: 'center',
    },
    marginTop: {
      marginTop: 10
    },
    resetButton: {
      marginLeft: 20,
      background: 'white !important',
      textTransform: 'none'
    },
}))
