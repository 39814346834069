import moment from 'moment'
import scrollIntoView from 'scroll-into-view'

const timeRangeObj = {
  "0-12 hrs" : 1,
  "+12-24 hrs" : 2,
  "+24-36 hrs" : 3,
  "+36 hrs" : 4
}

export const commonConst = {
    'genderList': [
        'Male',
        'Female'
    ],
    'nameWithSpaceRegex': /^[a-zA-Z][a-zA-Z ]{0,100}$/,
    'panRegEx': /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
    'adharRegEx': /^[2-9]{1}[0-9]{11}$/,
    'pinCodeRegex': /^[1-9][0-9]{4,5}$/,
    'mobileRegEx': /^[+]{0,1}[1-9][0-9]{9,18}$/,
    'landlineRegEx': /^[+]{0,1}[1-9][0-9]{9,18}$/,
    'addressRegEx': /^[a-zA-Z0-9 \-,'/&()#.]*$/,
    'pinCodeRegEx': /^[1-9][0-9]{5}$/,
    'nameRegex': /^[a-zA-Z ]{2,100}$/,
    'bankNameRegex': /^[a-zA-Z \-&]{2,100}$/,
    'bankAccountNumberRegEx': /^[0-9]{9,18}$/,
    'bankIfscCodeRegEx': /^[A-Z]{4}0[A-Z0-9]{6}$/,
    'emergencyContactRelationshipCodeRegEx': /^[a-zA-Z -]{2,100}$/,
    'pfRegEx': /^[a-zA-Z0-9/-]{5,18}$/,
    'uanRegEx': /^[0-9]{12}$/,
    'passportNumberRegEx': /^[a-zA-Z0-9]{7,15}$/,
    'minShareValue': 1,
    'maxShareValue': 100,
    'instituteNameRegex': /^[a-zA-Z0-9 -.]{3,100}$/,
    'companyNameNameRegex': /^[a-zA-Z0-9 -.]{3,100}$/,
    'designationRegEx': /^[a-zA-Z0-9 -.&]{2,100}$/,
    'sharePercentRegEx': /^[1-9]{1}[0-9]{0,2}$/,
    'marksRegEx': /^[A-Za-z1-9][0-9+-]{0,}(\.\d{1,2})?$/,
    'emailRegEx': /^[a-zA-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,
    'numberWithDecimalRegEx': /^\d+(\.\d{1,2})?$/,
    'amountRegEx': /^[1-9][0-9]{0,}(\.\d{1,2})?$/,
    'experienceRegEx': /^[1-9]{1}[0-9]{0,1}$/,
}

export const scrollToViewHandler = tableId => {
    if (tableId) {
        setTimeout(() => {
        let el = document.getElementById(tableId)
        if (el) {
            scrollIntoView(el)
        }
        }, 500)
    }
}

export const listingSort = (array, comparator) => {
  const compareArray = array.map((el, index) => [el, index])
  compareArray.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }

    return a[1] - b[1]
  })
  return compareArray.map(e1 => e1[0])
}

export const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function descendingComparator (a, b, orderBy) {
  if (['doj', 'group_doj', 'captured_date', 'tabp_record_date', 'tabp_record_timestamp', 'captured_timestamp'].includes(orderBy)) {
    if (b[orderBy] && a[orderBy]) {
      const aMoment = moment(a[orderBy])
      const bMoment = moment(b[orderBy])
      console.log(a[orderBy],aMoment, `aMoment`, b[orderBy], bMoment)
      if (bMoment.isAfter(aMoment)) {
        return 1
      } else if (bMoment.isBefore(aMoment)) {
        return -1
      } else  {
        return 0
      }
    } else if (!b[orderBy] && a[orderBy]) {
      return -1
    } else if (!a[orderBy] && b[orderBy]) {
      return 1
    } else if (!a[orderBy] && !b[orderBy]) {
      return 0
    }
  } else if (['tabp_record_time', 'captured_time'].includes(orderBy)) {
      if (b[orderBy] && a[orderBy]) {
        const aMoment = moment(`2024-01-01 ${a[orderBy]}`)
        const bMoment = moment(`2024-01-01 ${b[orderBy]}`)
        if (bMoment.isAfter(aMoment)) {
          return 1
        } else if (bMoment.isBefore(aMoment)) {
          return -1
        } else  {
          return 0
        }
      } else if (!b[orderBy] && a[orderBy]) {
        return -1
      } else if (!a[orderBy] && b[orderBy]) {
        return 1
      } else if (!a[orderBy] && !b[orderBy]) {
        return 0
      }
  } else  {
    let stringOne = a[orderBy] || ""
    let stringTwo = b[orderBy] || ""

    if (['grade','grade_name'].includes(orderBy)) {
      stringOne = parseInt(stringOne, 10)
      stringTwo = parseInt(stringTwo, 10)

      stringOne = isNaN(stringOne) ? 0 : stringOne
      stringTwo = isNaN(stringTwo) ? 0 : stringTwo
    } else if (['pending_days'].includes(orderBy)) {
      stringOne = parseFloat(a[orderBy], 10)
      stringTwo = parseFloat(b[orderBy], 10)
    } else if (orderBy === "time_range") {
      stringOne = timeRangeObj[stringOne] || 0
      stringTwo = timeRangeObj[stringTwo] || 0
    } else if (orderBy === "tat_format") {
      stringOne = stringOne ? parseInt(`${stringOne}`.replace(":", "")) : 0
      stringTwo = stringTwo ? parseInt(`${stringTwo}`.replace(":", "")) : 0
    } else if (['24', '25', '26', '27', '28', '29', '30', '31', '32', '44', '45', '46', '47', '48', '49', '36', '37', '38', '39', '50', '51', 'female_percent', 'percentOfCardExchanged', 'percentOfUniqueSender', 'percentOfMangerToDRSender', 'percentOfUniqueMangerToDRSender', 'casePercentWithin36', 'overall_avg'].includes(`${orderBy}`)) {
      stringOne = parseFloat(stringOne)
      stringTwo = parseFloat(stringTwo)
    } else if (orderBy === "timeFrame") {
      stringOne = Number(a["timeFrameOrder"]) || ""
      stringTwo = Number(b["timeFrameOrder"]) || ""
    } else {
      stringOne = typeof stringOne === "string" ? stringOne.toLowerCase() : stringOne
      stringTwo = typeof stringTwo === "string" ? stringTwo.toLowerCase() : stringTwo
    }

    if (stringTwo < stringOne) {
      return -1
    }
    if (stringTwo > stringOne) {
      return 1
    }
    return 0
  }

  return 0
}

export const SUPERADMIN = 1
export const EMPLOYEE_USER_ROLE = 2
export const MANAGER_USER_ROLE = 3
export const menuSubmenuPageCategory = {
  NON_ADMIN_CATEGORY: "Employee Details", // For Employee & Manager rights
  ADMIN_CATEGORY: "Manage Employee"
}
export const pageURLConfig = {
  EFILING_INDEX_DOCUMENT_MASTER_HRBP : { moduleName: 'efiling', controllerName: 'index', actionName: 'document-master-hrbp'},
  EFILING_INDEX_BULK_UPLOAD : { moduleName: 'efiling', controllerName: 'index', actionName: 'bulk-upload'},
  EFILING_INDEX_DOCUMENT_APPROVAL : { moduleName: 'efiling', controllerName: 'index', actionName: 'document-approval'},
  EFILING_INDEX_DOCUMENT_MASTER : { moduleName: 'efiling', controllerName: 'index', actionName: 'document-master'},
  EFILING_INDEX_DOCUMENT_MASTER_CONFIG : { moduleName: 'efiling', controllerName: 'index', actionName: 'document-master-config'},
  EFILING_INDEX_DOCUMENT_STATUS_REPORT : { moduleName: 'efiling', controllerName: 'index', actionName: 'document-status-report'},
  EFILING_INDEX_MODULE_MASTER_CONFIG : { moduleName: 'efiling', controllerName: 'index', actionName: 'module-master-config'},
  EFILING_INDEX_MULTIPLE_DOWNLOAD : { moduleName: 'efiling', controllerName: 'index', actionName: 'multiple-download'},
  EFILING_INDEX_DOCUMENT_MODIFY : { moduleName: 'efiling', controllerName: 'index', actionName: 'document-modify'},
  EFILING_INDEX_DOCUMENT_UPLOAD : { moduleName: 'efiling', controllerName: 'index', actionName: 'document-upload'},

  SURVEY_INDEX_SURVEY_DASHBOARD : { moduleName: 'survey', controllerName: 'index', actionName: 'survey-dashboard'},
  SURVEY_INDEX_MANAGE_CRITERIA : { moduleName: 'survey', controllerName: 'index', actionName: 'manage-criteria'},
  SURVEY_INDEX_COLOR_SHADING : { moduleName: 'survey', controllerName: 'index', actionName: 'color-shading'},
  EMPLOYEE_WORKDETAILS_NOMINEE_DETAILS_REDIRECT : { moduleName: 'employee', controllerName: 'workdetails', actionName: 'nominee-details-redirect'},


  PROBATION_INDEX_PENDING_DUE_LIST : { moduleName: 'probation', controllerName: 'index', actionName: 'pending-due-list'},
  PROBATION_INDEX_STATUS_VIEW :{ moduleName: 'probation', controllerName: 'index', actionName: 'status-view'}
}