import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
    pageContent: {
        width: '100%'
    },
    notification: {
        padding: theme.spacing(1),
        margin: theme.spacing(1),
        marginLeft: 0,
        textAlign: 'center',
      },
      input: {
        display: "none"
      },
    filterDiv:{
      cursor: "pointer"
    }
}))