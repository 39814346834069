import React, { useState,useEffect } from 'react';
import { useStyles } from './style';
import OrganizationChart from '../Organization/ChartContainer.js'
import { getEmpDetailsForOrgChart } from '../fetchData';
import { async } from 'regenerator-runtime';
import logo from '../../../utils/load.gif'
import { pageURLConfig, EMPLOYEE_USER_ROLE, menuSubmenuPageCategory } from '../../../constants/CommonConstant';
import { getMenuSubmenuRightsDetail } from '../../../utils/authFunction';
const { NON_ADMIN_CATEGORY } = menuSubmenuPageCategory




export function OrgChart(props) {

    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(true)
    const [employeeHeirarchy, setEmployeeHeirarchy] = useState({})

    const [isHavingPageAddAccess, setIsHavingPageAddAccess] = useState(false)
    const [isHavingPageEditAccess, setIsHavingPageEditAccess] = useState(false)
    const [isHavingPageViewAccess, setIsHavingPageViewAccess] = useState(null)

    useEffect( () => {
      setPageRights()
    },[])

    const setPageRights = async () => {
      const params = { pageCategory: NON_ADMIN_CATEGORY, moduleName:'', controllerName:'', actionName:'', userGroupId:EMPLOYEE_USER_ROLE, urlParam:`${process.env.REACT_APP_HOST_NAME}/orgChart`}
      const rightsObj = await getMenuSubmenuRightsDetail(params)
      console.log(rightsObj)
      const { 
          isUserSuperAdmin,
          isUserGroupAdmin,
          selfEditRights,
          selfAddRights, 
          selfViewRights 
      } = rightsObj
      const addAccess = isUserSuperAdmin ||  (selfAddRights) 
      const editAccess =  isUserSuperAdmin || selfEditRights
      const viewAccess =  isUserSuperAdmin || (selfViewRights)
      
      setIsHavingPageAddAccess(addAccess)
      setIsHavingPageEditAccess(editAccess)
      setIsHavingPageViewAccess(viewAccess)
    }
  
    useEffect(()=> {
      getEmployeeHeirarchy()
    },[])

    const clickBottomEdge = async () => {
      var el = document.getElementById('bottomEdgeE00001')
      if(el){
        el.click()
      }
    }

    const getEmployeeHeirarchy = async () => {
      try {
        setIsLoading(true)
        var response = await getEmpDetailsForOrgChart()
        response.data.map(empDetails => {
          if(empDetails && empDetails.employeeno == 'E00001'){
            empDetails.reporting_manager_emp_id = ''
          }
        })
        var converted = parseEmpToOrgChart(response.data,'E00001')
        setEmployeeHeirarchy(converted)
        setIsLoading(false)
        clickBottomEdge()
      } catch (error) {
        console.log(error)
      }
    }

    function parseEmpToOrgChart(empData, rootId){
      let rootEmp = empData.filter(emp=>{
        if(emp && emp.employeeno === rootId){
         return emp
        }
      })[0]
      let tempObj = {
        id: rootEmp.employeeno,
        name: rootEmp.legalname,
        title: rootEmp.designation,
        department: rootEmp.department,
        image: rootEmp.profile_image,
        sub_department: rootEmp.function,
        location: rootEmp.locality
      }
  
      let children = empData.filter(emp=>{
        if(emp.reporting_manager_emp_id === rootId){
          return emp
        }
      })
      if(children.length>0){
         tempObj['children']= children.map(child => {
          return parseEmpToOrgChart(empData, child.employeeno)
        })
        
        let childCount = 0
        tempObj['children'].map(child => {
        if(child['reporting']) {
            childCount += child['reporting']
        }})
        tempObj['reporting'] = children.length + childCount
  
      }
      return tempObj
  }

    return (
      <React.Fragment>
        {(!isHavingPageViewAccess) 
        ?
            (isHavingPageViewAccess == null) ? <div className={classes.loader}><img src={logo} alt="loading..." /></div> :
                <div> <h1 style={{margin:'0px'}}>Unauthorised </h1> <br></br> <span>You don't have access to view this page</span> </div> 
        : 
        <div className={classes.root}> 
        { ( isLoading ) 
            ? 
            <div className={classes.loader}><img src={logo} alt="loading..." /></div>
            : 
            <div>
            {employeeHeirarchy ? <OrganizationChart className={classes.root} datasource={employeeHeirarchy} /> : ''
            }
            </div> }
        </div>
        }
      </React.Fragment>
    )
  }
  