import Papa from 'papaparse'
import { arrayDiff, isEmpty } from '../../utils/CommonFunction'
import { SURVEY_CONST } from "../../constants/SurveyConstant"


const demoGraphicsMap = {
    tenure: 'Tenure Bucket',
    grade: 'Grade',
    department: 'Department',
    function: 'Sub-Department',
    reporting_manager: 'Reporting Manager',
    reporting_manager_id: 'Reporting Manager ID',
    lteam_member: 'L Team Member',
    branch: 'Branch',
    age_bucket: 'Age Bucket',
    gender: 'Gender',
    location: 'Location',
    rhr_name: 'RHR Name'
}

const criteriaHeader = [
    'employeeno',
    'bucket'
]
export const validateCsvFile = async (file) => {
    try {
        const csvData = await parseFile(file)
        const response = validateCsvData(csvData)
        return response
    } catch (err) {
        throw err
    }
}

export const validateCsvData = (csvData) => {
    let error = []
    let data = []

    if (!csvData.data.length) {
        error.push(`CSV file is empty`)
        return { error, data }
    }

    const diff = arrayDiff({baseArr:criteriaHeader, compArr:Object.keys(csvData.data[0])})
    if (diff.length) {
        error.push(`Invalid file header name(s)`)
        return { error, data }
    }
    
    csvData.data.forEach((element, index) => {
        index+=1
        criteriaHeader.forEach(key => {
            switch (key) {
                case 'employeeno':
                    if (element[key] === '') {
                        error.push(`Invalid employeeno @row ${index+1}`)
                    }
                    break
                default:
                    break
            }
        })
        data.push(element)
    })

    return { error, data }
}

export const parseFile = (file) => {
    return new Promise((resolve, reject) => {
        return Papa.parse(file, {
            header: true,
            skipEmptyLines: 'greedy',
            complete: function(results) {
               return resolve(results)
            },
            error: function(err) {
                return reject(err)
            }
        })
    })
}

export const colorCodingAverageScore = (score, reportType, colorShading) => {
    let color = ''
    score = Number(score).toFixed(1)
    if(colorShading){
        if (['f','mf'].includes(reportType)) {
            if (score >= Number(colorShading.fav_low_from) && score <= Number(colorShading.fav_low_to)){
                color = colorShading.fav_low_color || '#5478F6'
            } else if (score >= Number(colorShading.fav_medium_from) && score <= Number(colorShading.fav_medium_to)){
                color = colorShading.fav_medium_color || '#89AAFF'
            } else if (score >= Number(colorShading.fav_high_from)) {
                color = colorShading.fav_high_color || '#BDD0FF'
            }
        } else {
            if (score >= Number(colorShading.avg_low_from) && score <= Number(colorShading.avg_low_to)){
                color = colorShading.avg_low_color || '#5478F6'
            } else if (score >= Number(colorShading.avg_medium_from) && score <= Number(colorShading.avg_medium_to)){
                color = colorShading.avg_medium_color ||'#89AAFF'
            } else if (score >= Number(colorShading.avg_high_from)) {
                color = colorShading.avg_high_color ||'#BDD0FF'
            }
        }    
    }
    
    return color
}


export const getReportHtmlAverageScore = ({filterObj,reportData, overallStats, driverList}) => {

    let totalColumnsSpan = 4;
    Object.keys(reportData.driverQuestionMapping).forEach(driverName => {
        totalColumnsSpan += reportData.driverQuestionMapping[driverName].length
    })

    let html = '<html><body><table border="1">'

    if(['f','a'].includes(filterObj.reportType)){
        html += `<tr><th style="text-align: center;" colspan="${totalColumnsSpan}">Average Score</th></tr>`
    } else if (['ma'].includes(filterObj.reportType)) {
        html += `<tr><th style="text-align: center;" colspan="${totalColumnsSpan+1}">Manager Scorecard Average Score</th></tr>`
    }


    if(['f','a'].includes(filterObj.reportType)){
        html += `<tr> <td style="text-align: center;" colspan="${filterObj.demographics.length + 3}"></td>`
    } else if (['ma'].includes(filterObj.reportType)) {
        html += `<tr> <td style="text-align: center;" colspan="5"></td>`
    }
    
    Object.keys(reportData.driverQuestionMapping).map(driverName => {
        html += `<td style="text-align: center;" colspan='${reportData.driverQuestionMapping[driverName].length}'>${driverName}</td>`
    })
    html += '</tr>'

    html += `<tr>`

        if(['f','a'].includes(filterObj.reportType)){
            filterObj.demographics.map(ele => {
                html += `<td style="text-align: center;">${ ele == 'custom_criteria' ? filterObj.custom_criteria : SURVEY_CONST.SURVEY_REPORT_COLUMNS[ele]}</td>`
            })
        } else if (['ma','mf'].includes(filterObj.reportType)) {
            html += `<td style="text-align: center;"> Manager Name </td><td style="text-align: center;"> Manager Id </td>`
        }


        html += `<td style="text-align: center;">Participation Rate</td>
		<td style="text-align: center;">Participation Count</td>
		<td style="text-align: center;">Overall Score</td>`
        Object.keys(reportData.driverQuestionMapping).map(driverName => {
            reportData.driverQuestionMapping[driverName].forEach(questionDetails =>{
                html += `<td style="text-align: center;">${questionDetails.title}</td>`
            })
        })
    html +=`</tr>`
    
    html += `<tr>`
         
        if(['f','a'].includes(filterObj.reportType)){
            filterObj.demographics.map((ele,index) => {
                if(index === 0){
                    html += `<td style="text-align: center;">Overall</td>`
                } else {
                    html += `<td style="text-align: center;"></td>`
                }
            })
        } else if (['ma','mf'].includes(filterObj.reportType)) {
            html += `<td style="text-align: center;"> Overall </td><td style="text-align: center;"></td>`
        }   

    html +=  `<td style="text-align: center;"> ${overallStats.totalHeadCount ? ((overallStats.totalResponse/overallStats.totalHeadCount)*100).toFixed(1) + '%' : '-'}</td>
        <td style="text-align: center;"> ${overallStats.totalHeadCount ? overallStats.totalResponse : '-'}</td>
        <td style="text-align: center;"> ${Number(overallStats.totalOverallScore).toFixed(1)}</td>`
        Object.keys(reportData.driverQuestionMapping).map(driverName => {
            reportData.driverQuestionMapping[driverName].forEach(questionDetails =>{
                html += `<td style="text-align: center;">${overallStats[driverName] && overallStats[driverName][questionDetails.title] && overallStats[driverName][questionDetails.title].questionCount &&  (overallStats[driverName][questionDetails.title].average != undefined) ? (overallStats[driverName][questionDetails.title].average/overallStats[driverName][questionDetails.title].questionCount).toFixed(1) : '-'}</td>`
            })
        })
    html +=`</tr>`
    
    reportData.data.forEach(row => {
        html += `<tr>`
        if(['f','a'].includes(filterObj.reportType)){
            filterObj.demographics.map(ele => {
                html += `<td style="text-align: center;"> ${row[ele] ? row[ele] : ''}</td>`
            })
        } else if (['ma'].includes(filterObj.reportType)) {
            html += `<td style="text-align: center;"> ${row.reporting_manager}</td><td style="text-align: center;"> ${row.reporting_manager_id}</td>`
        }
        html += `<td style="text-align: center;"> ${((row.noOfResponses/row.headCount)*100).toFixed(1)}%</td>
        <td style="text-align: center;"> ${row.noOfResponses}</td>
        <td style="text-align: center;"> ${(row.noOfResponses <= 2 && filterObj.is_anonymous ) ? '-' : (Number(row.overallScore)).toFixed(1)}</td>`
        Object.keys(reportData.driverQuestionMapping).map(driverName => {
            reportData.driverQuestionMapping[driverName].forEach(questionDetails =>{
                html += `<td style="text-align: center;">${(row.noOfResponses <= 2 && filterObj.is_anonymous) ? '-' : (row[questionDetails.name] && row[questionDetails.name].average != '-') ? Number(row[questionDetails.name].average).toFixed(1) : '-'}</td>`
            })
        })
        html +=`</tr>`
    })

    html+='</table></body></html>'

    return html
}

export const getReportHtmlFavResponse = ({
    filterObj,
    reportData, 
    overallStats, 
    addPercentSign, 
    preciseTwoDecimalPlace
}) => {
    let totalColumnsSpan = 4;
    Object.keys(reportData.driverQuestionMapping).forEach(driverName => {
        totalColumnsSpan += reportData.driverQuestionMapping[driverName].length
    })
    
    let html = '<html><body><table border="1">'

    if(['f','a'].includes(filterObj.reportType)){
        html += `<tr><th style="text-align: center;" colspan="${totalColumnsSpan}"><b>Favourable Percentage</b></th></tr>`
    } else if (['mf'].includes(filterObj.reportType)) {
        html += `<tr><th style="text-align: center;" colspan="${totalColumnsSpan+1}"><b>Manager Scorecard Favourable Percentage</b></th></tr>`
    }

    // html += `<tr> <td style="text-align: center;" colspan="${filterObj.demographics.length + 3}"></td>`
    if(['f','a'].includes(filterObj.reportType)){
        // filterObj.demographics.map(ele => {
        //     html += `<td style="text-align: center;">${SURVEY_CONST.SURVEY_REPORT_COLUMNS[ele]}</td>`
        // })
        html += `<tr> <td style="text-align: center;" colspan="${filterObj.demographics.length + 3}"></td>`
    } else if (['ma','mf'].includes(filterObj.reportType)) {
        html += `<td style="text-align: center;" colspan="5">  </td>`
    }

    Object.keys(reportData.driverQuestionMapping).map(driverName => {
        html += `<td style="text-align: center;" colspan='${reportData.driverQuestionMapping[driverName].length}'><b>${driverName}</b></td>`
    })
    html += '</tr>'

    html += `<tr>`

    if(['f','a'].includes(filterObj.reportType)){
        // html += `<td style="text-align: center;"><b>${SURVEY_CONST.SURVEY_REPORT_COLUMNS[filterObj.demographics]}</b></td>`
        filterObj.demographics.map(ele => {
            html += `<td style="text-align: center;">${  ele == 'custom_criteria' ? filterObj.custom_criteria :  SURVEY_CONST.SURVEY_REPORT_COLUMNS[ele]}</td>`
        })
    } else if (['mf'].includes(filterObj.reportType)) {
        html += `<td style="text-align: center;"> Manager Name </td><td style="text-align: center;"> Manager Id </td>`
    }

    html += `<td style="text-align: center;"><b>Participation Rate</b></td>
		<td style="text-align: center;"><b>Participation Count</b></td>
		<td style="text-align: center;"><b>Overall Score</b></td>`
        Object.keys(reportData.driverQuestionMapping).map(driverName => {
            reportData.driverQuestionMapping[driverName].forEach(questionDetails =>{
                html += `<td style="text-align: center;"><b>${questionDetails.title}</b></td>`
            })
        })
    html +=`</tr>`
    
    html += `<tr>`
        // <td style="text-align: center;"> Overall </td>
    // filterObj.demographics.map((ele,index) => {
    //     if(index === 0){
    //         html += `<td style="text-align: center;">Overall</td>`
    //     } else {
    //         html += `<td style="text-align: center;"></td>`
    //     }
    // })
    if(['f','a'].includes(filterObj.reportType)){
        filterObj.demographics.map((ele,index) => {
            if(index === 0){
                html += `<td style="text-align: center;">Overall</td>`
            } else {
                html += `<td style="text-align: center;"></td>`
            }
        })
    } else if (['ma','mf'].includes(filterObj.reportType)) {
        html += `<td style="text-align: center;"> Overall </td><td style="text-align: center;"></td>`
    } 
        html += `<td style="text-align: center;"> ${overallStats.totalHeadCount ? preciseTwoDecimalPlace((overallStats.totalResponse/overallStats.totalHeadCount)*100) + '%' : '-'}</td>
        <td style="text-align: center;"> ${overallStats.totalHeadCount ? overallStats.totalResponse : '-'}</td>
        <td style="text-align: center;"> ${addPercentSign(ParseFloat(overallStats.totalOverallScore,1).toFixed(1))}</td>`
        Object.keys(reportData.driverQuestionMapping).map(driverName => {
            reportData.driverQuestionMapping[driverName].forEach(questionDetails =>{
                html += `<td style="text-align: center;">${overallStats[driverName] && overallStats[driverName][questionDetails.title] ? addPercentSign(((overallStats[driverName][questionDetails.title].average*100)/overallStats[driverName][questionDetails.title].questionCount).toFixed(1)) : '-'}</td>`
            })
        })
    html +=`</tr>`
    
    reportData.data.forEach(row => {
        html += `<tr>`
        if(['f','a'].includes(filterObj.reportType)){
        //    html += `<td style="text-align: center;"> ${row[filterObj.demographics]}</td>`
           filterObj.demographics.map(ele => {
                html += `<td style="text-align: center;"> ${row[ele] ? row[ele] : ''}</td>`
            })
        } else if (['mf'].includes(filterObj.reportType)) {
            html += `<td style="text-align: center;"> ${row.reporting_manager}</td><td style="text-align: center;">${row.reporting_manager_id}</td>`
        }
        html += `<td style="text-align: center;"> ${preciseTwoDecimalPlace((row.noOfResponses/row.headCount)*100)}%</td>
        <td style="text-align: center;"> ${row.noOfResponses}</td>
        <td style="text-align: center;">${(row.noOfResponses <= 2 && filterObj.is_anonymous ) ? '-' : addPercentSign(ParseFloat(row.overallScore,1).toFixed(1))}</td>`
        Object.keys(reportData.driverQuestionMapping).map(driverName => {
            reportData.driverQuestionMapping[driverName].forEach(questionDetails =>{
                html += `<td style="text-align: center;">${(row.noOfResponses <= 2 && filterObj.is_anonymous) ? '-' : (row[questionDetails.name]) ? addPercentSign(Number(row[questionDetails.name].average).toFixed(1)) : '-'}</td>`
            })
        })
        html +=`</tr>`
    })

    html+='</table></body></html>'

    return html
}

export const ParseFloat = (str,val) => {
	str = str.toString();
	str = str.slice(0, (str.indexOf(".")) + val + 1);
	return Number(str);
}