import React, { useState,useEffect } from 'react';
import HeaderLayout from '../HeaderLayout'
import PeopleOutlineTwoTone from '@material-ui/icons/PeopleOutlineTwoTone'

import { useStyles } from './style'
import { Grid,Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Fab } from '@material-ui/core'
import { getPendingSurveyListFetch } from '../fetchData';

import logo from '../../../utils/load.gif'


export default function SurveyTab() { 
    const classes = useStyles()
    const [isLoadingTable, setIsLoadingTable] = useState(false)
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);

    const [pendingSurveyList, setPendingSurveyList] = useState([])


    useEffect(() => {
        getPendingSurveyList()
    },[])

    const getPendingSurveyList = async () => {
        try {
            setIsLoadingTable(true)
            let response = await getPendingSurveyListFetch()
            if(response.data){
                setPendingSurveyList(response.data)
            }
            setIsLoadingTable(false)
        } catch (error) {
            throw error
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid> 
                <HeaderLayout
                    title='Pending Survey Tab'
                    subTitle=''
                    icon={<PeopleOutlineTwoTone/>}
                />
                </Grid>
            </Grid>
            { ( isLoadingTable ) 
            ? 
            <div className={classes.loader}><img src={logo} alt="loading..." /></div>
            : 
            <Paper className={classes.pageContent} elevation={5}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell><b>Survey Name</b></TableCell>
                                <TableCell><b>End DateTime</b></TableCell>
                                <TableCell><b>Status</b></TableCell>
                                <TableCell><b>Type</b></TableCell>
                                <TableCell><b>Action</b></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        { ( pendingSurveyList && pendingSurveyList.length > 0 ) ? pendingSurveyList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((element,key)=>(
                            <TableRow key={key}>
                                <TableCell> 
                                    {element.surveyName}
                                </TableCell>
                                <TableCell>
                                    {new Date(element.endDate)
                                    .toLocaleDateString('en-GB', {
                                        day: 'numeric',
                                        month: 'short',
                                        year: 'numeric',
                                        hour: 'numeric',
                                        minute:'numeric',
                                        second:'numeric'
                                    })
                                    .replace(/,/g, '')}
                                </TableCell>
                                <TableCell> 
                                    {element.status}
                                </TableCell>
                                <TableCell> 
                                    {element.mandatory ? 'Mandatory' : 'Optional'}
                                </TableCell>
                                <TableCell> <a target='_blank' href={`${process.env.REACT_APP_HOST_NAME}/fillSurvey/${element.uniqueId}`}>Fill Survey</a></TableCell>
                            </TableRow>
                        )) : (
                            <TableRow>
                            <TableCell><b>No result found</b></TableCell>
                            </TableRow>
                        ) } 
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                rowsPerPageOptions={[50 ,100,200, 250]}
                component="div"
                count={pendingSurveyList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
            }
        </div>
    )

}
