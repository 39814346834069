import React from 'react';
import { useStyles } from './style';
import HeaderLayout from '../HeaderLayout'
import CreateCampaignForm from '../CreateCampaignForm'
import PeopleOutlineTwoTone from '@material-ui/icons/PeopleOutlineTwoTone'
import {Paper} from '@material-ui/core'

export default function index(props) {

    const classes = useStyles();

  return (
      <div>
          <HeaderLayout
              title='Campaign'
              subTitle='Create New Campaign'
              icon={<PeopleOutlineTwoTone/>}
          />
          <Paper className={classes.pageContent}>
              <CreateCampaignForm {...props}/>
          </Paper>
          
      </div>
  )
}
