import React from "react"
import { useStyles } from "./style"

import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'

const PendingTableBody = ({
    row,
    index,
    columns = [],
    isDetailedPage = false,
    idx,
    recCount = 0,
    redirectLink = ""
}) => {
    const colCnt = columns.length
    const classes = useStyles()
    const labelId = `enhanced-table-checkbox-${index}`

    const getFullRedirectionLink = ({ isDetailedPage, redirectLink, row }) => {
        let link = `${redirectLink}/empId/${row.encryptedEmpId}`
        if (isDetailedPage === "Probation Pendency") {
            link = `${redirectLink}?empId=${row.encryptedEmpId}`
        }

        return link
    }
    return (
        <TableRow key={idx}>
            { isDetailedPage ? <TableCell
                component='th'
                id={labelId}
                scope='row'
                align={'center'}
                padding='none'
                className={isDetailedPage ? classes.tableCellDetail :  classes.tableCell}
            ><div
            style={{
              display: 'flex',
              justifyContent: 'left',
              alignItems: 'center'
            }}
          >{index+1}</div></TableCell> : (null)
            }

            { recCount > 0 ? columns.map((headCell, i) => <TableCell
                component='th'
                id={labelId}
                scope='row'
                align={'center'}
                padding='none'
                className={isDetailedPage ? classes.tableCellDetail : classes.tableCell}
                >
                { (isDetailedPage && (isDetailedPage !== "Onboarding Documents Pendency" || (isDetailedPage === "Onboarding Documents Pendency" && row.is_recruiter))  && headCell.id === "emp_name" && redirectLink)
                    ? (<span>
                        <a target="_blank" className={classes.anchorLinkEmp} href={getFullRedirectionLink({ isDetailedPage, redirectLink, row  })}>{row[headCell.id]}</a>
                        </span>)
                    : (row[headCell.id])
                }
                </TableCell>) : 
                <TableCell
                component='th'
                id={labelId}
                scope='row'
                align={'center'}
                colSpan={colCnt}
                className={isDetailedPage ? classes.tableCellDetail : classes.tableCell}
                padding='none'>{idx === 2 ? <span style={{color:"green"}}>No records to display</span> : ""}</TableCell>
            }
        </TableRow>
      )
}

export default PendingTableBody