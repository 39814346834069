import { makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiCardHeader-title': {
            fontSize: '1rem'
        },
        '& .MuiTableCell-root': {
          padding: '10px'
        },
        padding: '5px'
    },
    filterDiv: {
      cursor: 'pointer'
    },

    loader: {
        display: 'flex',
        justifyContent: 'center'
    },
    headerCell:{
      fontWeight: 'bold',
      textAlign: 'center',
    },
    headerBodyCell: {
      textAlign: 'center',
    },
    marginTop: {
      marginTop: 10
    },
    downloadAnchor: {
      margin:'12px',
      color:'blue',
      float:'right',
      '&:hover': {
        cursor: 'pointer',
      }
    }
}))
