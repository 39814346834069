import React, { Fragment } from 'react'
import { useStyles } from './style'
import { 
    Paper,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TableHead,
    Divider
} from '@material-ui/core'

const FileListTable = ({
    fileList,
    limit=2
}) => {
    const classes = useStyles()
    return (
        <Fragment>
            <Divider light />
            <Paper className={classes.paperFileList}>
                <Table className={classes.tableFiles} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                            Latest Uploaded Files
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {
                        fileList
                        .filter((result, index) => index <= limit)
                        .map(({absoluteUrl}, index) => {
                            return (
                                <TableRow key={index}>
                                        <TableCell component="th" scope="row">
                                            {`File ${index+1}    -   `}
                                            <a target="_blank" href={absoluteUrl}>View</a>
                                        </TableCell>
                                </TableRow>
                            )
                        })
                    }
                    </TableBody>
                    </Table>
            </Paper>
            </Fragment>
    )
}

export default FileListTable