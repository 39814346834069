import React from "react"
import { 
    Grid,
    Card,
    Divider
} from "@material-ui/core"
import { useStyles } from "./style"
import { Chart as ChartJS, ArcElement } from 'chart.js'
import { Doughnut } from "react-chartjs-2"
import ChartDataLabels from "chartjs-plugin-datalabels"

ChartJS.register(ArcElement)

const options = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 1,
    layout: {
        padding: 29
    },
    plugins: {
        legend: {
            display: false
        },
        datalabels: {
            anchor: 'end',
            align: "end",
            offset: 3,
            display: true,
            formatter: (val) => {
                return `${val}%`
            },
            color: '#101010',
            backgroundColor: '#FFFFFF',
            borderRadius: 5,
            font: {
                size: 10,
                weight: 'bold',
            }
        },
        tooltip: {
            enabled: true,
            boxPadding: 5
        }
    },
    elements: {
        arc: {
            offset: 0
        }
    }
}

const colorScheme = {
    "Male" : "#15C098",
    "Female" : "#5932E8"
}

const getDataSets = ({ records }) => {
    let data = {
            labels: ["Male", "Female"],
            datasets: [{
                data: [
                    parseFloat(records.male_percent), 
                    parseFloat(records.female_percent)
                ],
                backgroundColor: [
                    colorScheme["Male"], 
                    colorScheme["Female"]
                ],
                borderWidth: [5, 1],
                cutout: "65%"
            }]
        }

    return data
}

const getPlugin = (centerText = "Total Count") => {
    return [{
        beforeDraw: function(chart) {
            var width = chart.width,
            height = chart.height,
            ctx = chart.ctx
            ctx.restore()
            //var fontSize = (height / 160).toFixed(2)
            ctx.font = "12px bold sans-serif"
            ctx.textBaseline = "middle"
            var text = centerText,
            textX = Math.round((width - ctx.measureText(text).width) / 2),
            textY = height / 2
            ctx.fillText(text, textX, textY)
            ctx.save()
        } 
    }, ChartDataLabels]
}

const DiversityCard = ({ 
    diversityReport = {},
    handleDetailedData = () => {}
}) => {
    const classes = useStyles()

    const { total = {} } = diversityReport
    const { total_headcount = 0, diversityType = "" } = total

    const isSalesAvl = diversityReport.sales ? true : false
    const isNonSalesAvl = diversityReport.nonSales ? true : false

    const dataTotal = getDataSets({ records: diversityReport.total })

    // dataTotal.datasets[0].datalabels = {
    //     anchor: 'end',
    //     align: "end",
    //     offset: 0,
    //     display: true,
    //     formatter: (val) => {
    //         return `${val}%`
    //     },
    //     color: '#101010',
    //     backgroundColor: '#FFFFFF',
    //     borderRadius: 5,
    //     font: {
    //         size: 10,
    //         weight: 'bold',
    //     }
    // }
    
    const dataSales = isSalesAvl ? getDataSets({ records: diversityReport.sales }) : []
    if (isSalesAvl) { 
        // dataSales.datasets[0].datalabels = {
        //     anchor: 'end',
        //     align: "end",
        //     offset: 0,
        //     display: true,
        //     formatter: (val) => {
        //         return `${val}%`
        //     },
        //     color: '#101010',
        //     backgroundColor: '#FFFFFF',
        //     borderRadius: 5,
        //     font: {
        //         size: 10,
        //         weight: 'bold',
        //     }
        // }
    }

    const dataNonSales = isNonSalesAvl ? getDataSets({ records: diversityReport.nonSales }) : []
    if (isNonSalesAvl) { 
        // dataNonSales.datasets[0].datalabels = {
        //     anchor: 'end',
        //     align: "end",
        //     offset: 0,
        //     display: true,
        //     formatter: (val) => {
        //         return `${val}%`
        //     },
        //     color: '#101010',
        //     backgroundColor: '#FFFFFF',
        //     borderRadius: 5,
        //     font: {
        //         size: 10,
        //         weight: 'bold',
        //     }
        // }
    }
    const pluginsTotal = total_headcount ? getPlugin("Total Count") : null
    const pluginsSales = isSalesAvl ? getPlugin("Sales") : null
    const pluginsNonSales = isNonSalesAvl ? getPlugin("Non-Sales") : null
    
    return (<Grid item xs={12} sm={12} md={12} className={classes.gridBody} style={{ cursor: "pointer" }}>
                <Card style={{ height: "440px" }}>
                    <div onClick={e => handleDetailedData("Diversity")} >
                        <Grid container>
                            <Grid xs={7} sm={7} md={7} item style={{ padding: "10 10 0 10" }}>
                                <label style={{ fontSize: "1.17em", fontWeight: "bold", cursor: "pointer"  }}>Diversity</label>
                            </Grid>
                            <Grid xs={5} sm={5} md={5} item style={{ padding: "10 0 0 10" }}>
                                <div style={{ fontWeight: "bold", padding: "0 0 10 28"  }}>
                                    <span>Total Head Count </span>
                                    <br/>
                                    <span style={{ fontWeight: "bold", fontSize: "20px", paddingLeft: "30%"}}>{diversityReport.total.total_headcount}</span>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid xs={12} sm={12} md={12} item>
                                <div style={{ padding: "0 0 10 0" }}>
                                    <Divider variant="middle" />
                                </div>
                            </Grid>
                        </Grid>
                        
                        <Grid container style={{ paddingTop: "50px" }}>
                            {isSalesAvl && isNonSalesAvl ? null : <Grid xs={4} sm={4} md={4} item></Grid> }
                                <Grid xs={4} sm={4} md={4} item>
                                    { total_headcount ? <Doughnut data={dataTotal} options={options} plugins={pluginsTotal} /> : null }
                                </Grid>
                            {isSalesAvl && isNonSalesAvl ? null : <Grid xs={4} sm={4} md={4} item></Grid> }

                            {
                                isSalesAvl && isNonSalesAvl ? <Grid xs={4} sm={4} md={4} item>
                                                <Doughnut data={dataSales} options={options} plugins={pluginsSales} />
                                            </Grid> : null
                            }
                            {
                                isSalesAvl && isNonSalesAvl ? <Grid xs={4} sm={4} md={4} item>
                                        <Doughnut data={dataNonSales} options={options} plugins={pluginsNonSales} />
                                    </Grid> : null
                            }
                            
                            <Grid xs={12} sm={12} md={12} item style={{ paddingTop: "30px" }}>
                                {
                                    total_headcount ? <div style={{ float: "right", padding: "0 10 0 0" }}>
                                                        <span style={{ height: "7px", width: "7px", backgroundColor: `${colorScheme["Male"]}`, borderRadius: "50%", display: "inline-block" }}></span>
                                                        &nbsp; <span style={{ fontSize: "11px" }}>Male</span>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        <span style={{ height: "7px", width: "7px", backgroundColor: `${colorScheme["Female"]}`, borderRadius: "50%", display: "inline-block" }}></span>
                                                        &nbsp; <span style={{ fontSize: "11px" }}>Female</span>
                                                </div> : null
                                }
                            </Grid>
                        </Grid>
                        {
                            isSalesAvl && isNonSalesAvl ? <Grid container style={{ paddingTop: "50px" }}>
                                <Grid xs={12} sm={12} md={12} item style={{ textAlign: "center", padding: "10 0 0 0" }}>
                                    <span style={{ fontSize: "12px", fontWeight: "bold" }}>Total Head Count Sales : {diversityReport.sales.total_headcount}</span>
                                    <br/>
                                    <span style={{ fontSize: "12px", fontWeight: "bold" }}>Total Head Count Non-Sales : {diversityReport.nonSales.total_headcount}</span>
                                </Grid>
                            </Grid> : <Grid container style={{ paddingTop: "50px" }}>
                                <Grid xs={12} sm={12} md={12} item style={{ textAlign: "center", padding: "10 0 0 0" }}>
                                    <span style={{ fontSize: "14px", fontWeight: "bold" }}>Total Head Count {diversityType} : {diversityReport.total.total_headcount}</span>
                                </Grid>
                            </Grid>
                        }
                    </div>
                </Card>
            </Grid>
        )
}

export default DiversityCard