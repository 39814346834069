import axios from "axios"
import { getAxiosConfig, getRequestHeader } from "../../utils/CommonFunction"
import { masterAdminAccessUrl } from "../../utils/CommonUrl"

let axiosConfig = getAxiosConfig({ withCredentials:true });
axiosConfig.headers = getRequestHeader()

export const postMasterAdminAccess = async ({ formData }) => {
    const url = `${masterAdminAccessUrl.POST_MASTER_ADMIN_ACCESS}`

    try {
        const response = await axios.post(url, { formData }, axiosConfig)
        if (response.status === 200) {
            return response.data
        }
    } catch (err) {
        throw err
    }
}

export const getActiveEmployeeListFetch = async () => {
    const { GET_ACTIVE_EMPLOYEE_URL } = masterAdminAccessUrl

    try {
        const response = await axios.get(`${GET_ACTIVE_EMPLOYEE_URL}`)
        if (response.status === 200) {
            return response.data
        }

        throw response
    } catch (err) {
        throw err
    }
}

export const getMasterAdminEmployeesFetch = async () => {
    const { GET_MASTER_ADMIN_EMPLOYEE_URL } = masterAdminAccessUrl

    try {
        const response = await axios.get(`${GET_MASTER_ADMIN_EMPLOYEE_URL}`)
        if (response.status === 200) {
            return response.data
        }

        throw response
    } catch (err) {
        throw err
    }
}
