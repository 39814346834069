import React from 'react'
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';

const IOSSwitch = withStyles((theme) => ({
     root: {
       width: 48,
       height: 26,
       padding: 0,
       margin: theme.spacing(1),
     },
     switchBase: {
       padding: 1,
       '&$checked': {
         transform: 'translateX(20px)',
         color: theme.palette.common.white,
         '& + $track': {
           backgroundColor: '#52d869',
           opacity: 1,
           border: 'none',
         },
       },
       '&$focusVisible $thumb': {
         color: '#52d869',
         border: '6px solid #fff',
       },
     },
     thumb: {
       width: 24,
       height: 24,
     },
     track: {
       borderRadius: 26,
       border: `1px solid ${theme.palette.black}`,
       backgroundColor: theme.palette.black,
       opacity: 1,
       transition: theme.transitions.create(['background-color', 'border']),
     },
     checked: {},
     focusVisible: {},
   }))(({ classes, ...props }) => {
     return (
       <Switch
         focusVisibleClassName={classes.focusVisible}
         disableRipple
         classes={{
           root: classes.root,
           switchBase: classes.switchBase,
           thumb: classes.thumb,
           track: classes.track,
           checked: classes.checked,
         }}
         {...props}
       />
     );
   });

const PermissionRow = ({ isSwitchEnabled, handlePermissionSwitch, colSpanCount }) => {
     return (
          <TableRow>
               <TableCell colSpan={colSpanCount} align="center">
                    <FormControlLabel
                         control={<IOSSwitch 
                              disabled={!isSwitchEnabled}
                              onChange={(e) => {
                                   handlePermissionSwitch(e)
                              }}
                         />}
                              
                         label="I want to change my nominee details"
                         labelPlacement="start"
                    />
               </TableCell>
          </TableRow>
     );
}

export default PermissionRow;