import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiTableCell-root': {
            padding: '2px',
            // margin: theme.spacing(3)
        },
        '& .oc-heading': {
            backgroundColor: 'maroon !important',
            display: 'flex',
            justifyContent: 'space-around',
            width: '150px !important',
            whiteSpace: 'pre-line !important',
            height: 'auto !important'
        },
        '& .oc-node': {
            width: '160px !important'
        },
        '& .oc-content': {
            width: '150px !important',
            whiteSpace: 'pre-line !important',
            height: 'auto !important'
        },
        '& .orgchart': {
            background: 'antiquewhite !important'
        },
        '& .oc-symbol': {
            width: '8px !important',
            padding: '2px !important'
        }
    },
    pageContent: {
        margin: theme.spacing(5),
        padding: theme.spacing(3),
        marginLeft: theme.spacing(1)
        // width: '50%'
    },
    loader: {
        display: 'flex',
        justifyContent: 'center'
    }
}))
