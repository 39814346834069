import React, { useState,useEffect } from 'react';

import moment from 'moment-timezone'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CardHeader from '@material-ui/core/CardHeader';
import Card from '@material-ui/core/Card';
import HeaderLayout from '../HeaderLayout'
import PeopleOutlineTwoTone from '@material-ui/icons/PeopleOutlineTwoTone'
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined'
import { validateCsvFile } from '../surveyCommonFunction'
import Publish from '@material-ui/icons/Publish'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import SimpleAlerts from '../../Joiner/Alert'
import qs from 'qs'

import { useStyles } from './style'
import { Button, FormControl, Grid,Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Fab } from '@material-ui/core'
import { saveColorShadingLogic, getCurrentColorShadingLogicFetch, getColorCodingListFetch } from '../fetchData';

import { orange } from '@material-ui/core/colors'
import { withStyles } from '@material-ui/core/styles'
import logo from '../../../utils/load.gif'
import { surveyApiUrl } from "../../../utils/CommonUrl"
import { scrollToViewHandler } from '../../../constants/CommonConstant';
import { PopoverPicker } from "./PopoverPicker";
import { pageURLConfig } from '../../../constants/CommonConstant';
import { getPageRightsDetails } from '../../../utils/authFunction';
const { moduleName, controllerName, actionName } = pageURLConfig.SURVEY_INDEX_COLOR_SHADING

const PreviousButton = withStyles(theme => ({
    root: {
      color: theme.palette.getContrastText(orange[500]),
      backgroundColor: orange[500],
      '&:hover': {
        backgroundColor: orange[700]
      }
    }
  }))(Button)

const INITIAL_FORM_OBJECT = {
    criteria_name: '',
    buckets: ['','']
}

const defaultStartDate = moment().format("YYYY-MM-DD")
const defaultEndDate = moment().format("YYYY-MM-DD")


const INITIAL_FORM_OBJECT_T = {
    name: '',
    average_color_ranges: {
        'low': {
            from : 0,
            to: '',
            colorCode: '#5478F6'
        },
        'medium': {
            from : '',
            to: '',
            colorCode: '#89AAFF'
        },
        'high': {
            from : '',
            to: 5,
            colorCode: '#BDD0FF'
        }
    },
    favourable_color_ranges: {
        'low': {
            from : 0,
            to: '',
            colorCode: '#5478F6'
        },
        'medium': {
            from : '',
            to: '',
            colorCode: '#89AAFF'
        },
        'high': {
            from : '',
            to: 100,
            colorCode: '#BDD0FF'
        }
    },
    startDate: defaultStartDate,
    endDate: defaultEndDate
}

const ColorShadingForm = ({handleSave,setNotification,formObj,setIsLoading,props, isHavingPageAddEditAccess}) => {
    const classes = useStyles()
    const [expandDefault, setExpandDefault] = useState(false)
    const [formObjT, setFormObjT] = useState(INITIAL_FORM_OBJECT_T)
    const [isView, setIsView] = useState(false)
    const [currentLogicId, setCurrentLogicId] = useState(null)
    const isColorShadingFormVisible = !isView ? isHavingPageAddEditAccess : true

    useEffect(() => {
        getCurrentColorShadingLogic()
    },[])

    const getCurrentColorShadingLogic = async () => {

        let logicId = qs.parse(props.location.search, { ignoreQueryPrefix: true }).logicId
        let searchParams = {}
        if(logicId){
            setIsView(true)
            searchParams['logicId'] = logicId
        }
        try {
            let response = await getCurrentColorShadingLogicFetch({searchParams})
            if(response.data && response.data[0]){
                let responseData = response.data[0]
                setCurrentLogicId(responseData['id'])
                let tempFormObj = JSON.parse(JSON.stringify(formObjT))
                tempFormObj.startDate = responseData['start_date']
                tempFormObj.endDate = responseData['end_date']
                tempFormObj.average_color_ranges.low.from  = responseData['avg_low_from']
                tempFormObj.average_color_ranges.low.to  = responseData['avg_low_to']
                tempFormObj.average_color_ranges.low.colorCode  = responseData['avg_low_color'] || tempFormObj.average_color_ranges.low.colorCode
                tempFormObj.average_color_ranges.medium.from  = responseData['avg_medium_from']
                tempFormObj.average_color_ranges.medium.to  = responseData['avg_medium_to'] 
                tempFormObj.average_color_ranges.medium.colorCode  = responseData['avg_medium_color'] || tempFormObj.average_color_ranges.medium.colorCode
                tempFormObj.average_color_ranges.high.from  = responseData['avg_high_from']
                tempFormObj.average_color_ranges.high.to  = responseData['avg_high_to']
                tempFormObj.average_color_ranges.high.colorCode  = responseData['avg_high_color'] || tempFormObj.average_color_ranges.high.colorCode
                
                tempFormObj.favourable_color_ranges.low.from  = responseData['fav_low_from']
                tempFormObj.favourable_color_ranges.low.to  = responseData['fav_low_to']
                tempFormObj.favourable_color_ranges.low.colorCode  = responseData['fav_low_color'] || tempFormObj.favourable_color_ranges.low.colorCode
                tempFormObj.favourable_color_ranges.medium.from  = responseData['fav_medium_from']
                tempFormObj.favourable_color_ranges.medium.to  = responseData['fav_medium_to']
                tempFormObj.favourable_color_ranges.medium.colorCode  = responseData['fav_medium_color'] || tempFormObj.favourable_color_ranges.medium.colorCode
                tempFormObj.favourable_color_ranges.high.from  = responseData['fav_high_from']
                tempFormObj.favourable_color_ranges.high.to  = responseData['fav_high_to']
                tempFormObj.favourable_color_ranges.high.colorCode  = responseData['fav_high_color'] || tempFormObj.favourable_color_ranges.high.colorCode
                setFormObjT(tempFormObj)
            }
        } catch (error) {
            throw error
        }
    }

    const handleReset = () => {
        setFormObjT(INITIAL_FORM_OBJECT_T)
    }

    const handleAverageRangeChange = (e , key, pos ) => {
        let tempFormObj = JSON.parse(JSON.stringify(formObjT))
        tempFormObj.average_color_ranges[key][pos] = e.target.value
        let nextKey = ''
        if(key == 'low'){
            nextKey = 'medium'
        }
        if(key == 'medium'){
            nextKey = 'high'
        }
        if(nextKey){
            tempFormObj.average_color_ranges[nextKey]['from'] = String((Number(e.target.value) + 0.1).toFixed(1))
        }
        setFormObjT(tempFormObj)
    }

    const handleFavourableRangeChange = (e , key, pos ) => {

        let tempFormObj = JSON.parse(JSON.stringify(formObjT))
        tempFormObj.favourable_color_ranges[key][pos] = e.target.value
        let nextKey = ''
        if(key == 'low'){
            nextKey = 'medium'
        }
        if(key == 'medium'){
            nextKey = 'high'
        }
        if(nextKey){
            tempFormObj.favourable_color_ranges[nextKey]['from'] = String((Number(e.target.value) + 0.1).toFixed(1))
        }
        setFormObjT(tempFormObj)
    }

    const handleDateChange = (val, key) => {
        if(isView){
            return
        }
        setFormObjT({...formObjT, [key]:val })
    }

    const setColorCodes = (e,key,type) => {
        
        if(isView){
            return
        }
        let tempFormObj = JSON.parse(JSON.stringify(formObjT))
        if(type == 'average') {
            tempFormObj.average_color_ranges[key]['colorCode'] = e
        } else if(type == 'fav'){
            tempFormObj.favourable_color_ranges[key]['colorCode'] = e
        }
        setFormObjT(tempFormObj)   
    }

    return (isColorShadingFormVisible &&
            <Accordion
                expanded={expandDefault}
                onChange={() => setExpandDefault(!expandDefault)}
                className={classes.pageContent}
                style={{margin:'9px'}}
                
            >
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1a-content'
                id='panel1a-header'
                className={classes.pageContent}
                >                   
                    <Grid>
                        <Card>
                            <CardHeader
                                title = {!isView ? "Add/Edit Color Shading Logic" : "View Color Shading Logic"}
                                style={{ color: '#3f51b5'}}
                                titleTypographyProps={{variant:'subtitle1' }}
                            />
                        </Card>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails className={classes.pageContent}>
                <Grid container>
                <Paper elevation={2} className={classes.root}>
                    <form className={classes.formRoot} noValidate autoComplete='off'>
                        <Grid container>
                            <Grid container spacing={3}>
                                <Grid item xs={5} className={classes.gridItem}> 
                                <h5>
                                    Average Report Color Shading Ranges <span style={{color:'red'}}>*</span>:
                                </h5>
                                </Grid>
                            </Grid>
                        </Grid> 
                        <Grid container>
                            {formObjT.average_color_ranges && Object.keys(formObjT.average_color_ranges).map((key,index) => {
                            return <Grid container spacing={3} style={{marginTop:'auto'}}>
                                        <Grid item xs={2}  className={classes.gridItem}> 
                                            <h5>
                                                {key}<span style={{color:'red'}}>*</span>:
                                            </h5>
                                        </Grid>
                                        <Grid item xs={3} sm={12} md={3} style={{marginLeft:'inherit'}}>
                                        <TextField
                                            required
                                            type="number"
                                            id='outlined-basic'
                                            placeholder='from'
                                            name='from'
                                            inputProps={{ readOnly: true }}
                                            value={formObjT.average_color_ranges[key]['from']}
                                            className={classes.formControl}
                                        />
                                        </Grid>
                                        <Grid item xs={3} sm={12} md={3} style={{marginLeft:'inherit'}}>
                                        <TextField
                                            required
                                            id='outlined-basic'
                                            placeholder='to'
                                            name='to'
                                            type="number"
                                            inputProps={{ readOnly: (key == 'high' || isView) ? true : false }}
                                            value={formObjT.average_color_ranges[key]['to'] || ''}
                                            onChange={e => handleAverageRangeChange(e,key,'to')}
                                            className={classes.formControl}
                                        />
                                        </Grid>
                                        <PopoverPicker color={formObjT.average_color_ranges[key]['colorCode']} onChange={e => setColorCodes(e,key,'average')} />
                                        </Grid>
                            })}
                        </Grid>


                        <Grid container>
                            <Grid container spacing={3}>
                                <Grid item xs={5} className={classes.gridItem}> 
                                <h5>
                                    Favourable Percentage Color Shading Ranges <span style={{color:'red'}}>*</span>:
                                </h5>
                                </Grid>
                            </Grid>
                        </Grid> 
                        <Grid container>
                            {formObjT.favourable_color_ranges && Object.keys(formObjT.favourable_color_ranges).map((key,index) => {
                            return <Grid container spacing={3} style={{marginTop:'auto'}}>
                                        <Grid item xs={2}  className={classes.gridItem}> 
                                            <h5>
                                                {key}<span style={{color:'red'}}>*</span>:
                                            </h5>
                                        </Grid>
                                        <Grid item xs={3} sm={12} md={3} style={{marginLeft:'inherit'}}>
                                        <TextField
                                            required
                                            type="number"
                                            id='outlined-basic'
                                            placeholder='from'
                                            name='from'
                                            inputProps={{ readOnly: true }}
                                            value={formObjT.favourable_color_ranges[key]['from']}
                                            onChange={e => handleFavourableRangeChange(e,key,'from')}
                                            className={classes.formControl}
                                        />
                                        </Grid>
                                        <Grid item xs={3} sm={12} md={3} style={{marginLeft:'inherit'}}>
                                        <TextField
                                            required
                                            id='outlined-basic'
                                            placeholder='to'
                                            name='to'
                                            type="number"
                                            inputProps={{ readOnly: (key == 'high' || isView) ? true : false }}
                                            value={formObjT.favourable_color_ranges[key]['to'] || ''}
                                            onChange={e => handleFavourableRangeChange(e,key,'to')}
                                            className={classes.formControl}
                                        />
                                        </Grid>
                                        <PopoverPicker color={formObjT.favourable_color_ranges[key]['colorCode']} onChange={e => setColorCodes(e,key,'fav')} />  
                                    </Grid>
                            })}
                        </Grid>

                        <Grid container>
                        <Grid item xs={3}>
                            <FormControl>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        label="Logic Start Date"
                                        format="dd-MM-yyyy"
                                        clearable={false}
                                        name="startDate"
                                        value={formObjT.startDate}
                                        onChange={e => handleDateChange(e, 'startDate')}
                                        disableFuture={false}
                                        disablePast={false}
                                        disabled = {true}
                                        inputProps={{ readOnly: true }}
                                        required={true}
                                    />
                                </MuiPickersUtilsProvider>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                                <FormControl>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            label="Logic End Date"
                                            format="dd-MM-yyyy"
                                            clearable={false}
                                            name="endDate"
                                            value={formObjT.endDate}
                                            onChange={e => handleDateChange(e, 'endDate')}
                                            disableFuture={false}
                                            disablePast={isView ? false : true}
                                            disabled={isView ? true : false}
                                            inputProps={{ readOnly: true }}
                                            required={true}
                                        />
                                    </MuiPickersUtilsProvider>
                                </FormControl>
                            </Grid>
                        </Grid>
                        {!isView ? 
                        <Grid
                            container
                            spacing={3}
                            className={classes.marginTop}
                            direction='row'
                            alignItems='center'
                        >
                            <Grid item>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    size='small'
                                    style={{ marginLeft: 20 }}
                                    onClick={() => handleSave(formObjT,currentLogicId)}
                                    className={classes.button}
                                >
                                    Save
                                </Button>
                                <PreviousButton
                                    variant='outlined'
                                    color='primary'
                                    className={classes.resetButton}
                                    size='small'
                                    onClick={() => handleReset()}
                                >
                                    Reset
                                </PreviousButton>
                            </Grid>
                        </Grid>
                        : null }
                    </form>
                </Paper>
                </Grid>
                </AccordionDetails>
            </Accordion>
    )
}


export default function ColorShading(props) { 
    const classes = useStyles()
    const [notification, setNotification] = useState({type: '',  message: ''})
    const [colorCodingList, setColorCodingList] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingTable, setIsLoadingTable] = useState(false) 
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(50)
    const [isHavingPageViewAccess, setIsHavingPageViewAccess] = useState(null)
    const [isHavingPageAddAccess, setIsHavingPageAddAccess] = useState(true)
    const [isHavingPageEditAccess, setIsHavingPageEditAccess] = useState(true)


    let logicId = qs.parse(props.location.search, { ignoreQueryPrefix: true }).logicId
        
    useEffect(() => {
        getColorCodingList()
        setPageRights()
    },[])

    const getColorCodingList = async () => {
        try {
            setIsLoadingTable(true)
            let response = await getColorCodingListFetch()
            if(response.data){
                setColorCodingList(response.data)
            }
            setIsLoadingTable(false)
        } catch (error) {
            throw error
        }
    }

    const setPageRights = async () => {
        const { 
            isUserSuperAdmin,
            isHavingPageViewRights,
            isHavingPageAddRights, 
            isHavingPageEditRights 
        } = await getPageRightsDetails({moduleName, controllerName, actionName})
        setIsHavingPageViewAccess(isHavingPageViewRights)
        setIsHavingPageAddAccess(isHavingPageAddRights)
        setIsHavingPageEditAccess(isHavingPageEditRights)
    }

    const handleSave = async ( formData, currentLogicId ) => {
        
        try {
            setIsLoadingTable(true)
            formData['currentLogicId'] = currentLogicId
            let response = {}
            response = await saveColorShadingLogic({formData})
            if(response.error){
                setNotification({type:'error',message:response.validationObj.msg})
                scrollToViewHandler('alerts')
            } else if (!response.error && response.success){
                setNotification({type:'success',message:response.message})
                scrollToViewHandler('alerts')
            }
            getColorCodingList()
            setIsLoadingTable(false)
        } catch (error) {
            throw(error)
        }
    }

    const closeAlert = () => {
        setNotification({type:'', message: ''})
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
    const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    };

    return (
      <React.Fragment>
        {(!isHavingPageViewAccess) 
            ? 
                (isHavingPageViewAccess == null) ? <div className={classes.loader}><img src={logo} alt="loading..." /></div> :
                <div> <h1 style={{margin:'0px'}}>Unauthorised </h1> <br></br> <span>You don't have access to view this page</span> </div> 
            :
        <div className={classes.root}>
            <Grid container>
                <Grid> 
                <HeaderLayout
                    title='Color Shading Logic'
                    subTitle=''
                    icon={<PeopleOutlineTwoTone/>}
                />
                </Grid>
            </Grid>
            {
                notification.message ? (
                    <div className={classes.notification} id={'alerts'}>
                        <SimpleAlerts 
                            type={notification.type}
                            message={notification.message}
                            handleAlert={closeAlert}
                        />
                    </div>
                ) : (null)
            }
            { ( isLoading ) 
            ? 
                <div className={classes.loader}><img src={logo} alt="loading..." /></div>
            : 
                <ColorShadingForm
                handleSave={handleSave}
                setNotification={setNotification}
                setIsLoading={setIsLoading}
                props={props}
                isHavingPageAddEditAccess={isHavingPageAddAccess || isHavingPageEditAccess}
                />
            }

            { ( isLoadingTable ) 
            ? 
            <div className={classes.loader}><img src={logo} alt="loading..." /></div>
            : 
            (!logicId) 
            ?
            <Paper className={classes.pageContent} elevation={5}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell><b>S.No.</b></TableCell>
                                <TableCell><b>Logic Start Date</b></TableCell>
                                <TableCell><b>Logic End Date</b></TableCell>
                                <TableCell><b>Added On</b></TableCell>
                                <TableCell><b>Added By</b></TableCell>
                                <TableCell><b>Action</b></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        { ( colorCodingList && colorCodingList.length > 0 ) ? colorCodingList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((element,key)=>(
                            <TableRow key={key}>
                                <TableCell> 
                                    {key+1}
                                </TableCell>
                                <TableCell> 
                                    {new Date(element.start_date)
                                    .toLocaleDateString('en-GB', {
                                        day: 'numeric',
                                        month: 'short',
                                        year: 'numeric'
                                    })
                                    .replace(/ /g, '-')
                                    .replace(/,/g, '')}
                                </TableCell>
                                <TableCell> 
                                    {new Date(element.end_date)
                                    .toLocaleDateString('en-GB', {
                                        day: 'numeric',
                                        month: 'short',
                                        year: 'numeric'
                                    })
                                    .replace(/ /g, '-')
                                    .replace(/,/g, '')}
                                </TableCell>
                                <TableCell> 
                                    {new Date(element.created_at)
                                    .toLocaleDateString('en-GB', {
                                        day: 'numeric',
                                        month: 'short',
                                        year: 'numeric'
                                    })
                                    .replace(/ /g, '-')
                                    .replace(/,/g, '')}
                                </TableCell>
                                <TableCell> {element.created_by} </TableCell>
                                <TableCell> <a target='_blank' href={`${process.env.REACT_APP_BASE_URL}/survey/index/color-shading/logicId/${element.id}`}>View Logic</a></TableCell>
                            </TableRow>
                        )) : (
                            <TableRow>
                            <TableCell><b>No result found</b></TableCell>
                            </TableRow>
                        ) } 
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                rowsPerPageOptions={[50 ,100,200, 250]}
                component="div"
                count={colorCodingList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
            : null
            }
        </div> 
        }
      </React.Fragment>
    )

}
