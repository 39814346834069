import axios from "axios"

import { onboardingAPIUrl } from "../../utils/CommonUrl"
import { getAxiosConfig, getRequestHeader } from '../../utils/CommonFunction'

let axiosConfig = getAxiosConfig({ withCredentials:true });
axiosConfig.headers = getRequestHeader()

export const getCeoLetterListService = ({ filterObj = {} }) => {
    const { CEO_LETTER_LIST_API } = onboardingAPIUrl
    return axios.post(`${CEO_LETTER_LIST_API}`, { filterObj }, axiosConfig)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}

export const previewCeoLetterService = (data) => {
    const { PREVIEW_CEO_LETTER_API } = onboardingAPIUrl
    return axios.post(`${PREVIEW_CEO_LETTER_API}`, data, axiosConfig)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}

export const sendCeoLetterService = (data) => {
    const { SEND_CEO_LETTER_API } = onboardingAPIUrl
    return axios.post(`${SEND_CEO_LETTER_API}`, data, axiosConfig)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}

export const getFileFromS3Service = ({ s3DocumentKey }) => {
    const { GET_FILE_FROM_S3_API } = onboardingAPIUrl
    return axios.get(`${GET_FILE_FROM_S3_API}?key=${s3DocumentKey}`, axiosConfig)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}