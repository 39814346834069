import React from "react"
import { TableHead, TableRow, TableCell, TableSortLabel } from "@material-ui/core"

import { useStyles } from "./style"

const ReportTableHeader = ({
    handleSortRequest,
    order,
    orderBy,
    columns,
    index
}) => {
    const classes = useStyles()

    const getCellLabelName = (headCell) => {
        let label =  <span>{headCell.label}</span>
        return label
    }
    
    return (
        <TableHead className={classes.tableHeader} stickyheader={"stickyheader"} aria-label="sticky table">
            <TableRow key={"Header_Row"} className={classes.tableHeaderRow}>{
                columns.map((headCell, i) => (
                    <TableCell
                        key={`${headCell.id}_${index}_${i}`}
                        className={ `${classes.tableHeadCellDetail}`}
                        onClick={ headCell.disableSorting ? null : () => { handleSortRequest(headCell.id)} }
                        style={{ width: headCell.label === "Guidance & Support by Reporting Manager" ? "200px" : "" }}
                    >
                        {
                            headCell.disableSorting ? (getCellLabelName(headCell)) : (
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                style={{ flexDirection: "column" }}
                            >
                            {getCellLabelName(headCell)}
                            {orderBy === headCell.id ? (<span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>)
                        }
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

export default ReportTableHeader