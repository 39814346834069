import React from "react"
import { Grid, Paper, Card, Button } from "@material-ui/core"
import { useStyles } from "./style"


const MainDivOnDownload = ({
    handleDetailedData = () => {},
    message = "No record to display!",
    pageView = ""
 }) => {
    const classes = useStyles()
    const goBack = (pageName) => {
        handleDetailedData(pageName, true)
    }
    return (
        <Grid item xs={12} sm={12} md={12} className={classes.youAreAwesomeTableGridBody}>
        <Card>
            <Paper>
            <Grid style={{textAlign:"center", height: "80px"}}>
                <div style={{ textAlign: "right", padding: "5px" }}>
                {
                    pageView !== "Main" ? (
                    <Button 
                        onClick={() => goBack("Main")}
                        style={{ backgroundColor: '#4559C9', color: "white", padding: "5px", borderRadius: "5px", textTransform: "none" }}>
                    Back</Button> 
                ) : null
                }
                </div>
                <Grid style={{textAlign:"center", height: "25px"}}>{message}</Grid>
        </Grid>
            </Paper>
        </Card>
    </Grid>)
}

export default MainDivOnDownload