import { makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        color: '#fff',
        padding: theme.spacing(0),
        display: 'flex',
        flexDirection: 'column',
        marginTop:'3%'
    },
    control: {
      padding: theme.spacing(0),
    },
    footer: {
        backgroundColor: '#1c1c1c'
    }
}));