import React from 'react'
import Alert from '@material-ui/lab/Alert'

export default function SimpleAlerts({
    variant="outlined",
    type,
    message,
    handleAlert=()=>{}
}) {
  return (
        <Alert 
          severity={type} 
          variant={variant} 
          onClose={handleAlert}
        >
        {message}
        </Alert>
  );
}