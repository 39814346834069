import React, { useState,useEffect } from 'react';
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CardHeader from '@material-ui/core/CardHeader';
import Card from '@material-ui/core/Card';
import HeaderLayout from '../HeaderLayout'
import PeopleOutlineTwoTone from '@material-ui/icons/PeopleOutlineTwoTone'
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined'
import { validateCsvFile, colorCodingAverageScore, getReportHtmlAverageScore, getReportHtmlFavResponse, ParseFloat } from '../surveyCommonFunction'
import Publish from '@material-ui/icons/Publish'
import SimpleAlerts from '../../Joiner/Alert'

import { useStyles } from './style'
import { Button, FormControl, Grid,Paper, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Fab } from '@material-ui/core'
import { postSurveyCritertiaData, getCriteriaListFetch,  getAverageScoreDetails, fetchSurveyListV2, getFavResponseDetails, getAvgManagerScorecardDetails, getManagerScorecardFavDetails, getCurrentColorShadingLogicFetch } from '../fetchData';

import { orange } from '@material-ui/core/colors'
import { withStyles } from '@material-ui/core/styles'
import logo from '../../../utils/load.gif'
import { surveyApiUrl } from "../../../utils/CommonUrl"
import { SUPERADMIN, pageURLConfig, scrollToViewHandler } from '../../../constants/CommonConstant';
import qs from 'qs'
import DriverModal from './driverModal';
import Loader from "../../../components/Loader"
import ToastElement from "../../../components/Shared/Elements/ToastElement"
import { getLoggedInEmployeeDetailFetchData } from '../../Efiling/fetchData';
import { getPagesAcess } from '../../../utils/authFunction';
const { moduleName, controllerName, actionName } = pageURLConfig.SURVEY_INDEX_SURVEY_DASHBOARD



const PreviousButton = withStyles(theme => ({
    root: {
      color: theme.palette.getContrastText(orange[500]),
      backgroundColor: orange[500],
      '&:hover': {
        backgroundColor: orange[700]
      }
    }
  }))(Button)

const INITIAL_FILTER_OBJECT = {
    reportType: '',
    demographics: [],
    reporteeType: 'all',
    surveyId: '',
    is_anonymous: 0,
    custom_criteria: 'Custom Criteria'
}

const INITIAL_COLOR_SHADING_FORM_OBJECT = {
    name: '',
    average_color_ranges: {
        'low': {
            from : 0,
            to: '',
            colorCode: '#5478F6'
        },
        'medium': {
            from : '',
            to: '',
            colorCode: '#89AAFF'
        },
        'high': {
            from : '',
            to: 5,
            colorCode: '#BDD0FF'
        }
    },
    favourable_color_ranges: {
        'low': {
            from : 0,
            to: '',
            colorCode: '#5478F6'
        },
        'medium': {
            from : '',
            to: '',
            colorCode: '#89AAFF'
        },
        'high': {
            from : '',
            to: 100,
            colorCode: '#BDD0FF'
        }
    }
}

const reportTypeListAnonymous = [
    {
        value:'a',
        label: 'Average Score'
    },
    {
        value:'f',
        label: 'Favourable Percentage'
    },
    {
        value:'ma',
        label: 'Manager Scorecard - Average Score'
    },
    {
        value:'mf',
        label: 'Manager Scorecard - Favourable Percentage'
    }
]

const reportTypeListNonAnonymous = [
    {
        value:'a',
        label: 'Average Score'
    },
    {
        value:'f',
        label: 'Favourable Percentage'
    }
]
 
const demoGraphicsList = [
    {
        value:'all',
        label: 'All'
    },
    {
        value:'tenure',
        label: 'Tenure Bucket'
    },
    {
        value:'grade',
        label: 'Grade'
    },
    {
        value:'department',
        label: 'Department'
    },
    {
        value:'function',
        label: 'Sub-Department'
    },
    {
        value:'reporting_manager',
        label: 'Reporting Manager'
    },
    {
        value:'reporting_manager_id',
        label: 'Reporting Manager ID'
    },
    {
        value:'lteam_member',
        label: 'L Team Member'
    },
    {
        value:'branch',
        label: 'Branch'
    },
    {
        value:'age_bucket',
        label: 'Age Bucket'
    },
    {
        value:'gender',
        label: 'Gender'
    },
    {
        value:'location',
        label: 'Location'
    },
    {
        value:'rhr_name',
        label: 'RHR Name'
    },
    {
        value:'custom_criteria',
        label: 'Custom Criteria'
    }
]

const demoGraphicsMap = {
    tenure: 'Tenure Bucket',
    grade: 'Grade',
    department: 'Department',
    function: 'Sub-Department',
    reporting_manager: 'Reporting Manager',
    reporting_manager_id: 'Reporting Manager ID',
    lteam_member: 'L Team Member',
    branch: 'Branch',
    age_bucket: 'Age Bucket',
    gender: 'Gender',
    location: 'Location',
    rhr_name: 'RHR Name',
    custom_criteria: 'Custom Criteria'
}


const SearchFilters = ({handleFilters, props, colorShadingFormObj}) => {
    const classes = useStyles()
    const [expandDefault, setExpandDefault] = useState(false)
    const [filterObj, setFilterObj] = useState(INITIAL_FILTER_OBJECT)
    const [surveyList, setSurveyList] = useState([])
    const [surveyListIdMap, setSurveyListIdMap] = useState([])
    useEffect( () => {
        getSurveyListV2()
    },[])

    let type = qs.parse(props.location.search, { ignoreQueryPrefix: true }).type
            
    const getSurveyListV2 = async () => {
        try {
            let type = qs.parse(props.location.search, { ignoreQueryPrefix: true }).type
            let searchObj = {isManagerDashboard: type == 'manager' ? 1 : 0}
            let response = await fetchSurveyListV2({searchObj})
            if(response.data){
                setSurveyList(response.data)
                let surveyListIdMapTemp = {}
                response.data.map(ele => {
                    surveyListIdMapTemp[ele.id] = ele
                })
                setSurveyListIdMap(surveyListIdMapTemp)
            }
        } catch (error) {
            
        }
    }

    const handleChange = event => {
        setFilterObj({ ...filterObj, [event.target.name]: event.target.value })
        if(event.target.name == 'surveyId'){
            setFilterObj({ ...filterObj, 
                [event.target.name]: event.target.value,
                ['is_anonymous']: surveyListIdMap[event.target.value] && surveyListIdMap[event.target.value].is_anonymous ? surveyListIdMap[event.target.value].is_anonymous : 0,
                ['custom_criteria']: surveyListIdMap[event.target.value] && surveyListIdMap[event.target.value].criteria_name ? surveyListIdMap[event.target.value].criteria_name : 'Custom Criteria'
            })
        }
    }

    const handleReset = () => {
        setFilterObj(INITIAL_FILTER_OBJECT)
    }
    
    return (
            <Accordion
                expanded={expandDefault}
                onChange={() => setExpandDefault(!expandDefault)}
                className={classes.pageContent}
                style={{margin:'9px'}}
                
            >
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1a-content'
                id='panel1a-header'
                className={classes.pageContent}
                >                   
                    <Grid>
                        <Card>
                            <CardHeader
                                title="Filters"
                                style={{ color: '#3f51b5'}}
                                titleTypographyProps={{variant:'subtitle1' }}
                            />
                        </Card>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails className={classes.pageContent}>
                <Grid container>
                <Paper elevation={2} className={classes.root}>
                    <form className={classes.formRoot} noValidate autoComplete='off'>
                        <Grid container>
                            <Grid container spacing={3}>
                                <Grid item xs={4} sm={12} md={4}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel id='demo-simple-select-outlined-label'>
                                    Survey
                                    </InputLabel>
                                    <Select
                                    labelId='demo-simple-select-outlined-label'
                                    id='demo-simple-select-outlined'
                                    name='surveyId'
                                    value={filterObj.surveyId || ''}
                                    onChange={e => handleChange(e)}
                                    >
                                    {surveyList.length > 0 &&
                                        surveyList.map((item, index) => (
                                        <MenuItem value={item.id} key={item.id}>
                                            {item.surveyName}
                                        </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                </Grid>
                                <Grid item xs={4} sm={12} md={4}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel id='demo-simple-select-outlined-label'>
                                        Report Type
                                        </InputLabel>
                                        <Select
                                        labelId='demo-simple-select-outlined-label'
                                        id='demo-simple-select-outlined'
                                        name='reportType'
                                        value={filterObj.reportType || ''}
                                        onChange={e => handleChange(e)}
                                        >
                                        {surveyListIdMap[filterObj.surveyId] && surveyListIdMap[filterObj.surveyId].is_anonymous 
                                        ? reportTypeListAnonymous.length > 0 &&
                                        reportTypeListAnonymous.map((item, index) => (
                                            <MenuItem value={item.value} key={item.value}>
                                                {item.label}
                                            </MenuItem>
                                            )) 
                                        : reportTypeListNonAnonymous.length > 0 &&
                                        reportTypeListNonAnonymous.map((item, index) => (
                                            <MenuItem value={item.value} key={item.value}>
                                                {item.label}
                                            </MenuItem>
                                            ))}
                                        {/* {reportTypeList.length > 0 &&
                                            reportTypeList.map((item, index) => (
                                            <MenuItem value={item.value} key={item.value}>
                                                {item.label}
                                            </MenuItem>
                                            ))} */}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {['a','f'].includes(filterObj.reportType) && (
                                <Grid item xs={4} sm={12} md={4}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel id='demo-simple-select-outlined-label'>
                                    Demography
                                    </InputLabel>
                                    <Select
                                    labelId='demo-simple-select-outlined-label'
                                    id='demo-simple-select-outlined'
                                    name='demographics'
                                    multiple
                                    value={filterObj.demographics || ''}
                                    onChange={e => handleChange(e)}
                                    >
                                    {demoGraphicsList.length > 0 &&
                                        demoGraphicsList.map((item, index) => (
                                        <MenuItem value={item.value} key={item.value}>
                                            {item.label}
                                        </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                </Grid>
                                )}
                            </Grid>
                            {(type == 'manager') && (
                            <Grid container spacing={3}>
                                <Grid item xs={4} sm={12} md={4}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel id='demo-simple-select-outlined-label'>
                                    Reportee Type
                                    </InputLabel>
                                    <Select
                                    labelId='demo-simple-select-outlined-label'
                                    id='demo-simple-select-outlined'
                                    name='reporteeType'
                                    value={filterObj.reporteeType || ''}
                                    onChange={e => handleChange(e)}
                                    >
                                        <MenuItem value='all' key='all'>
                                        All
                                        </MenuItem>
                                        <MenuItem value='direct' key='direct'>
                                        Direct
                                        </MenuItem>
                                        <MenuItem value='indirect' key='indirect'>
                                        Indirect
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                                </Grid>
                            </Grid>
                            )}
                        </Grid>
                        <Grid
                            container
                            spacing={3}
                            className={classes.marginTop}
                            direction='row'
                            alignItems='center'
                        >
                            <Grid item>
                            <Button
                                variant='contained'
                                color='primary'
                                size='small'
                                style={{ marginLeft: 20 }}
                                onClick={() => handleFilters(filterObj)}
                                className={classes.button}
                            >
                                Filter Results
                            </Button>
                            <PreviousButton
                                variant='outlined'
                                color='primary'
                                className={classes.resetButton}
                                size='small'
                                onClick={() => handleReset()}
                            >
                                Reset
                            </PreviousButton>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
                {Object.keys(colorShadingFormObj).length > 0 ?
                <Paper elevation={2} className={classes.root} style={{marginTop:'15px',display:'flex'}}>
                    <Grid container>
                        <Grid container>
                            <Grid container spacing={3} >
                                <Grid item xs={5} className={classes.gridItem} style={{marginLeft:'15px'}}> 
                                <h5>
                                    Average Report Color Shading Ranges:
                                </h5>
                                </Grid>
                            </Grid>
                        </Grid> 
                        <Grid container>
                            {colorShadingFormObj.average_color_ranges && Object.keys(colorShadingFormObj.average_color_ranges).map((key,index) => {
                            return <Grid container spacing={3} style={{marginTop:'auto'}}>
                                        <Grid item xs={2}  style={{marginLeft:'inherit',marginLeft:'15px'}}>
                                        <TextField
                                            required
                                            type="number"
                                            id='outlined-basic'
                                            placeholder='from'
                                            name='from'
                                            disabled
                                            inputProps={{ readOnly: true }}
                                            value={colorShadingFormObj.average_color_ranges[key]['from']}
                                            className={classes.formControl}
                                        />
                                        </Grid>
                                        <Grid item xs={2} style={{marginLeft:'inherit'}}>
                                        <TextField
                                            required
                                            id='outlined-basic'
                                            placeholder='to'
                                            name='to'
                                            type="number"
                                            disabled
                                            inputProps={{ readOnly:  true  }}
                                            value={colorShadingFormObj.average_color_ranges[key]['to'] || ''}
                                            className={classes.formControl}
                                        />
                                        </Grid>
                                        <Grid item xs={2} className={classes.gridItem} style={{margin:'auto 10px auto 10px'}}  > 
                                            <a className={classes.colorPallete} style={{backgroundColor:colorShadingFormObj.average_color_ranges[key]['colorCode']}}> </a>
                                        </Grid>
                                    </Grid>
                            })}
                        </Grid>
                      </Grid>  
                      <Grid container>
                        <Grid container>
                            <Grid container spacing={3}>
                                <Grid item xs={5} className={classes.gridItem} style={{marginLeft:'15px'}}> 
                                <h5>
                                    Favourable Percentage Color Shading Ranges:
                                </h5>
                                </Grid>
                            </Grid>
                        </Grid> 
                        <Grid container>
                            {colorShadingFormObj.favourable_color_ranges && Object.keys(colorShadingFormObj.favourable_color_ranges).map((key,index) => {
                            return <Grid container spacing={3} style={{marginTop:'auto'}}>
                                        <Grid item xs={2} style={{marginLeft:'inherit',marginLeft:'15px'}}>
                                        <TextField
                                            required
                                            type="number"
                                            id='outlined-basic'
                                            placeholder='from'
                                            name='from'
                                            disabled
                                            inputProps={{ readOnly: true }}
                                            value={colorShadingFormObj.favourable_color_ranges[key]['from']}
                                            className={classes.formControl}
                                        />
                                        </Grid>
                                        <Grid item xs={2} style={{marginLeft:'inherit'}}>
                                        <TextField
                                            required
                                            id='outlined-basic'
                                            placeholder='to'
                                            name='to'
                                            type="number"
                                            disabled
                                            inputProps={{ readOnly: true }}
                                            value={colorShadingFormObj.favourable_color_ranges[key]['to'] || ''}
                                            className={classes.formControl}
                                        />
                                        </Grid>
                                        <Grid item xs={2} className={classes.gridItem} style={{margin:'auto 10px auto 10px'}}> 
                                            <a className={classes.colorPallete} style={{backgroundColor:colorShadingFormObj.favourable_color_ranges[key]['colorCode']}}> </a>
                                        </Grid>
                                    </Grid>
                            })}
                        </Grid>
                        </Grid>
                </Paper> : null }
                </Grid>
                
                </AccordionDetails>
            </Accordion>
    )
}

export default function SurveyDashboard(props) { 
    const classes = useStyles()
    const [reportData, setReportData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [driverList, setDriverList] = useState([])
    const [filterObj, setFilterObj] = useState(INITIAL_FILTER_OBJECT)
    const [overallStats, setOverallStats] = useState({})
    const [openDriverModal, setOpenDriverModal] = useState(false)
    const [driverModalData,setDriverModalData] = useState({})
    const [colorShading, setColorShading] = useState({})
    const [colorShadingFormObj, setColorShadingFormObj] = useState({})
    const [isHavingPageViewAccess, setIsHavingPageViewAccess] = useState(true)

    useEffect(() => {
        setPageAcessses()
    }, [])

    const setPageAcessses = async () => {
        const loggedInUserObj = await getLoggedInEmployeeDetailFetchData()
        let isSuperAdminUser = false
        if(loggedInUserObj.data[0].admin_user_group_id == SUPERADMIN){
            isSuperAdminUser = true
        }

        let pageAccess = null
        let pageViewAccessVal = false
        if(isSuperAdminUser) {
            pageViewAccessVal = true
        } else {
            pageAccess = await getPagesAcess ({ userGroupId: loggedInUserObj.data[0].admin_user_group_id, moduleName,controllerName, actionName })
            if(pageAccess && pageAccess.data && pageAccess.data.data[0] && pageAccess.data.data[0].page_view == "Yes"){
                pageViewAccessVal = true
            }
        }
        setIsHavingPageViewAccess(pageViewAccessVal)
    }

    const getCurrentColorShadingLogic = async (searchParams) => {
        try {
            let response = await getCurrentColorShadingLogicFetch({searchParams})
            if(response && response.data && response.data[0]){
                let responseData = response.data[0]
                setColorShading(responseData)
                let tempFormObj = JSON.parse(JSON.stringify(INITIAL_COLOR_SHADING_FORM_OBJECT))
                tempFormObj.average_color_ranges.low.from  = responseData['avg_low_from']
                tempFormObj.average_color_ranges.low.to  = responseData['avg_low_to']
                tempFormObj.average_color_ranges.low.colorCode  = responseData['avg_low_color'] || tempFormObj.average_color_ranges.low.colorCode
                tempFormObj.average_color_ranges.medium.from  = responseData['avg_medium_from']
                tempFormObj.average_color_ranges.medium.to  = responseData['avg_medium_to'] 
                tempFormObj.average_color_ranges.medium.colorCode  = responseData['avg_medium_color'] || tempFormObj.average_color_ranges.medium.colorCode
                tempFormObj.average_color_ranges.high.from  = responseData['avg_high_from']
                tempFormObj.average_color_ranges.high.to  = responseData['avg_high_to']
                tempFormObj.average_color_ranges.high.colorCode  = responseData['avg_high_color'] || tempFormObj.average_color_ranges.high.colorCode
                
                tempFormObj.favourable_color_ranges.low.from  = responseData['fav_low_from']
                tempFormObj.favourable_color_ranges.low.to  = responseData['fav_low_to']
                tempFormObj.favourable_color_ranges.low.colorCode  = responseData['fav_low_color'] || tempFormObj.favourable_color_ranges.low.colorCode
                tempFormObj.favourable_color_ranges.medium.from  = responseData['fav_medium_from']
                tempFormObj.favourable_color_ranges.medium.to  = responseData['fav_medium_to']
                tempFormObj.favourable_color_ranges.medium.colorCode  = responseData['fav_medium_color'] || tempFormObj.favourable_color_ranges.medium.colorCode
                tempFormObj.favourable_color_ranges.high.from  = responseData['fav_high_from']
                tempFormObj.favourable_color_ranges.high.to  = responseData['fav_high_to']
                tempFormObj.favourable_color_ranges.high.colorCode  = responseData['fav_high_color'] || tempFormObj.favourable_color_ranges.high.colorCode
                setColorShadingFormObj(tempFormObj)
            } else {
                setColorShadingFormObj({})
                setColorShading({})
            }
        } catch (error) {
            throw error
        }
    }

    

    const calculateOverallStatsAverage = (data) => {
        let overallStats = {}
        let totalHeadCount = 0
        let totalResponse = 0
        let totalQuestionCount = 0
        let totalAverage = 0.00
        data.map(group => {
            totalHeadCount += group.headCount
            totalResponse += group.noOfResponses
        })
        overallStats.totalHeadCount = totalHeadCount
        overallStats.totalResponse = totalResponse

        return overallStats
    }

    const calculateOverallStatsFavourable = (data) => {
        let overallStats = {}
        let totalHeadCount = 0
        let totalResponse = 0
        let totalQuestionCount = 0
        let totalAverage = 0.00
        data.map(group => {
            totalHeadCount += group.headCount
            totalResponse += group.noOfResponses
        })
        overallStats.totalHeadCount = totalHeadCount
        overallStats.totalResponse = totalResponse
        
        return overallStats
    }

    
    const handleFilters = async ( filterObj ) => {
        try {
            setOverallStats({})
            setReportData([])
            if(filterObj.demographics.includes('all')){
                setFilterObj({...filterObj,demographics:Object.keys(demoGraphicsMap)})
            } else {
                setFilterObj(filterObj)
            }
            setIsLoading(true)
            let response = {}
            let type = qs.parse(props.location.search, { ignoreQueryPrefix: true }).type
            let reportObj = {surveyId:filterObj.surveyId,columns:filterObj.demographics, isDashboard: true, type:type, reporteeType:filterObj.reporteeType}
            let searchParams = {surveyId:filterObj.surveyId}
            getCurrentColorShadingLogic(searchParams)
            if(filterObj.reportType == 'a'){
                response = await getAverageScoreDetails({reportObj})
                let overallStatsTemp = response.data && response.data.length > 0 ? calculateOverallStatsAverage(response.data) : {}
               setOverallStats({...response.overllStats,...overallStatsTemp})
            }
            
            if (filterObj.reportType == 'f') {
                response = await getFavResponseDetails({reportObj})
                let overallStatsTemp = response.data && response.data.length ? calculateOverallStatsFavourable(response.data) : {}
                setOverallStats({...response.overllStats,...overallStatsTemp})
            }

            if (filterObj.reportType == 'ma') {
                setFilterObj({...filterObj,demographics:['reporting_manager','reporting_manager_id']})
                reportObj = {...reportObj,columns:['reporting_manager','reporting_manager_id']}
                response = await getAvgManagerScorecardDetails({reportObj})
                let overallStatsTemp = response.data && response.data.length > 0 ? calculateOverallStatsAverage(response.data) : {}
                setOverallStats({...response.overllStats,...overallStatsTemp})
            }

            if (filterObj.reportType == 'mf') {
                setFilterObj({...filterObj,demographics:['reporting_manager','reporting_manager_id']})
                reportObj = {...reportObj,columns:['reporting_manager','reporting_manager_id']}
                response = await getManagerScorecardFavDetails({reportObj})
                let overallStatsTemp = response.data && response.data.length > 0 ? calculateOverallStatsFavourable(response.data) : {}
                setOverallStats({...response.overllStats,...overallStatsTemp})
            }

            
            if (response && response.data) {
                setDriverList(Object.keys(response.driverQuestionMapping))
                setReportData(response)
            }

            setIsLoading(false)
        } catch (error) {
            let errMsg = error.response.data.message || error.message
            ToastElement({ msg: errMsg || `Something went wrong!`, type: `error` })
            setIsLoading(false)
        }
    }

    const handleModalShow = (driverName, driverData, overallScore) => {
        setOpenDriverModal(true)
        if(driverData.length > 0 && overallScore && overallScore != '-'){
        setDriverModalData({
            driverData: driverData || [],
            driverName: driverName,
            reportType: filterObj.reportType
        })
        }
    }

    const handleOverallScoreModalShow = (driverName, overallStatsDriverData, overallScore) => {
        setOpenDriverModal(true)
        if(overallScore && overallScore != '-'){
        let driverData = []
        reportData.driverQuestionMapping[driverName].forEach(questionDetails =>{
            let avgTemp = 0
            if(['a','ma'].includes(filterObj.reportType)){
                avgTemp = overallStatsDriverData[questionDetails.title].average
            } else if(['f','mf'].includes(filterObj.reportType)){
                avgTemp = overallStatsDriverData[questionDetails.title].average*100
            }
            driverData.push({...overallStatsDriverData[questionDetails.title], average:  overallStatsDriverData[questionDetails.title] && overallStatsDriverData[questionDetails.title].questionCount ? (avgTemp/overallStatsDriverData[questionDetails.title].questionCount).toFixed(1) : '-' })
        })
        setDriverModalData({
            driverData: driverData || [],
            driverName: driverName,
            reportType: filterObj.reportType
        })
        }
    }

    const closeDriverModal = () => {
        setOpenDriverModal(false)
        setDriverModalData({})
    }

    const handleDownloadReport = async () => {
        let fileName = ''
        let htmlData = ''
        
        switch (filterObj.reportType) {
            case 'a':
                fileName = 'Average_Score_Report.xls'
                htmlData = getReportHtmlAverageScore({
                    filterObj,
                    reportData, 
                    overallStats, 
                    driverList
                })
                break
            case 'f':
                fileName = 'Favourable_Percentage_Report.xls'
                htmlData = getReportHtmlFavResponse({
                    filterObj,
                    reportData, 
                    overallStats,
                    preciseTwoDecimalPlace,
                    addPercentSign
                })
                break
            case 'ma':
                fileName = 'Manager_Scorecard_Average_Score_Report.xls'
                htmlData = getReportHtmlAverageScore({
                    filterObj,
                    reportData, 
                    overallStats, 
                    driverList
                })
                break
            case 'mf':
                fileName = 'Manager_Scorecard_Favourable_Percentage_Report.xls'
                htmlData = getReportHtmlFavResponse({
                    filterObj,
                    reportData, 
                    overallStats,
                    preciseTwoDecimalPlace,
                    addPercentSign
                })
                break
            default:
                break
        }
        
        const blob = new Blob([htmlData], {
            type: 'data:application/vnd.ms-excel;base64'
        })
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = fileName
        a.click()
    }

    const preciseTwoDecimalPlace = (num) => {
        if (['NaN', NaN].includes(num)) {
            return '-'
        }
        
        return parseFloat(num).toFixed(1)
    }

    const addPercentSign = (str, type = '') => {
        if (['NaN', NaN].includes(str)) {
            return '-'
        } else if (filterObj.reportType == 'f' || type === 'participation' || filterObj.reportType == 'mf') {
            return str === '-' ? str : `${str}%`
        }

        return str
    }
   
    return (
        <React.Fragment>
            { (!isHavingPageViewAccess && 0) ? 
                <div> <h1 style={{margin:'0px'}}>Unauthorised </h1> <br></br> <span>You don't have access to view this page</span> </div> :
            <div className={classes.root}>
                <Grid container>
                    <Grid> 
                    <HeaderLayout
                        title='Report Dashboard'
                        subTitle=''
                        icon={<PeopleOutlineTwoTone/>}
                    />
                    </Grid>
                </Grid>
                <SearchFilters
                    handleFilters={handleFilters}
                    props={props}
                    colorShadingFormObj={colorShadingFormObj}
                />
                { Object.keys(overallStats).length ?
                    (<Paper className={classes.pageContent} elevation={5}>
                        {(reportData && reportData.data && reportData.data.length > 0 ) ? <a  className={classes.downloadAnchor} onClick={handleDownloadReport}>
                                    Download Report
                        </a>: null}
                        <TableContainer>
                            <Table style={{width:'180%'}}>
                                <TableHead>
                                    <TableRow>
                                        {['f','a'].includes(filterObj.reportType) && filterObj.demographics.map(ele => {
                                            return <TableCell style={{width:'15px'}}>
                                                {ele == 'custom_criteria' ? filterObj.custom_criteria : demoGraphicsMap[ele]}
                                                    </TableCell>
                                        })}
                                        {['mf','ma'].includes(filterObj.reportType) && (<TableCell style={{width:'15px'}}></TableCell>
                                        )}
                                        <TableCell style={{width:'15px'}}>Participation Rate</TableCell>
                                        <TableCell style={{width:'15px'}}>Participation Count</TableCell>
                                        <TableCell style={{width:'15px'}}>Overall Score</TableCell>
                                        {driverList.map((ele, i) => {
                                            return <TableCell key={i} style={{width:'15px'}}>{ele}</TableCell>
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                { Object.keys(overallStats).length > 0 ? 
                                <TableRow>
                                    {['f','a'].includes(filterObj.reportType) && filterObj.demographics.map((ele,index) => {
                                            return index === 0 ? <TableCell> Overall
                                                    </TableCell> : <TableCell>
                                                    </TableCell>
                                    })}
                                    {['mf','ma'].includes(filterObj.reportType) &&
                                        <TableCell> Overall
                                        </TableCell> 
                                    }
                                    <TableCell style={{backgroundColor:'lightgrey'}}>
                                        {overallStats.totalHeadCount ? addPercentSign(preciseTwoDecimalPlace((overallStats.totalResponse/overallStats.totalHeadCount)*100), 'participation') : '-'}
                                    </TableCell>
                                    <TableCell style={{backgroundColor:'lightgrey'}}> 
                                        {overallStats.totalHeadCount ? overallStats.totalResponse : '-'}
                                    </TableCell>
                                    <TableCell style={{backgroundColor:colorCodingAverageScore(overallStats.totalOverallScore, filterObj.reportType, colorShading)}}> 
                                        {addPercentSign(overallStats.totalOverallScore)}
                                    </TableCell>
                                        {driverList.map((driverName, j)=> {
                                            return ['mf','f'].includes(filterObj.reportType) ? <TableCell key={j} style={{backgroundColor:colorCodingAverageScore((overallStats[driverName] && overallStats[driverName].questionCount) ? preciseTwoDecimalPlace(overallStats[driverName].average*100/overallStats[driverName].questionCount) : '-', filterObj.reportType,colorShading)}} onClick={e => handleOverallScoreModalShow(driverName,overallStats[driverName],  (overallStats[driverName] && overallStats[driverName].questionCount) ? overallStats[driverName].average/overallStats[driverName].questionCount : '-')}> 
                                                        {['mf','f'].includes(filterObj.reportType) && ((overallStats[driverName] && overallStats[driverName].questionCount) ? addPercentSign(((overallStats[driverName].average*100)/overallStats[driverName].questionCount).toFixed(1)) : '-'
                                                        )}
                                                    </TableCell> : 
                                                    <TableCell key={j} style={{backgroundColor:colorCodingAverageScore((overallStats[driverName] && overallStats[driverName].questionCount) ? preciseTwoDecimalPlace(overallStats[driverName].average/overallStats[driverName].questionCount) : '-', filterObj.reportType,colorShading)}} onClick={e => handleOverallScoreModalShow(driverName,overallStats[driverName],  (overallStats[driverName] && overallStats[driverName].questionCount) ? overallStats[driverName].average/overallStats[driverName].questionCount : '-')}> 
                                                        {['ma','a'].includes(filterObj.reportType) && ((overallStats[driverName] && overallStats[driverName].questionCount) ? addPercentSign(((overallStats[driverName].average)/overallStats[driverName].questionCount).toFixed(1)) : '-'
                                                        )}
                                                    </TableCell> 
                                        })}
                                </TableRow>
                                :
                                null
                                }
                                { ( reportData && reportData.data && reportData.data.length > 0 ) ? reportData.data.map((element,key)=>(
                                    <TableRow key={key}>
                                        {['f','a'].includes(filterObj.reportType) && ( 
                                        filterObj.demographics.map(ele => {
                                            return <TableCell> 
                                                        {element[ele]}
                                                    </TableCell>
                                        })
                                        )}
                                        {['ma','mf'].includes(filterObj.reportType) && ( 
                                        <TableCell> 
                                            {element.reporting_manager}({element.reporting_manager_id})
                                        </TableCell>
                                        )}
                                        <TableCell style={{backgroundColor:'lightgrey'}}> 
                                            {addPercentSign(preciseTwoDecimalPlace((element.noOfResponses/element.headCount)*100), 'participation')}
                                        </TableCell>
                                        <TableCell style={{backgroundColor:'lightgrey'}}>
                                            {element.noOfResponses}
                                        </TableCell> 

                                        {
                                        (element.noOfResponses <= 2 && ['a','ma','mf','f'].includes(filterObj.reportType) && filterObj.is_anonymous)
                                        ?  
                                        <TableCell> 
                                            -
                                        </TableCell>
                                        : 
                                        <TableCell style={{backgroundColor:colorCodingAverageScore(element.overallScore, filterObj.reportType,colorShading)}}> 
                                            {addPercentSign(ParseFloat(element.overallScore,1).toFixed(1))}
                                        </TableCell>
                                        }
                                        {driverList.map((driverName, k) => {
                                            return (element.noOfResponses <= 2 && ['a','ma','mf','f'].includes(filterObj.reportType) && filterObj.is_anonymous) ?  
                                                    <TableCell key={k} > 
                                                        -
                                                    </TableCell> 
                                                    :
                                                    <TableCell key={k} style={{backgroundColor:colorCodingAverageScore(element.driverWiseAverage && element.driverWiseAverage[driverName], filterObj.reportType,colorShading)}} > 
                                                        <a  className={ element.driverWiseAverage ? classes.modalButton : '-'} onClick={e => handleModalShow(driverName,element.driver[driverName], (element.driverWiseAverage && element.driverWiseAverage !== '-' ? element.driverWiseAverage[driverName] : '-')  )}>{element.driverWiseAverage && element.driverWiseAverage[driverName] != undefined && element.driverWiseAverage !== '-' ? addPercentSign(Number(element.driverWiseAverage[driverName]).toFixed(1)) : '-'}</a>
                                                    </TableCell>
                                        })}
                                    </TableRow>
                                )) : (
                                    <TableRow>
                                    <TableCell><b>No result found</b></TableCell>
                                    </TableRow>
                                ) } 
                                </TableBody> 
                            </Table>
                        </TableContainer>
                    </Paper>)
                    : (<div><Loader loading={isLoading} /></div>)
                }
                {
                    driverModalData && Object.keys(driverModalData).length ? (
                        <DriverModal 
                            openDriverModal={openDriverModal}
                            closeDriverModal={closeDriverModal}
                            driverModalData={driverModalData}
                            addPercentSign={addPercentSign}
                        />) : null
                }
            </div> 
            }
        </React.Fragment>
    )

}
