import React, { useState, useEffect } from "react"
import moment from "moment"
import { 
    Grid, Card,
    Collapse
} from "@material-ui/core"
import Alert from '@material-ui/lab/Alert'

import { Helmet } from 'react-helmet'
import Filter from "../Filter"
import { useStyles } from "./style"
import { 
    getDepartmentListFetchData, 
    getSubDepartmentFetchData, 
    getUniqueGradeListFetchData,
    getLocationListFetchData,
    getDesignationListByGradeNameFetchData
} from '../../Shared/FetchData'

import { getCeoLetterListService, previewCeoLetterService, sendCeoLetterService } from "../fetchData"
import CommonTable from "../../Shared/CommonTableData/CommonTable"
import View from "./view"
import PdfViewer from "./pdfViewer"

const nameErrText = `Invalid name! Special characters are not allowed.`

const INIT_FILTER_OBJ = {
    empName: "",
    department: [],
    subDepartment: [],
    grade: [],
    designation: [],
    location: [],
    branch: [],
    doj: null,
    isEmailSent: [],
    selectAllDepartment: false,
    selectAllSubDepartment: false,
    selectAllGrade: false,
    selectAllDesignation: false,
    selectAllBranch: false,
    selectAllLocation: false,
    selectAllIsEmailSent: false
}

const defaultErrObj = {
    doj: ""
}

const emailSentListData = [{
    id: "1",
    value: "Yes"
}, {
    id: "0",
    value: "No"
}]

const columns = [
    {
        id: "#",
        label: "#",
        disableSorting: true
    },
    {
        id: "emp_name",
        label: "Name",
        disableSorting: false
    },
    {
        id: "department",
        label: "Department",
        disableSorting: false
    },
    {
        id: "grade",
        label: "Grade",
        disableSorting: false
    },
    {
        id: "date_of_joining",
        label: "Date of Joining",
        disableSorting: false
    },
    {
        id: "branch",
        label: "Branch",
        disableSorting: false
    },
    {
        id: "offer_date",
        label: "Offer Date",
        disableSorting: false
    },
    {
        id: "is_email_sent",
        label: "Email Sent",
        disableSorting: false,
    },
    {
        id: "action",
        label: "Action",
        disableSorting: true,
        isActionBtn: true
    }
]

const initFormData = { offerLetterId: "", parentName: "Parents", empName: "" }
const defaultInputErrObj = { parentName:  false, empName: false } 

const CeoLetter = () => {
    const classes = useStyles()

    const [loading, setLoading] = useState(true)
    const [filterObj, setFilterObj] = useState({ ...INIT_FILTER_OBJ })
    const [recordList, setRecordList] = useState([])

    const [departmentData, setDepartmentData] = useState([])
    const [subDepartmentData, setSubDepartmentData] = useState([])
    const [gradeData, setGradeData] = useState([])
    const [designationsData, setDesignationsData] = useState([])
    const [locationData, setLocationData] = useState([])
    const [branchData, setBranchData] = useState([])
    const [errorObj, setErrorObj] = useState(defaultErrObj)
    const [apiErrorMessage, setApiErrorMessage] = useState("")

    const [ackMessage, setAckMessage] = useState("")

    const [isFilterSelected, setIsFilterSelected] = useState(true)
    const [detailedObj, setDetailedObj] = useState(null)
    const [formData, setFormData] = useState(initFormData)
    const [inputErrObj, setInputErrObj] = useState(defaultInputErrObj)
    const [isPreview, setIsPreview] = useState(false)
    const [pdfFileUrl, setPdfFileUrl] = useState(null)

    useEffect(() => {
        async function fetchData() {
            let fData = { ...filterObj }
            const { list = [], success, message = "" } = await getCeoLetterListData(fData)
            setApiErrorMessage(message)
            if (success) {
                setRecordList(list)
            }
            await getDepartmentList()
            await getGradeList()
            await getBranchList()
            setLoading(false)
        }

        fetchData()
    }, [])

    const getDepartmentList = async () => {
        try {
            const { data = [] } = await getDepartmentListFetchData()
            setDepartmentData(data)
            return data
        } catch (error) {}
    }   

    const getSubDepartment = async (departmentId = []) => {
        try {
            const { data = []} = await getSubDepartmentFetchData({departmentId:departmentId})
            setSubDepartmentData(data)

            return data
        } catch (err) {}
    }

    const getGradeList = async () => {
        try {
            const { data = [] } = await getUniqueGradeListFetchData()
            setGradeData(data)
            return data
        } catch (err) {}
    }

    const getDesignationList = async (gradeName = []) => {
        try {
            const { data = [] } = await getDesignationListByGradeNameFetchData({gradeName: gradeName});
            setDesignationsData(data)
            return data
        } catch(err) {}
    }

    const getBranchList = async () => {
        try {
            const { data = [] } = await getLocationListFetchData()
            if (data.length) {
                setBranchData(data)
                setLocationData(getLocationNameList(data))
            }     

            return data
        } catch (err) {}
    }

    const getLocationNameList = (data) => {
        let result = []
        const locationNamesList = [...new Set(data.map(obj => obj.location_name))]
        for (const location_name of locationNamesList) {
            result.push({
                id: location_name,
                value: location_name
            })
        }

        return result
    }

    const getCeoLetterListData = async (filterObj = {}) => {
        try {
            const response = await getCeoLetterListService({ filterObj: filterObj })
            return response
        } catch (err) {
            throw err
        }
    }

    const previewCeoLetterData = async (postData) => {
        try {
            const response = await previewCeoLetterService(postData)
            return response
        } catch (err) {
            throw err
        }
    }

    const sendCeoLetterData = async (postData) => {
        try {
            const response = await sendCeoLetterService(postData)
            return response
        } catch (err) {
            throw err
        }
    }

    const handleSubmit = async (sendObj) => {
        setLoading(true)
        const { list = [], message = "" } = await getCeoLetterListData(sendObj)
        setApiErrorMessage(message)

        setRecordList(list)
        setLoading(false)
    }

    const handleAfterSendEmail = async (sendObj) => {
        setLoading(true)
        const { list = [], message = "" } = await getCeoLetterListData(sendObj)
        setApiErrorMessage(message)

        setRecordList(list)
        setLoading(false)
    }

    const handleChange = async event => {
        let fObj = { ...filterObj }
        let eventName = event.target.name
        let eventVal = event.target.value

        if (eventName === 'empName') {
            fObj.empName = eventVal
        }

        if (eventName === 'department') {
            let selectedDepartmentId = []
            if (eventVal[eventVal.length - 1] === "all") {
                selectedDepartmentId = departmentData.map(item => item.department_id)
                selectedDepartmentId = selectedDepartmentId.length === filterObj.department.length ? [] : selectedDepartmentId
            } else {
                for (const item of departmentData) {
                    if (eventVal.includes(item.department_id)) {
                        selectedDepartmentId.push(item.department_id)
                    }
                }
            }
            
            const funcData = await getSubDepartment(selectedDepartmentId)
            fObj.selectAllDepartment = selectedDepartmentId.length
            fObj = { ...fObj, [eventName]: selectedDepartmentId }
            let subDept = []
            if (selectedDepartmentId.length && filterObj.subDepartment.length) {
                for (const obj of funcData) {
                    if (filterObj.subDepartment.includes(obj.function_id)) {
                        subDept.push(obj.function_id)
                    }
                }
            }
            fObj.subDepartment = subDept
        }

        if (eventName === 'subDepartment') {
            let selectedSubDepartmentId = []
            if (eventVal[eventVal.length - 1] === "all") {
                selectedSubDepartmentId = subDepartmentData.map(item => item.function_id)
                selectedSubDepartmentId = selectedSubDepartmentId.length === filterObj.subDepartment.length ? [] : selectedSubDepartmentId
            } else {
                for (const item of subDepartmentData) {
                    if (eventVal.includes(item.function_id)) {
                        selectedSubDepartmentId.push(item.function_id)
                    }
                }
            }
            fObj.selectAllSubDepartment = selectedSubDepartmentId.length
            fObj = { ...fObj, [eventName]: selectedSubDepartmentId }
        }
    
        if (eventName === 'grade') {
            let selectedGrade = []
            if (eventVal[eventVal.length - 1] === "all") {
                selectedGrade = gradeData.map(item => item.grade)
                selectedGrade = selectedGrade.length === filterObj.grade.length ? [] : selectedGrade
            } else {
                for (const item of gradeData) {
                    if (eventVal.includes(item.grade)) {
                        selectedGrade.push(item.grade)
                    }
                }
            }
            
            const desData = await getDesignationList(selectedGrade)
            fObj.selectAllGrade = selectedGrade.length ? true : false
            fObj = { ...fObj, [eventName]: selectedGrade }

            let des = []
            if (selectedGrade.length && filterObj.designation.length) {
                for (const obj of desData) {
                    if (filterObj.designation.includes(obj.designation)) {
                        des.push(obj.designation)
                    }
                }
            }
            fObj.designation = des
        }

        if (eventName === 'designation') {
            let selectedDes = []
            if (eventVal[eventVal.length - 1] === "all") {
                selectedDes = designationsData.map(item => item.designation)
                selectedDes = selectedDes.length === filterObj.designation.length ? [] : selectedDes
            } else {
                for (const item of designationsData) {
                    if (eventVal.includes(item.designation)) {
                        selectedDes.push(item.designation)
                    }
                }
            }

            fObj.selectAllDesignation = selectedDes.length
            fObj = { ...fObj, [eventName]: selectedDes }
        }

        if (eventName === 'location') {
            let selectedLoc = []
            if (eventVal[eventVal.length - 1] === "all") {
                selectedLoc = locationData.map(item => item.id)
                selectedLoc = selectedLoc.length === filterObj.location.length ? [] : selectedLoc
            } else {
                for (const item of locationData) {
                    if (eventVal.includes(item.id)) {
                        selectedLoc.push(item.id)
                    }
                }
            }

            fObj.selectAllLocation = selectedLoc.length
            fObj = { ...fObj, [eventName]: selectedLoc }
        }

        if (eventName === 'branch') {
            let selectedBranch = []
            if (eventVal[eventVal.length - 1] === "all") {
                selectedBranch = branchData.map(item => item.id)
                selectedBranch = selectedBranch.length === filterObj.branch.length ? [] : selectedBranch
            } else {
                for (const item of branchData) {
                    if (eventVal.includes(item.id)) {
                        selectedBranch.push(item.id)
                    }
                }
            }

            fObj.selectAllBranch = selectedBranch.length
            fObj = { ...fObj, [eventName]: selectedBranch }
        }

        if (eventName === 'isEmailSent') {
            let selected = []
            if (eventVal[eventVal.length - 1] === "all") {
                selected = emailSentListData.map(item => item.id)
                selected = selected.length === filterObj.isEmailSent.length ? [] : selected
            } else {
                for (const item of emailSentListData) {
                    if (eventVal.includes(item.id)) {
                        selected.push(item.id)
                    }
                }
            }

            fObj.selectAllIsEmailSent = selected.length
            fObj = { ...fObj, [eventName]: selected }
        }
        
        setFilterObj({ ...fObj })
    }

    const handleFilters = () => {
        let sendObj = { ...filterObj }
        setAckMessage("")
        handleSubmit(sendObj)
    }

    const handleReset = async () => {
        setFilterObj({ ...INIT_FILTER_OBJ })
        setSubDepartmentData([])
        setDesignationsData([])
        
        setLoading(true)
        const { list = [], message = "" } = await getCeoLetterListData({ ...INIT_FILTER_OBJ })
        setApiErrorMessage(message)
        setAckMessage("")
        setRecordList(list)
        setLoading(false)
    }

    const handleDateChange = (date, labelName) => {
        let errMsg = ""
        const dateVal = moment(date).format("DD-MMM-YYYY")

        let newErrObj = { [labelName]: errMsg }
        if (errMsg === "") {
            newErrObj = { ...defaultErrObj }
        } else  {
            newErrObj = { [labelName]: errMsg }
        }

        setErrorObj({ ...errorObj, ...newErrObj })
        setFilterObj({ ...filterObj, [labelName]: dateVal })
    }

    const handleClick = (offerLetterId) => {
        let recObj = null
        for (const obj of recordList) {
            if (obj.offer_letter_id === offerLetterId) {
                recObj = { ...obj }
            }
        }
        setDetailedObj(recObj)
        setFormData({
            ...formData,
            offerLetterId,
            empName: recObj.first_name
        })
        setAckMessage("")
    }

    const handleNameChange = (e, offerLetterId) => {
        let obj = { offerLetterId }
        let errObj = { ...inputErrObj }
        let eventName = e.target.name
        let eventVal = e.target.value

        const nameRegEx = /^[a-zA-Z.& ]{1,30}$/

        if (!nameRegEx.test(eventVal)) {
            errObj[eventName] = nameErrText
        } else {
            errObj[eventName] = ""
        }

        obj[eventName] = eventVal
        setFormData({ ...formData, ...obj })
        setInputErrObj({ ...errObj })
    }

    const goBack = () => {
        setDetailedObj(null)
        setFormData({...initFormData})
        handleFilters()
        setInputErrObj(defaultErrObj)
    }

    const handleSendEmail = async () => {
        setLoading(true)
        setAckMessage("")
        setApiErrorMessage("")

        const response = await sendCeoLetterData({ ...formData })
        if (response.success) {
            setAckMessage(response.message)
            setIsPreview(false)
            setDetailedObj(null)
            setFormData({...initFormData})
            handleAfterSendEmail({ ...filterObj })
        } else  {
            setApiErrorMessage(response.message)
        }
        
        setLoading(false)
    }
    
    const handleContinue = () => {
        setAckMessage("")
        setApiErrorMessage("")
        setPdfFileUrl("")
        setLoading(true)
        setIsPreview(false)
        setLoading(false)
    }

    const handlePreview = async () => {
        setLoading(true)
        setAckMessage("")
        setApiErrorMessage("")

        const response  = await previewCeoLetterData({ ...formData })
        setApiErrorMessage(response.message)
        if (response.success && response.fileUrl) {
            setPdfFileUrl(response.fileUrl)
            setIsPreview(true)
        }

        setLoading(false)
    }

    return <React.Fragment>
        <Helmet>
            <title>Onboarding: CEO Letter</title>
        </Helmet>
        <div className={classes.root}>
            <Grid container id="main-container-grid">
                <div className={classes.cardHeader}>
                    <h3 className={classes.headerText}>CEO Letter</h3>
                </div>

                <div className={classes.cardBody}>
                    <Grid container>
                        {apiErrorMessage ? <Grid item xs={12} style={{ paddingBottom: "5px" }}>
                            <Collapse in={apiErrorMessage ? true : false}>
                                <Alert onClose={() => setApiErrorMessage("")} variant="outlined" severity="error">{apiErrorMessage}</Alert>
                            </Collapse>
                            </Grid> : null}

                        {ackMessage ? <Grid item xs={12} style={{ paddingBottom: "5px" }}>
                        <Collapse in={ackMessage ? true : false}>
                            <Alert onClose={() => setAckMessage("")} variant="outlined" severity="success">
                        {ackMessage}
                        </Alert>
                        </Collapse></Grid> : null}
                    </Grid>
                    {
                        !detailedObj && !isPreview ? <div className={classes.filterBody}>
                        <Filter 
                            filterObj={filterObj}
                            loading={loading}
                            handleSubmit={handleSubmit}
                            departmentData={departmentData}
                            subDepartmentData={subDepartmentData}
                            gradeData={gradeData}
                            designationsData={designationsData}
                            locationData={locationData}
                            branchData={branchData}
                            emailSentListData={emailSentListData}

                            handleChange={handleChange}
                            handleFilters={handleFilters}
                            handleReset={handleReset}
                            handleDateChange={handleDateChange}
                            
                            errorObj={errorObj}
                            
                            
                            setIsFilterSelected={setIsFilterSelected}
                            isFilterSelected={isFilterSelected}
                        />
                    </div> : null
                    }
                    
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12} className={classes.mainGridBody}>
                            {
                                isPreview ? (<PdfViewer 
                                    pdfFileUrl={pdfFileUrl}
                                    loading={loading}
                                    handleSendEmail={handleSendEmail}
                                    handleContinue={handleContinue}
                                />) : (<Card>
                                    {
                                        detailedObj ? 
                                        <View 
                                            detailedObj={detailedObj}
                                            handleNameChange={handleNameChange}
                                            formData={formData}
                                            goBack={goBack}
                                            inputErrObj={inputErrObj}
                                            handlePreview={handlePreview}
                                            loading={loading}
                                        /> 
                                        : 
                                        <CommonTable 
                                            rows={recordList}
                                            columns={columns}
                                            totalRow={{}}
                                            reportName="CEO Letter"
                                            actionId={"offer_letter_id"}
                                            actionName={"ceo_letter_sent"}
                                            minHeight={"600px"}
                                            maxHeight={"600px"}
                                            handleClick={handleClick}
                                        />
                                    }
                                </Card>)
                            }
                        </Grid>
                    </Grid>
                </div>
            </Grid>
        </div>
    </React.Fragment>
}

export default CeoLetter