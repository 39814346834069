import React, { Fragment, useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { useStyles } from './style';
import {
  Paper,
  FormControl,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Divider,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import UpdateIcon from '@material-ui/icons/Update';
import ReplayIcon from '@material-ui/icons/Replay';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import SimpleAlerts from '../Alert';
import { deleteJoinerRecord, postReferenceData } from '../fetchData';

import {
  onlyAlphabets,
  checkEmailRegEx,
  checkMobileRegEx,
} from '../../../utils/Validation';
import { scrollToViewHandler } from '../../../constants/CommonConstant'

const tableColumns = [
  { id: 'Name', label: 'Name', minWidth: 100 },
  { id: 'Designation', label: 'Designation', minWidth: 150 },
  { id: 'Email Address', label: 'Email Address', minWidth: 50 },
  { id: 'Contact', label: 'Contact', minWidth: 50 },
  {
    id: 'Edit',
    label: '',
    minWidth: 10,
    align: 'left',
  },
  {
    id: 'Delete',
    label: '',
    minWidth: 10,
    align: 'left',
  },
];

const Reference = ({
  offerData,
  joinerReferenceData,
  handleChildData = () => {},
  handleChildDataValidation = () => {},
  handleNotification = () => {},
  handleSkippableComponent = () => {},
}) => {
  const classes = useStyles();
  const [joinerReferenceDataList, setJoinerReferenceData] = useState(
    joinerReferenceData
  );
  const [notification, setNotification] = useState({ type: '', message: '' });
  const [isUpdate, setIsUpdate] = useState(0);
  const [offerDataDetail, setOfferDataDetail] = useState(offerData)
  const initObj = {
    id: null,
    name: '',
    companyAddress: '',
    designation: '',
    emailAddress: '',
    contact: ''
  };

  let initErrObj = {};
  for (let k in initObj) {
    initErrObj[k] = { status: false, text: '' };
  }

  const [referenceObj, setReferenceObj] = useState(initObj);
  const [localErrObj, setLocalErrObj] = useState(initErrObj);

  const { name, designation, emailAddress, contact } = referenceObj;

  useEffect(() => {
    const isCount = joinerReferenceDataList.length > 1 ? true : false;
    handleChildData('joinerReferenceData', joinerReferenceDataList);
    handleChildDataValidation('joinerReferenceData', {
      status: !isCount,
      text: '',
    });
  }, [joinerReferenceDataList]);

  const handleChange = (e) => {
    let key = e.target.name;
    let value = e.target.value;
    setReferenceObj({ ...referenceObj, [key]: value });
    let { text, status } = checkReferenceDataError(key, value);
    setLocalErrObj({ ...localErrObj, [key]: { text, status } });
  };

  const handleInput = (e) => {
    switch (e.target.name) {
      case 'name':
        if (!onlyAlphabets(e.which)) {
          e.preventDefault();
        }
        break;
      default:
        break;
    }
  };

  const checkReferenceDataError = (key, value) => {
    let text = '';
    let status = false;
    switch (key) {
      case 'name':
        if (value === '') {
          status = true;
          text = `Invalid name`;
        }
        break;
      case 'designation':
        if (value === '') {
          status = true;
          text = `Invalid designation`;
        }
        break;
      case 'emailAddress':
        if (value === '' || !checkEmailRegEx(value)) {
          status = true;
          text = `Invalid email address`;
        }
        break;
      case 'contact':
        if (value === '' || !checkMobileRegEx(value)) {
          status = true;
          text = `Invalid contact`;
        }
        break;
      default:
        break;
    }

    return { text, status };
  };

  const handleSaveData = () => {
    let text = ``;
    if (!isUpdate && joinerReferenceDataList.length >= 3) {
      text = `You can add upto 3 records!`;
      setNotification({ message: text, type: 'error' });
    } else {
      let errObj = {};
      let finalStatus;
      for (let key in referenceObj) {
        let value = referenceObj[key]
        let { text, status } = checkReferenceDataError(key, value);
        errObj[key] = { text, status };
        if (status) {
          finalStatus = status;
        }
      }
      setLocalErrObj({...localErrObj, errObj})
      if (finalStatus) {
        text = `There is some error in the form!`;
        setNotification({ message: text, type: 'error' });
      } else {
        handleApiCall(referenceObj);
      }
    }
  };

  const handleApiCall = async (dataObj) => {
    let response = await postReferenceData({ referenceList: [dataObj] });
    const { success = false, joinerReferenceData = [], offerData, message = '' } = response || {};
    let text = ``;
    let type = ``;
    if (success) {
      setOfferDataDetail(offerData)
      setReferenceObj(initObj);
      setJoinerReferenceData(joinerReferenceData);
      handleSkippableComponent('Reference Details', joinerReferenceData.length);
      handleNotification({ status: false, text: '' });
      text = dataObj.id
        ? 'Record updated successfully!'
        : 'Record added successfully!';
      type = `success`;
    } else {
      text = message;
      type = `error`;
    }
    setNotification({ message: text, type });
    setIsUpdate(false);
  };

  const handleReset = () => {
    setReferenceObj(initObj);
    setIsUpdate(false);
    setNotification({ message: '', type: '' });
    handleNotification({ status: false, text: '' });
  };

  const handleRecordEdit = (e, recId) => {
    scrollToViewHandler('main-form-grid-div')
    const updateRec = getReferenceRecordById(recId);
    if (updateRec) {
      const {
        id = null,
        name = '',
        designation = '',
        email_address = '',
        contact = '',
      } = updateRec;
      const updateObj = {
        id,
        name,
        designation,
        emailAddress: email_address,
        contact: contact,
      };
      setReferenceObj(updateObj);
      setIsUpdate(recId);
      setLocalErrObj(initErrObj);
    }
  };

  const getReferenceRecordById = (id) => {
    return joinerReferenceDataList.find((obj) => obj.id === id);
  };

  const handleRecordDelete = async (e, id) => {
    if (id) {
      let response = {};
      response = await deleteJoinerRecord({ id, modelName: 'reference' });
      const { success = false, joinerReferenceData = [] } = response || {};
      let text = ``;
      let type = ``;
      if (success) {
        setReferenceObj(initObj);
        setJoinerReferenceData(joinerReferenceData);
        handleSkippableComponent(
          'Reference Details',
          joinerReferenceData.length
        );
        text = `Record deleted successfully!`;
        type = `success`;
      } else {
        text = `Something went wrong!`;
        type = `error`;
      }
      setNotification({ message: text, type });
    }
  };

  const closeAlert = () => {
    setNotification({ type: '', message: '' });
  };

  return (
    <Fragment>
      <Grid container spacing={2} className={classes.root}>
        <Grid item sm={10} id="main-form-grid-div">
          <Paper className={classes.paperFirst}>
            {notification.message ? (
              <SimpleAlerts
                type={notification.type}
                message={notification.message}
                handleAlert={closeAlert}
              />
            ) : null}
            <div style={{color: "red", float: "left", fontSize: "11px", paddingTop: "10px"}}>
              <sup>*</sup> Kindly read general instructions before filling in the information on this page
            </div>
            
            <Grid container spacing={2}>
              <FormControl className={classes.formControl}>
                <TextField
                  autoComplete='off'
                  required={true}
                  label='Name'
                  placeholder='Name'
                  value={name}
                  name='name'
                  onChange={(e) => handleChange(e)}
                  onKeyPress={(e) => handleInput(e)}
                  inputProps={{ minLength: 2, maxLength: 100 }}
                  error={localErrObj.name.status}
                  helperText={localErrObj.name.text}
                  disabled={!offerDataDetail.joiner_extra_curricular}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  autoComplete='off'
                  required={true}
                  label='Designation'
                  placeholder='Designation'
                  value={designation}
                  name='designation'
                  onChange={(e) => handleChange(e)}
                  onKeyPress={(e) => handleInput(e)}
                  inputProps={{ minLength: 2, maxLength: 100 }}
                  error={localErrObj.designation.status}
                  helperText={localErrObj.designation.text}
                  disabled={!offerDataDetail.joiner_extra_curricular}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  autoComplete='off'
                  required={true}
                  label='Email Address'
                  placeholder='Email Address'
                  value={emailAddress}
                  name='emailAddress'
                  onChange={(e) => handleChange(e)}
                  onKeyPress={(e) => handleInput(e)}
                  inputProps={{ minLength: 2, maxLength: 100 }}
                  error={localErrObj.emailAddress.status}
                  helperText={localErrObj.emailAddress.text}
                  disabled={!offerDataDetail.joiner_extra_curricular}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  autoComplete='off'
                  required={true}
                  label='Contact'
                  placeholder='Contact'
                  value={contact}
                  name='contact'
                  onChange={(e) => handleChange(e)}
                  onKeyPress={(e) => handleInput(e)}
                  inputProps={{ minLength: 2, maxLength: 1000 }}
                  error={localErrObj.contact.status}
                  helperText={localErrObj.contact.text}
                  disabled={!offerDataDetail.joiner_extra_curricular}
                />
                <div
                  style={{
                    fontSize: 10,
                    wordWrap: 'normal',
                    width: '250px',
                    textAlign: 'left',
                    marginTop: '5px',
                  }}
                >
                  Please add country code before entering mobile number. For
                  example, '+91XXXXXXXXXX'
                </div>
              </FormControl>
            </Grid>
            <Grid container spacing={2} className={classes.formControlAddBtn}>
              <FormControl className={classes.formControlAddBtn}>
                {isUpdate ? (
                  <Button
                    variant='contained'
                    color='secondary'
                    size='small'
                    className={classes.button}
                    startIcon={<UpdateIcon />}
                    onClick={() => handleSaveData()}
                    disabled={
                      (name &&
                      designation &&
                      emailAddress &&
                      contact &&
                      offerDataDetail.joiner_extra_curricular &&
                      !localErrObj.name.status &&
                      !localErrObj.designation.status &&
                      !localErrObj.emailAddress.status &&
                      !localErrObj.contact.status
                      ) ? false : true
                    }
                  >
                    Update
                  </Button>
                ) : (
                  <Button
                    variant='contained'
                    color='secondary'
                    size='small'
                    className={classes.button}
                    startIcon={<SaveIcon />}
                    onClick={() => handleSaveData()}
                    disabled={
                      (name &&
                      designation &&
                      emailAddress &&
                      contact &&
                      !localErrObj.name.status &&
                      !localErrObj.designation.status &&
                      !localErrObj.emailAddress.status &&
                      !localErrObj.contact.status
                      ) ? false : true
                    }
                  >
                    Add
                  </Button>
                )}
              </FormControl>
              <FormControl className={classes.formControlAddBtn}>
                <Button
                  variant='contained'
                  size='small'
                  className={classes.button}
                  startIcon={<ReplayIcon />}
                  onClick={handleReset}
                >
                  Reset
                </Button>
              </FormControl>
            </Grid>
          </Paper>
          <Divider light />
          <Paper className={classes.root}>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label='sticky table'>
                <TableHead>
                  <TableRow>
                    {tableColumns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {joinerReferenceDataList.map(
                    (
                      { id, name, designation, email_address, contact },
                      index
                    ) => {
                      return (
                        <TableRow hover tabIndex={-1} key={index}>
                          <TableCell align={'left'} size={'small'}>
                            {name}
                          </TableCell>
                          <TableCell align={'left'} size={'small'}>
                            {designation}
                          </TableCell>
                          <TableCell align={'left'} size={'small'}>
                            {email_address}
                          </TableCell>
                          <TableCell align={'left'} size={'small'}>
                            {contact}
                          </TableCell>
                          <TableCell align={'left'} size={'small'}>
                            <div onClick={(e) => handleRecordEdit(e, id)}>
                              <EditIcon />
                            </div>
                          </TableCell>
                          <TableCell align={'left'} size={'small'}>
                            <div onClick={(e) => handleRecordDelete(e, id)}>
                              <DeleteForeverIcon />
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
        <Grid item sm={2}>
          <Paper className={classes.paperGeneral}>
            <div>
            <ul>
                <li className='unorder-list'>&nbsp;</li>
                <li className='unorder-list'>General Instructions:</li>
                <li className='unorder-list'>&nbsp;</li>
                <li>Kindly share your professional reference details (minimum 2) i.e. references of your managers / skip-level managers with whom you have worked in the past. Freshers may provide details of their college professors / internship mentors etc.</li>
                <li>To move ahead, click SAVE & NEXT.</li>
              </ul>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default Reference;
