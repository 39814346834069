import React from "react"
import { 
    Grid,
    Card,
    Divider,
    Typography,
    Table,
    TableRow,
    TableCell,
    Tooltip as UITooltip,
    makeStyles
} from "@material-ui/core"

import { useStyles } from "./style"
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    PointElement
} from "chart.js"
import { Bar } from "react-chartjs-2"
import { Doughnut } from "react-chartjs-2"
import ChartDataLabels from "chartjs-plugin-datalabels"

const useToolTipStyles = makeStyles(theme => ({
    customTooltip: {
        backgroundColor: 'rgba(242,243,247)',
    },
    customArrow: {
        color: 'rgba(242,243,247)',
    },
}))

const toolTipFontSize = "9px"

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    PointElement
)

const colorScheme = {
    "REMAINING": "#962DFF",
    "ATTRITION": "#493AFF",
    "EXCLUDING_REMAINING": "#15C098"
}

const options = {
    responsive: true,
    indexAxis: 'y',
    plugins: {
        legend: {
            display: false
        }
    },
    scales: {
        x: {
            min: 1,
            max: 5,
            position: 'top',
            ticks: {
                beginAtZero: true,
                precision: 0,
                font: {
                    size: 10,
                }
            },
            border: {
                dash: [2, 4],
            },
            grid: {
                drawTicks: true
            }
        },
        y:{
            grid: {
                display: false,
            },
            ticks: {
                font: {
                    size: 10,
                }
            }
        }
    }
}

const doughnutOptions = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 1,
    legend: {
        display: true
    },
    plugins: {
        tooltip: {
            enabled: false
        },
        title: {
            display: false
        },
        subtitle: {
            display: false
        },
    },
    elements: {
        arc: {
            offset: 15
        }
    }
}

const graphColumns = {
    24 : ["Company Policies Processes"],
    25 : ["HR Approachability"],
    26 : ["Performance Management System"],
    27 : ["Training Provided"],
    28 : ["Organizational Environment"],
    29 : ["Challenging Role/Job profile"],
    30 : ["Guidance & Support by Manager"],
    31 : ["Transparent & Approachable Leadership"],
    32 : ["Opportunities to air views"]
}

const getGraphLabelList = ({ obj }) => {
    let result = []
    for (const key in obj) {
        result.push(graphColumns[key])
    }

    return result
} 

const getDataSets = ({ labels, records }) => {
    let datasets = [ 
    {
        type: "scatter",
        label: "",
        data: [],
        backgroundColor: "#4A3AFF",
        borderColor: ['white'],
        radius: 5,
    },
    {
        label: "",
        type: "bar",
        data: [],
        backgroundColor: "#C5D2FD",
        
        barPercentage: 0.6,
        categoryPercentage: 0.5,
        borderRadius: 5,
    }
    ]

    
    for (const key in labels) {
        datasets[0].data.push(records[key].toFixed(2))
        datasets[1].data.push(records[key].toFixed(2))
    }

    return datasets
}

const getDataSetForDoughnut = ({ attritionPercent }) => {
    attritionPercent = parseFloat(attritionPercent)
    const remainingPercent = 100 - attritionPercent
    let data = {
            labels: "",
            datasets: [{
                data: [remainingPercent, attritionPercent],
                backgroundColor: [colorScheme["REMAINING"], colorScheme["ATTRITION"]],
                borderWidth: [5, 1],
                cutout: "55%"
            }]
        }

    return data
}

const getExcludingDataSetForDoughnut = ({ attritionPercentExcludingJoinNLeft }) => {
    attritionPercentExcludingJoinNLeft = parseFloat(attritionPercentExcludingJoinNLeft)
    const remainingPercent = 100 - attritionPercentExcludingJoinNLeft
    let data = {
            labels: "",
            datasets: [{
                data: [remainingPercent, attritionPercentExcludingJoinNLeft],
                backgroundColor: [colorScheme["EXCLUDING_REMAINING"], colorScheme["ATTRITION"]],
                borderWidth: [5, 1],
                cutout: "55%"
            }]
        }

    return data
}

const getPlugin = (exitCount) => {
    return [{
        beforeDraw: function(chart) {
            var width = chart.width,
            height = chart.height,
            ctx = chart.ctx
            ctx.restore()
            //var fontSize = (height / 160).toFixed(2)
            ctx.font = "bold 8px sans-serif"
            ctx.textBaseline = "middle"
            var text = `${exitCount}`
            ,
            textX = Math.round((width - ctx.measureText(text).width) / 2),
            textY = height / 2
            
            var text1 = 'No Of Exits',
            textX1 = Math.round((width - ctx.measureText(text).width) / 2) - 15,
            textY2 = (height / 2) + 10

            ctx.fillText(text, textX, textY)
            ctx.fillText(text1,textX1, textY2)
            ctx.save()
        }
    }, ChartDataLabels]
}

const ExitAnalysisCard = ({ 
    exitFeedbackRecord = {},
    handleDetailedData = () => {}
}) => {
    const classes = useStyles()
    const { attritionData = {}, feedbackReport = {} } = exitFeedbackRecord
    const { questionNames = {}, avgRatings = {}, avgTat, totalAvg } = feedbackReport

    const { hours = 0, minutes = 0 } = avgTat
    const { 
        exitCount = 0, attritionPercent = '0.00', earlyAttritionPercent = '0.00',
        excludingJoinNLeftExitCount = 0,
        attritionPercentExcludingJoinNLeft = '0.00',
        earlyAttritionPercentExcludingJoinNLeft = '0.00'

    } = attritionData

    const dataForDoughnut = getDataSetForDoughnut({ attritionPercent })
    dataForDoughnut.datasets[0].datalabels = {
        anchor: 'end',
        align: "end",
        offset: 3,
        display: function(context) {
            return context.dataIndex % 2
        },
        formatter: (val) => {
            return `${val}%`
        },
        color: 'black',
        borderRadius: 10,
        font: {
            size: 10,
            weight: 'bold',
        }
    }
    const plugins = exitCount ? getPlugin(exitCount) : null

    const excludingDataForDoughnut = getExcludingDataSetForDoughnut({ attritionPercentExcludingJoinNLeft }) 
    excludingDataForDoughnut.datasets[0].datalabels = {
        anchor: 'end',
        align: "end",
        offset: 3,
        display: function(context) {
            return context.dataIndex % 2
        },
        formatter: (val) => {
            return `${val}%`
        },
        color: 'black',
        borderRadius: 10,
        font: {
            size: 10,
            weight: 'bold',
        }
    }
    const excludingPlugins = excludingJoinNLeftExitCount ? getPlugin(excludingJoinNLeftExitCount) : null


    const labels = getGraphLabelList({ obj: questionNames })
    const datasets = getDataSets({ labels: questionNames, records: avgRatings })

    const toolTipClasses = useToolTipStyles()

    const getIncludingToolTipText = () => {
        return <Typography>
                    <Table>
                        <TableRow>
                            <TableCell><span style={{ fontSize: toolTipFontSize }}><b>Attrition % = </b></span></TableCell>
                            <TableCell>
                                <span style={{ fontSize: toolTipFontSize }}>Total Exits (<b>including</b> Joined & Left)
                                <br></br>in the selected period</span>
                                <hr style={{
                                    display: "block",
                                    height: "0.2px",
                                    border: 0,
                                    borderTop: "1.2px solid black",
                                    margin: "1px",
                                    padding: 0
                                }}/>
                                <span style={{ fontSize: toolTipFontSize }}>Average of starting and ending HC<br></br>of the selected  period</span>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell><span style={{ fontSize: toolTipFontSize }}><b>Early<br></br>Attrition % = </b></span></TableCell>
                            <TableCell>
                                <span  style={{ fontSize: toolTipFontSize }}>Out of total exits being considered, all exits<br></br>
                                which happened within 180 days
                                </span>
                                <hr style={{
                                    display: "block",
                                    height: "0.2px",
                                    border: 0,
                                    borderTop: "1.2px solid black",
                                    margin: "1px",
                                    padding: 0
                                }}/>
                                <span style={{ fontSize: toolTipFontSize }}>Total No of Exits in the selected period
                                    <br></br>(<b>including</b> Joined & Left)
                                </span>
                            </TableCell>
                        </TableRow>
                    </Table>
                </Typography>
    }

    const getExcludingToolTipText = () => {
        return <Typography>
                    <Table>
                        <TableRow>
                            <TableCell><span style={{ fontSize: toolTipFontSize }}><b>Attrition % = </b></span></TableCell>
                            <TableCell>
                                <span style={{ fontSize: toolTipFontSize }}>Total Exits (<b>excluding</b> Joined & Left)
                                <br></br>in the selected period</span>
                                <hr style={{
                                    display: "block",
                                    height: "0.2px",
                                    border: 0,
                                    borderTop: "1.2px solid black",
                                    margin: "1px",
                                    padding: 0
                                }}/>
                                <span style={{ fontSize: toolTipFontSize }}>Average of starting and ending HC<br></br>of the selected  period</span>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell><span style={{ fontSize: toolTipFontSize }}><b>Early<br></br>Attrition % = </b></span></TableCell>
                            <TableCell>
                                <span  style={{ fontSize: toolTipFontSize }}>Out of total exits being considered, all exits<br></br>
                                which happened within 180 days
                                </span>
                                <hr style={{
                                    display: "block",
                                    height: "0.2px",
                                    border: 0,
                                    borderTop: "1.2px solid black",
                                    margin: "1px",
                                    padding: 0
                                }}/>
                                <span style={{ fontSize: toolTipFontSize }}>Total No of Exits in the selected period
                                    <br></br>(<b>excluding</b> Joined & Left)
                                </span>
                            </TableCell>
                        </TableRow>
                    </Table>
                </Typography>
    }

    return (<Grid item xs={12} sm={12} md={12} className={classes.gridBody} style={{ cursor: "pointer" }}>
                <Card style={{ minHeight: "370px", height: "580px" }}>
                    <div onClick={e => handleDetailedData("ExitAnalysis")}>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12}>
                                <div style={{ padding: "5 10 0 10" }} onClick={e => handleDetailedData("GrossOffer")}>
                                    <div style={{ padding: "0 0 10 0" }}>
                                        <label style={{ fontSize: "1.17em", fontWeight: "bold", cursor: "pointer"  }}>Exit Analysis</label>
                                    </div>
                                    <div style={{ padding: "0 0 10 0" }}>
                                        <Divider variant="middle" />
                                    </div>
                                    <Card style={{ width: "100%" }}>
                                        <div style={{ padding: "5 0 10 10" }}>
                                            <label style={{ fontSize: "14px", fontWeight: "bold" }}>Exit Feedback Score</label>
                                        </div>
                                        <div style={{ width: "450px", height: "250px" }}>
                                            <Bar options={options} data={{ labels, datasets }} />
                                        </div>
                                        <div style={{ textAlign: "center", padding: "0 0 10 0" }}>
                                            <span><b>Overall Feedback Score: { totalAvg.toFixed(2) }</b></span>
                                        </div>
                                    </Card>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={1} sm={1} md={1}></Grid>
                            <Grid item xs={6} sm={6} md={6} style={{ padding: "5 0 10 0" }}>
                                <span><b>TAT to close the Feedback</b></span>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4} style={{ padding: "5 0 10 0" }}>
                                <span style={{ color:"#FF839A", fontWeight: "bolder" }}>{`${hours}hr ${minutes}m`}</span>
                            </Grid>
                            <Grid item xs={1} sm={1} md={1}></Grid>
                        </Grid>
                        <Grid container>
                            <UITooltip
                                title={getIncludingToolTipText()}
                                arrow={true}
                                placement={"bottom-end"}
                                classes={{
                                    tooltip: toolTipClasses.customTooltip,
                                    arrow: toolTipClasses.customArrow
                                }}
                                PopperProps={{ style: { marginTop: -12 } }}
                            >
                                <Grid item xs={6} sm={6} md={6}>
                                    <div style={{ textAlign: "center", padding: "0 0 0 20" }}>
                                        <div style={{ width: "180px", height: "120px" }}>
                                            <Doughnut data={dataForDoughnut} options={doughnutOptions} plugins={plugins} />
                                        </div>
                                    </div>
                                    <div style={{ textAlign: "center" }}>
                                        <span style={{ fontSize: "9px", fontWeight: "bold" }}>(for the selected period including Joined & Left)</span>
                                        <br/>
                                        <span style={{ height: "7px", width: "7px", backgroundColor: `${colorScheme["ATTRITION"]}`, borderRadius: "50%", display: "inline-block" }}></span>
                                        &nbsp; <span style={{ fontSize: "9px", fontWeight: "bold" }}>Attrition %</span>
                                        &nbsp;&nbsp;
                                        <span style={{ color:"#FF839A", fontWeight: "bold" }}>{attritionPercent}</span>
                                        <br/>
                                        <span style={{ fontSize: "9px", fontWeight: "bold" }}>Early Attrition %</span>
                                        &nbsp;&nbsp;
                                        <span style={{ color:"#FF839A", fontWeight: "bold" }}>{earlyAttritionPercent}</span>
                                    </div>
                                </Grid>
                            </UITooltip>
                            <UITooltip
                                title={getExcludingToolTipText()}
                                arrow={true}
                                placement={"bottom-end"}
                                classes={{
                                    tooltip: toolTipClasses.customTooltip,
                                    arrow: toolTipClasses.customArrow
                                }}
                                PopperProps={{ style: { marginTop: -12 } }}
                            >
                                <Grid item xs={6} sm={6} md={6}>
                                    <div style={{ textAlign: "center", padding: "0 0 0 20" }}>
                                        <div style={{ width: "180px", height: "120px" }}>
                                            <Doughnut data={excludingDataForDoughnut} options={doughnutOptions} plugins={excludingPlugins} />
                                        </div>
                                    </div>
                                    <div style={{ textAlign: "center" }}>
                                        <span style={{ fontSize: "9px", fontWeight: "bold" }}>(for the selected period excluding Joined & Left)</span>
                                        <br/>
                                        <span style={{ height: "7px", width: "7px", backgroundColor: `${colorScheme["ATTRITION"]}`, borderRadius: "50%", display: "inline-block" }}></span>
                                        &nbsp; <span style={{ fontSize: "9px", fontWeight: "bold" }}>Attrition %</span>
                                        &nbsp;&nbsp;
                                        <span style={{ color:"#FF839A", fontWeight: "bold" }}>{attritionPercentExcludingJoinNLeft}</span>
                                        <br/>
                                        <span style={{ fontSize: "9px", fontWeight: "bold" }}>Early Attrition %</span>
                                        &nbsp;&nbsp;
                                        <span style={{ color:"#FF839A", fontWeight: "bold" }}>{earlyAttritionPercentExcludingJoinNLeft}</span>
                                    </div>
                                </Grid>
                            </UITooltip>
                            <Grid item xs={12} sm={12} md={12}>
                                <div style={{ textAlign: "center", padding: " 5 5 0 0" }}><span style={{ fontSize: "9px", fontWeight: "bold", fontStyle: "italic" }}>All Exit Analysis reflecting  above is done basis LWD</span></div>
                            </Grid>
                        </Grid>
                    </div>
                </Card>
            </Grid>
        )
}

export default ExitAnalysisCard