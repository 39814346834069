import React from "react"
import { Grid, Button } from "@material-ui/core"


const NoRecordToDisplay = ({
    goBack = () => {},
    message = "No record to display!"
 }) => {
    return (<Grid style={{textAlign:"center", height: "80px"}}>
                <div style={{ textAlign: "right", padding: "5px" }}>
                    <Button 
                        onClick={() => goBack("Main")}
                        style={{ backgroundColor: '#4559C9', color: "white", padding: "5px", borderRadius: "5px", textTransform: "none" }}>
                    Back</Button> 
                </div>
                <Grid style={{textAlign:"center", height: "25px"}}>{message}</Grid>
        </Grid>)
}

export default NoRecordToDisplay