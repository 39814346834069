import React from "react"
import DateFnsUtils from "@date-io/date-fns"
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers'

import {
    Grid,
    Input,
    InputLabel,
    FormControl,
    MenuItem,
    Select,
    Accordion,
    Button,
    CircularProgress,
    AccordionSummary,
    AccordionDetails,
    Checkbox,
    ListItemText,
    ListItemIcon
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useStyles } from './style'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
        }
    },
    getContentAnchorEl: null
}

const Filter = ({ 
    loading = true,
    filterObj = {},
    departmentData = [],
    subDepartmentData = [],
    gradeData = [],
    designationsData = [],
    locationData = [],
    branchData = [],
    emailSentListData = [],
    errorObj={},
    handleChange = () => {},
    handleFilters = () => {},
    handleReset = () => {},
    handleDateChange = () => {},
    isFilterSelected = true,
    setIsFilterSelected = () => {}
}) => {
    const classes = useStyles()

    const handleMultiSelectValue = ({ selectedIds, keyId, keyName, list }) => {
        let hashList = {}
        for (const obj of list) {
            hashList[obj[keyId]] = obj[keyName]
        }
        let result = []
        for (const selectedId of selectedIds) {
            if (hashList[selectedId]) {
                result.push(hashList[selectedId])
            }
        }
        return result.join(",")
    }

    return (<React.Fragment>
    <Grid container>
        <Accordion
        expanded={isFilterSelected}
        onChange={()=>setIsFilterSelected(!isFilterSelected)}
        style={{ color:'#4356C2' }}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
            aria-controls='panel1a-content'
            id='panel1a-header'
            >
            Filters
            </AccordionSummary>
        </Accordion>
        </Grid>
        <Grid container style={{ marginTop: '20px' }}>
            <Accordion
            expanded={isFilterSelected}
            style={{ width:  '100%'}}
            >
                <AccordionSummary style={{ minHeight: "0px !important"}}>
                </AccordionSummary>
                <AccordionDetails>
                <div className={classes.root}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Grid container spacing={1} style={{ textAlign: "center" }}>
                        {filterObj.hasOwnProperty("empName") && (
                            <Grid item xs={6} sm={4} md={3} style={{ padding: 0 }}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel id='demo-simple-input-outlined-label'>
                                        Employee Name
                                    </InputLabel>
                                    <Input onChange={e => handleChange(e)} value={filterObj.empName} name="empName" style={{ maxWidth: '200px' }}/>
                                </FormControl>
                            </Grid>
                        )
                        }
                        
                        {filterObj.hasOwnProperty('department') && (
                            <Grid item xs={6} sm={4} md={3} style={{ padding: 0 }}>
                            <FormControl className={classes.formControl}>
                                <InputLabel id='demo-simple-select-outlined-label'>
                                Department
                                </InputLabel>
                                <Select
                                labelId='demo-mutiple-checkbox-label'
                                id='demo-mutiple-checkbox'
                                name='department'
                                value={filterObj.department}
                                onChange={e => handleChange(e)}
                                input={<Input style={{ maxWidth: '200px' }}/>}
                                renderValue={(selected) => { return handleMultiSelectValue({ selectedIds: selected, keyId:"department_id", keyName:"department", list:departmentData })}}
                                MenuProps={MenuProps}
                                multiple
                                >
                                { departmentData.length > 0 &&
                                    <MenuItem value="all">
                                        <ListItemIcon>
                                            <Checkbox
                                                checked={filterObj.selectAllDepartment ? true : false}
                                                indeterminate={
                                                    filterObj.department.length > 0 && filterObj.department.length < departmentData.length
                                                }
                                            />
                                        </ListItemIcon>
                                        <ListItemText primary={"Select All"} />
                                    </MenuItem>
                                }
                                {departmentData.length > 0 &&
                                    departmentData.map((item, index) => (
                                    <MenuItem value={item.department_id} key={item.department_id}>
                                        <ListItemIcon>
                                            <Checkbox
                                                checked={filterObj.department.includes(item.department_id)}
                                            />
                                        </ListItemIcon>
                                        <ListItemText primary={item.department} />
                                    </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            </Grid>
                        )}

                        {filterObj.hasOwnProperty('subDepartment') && (
                            <Grid item xs={6} sm={4} md={3} style={{ padding: 0 }}>
                            <FormControl className={classes.formControl}>
                                <InputLabel id='demo-simple-select-outlined-label'>
                                Sub Department
                                </InputLabel>
                                <Select
                                labelId='demo-mutiple-checkbox-label'
                                id='demo-mutiple-checkbox'
                                name='subDepartment'
                                multiple
                                value={filterObj.subDepartment}
                                onChange={e => handleChange(e)}
                                input={<Input style={{ maxWidth: '200px' }}/>}
                                renderValue={(selected) => { return handleMultiSelectValue({ selectedIds: selected, keyId:"function_id", keyName:"function_name", list:subDepartmentData })}}
                                MenuProps={MenuProps}
                                disabled={!subDepartmentData.length}
                                >
                                { subDepartmentData.length > 0 &&
                                    <MenuItem value="all">
                                        <ListItemIcon>
                                            <Checkbox
                                                checked={filterObj.selectAllSubDepartment ? true : false}
                                                indeterminate={
                                                    filterObj.subDepartment.length > 0 && filterObj.subDepartment.length < subDepartmentData.length
                                                }
                                            />
                                        </ListItemIcon>
                                        <ListItemText primary={"Select All"} />
                                    </MenuItem>
                                }
                                {subDepartmentData.length > 0 &&
                                    subDepartmentData.map((item, index) => (
                                    <MenuItem value={item.function_id} key={item.function_id}>
                                        <Checkbox
                                            checked={filterObj.subDepartment.includes(item.function_id)}
                                        />
                                        <ListItemText primary={item.function_name} />
                                    </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            </Grid>
                        )}
                        
                        {filterObj.hasOwnProperty('grade') && (
                            <Grid item xs={6} sm={4} md={3} style={{ padding: 0 }}>
                            <FormControl className={classes.formControl}>
                                <InputLabel id='demo-mutiple-checkbox-label'>
                                Grade
                                </InputLabel>
                                <Select
                                labelId='demo-mutiple-checkbox-label'
                                id='demo-mutiple-checkbox'
                                multiple
                                name='grade'
                                value={filterObj.grade}
                                onChange={e => handleChange(e)}
                                input={<Input style={{ maxWidth: '200px' }}/>}
                                renderValue={selected => selected.join(",")}
                                MenuProps={MenuProps}
                                >
                                { gradeData.length > 0 &&
                                    <MenuItem value="all">
                                        <ListItemIcon>
                                            <Checkbox
                                                checked={filterObj.selectAllGrade ? true : false}
                                                indeterminate={
                                                    filterObj.grade.length > 0 && filterObj.grade.length < gradeData.length
                                                }
                                            />
                                        </ListItemIcon>
                                        <ListItemText primary={"Select All"} />
                                    </MenuItem>
                                }
                                {gradeData.length > 0 &&
                                    gradeData.map((item, index) => (
                                    <MenuItem
                                        value={item.grade}
                                        key={item.grade}
                                    >
                                        <Checkbox checked={filterObj.grade.includes(item.grade)} />
                                        <ListItemText primary={`${item.grade}`} />
                                    </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            </Grid>
                        )}

                        {filterObj.hasOwnProperty('designation') && (
                            <Grid item xs={6} sm={4} md={3} style={{ padding: 0 }}>
                            <FormControl className={classes.formControl}>
                                <InputLabel id='demo-mutiple-checkbox-label'>
                                Designation
                                </InputLabel>
                                <Select
                                labelId='demo-mutiple-checkbox-label'
                                id='demo-mutiple-checkbox'
                                multiple
                                name='designation'
                                value={filterObj.designation}
                                onChange={e => handleChange(e)}
                                input={<Input style={{ maxWidth: '200px' }}/>}
                                renderValue={selected => selected.join(",")}
                                MenuProps={MenuProps}
                                disabled={!designationsData.length}
                                >
                                { designationsData.length > 0 &&
                                    <MenuItem value="all">
                                        <ListItemIcon>
                                            <Checkbox
                                                checked={filterObj.selectAllDesignation ? true : false}
                                                indeterminate={
                                                    filterObj.designation.length > 0 && filterObj.designation.length < designationsData.length
                                                }
                                            />
                                        </ListItemIcon>
                                        <ListItemText primary={"Select All"} />
                                    </MenuItem>
                                }
                                {designationsData.length > 0 &&
                                    designationsData.map((item, index) => (
                                    <MenuItem
                                        value={item.designation}
                                        key={item.designation}
                                    >
                                        <Checkbox
                                        checked={
                                            filterObj.designation.includes(
                                            item.designation
                                            )
                                        }
                                        />
                                        <ListItemText
                                        primary={`${item.designation}`}
                                        />
                                    </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            </Grid>
                        )}

                        {filterObj.hasOwnProperty('location') && (
                            <Grid item xs={6} sm={4} md={3} style={{ padding: 0 }}>
                            <FormControl className={classes.formControl}>
                                <InputLabel id='demo-mutiple-checkbox-label'>
                                Location
                                </InputLabel>
                                <Select
                                labelId='demo-mutiple-checkbox-label'
                                id='demo-mutiple-checkbox'
                                multiple
                                name='location'
                                value={filterObj.location}
                                onChange={e => handleChange(e)}
                                input={<Input style={{ maxWidth: '200px' }}/>}
                                renderValue={(selected) => { return handleMultiSelectValue({ selectedIds: selected, keyId:"id", keyName:"value", list:locationData })}}
                                MenuProps={MenuProps}
                                >
                                { locationData.length > 0 &&
                                    <MenuItem value="all">
                                        <ListItemIcon>
                                            <Checkbox
                                                checked={filterObj.selectAllLocation ? true : false}
                                                indeterminate={
                                                    filterObj.location.length > 0 && filterObj.location.length < locationData.length
                                                }
                                            />
                                        </ListItemIcon>
                                        <ListItemText primary={"Select All"} />
                                    </MenuItem>
                                }
                                {locationData.length > 0 &&
                                    locationData.map((item, index) => (
                                    <MenuItem
                                        value={item.id}
                                        key={item.id}
                                    >
                                        <Checkbox
                                        checked={
                                            filterObj.location.includes(
                                            item.id
                                            )
                                        }
                                        />
                                        <ListItemText
                                        primary={item.value}
                                        />
                                    </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            </Grid>
                        )}

                        {filterObj.hasOwnProperty('branch') && (
                            <Grid item xs={6} sm={4} md={3} style={{ padding: 0 }}>
                            <FormControl className={classes.formControl}>
                                <InputLabel id='demo-mutiple-checkbox-label'>
                                Branch
                                </InputLabel>
                                <Select
                                labelId='demo-mutiple-checkbox-label'
                                id='demo-mutiple-checkbox'
                                multiple
                                name='branch'
                                value={filterObj.branch}
                                onChange={e => handleChange(e)}
                                input={<Input style={{ maxWidth: '200px' }}/>}
                                renderValue={(selected) => { return handleMultiSelectValue({ selectedIds: selected, keyId:"id", keyName:"locality", list:branchData })}}
                                MenuProps={MenuProps}
                                >
                                { branchData.length > 0 &&
                                    <MenuItem value="all">
                                        <ListItemIcon>
                                            <Checkbox
                                                checked={filterObj.selectAllBranch ? true : false}
                                                indeterminate={
                                                    filterObj.branch.length > 0 && filterObj.branch.length < branchData.length
                                                }
                                            />
                                        </ListItemIcon>
                                        <ListItemText primary={"Select All"} />
                                    </MenuItem>
                                }
                                {branchData.length > 0 &&
                                    branchData.map((item, index) => (
                                    <MenuItem
                                        value={item.id}
                                        key={item.id}
                                    >
                                        <Checkbox
                                        checked={
                                            filterObj.branch.includes(
                                            item.id
                                            )
                                        }
                                        />
                                        <ListItemText
                                        primary={item.locality}
                                        />
                                    </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            </Grid>
                        )}

                        {filterObj.hasOwnProperty('doj') && (
                            <Grid
                            item
                            xs={6}
                            sm={4}
                            md={3}
                            style={{ padding: 0, marginTop: -8 }}
                            >
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                margin='normal'
                                id='date-picker-dialog'
                                name='doj'
                                label='DOJ'
                                format='dd-MMM-yyyy'
                                value={filterObj.doj}
                                onChange={date => handleDateChange(date, 'doj')}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date'
                                }}
                                InputProps={{ readOnly: true }}
                                style={{ width: "90%" }}
                                helperText={errorObj.doj}
                                error={errorObj.doj ? true : false}
                                minDate={filterObj.minDojDate}
                                maxDate={filterObj.maxDojDate}
                                />
                            </MuiPickersUtilsProvider>
                            </Grid>
                        )}

                        {filterObj.hasOwnProperty('isEmailSent') && (
                            <Grid item xs={6} sm={4} md={3} style={{ padding: 0 }}>
                            <FormControl className={classes.formControl}>
                                <InputLabel id='demo-simple-select-outlined-label'>
                                Email Sent
                                </InputLabel>
                                <Select
                                labelId='demo-mutiple-checkbox-label'
                                id='demo-mutiple-checkbox'
                                name='isEmailSent'
                                value={filterObj.isEmailSent}
                                onChange={e => handleChange(e)}
                                input={<Input style={{ maxWidth: '200px' }}/>}
                                renderValue={(selected) => { return handleMultiSelectValue({ selectedIds: selected, keyId:"id", keyName:"value", list:emailSentListData })}}
                                MenuProps={MenuProps}
                                multiple
                                >
                                { emailSentListData.length > 0 &&
                                    <MenuItem value="all">
                                        <ListItemIcon>
                                            <Checkbox
                                                checked={filterObj.selectAllIsEmailSent ? true : false}
                                                indeterminate={
                                                    filterObj.isEmailSent.length > 0 && filterObj.isEmailSent.length < emailSentListData.length
                                                }
                                            />
                                        </ListItemIcon>
                                        <ListItemText primary={"Select All"} />
                                    </MenuItem>
                                }
                                {emailSentListData.length > 0 &&
                                    emailSentListData.map((item, index) => (
                                    <MenuItem value={item.id} key={item.id}>
                                        <ListItemIcon>
                                            <Checkbox
                                                checked={filterObj.isEmailSent.includes(item.id)}
                                            />
                                        </ListItemIcon>
                                        <ListItemText primary={item.value} />
                                    </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            </Grid>
                        )}

                        <Grid item xs={6} sm={4} md={3}></Grid>
                        </Grid> 
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Grid container spacing={1} style={{ textAlign: "center" }}>
                            <Grid item xs={8} sm={8} md={8}></Grid>
                            <Grid item xs={4} sm={4} md={4} style={{ padding: 0, marginTop: 30 }}>
                                {
                                    !loading ? (<React.Fragment>
                                        <Button
                                            variant='contained'
                                            className={classes.btnLoading}
                                            onClick={() => handleReset()}
                                            size='small'
                                            >
                                            Reset
                                            </Button>
                                            <Button
                                            variant='contained'
                                            color='primary'
                                            onClick={() => handleFilters()}
                                            size='small'
                                            className={classes.btnLoading}
                                            >
                                            Filter
                                            </Button>
                                    </React.Fragment>) : (<CircularProgress
                                        size={24}
                                        className={classes.purpleButtonProgress}
                                        />)
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                </AccordionDetails>
            </Accordion>
        </Grid>
    </React.Fragment>
    )
}

export default Filter