import React from "react"
import { TableRow, TableCell } from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"
import { useStyles } from "./style"


const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow)

const ReportTableBody = ({ 
    columns = [],
    row = {},
    idx = 0,
    recCount = 0,
    colCount = 0,
    isTotalRow = false,
    page,
    pages,
    rowsPerPage,
}) => {
    const classes = useStyles()
    const labelId = `enhanced-table-checkbox-${idx}`
    const getCellData = ({ headCell, row }) => {
        let data = ``
        if (["tabp_remarks", "emp_remarks"].includes(headCell.id)) {
            data = <div style={{
                width: "100px",
                height: "60px",
                position: "relative",
                overflow: "auto",
                scrollbarWidth: "thin"
            }}>{row[headCell.id]}</div>
        } else {
            data = row[headCell.id]
        }

        return data
    }

    const getEmptyRow = ({ colCount, labelId }) => {
        return <TableCell
            component='th'
            id={labelId}
            scope='row'
            align={'center'}
            colSpan={colCount}
            className={classes.tableEmptyCellDetail}
        ><span style={{ color:"black" }}>No records to display</span></TableCell>
    }

    const getDataRow = ({ columns, row }) => {
        return columns.map((headCell, i) => <TableCell
            key={i}
            id={labelId}
            scope='row'
            align={'center'}
            padding='none'
            className={classes.tableCellDetail}
        >{headCell.id === "#" ? (page*rowsPerPage) + (idx+1) : getCellData({ headCell, row }) }</TableCell>)
    }

    const getTotalRow = ({ columns, rowObj, idx }) => {
        return <TableRow key={idx}>{columns.map(colObj => <TableCell className={classes.tableTotalCellDetail}>{rowObj[colObj.key]}</TableCell>)}</TableRow>
    }
    return (
        isTotalRow ? getTotalRow({ columns, rowObj:row, idx }) :
        <StyledTableRow key={idx+1}>
            { recCount ? getDataRow({ columns, row }) : getEmptyRow({ colCount, labelId }) }
        </StyledTableRow> 
    )
}

export default ReportTableBody