import React, {useEffect, useRef, useState} from 'react'
import Carousel from 'react-elastic-carousel'
import {useStyles} from './style'
import Item from './Item.js'
import { getActiveCampaigns } from '../fetchData'
import { campaignConst } from '../../../constants/CampaignConstants'

import { campaignApiUrl } from '../../../utils/CommonUrl'

export default function index(props) {

  const classes = useStyles();
  const [activeCampaignList, setActiveCampaignList] = useState([]);
  const [countOfHomepageBanner, setCountOfHomepageBanner] = useState(0);
  const [lastCampaignId,setLastCampaignId] = useState(-1)
  const [isAutoPlay, setIsAutoPlay] = useState(true);
  let resetTimeout;
  const carouselRef = useRef(null);

  useEffect(()=>{
    getActiveCampaignList();
  },[])

  const getActiveCampaignList = async () => {
    let response = {};
    response = await getActiveCampaigns();
    let countOfHomepageBannerTemp = 0;
    (response.data).forEach(ele => {
      if(ele.campaignTemplateMasterId ===  campaignConst.TEMPLATE_HOMEPAGE_IMAGE_HYPERLINK){
        countOfHomepageBannerTemp += 1
        setLastCampaignId(ele.id)
      }
    });
    setCountOfHomepageBanner(countOfHomepageBannerTemp)
    setActiveCampaignList(response.data)
  }

  const handleAutoPlay = (e,campaignId) => {
    setIsAutoPlay(!isAutoPlay)
    if(campaignId && campaignId === lastCampaignId){
      carouselRef.current.goTo(0)
    }
  }

  return (
    <div className={classes.contentWrapper}>
      { activeCampaignList && activeCampaignList.length > 0 ? 
        (countOfHomepageBanner ===  1 ? 
        <div className={classes.singleImage}> 
          {activeCampaignList.map((campaign) => ( 
            (campaign.campaignTemplateMasterId ===  campaignConst.TEMPLATE_HOMEPAGE_IMAGE_HYPERLINK) ?
             ((campaign && campaign.templateAttributes && campaign.templateAttributes.hyperlink !==  '') ? 
              <a target='_blank' href={campaign.templateAttributes.hyperlink} className={classes.anchor}>
                  <img alt='' src={campaignApiUrl.GET_IMAGE_URL+campaign.templateAttributes.image} height='380px' />
              </a>
               : <img alt='' src={campaignApiUrl.GET_IMAGE_URL+campaign.templateAttributes.image} height='380px' />)
              : (null) 
          ))}
        </div> :
        <Carousel
          ref={carouselRef}
          enableAutoPlay={isAutoPlay}
          autoPlaySpeed={5000}
          onNextEnd={({ index }) => {
              clearTimeout(resetTimeout)
              if (index + 1 === countOfHomepageBanner) {
                  if (carouselRef.current.goTo) {
                      resetTimeout = setTimeout(() => {
                        setIsAutoPlay((prevValue) => {
                          if(prevValue){
                            if (carouselRef.current.goTo) {
                                carouselRef.current.goTo(0)
                            }
                        }
                        return prevValue
                        })
                      }, 5000)
                  }
              }
          }}
        >
          {activeCampaignList.map((campaign) => ( 
            (campaign.campaignTemplateMasterId ===  campaignConst.TEMPLATE_HOMEPAGE_IMAGE_HYPERLINK) ?
              <Item key={campaign.id}>
                { (campaign && campaign.templateAttributes && campaign.templateAttributes.hyperlink !==  '') ? 
                <a target='_blank' href={campaign.templateAttributes.hyperlink} className={classes.anchor}>
                    <img alt='' src={campaignApiUrl.GET_IMAGE_URL+campaign.templateAttributes.image}  height='100%' onMouseEnter={handleAutoPlay}
    onMouseLeave={e => handleAutoPlay(e,campaign.id)}/>
                </a> : <img alt='' src={campaignApiUrl.GET_IMAGE_URL+campaign.templateAttributes.image}   height='100%' onMouseEnter={handleAutoPlay} onMouseLeave={e => handleAutoPlay(e,campaign.id)} />
                }
              </Item> : (null) 
          ))}
        </Carousel> ) : (null)
      }
    </div>
  )
}
