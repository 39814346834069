import axios from 'axios'

import { campaignApiUrl, getEmployeeDetailUrl } from '../../utils/CommonUrl'
import { getAxiosConfig, getRequestHeader } from '../../utils/CommonFunction'

let axiosConfig = getAxiosConfig({ withCredentials:true });
axiosConfig.headers = getRequestHeader()

export const postCampaignData = (dataObj) => {
    const { CAMPAIGN_DATA_POST_URL } = campaignApiUrl
    return axios.post(`${CAMPAIGN_DATA_POST_URL}`, dataObj)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}

export const updateCampaignData = (dataObj) => {
    const { CAMPAIGN_DATA_UPDATE_URL } = campaignApiUrl
    return axios.post(`${CAMPAIGN_DATA_UPDATE_URL}`, dataObj, axiosConfig)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}


export const getDepartmentList = () => {
    const { GET_DEPARTMENT_LIST } = campaignApiUrl
    return axios.get(`${GET_DEPARTMENT_LIST}`)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}


export const getActivityList = () => {
    const { GET_ACTIVITY_LIST } = campaignApiUrl
    return axios.get(`${GET_ACTIVITY_LIST}`)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}

export const getSubDepartmentList = (dataObj) => {
    const { GET_SUBDEPARTMENT_LIST } = campaignApiUrl
    return axios.get(`${GET_SUBDEPARTMENT_LIST}`, {params:dataObj})
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}

export const getGradeList = (dataObj) => {
    const { GET_GRADE_LIST } = campaignApiUrl
    return axios.get(`${GET_GRADE_LIST}`, {params:dataObj})
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}


export const getLocationList = (dataObj) => {
    const { GET_LOCATION_LIST } = campaignApiUrl
    return axios.get(`${GET_LOCATION_LIST}`, {params:dataObj})
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}

export const getCampaignTypesList = () => {
    const { GET_CAMPAIGN_TYPE_LIST } = campaignApiUrl
    return axios.get(`${GET_CAMPAIGN_TYPE_LIST}`)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}


export const getActiveCampaigns = () => {
    const { GET_ACTIVE_CAMPAIGNS } = campaignApiUrl
    return axios.get(`${GET_ACTIVE_CAMPAIGNS}`)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}


export const getLoggedInEmployeeDetails = () => {
    return axios.get(`${getEmployeeDetailUrl()}`)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}

export const getCampaignData = (dataObj) => {
    const { GET_CAMPAIGN_DATA } = campaignApiUrl
    return axios.get(`${GET_CAMPAIGN_DATA}`, {params:dataObj})
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}

export const getAllCampaigns = ({ filterStatus = ''}) => {
    const { GET_ALL_CAMPAIGNS } = campaignApiUrl
    const param = filterStatus ? `?campaignStatus=${filterStatus}` : ``
    return axios.get(`${GET_ALL_CAMPAIGNS}${param}`)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}


export const postUploadedFiles = (dataObj) => {
    const formData = new FormData()
    formData.append('image', dataObj.templateAttributes.image)
    const { UPLOAD_FILE_URL } = campaignApiUrl
    return axios.post(`${UPLOAD_FILE_URL}`, formData)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}

export const updateCampaignsOrder = (dataObj) => {
    const { CAMPAIGN_ORDER_UPDATE_URL } = campaignApiUrl
    return axios.put(`${CAMPAIGN_ORDER_UPDATE_URL}`, dataObj)
            .then(response => response)
            .catch(err => err)
}