import axios from 'axios'

import { getAxiosConfig, getRequestHeader } from '../../utils/CommonFunction'

const OrgChartUrl = {
    'GET_EMP_DETAILS_FOR_ORG_CHART': `${process.env.REACT_APP_HOST_NAME}/api/hrms/emp/getEmpDetailsForOrgChart`
}

let axiosConfig = getAxiosConfig({ withCredentials:true });
axiosConfig.headers = getRequestHeader()

export const getEmpDetailsForOrgChart = (dataObj) => {
    const { GET_EMP_DETAILS_FOR_ORG_CHART } = OrgChartUrl
    return axios.get(`${GET_EMP_DETAILS_FOR_ORG_CHART}`)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}
