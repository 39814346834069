import moment from "moment";
import { getNomineeFinalSubmitUrl, getEmployeeNomineeDetailUrl, getSendEmailForNomineeUrl } from './NomineeApiUrl';
import axios from 'axios'
import { getAxiosConfig, getRequestHeader } from '../../utils/CommonFunction'

let axiosConfig = getAxiosConfig({ withCredentials:true }); 
axiosConfig.headers = getRequestHeader();

const postNomineeDetails = async (postData, isGuardianRequired) => {
     const data = {
          nominee_name : postData.legalName,
          nominee_relation_id : postData.relation,
          nominee_dob : postData.dob ? moment(new Date(postData.dob)).format("YYYY-MM-DD") : null,
          nominee_contact_no : postData.phone,
          nominee_address : postData.address,
          share_percent : postData.percentage
     }
     if (isGuardianRequired) {
          data.guardian_name = postData.guardianName;
          data.guardian_address = postData.guardianAddress;
          data.guardian_contact = postData.guardianPhone;
     }
     try {
          const response = await axios.post(getEmployeeNomineeDetailUrl(), data, axiosConfig);
          return response;
     } catch(error) {
          console.log(error);
     }
     
     return false;
}

const updateNomineeDetails = (deleteId) => {
     if (deleteId) {
          const data = {
                         id: deleteId,
                         status: 'Inactive'
                    };
          try {
               return axios.put(getEmployeeNomineeDetailUrl(), JSON.stringify(data), axiosConfig)
               .then(response => {
                    return response;
               })
               .catch(error => {
                    console.log(error)
               })
          } catch(error) {
               console.log(error);
          }
     }

     return false;
}

const updateEmployeeRecForNominee = async () => {
     try {
          const response = await axios.put(getNomineeFinalSubmitUrl(), {}, axiosConfig);
          return response;
     } catch (error) {
          console.log(error);
     }
     
     return false;
}

const sendEmailNotification = async (data) => {
     try {
          const response = axios.post(getSendEmailForNomineeUrl(), data, axiosConfig);
          return response;
     } catch (error) {
          console.log(error);
     }

     return false;
}

export { postNomineeDetails, updateNomineeDetails, sendEmailNotification, updateEmployeeRecForNominee }