export const probationConstant = {
  policyInfo: {
    'PROBATION_POLICY_START_DATE': '2023-06-01',
  },
  probationActionInForm:{
    CONFIRMED: 'confirmed',
    TERMINATED: 'terminated'
  },
  probationStatus: {
    'CONFIRMED': 1,
    'ON_PROBATION': 2,
    'TERMINATED': 3,
  },
  probationStatusLabel: {
    1: "Confirmed",
    2: "On Probation",
    3: "Terminated",
  },
  confirmationDoneByLabel: {
    PAA: "P.A.A.",
    RHR: "RHR",
    HR_HEAD: "HR Head",
    System: "System"
  },
  probationTargetType: {
    ACTIVITIES: 'activities',
    SALES_TARGET: 'sales_target'
  },
  probationFormAccess: {
    FILL: 'fill', // unfilled form
    REVIEW: 'review', // editable form field. Review happens whenever form send back
    VERIFY_AND_EDIT: 'verify_and_edit', // editable form field. Verify happens by next authority whenever form got forwarded (only for RHR)
    VERIFY: 'verify', 
    VIEW: 'view',
  },
  formAccessMapping : {
    'fill' : 'Fill',
    'review': 'Review',
    'verify_and_edit': 'Verify',
    'verify' : 'Verify',
    'view': 'View',
  },
  UNFILLED_PROBATION_FORM_STATUS : 0,
  formStatusKeyToIdMapping: {
    PENDING_WITH_PAA: 0,
    PENDING_WITH_RHR: 1,
    PENDING_WITH_HROPS_L1: 2,
    PENDING_WITH_HROPS_L2: 3,
    PENDING_WITH_HR_HEAD: 4,
    CLOSED: 5,
    SENT_BACK_BY_RHR: 6,
    SENT_BACK_BY_HROPS_L1: 7,
    SENT_BACK_BY_HROPS_L2: 8,
    SENT_BACK_BY_HR_HEAD: 9,
    FORWARDED_PV_TO_HROPS_L1: 10,
    FORWARDED_PV_TO_HROPS_L2: 11,
    FORWARDED_PV_TO_HR_HEAD: 12,
    FORWARDED_PV_TO_HROPS_L2_AT_HROPS_L1: 13,
    FORWARDED_PV_TO_HR_HEAD_AT_HROPS_L1: 14,
    FORWARDED_PV_TO_HR_HEAD_AT_HROPS_L2: 15,
    CONFIRMATION_FWD_TO_HR_HEAD_FROM_PAA: 16,
    CONFIRMATION_FWD_TO_HR_HEAD_FROM_RHR: 17,
    CONFIRMATION_FWD_TO_HROPS_FROM_HR_HEAD: 18,
    CONFIRMATION_FORWARDED_PV_TO_HR_HEAD_FROM_PAA: 19,
    CONFIRMATION_SENT_BACK_BY_HR_HEAD_TO_PAA: 20,
    CONFIRMATION_SENT_BACK_BY_HR_HEAD_TO_RHR: 21,
    CONFIRMATION_FORWARDED_PV_TO_HR_OPS: 22,
    CONFIRMATION_FORWARDED_PV_TO_HR_HEAD_FROM_RHR: 23,
    SENT_BACK_BY_HROPS_L3: 24,
  },
  probationFormStatus: {
    0: 'Pending with P.A.A.',
    1: 'Termination pending with RHR',
    2: 'Termination pending with HR Ops L1',
    3: 'Termination pending with HR Ops L2',
    4: 'Termination pending with HR Head',
    5: 'Closed',
    6: 'Sent back by RHR',
    7: 'Sent back by HR Ops L1',
    8: 'Sent back by HR Ops L2',
    9: 'Sent back by HR Head',
    10: 'Forwarded Post Verification',
    11: 'Forwarded Post Verification',
    12: 'Forwarded Post Verification',
    13: 'Forwarded Post Verification',
    14: 'Forwarded Post Verification',
    15: 'Forwarded Post Verification',
    16: 'Confirmation pending with HR Head',
    17: 'Confirmation pending with HR Head',
    18: 'Confirmation pending with HR Ops',
    19: 'Forwarded Post Verification',
    20: 'Sent back by HR Head',
    21: 'Sent back by HR Head',
    22: 'Forwarded Post Verification',
    23: 'Forwarded Post Verification',
    24: 'Sent back by HR Ops L3'
  },

  loggedInUserGroup: {
    EMPLOYEE: 'Employee',
    HRBP: 'HRBP',
    SUPER_ADMIN: 'Super Admin',
  },
  loggedInUserRole: {
    PAA: 'PAA',
    RHR: 'RHR',
    HROPS_L1: 'HROPS_L1',
    HROPS_L2: 'HROPS_L2',
    HR_HEAD: 'HR_HEAD',
    HROPS_L3: 'HROPS_L3',
   },
   paaDasboardTab: {
    MY_AWAITING_TASK : 0,
    MY_TEAM: 1,
  },
  validRolesInURLParams : {
    rpt:'PAA',
    rhr:'RHR',
    hropsl1:'HROPS_L1',
    hropsl2 : 'HROPS_L2',
    hrhead: 'HR_HEAD',
    hropsl3: 'HROPS_L3',
  },
  userRoleToURLParamsMapping: {
    PAA: 'rpt',
    RHR: 'rhr',
    HROPS_L1: 'hropsl1',
    HROPS_L2: 'hropsl2',
    HR_HEAD: 'hrhead',
    HROPS: 'hrops',
  },
  listingTypeUrlParams: {
    CONFIRMATION_LISTING: 'cnf',
    TERMINATION_LISTING: 'trm',
  },
  applicableDepartmentListForProbation : [
    4, // Primary Sells
    6  // Homeloan
  ],
  probationApplicableGradeList : [1, 2, 3, 4, 5],
  porbationFormTypeBasedOnGrade: {
    type1: ["1", "2", "3"],
    type2: ["4", "5"],
  },
  higherAuthorityReachToIdMapping : {
    PAA: 1,
    RHR: 2,
    HROPS_L1: 3,
    HROPS_L2: 4,
    HR_HEAD: 5,
    HROPS: 6,
  },
};
