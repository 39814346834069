import axios from 'axios'

import { employeeTaggingUrl } from '../../utils/CommonUrl'
import { getAxiosConfig, getRequestHeader } from '../../utils/CommonFunction'

let axiosConfig = getAxiosConfig({ withCredentials:true });
axiosConfig.headers = getRequestHeader()

export const getAllEmployeesTaggingData = (dataObj) => {
    const { GET_ALL_EMPLOYEE_TAGGING_DETAILS } = employeeTaggingUrl
    return axios.get(`${GET_ALL_EMPLOYEE_TAGGING_DETAILS}`)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}
export const getEmpTaggingSummary = (dataObj) => {
    const { GET_ALL_EMP_SUMMARY } = employeeTaggingUrl
    return axios.get(`${GET_ALL_EMP_SUMMARY}`)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}

export const getSkillsListData = (dataObj) => {
    const { GET_SKILLS_LIST } = employeeTaggingUrl
    return axios.get(`${GET_SKILLS_LIST}`)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}

export const getPodsListData = (dataObj) => {
    const { GET_PODS_LIST } = employeeTaggingUrl
    return axios.get(`${GET_PODS_LIST}`)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}



export const updateEmployeeTagging = (dataObj) => {
    const { UPDATE_EMPLOYEE_TAGGING } = employeeTaggingUrl
    return axios.post(`${UPDATE_EMPLOYEE_TAGGING}`, dataObj)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}

export const getLoggedInEmployeeDetailFetchData = () => {
    const { GET_EMPLOYEE_DETAILS_FOR_EMPLOYEE_TAGGING } = employeeTaggingUrl
    return axios.get(`${GET_EMPLOYEE_DETAILS_FOR_EMPLOYEE_TAGGING}`)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}
