import Papa from 'papaparse'
import moment from 'moment'
import { arrayDiff, isEmpty } from '../../../utils/CommonFunction'
const rnrHeader = [
    'date',
    'employeeno',
    'category',
    'type',
    'sub_type',
    'message',
    'city_name',
    'month'
]
const MAX_PROCESS_EMP_CERTIFICATE_RECORD = 100
const categoryList = ['LOL', 'SPOTLIGHT']
const typeList = { SPOTLIGHT:[
                            'Excellence',
                            'Ownership',
                            'Respect',
                            'Boundarylessness',
                            'Innovation',
                            'Trust & Integrity'
                        ],
                    LOL: ['Individual', 'Team Manager', 'City']
                }

export const validateCsvFile = async (file) => {
    try {
        const csvData = await parseFile(file)
        const response = validateCsvData(csvData)
        return response
    } catch (err) {
        throw err
    }
}

export const validateCsvData = (csvData) => {
    let error = []
    let data = []

    if (!csvData.data.length) {
        error.push(`CSV file is empty`)
        return { error, data }
    }

    if (csvData.data.length > (MAX_PROCESS_EMP_CERTIFICATE_RECORD+1)) {
        error.push(`You can process max ${MAX_PROCESS_EMP_CERTIFICATE_RECORD} record(s) per file!`)
        return { error, data }
    }

    const diff = arrayDiff({baseArr:rnrHeader, compArr:Object.keys(csvData.data[0])})
    if (diff.length) {
        error.push(`Invalid file header name(s) - ${diff.join(',')}`)
        return { error, data }
    }
    
    csvData.data.forEach((element, index) => {
        index+=1
        rnrHeader.forEach(key => {
            switch (key) {
                case 'date':
                    if (!moment().isValid(element[key])) {
                        error.push(`Invalid date @row ${index+1}`)
                    }
                    break
                case 'employeeno':
                    if (element[key] === '') {
                        error.push(`Invalid employeeno @row ${index+1}`)
                    }
                    break
                case 'category':
                    if (element[key] === '' || !categoryList.includes(element[key])) {
                        error.push(`Invalid category @row ${index+1}`)
                    }
                    break
                case 'type':
                    if (element[key] === '' || !typeList[element['category']].includes(element[key])) {
                        error.push(`Invalid type @row ${index+1}`)
                    }
                    break
                case 'sub_type':
                    if (element['category'] === 'LOL' && element[key] === '') {
                        error.push(`sub_type can't be blank @row ${index+1}`)
                    }
                    if (element['category'] === 'SPOTLIGHT' && !isEmpty(element[key]) ) {
                        error.push(`sub_type must be blank @row ${index+1}`)
                    }
                    break
                case 'month':
                    if (!moment().isValid(element[key])) {
                        error.push(`Invalid month @row ${index+1}`)
                    }
                    break
                default:
                    break
            }
        })
        data.push(element)
    })

    return { error, data }
}

export const parseFile = (file) => {
    return new Promise((resolve, reject) => {
        return Papa.parse(file, {
            header: true,
            skipEmptyLines: 'greedy',
            complete: function(results) {
               return resolve(results)
            },
            error: function(err) {
                return reject(err)
            }
        })
    })
}