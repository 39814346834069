// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.go-back-btn {
    margin-top: 1%;
    margin-left: 80%;
}

.save-draft-info {
    color: brown;
    font-size: small;
    text-align: center;
    padding-top: 10px;
}

.correctAnswer {
    color: green;
}
.incorrectAnswer {
    color: red;
}
.sd-element__title.sd-element__title--disabled {
    opacity: 1;
}

#webpack-dev-server-client-overlay {
    display: none;
}`, "",{"version":3,"sources":["webpack://./src/pages/Survey/FillSurvey/index.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,gBAAgB;IAChB,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,YAAY;AAChB;AACA;IACI,UAAU;AACd;AACA;IACI,UAAU;AACd;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".go-back-btn {\n    margin-top: 1%;\n    margin-left: 80%;\n}\n\n.save-draft-info {\n    color: brown;\n    font-size: small;\n    text-align: center;\n    padding-top: 10px;\n}\n\n.correctAnswer {\n    color: green;\n}\n.incorrectAnswer {\n    color: red;\n}\n.sd-element__title.sd-element__title--disabled {\n    opacity: 1;\n}\n\n#webpack-dev-server-client-overlay {\n    display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
