import React from 'react'
import Home from '../../../pages/Joiner/Home'

const Dashboard = () => {
    return (
        <Home />
    )
}

export default Dashboard
