import Cookie from 'js-cookie';

const getCookie = (cookieName) => { 
     return Cookie.get(cookieName); 
}

const setCookie = (cookieName, cookieValue) => Cookie.set(cookieName, cookieValue);

const removeCookie = (cookieName) => Cookie.remove(cookieName);

export { getCookie, setCookie, removeCookie }
