import React from 'react'
import Dashboard from '../../../pages/Campaign/Dashboard'


export default function DashboardComponent(props) {
  return (
    <div>
      < Dashboard {...props}/>
    </div>
  )
}
