import React, { useState, useRef } from "react"
import { Grid, Button, TableContainer, Table, TableBody, Paper, TableRow, TableCell } from "@material-ui/core"

import moment from "moment"
import { useStyles } from "./style"

import { getReportHtml, handleDownloadReport } from "../DownloadReport"

import ReportTableHeader from "./ReportTableHeader"
import ReportPagination from "./ReportPagination"
import ReportTableBody from "./ReportTableBody"
import ExitFirstRow from "./ExitFirstRow"

import {
    listingSort,
    getComparator
} from "../../../constants/CommonConstant"


const getPagesCount = ({ pages, totalCount = 0 }) => {
    if (totalCount > 1000) {
        pages.push(totalCount)
    }

    return pages
}


const ReportTable = ({
    rows = [],
    firstRow = "",
    columns = [],
    totalRow = {},
    reportName = "Report",
    goBack = () => {},
    pages = [ 50, 100, 500, 1000 ],
    isCompareTotal = false
}) => {
    const classes = useStyles()
    
    pages = getPagesCount({ pages, totalCount: rows.length })
    const [order, setOrder] = useState()
    const [orderBy, setOrderBy] = useState()

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(pages[page])
    const ref = useRef(null)

    const handleSortRequest = cellId => {
        const isAsc = orderBy === cellId && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(cellId)
    }

    const record = () => {
        return listingSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, (page + 1) * rowsPerPage)
    }

    const handleChangePage = (event, newPage) => {
        ref.current.scrollIntoView()
        setPage(newPage)
    }

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }


    const records = record()
    const recordCount = records.length
    const colCount = columns.length
    const totalDataLen = Object.keys(totalRow).length

    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12} sm={12} md={8}>
                    {firstRow ? <ExitFirstRow firstRow={firstRow} /> : ""}
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                    <Grid style={{ textAlign: "right", padding: "10px" }}>
                        { recordCount && handleDownloadReport ? (
                            <Button style={{ width: "140px", height: "27px", padding: "0 0 0 0", textTransform: "capitalize" }} variant="contained" color="primary" title="Download Report" onClick={(e)=>handleDownloadReport(e, { fileName:`${reportName.replace(" ", "_")}_Report_${moment().format("DDMMYYYYHHmmss")}.xls`, htmlData: getReportHtml({ columns:columns, reportData: rows, totalRow: (totalDataLen ? [totalRow] : []), firstRow }) })} ><span>Download Report</span></Button>
                        ) : (null)
                        }
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={1}>
                    <div style={{ textAlign: "right", padding: "10px" }}>
                        <Button 
                            onClick={() => goBack("Main")}
                            style={{ backgroundColor: '#4559C9', height: "27px", color: "white", padding: "5px", borderRadius: "5px", textTransform: "none" }}>
                        Back</Button> 
                    </div>
                </Grid>
            </Grid>
            <TableContainer style={{ minHeight: "338px", maxHeight: "338px"}} component={Paper}>
                <div ref={ref}>
                <Table stickyHeader aria-label="customized table">
                        
                        <ReportTableHeader 
                            order={order}
                            orderBy={orderBy}
                            columns={columns}
                            handleSortRequest={handleSortRequest}
                            firstRow={firstRow}
                        />
                        <TableBody>
                        {
                            recordCount ? (
                                <React.Fragment>
                                { records.map((rowObj, i) => 
                                    <ReportTableBody 
                                        key={i}
                                        row={rowObj}
                                        idx={i}
                                        columns={columns}
                                        recCount={recordCount}
                                        colCount={colCount}
                                        page={page}
                                        pages={pages}
                                        rowsPerPage={rowsPerPage}
                                    />)
                                }
                                {((recordCount > 1 || isCompareTotal) && totalDataLen) ? <ReportTableBody 
                                    key={recordCount+1}
                                    isTotalRow={true}
                                    row={totalRow}
                                    idx={recordCount+1}
                                    columns={columns}
                                    recCount={recordCount}
                                    colCount={colCount}
                                /> : null}
                                </React.Fragment>
                            ) : (<ReportTableBody
                                row={{}}
                                idx={0}
                                columns={columns}
                                recCount={recordCount}
                                colCount={colCount}
                            />)
                        }
                        </TableBody>
                </Table>
                </div>
            </TableContainer>
            {
                recordCount ? (<ReportPagination
                    page={page}
                    pages={pages}
                    rowsPerPage={rowsPerPage}
                    rows={rows}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                />) : null
            }
        </React.Fragment>
    )
}

export default ReportTable