import React from 'react'
import Grid from '@material-ui/core/Grid'
import { useStyles } from './style'
import image from '../../../components/Header/REA_India_Straight_Logo.png'
import Typography from '@material-ui/core/Typography'

const HeaderLayout = ({
    title='Housing',
    candidateName='Candidate'
}) => {
    const classes = useStyles()
    return (
        <Grid container className={classes.root} spacing={2} direction="column">
            <Grid item container sm={12} className={classes.header}>
                <Grid item container sm={6}>
                    <img src={image} className={classes.logoImage} alt={title} title={title}/>
                </Grid>
                <Grid item container sm={6} alignItems="center" justifyContent="flex-end">
                    <Typography className={classes.candidateName}>Welcome - {candidateName}</Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default HeaderLayout