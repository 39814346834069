import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  cardHeader: {
    backgroundColor: '#FFFFFF',
    width: '100%',
    minHeight: '50px',
    borderRadius: "5px"
  },
  headerText: {
    fontSize: "22px",
    color: '#888888',
    fontFamily: "arial",
    fontWeight: 'normal',
    textDecoration: 'none'
  },
  root: {
    padding: theme.spacing(0)
  },
  cardBody: {
    width: '100%',
    minHeight: "500px",
    backgroundColor: "#F1F1F4",
  },
  filterBody: {
    padding: "10px"
  },
  maiBody: {
    padding: "10px"
  },
  gridBody: {
    padding: "10px"
  },
  mainGridBody: {
      padding: "10px"
  },
  emailCell_1: {
    lineHeight: "0.2",
    width: "50px",
    padding: "0 0 0 50px"
  },
  emailCell_2: {
    lineHeight: "0.2",
    width: "10px"
  },
  emailCell_3: {
    lineHeight: "0.2",
    width: "50px"
  }
}))
