import { makeStyles } from '@material-ui/core/styles'
import blue from "@material-ui/core/colors/blue"

export const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(0),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    paperFileList: {
      padding: theme.spacing(0),
      textAlign: 'left'
    },
    paperGeneral: {
      padding: theme.spacing(1),
      textAlign: 'left',
      color: theme.palette.text.secondary,
      minHeight: '300px',
      fontSize: '12px'
    },
    paperFirst: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    formControl: {
      margin: theme.spacing(3),
      minWidth: 120,
    },
    formControlGender: {
      margin: theme.spacing(3),
      minWidth: 140,
    },
    formControlAccNo: {
      margin: theme.spacing(3),
      minWidth: 180,
    },
    formControlLable: {
      textAlign: 'left',
      margin: theme.spacing(3),
      minWidth: 120,
    },
    formControlMaritalStatus: {
      margin: theme.spacing(3),
      minWidth: 150,
    },
    formControlBankStatus: {
      margin: theme.spacing(3),
      minWidth: 150,
    },
    selectEmpty: {
      width: '120%',
      marginTop: theme.spacing(2),
    },
    formControlSpouseActive: {
      display: ''
    },
    formControlSpouseInactive: {
      display: 'none'
    },
    formControlBankActive: {
      display: ''
    },
    formControlBankInactive: {
      display: 'none'
    },
    input: {
      display: "none"
    },
    button: {
      color: blue[900],
      margin: 10
    },
    icon: {
      margin: 10,
      fontSize: 24,
    },
    tableFiles: {
      width: '20%'
    }
}));
  