import React, { useState,useEffect } from 'react';
import HeaderLayout from '../HeaderLayout'
import PeopleOutlineTwoTone from '@material-ui/icons/PeopleOutlineTwoTone'
import {Button, Tab, Tabs }from '@material-ui/core';
import { useStyles } from './style'
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography} from '@material-ui/core'
import { getLoggedInUserDetailsInProbation, getProbationRecords } from '../fetchData';

import { probationConstant } from '../../../constants/ProbationConstant';
import logo from '../../../utils/load.gif'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import Filter from '../Filter';
import { getProbationFormAccessByUserRole } from '../common';
import qs from "qs";
import moment from 'moment';
import { useLocation } from 'react-router-dom'
import { pageURLConfig, MANAGER_USER_ROLE, menuSubmenuPageCategory } from '../../../constants/CommonConstant';
import { getMenuSubmenuRightsDetail } from '../../../utils/authFunction';
const { moduleName, controllerName, actionName } = pageURLConfig.PROBATION_INDEX_PENDING_DUE_LIST
const { NON_ADMIN_CATEGORY } = menuSubmenuPageCategory


const INITIAL_FILTER_OBJECT = {
  empName: '',
  empNo: '',
  reportingManager: '',
  department: null,
  subDepartment: null,
  grade: null,
  designation: null,
  location: null,
  dojStartDate: null,
  dojEndDate: null,
}
const dashBoardTab = {
  MY_AWAITING_TASK : 0,
  MY_TEAM: 1,
}
const EMPTY_URL_ROLE_PARAMS = '/r/'
const DATE_FORMAT = 'DD-MMM-YYYY'
const { loggedInUserRole, formStatusKeyToIdMapping, probationFormAccess, probationActionInForm, listingTypeUrlParams,userRoleToURLParamsMapping } = probationConstant;
const {CONFIRMATION_LISTING, TERMINATION_LISTING} = listingTypeUrlParams
const { PAA, RHR, HROPS_L1, HROPS_L2, HR_HEAD, HROPS_L3} = loggedInUserRole;
const {
  PENDING_WITH_PAA,
  PENDING_WITH_RHR,
  PENDING_WITH_HROPS_L1,
  PENDING_WITH_HROPS_L2,
  PENDING_WITH_HR_HEAD,
  CLOSED,
  SENT_BACK_BY_RHR,
  SENT_BACK_BY_HROPS_L1,
  SENT_BACK_BY_HROPS_L2,
  SENT_BACK_BY_HR_HEAD ,
  FORWARDED_PV_TO_HROPS_L1 ,
  FORWARDED_PV_TO_HROPS_L2 ,
  FORWARDED_PV_TO_HR_HEAD ,
  FORWARDED_PV_TO_HROPS_L2_AT_HROPS_L1 ,
  FORWARDED_PV_TO_HR_HEAD_AT_HROPS_L1 ,
  FORWARDED_PV_TO_HR_HEAD_AT_HROPS_L2 ,
  CONFIRMATION_FWD_TO_HR_HEAD_FROM_PAA,
  CONFIRMATION_FWD_TO_HR_HEAD_FROM_RHR,
  CONFIRMATION_FWD_TO_HROPS_FROM_HR_HEAD,
  CONFIRMATION_FORWARDED_PV_TO_HR_HEAD_FROM_PAA,
  CONFIRMATION_SENT_BACK_BY_HR_HEAD_TO_PAA,
  CONFIRMATION_SENT_BACK_BY_HR_HEAD_TO_RHR,
  CONFIRMATION_FORWARDED_PV_TO_HR_OPS,
  CONFIRMATION_FORWARDED_PV_TO_HR_HEAD_FROM_RHR,
  SENT_BACK_BY_HROPS_L3
} = formStatusKeyToIdMapping;

export default function PendingDueList(props) {
    const classes = useStyles()

    const lObj = useLocation()
    const query = new URLSearchParams(lObj.search)
    const empId = query.get("empId") || null
    
    const [tabIndex, setTabIndex] = React.useState(0);
    const [isSearchFilterVisible, setIsSearchFilterVisible] = useState(false);
    const [pendingDueList, setPendingDueList] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filterObj, setFilterObj] = useState(INITIAL_FILTER_OBJECT)
    const [loggedInUser, setloggedInUser] = useState({empNo: null, role: null})
    const [roleInURLParams, setRoleInURLParams] = useState(EMPTY_URL_ROLE_PARAMS);
    const [pendingListingTypeParams, setPendingListingTypeParams]  = useState(null);
    const [pageTitle, setPageTitle] = useState('Pending Probation Due List');
    const [isClosedFormList, setClosedFormList] = useState(false);
    const [isHavingPageAccess, setIsHavingPageAccess] = useState(false);
    const [isHavingPageAddAccess, setIsHavingPageAddAccess] = useState(false)
    const [isHavingPageEditAccess, setIsHavingPageEditAccess] = useState(false)
    const [isHavingPageViewAccess, setIsHavingPageViewAccess] = useState(null)

    useEffect( () => {
      setPageRights()
    },[])

    const setPageRights = async () => {
      const params = { pageCategory: NON_ADMIN_CATEGORY, moduleName, controllerName, actionName, userGroupId:MANAGER_USER_ROLE}
      const rightsObj = await getMenuSubmenuRightsDetail(params)
      const { 
          isUserSuperAdmin,
          isUserGroupAdmin,
          reporteeEditRights,
          reporteeAddRights, 
          reporteeViewRights 
     } = rightsObj
     const addAccess = isUserSuperAdmin ||  (reporteeAddRights) 
     const editAccess =  isUserSuperAdmin || reporteeEditRights
     const viewAccess =  isUserSuperAdmin || (reporteeViewRights)
      
      setIsHavingPageAddAccess(addAccess)
      setIsHavingPageEditAccess(editAccess)
      setIsHavingPageViewAccess(viewAccess)
    }

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
    const handleTabChange = (event, newValue) => {
      if(newValue == 0) {
        setIsSearchFilterVisible(false);
      }
      setTabIndex(newValue);
    };
    const handleFilterVisibility = () => {
      setIsSearchFilterVisible(!isSearchFilterVisible);
    }

    const handleListingData = ( filterObj ) => {
      const dojStartDate = filterObj.dojStartDate
      const dojEndDate = filterObj.dojEndDate
      let newFilterObj = filterObj
      newFilterObj.dojStartDate = dojStartDate ? moment(dojStartDate).format("YYYY-MM-DD") : null
      newFilterObj.dojEndDate = dojEndDate ? moment(dojEndDate).format("YYYY-MM-DD") : null
      getProbationPendingListRecords(loggedInUser, newFilterObj)
    }

    const getValidFormStatusListInTab = (loggedInUserDetail, listingType, empId) => {
      let roleWiseFilter = {};
      let formStatusList = [];
      switch (loggedInUserDetail.role) {
        case PAA:
          if(tabIndex == dashBoardTab.MY_AWAITING_TASK) {
            formStatusList =
            [
                PENDING_WITH_PAA,
                SENT_BACK_BY_RHR, 
                CONFIRMATION_SENT_BACK_BY_HR_HEAD_TO_PAA
              ] 
          } else {
            formStatusList = Object.keys(probationConstant.probationFormStatus).filter(status => {
              if(
                status == PENDING_WITH_PAA || 
                status == SENT_BACK_BY_RHR || 
                status == CONFIRMATION_SENT_BACK_BY_HR_HEAD_TO_PAA || 
                status == CLOSED
              ) {
                return false;
              }
              return true;
            })
          }
          roleWiseFilter={
            formStatusArr: formStatusList,
            empNo: loggedInUserDetail.empNo
          }
          break;
        case RHR:
           formStatusList = [
            PENDING_WITH_RHR,
            SENT_BACK_BY_HROPS_L1, 
            SENT_BACK_BY_HROPS_L2, 
            SENT_BACK_BY_HR_HEAD,
            CONFIRMATION_SENT_BACK_BY_HR_HEAD_TO_RHR
          ];
          
          roleWiseFilter = {
            formStatusArr: formStatusList,
            empNo: loggedInUserDetail.empNo 
          }
          break;
        case HROPS_L1:
          if(listingType == CONFIRMATION_LISTING) {
            formStatusList =
            [
              CONFIRMATION_FWD_TO_HROPS_FROM_HR_HEAD,
              CONFIRMATION_FORWARDED_PV_TO_HR_OPS
            ]
          } else {
            formStatusList =
            [
              PENDING_WITH_HROPS_L1,
              FORWARDED_PV_TO_HROPS_L1,
              FORWARDED_PV_TO_HROPS_L2_AT_HROPS_L1,
              FORWARDED_PV_TO_HR_HEAD_AT_HROPS_L1,
            ]
          }
          roleWiseFilter = {
            formStatusArr: formStatusList,
          }
          break;
        case HROPS_L2:
          if(listingType == CONFIRMATION_LISTING) {
            formStatusList = [
              CONFIRMATION_FWD_TO_HROPS_FROM_HR_HEAD,
              CONFIRMATION_FORWARDED_PV_TO_HR_OPS
            ]
          } else {
            formStatusList = [
              PENDING_WITH_HROPS_L2,
              FORWARDED_PV_TO_HROPS_L2,
              FORWARDED_PV_TO_HR_HEAD_AT_HROPS_L2,
              SENT_BACK_BY_HROPS_L3
            ]
          }
          roleWiseFilter = {
            formStatusArr: formStatusList,
          }
          break;
        case HR_HEAD:
          if(listingType == CONFIRMATION_LISTING) {
            formStatusList = [
              CONFIRMATION_FWD_TO_HR_HEAD_FROM_PAA,
              CONFIRMATION_FWD_TO_HR_HEAD_FROM_RHR,
              CONFIRMATION_FORWARDED_PV_TO_HR_HEAD_FROM_PAA,
              CONFIRMATION_FORWARDED_PV_TO_HR_HEAD_FROM_RHR,
            ]
          } else {
            formStatusList = [
              PENDING_WITH_HR_HEAD,
              FORWARDED_PV_TO_HR_HEAD, 
            ]
          }
          roleWiseFilter = {
            formStatusArr: formStatusList,
          }
          break;
        case HROPS_L3:
          formStatusList = [CLOSED]
          roleWiseFilter = {
            formStatusArr: formStatusList,
            probationAction: 'terminated'
          }
          break;
      }

      return roleWiseFilter;
    }

    
    const getProbationPendingListRecords = async (
      loggedInUserDetail, initialFilterObj, pendingListingType=null
      ) => {
      try {
        setIsLoading(true)
        const listingType = pendingListingType ? pendingListingType : pendingListingTypeParams
        const roleWiseFilter = getValidFormStatusListInTab(loggedInUserDetail, listingType, empId);
        let obj = {...initialFilterObj, role: loggedInUserDetail.role, roleWiseFilter,}
        if (empId) {
          obj.empId = empId
        }
        let response = await getProbationRecords(obj)
        if(response){
          response.forEach(record => {
            record.formAccessType = getProbationFormAccessByUserRole(loggedInUserDetail.role, record.probationFormStatus)
            record.doj = moment(record.doj).format(DATE_FORMAT);
            record.groupDoj = moment(record.groupDoj).format(DATE_FORMAT);
            record.probationDueDate = moment(record.probationDueDate).format(DATE_FORMAT);
            record.confirmationDate = record.confirmationDate ? moment(record.confirmationDate).format(DATE_FORMAT) : ''
          });
          setPendingDueList(response)
        }
        setPage(0);
        setIsLoading(false)
      } catch (error) {
        console.log(error)
        setIsLoading(false)
      }
    }

    const getActionLink = (empNo, probationFormId, tabIndex=0) => {
      let actionLink = '';
      if(loggedInUser.role == PAA) {
        actionLink = `${process.env.REACT_APP_BASE_URL}/probation/index/pending-due-list${roleInURLParams}?empNo=${btoa(empNo)}&id=${probationFormId ? btoa(probationFormId) : ''}&tab=${tabIndex}`
      } else {
        if([HROPS_L1, HROPS_L2, HR_HEAD].includes(loggedInUser.role)) {
          let urlParams = userRoleToURLParamsMapping[loggedInUser.role]
          if(loggedInUser.role == HR_HEAD) {
            if(pendingListingTypeParams == CONFIRMATION_LISTING) {
              actionLink = `${process.env.REACT_APP_BASE_URL}/probation/index/pending-confirmation-letter/r/${urlParams}?empNo=${btoa(empNo)}&id=${probationFormId ? btoa(probationFormId) : ''}&type=${pendingListingTypeParams ? btoa(pendingListingTypeParams) : ''}`
            } else {
              actionLink = `${process.env.REACT_APP_BASE_URL}/probation/index/pending-termination-letter/r/${urlParams}?empNo=${btoa(empNo)}&id=${probationFormId ? btoa(probationFormId) : ''}&type=${pendingListingTypeParams ? btoa(pendingListingTypeParams) : ''}`
            }
            
          } else {
            if(pendingListingTypeParams == CONFIRMATION_LISTING) {
              actionLink = `${process.env.REACT_APP_BASE_URL}/probation/index/pending-confirmation-letter/r/${urlParams}?empNo=${btoa(empNo)}&id=${probationFormId ? btoa(probationFormId) : ''}&type=${pendingListingTypeParams ? btoa(pendingListingTypeParams) : ''}`
            } else {
              actionLink = `${process.env.REACT_APP_BASE_URL}/probation/index/pending-termination-letter/r/${urlParams}?empNo=${btoa(empNo)}&id=${probationFormId ? btoa(probationFormId) : ''}&type=${pendingListingTypeParams ? btoa(pendingListingTypeParams) : ''}`
            }
          } 
        } else if (loggedInUser.role == HROPS_L3){
          actionLink = `${process.env.REACT_APP_BASE_URL}/probation/index/closed-form-list/r/hropsl3?empNo=${btoa(empNo)}&id=${probationFormId ? btoa(probationFormId) : ''}`
        } else {
          actionLink = `${process.env.REACT_APP_BASE_URL}/probation/index/pending-due-list-hrbp${roleInURLParams}?empNo=${btoa(empNo)}&id=${probationFormId ? btoa(probationFormId) : ''}`
        }
      }
      window.parent.postMessage(`${actionLink}`, process.env.REACT_APP_BASE_URL) 
    }

    useEffect(() => { 
      if(loggedInUser.role) {
        const fetchListDateOnChanginTab = async () => {
          await getProbationPendingListRecords(loggedInUser, filterObj)
        }
        fetchListDateOnChanginTab();
      }
    }, [tabIndex]);

    useEffect(() => {
      setIsLoading(true);
      const fetchData = async () => {
        try {
          let roleParams = null;
          let loggedInUserDetails = {};
          let isPAADashboard = false;
          let filterObj = INITIAL_FILTER_OBJECT;
          let tabIndex = 0;
          let pendingListingType = null;
          let pageTitle = 'Pending Probation Due List'
          let isClosedFormList = false;
          let isHavingPageAccess = false;
          const { validRolesInURLParams } = probationConstant;
          const {tab, type} = qs.parse(props.location.search, { ignoreQueryPrefix: true });
          const path = props && props.location && props.location.pathname;
          const pageName = props && props.computedMatch && props.computedMatch.params && props.computedMatch.params.type

          const userData = await getLoggedInUserDetailsInProbation();
          if(userData && userData.empNo && userData.userRole) {
            loggedInUserDetails = {empNo: userData.empNo, role: userData.userRole, hrbpDetails: userData.hrbpDetails}
            isPAADashboard = userData.userRole == PAA ? true : false;
            if(isPAADashboard) {
              tabIndex = (tab && !isNaN(parseInt(tab, 10))) ? parseInt(tab, 10) : 0;
              filterObj = {
                empName: '',
                dojStartDate: null,
                dojEndDate: null,
              }
            }
            if([HROPS_L1, HROPS_L2, HR_HEAD].includes(loggedInUserDetails.role) && 
              [CONFIRMATION_LISTING, TERMINATION_LISTING].includes(type)
            ) {
              pendingListingType = type
              pageTitle = (type == CONFIRMATION_LISTING) ? 'Pending Confirmation List' : 'Pending Termination List';
            } else if(loggedInUserDetails.role == HROPS_L3) {
              isClosedFormList = true
              pageTitle = 'Employee Termination List'
            }
            const pathSplit = path.split('/');
            if(pathSplit.length >=4  && pathSplit[pathSplit.length - 2] == 'r' && Object.keys(validRolesInURLParams).includes(pathSplit[pathSplit.length - 1])) {
              const roleInParams = pathSplit[pathSplit.length - 1]
              if(validRolesInURLParams[roleInParams] == loggedInUserDetails.role) {
                roleParams = `/r/${pathSplit[pathSplit.length - 1]}`;
                if(
                    (loggedInUserDetails.role == PAA && pageName == 'pending-due-list')
                    ||  (loggedInUserDetails.role == RHR && pageName == 'pending-due-list-hrbp')
                    || ([HROPS_L1, HROPS_L2, HR_HEAD].includes(loggedInUserDetails.role) && ['pending-confirmation-letter', , 'pending-termination-letter'].includes(pageName))
                    || (loggedInUserDetails.role == HROPS_L3 && pageName == 'closed-form-list')
                  ) {
                  isHavingPageAccess = true
                }
              }
            }
            await getProbationPendingListRecords(loggedInUserDetails, filterObj, pendingListingType);
          }  
          setloggedInUser(loggedInUserDetails);
          setRoleInURLParams(roleParams) 
          setFilterObj(filterObj);
          setTabIndex(tabIndex);
          setPendingListingTypeParams(pendingListingType)
          setPageTitle(pageTitle);
          setClosedFormList(isClosedFormList)
          setIsHavingPageAccess(isHavingPageAccess)
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
        }
      }
      
      fetchData();
    }, []);

    const HeaderComponent = (
      <Grid container spacing={2} alignItems="center">
        <Grid item> 
          <HeaderLayout
              title={pageTitle}
              subTitle=''
              icon={<PeopleOutlineTwoTone/>}
          />
        </Grid>
      </Grid>
    );
    
    if((!isHavingPageAccess && !isLoading) || !isHavingPageViewAccess) {
      return <div className={classes.root}>
          {HeaderComponent}
          <Grid item xs={12} style={{width: '90%'}}>
            <Typography variant="h6" align="center" style={{color: 'red', textAlign: 'center'}}>
              You are not authorized to view this page
            </Typography>
          </Grid> 
      </div>
    } else {
      if(loggedInUser.role == probationConstant.loggedInUserRole.PAA) { // PAA dashboard
        return <div className={classes.root}>
                {HeaderComponent}
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={10}>
                    <Tabs value={tabIndex} onChange={handleTabChange}>
                      <Tab label="My Awaiting Task" classes={classes.tab} />
                      <Tab label="My Team" classes={classes.tab} />
                    </Tabs>
                  </Grid>
                  {tabIndex ? <Grid item xs={2}>
                      <FontAwesomeIcon className={classes.filterDiv} icon={faFilter} size="lg" onClick={handleFilterVisibility} />&nbsp;Filter
                  </Grid> : (null)} 
                </Grid>
                  {isSearchFilterVisible ? 
                    <Filter
                      loggedInUser={loggedInUser}
                      sendFilterObj={handleListingData}
                    />
                    : (null)
                  }
                  <Paper elevation={4}>
                    { (isLoading )
                      ? 
                      <div className={classes.loader}><img src={logo} alt="loading..." /></div>
                      : 
                      <div>
                        <div style={{ overflow: 'auto'}}>
                          <TableContainer component={Paper}>
                              <Table>
                                  <TableHead>
                                      <TableRow>
                                          <TableCell className={classes.headerCell} style={{paddingLeft: '15px'}}>Action</TableCell>
                                          <TableCell className={classes.headerCell} style={{minWidth: '125px'}}>Employee</TableCell>
                                          <TableCell className={classes.headerCell} >Status</TableCell>
                                          <TableCell className={classes.headerCell} style={{minWidth: '90px'}} >Group DOJ</TableCell>
                                          <TableCell className={classes.headerCell} >Designation</TableCell>
                                          <TableCell className={classes.headerCell} >Grade</TableCell>
                                          <TableCell className={classes.headerCell} >Department</TableCell>
                                          <TableCell className={classes.headerCell} >Sub Department</TableCell>
                                          <TableCell className={classes.headerCell} style={{minWidth: '125px'}}>Reporting Manager Name</TableCell>
                                          <TableCell className={classes.headerCell} style={{minWidth: '90px'}}>Probation Due Date</TableCell>
                                          <TableCell className={classes.headerCell} style={{minWidth: '90px'}}>Probation Form Status</TableCell>
                                      </TableRow>
                                  </TableHead>
                                  <TableBody>
                                  { ( pendingDueList && pendingDueList.length > 0 ) ? pendingDueList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((element,key)=>(
                                      <TableRow key={key}>
                                          <TableCell className={classes.headerBodyCell}  style={{paddingLeft: '15px'}}>
                                          {isHavingPageEditAccess ? 
                                            <Button
                                              onClick={e => getActionLink(element.employeeno, element.probationFormId, tabIndex)}
                                              variant="text"
                                              style={{ color: 'blue', textTransform:'none' }}
                                            >
                                              {probationConstant.formAccessMapping[element.formAccessType]}
                                            </Button>
                                            : null }
                                            
                                          </TableCell>
                                          <TableCell className={classes.headerBodyCell}  >{element.legalname}&nbsp;({element.employeeno})</TableCell>
                                          <TableCell className={classes.headerBodyCell} >{element.employeeStatus}</TableCell>
                                          <TableCell className={classes.headerBodyCell}>{element.groupDoj}</TableCell>
                                          <TableCell className={classes.headerBodyCell} >{element.designation}</TableCell>
                                          <TableCell className={classes.headerBodyCell} >{element.grade}</TableCell>
                                          <TableCell className={classes.headerBodyCell} >{element.department}</TableCell>
                                          <TableCell className={classes.headerBodyCell} >{element.subDepartment}</TableCell>
                                          <TableCell className={classes.headerBodyCell} >{element.reportingManagerName}</TableCell>
                                          <TableCell className={classes.headerBodyCell} >{element.probationDueDate}</TableCell>
                                          <TableCell className={classes.headerBodyCell} >{element.probationFormStatusLabel}</TableCell>
                                      </TableRow>
                                  )) : (
                                      <TableRow>
                                        <TableCell style={{ color: 'red', textAlign: 'center'}} colSpan={3}>No records found</TableCell>
                                      </TableRow>
                                  ) }
                                  </TableBody>
                              </Table>
                          </TableContainer>
                        </div>
                        <div>
                          <TablePagination
                            rowsPerPageOptions={[10, 25, 50, 100]}
                            component="div"
                            count={pendingDueList.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </div> 
                      </div>
                    }
                  </Paper>
            </div>
      } else {
        if(loggedInUser.role != loggedInUserRole.HROPS_L3) {
          return <div className={classes.root}>
          {HeaderComponent}
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={10}>
              <Tabs value={tabIndex} onChange={handleTabChange}>
                <Tab label="My Awaiting Task" classes={classes.tab} />
              </Tabs>
            </Grid>
            <Grid item xs={2}>
              <FontAwesomeIcon className={classes.filterDiv} icon={faFilter} size="lg" onClick={handleFilterVisibility} />&nbsp;Filter
             </Grid>
            </Grid>
              {isSearchFilterVisible ?
                <Filter
                  loggedInUser={loggedInUser}
                  sendFilterObj={handleListingData}
                />
                : (null)
              }
              <Paper elevation={4}>
                { (isLoading )
                  ? 
                  <div className={classes.loader}><img src={logo} alt="loading..." /></div>
                  : 
                  <div>
                    <div style={{ overflow: 'auto'}}>
                      <TableContainer component={Paper}>
                          <Table>
                              <TableHead>
                                  <TableRow>
                                      <TableCell className={classes.headerCell} style={{paddingLeft: '15px'}}>Action
                                      </TableCell>
                                      <TableCell className={classes.headerCell} style={{minWidth: '125px'}}>Employee</TableCell>
                                      <TableCell className={classes.headerCell} >Status</TableCell>
                                      <TableCell className={classes.headerCell} style={{minWidth: '90px'}} >Group DOJ</TableCell>
                                      <TableCell className={classes.headerCell} >Designation</TableCell>
                                      <TableCell className={classes.headerCell} >Grade</TableCell>
                                      <TableCell className={classes.headerCell} >Department</TableCell>
                                      <TableCell className={classes.headerCell} >Sub Department</TableCell>
                                      <TableCell className={classes.headerCell} style={{minWidth: '125px'}}>P.A.A.</TableCell>
                                      <TableCell className={classes.headerCell} style={{minWidth: '125px'}}>Reporting Manager</TableCell>
                                      <TableCell className={classes.headerCell} style={{minWidth: '90px'}}>Probation Due Date</TableCell>
                                      <TableCell className={classes.headerCell} style={{minWidth: '90px'}}>Probation Form Status</TableCell>
                                  </TableRow>
                              </TableHead>
                              <TableBody>
                              { ( pendingDueList && pendingDueList.length > 0 ) ? pendingDueList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((element,key)=>(
                                  <TableRow key={key}>
                                      <TableCell className={classes.headerBodyCell} style={{paddingLeft: '15px'}}>
                                        <Button
                                          onClick={e => getActionLink(element.employeeno, element.probationFormId)}
                                          variant="text"
                                          style={{ color: 'blue', textTransform:'none' }}
                                        >
                                          {probationConstant.formAccessMapping[element.formAccessType]}
                                        </Button>
                                      </TableCell>
                                      <TableCell className={classes.headerBodyCell}  >{element.legalname}&nbsp;({element.employeeno})</TableCell>
                                      <TableCell className={classes.headerBodyCell} >{element.employeeStatus}</TableCell>
                                      <TableCell className={classes.headerBodyCell} >{element.groupDoj}</TableCell>
                                      <TableCell className={classes.headerBodyCell} >{element.designation}</TableCell>
                                      <TableCell className={classes.headerBodyCell} >{element.grade}</TableCell>
                                      <TableCell className={classes.headerBodyCell} >{element.department}</TableCell>
                                      <TableCell className={classes.headerBodyCell} >{element.subDepartment}</TableCell>
                                      <TableCell className={classes.headerBodyCell} >{element.paaName ? (element.paaName) : ''}&nbsp;{(element.paaEmpId) ? `(${element.paaEmpId})` : ''}</TableCell>
                                      <TableCell className={classes.headerBodyCell} >{element.reportingManagerName}&nbsp;({element.reportinManagerEmpId})</TableCell>
                                      <TableCell className={classes.headerBodyCell} >{element.probationDueDate}</TableCell>
                                      <TableCell className={classes.headerBodyCell} >{element.probationFormStatusLabel}</TableCell>
                                  </TableRow>
                              )) : (
                                  <TableRow>
                                  <TableCell style={{ color: 'red', textAlign: 'center'}} colSpan={3}>No records found</TableCell>
                                  </TableRow>
                              ) } 
                              </TableBody>
                          </Table>
                      </TableContainer>
                    </div>
                    <div>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        component="div"
                        count={pendingDueList.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </div>
                  </div>
                }
              </Paper>
        </div>
        } else { // HR Ops l3
          return <div className={classes.root}>
          {HeaderComponent}
          <Grid container spacing={2} alignItems="center" style={{ justifyContent: 'right', paddingBottom: 5 }}>
            
            <Grid item xs={2}>
              <FontAwesomeIcon className={classes.filterDiv} icon={faFilter} size="lg" onClick={handleFilterVisibility} />&nbsp;Filter
             </Grid>
            </Grid>
              {isSearchFilterVisible ? 
                <Filter
                  loggedInUser={loggedInUser}
                  sendFilterObj={handleListingData}
                />
                : (null)
              }
              <Paper elevation={4}>
                { (isLoading ) 
                  ? 
                  <div className={classes.loader}><img src={logo} alt="loading..." /></div>
                  : 
                  <div>
                    <div style={{ overflow: 'auto'}}>
                      <TableContainer component={Paper}>
                          <Table>
                              <TableHead>
                                  <TableRow>
                                      <TableCell className={classes.headerCell} style={{minWidth: '90px', paddingLeft: '15px'}}>Action
                                      </TableCell>
                                      <TableCell className={classes.headerCell} style={{minWidth: '90px'}}>Termination Date</TableCell>
                                      <TableCell className={classes.headerCell} style={{minWidth: '125px'}}>Employee</TableCell>
                                      <TableCell className={classes.headerCell} >Status</TableCell>
                                      <TableCell className={classes.headerCell} style={{minWidth: '90px'}} >Group DOJ</TableCell>
                                      <TableCell className={classes.headerCell} >Designation</TableCell>
                                      <TableCell className={classes.headerCell} >Grade</TableCell>
                                      <TableCell className={classes.headerCell} >Department</TableCell>
                                      <TableCell className={classes.headerCell} >Sub Department</TableCell>
                                      <TableCell className={classes.headerCell} style={{minWidth: '125px'}}>P.A.A.</TableCell>
                                      <TableCell className={classes.headerCell} style={{minWidth: '125px'}}>Reporting Manager</TableCell>
                                      <TableCell className={classes.headerCell} style={{minWidth: '90px'}}>Probation Due Date</TableCell>
                                  </TableRow>
                              </TableHead>
                              <TableBody>
                              { ( pendingDueList && pendingDueList.length > 0 ) ? pendingDueList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((element,key)=>(
                                  <TableRow key={key}>
                                      <TableCell className={classes.headerBodyCell} style={{paddingLeft: '15px'}}>
                                        <Button
                                          onClick={e => getActionLink(element.employeeno, element.probationFormId)}
                                          variant="text"
                                          style={{ color: 'blue', textTransform:'none' }}
                                        >
                                          Send Back
                                        </Button>
                                      </TableCell>
                                      <TableCell className={classes.headerBodyCell} >{element.confirmationDate}</TableCell>
                                      <TableCell className={classes.headerBodyCell}  >{element.legalname}&nbsp;({element.employeeno})</TableCell>
                                      <TableCell className={classes.headerBodyCell} >{element.employeeStatus}</TableCell>
                                      <TableCell className={classes.headerBodyCell} >{element.groupDoj}</TableCell>
                                      <TableCell className={classes.headerBodyCell} >{element.designation}</TableCell>
                                      <TableCell className={classes.headerBodyCell} >{element.grade}</TableCell>
                                      <TableCell className={classes.headerBodyCell} >{element.department}</TableCell>
                                      <TableCell className={classes.headerBodyCell} >{element.subDepartment}</TableCell>
                                      <TableCell className={classes.headerBodyCell} >{element.paaName ? (element.paaName) : ''}&nbsp;{(element.paaEmpId) ? `(${element.paaEmpId})` : ''}</TableCell>
                                      <TableCell className={classes.headerBodyCell} >{element.reportingManagerName}&nbsp;({element.reportinManagerEmpId})</TableCell>
                                      <TableCell className={classes.headerBodyCell} >{element.probationDueDate}</TableCell>
                                  </TableRow>
                              )) : (
                                  <TableRow>
                                  <TableCell style={{ color: 'red', textAlign: 'center'}} colSpan={3}>No records found</TableCell>
                                  </TableRow>
                              ) } 
                              </TableBody>
                          </Table>
                      </TableContainer>
                    </div>
                    <div>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        component="div"
                        count={pendingDueList.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </div> 
                  </div>
                }
              </Paper>
            </div>
        }
      } 
    }
    
 }
