import React from 'react'
import { Tooltip } from "@material-ui/core"

const ProgressBar = ({ foreGroundColor, backgroundColor, progress, height, titleValue = "" }) => {
    
    const Parentdiv = {
        height: height,
        width: '100%',
        backgroundColor: backgroundColor,
        borderRadius: 40,
    }
    
    const Childdiv = {
        height: '100%',
        width: `${progress}%`,
        backgroundColor: foreGroundColor,
        borderRadius:40,
        textAlign: 'right'
    }
    
    const progresstext = {
        padding: 10,
        color: 'black',
        fontWeight: 900
    }
    
    return (
        <Tooltip title={titleValue} arrow>
            <div style={Parentdiv}>
                <div style={Childdiv}>
                    <span style={progresstext}></span>
                </div>
            </div>
        </Tooltip>
    )
}

export default ProgressBar