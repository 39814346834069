import React, { useEffect, forwardRef } from 'react';
import './Nominee.css';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { loadCSS } from 'fg-loadcss';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import nomineeConstant from '../../constants/NomineeConstant';
import NomineeRecord from '../Nominee/NomineeRecord';
import PermissionRow from '../Nominee/PermissionRow';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import SaveAltSharpIcon from '@material-ui/icons/SaveAltSharp';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';

const Transition =  forwardRef(function Transition(props, ref) {
                         return <Slide direction="up" ref={ref} {...props} />;
                    });

const useStyles = makeStyles({
                    table: {
                         minWidth: 700,
                    },
               });

const NomineeDetailsTable = (props) => {
     const { 
          nomineeDetails, 
          isEditable, 
          switchView, 
          isSwitchEnabled, 
          handleDeleteClick,
          handlePermissionSwitch,
          openDeleteDialog,
          handleDeleteDialogClose,
          policyDocPath,
          finalSubmitEnable,
          handleFinalSubmit,
          openFinalSubmitDialog,
          handleFinalSubmitClose,
          confirmFinalDialogue,
          agreeBtnDisable,
          handleAgreeButton,
          confirmDeleteDialogue,
          declarationMessage,
          pageLoader,
          isHavingSelfViewAccess,
          isHavingSelfAddAccess,
          isHavingSelfEditAccess
     } = props;
     const { fontawesomeCssPath } = nomineeConstant;
     useEffect(() => {
          loadCSS(
               fontawesomeCssPath,
               document.querySelector('#font-awesome-css'),
          );
     }, []);

     const classes = useStyles();
     return (
          <div className={'container'}>
               <ToastContainer 
                    transition={Zoom}
               />
               <h3 className="header-text">
                    <center>Nominee Details</center>
                    {
                         policyDocPath ? (
                              <div className="nominee-policy-doc" title="Group Term Life and Personal Accident Insurance Benefit Document">
                                   <a target="_blank" href={policyDocPath} download="Group Term Life and Personal Accident Insurance Policy.pdf">
                                        <PictureAsPdfIcon></PictureAsPdfIcon>
                                   </a>
                              </div>
                         ) : (null)
                    }
               </h3>
               <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                         <TableHead>
                              <TableRow>
                                   <TableCell align="center" className="table-head">#</TableCell>
                                   <TableCell align="center" className="table-head">Name</TableCell>
                                   <TableCell align="center" className="table-head">Relation</TableCell>
                                   <TableCell align="center" className="table-head">Date Of Birth</TableCell>
                                   <TableCell align="center" className="table-head">Address</TableCell>
                                   <TableCell align="center" className="table-head">Contact No.</TableCell>
                                   <TableCell align="center" className="table-head">Share %</TableCell>
                                   <TableCell align="left" colSpan="2" className="table-head">Guardian Detail<br/>(if any)
                                   </TableCell>
                                   <TableCell align="center" className="table-head"></TableCell>
                              </TableRow>
                         </TableHead>
                         <TableBody>
                              {
                                   nomineeDetails.length ? (
                                        nomineeDetails.map((row, k) => 
                                             <NomineeRecord 
                                                  row={row} 
                                                  serialNo={k+1} 
                                                  handleDeleteClick={handleDeleteClick}
                                                  isEditable={isEditable}
                                                  isDeleteIconVisible={isHavingSelfEditAccess}
                                                  key={k+1}
                                             />)
                                   ) : (
                                        pageLoader ? (
                                             <TableRow>
                                                  <TableCell colSpan="10" align="center">
                                                       <Fade
                                                            in={pageLoader}
                                                            style={{
                                                                 transitionDelay: '0ms',
                                                            }}
                                                            unmountOnExit
                                                            >
                                                            <CircularProgress />
                                                       </Fade>
                                                       <Typography>Loading...</Typography>
                                                  </TableCell>
                                             </TableRow>
                                        ) : (
                                             <TableRow>
                                                  <TableCell colSpan={10} align="center">
                                                       No record found!
                                                  </TableCell>
                                             </TableRow>
                                        )
                                        
                                   )
                              }
                              {
                                   (finalSubmitEnable) ? (
                                        <TableRow>
                                             <TableCell align="center" colSpan="10">
                                                  <Button 
                                                       variant="contained"
                                                       color="secondary"
                                                       size="small"
                                                       className={classes.button}
                                                       startIcon={<SaveAltSharpIcon />}
                                                       type="button"
                                                       onClick={handleFinalSubmit}
                                                       disabled={!(isHavingSelfAddAccess || isHavingSelfEditAccess)}
                                                       >
                                                       Submit Details
                                                  </Button>
                                             </TableCell>
                                             <Dialog
                                                  open={openFinalSubmitDialog}
                                                  TransitionComponent={Transition}
                                                  keepMounted
                                                  onClose={() => handleFinalSubmitClose(false)} 
                                                  aria-labelledby="alert-dialog-slide-title-final"
                                                  aria-describedby="alert-dialog-slide-description"
                                             >
                                                  <DialogTitle id="alert-dialog-slide-title-final">
                                             
                                                  </DialogTitle>
                                                  <DialogContent>
                                                  <DialogContentText id="alert-dialog-description">
                                                  <Typography component={'span'} style={{fontSize: '1rem'}}>{confirmFinalDialogue}</Typography>
                                                  </DialogContentText>
                                                  <FormControlLabel
                                                       control={
                                                            <Checkbox
                                                            checked={!agreeBtnDisable}
                                                            onChange={handleAgreeButton}
                                                            name="agreeBtn"
                                                            color="primary"
                                                            />
                                                       }
                                                  label={<Typography component={'span'} style={{fontSize: '0.8rem'}}>{declarationMessage}</Typography>}
                                                  />
                                             </DialogContent>
                                                  <DialogActions>
                                                       <Button 
                                                       onClick={() => handleFinalSubmitClose(false)}  
                                                       color="secondary">
                                                       Disagree
                                                       </Button>
                                                       <Button onClick={() => handleFinalSubmitClose(true)} 
                                                       color="primary"
                                                       disabled={agreeBtnDisable}
                                                       >
                                                       Agree
                                                       </Button>
                                                  </DialogActions>
                                             </Dialog>
                                        </TableRow>
                                   ) : (null)
                              }

                              {
                                   ((!isEditable && switchView && !finalSubmitEnable)) ? 
                                   (
                                        
                                        <PermissionRow 
                                             colSpanCount={10}
                                             isSwitchEnabled={isSwitchEnabled}
                                             handlePermissionSwitch={handlePermissionSwitch}
                                        />
                                        
                                   ) :  
                                   (
                                        null
                                   )
                              }
                         </TableBody>
                    </Table>
               </TableContainer>
               <Dialog
                    open={openDeleteDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => handleDeleteDialogClose(false)} 
                    aria-labelledby="alert-dialog-slide-title-delete"
                    aria-describedby="alert-dialog-slide-description"
               >
                    <DialogTitle id="alert-dialog-slide-title-delete">
                    </DialogTitle>
                    <DialogContent>
                         <DialogContentText id="alert-dialog-description">
                         <Typography component={'span'} style={{fontSize: '1rem'}}>{confirmDeleteDialogue}</Typography>
                         </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                         <Button 
                         onClick={() => handleDeleteDialogClose(false)}  
                         color="secondary">
                         Disagree
                         </Button>
                         <Button onClick={() => handleDeleteDialogClose(true)} color="primary">
                         Agree
                         </Button>
                    </DialogActions>
               </Dialog>
          </div>
     )
}

export default NomineeDetailsTable;