import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      color: '#f2f3f5',
      padding: theme.spacing(4)
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(0),
    }
  }));