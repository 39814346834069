import React, { Component} from 'react'
import ManageCriteria from '../../pages/Survey/ManageCriteria'
import SurveyDashboard from '../../pages/Survey/SurveyDashboard'
import SurveyTab  from '../../pages/Survey/SurveyTab'
import ColorShading from '../../pages/Survey/ColorShading'

export default class SurveyJs extends Component {
  constructor(props) {
    super(props);
    this.state = {
         path : props.computedMatch.params.type
    };
}
  getPage(path){
    switch(path){
      case "manageCriteria":
        return ManageCriteria 
      case "dashboardHr":
        return SurveyDashboard 
      case "surveyTab":
          return SurveyTab  
      case "colorShading":
        return ColorShading   
      default:
        return null
    }
  }
  render (){
    const Component = this.getPage(this.state.path)

    return (
      <div>
        { Component ? <Component {...this.props}/> : null }
      </div>
    )
  }
}