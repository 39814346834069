import React, { Fragment, useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { useStyles } from './style';
import {
  Paper,
  FormControl,
  TextField,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  InputLabel,
  Select,
  Input,
  MenuItem,
  FormHelperText,
} from '@material-ui/core';
import {
  checkUanRegEx,
  checkPassportNumberRegEx,
  onlyNumber,
  charAllowedForPF,
  onlyAlphabetsWithNumber,
} from '../../../utils/Validation';
import { formatDate } from '../../../utils/CommonFunction';
import moment from 'moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { countries } from 'countries-list';

const getCountryList = () => {
  let result = [];
  for (let k in countries) {
    result.push(countries[k]);
  }
  return result;
};

const Employment = ({
  errorObj,
  offerData,
  joinerEmploymentData = [],
  handleChildData = () => {},
  handleChildDataValidation = () => {},
  submitCheck = false,
  setSubmitCheck = () => {}
}) => {
  const classes = useStyles();
  const {
    offerId,
    department,
    location_name,
    designation,
    date_of_joining,
    proposed_date_of_joining,
  } = offerData;
  let { date_of_birth } = offerData;
  date_of_birth = formatDate(date_of_birth);
  let proposedDateOfJoining = (proposed_date_of_joining && proposed_date_of_joining !== '0000-00-00 00:00:00') ? moment(
    formatDate(proposed_date_of_joining)
  ).format('DD-MMM-YYYY') : moment(
    formatDate(date_of_joining)
  ).format('DD-MMM-YYYY');

  const minDoeDate = moment(date_of_birth)
  .add(18, 'years')
  .format('YYYY-MM-DD');
  
  const maxDoeDate = moment(proposedDateOfJoining).subtract(1, 'days').format(
    'YYYY-MM-DD'
  )

  //errorObj = { ...errorObj, isPfEmp: { status: false, text: '' } };
  const [
    {
      id,
      previous_pf_no = '',
      previous_uan = '',
      date_of_exit = null,
      is_international_worker = 0,
      passport_number = '',
      passport_validity_from = null,
      passport_validity_to = null,
      country_name = '',
      is_fresher = ''
    } = {},
  ] = joinerEmploymentData;

  
  const initObj = {
    offerId,
    id: id,
    previousPfNo: previous_pf_no === '' ? '' : previous_pf_no,
    previousUan: previous_uan === '' ? '' : previous_uan,
    dateOfExit: date_of_exit === '' ? null : date_of_exit,
    isInternationalWorker: is_international_worker,
    passportNumber: passport_number,
    passportValidityFrom:
      passport_validity_from === '' ? null : passport_validity_from,
    passportValidityTo:
      passport_validity_to === '' ? null : passport_validity_to,
    isFresher: is_fresher,
    countryName: country_name === '' ? '' : country_name,
    isPfEmp: joinerEmploymentData.length ? (previous_uan.length ? 1 : 0): '',
  };

 let initErrObj = {};
  for (let k in initObj) {
    initErrObj[k] = { status: false, text: '' };
  }
  
  const [employmentObj, setEmploymentObj] = useState(initObj);
  const [newErrorObj, setNewErrorObj] = useState(initErrObj);
 
  useEffect(() => {
    for (let k in employmentObj) {
      handleChildData(k, employmentObj[k]);
      let err = checkEmployeeDataError(k, employmentObj[k]);
      handleChildDataValidation(k, err);
    }
    if(submitCheck){
      setNewErrorObj({...newErrorObj,...errorObj})
    }
  }, [employmentObj,submitCheck]);

  const {
    previousPfNo,
    previousUan,
    dateOfExit,
    isInternationalWorker,
    passportNumber,
    passportValidityFrom,
    passportValidityTo,
    isFresher,
    countryName,
    isPfEmp,
  } = employmentObj;
  const maxPassportValidityFromDateDate = moment().format('YYYY-MM-DD');
  // const minPassportValidityToDate = moment()
  //   .add(1, 'days')
  //   .format('YYYY-MM-DD');

  const handleChange = (e) => {
    let name = e.target.name;
    let value =
      name === 'countryName' ? e.target.value : e.target.value.toUpperCase();
      const newObject = { ...employmentObj, [name]: value };
      setEmploymentObj(newObject);
    handleChildData(name, value);
    let err = checkEmployeeDataError(name, value);
    handleChildDataValidation(name, err);
    setNewErrorObj({...newErrorObj,[name]:{status: err.status,text:err.text}})
  };

  const handleDateChange = (name, val) => {
    handleChildData(name, val);
    setEmploymentObj({ ...employmentObj, [name]: val });
    
    let err = checkEmployeeDataError(name, val);
    handleChildDataValidation(name, err);
    setNewErrorObj({...newErrorObj,[name]:{status: err.status,text:err.text}})
  };

  const onChangeRadio = (e) => {
    let name = e.target.name;
    let value = parseInt(e.target.value, 10);
    if (name === 'isFresher' && value === 1) {
      setEmploymentObj({ ...employmentObj, isPfEmp: 0, isFresher: 1, previousPfNo: '', previousUan: '' });
    }
    else if (name === 'isPfEmp' && value === 0){
      setEmploymentObj({...employmentObj,previousUan:'',previousPfNo:'',isPfEmp: 0})
    }
    else {
      setEmploymentObj({ ...employmentObj, [name]: value });
    }
    let err = checkEmployeeDataError(name, value);
    handleChildDataValidation(name, err);
    setSubmitCheck(false)
    setNewErrorObj({...newErrorObj,[name]:{status: err.status,text:err.text}})
  };

  const checkEmployeeDataError = (key, value) => {
    let text = '';
    let status = false;
    switch (key) {
      case 'isFresher':
        if (![0, 1].includes(value)) {
          text = 'Select this';
          status = true;
        }
        break;
      // case 'isPfEmp':
      //   if (![0, 1].includes(value)) {
      //     text = 'Select this';
      //     status = true;
      //   }
      //   break;
      case 'dateOfExit':
        if (![null, ''].includes(value) && !moment(value).isValid()) {
          text = 'Invalid date of exit';
          status = true;
        }
        break;
      case 'previousPfNo':
        // if (
        //   isFresher === 0 &&
        //   isPfEmp === 1 &&
        //   (!checkPfRegEx(value) || previousPfNo.length === 0)
        // ) {
        //   text = 'Invalid PF Number';
        //   status = true;
        // }
        break;
      case 'previousUan':
        if (
          isFresher === 0 &&
          isPfEmp === 1 &&
          (!checkUanRegEx(value) || previousUan.length === 0)
        ) {
          text = 'Invalid UAN';
          status = true;
        }
        break;
      case 'isInternationalWorker':
        if (![0, 1].includes(value)) {
          text = 'Select this';
          status = true;
        }
        break;
      case 'countryName':
        if (
          isInternationalWorker === 1 &&
          !getCountryList()
            .map(({ name }) => name)
            .includes(value)
        ) {
          text = 'Invalid country name';
          status = true;
        }
        break;
      case 'passportNumber':
        if (isInternationalWorker === 1 && !checkPassportNumberRegEx(value)) {
          text = 'Invalid passport number';
          status = true;
        }
        break;
      case 'passportValidityFrom':
        if (isInternationalWorker === 1 && !moment(value).isValid()) {
          text = 'Invalid passport from date';
          status = true;
        }
        if (
          isInternationalWorker === 1 &&
          moment(passportValidityTo).isValid() &&
          moment(value).isSameOrAfter(passportValidityTo)
        ) {
          text = 'Invalid passport from date';
          status = true;
        }
        break;
      case 'passportValidityTo':
        if (isInternationalWorker === 1 && !moment(value).isValid()) {
          text = 'Invalid passport from date';
          status = true;
        }

        if (
          isInternationalWorker === 1 &&
          moment(passportValidityFrom).isValid() &&
          moment(value).isSameOrBefore(passportValidityFrom)
        ) {
          text = 'Invalid passport to date';
          status = true;
        }
        break;
      default:
        break;
    }

    return { text, status };
  };

  const handleInput = (e, type) => {
    switch (type) {
      case 'previousPfNo':
        if (!charAllowedForPF(e.which)) {
          e.preventDefault();
        }
        break;
      case 'previousUan':
        if (!onlyNumber(e.which)) {
          e.preventDefault();
        }
        break;
      case 'passportNumber':
        if (!onlyAlphabetsWithNumber(e.which)) {
          e.preventDefault();
        }
        break;
      default:
        break;
    }
  };

  return (
    <Fragment>
      <Grid container spacing={2} className={classes.root}>
        <Grid item sm={10}>
          <Paper className={classes.paperFirst}>
          <div style={{color: "red", float: "left", fontSize: "11px", paddingTop: "10px"}}>
              <sup>*</sup> Kindly read general instructions before filling in the information on this page
            </div>
            <Grid container spacing={4}>
              <FormControl className={classes.formControlSpan}>
                <span>Date Of Joining: {proposedDateOfJoining}</span>
              </FormControl>
              <FormControl className={classes.formControlSpan}>
                <span>Department: {department}</span>
              </FormControl>
              <FormControl className={classes.formControlSpan}>
                <span>Designation: {designation}</span>
              </FormControl>
              <FormControl className={classes.formControlSpan}>
                <span>Job Location: {location_name}</span>
              </FormControl>
            </Grid>
          </Paper>
          <Paper className={classes.paper}>
            <Grid container spacing={3}>
              <FormControl
                className={classes.formControlLable}
                error={newErrorObj.isFresher.status}
              >
                <FormLabel>Are you a fresher?</FormLabel>
                <RadioGroup
                  row
                  aria-label='isFresher'
                  name='isFresher'
                  defaultValue='top'
                >
                  <FormControlLabel
                    value='1'
                    control={<Radio color='primary' required={true} />}
                    label='Yes'
                    labelPlacement='start'
                    checked={isFresher === 1}
                    onChange={onChangeRadio}
                  />
                  <FormControlLabel
                    value='0'
                    control={<Radio color='primary' />}
                    label='No'
                    labelPlacement='start'
                    checked={isFresher === 0}
                    onChange={onChangeRadio}
                  />
                </RadioGroup>
                <FormHelperText style={{ color: 'red' }}>
                  {newErrorObj.isFresher.text}
                </FormHelperText>
              </FormControl>
              <FormControl
                className={
                  isFresher === 0
                    ? classes.formControlDate
                    : classes.formControlInactive
                }
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    label='Date of Exit'
                    format='dd-MM-yyyy'
                    clearable={false}
                    name='dateOfExit'
                    value={dateOfExit}
                    onChange={(val) => handleDateChange('dateOfExit', val)}
                    // disableFuture={true}
                    minDate={minDoeDate}
                    maxDate={maxDoeDate}
                    inputProps={{ readOnly: true }}
                    error={newErrorObj.dateOfExit.status}
                    helperText={newErrorObj.dateOfExit.text}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
              <FormControl
                className={
                  isFresher === 0
                    ? classes.formControlDate
                    : classes.formControlInactive
                }
                error={newErrorObj.isPfEmp.status}
              >
                <FormLabel>
                  Are you already a member of Employee Provident Fund (PF)
                  scheme?
                </FormLabel>
                <RadioGroup
                  row
                  aria-label='isPfEmp'
                  name='isPfEmp'
                  defaultValue='top'
                >
                  <FormControlLabel
                    value='1'
                    control={<Radio color='primary' required={true} />}
                    label='Yes'
                    labelPlacement='start'
                    checked={isPfEmp === 1}
                    onChange={onChangeRadio}
                  />
                  <FormControlLabel
                    value='0'
                    control={<Radio color='primary' />}
                    label='No'
                    labelPlacement='start'
                    checked={isPfEmp === 0}
                    onChange={onChangeRadio}
                  />
                </RadioGroup>
                <FormHelperText style={{ color: 'red' }}>
                  {newErrorObj.isPfEmp.text}
                </FormHelperText>
              </FormControl>
              <FormControl className={classes.formControlPrevPf}>
                <TextField
                  className={
                    isFresher === 0 && isPfEmp === 1
                      ? classes.formControlActive
                      : classes.formControlInactive
                  }
                  label='Previous Employer PF Number'
                  value={previousPfNo}
                  name='previousPfNo'
                  onChange={(e) => handleChange(e)}
                  onKeyPress={(e) => handleInput(e, 'previousPfNo')}
                  inputProps={{ minLength: 4, maxLength: 24 }}
                  error={
                    isFresher === 0 && isPfEmp === 1
                      ? newErrorObj.previousPfNo.status
                      : null
                  }
                  helperText={
                    isFresher === 0 && isPfEmp === 1
                      ? newErrorObj.previousPfNo.text
                      : null
                  }
                />
              </FormControl>
              <FormControl className={classes.formControlPrevPf}>
                <TextField
                  className={
                    isFresher === 0 && isPfEmp === 1
                      ? classes.formControlActive
                      : classes.formControlInactive
                  }
                  label='Previous UAN Number'
                  value={previousUan}
                  name='previousUan'
                  onChange={(e) => handleChange(e)}
                  onKeyPress={(e) => handleInput(e, 'previousUan')}
                  inputProps={{ minLength: 12, maxLength: 12 }}
                  error={
                    isFresher === 0 && isPfEmp === 1
                      ? newErrorObj.previousUan.status
                      : null
                  }
                  helperText={
                    isFresher === 0 && isPfEmp === 1
                      ? newErrorObj.previousUan.text
                      : null
                  }
                />
              </FormControl>
            </Grid>
          </Paper>
          <Paper className={classes.paper}>
            <Grid container spacing={3}>
              <FormControl
                className={classes.formControlLable}
                error={newErrorObj.isInternationalWorker.status}
              >
                <FormLabel>Are you an international worker?</FormLabel>
                <RadioGroup
                  row
                  aria-label='isInternationalWorker'
                  name='isInternationalWorker'
                  defaultValue='top'
                >
                  <FormControlLabel
                    value='1'
                    control={<Radio color='primary' />}
                    label='Yes'
                    labelPlacement='start'
                    checked={isInternationalWorker === 1}
                    onChange={onChangeRadio}
                  />
                  <FormControlLabel
                    value='0'
                    control={<Radio color='primary' />}
                    label='No'
                    labelPlacement='start'
                    checked={isInternationalWorker === 0}
                    onChange={onChangeRadio}
                  />
                </RadioGroup>
                <FormHelperText style={{ color: 'red' }}>
                  {newErrorObj.isInternationalWorker.text}
                </FormHelperText>
              </FormControl>
              <FormControl className={classes.formControl}>
                <InputLabel
                  error={newErrorObj.countryName.status}
                  className={
                    isInternationalWorker === 1
                      ? classes.formControlActive
                      : classes.formControlInactive
                  }
                >
                  Country
                </InputLabel>
                <Select
                  className={
                    isInternationalWorker === 1
                      ? classes.formControlActive
                      : classes.formControlInactive
                  }
                  value={countryName}
                  name='countryName'
                  input={<Input id='countryName' />}
                  onChange={(e) => handleChange(e)}
                  error={
                    isInternationalWorker ? newErrorObj.countryName.status : null
                  }
                >
                  <MenuItem value='' disabled>
                    <em>Select the value</em>
                  </MenuItem>
                  {getCountryList().map(({ name, emoji }, index) => {
                    return (
                      <MenuItem key={index} value={name}>
                        {`${emoji} ${name}`}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText style={{ color: 'red' }}>
                  {isInternationalWorker ? newErrorObj.countryName.text : null}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid container spacing={3}>
              <FormControl className={classes.formControlLable}></FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  className={
                    isInternationalWorker === 1
                      ? classes.formControlActive
                      : classes.formControlInactive
                  }
                  label='Passport Number'
                  value={passportNumber}
                  name='passportNumber'
                  onChange={(e) => handleChange(e)}
                  onKeyPress={(e) => handleInput(e, 'passportNumber')}
                  inputProps={{ minLength: 8, maxLength: 8 }}
                  error={newErrorObj.passportNumber.status}
                  helperText={newErrorObj.passportNumber.text}
                />
              </FormControl>
              <FormControl className={classes.formControlDate}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    className={
                      isInternationalWorker === 1
                        ? classes.formControlActive
                        : classes.formControlInactive
                    }
                    label='Valid From'
                    format='dd-MM-yyyy'
                    clearable={false}
                    name='passportValidityFrom'
                    value={passportValidityFrom}
                    onChange={(val) =>
                      handleDateChange('passportValidityFrom', val)
                    }
                    disableFuture={true}
                    maxDate={maxPassportValidityFromDateDate}
                    inputProps={{ readOnly: true }}
                    error={newErrorObj.passportValidityFrom.status}
                    helperText={newErrorObj.passportValidityFrom.text}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
              <FormControl className={classes.formControlDate}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    className={
                      isInternationalWorker === 1
                        ? classes.formControlActive
                        : classes.formControlInactive
                    }
                    label='Valid To'
                    format='dd-MM-yyyy'
                    clearable={false}
                    name='passportValidityTo'
                    value={passportValidityTo}
                    onChange={(val) =>
                      handleDateChange('passportValidityTo', val)
                    }
                    disablePast={false}
                    inputProps={{ readOnly: true }}
                    error={newErrorObj.passportValidityTo.status}
                    helperText={newErrorObj.passportValidityTo.text}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            </Grid>
          </Paper>
        </Grid>
        <Grid item sm={2}>
          <Paper className={classes.paperGeneral}>
            <div>
              <ul>
                <li className='unorder-list'>&nbsp;</li>
                <li className='unorder-list'>General Instructions:</li>
                <li className='unorder-list'>&nbsp;</li>
                <li>In case you are not a fresher, please share the exit date and PF details of your previous employment. In case you are not sure about your PF / UAN number, you can refer to your previous payslips / F&F settlement sheet or contact your last employer for these details. If you are an international worker, please provide your passport details.</li>
                <li>To move ahead, click SAVE & NEXT.</li>
              </ul>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default Employment;
