import React from 'react'
import {
  Backdrop,
  CircularProgress
} from '@material-ui/core'

import { useStyles } from './style'

export default function SimpleBackdrop({
    isBackdrop=false 
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(isBackdrop)
  const handleClose = () => {
    setOpen(false);
  }

  return (
    <div>
      <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}