import React from 'react'
import SimpleAlerts from '../Alert'
import { useStyles } from './style' 

export const Auth = ({
    message
}) => {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <SimpleAlerts type="error" message={message} />
        </div>
    )
}