import React from "react"
import { TableContainer, Grid, Table, TableRow, TableCell } from "@material-ui/core"
import { useStyles } from "./style"

export default function ExitFirstRow({ firstRow }) {
    const classes = useStyles()
    const {
        attritionPercent, exitCount, earlyAttritionPercent,
        excludingJoinNLeftExitCount,
        attritionPercentExcludingJoinNLeft,
        earlyAttritionPercentExcludingJoinNLeft
    } = firstRow
    return (
        <Grid style={{ width: "64%", padding: "5 0 5 5" }}>
            <TableContainer>
                <Table>
                    <TableRow>
                        <TableCell className={classes.firstRowTableCell} ></TableCell>
                        <TableCell className={classes.firstRowTableCell}>Including<br/>joined and left cases</TableCell>
                        <TableCell className={classes.firstRowTableCell}>Excluding<br/>joined and left cases</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.firstRowTableCell}>Total Number Of Exits</TableCell>
                        <TableCell className={classes.firstRowTableCell}>{exitCount}</TableCell>
                        <TableCell className={classes.firstRowTableCell}>{excludingJoinNLeftExitCount}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.firstRowTableCell}>Attrition %</TableCell>
                        <TableCell className={classes.firstRowTableCell}>{attritionPercent}</TableCell>
                        <TableCell className={classes.firstRowTableCell}>{attritionPercentExcludingJoinNLeft}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.firstRowTableCell}>Early Attrition %</TableCell>
                        <TableCell className={classes.firstRowTableCell}>{earlyAttritionPercent}</TableCell>
                        <TableCell className={classes.firstRowTableCell}>{earlyAttritionPercentExcludingJoinNLeft}</TableCell>
                    </TableRow>
                </Table>
            </TableContainer>
        </Grid>
    )
}