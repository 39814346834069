import React from "react"
import { 
    Grid,
    Card,
    TableBody, TableRow, Table, TableContainer,
    Divider
} from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"
import MuiTableCell from "@material-ui/core/TableCell"

import { useStyles } from "./style"
import ProgressBar from "./ProgressBar"

const TableCell = withStyles({
    root: {
        borderBottom: "none"
    }
})(MuiTableCell)

const colorScheme = {
    "FG_COLOR" : "#4A3AFF",
    "BG_COLOR" : "#CDC8FF"
}

const FifteenFeedbackCard = ({ 
    fifteenFeedbackReport = {},
    handleDetailedData = () => {}
}) => {
    const classes = useStyles()
    const { questionNames, avgRatings, totalAvg, noOfJoiners } = fifteenFeedbackReport
    
    return (<Grid item xs={12} sm={12} md={12} className={classes.gridBody} style={{ cursor: "pointer" }}>
                <Card style={{ maxHeight: "540px", minHeight: "515px"}}>
                    <div onClick={e => handleDetailedData("FifteenFeedback")}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12}>
                            <div style={{ padding: "5 5 0 10" }}>
                                <label style={{ fontSize: "1.17em", fontWeight: "bold", cursor: "pointer"  }}>15 Day Feedback</label>
                                <br/>
                                <span style={{ color:"#9A99AA", fontSize: "11px" }}>Feedback Scores</span>
                            </div>
                            <div style={{ padding: "5 5 5 10" }}>
                                <Divider />
                            </div>
                            <br/>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                            <div style={{ paddingLeft: "5%" }}>
                                <span style={{ height: "7px", width: "7px", backgroundColor: `${colorScheme["FG_COLOR"]}`, borderRadius: "50%", display: "inline-block" }}></span>
                                &nbsp; <span style={{ fontSize: "11px" }}>Average Feedback Score of all participants</span>
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                            <div style={{ paddingLeft: "50%", textAlign: "center" }}>
                                <span style={{ fontSize: "12px" }}>No. of Eligible Joiner(s)</span>
                                <br/>
                                <span style={{ fontWeight: "bold", fontSize: "14px" }}>{noOfJoiners}</span>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <TableContainer>
                                <Table>
                                    <TableBody>
                                    {
                                        Object.keys(questionNames).map((key, index) => <TableRow key={index}>
                                            <TableCell>
                                                {questionNames[key]}
                                                <ProgressBar 
                                                    backgroundColor={colorScheme["BG_COLOR"]}
                                                    foreGroundColor={colorScheme["FG_COLOR"]}
                                                    progress={avgRatings[key].toFixed(2)*20}
                                                    height={10}
                                                    titleValue={avgRatings[key].toFixed(2)}
                                                />
                                            </TableCell>
                                            <TableCell>{avgRatings[key].toFixed(2)}</TableCell>
                                        </TableRow>)
                                    }
                                    <TableRow>
                                        <TableCell colSpan={2} style={{textAlign: "center"}}>
                                            <span><b>Overall Average Feedback Score: {totalAvg.toFixed(2)}</b></span>
                                        </TableCell>
                                    </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                    </div>
                </Card>
            </Grid>)
}

export default FifteenFeedbackCard