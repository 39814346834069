import React, { useState, useEffect } from 'react'
import moment from 'moment-timezone'
import { CSVLink } from 'react-csv'

import { FormControl, Grid, InputLabel, MenuItem, Select, TextField, Button as MuiButton, Fab, Table, TableBody, TableRow, TableCell } from '@material-ui/core'
import { useStyles } from './style'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import SimpleAlerts from '../../Joiner/Alert'
import { getDepartmentList, postCampaignData, getGradeList, getCampaignTypesList, getCampaignData,updateCampaignData, postUploadedFiles, getLocationList, getSubDepartmentList, getActivityList } from '../fetchData'
import qs from 'qs'
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate"
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined'
import { campaignApiUrl } from '../../../utils/CommonUrl'
import { campaignActivityConst, campaignConst } from '../../../constants/CampaignConstants'
import { validateCsvFile } from './rnrFunction'
import CircularProgress from '@material-ui/core/CircularProgress'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import { saveAs } from "file-saver"
import { FaInfoCircle } from "react-icons/fa"
import ReactTooltip from "react-tooltip";
import {getLoggedInUserDetailsFunc,getPagesAcess} from '../../../utils/authFunction'
import {SUPERADMIN} from '../../../constants/CommonConstant'

const defaultStartDate = moment().format("YYYY-MM-DD")
const defaultEndDate = moment().format("YYYY-MM-DD")
const defaultDojFrom = moment().format("2006-01-01")
const defaultDojTo = moment().format("YYYY-MM-DD")

const RNR_CAROUSEL_ID = 4
const RNR_SAMPLE_FILE_LINK = `${process.env.REACT_APP_BASE_URL}/certificate/index/employee-certificate-format`

const campaignInitialValues = {
    campaignTypeMasterId: '',
    campaignTemplateMasterId: '',
    startDate: defaultStartDate,
    endDate: defaultEndDate,
    gradeId: [],
    departmentId: [],
    locationId: [],
    statusId: 1,
    name: '',
    subDepartmentId: [],
    activityId: [],
    activityXDays: '',
    dojFrom: defaultDojFrom,
    dojTo: defaultDojTo
}
let defaultNotification = `All the fields are necessary except hyperlink`

export default function index(props) {
    const [campaignData, setCampaignData] = useState(campaignInitialValues);
    const classes = useStyles();
    const [campaignTemplates, setCampaignTemplates] = useState([]);
    const [templateAttributes, setTemplateAttributes] = useState({});
    const [notification, setNotification] = useState({type: 'success',  message: defaultNotification})
    const [departmentList, setDepartmentList] = useState([{department_id: 0, department: 'All', status: 1}]);
    const [gradeList, setGradeList] = useState([{id:0, grade_name:'All'}])
    const [subDepartmentList, setSubDepartmentList] = useState([{function_id:0, function:'All'}]);
    const [locationList, setLocationList] = useState([{id:0, locality:'All'}])
    const [campaignTypesList, setCampaignTypeList] = useState([]);
    const [campaignTypeAndTemplateMap, setCampaignTypeAndTemplateMap] = useState({})
    const [campaignTemplateAndMetaMap, setCampaignTemplateAndMetaMap] = useState({})
    const [isEditing, setIsEditing] = useState(false)
    const [campaignDataIdsForEditing, setCampaignDataIdsForEditing] = useState({})
    const [activityList, setActivityList] = useState([{id:0, name:'None'}])
    const [rnrData, setRnrData] = useState([])
    const [loading, setLoading] = useState(false)
    const [pageEdit,setPageEdit] =  useState('No')
    const [pageAdd,setPageAdd] =  useState('No')
    const [superAdmin,setSuperAdmin] =  useState('No')

    const [fileData, setFileData] = useState([])
    const onFileChange = async (e) => {
        let files = e.target.files[0]
        let name =  e.target.name
        e.target.value = ''
       
        if(files){
            let fileExtenstions = campaignConst.fileExtenstions
            if (campaignData.campaignTypeMasterId === RNR_CAROUSEL_ID) {
                fileExtenstions = ['csv']
            }
            if (!fileExtenstions.includes(files.name.split('.').pop())) {
                alert(`Selected file type is invalid! Allowed file types are ${fileExtenstions.join(',')}`)
                return false
            }
            let error = []
            if (campaignData.campaignTypeMasterId === RNR_CAROUSEL_ID) {
                const response = await validateCsvFile(files)
                error = response.error
                if (!error.length) {
                    setRnrData(response.data)
                }
            }

            if (!error.length) {
                setFileData([{ inputFile:files }])
                setTemplateAttributes({...templateAttributes, [name]:files})
            } else {
                setNotification({type: 'error',  message: `${error.join(',')}`})
            }
        }
    }

    const handleSelectedFile = (e, index) => {
        const newFileData = fileData.filter((obj, i) => {
            return i!==index
        })
        setFileData(newFileData)
    }

    useEffect(()=>{
        updateTemplateDropDown();
        updateTemplateAttributes();
    },[campaignData])

    useEffect(()=>{
        // updateGradeList();
    },[notification])

    useEffect(()=>{
        updateSubDepartmentList();
    }, [campaignData['departmentId']])

    useEffect(()=>{
      updateDepartmentList();
      updateCampaignTypeList();
      updateGradeList();
      updateLocationList();
      updateActivityList();
      setPageAcessses()
    }, [])

    const setPageAcessses = async () => {
        let loggedUserDetails = await getLoggedInUserDetailsFunc()
        let superAdminTemp = false
        if(loggedUserDetails.data.data.adminUserGroupId == SUPERADMIN){
            superAdminTemp = true
        }

        let createCampaignAcesses = await getPagesAcess({userGroupId:loggedUserDetails.data.data.adminUserGroupId,moduleName:'campaign' ,controllerName: 'index', actionName: 'create-campaign' })
        if(createCampaignAcesses && createCampaignAcesses.data && createCampaignAcesses.data.data[0] && createCampaignAcesses.data.data[0].page_add){
            setPageAdd(createCampaignAcesses.data.data[0].page_add)
        }

        let editCampaignAcesses = await getPagesAcess({userGroupId:loggedUserDetails.data.data.adminUserGroupId,moduleName:'campaign' ,controllerName: 'index', actionName: 'view-campaigns' })
        if(editCampaignAcesses && editCampaignAcesses.data && editCampaignAcesses.data.data[0] && editCampaignAcesses.data.data[0].page_edit){
            setPageEdit(editCampaignAcesses.data.data[0].page_edit)
        }
        setSuperAdmin(superAdminTemp)
    }

    const getCampaignDataForEditing = async() => {
        const campaignIdForEditing = qs.parse(props.location.search, { ignoreQueryPrefix: true }).campaignId

        if(campaignIdForEditing){
            setIsEditing(true)
            let response = {}
            response = await getCampaignData({campaignId: campaignIdForEditing})
            let dojFrom = response.data.doj_from ? response.data.doj_from : defaultDojFrom
            let dojTo = response.data.doj_from ? response.data.doj_to : defaultDojTo

            if (response.data.campaign_type_master_id === RNR_CAROUSEL_ID) {
                setNotification({type: 'success',  message: `All the fields are necessary`})
            }
            setCampaignData({
                name: response.data.name,
                campaignTypeMasterId: response.data.campaign_type_master_id,
                campaignTemplateMasterId: response.data.campaign_template_master_id,
                startDate: moment(response.data.start_date).format("YYYY-MM-DD"),
                endDate: moment(response.data.end_date).format("YYYY-MM-DD"),
                gradeId: JSON.parse(response.data.grade_id),
                departmentId: JSON.parse(response.data.department_id),
                subDepartmentId: response.data.sub_department_id ? JSON.parse(response.data.sub_department_id) : [0], // 0 -> All Subdepartment
                activityId: response.data.activity_id ? JSON.parse(response.data.activity_id) : campaignActivityConst.NONE,
                activityXDays: response.data.activity_days,
                locationId: JSON.parse(response.data.location_master_id),
                statusId: response.data.status,
                dojFrom: moment(dojFrom).format("YYYY-MM-DD"),
                dojTo: moment(dojTo).format("YYYY-MM-DD"),
            })
            const templateAttributesMetaData = JSON.parse(response.data.meta_data)
            setTemplateAttributes({
                ...templateAttributesMetaData,
                campaignTemplateMasterId: response.data.campaign_template_master_id
            })
            setCampaignDataIdsForEditing({
                campaignId: campaignIdForEditing,
                campaignTemplateDataId: response.data.campaign_template_data_id
            })
        }
        
        
    }

    const updateCampaignTypeList = async () => {
        try {
            let response = {};
            response = await getCampaignTypesList();
            setCampaignTypeList(response.data.campaignTypesList)
            setCampaignTypeAndTemplateMap(response.data.campaignTypeAndTemplateMap)
            setCampaignTemplateAndMetaMap(response.data.campaignTemplateAndMetaMap)
            getCampaignDataForEditing();
        } catch (error) {
            
        }
    }

    const updateDepartmentList = async () => {
        try {
            let response = {}
            response = await getDepartmentList()
            setDepartmentList(departmentList.concat(response.data))
        } catch (error) {
            
        }
    }

    const updateSubDepartmentList = async () => {
        try {
            if(campaignData && campaignData['departmentId'].length > 0 ){
                let response = {}
                response = await getSubDepartmentList({departmentId:campaignData['departmentId']})
                setSubDepartmentList([{function_id:0, function:'All'}].concat(response.data))
            }
        } catch (error) {
        }
    }

    const updateActivityList = async () => {
        try {
            let response = {}
            response = await getActivityList()
            setActivityList(activityList.concat(response.data))
        } catch (error) {
            
        }
    }

    const updateGradeList = async () => {

        try {
            var response = await getGradeList()
            response.data.sort((a,b) => (parseInt(a.grade_name, 10) > parseInt(b.grade_name, 10)) ? 1 : ((parseInt(b.grade_name, 10) > parseInt(a.grade_name, 10)) ? -1 : 0))
            setGradeList([{id:0, grade_name:'All'}].concat(response.data))
        } catch (error) {
            
        }
    }

    const updateLocationList = async () => {

        try {
            var response = await getLocationList()
            setLocationList([{id:0, locality:'All'}].concat(response.data))
        } catch (error) {
            
        }
    }

    const updateTemplateDropDown = () => {
        if(campaignData && campaignData['campaignTypeMasterId']){
            setCampaignTemplates(campaignTypeAndTemplateMap[campaignData['campaignTypeMasterId']])
        }   
    }

    const updateTemplateAttributes = () => {
        if(campaignData && campaignData['campaignTemplateMasterId']){
            if(templateAttributes.campaignTemplateMasterId !== campaignData['campaignTemplateMasterId']){
                setTemplateAttributes({...campaignTemplateAndMetaMap[campaignData['campaignTemplateMasterId']], campaignTemplateMasterId:campaignData['campaignTemplateMasterId']})
            }
        } else {
            setTemplateAttributes({})
        }
    }

    const handleInputChange = e => {
        let { name, value } = e.target

        if(name && name ===  'activityXDays'){
            if(value){
                value = parseInt(value, 10);
            }
            if(!value){
                value = ''
            }
        }

        if(name && name === 'campaignTypeMasterId'){
            setCampaignData({
                ...campaignData,
                [name]: value,
                campaignTemplateMasterId: ''
            })
           
            if (value === RNR_CAROUSEL_ID) {
                setNotification({type: 'success',  message: `All the fields are necessary`})
            } else {
                setNotification({type: 'success',  message: defaultNotification})
            }
        } else {
            setCampaignData({
                ...campaignData,
                [name]: value
    
            })
        }
    }

    const handleTemplateAttributesInputChange = e => {
        const { name, value } = e.target
        setTemplateAttributes({
            ...templateAttributes,
            [name]: value
        })
    }

    const handleSubmit = () => {
        let {startDate, endDate} = campaignData;
        // endDate.setHours(23,59,59)
        // startDate.setHours(0,0,0)
        // let offset = startDate.getTimezoneOffset()
        // startDate = new Date(startDate.getTime() - (offset*60*1000))
        // startDate = startDate.toISOString()
        // offset = endDate.getTimezoneOffset()
        // endDate = new Date(endDate.getTime() - (offset*60*1000))
        // endDate = endDate.toISOString()

        campaignData.endDate = moment(endDate).format("YYYY-MM-DD 23:59:59")
        campaignData.startDate =  moment(startDate).format("YYYY-MM-DD 00:00:00")

        let obj = {
            ...campaignData,
            templateAttributes
        }
        if(isEditing){
            obj = {...obj, campaignDataIdsForEditing}
            handleUpdateCampaignData(obj)
        }else{
            handlePostCampaignData(obj)
        }
    }

    const handlePostCampaignData = async (dataObj) => {
        let validationResponse = handleValidations(dataObj)

        if(validationResponse.error){
            setNotification({message: validationResponse.message, type: 'error'})
        } else {
            setLoading(true)
            let response = {} 
                ,responseUploadFile = {}
            responseUploadFile = await postUploadedFiles(dataObj)
            if(responseUploadFile.success){
                dataObj.templateAttributes.image = responseUploadFile.imagePath
                response = await postCampaignData({campaignData: dataObj, rnrData})
                if(response.success){
                    setNotification({message: response.message, type: 'success'})
                    setCampaignData(campaignInitialValues);
                    setFileData([])
                } else {
                    setNotification({message: response.message, type: 'error'})
                }
            } else {
                setNotification({message: response.message, type: 'error'})
            }
            setLoading(false)
        }     
    }

    const handleUpdateCampaignData = async (dataObj) => {

        let validationResponse = handleValidations(dataObj)

        if(validationResponse.error){
            setNotification({message: validationResponse.message, type: 'error'})
        } else {
            setLoading(true)
            let response = {}
                ,responseUploadFile = {}
                ,isImagePathExist = true; 

            if(dataObj.templateAttributes && dataObj.templateAttributes.hasOwnProperty('image') && typeof dataObj.templateAttributes.image ===  'object'){
                responseUploadFile = await postUploadedFiles(dataObj);
                isImagePathExist = false;
            }    
            if(responseUploadFile.success || isImagePathExist){
                if(!isImagePathExist){ 
                    dataObj.templateAttributes.image = responseUploadFile.imagePath
                }
                response = await updateCampaignData({campaignData: dataObj, rnrData})
                if(response.success){
                    setNotification({message: response.message, type: 'success'})
                    setCampaignData(campaignInitialValues);
                    setFileData([])
                    window.open(process.env.REACT_APP_BASE_URL+'/campaign/index/view-campaigns?param=updated')
                } else {
                    setNotification({message: response.message, type: 'error'})
                } 
            } else {
                setNotification({message: response.message, type: 'error'})
            }
            setLoading(false)
        }
        
    }

    const handleValidations = (dataObj) => {
        let validationResponse = {message: '', error: false}

        if(dataObj.name.trim() ===  '' || dataObj.name ===  ''){
            validationResponse.message = 'Please enter name'
            validationResponse.error = true
            return validationResponse
        }

        if(!dataObj.campaignTypeMasterId){
            validationResponse.message = 'Please choose campaign type'
            validationResponse.error = true
            return validationResponse
        }

        if(!dataObj.campaignTemplateMasterId){
            validationResponse.message = 'Please choose campaign template'
            validationResponse.error = true
            return validationResponse
        }

        if(dataObj.templateAttributes && dataObj.templateAttributes.hasOwnProperty('image') && !dataObj.templateAttributes.image){
            validationResponse.message = 'Please choose an image'
            validationResponse.error = true
            return validationResponse
        }

        if(dataObj.templateAttributes && dataObj.templateAttributes.hasOwnProperty('file') && !dataObj.templateAttributes.image){
            validationResponse.message = 'Please choose a file'
            validationResponse.error = true
            return validationResponse
        }

        if (moment(dataObj.endDate).isBefore(dataObj.startDate)) {
            validationResponse.message = 'End date should not be before start date'
            validationResponse.error = true
            return validationResponse
        }

        if (moment(dataObj.dojTo).isBefore(dataObj.dojFrom)) {
            validationResponse.message = 'DOJ To date should not be before DOJ From date'
            validationResponse.error = true
            return validationResponse
        }

        if (dataObj.campaignTypeMasterId !== RNR_CAROUSEL_ID) {
            if(dataObj.departmentId.length ===  0){
                validationResponse.message = 'Please select at least one department'
                validationResponse.error = true
                return validationResponse
            }

            if(dataObj.gradeId.length ===  0){
                validationResponse.message = 'Please select at least one grade'
                validationResponse.error = true
                return validationResponse
            }

            if(dataObj.locationId.length ===  0){
                validationResponse.message = 'Please select at least one location'
                validationResponse.error = true
                return validationResponse
            }

            if(dataObj.activityId.length ===  0){
                validationResponse.message = 'Please select at least one activity option'
                validationResponse.error = true
                return validationResponse
            }

            if(dataObj.activityId.length !== 0){
                if(dataObj.activityId !== 0){
                    if(!dataObj.activityXDays){
                        validationResponse.message = 'Please enter number of days for activity'
                        validationResponse.error = true
                        return validationResponse
                    }
                }
            }
        }

        return validationResponse
    }

    const closeAlert = () => {
        setNotification({type:'', message: ''})
    }

    const handleDateChange = (val, key) => {
        setCampaignData({...campaignData, [key]:val })
    }

    const downloadCsvData = async (e, url) => {
        e.preventDefault()
        saveAs(url, `${moment().format("DDMMYYYYHHmmss")}.csv`)
    }

    if (notification.message) {
        setTimeout(() => {
            setNotification({type: 'success',  message: ``})
        }, 30000)
    }
   
    return (
        <form className={classes.root}>
            {
                notification.message ? (
                    <div className={classes.notification}>
                        <SimpleAlerts 
                            type={notification.type}
                            message={notification.message}
                            handleAlert={closeAlert}
                        />
                    </div>
                ) : (null)
            }
            {
                campaignData.campaignTypeMasterId === RNR_CAROUSEL_ID ? (
                    <div style={{ textAlign: 'right', margin: '0 20 5 0' }}>
                        <a target="_blank" style={{ cursor:'pointer' }} title={"Download Sample CSV"} href={RNR_SAMPLE_FILE_LINK} className={classes.anchor}>
                        <CloudDownloadIcon />
                        </a>
                    </div>
                ) : (null)
            }
            <Grid container>
                <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            label="Name"
                            name='name'
                            value={campaignData.name}
                            onChange={handleInputChange}
                        />
                </Grid>
                <Grid item xs={4}>
                    <FormControl
                        variant='outlined'>
                        <InputLabel>
                            Campaign Type
                        </InputLabel>
                        <Select
                            label='Campaign Type'
                            name='campaignTypeMasterId'
                            value={campaignData.campaignTypeMasterId}
                            onChange={handleInputChange}
                        >
                            {
                                campaignTypesList.map(
                                    item => (<MenuItem key={item.id} value={item.id}> {item.name} </MenuItem>)
                                )
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl
                        variant='outlined'>
                        <InputLabel>
                            Campaign Template
                        </InputLabel>
                        <Select
                            label='Campaign Template'
                            name='campaignTemplateMasterId'
                            value={campaignData.campaignTemplateMasterId}
                            onChange={handleInputChange}
                        >
                            {
                                campaignTemplates.map(
                                    item => (<MenuItem key={item.id} value={item.id}> {item.name} </MenuItem>)
                                )
                            }

                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container>
                {(campaignData.campaignTypeMasterId !== RNR_CAROUSEL_ID && templateAttributes && templateAttributes.hasOwnProperty('hyperlink')) ?
                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            label="Hyper Link"
                            name='hyperlink'
                            value={templateAttributes.hyperlink}
                            onChange={handleTemplateAttributesInputChange}
                        />
                    </Grid> : (null)
                }

                {(campaignData.campaignTypeMasterId !== RNR_CAROUSEL_ID && templateAttributes && templateAttributes.hasOwnProperty('image')) ?
                    <Grid item xs={4}> 
                        <FormControl className={classes.formControl}>
                            <input
                                accept="application/pdf, image/*"
                                className={classes.input}
                                id="contained-button-file"
                                type="file"
                                name='image'
                                onChange={onFileChange}
                                title="Select a file"
                            />
                            <span>Select File (png/jpeg/jpg) <br></br> ({templateAttributes && templateAttributes.hasOwnProperty('dimensions') ? 'Dimensions:'+templateAttributes.dimensions : '' })</span>
                            <label htmlFor="contained-button-file">
                            <Fab component="span" className={classes.button}>
                                <AddPhotoAlternateIcon />
                            </Fab>
                            </label>
                        </FormControl>

                        <Table className={classes.table} aria-label="simple table">
                            <TableBody>
                            {
                                fileData.map(({inputFile}, index) => {
                                return (
                                    <TableRow key={index}>
                                            <TableCell component="th" scope="row">
                                                {inputFile && inputFile.name}
                                            </TableCell>
                                            <TableCell align="right">
                                                <span onClick={e=>handleSelectedFile(e, index)}>
                                                    <DeleteForeverOutlinedIcon className={classes.icon} />
                                                </span>
                                            </TableCell>
                                    </TableRow>
                                )
                                })
                            }
                            {   
                                (templateAttributes && templateAttributes.hasOwnProperty('image') && typeof templateAttributes.image !== 'object'  && templateAttributes.image !== '') ?
                                <TableRow key={index}>
                                        <TableCell>
                                        <a target='blank' href={campaignApiUrl.GET_IMAGE_URL+templateAttributes.image} className={classes.anchor}>
                                            IMAGE
                                        </a> 
                                        </TableCell>
                                </TableRow> : (null)
                            }
                            </TableBody>
                        </Table> 
                    </Grid> : (null)
                }
                
                {(campaignData.campaignTypeMasterId !== RNR_CAROUSEL_ID && templateAttributes && templateAttributes.hasOwnProperty('button')) ?
                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            label="Button Text"
                            name='button'
                            value={templateAttributes.button}
                            onChange={handleTemplateAttributesInputChange}
                        />
                    </Grid> : (null)
                }
                {
                    campaignData.campaignTypeMasterId === RNR_CAROUSEL_ID && templateAttributes && templateAttributes.hasOwnProperty('file') ?
                    <Grid item xs={6}> 
                        <FormControl className={classes.formControl}>
                            <input
                                accept=".csv"
                                className={classes.input}
                                id="contained-button-file"
                                type="file"
                                name="image"
                                onChange={onFileChange}
                                title="Select a file"
                            />
                            <span>Select File (csv)</span>
                            <label htmlFor="contained-button-file">
                            <Fab component="span" className={classes.button}>
                                <AddPhotoAlternateIcon />
                            </Fab>
                            </label>
                        </FormControl>

                        <Table className={classes.table} aria-label="simple table">
                            <TableBody>
                            {
                                fileData.map(({inputFile}, index) => {
                                return (
                                    <TableRow key={index}>
                                            <TableCell component="th" scope="row">
                                                {inputFile && inputFile.name}
                                            </TableCell>
                                            <TableCell align="right">
                                                <span onClick={e=>handleSelectedFile(e, index)}>
                                                    <DeleteForeverOutlinedIcon className={classes.icon} />
                                                </span>
                                                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                <span>
                                                    <CSVLink
                                                        headers={Object.keys(rnrData[0])}
                                                        data={rnrData}
                                                        filename={`${moment().format("DDMMYYYYHHmmss")}.csv`}
                                                        target="_blank"
                                                    >
                                                        <span title="Download Processed CSV File">
                                                            <CloudDownloadIcon />
                                                        </span>
                                                    </CSVLink>
                                                </span>
                                            </TableCell>
                                    </TableRow>
                                )
                                })
                            }
                            {   
                                (templateAttributes && templateAttributes.hasOwnProperty('file') && typeof templateAttributes.image === 'string'  && templateAttributes.image !== undefined) ?
                                <TableRow key={index}>
                                        <TableCell>
                                        <a style={{cursor:'pointer'}} title={"Download CSV"} onClick={e => downloadCsvData(e, campaignApiUrl.GET_IMAGE_URL+templateAttributes.image)} className={classes.anchor}>
                                            <CloudDownloadIcon />
                                        </a> 
                                        </TableCell>
                                </TableRow> : (null)
                            }
                            </TableBody>
                        </Table> 
                    </Grid>
                    : null
                }
            </Grid>
            <Grid container>
                <Grid item xs={3}>
                    <FormControl>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                label="Start Date"
                                format="dd-MM-yyyy"
                                clearable={false}
                                name="startDate"
                                value={campaignData.startDate}
                                onChange={e => handleDateChange(e, 'startDate')}
                                disableFuture={false}
                                disablePast={true}
                                inputProps={{ readOnly: true }}
                                required={true}
                            />
                        </MuiPickersUtilsProvider>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                label="End Date"
                                format="dd-MM-yyyy"
                                clearable={false}
                                name="endDate"
                                value={campaignData.endDate}
                                onChange={e => handleDateChange(e, 'endDate')}
                                disableFuture={false}
                                disablePast={true}
                                inputProps={{ readOnly: true }}
                                required={true}
                            />
                        </MuiPickersUtilsProvider>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl>
                        <div>
                            <FaInfoCircle 
                                data-tip data-for="registerTip"
                                style={{ margin:'0 0 0 58' }}
                            />
                        </div>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                label="DOJ From"
                                format="dd-MM-yyyy"
                                clearable={false}
                                name="dojFrom"
                                value={campaignData.dojFrom}
                                onChange={e => handleDateChange(e, 'dojFrom')}
                                disableFuture={false}
                                inputProps={{ readOnly: true }}
                                required={true}
                            />
                        </MuiPickersUtilsProvider>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl>
                        <div>
                            <FaInfoCircle 
                                data-tip data-for="registerTip"
                                style={{ margin:'0 0 0 40' }}
                            />
                        </div>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                label="DOJ To"
                                format="dd-MM-yyyy"
                                clearable={false}
                                name="dojTo"
                                value={campaignData.dojTo}
                                onChange={e => handleDateChange(e, 'dojTo')}
                                disableFuture={false}
                                inputProps={{ readOnly: true }}
                                required={true}
                            />
                        </MuiPickersUtilsProvider>
                    </FormControl>
                </Grid>
            </Grid>
            { campaignData.campaignTypeMasterId === RNR_CAROUSEL_ID ? 
                null
            :
                <Grid container>
                    <Grid item xs={4}>
                        <FormControl
                            variant='outlined'>
                            <InputLabel>
                                Activity
                            </InputLabel>
                            <Select
                                label='Activity'
                                name='activityId'
                                value={campaignData.activityId}
                                onChange={handleInputChange}
                            >
                                {
                                    activityList.map(
                                        item => (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)
                                    )
                                }

                            </Select>
                        </FormControl>
                    </Grid>
                    {(campaignData && ( Array.isArray(campaignData.activityId) ? (campaignData['activityId'].length > 0) : (campaignData.activityId) ) ) ? 
                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            label="Activity Days"
                            name='activityXDays'
                            value={campaignData.activityXDays}
                            onChange={handleInputChange}
                        />
                    </Grid> : (null)
                    }
                </Grid> 
            }
            { campaignData.campaignTypeMasterId === RNR_CAROUSEL_ID ? null :
            <Grid container>
                <Grid item xs={4}>
                    <FormControl
                        variant='outlined'>
                        <InputLabel>
                            Department
                        </InputLabel>
                        <Select
                            label='Department'
                            name='departmentId'
                            multiple
                            value={campaignData.departmentId}
                            onChange={handleInputChange}
                        >
                            {
                                departmentList.map(
                                    item => (<MenuItem key={item.department_id} value={item.department_id}>{item.department}</MenuItem>)
                                )
                            }

                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl
                        variant='outlined'>
                        <InputLabel>
                            Sub-Department
                        </InputLabel>
                        <Select
                            label='Sub-Department'
                            name='subDepartmentId'
                            multiple
                            value={campaignData.subDepartmentId}
                            onChange={handleInputChange}
                        >
                            {
                                subDepartmentList.map(
                                    item => (<MenuItem key={item.function_id} value={item.function_id}>{item.function}</MenuItem>)
                                )
                            }

                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl
                        variant='outlined'>
                        <InputLabel>
                            Grade
                        </InputLabel>
                        <Select
                            label='Grade'
                            name='gradeId'
                            multiple
                            value={campaignData.gradeId}
                            onChange={handleInputChange}
                        >
                            {
                                gradeList.map(
                                    item => (<MenuItem key={item.id} value={item.id}>{item.grade_name}</MenuItem>)
                                )
                            }

                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            }
            <Grid container>
                { campaignData.campaignTypeMasterId === RNR_CAROUSEL_ID ? null :
                <Grid item xs={4}>
                    <FormControl
                        variant='outlined'>
                        <InputLabel>
                            Location
                        </InputLabel>
                        <Select
                            label='Location'
                            name='locationId'
                            multiple
                            value={campaignData.locationId}
                            onChange={handleInputChange}
                        >
                            {
                                locationList.map(
                                    item => (<MenuItem key={item.id} value={item.id}>{item.locality}</MenuItem>)
                                )
                            }

                        </Select>
                    </FormControl>
                </Grid>
                }
                <Grid item xs={4}>
                    <FormControl
                        variant='outlined'>
                        <InputLabel>
                            Status
                        </InputLabel>
                        <Select
                            label='Status'
                            name='statusId'
                            value={campaignData.statusId}
                            onChange={handleInputChange}
                        >
                           <MenuItem key={0} value={0}>Inactive</MenuItem>
                           <MenuItem key={1} value={1}>Active</MenuItem>

                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <div className={classes.button}>
            <MuiButton
                variant='contained'
                size='large'
                color='primary'
                onClick={handleSubmit}
                disabled={loading || !(superAdmin || (isEditing && pageEdit == 'Yes') || (!isEditing && pageAdd == 'Yes'))}
            >
                Submit &nbsp;
                {loading && (
                    <CircularProgress size={24} />
                )}
                
            </MuiButton> 
            </div>
            <ReactTooltip 
                id="registerTip" 
                place="bottom" 
                effect="solid"
                type="error"
            >
                Do not change for campaign type - R&R Carousel
            </ReactTooltip>
        </form>
    )
}