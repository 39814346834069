import React from "react"
import { Model } from "survey-core"
import { Survey } from "survey-react-ui" 
import * as SurveyCore from "survey-core";
import Sortable from 'sortablejs';
import { sortablejs } from "surveyjs-widgets";

import "survey-core/defaultV2.min.css"
import "./index.css"

const PreviewSurvey = ({
    surveyQueData,
    surveyData
}) => {
    const { surveyName, description } = surveyData
    
    const { question_json = null } = surveyQueData || {}
    let dbQueJson = JSON.parse(question_json)
    dbQueJson = { ...dbQueJson, title: surveyName, description: description }
    
    sortablejs(SurveyCore);
    const loadSurvey = () => {
        const survey = new Model(dbQueJson)
        survey.mode = "display"

        const goBackFunc = () => {
            window.location = `${process.env.REACT_APP_BASE_URL}/survey/index/survey-list`
        }
          
        const renderBack = () => {
            const progressText = "Back"
            const progressSpan = <span className="navigation-text">{progressText}</span>

            return (
                <div className="navigation-block">
                    <div className="sv-action__content">
                        <button className="sd-btn sd-navigation__next-btn go-back-btn" onClick={goBackFunc}>{ progressSpan }</button>
                    </div>
                </div>
            )
        }
        
        return (
            <div>
                { renderBack() }
                { <Survey model={survey} /> }
            </div>
        )
    }
    
    return loadSurvey()
}

export { PreviewSurvey }