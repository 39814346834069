import React, { useState,useEffect } from 'react';

import HeaderLayout from '../HeaderLayout'
import { TableCell, TableContainer, TableHead, TableRow, Table, TableBody, Paper, Grid } from '@material-ui/core'
import PeopleOutlineTwoTone from '@material-ui/icons/PeopleOutlineTwoTone'
import { useStyles } from './style';
import { probationApiUrl } from '../../../utils/CommonUrl';
import { getProbationFormDetails } from '../fetchData';
import { probationConstant } from '../../../constants/ProbationConstant';
import { pageURLConfig, EMPLOYEE_USER_ROLE, menuSubmenuPageCategory } from '../../../constants/CommonConstant';
import { getMenuSubmenuRightsDetail } from '../../../utils/authFunction';
import logo from '../../../utils/load.gif'
const { moduleName, controllerName, actionName } = pageURLConfig.PROBATION_INDEX_STATUS_VIEW
const { NON_ADMIN_CATEGORY } = menuSubmenuPageCategory


export default function ProbationStatusView(props) {
  const classes = useStyles();
  const [isEmploymentConfirmed, setIsEmploymentConfirmed] = useState(false);
  const [letterLink, setLetterLink] = useState(null);
  const [linkNotFound, setLinkNotFound] = useState(false);

  const [isHavingPageAddAccess, setIsHavingPageAddAccess] = useState(false)
  const [isHavingPageEditAccess, setIsHavingPageEditAccess] = useState(false)
  const [isHavingPageViewAccess, setIsHavingPageViewAccess] = useState(null)

  useEffect( () => {
    setPageRights()
  },[])

  const setPageRights = async () => {
    const params = { pageCategory: NON_ADMIN_CATEGORY, moduleName, controllerName, actionName, userGroupId:EMPLOYEE_USER_ROLE}
    const rightsObj = await getMenuSubmenuRightsDetail(params)
    const { 
        isUserSuperAdmin,
        isUserGroupAdmin,
        selfEditRights,
        selfAddRights, 
        selfViewRights 
    } = rightsObj
    const addAccess = isUserSuperAdmin ||  (selfAddRights) 
    const editAccess =  isUserSuperAdmin || selfEditRights
    const viewAccess =  isUserSuperAdmin || (selfViewRights)
    
    setIsHavingPageAddAccess(addAccess)
    setIsHavingPageEditAccess(editAccess)
    setIsHavingPageViewAccess(viewAccess)
  }
  
  useEffect(() => {
    getProbationFormDetails()
    .then(resp => {
      if(resp.length && resp[0].probationAction == probationConstant.probationActionInForm.CONFIRMED && resp[0].probationFormStatus == probationConstant.formStatusKeyToIdMapping.CLOSED) {
        setIsEmploymentConfirmed(true);
        if(resp[0].letterLink) {
          setLetterLink(`${probationApiUrl.GET_FILE_URL}` + btoa(resp[0].letterLink))
        } else {
          setLinkNotFound(true);
        }
        
      }
    })
    .catch(err => {
      console.log('Error occurred while fetching letter link-', err);
    });
  }, []);

  const handleDownload = () => {
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', letterLink);
    a.setAttribute('target', '_blank');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  return (
    <React.Fragment>
        {(!isHavingPageViewAccess) 
        ?
            (isHavingPageViewAccess == null) ? <div className={classes.loader}><img src={logo} alt="loading..." /></div> :
                <div> <h1 style={{margin:'0px'}}>Unauthorised </h1> <br></br> <span>You don't have access to view this page</span> </div> 
        : 
    <div className={classes.root}>
        <Grid container spacing={2} alignItems="center">
          <Grid item> 
            <HeaderLayout
                title='Probation Status'
                subTitle=''
                icon={<PeopleOutlineTwoTone/>}
            />
          </Grid>
        </Grid>
        <Paper elevation={4}>
          <TableContainer component={Paper}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow style={{ backgroundColor: '#dbdbdb'}} >
                  <TableCell className={classes.tableCell} align="center">Status</TableCell>
                  <TableCell className={classes.tableCell} align="center">Document</TableCell>
                  <TableCell className={classes.tableCell} align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  isEmploymentConfirmed ?
                    <TableRow>
                      <TableCell className={classes.tableCell} align="center"><b>Employment Confirmation</b></TableCell>
                      <TableCell className={classes.tableCell} align="center"><b>Confirmation Letter</b></TableCell>
                      <TableCell align="center" className={linkNotFound ? classes.linkNotFound : classes.anchorLink}>
                        {!linkNotFound ?  
                          <a target='_blank' onClick={handleDownload}>
                              DOWNLOAD
                          </a> : "Link Not Found"
                        }
                      </TableCell>
                    </TableRow>
                  :
                  (
                    <TableRow>
                    <TableCell style={{ color: 'red', textAlign: 'center'}} colSpan={3}>No records to display</TableCell>
                    </TableRow>
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
    </div> 
    }
    </React.Fragment>
  )
}
