import React, { useState,useEffect } from 'react';
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CardHeader from '@material-ui/core/CardHeader';
import Card from '@material-ui/core/Card';
import Email from '@material-ui/icons/Email'
// import CardHeader from 'components/CardComponent/CardHeader'
import HeaderLayout from '../HeaderLayout'
import PeopleOutlineTwoTone from '@material-ui/icons/PeopleOutlineTwoTone'

import { useStyles } from './style'
import { Button, FormControl, Grid, Snackbar, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from '@material-ui/core'
import { getDocumentMasterListFetchData, postPendingDocumentStatusReport, getLoggedInEmployeeDetailFetchData,getHrbpListFetchData, getSubDepartmentFetchData, getGradeListFetchData, getLocationListFetchData, getHrbpEmployeeDocumentDetails, sendEmail } from '../fetchData';
// import { getGradeList, getLocationList } from '../../Campaign/fetchData';

import { orange } from '@material-ui/core/colors'
import { withStyles } from '@material-ui/core/styles'
import logo from '../../../utils/load.gif'
import MuiAlert from '@material-ui/lab/Alert/Alert';
import { getMenuSubmenuRightsDetail } from '../../../utils/authFunction';
import { pageURLConfig, menuSubmenuPageCategory } from '../../../constants/CommonConstant';
const { moduleName, controllerName, actionName } = pageURLConfig.EFILING_INDEX_DOCUMENT_STATUS_REPORT
const { ADMIN_CATEGORY } = menuSubmenuPageCategory

const PreviousButton = withStyles(theme => ({
    root: {
      color: theme.palette.getContrastText(orange[500]),
      backgroundColor: orange[500],
      '&:hover': {
        backgroundColor: orange[700]
      }
    }
  }))(Button)

const INITIAL_FILTER_OBJECT = {
    employeeno: '',
    document_status: [],
    list_of_documents: []
}

const SearchFilters = ({handleFilters, isLoading}) => {
    const classes = useStyles()
    const [expandDefault, setExpandDefault] = useState(true)
    const [filterObj, setFilterObj] = useState(INITIAL_FILTER_OBJECT)
    const [departmentList, setDepartmentList] = useState([])
    const [subDepartmentList, setSubDepartmentList] = useState([])
    const [gradeList, setGradeList] = useState([])
    const [locationList, setLocationList] = useState([])
    const [hrbpList, setHrbpList] = useState([])
    const [hrbpAndAdminGroupIdMapping,setHrbpAndAdminGroupIdMapping] = useState({})
    const [hrbpEmployeenoAndAdminGroupIdMapping, setHrbpEmployeenoAndAdminGroupIdMapping] = useState({})
    const [loggedInEmployeeDetails, setLoggedInEmployeeDetails] = useState({})
    const [documentMasterListAndIdMapping, setDocumentMasterListAndIdMapping] = useState([])

    useEffect( () => {
        getLoggedinUser()
        getDocumentMasterList()
    },[])

    const handleChange = event => {
        setFilterObj({ ...filterObj, [event.target.name]: event.target.value })
    }

    const getLoggedinUser = async () => {
        let loggedInEmployeeDetailsResponse = await getLoggedInEmployeeDetailFetchData()
        setLoggedInEmployeeDetails(loggedInEmployeeDetailsResponse.data[0])
    }


    const handleReset = () => {
        setFilterObj({...INITIAL_FILTER_OBJECT, hrbpId: loggedInEmployeeDetails.employeeno})
        handleFilters({...INITIAL_FILTER_OBJECT, hrbpId: loggedInEmployeeDetails.employeeno})
    }


    const getDocumentMasterList = async () => {
        try {
            let response = {}
            response = await getDocumentMasterListFetchData();
            let documentDetailAndIdMapping = {}
            response.data.forEach(element => {
                documentDetailAndIdMapping[element.id] = element
            })
            setDocumentMasterListAndIdMapping(documentDetailAndIdMapping)
        } catch (error) {
            
        }
    }

    return (
            <Accordion
                expanded={expandDefault}
                onChange={() => setExpandDefault(!expandDefault)}
                className={classes.pageContent}
                style={{margin:'9px'}}
                
            >
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1a-content'
                id='panel1a-header'
                className={classes.pageContent}
                >                   
                    <Grid>
                        <Card>
                            <CardHeader
                                title="Filters"
                                style={{ color: '#3f51b5'}}
                                titleTypographyProps={{variant:'subtitle1' }}
                            />
                        </Card>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails className={classes.pageContent}>
                <Grid container>
                <Paper elevation={2} className={classes.root}>
                    <form className={classes.formRoot} noValidate autoComplete='off'>
                    {( isLoading ) 
                        ? 
                        <div className={classes.loader}><img src={logo} alt="loading..." width='150px' height='150px'/></div>
                        : 
                        <Grid container>
                            <Grid container spacing={3}>
                                <Grid item xs={4} sm={12} md={4}>
                                <TextField
                                    id='outlined-basic'
                                    label='Employee Code'
                                    placeholder='Employee Code'
                                    name='employeeno'
                                    value={filterObj.employeeno || ''}
                                    onChange={e => handleChange(e)}
                                    className={classes.formControl}
                                />
                                </Grid>
                                <Grid item xs={4} sm={12} md={4}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel id='demo-simple-select-outlined-label'>
                                    List Of Documents
                                    </InputLabel>
                                    <Select
                                    labelId='demo-simple-select-outlined-label'
                                    id='demo-simple-select-outlined'
                                    name='list_of_documents'
                                    multiple
                                    value={filterObj.list_of_documents || ''}
                                    onChange={e => handleChange(e)}
                                    >
                                    {Object.keys(documentMasterListAndIdMapping).length > 0 &&
                                        Object.keys(documentMasterListAndIdMapping).map((item, index) => (
                                        <MenuItem value={documentMasterListAndIdMapping[item].id} key={documentMasterListAndIdMapping[item].id}>
                                            {documentMasterListAndIdMapping[item].document_type}
                                        </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                </Grid>
                                <Grid item xs={4} sm={12} md={4}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel id='demo-simple-select-outlined-label'>
                                    Document Status
                                    </InputLabel>
                                    <Select
                                    labelId='demo-simple-select-outlined-label'
                                    id='demo-simple-select-outlined'
                                    name='document_status'
                                    multiple
                                    value={filterObj.document_status || ''}
                                    onChange={e => handleChange(e)}
                                    >
                                    <MenuItem value='1'>Pending for submission</MenuItem>
                                    <MenuItem value='2'>Pending for Approval</MenuItem>
                                    <MenuItem value='3'>Approved</MenuItem>
                                    <MenuItem value='4'>Rejected</MenuItem>
                                    </Select>
                                </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        }
                        <Grid
                            container
                            spacing={3}
                            className={classes.marginTop}
                            direction='row'
                            alignItems='center'
                        >
                            <Grid item>
                            <PreviousButton
                                variant='outlined'
                                color='primary'
                                className={classes.resetButton}
                                size='small'
                                disabled={isLoading}
                                onClick={() => handleReset()}
                            >
                                Reset
                            </PreviousButton>
                            <Button
                                variant='contained'
                                color='primary'
                                size='small'
                                style={{ marginLeft: 20 }}
                                disabled={isLoading}
                                onClick={() => handleFilters(filterObj)}
                                className={classes.button}
                            >
                                Download Report
                            </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
                </Grid>
                </AccordionDetails>
            </Accordion>
    )
}


export default function DocumentMasterHrbpScreen() { 
    const classes = useStyles()
    const [snackBarDetails, setSnackBarDetails] = useState({open:false,errorMessage:'',type:'',positionVertical:'top'})
    const [isLoading, setIsLoading] = useState(false)
    const [isHavingPageAddAccess, setIsHavingPageAddAccess] = useState(false)
    const [isHavingPageEditAccess, setIsHavingPageEditAccess] = useState(false)
    const [isHavingPageViewAccess, setIsHavingPageViewAccess] = useState(null)

    useEffect(() => {
        setPageRights()
    }, [])

    const setPageRights = async () => {
        const params = { pageCategory: ADMIN_CATEGORY, moduleName, controllerName, actionName}
        const rightsObj = await getMenuSubmenuRightsDetail(params)
        const { 
            isUserSuperAdmin,
            isUserGroupAdmin,
            allEmployeeAddRights,
            allEmployeeEditRights, 
            allEmployeeViewRights 
       } = rightsObj
       const addAccess = isUserSuperAdmin || 
            (isUserGroupAdmin && allEmployeeAddRights) 
       const editAccess =  isUserSuperAdmin || 
            (isUserGroupAdmin && allEmployeeEditRights)
       const viewAccess =  isUserSuperAdmin || 
            (isUserGroupAdmin && allEmployeeViewRights)
        
        setIsHavingPageAddAccess(addAccess)
        setIsHavingPageEditAccess(editAccess)
        setIsHavingPageViewAccess(viewAccess)
    }

    const handleFilters = async ( filterObj ) => {
        try {
            setIsLoading(true)
            let response = {}
            response = await postPendingDocumentStatusReport({statusReportFilterObj: filterObj, pendingStatusReportHrops: true})
            if(response.statusCode == '400'){
                setSnackBarDetails({open:true,errorMessage:response.message,type:'error',positionVertical:'top'})
            } else {
                let downloadFileName = `Documents_Status_Report_${new Date().toLocaleDateString('en-GB', {
                    weekday:'short',
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric',
                    hour: 'numeric',
                    minute:'numeric',
                    second:'numeric'
                  }).replace(/ /g, '_')
                    .replace(/,/g, '')}.csv`
                const linkSource = `data:text/csv;charset=utf-8,${response}`;
                const downloadLink = document.createElement('a');
                document.body.appendChild(downloadLink);

                downloadLink.href = linkSource;
                downloadLink.target = '_self';
                downloadLink.download = downloadFileName;
                downloadLink.click();
            }
            setIsLoading(false)
        } catch (error) {
            
        }
    }
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackBarDetails({open:false,errorMessage:'',type:''});
    };

    return (
        <div>
         {(!isHavingPageViewAccess) 
            ? 
            (isHavingPageViewAccess == null) ? <div className={classes.loader}><img src={logo} alt="loading..." /></div> :
            <div> <h1 style={{margin:'0px'}}>Unauthorised </h1> <br></br> <span>You don't have access to view this page</span> </div> 
            :   
            <div className={classes.root}>
                {snackBarDetails && snackBarDetails.open ? 
                    <Snackbar
                        anchorOrigin={{ vertical:snackBarDetails.positionVertical, horizontal:'center' }}
                        open={snackBarDetails.open}
                        autoHideDuration={4000}
                        onClose={handleSnackbarClose}
                    >
                        <MuiAlert severity={snackBarDetails.type}>{snackBarDetails.errorMessage}</MuiAlert>
                    </Snackbar> : (null)
                }
                <Grid container>
                    <Grid> 
                    <HeaderLayout
                        title='Document Status Report'
                        subTitle=''
                        icon={<PeopleOutlineTwoTone/>}
                    />
                    </Grid>
                </Grid>
                <SearchFilters
                handleFilters={handleFilters}
                isLoading={isLoading}
                />
            </div>
        }
        </div>
    )

}
