import React, { Fragment, useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import { useStyles } from './style'
import { 
    Paper,
    FormControl,
    TextField,
    FormLabel,
    Checkbox,
    FormControlLabel,
    InputLabel,
    Select,
    Input,
    MenuItem,
    FormHelperText
} from '@material-ui/core'
import FileUpload from '../FileUpload'
import FileListTable from '../FileListTable'

import { 
    onlyAlphabets,
    checkNameRegEx,
    onlyNumber,
    checkPinCodeRegEx,
    checkAddressRegEx,
    onlyNumberForContact,
    charOnlyForRelationship,
    checkMobileRegEx,
    checkLandlineRegEx
} from '../../../utils/Validation'
import { joinerConst } from '../../../constants/JoinerConstant'

import {
    getUploadedFiles,
    postUploadedFiles
} from '../fetchData'

const relationList = [
    `Father`,
    `Mother`,
    `Spouse`,
    `Son`,
    `Daughter`,
    `Brother`,
    `Sister`,
    `Father-in-law`,
    `Mother-in-law`,
    `Maternal Uncle`,
    `Paternal Uncle`,
    `Maternal Aunt`,
    `Paternal Aunt`,
    `Brother-in-law`,
    `Sister-in-law`,
    `Paternal First Cousin`,
    `Maternal First Cousin`,
    `Friend`,
    `Other Relative`
  ]

const Contact = ({
    errorObj,
    offerData,
    joinerContactData = [],
    handleChildData = () => {},
    handleChildDataValidation = () => {},
    handleNotification=()=>{},
    submitCheck=false,
}) => {

    const [loading, setLoading] = useState(false)
    const classes = useStyles()
    const {
        offerId,
        email_id,
        mobile,
        address1,
        address2,
        address3,
        pin_code,
        present_address1,
        present_address2,
        present_address3,
        present_pin_code
    } =  offerData
    
    const [{
        id=null,
        mobile_no_2='',
        landline_no_1='',
        emergency_contact_name='',
        emergency_contact_no='',
        emergency_contact_relationship='',
        present_address_1='',
        present_address_2='',
        present_address_3='',
        present_address_pin_code='',
        permanent_address_1='',
        permanent_address_2='',
        permanent_address_3='',
        permanent_address_pin_code=''
    } = {}] = joinerContactData

    const initObj = {
        offerId,
        id: id,
        emailId: email_id,
        mobileNumber: mobile,
        mobileNumber2: mobile_no_2,
        landlineNumber1: landline_no_1,
        
        addressLine1: permanent_address_1 || address1,
        addressLine2: permanent_address_2 || address2,
        addressLine3: permanent_address_3 || address3,
        pinCode: permanent_address_pin_code || pin_code,
        presentAddressLine1: present_address_1 || present_address1,
        presentAddressLine2: present_address_2 || present_address2,
        presentAddressLine3: present_address_3 || present_address3,
        presentPinCode: present_address_pin_code || present_pin_code,
        emergencyContactName: emergency_contact_name,
        emergencyContactNumber: emergency_contact_no,
        emergencyContactRelation: emergency_contact_relationship,
    }
    

    const checkBothAddressSame = ({
        addressLine1,
        addressLine2,
        addressLine3,
        pinCode,
        presentAddressLine1,
        presentAddressLine2,
        presentAddressLine3,
        presentPinCode
    }) => {
        let flag = true
        if (String(addressLine1) !== String(presentAddressLine1)) {
            flag = false
        }
        
        if (String(addressLine2) !== String(presentAddressLine2)) {
            flag = false
        }
        if (String(addressLine3) !== String(presentAddressLine3)) {
            flag = false
        }
        if (String(pinCode) !== String(presentPinCode)) {
            flag = false
        }

        return flag
    }

    initObj.isSameAsPermanent = checkBothAddressSame(initObj)
    
    let initErrObj = {};
    for (let k in initObj) {
      initErrObj[k] = { status: false, text: '' };
    }
    
    const [contactObj, setContactObj] = useState(initObj)
    const [newErrorObj, setNewErrorObj] = useState(initErrObj)

    const [fileList, setFileList] =  useState([])
    // let isFileAvl = fileList && fileList.length ? true : false
    // if (fileList && fileList.length) {
    //     handleChildDataValidation('contactFileUpload', {status: !isFileAvl, text:''})
    // }
    const [isFileUploadMandatory, setFileUploadMandatory] = useState(false)
    const [fileData, setFileData] = useState([])
    const [fileNotification, setFileNotification] = useState({status: false, text:'', color: ''})

    let {
        emailId,
        mobileNumber,
        mobileNumber2,
        landlineNumber1,
        addressLine1,
        addressLine2,
        addressLine3,
        pinCode,
        isSameAsPermanent,
        presentAddressLine1,
        presentAddressLine2,
        presentAddressLine3,
        presentPinCode,
        emergencyContactName,
        emergencyContactNumber,
        emergencyContactRelation
    } = contactObj

    if (isSameAsPermanent) {
        presentAddressLine1 = addressLine1
        presentAddressLine2 = addressLine2
        presentAddressLine3 = addressLine3
        presentPinCode = pinCode

        errorObj.presentAddressLine1 = errorObj.addressLine1
        errorObj.presentAddressLine2 = errorObj.addressLine2
        errorObj.presentAddressLine3 = errorObj.addressLine3
        errorObj.presentPinCode = errorObj.pinCode
    }

    // const isContactDataChange = () => {
    //     return (
    //         !isEmpty(present_address_1)
    //         || 
    //         !isEmpty(present_address_2)
    //         || 
    //         !isEmpty(present_address_3)
    //         || 
    //         !isEmpty(present_address_pin_code)
    //     )
    // }
    
    //let isContactDataChanged = isContactDataChange()

    useEffect(() => {
        for (let k in contactObj) {
            checkChange(k, contactObj[k])
            handleChildData(k, contactObj[k])
            let err = checkContactDataError(k, contactObj[k])
            handleChildDataValidation(k, err)
        }
        
        if(submitCheck){
            setNewErrorObj({...newErrorObj,...errorObj})
        }
    }, [submitCheck,contactObj])

    useEffect(() => {
        getUploadedDocuments(offerId, true)
    }, [])

    const getUploadedDocuments = async(offerId, isInit = false) => {
        const query = {
                        objectId: offerId,
                        objectType: 'ContactDataDocument',
                        domainId: 2,
                    }
        let data = []
        try {
            data = await getUploadedFiles(query)
            data = data.data
        } catch(err) {
            console.log(err)
        }
        setFileList(data)
    }
    
    const checkChange = (key, value) => {
        let flag = false
        switch (key) {
            case "presentAddressLine1":
                if (present_address1.trim()!==value) {
                    flag = true
                }
                break
            case "presentAddressLine2":
                if (present_address2.trim()!==value) {
                    flag = true
                }
                break
            case "presentAddressLine3":
                if (present_address3.trim()!==value) {
                    flag = true
                }
                break
            case "presentPinCode":
                if (String(present_pin_code)!==value) {
                    flag = true
                }
                break
            default:
                break
        }
        flag = checkAddressChangeForFileUpload()
        // let text = ''
        // if (flag) { 
        //     text = 'Since you have changed present address, kindly upload the supporting documents for the same.'
        // }
        
        setFileUploadMandatory(flag)
        // handleChildDataValidation('contactFileUpload', {text: text, status: flag})
    }

    const checkAddressChangeForFileUpload = () => {
        let flag = false
        const pre_address1 = present_address_1 ? String(present_address_1).trim() : String(present_address1).trim()
        const pre_address2 = present_address_2 ? String(present_address_2).trim() : String(present_address2).trim()
        const pre_address3 = present_address_3 ? String(present_address_3).trim() : String(present_address3).trim()
        const pre_pin_code = present_address_pin_code ? String(present_address_pin_code).trim() : String(present_pin_code).trim()

        const preAddressLine1 = String(presentAddressLine1).trim()
        const preAddressLine2 = String(presentAddressLine2).trim()
        const preAddressLine3 = String(presentAddressLine3).trim()
        const prePinCode = String(presentPinCode).trim()

        if (preAddressLine1 && pre_address1 !== preAddressLine1) {
            flag = true
        }
        if (preAddressLine2 && pre_address2 !== preAddressLine2) {
            flag = true
        }
        if (preAddressLine3 && pre_address3 !== preAddressLine3) {
            flag = true
        }
        if (prePinCode && pre_pin_code !== prePinCode) {
            flag = true
        }
        
        return flag 
    }

    const handleChange = (e) => {
        let key = e.target.name
        let val = e.target.value
        let obj = {[key]: val}
        if (e.target.name === 'isSameAsPermanent') {
            val = e.target.checked
            obj = {...obj, ...copyContactObj(e.target.checked)}
        }
        checkChange(key, val)
        handleChildData(key, val)
        setContactObj({...contactObj, ...obj})
        let err = checkContactDataError(key, val)
        handleChildDataValidation(key, err)
        setNewErrorObj({...newErrorObj,[key]:{status: err.status,text:err.text}})
    }

    const copyContactObj = (checked) => {
        let obj = {}
        if (checked) {
            obj = {
                ...obj, 
                presentAddressLine1: addressLine1,
                presentAddressLine2: addressLine2,
                presentAddressLine3: addressLine3,
                presentPinCode: pinCode,
                isSameAsPermanent: true
            }
        } else {
            obj = {
                ...obj, 
                presentAddressLine1: present_address1,
                presentAddressLine2: present_address2,
                presentAddressLine3: present_address3,
                presentPinCode: present_pin_code,
                isSameAsPermanent: false
            }
        }
        return obj
    }

    const handleInput = (e, type) => {
        switch (type) {
            case "pinCode":
            case "presentPinCode":
                if (!onlyNumber(e.which)) {
                    e.preventDefault()
                }
                break
            case "emergencyContactName":
                if (!onlyAlphabets(e.which)) {
                    e.preventDefault()
                }
                break
            case "mobileNumber2":
            case "landlineNumber1":
            case "emergencyContactNumber":
                if (!onlyNumberForContact(e.which)) {
                    e.preventDefault()
                }
                break
            case "emergencyContactRelation":
                if (!charOnlyForRelationship(e.which)) {
                    e.preventDefault()
                }
                break
            default:
                break
        }
    }

    const checkContactDataError = (key, value) => {
        let text = ''
        let status = false
        switch (key) {
            case "presentAddressLine1":
                if (value==='' || !checkAddressRegEx(value)) {
                    text = 'Invalid address line 1'
                    status = true
                }
                break

            case "presentAddressLine2":
                if (value==='' ||!checkAddressRegEx(value)) {
                    text = 'Invalid address line 2'
                    status = true
                }
                break
            
            case "presentAddressLine3":
                if (value==='' || !checkAddressRegEx(value)) {
                    text = 'Invalid address line 1'
                    status = true
                }
                break
           
            case "presentPinCode":
                if (value==='' || !checkPinCodeRegEx(value)) {
                    text = 'Invalid pin code'
                    status = true
                }
                break

            case "emergencyContactName":
                if (!checkNameRegEx(value)) {
                    text = 'Invalid emergency contact name'
                    status = true
                }
                break
            case "mobileNumber2":
                if ((value !== '' && !checkMobileRegEx(value))) {
                    text = 'Invalid mobile number'
                    status = true
                }
                break
            case "emergencyContactNumber":
                if (!checkMobileRegEx(value)) {
                    text = 'Invalid emergency contact number'
                    status = true
                }
                break
            case "landlineNumber1":
                if (value !== '' && !checkLandlineRegEx(value)) {
                    text = 'Invalid landline number'
                    status = true
                }
                break
            case "emergencyContactRelation":
                if (value === '') {
                    text = 'Invalid emergency contact relation'
                    status = true
                }
                break
            default:
                break
        }

        return {text, status}
    }

    const { 
        fileExtenstions,
        maxUploadFileSize,
        oneMbInBytes
    } = joinerConst

    
    const onFileChange = async (e) => {
        setFileNotification({status:false, text:'', color:''})
        if (fileData.length >= 3) {
            alert('You can select maximum 3 files to upload!')
            return false
        }

        if (e.target.files.length) {
            let files = e.target.files[0]
            if (!fileExtenstions.includes(files.name.split('.').pop())) {
                alert(`Selected file type is invalid! Allowed file types are ${fileExtenstions.join(',')}`)
                return false
            }
            if (files.size > maxUploadFileSize) {
                alert(`Exceeding file size limit! Upload file size upto ${maxUploadFileSize/oneMbInBytes} kb!`)
                return false
            }
            setFileData([...fileData, { inputFile:files }])
        }
    }

    const handleFileUpload = async () => {
        let noitfyStatus=false
        let text=''
        let color=''
        if (!fileData.length) {
            noitfyStatus=true
            text='Choose atleast 1 file!'
            color='red'
        }

        setLoading(true)
        const [{ status } = {}] = await Promise.all(
                                        fileData
                                        .filter(({inputFile}) => inputFile !== null && inputFile !== undefined)
                                        .map(({inputFile}) => {
                                            const res = imageUpload({
                                                inputFile,
                                                objectId:offerId,
                                                objectType: 'ContactDataDocument',
                                                documentType: 'ContactDataDocument'
                                            })
                                            return postUploadedFiles(res)
                                        }),
                                    )
                                    .catch(err => console.log(err))

        if (status === 200) {
            handleChildDataValidation('contactFileUpload', {status: false, text: ''})
            getUploadedDocuments(offerId)
            noitfyStatus=true
            text='File uploaded successfully!'
            color='green'
            handleNotification({type:'', message:''})
        } else {
            noitfyStatus=true
            text='Something went wrong!'
            color='red'
        }

        setLoading(false)
        setFileData([])
        setFileNotification({status:noitfyStatus, text, color})
    }

    const imageUpload = ({
        inputFile, 
        objectId,
        objectType, 
        documentType
    }) => {
        const formData = new FormData()
        formData.append('file', inputFile)
        formData.append('objectId', objectId)
        formData.append('objectType', objectType)
        formData.append('documentType', documentType)
        formData.append('domainId', 2)
        return formData
    }

    const handleSelectedFile = (e, index) => {
        const newFileData = fileData.filter((obj, i) => {
            return i!==index
        })
        setFileData(newFileData)
    }

    return (
        <Fragment>
                <Grid container spacing={2} className={classes.root}>
                    <Grid item sm={10}>
                            <Paper className={classes.paperFirst}>
                            <div style={{color: "red", float: "left", fontSize: "11px", paddingTop: "10px"}}>
                                <sup>*</sup> Kindly read general instructions before filling in the information on this page
                            </div>
                                <Grid container spacing={3}>
                                    <FormControl className={classes.formControlEmailId}>
                                    <TextField
                                        disabled={true}
                                        label="Email Id"
                                        value={emailId}
                                    />
                                </FormControl>
                                    <FormControl className={classes.formControl}>
                                    <TextField
                                        disabled={true}
                                        label="Mobile Number"
                                        value={mobileNumber}
                                    />
                                </FormControl>
                                <FormControl className={classes.formControlMobile2}>
                                    <TextField
                                        autoComplete="off"
                                        label="Mobile Number(Alternate)"
                                        value={mobileNumber2}
                                        name="mobileNumber2"
                                        onChange={e => handleChange(e)}
                                        onKeyPress={e => handleInput(e, "mobileNumber2")}
                                        inputProps={{ minLength:9, maxLength:14 }}
                                        error={newErrorObj.mobileNumber2.status}
                                        helperText={newErrorObj.mobileNumber2.text}
                                        placeholder="Mobile Number(Alternate)"
                                    />
                                    <div style={{fontSize:10, wordWrap:'normal',width:'250px', textAlign: 'left',marginTop:'5px'}}>
                                        Please add country code before entering mobile number. 
                                        For example, '+91XXXXXXXXXX'
                                    </div>
                                </FormControl>
                                <FormControl className={classes.formControlMobile2}>
                                    <TextField
                                        autoComplete="off"
                                        label="Landline Number"
                                        value={landlineNumber1}
                                        name="landlineNumber1"
                                        onChange={e => handleChange(e)}
                                        onKeyPress={e => handleInput(e, "landlineNumber1")}
                                        inputProps={{ minLength:1, maxLength:100 }}
                                        error={newErrorObj.landlineNumber1.status}
                                        helperText={newErrorObj.landlineNumber1.text}
                                        placeholder="Landline Number"
                                    />
                                </FormControl>
                                </Grid>
                            </Paper>
                            <Paper className={classes.paper}>
                                <Grid container spacing={3}>
                                    <FormControl className={classes.formControlLable}>
                                        <FormLabel>Permanent Address:</FormLabel>
                                    </FormControl>
                                    <FormControl className={classes.formControl}>
                                    <TextField
                                        autoComplete="off"
                                        multiline={true}
                                        label="Address Line 1"
                                        value={addressLine1}
                                        name="addressLine1"
                                        required={false}
                                        onChange={e => handleChange(e)}
                                        onKeyPress={e => handleInput(e, "addressLine1")}
                                        inputProps={{ minLength:1, maxLength:100, readOnly: true }}
                                        error={newErrorObj.addressLine1.status}
                                        helperText={newErrorObj.addressLine1.text}
                                        placeholder="House No, Street, Landmark"
                                        disabled={true}
                                    />
                                    </FormControl>
                                    <FormControl className={classes.formControl}>
                                    <TextField
                                        autoComplete="off"
                                        multiline={true}
                                        label="Address Line 2"
                                        value={addressLine2}
                                        name="addressLine2"
                                        required={false}
                                        onChange={e => handleChange(e)}
                                        onKeyPress={e => handleInput(e, "addressLine2")}
                                        inputProps={{ minLength:1, maxLength:100, readOnly: true }}
                                        error={newErrorObj.addressLine2.status}
                                        helperText={newErrorObj.addressLine2.text}
                                        placeholder="City"
                                        disabled={true}
                                        />
                                </FormControl>
                                    <FormControl className={classes.formControl}>
                                        <TextField
                                            autoComplete="off"
                                            multiline={true}
                                            label="Address Line 3"
                                            value={addressLine3}
                                            name="addressLine3"
                                            required={false}
                                            onChange={e => handleChange(e)}
                                            onKeyPress={e => handleInput(e, "addressLine3")}
                                            inputProps={{ minLength:1, maxLength:100, readOnly: true }}
                                            error={newErrorObj.addressLine3.status}
                                            helperText={newErrorObj.addressLine3.text}
                                            placeholder="State"
                                            disabled={true}
                                            />
                                    </FormControl>
                                    <FormControl className={classes.formControl}>
                                            <TextField
                                                autoComplete="off"
                                                required={false}
                                                label="Pin Code"
                                                value={pinCode}
                                                name="pinCode"
                                                onChange={e => handleChange(e)}
                                                onKeyPress={e => handleInput(e, "pinCode")}
                                                inputProps={{ minLength:4, maxLength:6, readOnly: true }}
                                                error={newErrorObj.pinCode.status}
                                                helperText={newErrorObj.pinCode.text}
                                                placeholder="Pin Code"
                                                disabled={true}
                                            />
                                        </FormControl>
                                </Grid>
                                <Grid container spacing={3}>
                                <FormControl className={classes.formControlLable}>
                                        <FormLabel>Present Address:</FormLabel>
                                        <FormControlLabel
                                            control={
                                            <Checkbox
                                                checked={isSameAsPermanent}
                                                onChange={e => handleChange(e)}
                                                name="isSameAsPermanent"
                                                color="primary"
                                            />
                                            }
                                            label="Same As Permanent"
                                        />
                                    </FormControl>
                                <FormControl className={classes.formControl}>
                                <TextField
                                    autoComplete="off"
                                    multiline={true}
                                    disabled={isSameAsPermanent}
                                    label="Address Line 1"
                                    value={presentAddressLine1}
                                    name="presentAddressLine1"
                                    required={true}
                                    onChange={e => handleChange(e)}
                                    onKeyPress={e => handleInput(e, "presentAddressLine1")}
                                    inputProps={{ minLength:1, maxLength:100 }}
                                    error={newErrorObj.presentAddressLine1.status}
                                    helperText={newErrorObj.presentAddressLine1.text}
                                    placeholder="House No., Street, Landmark"
                                />
                            </FormControl>
                                <FormControl className={classes.formControl}>
                                <TextField
                                        autoComplete="off"
                                        multiline={true}
                                        disabled={isSameAsPermanent}
                                        label="Address Line 2"
                                        value={presentAddressLine2}
                                        name="presentAddressLine2"
                                        required={true}
                                        onChange={e => handleChange(e)}
                                        onKeyPress={e => handleInput(e, "presentAddressLine2")}
                                        inputProps={{ minLength:1, maxLength:100 }}
                                        error={newErrorObj.presentAddressLine2.status}
                                        helperText={newErrorObj.presentAddressLine2.text}
                                        placeholder="City"
                                    />
                            </FormControl>
                                <FormControl className={classes.formControl}>
                                    <TextField
                                            autoComplete="off"
                                            multiline={true}
                                            disabled={isSameAsPermanent}
                                            label="Address Line 3"
                                            value={presentAddressLine3}
                                            name="presentAddressLine3"
                                            required={true}
                                            onChange={e => handleChange(e)}
                                            onKeyPress={e => handleInput(e, "presentAddressLine3")}
                                            inputProps={{ minLength:1, maxLength:100 }}
                                            error={newErrorObj.presentAddressLine3.status}
                                            helperText={newErrorObj.presentAddressLine3.text}
                                            placeholder="State"
                                        />
                                </FormControl>
                                <FormControl className={classes.formControl}>
                                            <TextField
                                                autoComplete="off"
                                                disabled={isSameAsPermanent}
                                                required={true}
                                                label="Pin Code"
                                                value={presentPinCode}
                                                name="presentPinCode"
                                                onChange={e => handleChange(e)}
                                                onKeyPress={e => handleInput(e, "presentPinCode")}
                                                inputProps={{ minLength:4, maxLength:6 }}
                                                error={newErrorObj.presentPinCode.status}
                                                helperText={newErrorObj.presentPinCode.text}
                                                placeholder="Pin Code"
                                            />
                                        </FormControl>
                            </Grid>
                            </Paper>
                            <Paper className={classes.paperFirst}>
                                <Grid container spacing={3}>
                                <FormControl className={classes.formControlLable}>
                                    <FormLabel>Emergency Contact Details:</FormLabel>
                                </FormControl>
                                <FormControl className={classes.formControl}>
                                    <TextField
                                        autoComplete="off"
                                        label="Name"
                                        value={emergencyContactName}
                                        name="emergencyContactName"
                                        required={true}
                                        onChange={e => handleChange(e)}
                                        onKeyPress={e => handleInput(e, "emergencyContactName")}
                                        inputProps={{ minLength:1, maxLength:100 }}
                                        error={newErrorObj.emergencyContactName.status}
                                        helperText={newErrorObj.emergencyContactName.text}
                                    />
                                </FormControl>
                                <FormControl className={classes.formControlMobile2}>
                                    <TextField
                                        required={true}
                                        autoComplete="off"
                                        label="Contact Number"
                                        value={emergencyContactNumber}
                                        name="emergencyContactNumber"
                                        onChange={e => handleChange(e)}
                                        onKeyPress={e => handleInput(e, "emergencyContactNumber")}
                                        inputProps={{ minLength:1, maxLength:100 }}
                                        error={newErrorObj.emergencyContactNumber.status}
                                        helperText={newErrorObj.emergencyContactNumber.text}
                                    />
                                    <div style={{fontSize:10, wordWrap:'normal',width:'250px', textAlign: 'left',marginTop:'5px'}}>
                                        Please add country code before entering mobile number. 
                                        For example, '+91XXXXXXXXXX'
                                    </div>
                                </FormControl>
                                <FormControl className={classes.formControl}>
                                    <InputLabel
                                    required={true}
                                    error={newErrorObj.emergencyContactRelation.status}
                                    >
                                    Relationship
                                    </InputLabel>
                                    <Select
                                    value={emergencyContactRelation}
                                    name='emergencyContactRelation'
                                    input={<Input id='emergencyContactRelation' />}
                                    onChange={(e) => handleChange(e)}
                                    error={newErrorObj.emergencyContactRelation.status}
                                    >
                                    <MenuItem value='' disabled>
                                        <em>Select the value</em>
                                    </MenuItem>
                                    {relationList.map((element, index) => {
                                        return (
                                        <MenuItem key={index} value={element}>
                                            {element}
                                        </MenuItem>
                                        );
                                    })}
                                    </Select>
                                    <FormHelperText style={{ color: 'red' }}>
                                    {newErrorObj.emergencyContactRelation.text}
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                            </Paper>
                            {
                                isFileUploadMandatory ? (
                                    <div>
                                    <FileUpload 
                                        loading={loading}
                                        fileData={fileData} 
                                        fileNotification={fileNotification}
                                        onFileChange={onFileChange}
                                        handleFileUpload={handleFileUpload}
                                        handleSelectedFile={handleSelectedFile}
                                        customLable='Kindly upload all the required supporting documents for the change in address by clicking this button'
                                        supportingDocList="Supporting Docs: {Present Address Change - Proof of Present Address}"
                                    />
                                    </div>
                                ) : (null)
                            }
                            {
                                isFileUploadMandatory && fileList && fileList.length ? (
                                    <FileListTable fileList={fileList} limit={2} />
                                ) : (null)
                            }
                    </Grid>
                    <Grid item sm={2}>
                        <Paper className={classes.paperGeneral}>
                            <div>
                                <ul>
                                    <li className='unorder-list'>&nbsp;</li>
                                    <li className='unorder-list'>General Instructions:</li>
                                    <li className='unorder-list'>&nbsp;</li>
                                    <li>Please share your emergency contact details.</li>
                                    <li>In case you change your present address then you have to upload a supporting document.</li>
                                    <li>Allowed file type: - pdf, doc, jpg, jpeg, png</li>
                                    <li>To move ahead, click SAVE & NEXT</li>
                                </ul>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
        </Fragment>
    )
}

export default Contact