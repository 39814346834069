import { makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#fdfdff',
        marginLeft: theme.spacing(0)
    },
    pageHeader:{
        padding:theme.spacing(1),
        display:'flex',
        marginBottom:theme.spacing(1),
        paddingLeft: "20px",
        paddingBottom: '0px'
    },
    pageIcon:{
        display:'inline-block',
        padding:theme.spacing(2),
        color:'#3c44b1'
    },
    pageTitle:{
        paddingLeft:theme.spacing(4),
        '& .MuiTypography-subtitle2':{
            opacity:'0.6'
        }
    },
    anchorLink: {
        textDecoration: "none !important",
        color: "rgb(69, 89, 201)",
        fontWeight: "bold"
    }
}));
