import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiTableCell-root': {
            textAlign: 'center'
        }
    },
    pageContent: {
        margin: theme.spacing(5),
        padding: theme.spacing(3),
        marginLeft: theme.spacing(1),
        marginTop: '0px'
        // width: '50%'
    },
    saveButton : {
        margin: theme.spacing(5),
        color: 'white',
        backgroundColor: 'blue',
        float: 'right'
    },
    uploadButton : {
        '&:hover': {
            cursor: 'pointer'
        }
    },
    modifyButton : {
        color: 'black',
        backgroundColor: 'lightgrey',
    },
    loader: {
        display: 'flex',
        justifyContent: 'center'
    },
    table: {
        width: '100%'
    },
    input: {
        display: "none"
    },
    button: {
        margin: theme.spacing(3),
        padding: theme.spacing(1)
    },
    formControl: {
        marginTop: '-10px',
        width: '100%'
        //minWidth: 200
    },
    addNewDocumentButton : {
        marginLeft: '7px',
        backgroundColor: 'royalblue',
        '&:hover': {
            backgroundColor: '#3f51b5'
        },
        fontSize: 'small'
    },
    removeDocumentButton: {
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: 'ligthgrey'
        }
    }
}))