import React from 'react'
import { useStyles } from './style'
import { 
    Paper,
    FormControl,
    FormHelperText,
    Button,
    Fab,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Grid
} from '@material-ui/core'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate"
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined'
import CircularProgress from '@material-ui/core/CircularProgress'
import { joinerConst } from '../../../constants/JoinerConstant'

let allowedFileTypes = ''
joinerConst.fileExtenstions.forEach((ele, index) => {
    allowedFileTypes += `.${ele}`
    if (index+1 !== joinerConst.fileExtenstions.length) {
        allowedFileTypes += `, `
    }
})

const FileUpload = ({
    fileData,
    fileNotification,
    onFileChange=()=>{},
    handleFileUpload=()=>{},
    handleSelectedFile=()=>{},
    customLable=null,
    loading=false,
    supportingDocList='',
    acceptedFileTypes=allowedFileTypes
}) => {
    const classes = useStyles()
    return (
            <Paper className={classes.paper}>
                <Grid container justifyContent="space-evenly" alignItems="center">
                    <div style={{ fontSize:"13px" }}>{customLable}
                        <div style={{ fontSize:"9px", color:"red" }}>{supportingDocList}</div>
                    </div>
                    <FormControl className={classes.formControl}> 
                        <input
                            accept={acceptedFileTypes}
                            className={classes.input}
                            id="contained-button-file"
                            type="file"
                            onChange={onFileChange}
                            title="Select a file"
                        />
                        <span>Select File</span>
                        <label htmlFor="contained-button-file">
                        <Fab component="span" className={classes.button}>
                            <AddPhotoAlternateIcon />
                        </Fab>
                        </label> 
                        <FormHelperText style={{color:fileNotification.color}}>{fileNotification.text}</FormHelperText>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                            {
                                <Table className={classes.table} aria-label="simple table">
                                    <TableBody>
                                    {
                                        fileData.map(({inputFile}, index) => {
                                        return (
                                            <TableRow key={index}>
                                                    <TableCell component="th" scope="row">
                                                        {inputFile.name}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <span onClick={e=>handleSelectedFile(e, index)}>
                                                            <DeleteForeverOutlinedIcon className={classes.icon} />
                                                        </span>
                                                    </TableCell>
                                            </TableRow>
                                        )
                                        })
                                    }
                                    </TableBody>
                                </Table>
                            }
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        {
                            fileData.length ? (
                                <label htmlFor="upload-photo">
                                    <Button
                                        startIcon={<CloudUploadIcon />}
                                        className="btn-upload"
                                        color="secondary"
                                        variant="contained"
                                        component="span"
                                        onClick={handleFileUpload}
                                        disabled={loading}
                                    >
                                        Upload
                                        {loading && (
                                            <CircularProgress size={24} className={classes.buttonProgress} />
                                        )}
                                    </Button>
                                </label>
                            ) : (null)
                        }
                    </FormControl> 
                </Grid>
            </Paper>
        
    )
}
export default FileUpload