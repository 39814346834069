import React from "react"
import { useStyles } from "./style"
import { Grid, Card, Paper } from "@material-ui/core"
import ReportTable from "./ReportTable"
import NoRecordToDisplay from "./NoRecordToDisplay"

import {
    listingSort,
    getComparator
} from "../../../constants/CommonConstant"
import { getSortBy } from "./Comparison"

const columns = [
    {
        id: "timeFrame",
        label : "Time Frame",
        disableSorting: false
    },
    {
        id: "tabp_name",
        label: "TABP",
        disableSorting: false
    },
    {
        id: "tabp",
        label: "TABP Ecode",
        disableSorting: false
    },
    {
        id: "department_name",
        label: "Department",
        disableSorting: false
    },
    {
        id: "function_name",
        label: "Sub Department",
        disableSorting: false
    },
    {
        id: "grade_name",
        label: "Grade",
        disableSorting: false
    },
    {
        id: "designation_name",
        label: "Designation",
        disableSorting: false
    },
    {
        id: "location_name",
        label: "Location",
        disableSorting: false
    },
    {
        id: "rm_name",
        label: "Reporting Manager",
        disableSorting: false
    },
    {
        id: "reportingManager",
        label: "RM Ecode",
        disableSorting: false
    },
    {
        id: "function_type",
        label: "Function Type",
        disableSorting: false
    },
    {
        id: "male_count",
        label: "Male",
        disableSorting: false
    },
    {
        id: "female_count",
        label: "Female",
        disableSorting: false
    },
    {
        id: "total_headcount",
        label: "Total HC",
        disableSorting: false
    },
    {
        id: "female_percent",
        label: "Female HC as % of Total HC",
        disableSorting: false
    }
]

const getColumns = ({ allFilterData }) => {
    let downloadableColumns = []

    for (const obj of columns) {
        if (allFilterData.includes(obj.id)) {
            downloadableColumns.push({
                id: obj.id,
                key: obj.id,
                header: obj.label,
                label: obj.label,
                disableSorting: false
            })

            if (obj.id === "tabp_name") {
                downloadableColumns.push({
                    id: "tabp",
                    key: "tabp",
                    header: "TABP Ecode",
                    label: "TABP Ecode",
                    disableSorting: false
                }) 
            }
            if (obj.id === "rm_name") {
                downloadableColumns.push({
                    id: "reportingManager",
                    key: "reportingManager",
                    header: "RM Ecode",
                    label: "RM Ecode",
                    disableSorting: false
                }) 
            }
        }
    }
    
    return downloadableColumns
}

const getObjectToArrayList = ({ data = {}, dynamicColumns = [], allFilterData }) => {
    let txnRows = []
    let totalRow = {
        male_count: 0,
        female_count: 0,
        total_headcount: 0,
        female_percent: "0.00"
    }

    const allRowCount = Object.keys(data).length

    for (const ordered in data) {
        const rowObj = data[ordered]
        let pushObj = { timeFrameOrder: rowObj.timeFrameOrder || "" }

        for (const colObj of dynamicColumns) {
            pushObj[colObj.key] = rowObj[colObj.key]
            if (["male_count", "female_count", "total_headcount"].includes(colObj.key)) {
                totalRow[colObj.key] += rowObj[colObj.key]
            }
        }
        
        txnRows.push({ ...pushObj })
    }

    totalRow["female_percent"] = totalRow["total_headcount"] ? ((totalRow["female_count"]*100)/totalRow["total_headcount"]).toFixed(2) : "0.00"
    totalRow[dynamicColumns[0].key] = "Total"

    const sortBy = getSortBy({ allFilterData })
    txnRows = listingSort(txnRows, getComparator("asc", sortBy))

    return { txnRows, totalRow, allRowCount }
}

const DiversityData = ({
    detailedData,
    handleDetailedData
}) => {
    const classes = useStyles()

    let {  
        allFilterData = [], 
        finalProcessedData = {},
        totalRowRecord = {},
        totalColName = "",
        isCompare = false,
        isCompareTotal = false
    } = detailedData
    const dynamicColumns = getColumns({ allFilterData })
    let { txnRows, totalRow } = getObjectToArrayList({ data: finalProcessedData, dynamicColumns, allFilterData })
    
    if (isCompare && isCompareTotal && totalRowRecord && Object.keys(totalRowRecord).length) {
        let compareTotal = getObjectToArrayList({ data: totalRowRecord, dynamicColumns, allFilterData })
        totalRow = compareTotal.totalRow
        isCompareTotal = true
        totalRow[dynamicColumns[0].key] = totalColName
    } else if (isCompare && !isCompareTotal) {
        totalRow = {}
    }  

    const txnRowsLen = txnRows.length

    const goBack = (pageName) => {
        handleDetailedData(pageName, true)
    }

    return (<React.Fragment>
        <Grid item xs={12} sm={12} md={12} className={classes.youAreAwesomeTableGridBody}>
        <Card>
            <Paper>
                {
                    txnRowsLen ? (<ReportTable 
                        rows={txnRows}
                        totalRow={totalRow}
                        columns={dynamicColumns}
                        reportName="Diversity %"
                        goBack={goBack}
                        pages={[ 50, 100, 500, 1000 ]}
                        isCompareTotal={isCompareTotal}
                    />) : <NoRecordToDisplay goBack={goBack} />
                }
                
            </Paper>
        </Card>
        </Grid>
    </React.Fragment>)
}

export default DiversityData