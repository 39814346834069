import React from 'react'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@material-ui/core'

const DialogComponent = ({
    openDialog=false,
    dialogTitle='',
    dialogContentText='',
    agree=true,
    disAgree=false,
    agreeButtonText="Agree",
    disagreeButtonText="Disagree",
    handleDialogClose=()=>{},
    showAgreeButtonAtFirst=false,
}) => {
    return (
        <Dialog
            open={openDialog}
            onClose={handleDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {dialogContentText}
            </DialogContentText>
            </DialogContent>
            {showAgreeButtonAtFirst ? 
                <DialogActions>
                    <Button onClick={e => handleDialogClose(agree)} color="primary" autoFocus>
                        {agreeButtonText}
                    </Button>
                    <Button onClick={e => handleDialogClose(disAgree)} color="primary">
                        {disagreeButtonText}
                    </Button>
                </DialogActions> :
                <DialogActions>
                    <Button onClick={e => handleDialogClose(disAgree)} color="primary">
                        {disagreeButtonText}
                    </Button>
                    <Button onClick={e => handleDialogClose(agree)} color="primary" autoFocus>
                        {agreeButtonText}
                    </Button>
                </DialogActions>
            }
            
        </Dialog>
    )
}

export default DialogComponent