import React, { Fragment, useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { useStyles } from './style';
import {
  Paper,
  FormControl,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  InputLabel,
  Select,
  Input,
  MenuItem,
  FormHelperText,
  Fab,
  Divider,
  RadioGroup,
  Radio,
  FormLabel,
  FormControlLabel,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import UpdateIcon from '@material-ui/icons/Update';
import ReplayIcon from '@material-ui/icons/Replay';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import moment from 'moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import SimpleAlerts from '../Alert';
import { deleteJoinerRecord, postChildCareData } from '../fetchData';

import { onlyAlphabets, checkNameRegEx } from '../../../utils/Validation';
import { joinerConst } from '../../../constants/JoinerConstant';
import { getUploadedFiles, postUploadedFiles } from '../fetchData';
import DialogComponent from '../../../components/Shared/Elements/DialogComponent';

import DelayingAppearance from '../DelayingAppearance'

const genderList = ['Male', 'Female'];
const relationList = ['Child', 'Spouse'];

const tableColumns = [
  { id: 'Relationship', label: 'Relationship', minWidth: 100 },
  { id: 'Name', label: 'Name', minWidth: 100 },
  { id: 'Gender', label: 'Gender', minWidth: 150 },
  { id: 'Date Of Birth', label: 'Date Of Birth', minWidth: 50 },
  { id: 'Is Single Parent', label: 'Is Single Parent', minWidth: 10 },
  { id: 'Birth Certificate', label: 'Birth Certificate', minWidth: 20 },
  {
    id: 'Edit',
    label: '',
    minWidth: 10,
    align: 'left',
  },
  {
    id: 'Delete',
    label: '',
    minWidth: 10,
    align: 'left',
  },
];

const ChildCare = ({
  errorObj,
  offerData,
  joinerChildCareData,
  handleChildData = () => {},
  handleChildDataValidation = () => {},
  handleSkippableComponent = () => {},
  handleNotification = () => {},
}) => {
  const [loading, setLoading] = useState(false)
  const [isApiCall, setIsApiCall] = useState(false)
  const classes = useStyles();
  const { offerId } = offerData;
  const spouseRelationship = relationList[1];
  const childRelationship = relationList[0];
  const [joinerChildCareDataList, setJoinerChildCareData] = useState(
    joinerChildCareData
  );
  const [fileList, setFileList] = useState([]);
  const [notification, setNotification] = useState({ type: '', message: '' });
  const [isUpdate, setIsUpdate] = useState(0);
  const [hasSpouse, setHasSpouse] = useState('');

  const checkParent = (joinerChildCareDataList) => {
    let isSingleParent = joinerChildCareDataList.filter(
      (rec) => rec.is_single_parent === 1
    )

    return isSingleParent.length ? 1 : (joinerChildCareDataList.length ? 0 : null)
  }

  const initObj = {
    id: null,
    spouseName: '',
    spouseGender: '',
    spouseDateOfBirth: null,
    fileData: [],
    isSingleParent: checkParent(joinerChildCareDataList),
    childName: '',
    childGender: '',
    childDateOfBirth: null,
  };

  let initErrObj = {};
  for (let k in initObj) {
    initErrObj[k] = { status: false, text: '' };
  }

  const [childCareObj, setChildCareObj] = useState(initObj);
  const [localErrObj, setLocalErrObj] = useState(initErrObj);
  const [spouseDataObj, setSpouseDataObj] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteRecordId, setDeleteRecordId] = useState({});
  const dialogTitle = `Confirm`;

  useEffect(() => {
    const isCount = joinerChildCareDataList.length ? true : false;
    const spouseData = joinerChildCareDataList.length
      ? joinerChildCareDataList.filter(
          (rec) => rec.relation === spouseRelationship
        )
      : null;
  
    if (spouseData && spouseData.length) {
      const [spouseObj] = spouseData;
      setHasSpouse(true);
      setChildCareObj({
        ...childCareObj,
        isSingleParent: 0,
        spouseName: spouseObj.name,
        spouseDateOfBirth: spouseObj.dateOfBirth,
        spouseGender: spouseObj.gender,
      });
      setSpouseDataObj(spouseObj);
    }
    handleChildData('joinerChildCareData', joinerChildCareDataList);
    handleChildDataValidation('joinerChildCareData', {
      status: !isCount,
      text: '',
    });
    getUploadedDocuments(offerId);
  }, [joinerChildCareDataList]);

  const {
    spouseName,
    spouseGender,
    spouseDateOfBirth,
    childName,
    childDateOfBirth,
    childGender,
    isSingleParent,
  } = childCareObj;

  const handleDialogOpen = (id, relation) => {
    setOpenDialog(true);
    if (id) {
      setDeleteRecordId({ id, relation });
    }
  };
  const handleDialogClose = (val) => {
    if (val === true) {
      if (Object.keys(deleteRecordId).length) {
        handleRecordDelete(deleteRecordId);
      } else {
        handleSaveData();
      }
    }
    setOpenDialog(false);
  };

  const handleChange = (e) => {
    let key = e.target.name;
    let value = e.target.value;
    setChildCareObj({ ...childCareObj, [key]: value });
    let { text, status } = checkChildCareDataError(key, value);
    setLocalErrObj({ ...localErrObj, [key]: { text, status } });
  };

  const onChangeRadio = (e) => {
    let key = e.target.name;
    let value = parseInt(e.target.value, 10);
    if (value === 1) {
      setChildCareObj({
        ...childCareObj,
        spouseDateOfBirth: null,
        spouseGender: '',
        spouseName: '',
        [key]: value,
      });
    } else {
      setChildCareObj({
        ...childCareObj,
        childDateOfBirth: null,
        childGender: '',
        childName: '',
        fileData: [],
        [key]: value,
      });
    }
    setLocalErrObj(initErrObj);
  };

  const handleDateChange = (val) => {
    if (isSingleParent === 0 && Object.keys(spouseDataObj).length === 0) {
      setChildCareObj({ ...childCareObj, spouseDateOfBirth: val });
      let { text, status } = checkChildCareDataError('spouseDateOfBirth', val);
      setLocalErrObj({ ...localErrObj, spouseDateOfBirth: { text, status } });
    } else {
      setChildCareObj({ ...childCareObj, childDateOfBirth: val });
      let { text, status } = checkChildCareDataError('childDateOfBirth', val);
      setLocalErrObj({ ...localErrObj, childDateOfBirth: { text, status } });
    }
  };

  const handleInput = (e) => {
    switch (e.target.name) {
      case 'spouseName':
        if (!onlyAlphabets(e.which)) {
          e.preventDefault();
        }
        break;
      case 'childName':
        if (!onlyAlphabets(e.which)) {
          e.preventDefault();
        }
        break;
      default:
        break;
    }
  };

  /*
  const checkSpouseAge = (key, value) => {
    let dob = value;
    let status = false;
    if (dob && moment().diff(dob, 'year') < 18) {
      status = true;
    }

    return status;
  };
  */

  const checkChildAge = (key, value) => {
    let dob = value;
    let status = false;
    if (dob && moment().diff(dob, 'year') >= 3) {
      status = true;
    }

    return status;
  };

  const isSpouseAgeValid = (key, value) => {
    let dob = value;
    let status = false;
    if (dob && moment().diff(dob, 'year') >= 18 ) {
      status = true;
    }

    return status;
  };

  const checkChildCareDataError = (key, value) => {
    let text = '';
    let status = false;
    switch (key) {
      case 'spouseName':
        if (!checkNameRegEx(value)) {
          status = true;
          text = `Invalid name`;
        }
        break;
      case 'childName':
        if (!checkNameRegEx(value)) {
          status = true;
          text = `Invalid name`;
        }
        break;
      case 'spouseGender':
        if (!genderList.includes(value)) {
          status = true;
          text = `Invalid gender`;
        }
        break;
      case 'childGender':
        if (!genderList.includes(value)) {
          status = true;
          text = `Invalid gender`;
        }
        break;
      case 'spouseDateOfBirth':
        if (!moment(value).isValid()) {
          status = true;
          text = `Invalid date of birth`;
        }
        else if (!isSpouseAgeValid(key, value)) {
            status = true
            text = `Spouse must be 18 years old`
        }
        break;
      case 'childDateOfBirth':
        if (!moment(value).isValid()) {
          status = true;
          text = `Invalid date of birth`;
        } else if (checkChildAge(key, value)) {
          status = true;
          text = 'Child must be younger than 3 years of age';
        }
        break;
      case 'fileData':
        if (!isUpdate && fileData.length === 0) {
          status = true;
          text = 'Birth Certificate is mandatory';
        }
        break;
      default:
        break;
    }

    return { text, status };
  };

  const handleSaveData = () => {
    let text = ``;
    if (joinerChildCareDataList.length >= 6) {
      text = `You can add upto 6 records!`;
      setNotification({ message: text, type: 'error' });
    } else if (isSingleParent === 0  && checkParent(joinerChildCareDataList)) {
      text = `There is some record exist with single parent. Kindly remove and then proceed!`;
      setNotification({ message: text, type: 'error' });
    } else {
      let errObj = {};
      let finalStatus;
      let ccObj = {};
      if (isSingleParent === 1 || (isSingleParent === 0 && hasSpouse)) {
        ccObj = {
          childName: childCareObj.childName,
          childGender: childCareObj.childGender,
          childDateOfBirth: childCareObj.childDateOfBirth,
          fileData: fileData,
        };
      } else {
        ccObj = {
          spouseName: childCareObj.spouseName,
          spouseGender: childCareObj.spouseGender,
          spouseDateOfBirth: childCareObj.spouseDateOfBirth,
        };
      }
      for (let key in ccObj) {
        let { text, status } = checkChildCareDataError(key, ccObj[key]);
        errObj[key] = { text, status };
        if (status) {
          finalStatus = status;
        }
      }
      setLocalErrObj({ ...localErrObj, ...errObj });
      if (finalStatus) {
        text = `There is some error in the form!`;
        setNotification({ message: text, type: 'error' });
      } else {
        let finalObj = {};
        if (isSingleParent === 1 || (isSingleParent === 0 && hasSpouse)) {
          finalObj = {
            id: childCareObj.id ? childCareObj.id : null,
            name: childCareObj.childName,
            gender: childCareObj.childGender,
            dateOfBirth: childCareObj.childDateOfBirth,
            relation: childRelationship,
            isSingleParent
          };
        } else {
          finalObj = {
            id: spouseDataObj.id ? spouseDataObj.id : null,
            name: childCareObj.spouseName,
            gender: childCareObj.spouseGender,
            dateOfBirth: childCareObj.spouseDateOfBirth,
            relation: spouseRelationship,
            isSingleParent
          };
        }
        handleApiCall({
          ...finalObj,
          dateOfBirth: moment(finalObj.dateOfBirth).format('YYYY-MM-DD'),
        });
      }
    }
  };

  const handleApiCall = async (dataObj) => {
    setLoading(true)
    setIsApiCall(true)
    let response = {};
    response = await postChildCareData({ childCareList: [dataObj] });
    const {
      success = false,
      recId,
      joinerChildCareData = joinerChildCareDataList,
    } = response || {};
    let text = ``;
    let type = ``;
    let fileErrorText = ``;
    let singleParent = false;
    if (success) {
      if (recId && fileData.length) {
        let fileUploadStatus = await handleFileUpload({
          fileData,
          objectId: offerId,
          tag: recId,
        });

        if (fileUploadStatus !== 200) {
          fileErrorText = "File couldn't get uploaded! Please try again.";
        }
        getUploadedDocuments(offerId);
      }
      const [spouseData = {}] = joinerChildCareData.filter(
        (record) => record.relation === spouseRelationship
      );
      if (Object.keys(spouseData).length) {
        singleParent = 0;
        setChildCareObj({
          ...initObj,
          spouseName: spouseData.name,
          spouseGender: spouseData.gender,
          spouseDateOfBirth: spouseData.dateOfBirth,
          isSingleParent: singleParent,
        });
      } else {
        singleParent = 1;
        setChildCareObj({
          ...childCareObj,
          childGender: '',
          childDateOfBirth: null,
          childName: '',
          fileData: [],
        });
      }
      setFileData([]);
      text = dataObj.id
        ? 'Record updated successfully!'
        : 'Record added successfully!';
      type = `success`;
    } else {
      text = `Something went wrong!`;
      type = `error`;
    }
    setFileUploadError(fileErrorText);
    setNotification({ message: text, type });
    setJoinerChildCareData(joinerChildCareData);
    handleSkippableComponent('Child Care Details', joinerChildCareData.length);
    setIsUpdate(false);
    setLocalErrObj(initErrObj);
    setIsApiCall(false)
    setLoading(false)
  };

  const handleReset = (type) => {
    if (type === spouseRelationship) {
      setChildCareObj(initObj);
    } else {
      setChildCareObj({
        ...childCareObj,
        childName: '',
        childDateOfBirth: null,
        childGender: '',
        fileData: '',
      });
    }
    setIsUpdate(false);
    setFileData([]);
    setNotification({ message: '', type: '' });
    setFileUploadError(``);
    handleNotification({ status: false, text: '' });
    setLocalErrObj(initErrObj);
  };

  const handleRecordEdit = (e, recId) => {
    const updateRec = getChildCareRecordById(recId);
    if (updateRec) {
      const {
        id = null,
        name = '',
        gender = '',
        date_of_birth = null,
        is_single_parent = null
      } = updateRec;
      const updateObj = {
        id,
        childName: name,
        childGender: gender,
        childDateOfBirth: date_of_birth,
        isSingleParent: is_single_parent
      };
      setChildCareObj({ ...childCareObj, ...updateObj });
      setIsUpdate(recId);
      setLocalErrObj(initErrObj);
    }
  };

  const getChildCareRecordById = (id) => {
    return joinerChildCareDataList.find((obj) => obj.id === id);
  };

  const handleRecordDelete = async (deleteRecord) => {
    let text = ``;
    let type = ``;
    setLocalErrObj(initErrObj);
    setChildCareObj(initObj);
    setIsUpdate(false);
    const { relation, id } = deleteRecord;
    if (id) {
      let response = {};
      response = await deleteJoinerRecord({ id, modelName: 'childcare' });
      const { success = false, joinerChildCareData = [] } = response || {};
      if (success) {
        setChildCareObj(initObj);
        setJoinerChildCareData(joinerChildCareData);
        if (relation === 'Spouse') {
          setSpouseDataObj({});
          setHasSpouse('');
        }
        handleSkippableComponent(
          'Child Care Details',
          joinerChildCareData.length
        );
        text = `Record deleted successfully!`;
        type = `success`;
      } else {
        text = `Something went wrong!`;
        type = `error`;
      }
    }
    setFileUploadError(``);
    handleNotification({ status: false, text: '' });
    setNotification({ message: text, type });
    setDeleteRecordId({});
  };

  const findUrlData = (id) => {
    const imageArr = fileList.filter((rec) => String(rec.id) === String(id));
    if (imageArr.length) {
      const imageObj = imageArr[0];
      return imageObj.url;
    } else {
      return null;
    }
  };

  const closeAlert = () => {
    setNotification({ type: '', message: '' });
  };

  const getUploadedDocuments = async (offerId) => {
    const query = {
      objectId: offerId,
      objectType: 'ChildCareDocument',
      domainId: 2,
    };
    let data = [];
    try {
      data = await getUploadedFiles(query);
      data = data.data;
    } catch (err) {
      console.log(err);
    }
    setFileList(sortFileList(data));
  };

  const sortFileList = (fileList = []) => {
    const mappedList = [];
    if (fileList.length) {
      const filteredList = fileList.sort((a, b) => b.updatedAt - a.updatedAt);
      filteredList.forEach((record) => {
        const existingFile = mappedList.filter(
          (file) => file.id === record.description
        );
        if (existingFile.length === 0) {
          mappedList.push({ id: record.description, url: record.absoluteUrl });
        }
      });
    }
    return mappedList;
  };

  const { fileExtenstions, maxUploadFileSize, oneMbInBytes } = joinerConst;
  const [fileData, setFileData] = useState([]);
  const [fileUploadError, setFileUploadError] = useState('');
  const onFileChange = async (e) => {
    if (fileData.length >= 1) {
      alert('You can select maximum one file to upload!');
      return false;
    }

    if (e.target.files.length) {
      let files = e.target.files[0];
      if (!fileExtenstions.includes(files.name.split('.').pop())) {
        alert(
          `Selected file type is invalid! Allowed file types are ${fileExtenstions.join(
            ','
          )}`
        );
        return false;
      }
      if (files.size > maxUploadFileSize) {
        alert(
          `Exceeding file size limit! Upload file size upto ${maxUploadFileSize/oneMbInBytes} kb!`
        );
        return false;
      }
      setFileData([{ inputFile: files }]);
    } else {
      setFileData([]);
    }
  };

  const handleFileUpload = async ({ fileData, objectId, tag }) => {
    const [{ status } = {}] = await Promise.all(
      fileData
        .filter(
          ({ inputFile }) => inputFile !== null && inputFile !== undefined
        )
        .map(({ inputFile }) => {
          const res = imageUpload({
            inputFile,
            objectId,
            objectType: 'ChildCareDocument',
            documentType: 'ChildCareDocument',
            tag,
          });
          return postUploadedFiles(res);
        })
    ).catch((err) => console.log(err));
    return status;
  };

  const imageUpload = ({
    inputFile,
    objectId,
    objectType,
    documentType,
    tag,
  }) => {
    const formData = new FormData();
    formData.append('file', inputFile);
    formData.append('objectId', objectId);
    formData.append('objectType', objectType);
    formData.append('documentType', documentType);
    formData.append('domainId', 2);
    formData.append('description', tag);
    return formData;
  };
  
  return (
    <Fragment>
      <Grid container spacing={2} className={classes.root}>
        <Grid item sm={10}>
          <Paper className={classes.paperFirst}>
            {notification.message ? (
              <SimpleAlerts
                type={notification.type}
                message={notification.message}
                handleAlert={closeAlert}
              />
            ) : null}
            <div style={{color: "red", float: "left", fontSize: "11px", paddingTop: "10px"}}>
              <sup>*</sup> Kindly read general instructions before filling in the information on this page
            </div>
            <Grid container spacing={2}>
              <FormControl className={classes.formControlLabel}>
                <FormLabel>Are you a single parent?</FormLabel>
                <RadioGroup
                  row
                  aria-label='isSingleParent'
                  name='isSingleParent'
                  defaultValue='top'
                >
                  <FormControlLabel
                    value='1'
                    control={<Radio color='primary' required={true} />}
                    label='Yes'
                    labelPlacement='start'
                    checked={isSingleParent === 1}
                    onChange={onChangeRadio}
                    disabled={
                      spouseDataObj && Object.keys(spouseDataObj).length
                        ? true
                        : false
                    }
                  />
                  <FormControlLabel
                    value='0'
                    control={<Radio color='primary' />}
                    label='No'
                    labelPlacement='start'
                    checked={isSingleParent === 0}
                    onChange={onChangeRadio}
                    disabled={
                      spouseDataObj && Object.keys(spouseDataObj).length
                        ? true
                        : false
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            {isSingleParent === 0 ? (
              <div>
                <Grid container>
                  <FormControl className={classes.formControl}>
                    <TextField
                      autoComplete='off'
                      required={true}
                      label='Relationship'
                      placeholder='Spouse'
                      value={spouseDataObj.relation || 'Spouse'}
                      name='Spouse'
                      disabled={
                        spouseDataObj && Object.keys(spouseDataObj).length
                          ? true
                          : false
                      }
                    />
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <TextField
                      autoComplete='off'
                      required={true}
                      label='Spouse Name'
                      placeholder='Spouse Name'
                      value={childCareObj.spouseName}
                      name='spouseName'
                      onChange={(e) => handleChange(e)}
                      onKeyPress={(e) => handleInput(e)}
                      inputProps={{ minLength: 2, maxLength: 100 }}
                      error={localErrObj.spouseName.status}
                      helperText={localErrObj.spouseName.text}
                      disabled={
                        spouseDataObj && Object.keys(spouseDataObj).length
                          ? true
                          : false
                      }
                    />
                  </FormControl>
                  <FormControl
                    className={classes.formControl}
                    disabled={
                      spouseDataObj && Object.keys(spouseDataObj).length
                        ? true
                        : false
                    }
                  >
                    <InputLabel
                      required={true}
                      error={localErrObj.spouseGender.status}
                    >
                      Gender
                    </InputLabel>
                    <Select
                      value={childCareObj.spouseGender}
                      name='spouseGender'
                      input={<Input id='gender' />}
                      onChange={(e) => handleChange(e)}
                      error={localErrObj.spouseGender.status}
                      disabled={
                        spouseDataObj && Object.keys(spouseDataObj).length
                          ? true
                          : false
                      }
                    >
                      <MenuItem disabled>
                        <em>Select the value</em>
                      </MenuItem>
                      {genderList.map((element, index) => {
                        return (
                          <MenuItem key={index} value={element}>
                            {element}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText style={{ color: 'red' }}>
                      {localErrObj.spouseGender.text}
                    </FormHelperText>
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        label='Date of Birth'
                        format='dd-MM-yyyy'
                        clearable={false}
                        name='spouseDateOfBirth'
                        value={childCareObj.spouseDateOfBirth || spouseDataObj.date_of_birth}
                        onChange={handleDateChange}
                        disableFuture={true}
                        inputProps={{ readOnly: true }}
                        required={true}
                        error={localErrObj.spouseDateOfBirth.status}
                        helperText={localErrObj.spouseDateOfBirth.text}
                        disabled={
                          spouseDataObj && Object.keys(spouseDataObj).length
                            ? true
                            : false
                        }
                      />
                    </MuiPickersUtilsProvider>
                  </FormControl>
                  {spouseDataObj && Object.keys(spouseDataObj).length ? (
                    <div></div>
                  ) : (
                    <Grid
                      container
                      spacing={2}
                      className={classes.formControlAddBtn}
                    >
                      <FormControl className={classes.formControlAddBtn}>
                        <Button
                          variant='contained'
                          color='secondary'
                          size='small'
                          className={classes.button}
                          startIcon={<SaveIcon />}
                          onClick={(e) => handleDialogOpen('')}
                          disabled={
                            localErrObj.spouseDateOfBirth.text ||
                            localErrObj.spouseName.text ||
                            localErrObj.spouseGender.text ||
                            !spouseName ||
                            !spouseGender ||
                            !spouseDateOfBirth
                              ? true
                              : false
                          }
                        >
                          Add
                        </Button>
                      </FormControl>
                      <FormControl className={classes.formControlAddBtn}>
                        <Button
                          variant='contained'
                          size='small'
                          className={classes.button}
                          startIcon={<ReplayIcon />}
                          onClick={(e) => handleReset(spouseRelationship)}
                        >
                          Reset
                        </Button>
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
              </div>
            ) : (
              <div></div>
            )}

            {isSingleParent === 1 || (isSingleParent === 0 && hasSpouse) ? (
              <div>
                <Grid container>
                  <FormControl className={classes.formControl}>
                    <TextField
                      autoComplete='off'
                      required={true}
                      label='Relationship'
                      placeholder='Child'
                      value={childRelationship}
                      name='child'
                    />
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <TextField
                      autoComplete='off'
                      required={true}
                      label='Name'
                      placeholder='Child'
                      value={childName}
                      name='childName'
                      onChange={(e) => handleChange(e)}
                      onKeyPress={(e) => handleInput(e)}
                      inputProps={{ minLength: 2, maxLength: 100 }}
                      error={localErrObj.childName.status}
                      helperText={localErrObj.childName.text}
                    />
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <InputLabel
                      required={true}
                      error={localErrObj.childGender.status}
                    >
                      Gender
                    </InputLabel>
                    <Select
                      value={childGender}
                      name='childGender'
                      input={<Input id='gender' />}
                      onChange={(e) => handleChange(e)}
                      error={localErrObj.childGender.status}
                    >
                      <MenuItem value='' disabled>
                        <em>Select the value</em>
                      </MenuItem>
                      {genderList.map((element, index) => {
                        return (
                          <MenuItem key={index} value={element}>
                            {element}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText style={{ color: 'red' }}>
                      {localErrObj.childGender.text}
                    </FormHelperText>
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        label='Date of Birth'
                        format='dd-MM-yyyy'
                        clearable={false}
                        name='childDateOfBirth'
                        value={childDateOfBirth}
                        onChange={handleDateChange}
                        disableFuture={true}
                        inputProps={{ readOnly: true }}
                        required={true}
                        error={localErrObj.childDateOfBirth.status}
                        helperText={localErrObj.childDateOfBirth.text}
                      />
                    </MuiPickersUtilsProvider>
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <InputLabel>Birth Certificate</InputLabel>
                    <label htmlFor='contained-button-file'>
                      <Fab component='span' className={classes.buttonFile}>
                        <AddPhotoAlternateIcon />
                        <input
                          accept='application/pdf, image/*'
                          className={classes.input}
                          id='contained-button-file'
                          type='file'
                          onChange={onFileChange}
                          title='Select a file'
                        />
                      </Fab>
                    </label>
                    <FormHelperText
                      style={
                        localErrObj.fileData.text.length &&
                        fileData.length === 0
                          ? { color: 'red' }
                          : null
                      }
                    >
                      {fileData.length ? (
                        fileData[0].inputFile.name
                      ) : isUpdate ? (
                        <a target='_blank' href={findUrlData(childCareObj.id)}>
                          View
                        </a>
                      ) : (
                        localErrObj.fileData.text
                      )}
                    </FormHelperText>
                    <FormHelperText style={{ color: 'red' }}>
                      {fileUploadError}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  className={classes.formControlAddBtn}
                >
                  <FormControl className={classes.formControlAddBtn}>
                    {isUpdate ? (
                      <Button
                        variant='contained'
                        color='secondary'
                        size='small'
                        className={classes.button}
                        startIcon={<UpdateIcon />}
                        onClick={handleSaveData}
                        disabled={
                          localErrObj.childName.text ||
                          localErrObj.childDateOfBirth.text ||
                          localErrObj.childGender.text ||
                          localErrObj.fileData.dialogContentText ||
                          !childName ||
                          !childDateOfBirth ||
                          !childGender
                            ? true
                            : false || isApiCall
                        }
                      >
                        Update
                      </Button>
                    ) : (
                      <Button
                        variant='contained'
                        color='secondary'
                        size='small'
                        className={classes.button}
                        startIcon={<SaveIcon />}
                        onClick={handleSaveData}
                        disabled={
                          localErrObj.childName.text ||
                          localErrObj.childDateOfBirth.text ||
                          localErrObj.childGender.text ||
                          localErrObj.fileData.dialogContentText ||
                          !childName ||
                          !childDateOfBirth ||
                          !childGender ||
                          fileData.length === 0
                            ? true
                            : false || isApiCall
                        }
                      >
                        Add
                      </Button>
                    )}
                  </FormControl>
                  <FormControl className={classes.formControlAddBtn}>
                    <Button
                      variant='contained'
                      size='small'
                      className={classes.button}
                      startIcon={<ReplayIcon />}
                      onClick={(e) => handleReset(childRelationship)}
                    >
                      Reset
                    </Button>
                  </FormControl>
                  <FormControl className={classes.formControlAddBtn}>
                    <DelayingAppearance loading={loading}/>
                  </FormControl>
                </Grid>
              </div>
            ) : (
              <div></div>
            )}
          </Paper>
          <Divider light />
          <Paper className={classes.root}>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label='sticky table'>
                <TableHead>
                  <TableRow>
                    {tableColumns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {joinerChildCareDataList.map(
                    ({ id, name, relation, gender, date_of_birth, is_single_parent }, index) => {
                      return (
                        <TableRow hover tabIndex={-1} key={index}>
                          <TableCell align={'left'} size={'small'}>
                            {relation}
                          </TableCell>
                          <TableCell align={'left'} size={'small'}>
                            {name}
                          </TableCell>
                          <TableCell align={'left'} size={'small'}>
                            {gender}
                          </TableCell>
                          <TableCell align={'left'} size={'small'}>
                            {moment(date_of_birth).format('DD-MM-YYYY')}
                          </TableCell>
                          <TableCell align={'left'} size={'small'}>
                            {is_single_parent ? 'Yes' : 'No'}
                          </TableCell>
                          <TableCell>
                            {fileList.length &&
                            findUrlData(id) &&
                            findUrlData(id).length ? (
                              <a target='_blank' href={findUrlData(id)}>
                                View
                              </a>
                            ) : (
                              '-'
                            )}
                          </TableCell>
                          <TableCell align={'left'} size={'small'}>
                            {relation === 'Spouse' ? (
                              <div></div>
                            ) : (
                              <div onClick={(e) => handleRecordEdit(e, id)}>
                                <EditIcon />
                              </div>
                            )}
                          </TableCell>
                          <TableCell align={'left'} size={'small'}>
                            <div
                              onClick={(e) => handleDialogOpen(id, relation)}
                            >
                              <DeleteForeverIcon />
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <DialogComponent
            openDialog={openDialog}
            dialogTitle={dialogTitle}
            dialogContentText={
              Object.keys(spouseDataObj).length === 0 && isSingleParent === 0
                ? `Are you sure you want to save this spouse data?`
                : 'Are you sure you want to delete this child care record?'
            }
            handleDialogClose={handleDialogClose}
          />
        </Grid>
        <Grid item sm={2}>
          <Paper className={classes.paperGeneral}>
            <div>
              <ul>
                <li className='unorder-list'>&nbsp;</li>
                <li className='unorder-list'>General Instructions:</li>
                <li className='unorder-list'>&nbsp;</li>
                <li>This section allows you to apply for Monthly Child Care Allowance that is applicable only for all our female colleagues with children less than 3 years of age. Kindly provide details of your spouse (no details required if you are a single parent), followed by details of your children (below the age of 3 years only).</li>
                <li>Click on ADD to enter details of more than 1 child.</li>
                <li>You may click on SKIP option in case this section is not applicable to you.</li>
                <li>To move ahead, click SAVE & NEXT.
                </li>
              </ul>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default ChildCare;
