import React, { useState,useEffect } from 'react';
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CardHeader from '@material-ui/core/CardHeader';
import Card from '@material-ui/core/Card';
import HeaderLayout from '../HeaderLayout'
import PeopleOutlineTwoTone from '@material-ui/icons/PeopleOutlineTwoTone'
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined'
import { validateCsvFile } from '../surveyCommonFunction'
import Publish from '@material-ui/icons/Publish'
import SimpleAlerts from '../../Joiner/Alert'

import { useStyles } from './style'
import { Button, FormControl, Grid,Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Fab } from '@material-ui/core'
import { postSurveyCritertiaData, getCriteriaListFetch } from '../fetchData';

import { orange } from '@material-ui/core/colors'
import { withStyles } from '@material-ui/core/styles'
import logo from '../../../utils/load.gif'
import { surveyApiUrl } from "../../../utils/CommonUrl"
import { pageURLConfig, scrollToViewHandler } from '../../../constants/CommonConstant';
import { getPageRightsDetails } from '../../../utils/authFunction';
const { moduleName, controllerName, actionName } = pageURLConfig.SURVEY_INDEX_MANAGE_CRITERIA

const PreviousButton = withStyles(theme => ({
    root: {
      color: theme.palette.getContrastText(orange[500]),
      backgroundColor: orange[500],
      '&:hover': {
        backgroundColor: orange[700]
      }
    }
  }))(Button)

const INITIAL_FORM_OBJECT = {
    criteria_name: '',
    buckets: ['','']
}

const CriteriaForm = ({handleSave,setNotification, isVisible}) => {
    const classes = useStyles()
    const [expandDefault, setExpandDefault] = useState(false)
    const [formObj, setFormObj] = useState(INITIAL_FORM_OBJECT)
    const [bucketCsvData, setBucketCsvData] = useState([])
    const [fileData, setFileData] = useState([])

    const handleChange = event => {
        setFormObj({ ...formObj, [event.target.name]: event.target.value })
    }

    const handleBucketChange = (event,bucketIndex) => {
        let tempFormObj = JSON.parse(JSON.stringify(formObj))
        tempFormObj['buckets'][bucketIndex] = event.target.value
        setFormObj(tempFormObj)
    }

    const handleAddBucket = () => {
        let tempFormObj = JSON.parse(JSON.stringify(formObj))
        tempFormObj['buckets'].push('')
        setFormObj(tempFormObj)
    }

    const handleDeleteBucket = (e,bucketIndex) => {
        if( (bucketIndex == 0  || bucketIndex == 1 ) && (formObj['buckets'].length <= 2)){
            return
        }
        let tempFormObj = JSON.parse(JSON.stringify(formObj))
        tempFormObj['buckets'].splice(bucketIndex,1)
        setFormObj(tempFormObj)
    }


    const handleReset = () => {
        setFormObj(INITIAL_FORM_OBJECT)
    }

    const onFileChange = async (e) => {
        let files = e.target.files[0]
        let name =  e.target.name
        e.target.value = ''
       
        if(files){
            let fileExtenstions = ['csv']
            if (!fileExtenstions.includes(files.name.split('.').pop())) {
                alert(`Selected file type is invalid! Allowed file types are ${fileExtenstions.join(',')}`)
                return false
            }
            let error = []
            const response = await validateCsvFile(files)
            error = response.error
            if (!error.length) {
                setFileData([{ inputFile:files }])
                setBucketCsvData(response.data)
            }else{
                setNotification({type: 'error',  message: `${error.join(',')}`})
                scrollToViewHandler('alerts')
            }
        }
    }

    const handleSelectedFile = (e, index) => {
        const newFileData = fileData.filter((obj, i) => {
            return i!==index
        })
        setFileData(newFileData)
        setBucketCsvData([])
    }




    return (isVisible &&
            <Accordion
                expanded={expandDefault}
                onChange={() => setExpandDefault(!expandDefault)}
                className={classes.pageContent}
                style={{margin:'9px'}}
                
            >
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1a-content'
                id='panel1a-header'
                className={classes.pageContent}
                >                   
                    <Grid>
                        <Card>
                            <CardHeader
                                title="Add Criteria"
                                style={{ color: '#3f51b5'}}
                                titleTypographyProps={{variant:'subtitle1' }}
                            />
                        </Card>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails className={classes.pageContent}>
                <Grid container>
                <Paper elevation={2} className={classes.root}>
                    <form className={classes.formRoot} noValidate autoComplete='off'>
                        <Grid container>
                            <Grid container spacing={3}>
                                <Grid item xs={2} className={classes.gridItem}> 
                                <h5>
                                    Criteria Name<span style={{color:'red'}}>*</span>:
                                </h5>
                                </Grid>
                                <Grid item xs={3} sm={12} md={3} style={{margin:'inherit'}}>
                                <TextField
                                    id='outlined-basic'
                                    label='Criteria Name'
                                    placeholder='Criteria Name'
                                    name='criteria_name'
                                    value={formObj.criteria_name || ''}
                                    onChange={e => handleChange(e)}
                                    className={classes.formControl}
                                />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container>
                            {formObj.buckets.map((ele,index) => {
                            return <Grid container spacing={3} style={(index != 1) ? {marginTop:'auto'}: {}}>
                                        <Grid item xs={2}  className={classes.gridItem}> 
                                            {index === 0 && <h5>
                                                Buckets<span style={{color:'red'}}>*</span>:
                                            </h5> }
                                        </Grid>
                                        <Grid item xs={3} sm={12} md={3} style={(index == 0) ? {marginLeft:'inherit'} : {margin:'inherit'}}>
                                        <TextField
                                            id='outlined-basic'
                                            placeholder='Buckets'
                                            name='buckets'
                                            value={formObj.buckets[index] || ''}
                                            onChange={e => handleBucketChange(e,index)}
                                            className={classes.formControl}
                                        />
                                        </Grid>
                                        <Grid item xs={0.1} className={classes.gridItem} style={ index == 0 ? {margin:'auto 10px auto 32px'} : {margin:'auto 10px auto 44px'}}> 
                                            <span onClick={e=>handleDeleteBucket(e,index)}>
                                                <DeleteForeverOutlinedIcon className={classes.icon} />
                                            </span>
                                        </Grid>
                                        {index == 0 && 
                                            <Grid item xs={2} className={classes.gridItem} style={{margin:'auto 10px auto 10px'}}> 
                                                <a className={classes.addButton} onClick={handleAddBucket}> Add Bucket </a>
                                            </Grid>
                                        }
                                    </Grid>
                            })}
                        </Grid>
                        <Grid container>
                            <Grid container spacing={3} style={{marginTop:'10px'}}>
                                <Grid item xs={2} className={classes.gridItem}> 
                                    <h5>
                                        Upload CSV <span style={{color:'red'}}>*</span>:
                                        <br></br>
                                        <a target='_blank' href={`${surveyApiUrl.DOWNLOAD_CSV_FOR_CRITERIA_BUCKET}/sample`}>Sample File</a>
                                    </h5>
                                </Grid>
                                <Grid item xs={1} className={classes.gridItem}> 
                                    <FormControl className={classes.formControl} style={{display:'contents'}}>
                                        <input
                                            accept=".csv"
                                            className={classes.input}
                                            id="contained-button-file"
                                            type="file"
                                            name="image"
                                            onChange={onFileChange}
                                            title="Select a file"
                                        />
                                        {/* <span>Select File (csv)</span> */}
                                        <label htmlFor="contained-button-file">
                                        <Fab component="span" className={classes.button}>
                                            <Publish />
                                        </Fab>
                                        </label>
                                    </FormControl>
                                    <Table className={classes.table} aria-label="simple table">
                                        <TableBody>
                                            {
                                                fileData.map(({inputFile}, index) => {
                                                return (
                                                    <TableRow key={index}>
                                                            <TableCell component="th" scope="row">
                                                                {inputFile && inputFile.name}
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <span onClick={e=>handleSelectedFile(e, index)}>
                                                                    <DeleteForeverOutlinedIcon className={classes.icon} />
                                                                </span>
                                                            </TableCell>
                                                    </TableRow>
                                                )
                                                })
                                            }
                                        </TableBody>
                                    </Table> 
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            spacing={3}
                            className={classes.marginTop}
                            direction='row'
                            alignItems='center'
                        >
                            <Grid item>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    size='small'
                                    style={{ marginLeft: 20 }}
                                    onClick={() => handleSave(formObj,bucketCsvData)}
                                    className={classes.button}
                                >
                                    Save
                                </Button>
                                <PreviousButton
                                    variant='outlined'
                                    color='primary'
                                    className={classes.resetButton}
                                    size='small'
                                    onClick={() => handleReset()}
                                >
                                    Reset
                                </PreviousButton>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
                </Grid>
                </AccordionDetails>
            </Accordion>
    )
}


export default function ManageCriteria() { 
    const classes = useStyles()
    const [notification, setNotification] = useState({type: '',  message: ''})
    const [criteriaList, setCriteriaList] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingTable, setIsLoadingTable] = useState(false)
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);
    const [isHavingPageViewAccess, setIsHavingPageViewAccess] = useState(null)
    const [isHavingPageAddAccess, setIsHavingPageAddAccess] = useState(true)

    useEffect(() => {
        getCriteriaList()
        setPageRights()
    },[])

    const getCriteriaList = async () => {
        try {
            setIsLoadingTable(true)
            let response = await getCriteriaListFetch()
            if(response.data){
                setCriteriaList(response.data)
            }
            setIsLoadingTable(false)
        } catch (error) {
            throw error
        }
    }

    const setPageRights = async () => {
        const { 
            isUserSuperAdmin,
            isHavingPageViewRights,
            isHavingPageAddRights, 
            isHavingPageEditRights 
        } = await getPageRightsDetails({moduleName, controllerName, actionName})
        setIsHavingPageViewAccess(isHavingPageViewRights)
        setIsHavingPageAddAccess(isHavingPageAddRights)
    }

    const handleSave = async ( formData, bucketCsvData ) => {
        const validateResponse = validateFormData(formData,bucketCsvData)
        if(validateResponse.error){
            setNotification({type:'error',message:validateResponse.message})
            scrollToViewHandler('alerts')
        } else {
            try {
                setIsLoading(true)
                let response = {}
                response = await postSurveyCritertiaData({formData,bucketCsvData})
                if(response.success){
                    setNotification({type:'success',message:response.message})
                    scrollToViewHandler('alerts')
                    getCriteriaList()
                }
                setIsLoading(false)
            } catch (error) {
                throw(error)
            }
        }
    }

    const validateFormData = (formData,bucketCsvData) => {
        let validateResponse = {error:false,message:''}
        let bucketCsvArray = bucketCsvData.map(ele => {return ele['bucket']})
        let csvEmployeenoArray = {}
        bucketCsvData.forEach(ele => {
            if(csvEmployeenoArray[ele['employeeno']] && csvEmployeenoArray[ele['employeeno']] != ele['bucket']){
                validateResponse.error = true
                validateResponse.message = "Duplicate entries for the employee code : " + ele['employeeno'] + "with different bucket"
            } else {
                csvEmployeenoArray[ele['employeeno']] = ele['bucket']
            }
        })

        if(validateResponse.error){
            return validateResponse
        }

        if(formData.criteria_name.trim() == ''){
            validateResponse.error = true
            validateResponse.message = 'Please mention Criteria Name'
            return validateResponse
        }

        if(formData.buckets && formData.buckets.length > 0 ){
            formData.buckets.map(ele => {
                    if(ele.trim() == '' ){
                        validateResponse.error = true
                        if(formData.buckets.length <= 2){
                            validateResponse.message = 'Please mention atleast 2 bucket'
                        } else {
                            validateResponse.message = 'Buckets cannot  be empty!'
                        }
                    }
            })
            if(validateResponse.error){
                return validateResponse
            }
        }

        if (bucketCsvData.length == 0 ){
            validateResponse.error = true
            validateResponse.message = 'Please add a CSV file'
            return validateResponse
        }

        if(formData.buckets && formData.buckets.length > 0 ){
            // formData.buckets.map(ele => {
            //         if(!bucketCsvArray.includes(ele) && !validateResponse.error){
            //             validateResponse.error = true
            //             validateResponse.message = 'Bucket in the CSV is Invalid'
            //         }
            // })

            bucketCsvArray.map(ele => {
                if(!formData.buckets.includes(ele) && !validateResponse.error){
                    validateResponse.error = true
                    validateResponse.message = 'Bucket in the CSV is Invalid'
                }
            })
            if(validateResponse.error){
                return validateResponse
            }
        }

        return validateResponse
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };
    const closeAlert = () => {
        setNotification({type:'', message: ''})
    }

    return (
        <React.Fragment>
            {(!isHavingPageViewAccess) 
            ? 
                (isHavingPageViewAccess == null) ? <div className={classes.loader}><img src={logo} alt="loading..." /></div> :
                <div> <h1 style={{margin:'0px'}}>Unauthorised </h1> <br></br> <span>You don't have access to view this page</span> </div> 
            :
            <div className={classes.root}>
                <Grid container>
                    <Grid> 
                    <HeaderLayout
                        title='Manage Criteria'
                        subTitle=''
                        icon={<PeopleOutlineTwoTone/>}
                    />
                    </Grid>
                </Grid>
                {
                    notification.message ? (
                        <div className={classes.notification} id={'alerts'}>
                            <SimpleAlerts 
                                type={notification.type}
                                message={notification.message}
                                handleAlert={closeAlert}
                            />
                        </div>
                    ) : (null)
                }
                { ( isLoading ) 
                ? 
                    <div className={classes.loader}><img src={logo} alt="loading..." /></div>
                : 
                    <CriteriaForm
                    handleSave={handleSave}
                    setNotification={setNotification}
                    isVisible={isHavingPageAddAccess}
                    />
                }
                { ( isLoadingTable ) 
                ? 
                <div className={classes.loader}><img src={logo} alt="loading..." /></div>
                : 
                <Paper className={classes.pageContent} elevation={5}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell><b>Criteria Name</b></TableCell>
                                    <TableCell><b>Criteria Added On</b></TableCell>
                                    <TableCell><b>Criteria Added By</b></TableCell>
                                    <TableCell><b>View CSV</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            { ( criteriaList && criteriaList.length > 0 ) ? criteriaList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((element,key)=>(
                                <TableRow key={key}>
                                    <TableCell> 
                                        {element.criteria_name}
                                    </TableCell>
                                    <TableCell> 
                                        {new Date(element.created_at)
                                        .toLocaleDateString('en-GB', {
                                            day: 'numeric',
                                            month: 'short',
                                            year: 'numeric'
                                        })
                                        .replace(/ /g, '-')
                                        .replace(/,/g, '')}
                                    </TableCell>
                                    <TableCell> {element.created_by}</TableCell>
                                    <TableCell> <a target='_blank' href={`${surveyApiUrl.DOWNLOAD_CSV_FOR_CRITERIA_BUCKET}/${element.id}`}>Download File</a></TableCell>
                                </TableRow>
                            )) : (
                                <TableRow>
                                <TableCell><b>No result found</b></TableCell>
                                </TableRow>
                            ) } 
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                    rowsPerPageOptions={[50 ,100,200, 250]}
                    component="div"
                    count={criteriaList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                </Paper>
                }
            </div>
            }
        </React.Fragment>
    )

}
