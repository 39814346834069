import React, { useState,useEffect } from 'react';
import HeaderLayout from '../HeaderLayout'
import PeopleOutlineTwoTone from '@material-ui/icons/PeopleOutlineTwoTone'
import { useStyles } from './style'
import { Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography} from '@material-ui/core'
import { getLoggedInUserDetailsInProbation, getProbationRecords } from '../fetchData';

import { probationConstant } from '../../../constants/ProbationConstant';
import logo from '../../../utils/load.gif'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import Filter from '../Filter';
import moment from 'moment';
import { probationApiUrl } from '../../../utils/CommonUrl';

const INITIAL_FILTER_OBJECT = {
  empName: '',
  empNo: '',
  reportingManager: '',
  department: null,
  subDepartment: null,
  grade: null,
  designation: null,
  location: null,
  dojStartDate: null,
  dojEndDate: null,
  isReport: false,
}

const DATE_FORMAT = 'DD-MMM-YYYY'
const { loggedInUserGroup, loggedInUserRole, formStatusKeyToIdMapping, probationFormAccess, confirmationDoneByLabel } = probationConstant;
const { EMPLOYEE, HRBP, SUPER_ADMIN } = loggedInUserGroup

export default function ReportHrbp(props) { 
    const classes = useStyles()
    const [isSearchFilterVisible, setIsSearchFilterVisible] = useState(false);
    const [pendingDueList, setPendingDueList] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filterObj, setFilterObj] = useState(INITIAL_FILTER_OBJECT)
    const [loggedInUser, setloggedInUser] = useState({
      empNo: null, role: null, userGroup: null, hrbpDetails: null
    })
    const [isHavingReportAccess, setIsHavingReportAccess] = useState(true);
    const [noRecordsFound, setNoRecordsFound] = useState(false);

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
    
    const handleFilterVisibility = () => {
      setIsSearchFilterVisible(!isSearchFilterVisible);
    }

    const handleListingData = async (searchedFilterObj) => {
      const dojStartDate = searchedFilterObj.dojStartDate
      const dojEndDate = searchedFilterObj.dojEndDate
      let newFilterObj = searchedFilterObj
      newFilterObj.dojStartDate = dojStartDate ? moment(dojStartDate).format("YYYY-MM-DD") : null
      newFilterObj.dojEndDate = dojEndDate ? moment(dojEndDate).format("YYYY-MM-DD") : null
      setFilterObj(searchedFilterObj)
      await getProbationPendingListRecords(loggedInUser, newFilterObj)
    }
    
    const getFilterObjForReport = (loggedInUserDetail, filterObj) => {
      let newFilterObj = filterObj
      if(loggedInUserDetail.userGroup == HRBP) {
        newFilterObj = {
          ...newFilterObj,
          hrbpFilter: loggedInUserDetail.hrbpDetails,
        }
      }
      return newFilterObj
    }

    const getProbationPendingListRecords = async (loggedInUserDetail, filterObj) => {
      try {
        let obj = getFilterObjForReport(loggedInUserDetail, filterObj)
        setIsLoading(true)
        let response = await getProbationRecords(obj)
        let noRecordsFound = false;
        if(response){
          response.forEach(record => {
            record.formAccessType = probationFormAccess.VIEW
            record.groupDoj = moment(record.groupDoj).format(DATE_FORMAT);
            record.probationDueDate = moment(record.probationDueDate).format(DATE_FORMAT);
            record.confirmationDate = (record.confirmationDate && record.confirmationDate != "0000-00-00 00:00:00") ? 
              moment(record.confirmationDate).format(DATE_FORMAT) : ''
            record.confirmationDoneBy = Object.keys(confirmationDoneByLabel).includes(record.confirmationDoneBy) ? 
              confirmationDoneByLabel[record.confirmationDoneBy] : ''
            if(record.probationAction == probationConstant.probationActionInForm.TERMINATED) {
              record.confirmationDoneBy = '' // reset value in case of termination
            }
          });
          noRecordsFound = response.length == 0;
          setPendingDueList(response)
        }
        setPage(0);
        setIsLoading(false)
        setNoRecordsFound(noRecordsFound);
      } catch (error) {
        console.log(error)
        setIsLoading(false)
      }
    }

    const getActionLink = (empNo, probationFormId) => {
      let actionLink = '';
      actionLink = `${process.env.REACT_APP_BASE_URL}/reports/probation/over-all?empNo=${btoa(empNo)}&id=${probationFormId ? btoa(probationFormId) : ''}`
      window.parent.postMessage(`${actionLink}`, process.env.REACT_APP_BASE_URL)
    }

    const handleDownloadAsExcel = () => {
      const a = document.createElement('a');
      a.setAttribute('hidden', '');
      a.setAttribute('href', `${probationApiUrl.DOWNLOAD_PROBATION_REPORT}`);
      a.setAttribute('target', '_blank');

      const form = document.createElement('form');
      form.method = 'POST';
      form.action = a.href;
      const inputData = document.createElement('input');
      inputData.type = 'hidden';
      inputData.name = 'reportData';
      let obj = getFilterObjForReport(loggedInUser, filterObj)
      inputData.value = JSON.stringify(obj)
      form.appendChild(inputData);
      document.body.appendChild(form);
      form.submit();
      document.body.removeChild(form);      
    }

    useEffect(() => {
      const fetchData = async () => {
        try {
          let loggedInUserDetails = {};
          let filterObj = INITIAL_FILTER_OBJECT;
          let isHavingReportAccess = false;
          const userData = await getLoggedInUserDetailsInProbation();
          if(userData && userData.empNo) {
            if(userData.userGroupName == HRBP) {
              loggedInUserDetails = {
                empNo: userData.empNo,
                role: userData.userRole,
                userGroup: userData.userGroupName,
                hrbpDetails: userData.hrbpDetails
              }
            } else {
              loggedInUserDetails = {
                empNo: userData.empNo,
                role: userData.userRole,
                userGroup: userData.userGroupName,
              }
            }

            isHavingReportAccess = loggedInUserDetails.userGroup == HRBP || loggedInUserDetails.userGroup == SUPER_ADMIN
            if(isHavingReportAccess) {
              filterObj = {
                ...filterObj,
                isReport: true,
              }
            }
            await getProbationPendingListRecords(loggedInUserDetails, filterObj);
          }
          setloggedInUser(loggedInUserDetails);
          setFilterObj(filterObj);
          setIsHavingReportAccess(isHavingReportAccess);
        } catch (error) {
          
        }
      }
      fetchData();
    }, []);

    const HeaderComponent = (
      <Grid container spacing={2} alignItems="center">
        <Grid item> 
          <HeaderLayout
              title='Probation Report'
              subTitle=''
              icon={<PeopleOutlineTwoTone/>}
          />
        </Grid>
      </Grid>
    );
     
    if(!isHavingReportAccess) {
      return <div className={classes.root}>
          {HeaderComponent}
          <Grid item xs={12} style={{width: '90%'}}>
            <Typography variant="h6" align="center" style={{color: 'red', textAlign: 'center'}}>
              You are not authorized to view this report page
            </Typography>
          </Grid> 
      </div>
    } else {
      return <div className={classes.root}>
            {HeaderComponent}
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} style={{ textAlign: 'right', marginRight: '10px', marginBottom: '5px'}}>
                <FontAwesomeIcon className={classes.filterDiv} icon={faFilter} size="lg" onClick={handleFilterVisibility} />&nbsp;Filter
                </Grid>
              </Grid>
                {isSearchFilterVisible ? 
                  <Filter
                    loggedInUser={loggedInUser}
                    sendFilterObj={handleListingData}
                    isForReport={true}
                  />
                  : (null)
                }
                <Paper elevation={4}>
                  { (isLoading ) 
                    ? 
                    <div className={classes.loader}><img src={logo} alt="loading..." /></div>
                    : 
                    <div>
                      <div style={{ overflow: 'auto'}}> 
                        {noRecordsFound ?
                          <a
                            className={classes.downloadAnchor} 
                            onClick={handleDownloadAsExcel}
                            style={{ pointerEvents: 'none', color: 'grey' }}
                          >
                            Click to download Report
                          </a> : 
                          <a
                            className={classes.downloadAnchor} 
                            onClick={handleDownloadAsExcel}
                          >
                            Click to download Report
                          </a>
                        }
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.headerCell} style={{paddingLeft: '15px'}}>Probation Evaluation Form</TableCell>
                                        <TableCell className={classes.headerCell} style={{ minWidth: '125px'}}>Employee</TableCell>
                                        
                                        <TableCell className={classes.headerCell} >Status</TableCell>
                                        <TableCell className={classes.headerCell} style={{minWidth: '90px'}} >Group DOJ</TableCell>
                                        <TableCell className={classes.headerCell} >Designation</TableCell>
                                        <TableCell className={classes.headerCell} >Grade</TableCell>
                                        <TableCell className={classes.headerCell} >Department</TableCell>
                                        <TableCell className={classes.headerCell} >Sub Department</TableCell>
                                        <TableCell className={classes.headerCell} style={{minWidth: '125px'}}>P.A.A.</TableCell>
                                        <TableCell className={classes.headerCell} style={{minWidth: '125px'}}>Reporting Manager</TableCell>
                                        <TableCell className={classes.headerCell} style={{minWidth: '90px'}}>Probation Status</TableCell>
                                        <TableCell className={classes.headerCell} style={{minWidth: '90px'}}>Probation Form Status</TableCell>
                                        <TableCell className={classes.headerCell} style={{minWidth: '90px'}}>Probation Due Date</TableCell>
                                        <TableCell className={classes.headerCell} style={{minWidth: '90px'}}>Confirmation&nbsp;/&nbsp;Termination Date</TableCell>
                                        <TableCell className={classes.headerCell} style={{minWidth: '80px'}}>Confirmation Done By</TableCell>
                                        <TableCell className={classes.headerCell} style={{minWidth: '150px'}}>Company</TableCell>
                                        <TableCell className={classes.headerCell} style={{minWidth: '80px', paddingRight: '15px'}}>Official Email Id</TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                { ( pendingDueList && pendingDueList.length > 0 ) ? pendingDueList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((element,key)=>(
                                    <TableRow key={key}>
                                        <TableCell className={classes.headerBodyCell}  style={{paddingLeft: '15px'}}>
                                          { element.isFormFilled ?
                                            <Button
                                              onClick={e => getActionLink(element.employeeno, element.probationFormId)}
                                              variant="text"
                                              style={{ color: 'blue', textTransform:'none' }}
                                            >
                                              {probationConstant.formAccessMapping[element.formAccessType]}
                                            </Button>
                                            : '-' 
                                          }
                                        </TableCell>
                                        <TableCell className={classes.headerBodyCell}  style={{width: '50px'}}>{element.legalname}&nbsp;({element.employeeno})</TableCell>
                                        <TableCell className={classes.headerBodyCell} >{element.employeeStatus}</TableCell>
                                        <TableCell className={classes.headerBodyCell} >{element.groupDoj}</TableCell>
                                        <TableCell className={classes.headerBodyCell} >{element.designation}</TableCell>
                                        <TableCell className={classes.headerBodyCell} >{element.grade}</TableCell>
                                        <TableCell className={classes.headerBodyCell} >{element.department}</TableCell>
                                        <TableCell className={classes.headerBodyCell} >{element.subDepartment}</TableCell>
                                        <TableCell className={classes.headerBodyCell} >{element.paaName ? (element.paaName) : ''}&nbsp;{(element.paaEmpId) ? `(${element.paaEmpId})` : ''}</TableCell>
                                        <TableCell className={classes.headerBodyCell} >{element.reportingManagerName}&nbsp;({element.reportinManagerEmpId})</TableCell>
                                        <TableCell className={classes.headerBodyCell} >{element.probationStatusLabel}</TableCell>
                                        <TableCell className={classes.headerBodyCell} >{element.probationFormStatusLabel}</TableCell>
                                        <TableCell className={classes.headerBodyCell} >{element.probationDueDate}</TableCell>
                                        <TableCell className={classes.headerBodyCell} >{element.confirmationDate}</TableCell>
                                        <TableCell className={classes.headerBodyCell} >{element.confirmationDoneBy}</TableCell>
                                        <TableCell className={classes.headerBodyCell} >{element.company}</TableCell>
                                        <TableCell className={classes.headerBodyCell} style={{paddingRight: '15px'}}>{element.companyProvidedEmailId}</TableCell>
                                    </TableRow>
                                )) : (
                                    <TableRow>
                                    <TableCell style={{ color: 'red', textAlign: 'center'}} colSpan={3}>No records found</TableCell>
                                    </TableRow>
                                ) } 
                                </TableBody>
                            </Table>
                        </TableContainer>
                      </div>
                      <div>
                        <TablePagination
                          rowsPerPageOptions={[10, 25, 50, 100]}
                          component="div"
                          count={pendingDueList.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </div> 
                    </div>
                  }
                </Paper>
          </div>
    }
    
 }
