import { toast } from 'react-toastify'

const ToastElement = ({
    msg,
    type,
    position = "top-right",
    autoClose = 8000,
    hideProgressBar = false,
    closeOnClick = true,
    pauseOnHover = true,
    draggable = true,
    bodyClassName = "grow-font-size"
}) => {
    const toastOption = {
        position,
        autoClose,
        hideProgressBar,
        closeOnClick,
        pauseOnHover,
        draggable,
        bodyClassName
   };
    switch(type) {
        case 'success' :
             toast.success(`🚀${msg}`, toastOption);
             break;
        case 'warn' :
             toast.warn(`🦄${msg}`, toastOption);
             break;
        case 'error' :
             toast.error(`🦄${msg}`, toastOption);
             break;
        case 'info' :
             toast.info(`🦄${msg}`, toastOption);
             break;
        default:
             toast(`🚀${msg}`, toastOption);
             break;
   }
}

export default ToastElement