import React from 'react'
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Icon from '@material-ui/core/Icon';
import moment from "moment";

const NomineeRecord = ({ handleDeleteClick, isEditable,isDeleteIconVisible, serialNo, row: {
          id, 
          nominee_name, 
          relation,
          nominee_dob,
          nominee_address,
          nominee_contact_no,
          share_percent,
          guardian_name,
          guardian_contact,
          guardian_address
     }
     }) => {     
     return (
          <TableRow>
               <TableCell align="center">{serialNo}</TableCell>
               <TableCell align="center">{nominee_name}</TableCell>
               <TableCell align="center">{relation}</TableCell>
               <TableCell align="center">{moment(nominee_dob).format("DD-MMM-YYYY")}</TableCell>
               <TableCell align="center"><div className="overflow-div-address">{nominee_address}</div></TableCell>
               <TableCell align="center">{nominee_contact_no}</TableCell>
               <TableCell align="center">{share_percent}%</TableCell>
               <TableCell align="left" colSpan={2}>
                    { 
                         guardian_name && guardian_contact && guardian_address ? 
                         (    <div>
                              <div><b>Name:</b> {guardian_name}</div>
                              <div><b>Contact:</b> {guardian_contact}</div>
                              <div><b>Address:</b> 
                              <div className="overflow-div-gaddress">{guardian_address}</div>
                              </div>
                              </div>
                         ) 
                         :
                         (<div>N/A</div>)
                    }
               </TableCell>
               <TableCell align="center">
                    {
                         (isDeleteIconVisible && isEditable) ? 
                         (<div>
                              <Icon 
                              onClick={() => handleDeleteClick(id)}
                              className="fa fa fa-trash-alt" 
                              title="Delete"
                              isDisabled={true}
                              />
                         </div>)
                         : (<div></div>)
                    }
               </TableCell>
          </TableRow>
     )
}

export default NomineeRecord;