import { makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles(theme => ({
    formRoot: {
      padding: theme.spacing(1),
      margin: '2em',
      width: '100%'
    },
    label:{
      fontSize: '14px',
      fontWeight: '600',
    },
    value: {
      fontSize: '14px',
      margin: '2px 10px',
    },
    gridContainer: {
      marginBottom: '10px',
      padding: '2px',
    },
    gridInputContainer: {
      marginTop: '25px',
    },
    button: {
      marginTop: '60px',
      textTransform: 'none'
    },
    charCount: {
      textAlign:'right',
      paddingRight: '10px',
      paddingTop: '2px'
    },
    history: {
      '& li:last-child': {
        display: 'none'
      }, 
    },   
    customTextBox: {
      '& .MuiOutlinedInput-input::placeholder': {
        fontSize: 13
      }
    } 
}))
