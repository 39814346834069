import axios from "axios"

import { hrDashboardAPIUrl } from "../../utils/CommonUrl"
import { getAxiosConfig, getRequestHeader } from '../../utils/CommonFunction'

let axiosConfig = getAxiosConfig({ withCredentials:true });
axiosConfig.headers = getRequestHeader()

export const getPendingTaskListService = ({ filterObj = {} }) => {
    const { PENDING_TASK_LIST_API } = hrDashboardAPIUrl
    return axios.post(`${PENDING_TASK_LIST_API}`, { filterObj }, axiosConfig)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}

export const getReportAnalyticsService = ({ filterObj = {} }) => {
    const { REPORT_ANALYTICS_API } = hrDashboardAPIUrl
    return axios.post(`${REPORT_ANALYTICS_API}`, { filterObj }, axiosConfig)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}

export const getYouAreAwesomeDetailDataService = ({ filterObj = {}, reportName = "" }) => {
    const { REPORT_YOU_ARE_AWESOME_DATA_API } = hrDashboardAPIUrl
    return axios.post(`${REPORT_YOU_ARE_AWESOME_DATA_API}`, { filterObj, reportName }, axiosConfig)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}

export const getReportFilterInitFetchData = () => {
    const { REPORT_FILTER_INIT_DATA_API } = hrDashboardAPIUrl
    return axios.post(`${REPORT_FILTER_INIT_DATA_API}`)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}

export const getReportFilterFetchData = ({ filterObj = {}, pageView }) => {
    const { REPORT_FILTER_DATA_API } = hrDashboardAPIUrl
    return axios.post(`${REPORT_FILTER_DATA_API}`, { filterObj, pageView }, axiosConfig)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}

export const getObFeedbackDetailDataService = ({ filterObj = {}, feedbackType = "" }) => {
    const { REPORT_OB_FEEDBACK_DATA_API } = hrDashboardAPIUrl
    return axios.post(`${REPORT_OB_FEEDBACK_DATA_API}`, { filterObj, feedbackType }, axiosConfig)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}

export const getSubDepartmentFetchData = (dataObj) => {
    const { REPORT_OB_SUBDEPARTMENT_API } = hrDashboardAPIUrl
    return axios.post(`${REPORT_OB_SUBDEPARTMENT_API}`, { ...dataObj })
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}

export const getFeelingAtHomeDetailDataService = ({ filterObj = {}, reportName }) => {
    const { REPORT_FEELING_AT_HOME_DATA_API } = hrDashboardAPIUrl
    return axios.post(`${REPORT_FEELING_AT_HOME_DATA_API}`, { filterObj, reportName }, axiosConfig)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}

export const getDiversityDetailDataService = ({ filterObj = {} }) => {
    const { REPORT_DIVERSITY_DATA_API } = hrDashboardAPIUrl
    return axios.post(`${REPORT_DIVERSITY_DATA_API}`, { filterObj }, axiosConfig)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}

export const getExitAnalysisDetailDataService = ({ filterObj = {}, reportName = "" }) => {
    const { REPORT_EXIT_ANALYSIS_DATA_API } = hrDashboardAPIUrl
    return axios.post(`${REPORT_EXIT_ANALYSIS_DATA_API}`, { filterObj, reportName }, axiosConfig)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}

export const getGrossOfferDataService = ({ filterObj = {} }) => {
    const { REPORT_GROSS_OFFER_DATA_API } = hrDashboardAPIUrl
    return axios.post(`${REPORT_GROSS_OFFER_DATA_API}`, { filterObj }, axiosConfig)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}

export const getNetOfferDataService = ({ filterObj = {} }) => {
    const { REPORT_NET_OFFER_DATA_API } = hrDashboardAPIUrl
    return axios.post(`${REPORT_NET_OFFER_DATA_API}`, { filterObj }, axiosConfig)
            .then(response => {
                const { data } = response || {}
                return data
            })
            .catch((err) => {
                const { 
                    response: {
                        data
                    } = {}
                } = err
                return data
            })
}
